import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatObstructedView = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 10 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M1 3.525V1.5a1.5 1.5 0 0 1 .067-.446A1.5 1.5 0 0 1 2.5 0h5A1.5 1.5 0 0 1 9 1.5v2.025A1.25 1.25 0 0 0 7.5 4.75V6h-5V4.75a1.246 1.246 0 0 0-1-1.225 1.3 1.3 0 0 0-.477-.004m0 0L1 3.525zM1.5 2.96v.058c.345.05.657.199.907.419L4.905.5H3.592zM2.905.5H2.5a1 1 0 0 0-1 1v.652zm2.687 0L2.748 3.844c.16.264.252.574.252.906v.34L6.905.5zm1.997.004L3.339 5.5h1.313L8.41 1.083a1 1 0 0 0-.82-.58m.911 1.28L5.34 5.5h1.312L7 5.09v-.34a1.75 1.75 0 0 1 1.5-1.732zM10 5a1 1 0 0 0-1.097-.995h-.008l-.018.002-.01.002a1 1 0 0 0-.859.868l-.002.011A1 1 0 0 0 8 5v1h2zM9 6.5h1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-.676-.263A1 1 0 0 1 0 7.5v-1h9M7.376 7H6.064l-.851 1h1.313zm-.163 1 .85-1h1.313l-.85 1zm2.082-.096a.5.5 0 0 0 .151-.178zM4.525 8l.851-1H4.064l-.851 1zm-2 0 .851-1H2.064l-.851 1zM.65 7.856 1.376 7H.5v.5c0 .14.057.265.149.356m.485-3.847-.01-.001h-.001l-.02-.003h-.006A1 1 0 0 0 0 5v1h2V5a1 1 0 0 0-.866-.991"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatObstructedView;
