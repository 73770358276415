import React from 'react';
import { Set } from 'immutable';
import {
  AvailableSeatModelStatus,
  AVAILABLE_SEAT_STATUS_AVAILABLE,
  AVAILABLE_SEAT_STATUS_DISMISSED,
} from 'mapado-ticketing-js-sdk';
import { selectBatchOfSeats } from '../../../../actions/SeatActions';
import { updateAvailableSeatModelListStatus } from '../../../../actions/LogicalSeatConfigAction';
import '../../../../styles/components/Sidebar/StockContingentSidebar.scss';
import { useSeatingDispatch } from '../../../../reducers/typedFunctions';
import { useTranslation } from '../../../../i18n';

type GaugePaneActionsProps = {
  onAction: () => void;
};

function GaugeSidebarActions({ onAction }: GaugePaneActionsProps) {
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();

  async function handleSeatListStatus(status: AvailableSeatModelStatus) {
    await dispatch(updateAvailableSeatModelListStatus(status));
    dispatch(selectBatchOfSeats(Set()));
    onAction();
  }

  return (
    <>
      <button
        type="button"
        className="mpd-btn mpd-btn--primary"
        onClick={() => handleSeatListStatus(AVAILABLE_SEAT_STATUS_DISMISSED)}
      >
        {t('seating.dismiss_seat_cta')}
      </button>
      <button
        type="button"
        className="mpd-btn mpd-btn--secondary"
        onClick={() => handleSeatListStatus(AVAILABLE_SEAT_STATUS_AVAILABLE)}
      >
        {t('seating.restore_seat_cta')}
      </button>
    </>
  );
}

export default GaugeSidebarActions;
