import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum FEE_TYPE {
  POSTAL_MAIL = 'postalMail',
  REGISTERED_MAIL = 'registeredMail',
}

export type FeeType = BaseEntity<'Fee'> & {
  contract: null | EntityRelation<Contract>;
  type: null | FEE_TYPE;
  amount: null | number;
  calculatedAmount: null | number;
  rate: null | number;
  vatRate: null | number;
  currency: null | string;
};

class Fee extends NetworkEntity<FeeType>({
  '@id': null,
  '@type': 'Fee',
  contract: null,
  type: null,
  amount: null,
  calculatedAmount: null,
  rate: null,
  vatRate: null,
  currency: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<FeeType> = {
      '@id': null,
      '@type': 'Fee',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/fees/', '') || '';
  }
}

Fee.classMetadata = new ClassMetadata(
  'fee',
  'fees',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Fee.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('amount', 'amount', 'integer'),
  new Attribute('calculatedAmount', 'calculatedAmount', 'integer'),
  new Attribute('rate', 'rate', 'float'),
  new Attribute('vatRate', 'vatRate', 'float'),
  new Attribute('currency'),
]);
Fee.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Fee;
