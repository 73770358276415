import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderSeatMappingClient from '../../../client/ProviderSeatMappingClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import Seat from '../../Seat';
import { ProviderTicketMappingType } from './ProviderTicketMappingType';

export type ProviderSeatMappingType = BaseEntity<'ProviderSeatMapping'> &
  ProviderTicketMappingType & {
    seat?: EntityRelation<Seat> | null;
  };

class ProviderSeatMapping extends NetworkEntity<ProviderSeatMappingType>({
  '@id': null,
  '@type': 'ProviderSeatMapping',
  contract: undefined,
  provider: undefined,
  seat: undefined,
  label: undefined,
  isMapped: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderSeatMappingType> = {
      '@id': null,
      '@type': 'ProviderSeatMapping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/provider_seat_mappings/', '') || '';
  }
}

ProviderSeatMapping.classMetadata = new ClassMetadata(
  'providerSeatMapping',
  'provider_seat_mappings',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderSeatMappingClient
);
ProviderSeatMapping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('provider'),
  new Attribute('label'),
  new Attribute('isMapped', 'isMapped', 'boolean'),
]);
ProviderSeatMapping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'seat', 'seat'),
]);

export default ProviderSeatMapping;
