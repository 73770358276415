import { Map, Record } from 'immutable';

interface MappingType {
  columns: Map<string, null | string | undefined>;
  statuses: Map<string, string>;
}

export default class Mapping extends Record<MappingType>({
  columns: Map<string, null | string>(),
  statuses: Map<string, string>(),
}) {}
