import panzoom, { PanZoom as PanZoomType } from 'panzoom';

export type SvgPanZoom = PanZoomType & {
  zoomBy: (scale: number, x?: number, y?: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  disablePan: () => void;
  enablePan: () => void;
  destroy: () => void;
};

let instance: SvgPanZoom | null = null;

export function getSvgPanZoomInstance(): SvgPanZoom {
  if (!instance) {
    throw new Error(
      'Unable to get SvgPanZoomInstance. Did you forgot to call `initSvgPanZoom` ?'
    );
  }

  return instance;
}

function initSvgPanZoom(element: HTMLElement | SVGElement): SvgPanZoom {
  instance = panzoom(element, {
    zoomSpeed: 0.1, // 10% per mouse wheel event
    maxZoom: 3,
    minZoom: 0.01,
    autocenter: true,
    bounds: true,
    beforeWheel: (event: WheelEvent) => {
      // disable wheelevent in panzoom in order to prevent the change of the scale value when moving top/bottom with the trackpad
      return Math.abs(event.deltaY) < 100;
    },
  }) as SvgPanZoom;

  const zoomScaleSensitivity = 0.2; // on click on + / - buttons

  const seatingApp = document.getElementById('mpd-seating__app');
  const height = seatingApp ? seatingApp.offsetHeight : 0;

  /* eslint-disable func-names */
  instance.zoomBy = function (scale: number, x?: number, y?: number): void {
    const transformModel = this.getTransform();

    this.zoomTo(
      x ?? transformModel.x,
      y ?? transformModel.y + height / 2,
      scale
    );
  };

  instance.zoomIn = function (): void {
    this.zoomBy(1 + zoomScaleSensitivity);
  };

  instance.zoomOut = function (): void {
    this.zoomBy(1 - zoomScaleSensitivity);
  };

  instance.disablePan = function (): void {
    this.pause();
  };

  instance.enablePan = function (): void {
    this.resume();
  };

  instance.destroy = function (): void {
    this.dispose();
  };
  /* eslint-enable func-names */

  return instance;
}

export function disablePan(): void {
  const svgPanZoomInstance = getSvgPanZoomInstance();

  svgPanZoomInstance.disablePan();
}

export function enablePan(): void {
  const svgPanZoomInstance = getSvgPanZoomInstance();

  svgPanZoomInstance.enablePan();
}

export default initSvgPanZoom;
