import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrossStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M3.565 21.506c4.715 4.658 12.312 4.613 16.97-.101 4.659-4.714 4.614-12.312-.1-16.97-4.714-4.659-12.312-4.613-16.97.1-4.659 4.715-4.614 12.313.1 16.97M19.03 5.857c3.928 3.882 3.966 10.214.084 14.142S8.9 23.965 4.971 20.083C1.043 16.201 1.005 9.87 4.887 5.941c3.882-3.928 10.214-3.966 14.142-.084m-7.037 5.7L14.1 9.421l1.423 1.406-2.109 2.134 2.134 2.108-1.406 1.423-2.133-2.108-2.11 2.133-1.422-1.405 2.109-2.134-2.134-2.109 1.406-1.423z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCrossStroke;
