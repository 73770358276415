import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCross = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/closes" transform="translate(-5 -5)">
        <path
          id="icon-SVG"
          d="M17 3v10h10v4H17v10h-4V17H3v-4h10V3z"
          transform="rotate(46 15 15)"
        />
      </g>
    </g>
  </svg>
);
export default SvgCross;
