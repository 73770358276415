import React, { MouseEvent, ReactNode, ReactElement } from 'react';
import cn from 'classnames';

type Tab = {
  id: string;
  selected?: boolean;
  title: string;
  content?: string;
  progression?: number;
  path: string;
};

type Props = {
  tabs: Array<Tab>;
  onChange: (n: Tab) => void;
  children: ReactNode;
};

type State = {
  selected: string;
};

class MpdAside extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: this.getSelectedTab(),
    };
  }

  onClick(event: MouseEvent<HTMLAnchorElement>, tab: Tab): void {
    event.preventDefault();

    this.setState({ selected: tab.id });

    this.props.onChange(tab);
  }

  getSelectedTab(): string {
    const selectedTab = this.props.tabs.find((tab) => tab.selected);

    return selectedTab ? selectedTab.id : this.props.tabs[0].id;
  }

  render(): ReactElement {
    const { tabs, children } = this.props;
    const { selected } = this.state;

    return (
      <div className="aside">
        <nav className="aside__navigation">
          <ul>
            {tabs.map((tab) => {
              const progressionClasses = cn(
                'aside__progression',
                tab.progression !== undefined && {
                  'aside__progression--zero': tab.progression === 0,
                  'aside__progression--complete': tab.progression === 100,
                }
              );

              return (
                <li
                  className={cn({ selected: selected === tab.id })}
                  key={tab.id}
                >
                  <div className="selector" />
                  <a
                    href={tab.path}
                    onClick={(event) => this.onClick(event, tab)}
                  >
                    {tab.progression !== undefined && (
                      <span className={progressionClasses}>
                        {tab.progression}%
                      </span>
                    )}
                    <span className="h3-like mt1 mb1">{tab.title}</span>
                    <span className="smaller">{tab.content}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className="aside__content">{children}</div>
      </div>
    );
  }
}

export default MpdAside;
