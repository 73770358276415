import * as React from 'react';
import type { SVGProps } from 'react';
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 27 21" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="m25.039.148 1.364 1.463-2.194 2.046 2.046 2.194-1.463 1.364-2.046-2.194-2.194 2.046-1.364-1.463 2.194-2.046-2.046-2.194L20.799 0l2.046 2.194zM0 1.155h17v2H2v15h22v-8h2v10H0zm17 10.01 3.615 3.99h2.728l-6.343-7-3.116 3.439L10 7.155l-7 8h2.728L10 10.441l4.478 4.714H17l-1.776-2.03z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgImage;
