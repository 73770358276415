import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPercent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="5 5 20 20" className="mpd-icon" {...props}>
    <path
      id="path-1"
      d="M15 5c5.523 0 10 4.477 10 10s-4.477 10-10 10S5 20.523 5 15 9.477 5 15 5m3.5 5.5-1.506.083L11.5 19.5l1.506-.083zm.067 5-.179.003q-.816.045-1.372.663a1.93 1.93 0 0 0-.513 1.45q.045.83.65 1.384.62.542 1.446.496a1.97 1.97 0 0 0 1.379-.65q.563-.607.519-1.45a1.92 1.92 0 0 0-.669-1.384 1.94 1.94 0 0 0-1.44-.509Zm-.123 1.002a1 1 0 0 1 .733.236q.296.258.32.704.026.445-.238.74a.97.97 0 0 1-.702.316.975.975 0 0 1-1.055-.954.95.95 0 0 1 .247-.726.97.97 0 0 1 .695-.316M11.567 10.5l-.179.002q-.816.045-1.372.663a1.93 1.93 0 0 0-.513 1.45q.045.83.65 1.384.62.542 1.446.496a1.97 1.97 0 0 0 1.379-.65q.563-.607.519-1.45a1.92 1.92 0 0 0-.669-1.384 1.94 1.94 0 0 0-1.44-.509Zm-.123 1a1 1 0 0 1 .733.237q.296.258.32.704.027.445-.238.74a.97.97 0 0 1-.702.316.975.975 0 0 1-1.055-.954.95.95 0 0 1 .247-.726.97.97 0 0 1 .695-.316"
    />
  </svg>
);
export default SvgPercent;
