import React, { FunctionComponent } from 'react';
import { components, ClearIndicatorProps, GroupBase } from 'react-select';
import MpdIcon from '../../../MpdIcon';
import { SingleSelectOption } from './utils';

// @ts-expect-error typescipt version is too old. Upgrade it requires to upgrade storybook too
const ClearIndicator: FunctionComponent<ClearIndicatorProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>
>> = (props: ClearIndicatorProps<SingleSelectOption>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.ClearIndicator {...props}>
    <MpdIcon icon="cross" className="mpd-color-light-gray ml1" />
  </components.ClearIndicator>
);

export default ClearIndicator;
