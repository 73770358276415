// Pure JS with checking all nodes between
const isTopElement = (wrapperElement: Node, node: Node) => {
  if (typeof node.isSameNode === 'function') {
    return node.isSameNode(wrapperElement);
  }

  return node.isEqualNode(wrapperElement);
};

export default function delegate(
  wrapperElement: Node,
  eventName: string,
  selector: string,
  listener: (event: Event) => void
): void {
  // eslint-disable-next-line func-names
  wrapperElement.addEventListener(eventName, function (event) {
    let checkingNode: null | Element = event.target as Element;

    while (checkingNode) {
      if (isTopElement(wrapperElement, checkingNode)) {
        // checking element itself
        return;
      }

      if (checkingNode.matches(selector)) {
        // found delegated element
        listener.call(checkingNode, event); // "this" will be delegated element

        return;
      }

      // going to parent node
      checkingNode = checkingNode.parentNode as null | Element;
    }
  });
}
