import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import PayoutClient from '../client/PayoutClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import NetworkEntity from './NetworkEntity';
import { extractTimezone } from './utils';
import { BaseEntity, PartialEntity } from '.';

export type PayoutType = BaseEntity<'Payout'> & {
  createdAt: null | Moment;
  isOpaque: null | boolean;
  hasRefunds: null | boolean;
  type: null | string;
  payoutRef: null | string;
  creditedFunds: null | number;
  paidAt: null | Moment;
  status: null | string;
  provider: null | string;
  wallet: null | string;
};
class Payout extends NetworkEntity<PayoutType>({
  '@id': null,
  '@type': 'Payout',
  createdAt: null,
  isOpaque: null,
  hasRefunds: null,
  type: null,
  payoutRef: null,
  creditedFunds: null,
  paidAt: null,
  status: null,
  provider: null,
  wallet: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<PayoutType> = { '@id': null, '@type': 'Payout' }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['wallet', 'contract', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);
    data.paidAt = parseDate(data.paidAt, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/payouts/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Payout.classMetadata = new ClassMetadata('payout', 'payouts', PayoutClient);
Payout.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('isOpaque'),
  new Attribute('hasRefunds'),
  new Attribute('type'),
  new Attribute('payoutRef'),
  new Attribute('creditedFunds', 'creditedFund', 'number'),
  new Attribute('paidAt', 'paidAt', 'datetime'),
  new Attribute('status'),
  new Attribute('provider'),
]);
Payout.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
]);

export default Payout;
