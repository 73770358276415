import URI from 'urijs';
import CartItem from '../entity/CartItem';
import PagedCollection from '../entity/PagedCollection';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class CartItemClient extends AbstractClient<TSMetadata['cartItem']> {
  getPathBase(): string {
    return '/v1/cart_items';
  }

  bulkUpdate(
    cartItemList: Record<string, unknown>[],
    fields: FieldsParameter
  ): Promise<PagedCollection<CartItem>> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(uri, {
        method: 'POST',
        body: JSON.stringify({
          'hydra:member': cartItemList,
        }),
      }),
      'list'
    );
  }
}

export default CartItemClient;
