import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M12 24.97c-6.627 0-12-5.372-12-12 0-6.627 5.373-12 12-12s12 5.373 12 12c0 6.628-5.373 12-12 12m0-2c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10m4.663-13.563 1.4 1.429-6.612 6.481-.346-.352-.01.01-3.542-3.582 1.423-1.407 2.489 2.517z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckStroke;
