// eslint-disable-next-line import/prefer-default-export
export const colorList = [
  '#895274',
  '#22A7B8',
  '#CD5C52',
  '#F5A623',
  // The following colors where not selected by our designer, but chosen randomly
  '#DB0A5B',
  '#005555',
  '#3455DB',
  '#002A15',
];
