import clone from 'clone';
import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import Transaction from './Transaction';
import { BaseEntity, PartialEntity } from '.';

export type LetteringType = BaseEntity<'Lettering'> & {
  transactionList: null | List<Transaction>;
};
class Lettering extends NetworkEntity<LetteringType>({
  '@id': null,
  '@type': 'Lettering',
  transactionList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<LetteringType> = { '@id': null, '@type': 'Lettering' }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/letterings/', '') || '';
  }
}

Lettering.classMetadata = new ClassMetadata(
  'lettering',
  'letterings',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Lettering.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
]);
Lettering.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'transaction', 'transactionList'),
]);

export default Lettering;
