import React, { ReactNode, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { generateRandomID } from '../../js-libs/utils';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  id?: string;
  className?: string;
  description?: ReactNode | string;
  small?: boolean;
  active?: boolean;
  image?: ReactNode | null;
  children: ReactNode;
  withoutPadding?: boolean;
};

function MpdRadio({
  id,
  className,
  description,
  small,
  active = true,
  image,
  children,
  withoutPadding = false,
  ...input
}: Props): JSX.Element {
  const inputId = id ?? generateRandomID();

  return (
    <div
      className={cn('mpd-radio mpd-card', className, {
        'mpd-radio--has-description': !!description,
        'mpd-radio--small': small,
        'mpd-radio--has-image': !!image,
        'mpd-radio--without-padding': withoutPadding,
        active,
      })}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="radio" id={inputId} {...input} />
      <label htmlFor={inputId}>
        <div className="mpd-radio__children">
          {image}
          <div className="content">{children}</div>
        </div>
        {!!description && (
          <div className="mpd-radio__description">{description}</div>
        )}
      </label>
    </div>
  );
}

export default MpdRadio;
