import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SeatGroupClient from '../client/SeatGroupClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import type { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import Seat from './Seat';
import SeatConfig from './SeatConfig';
import TicketPrice from './TicketPrice';
import TicketPriceSeatGroup from './TicketPriceSeatGroup';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SeatGroupType = HidableType &
  BaseEntity<'SeatGroup'> & {
    contract: null | EntityRelation<Contract>;
    seatColor: null | string;
    seatList: null | List<EntityRelation<Seat>>;
    seatConfig: null | EntityRelation<SeatConfig>;
    ticketPriceList: null | List<EntityRelation<TicketPrice>>;
    ticketPriceSeatGroupList: null | List<EntityRelation<TicketPriceSeatGroup>>;
    label: null | string;
  };
class SeatGroup extends NetworkEntity<SeatGroupType>({
  '@id': null,
  '@type': 'SeatGroup',
  contract: null,
  seatColor: null,
  seatList: List<Seat>(),
  seatConfig: new SeatConfig(),
  ticketPriceList: List<TicketPrice>(),
  ticketPriceSeatGroupList: List<TicketPriceSeatGroup>(),
  label: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SeatGroupType> = { '@id': null, '@type': 'SeatGroup' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seat_groups/', '') || '';
  }
}

SeatGroup.classMetadata = new ClassMetadata(
  'seatGroup',
  'seat_groups',
  /** @ts-expect-error -- method signature are incompatible */
  SeatGroupClient
);
SeatGroup.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
  new Attribute('seatColor'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);
SeatGroup.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'seat', 'seatList'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'ticketPriceSeatGroup',
    'ticketPriceSeatGroupList'
  ),
]);

export default SeatGroup;
