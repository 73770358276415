import delegate from './delegate';
import breakpoints from '../breakpoints';

const hasClass = (element: Element, className: string) =>
  element.classList.contains(className);

const isAnimated = (element: Element) =>
  hasClass(element, 'mpd-dropDown--animate');

function triggerDropdownEvent(dropdown: Element, opened: boolean) {
  dropdown.dispatchEvent(
    new CustomEvent('mpd_dropdown::change', { detail: { opened } })
  );
}

function closeDropDown(dropdown?: Element) {
  if (!dropdown) {
    return;
  }

  dropdown.classList.remove('mpd-dropDown--open');

  if (isAnimated(dropdown)) {
    dropdown.classList.add('mpd-dropDown--closing');
    setTimeout(() => {
      dropdown.classList.remove('mpd-dropDown--closing');
      dropdown.classList.add('mpd-dropDown--close');

      triggerDropdownEvent(dropdown, false);
    }, 500);
  } else {
    dropdown.classList.add('mpd-dropDown--close');
    triggerDropdownEvent(dropdown, false);
  }
}

function openDropDown(dropdown?: Element) {
  if (!dropdown) {
    return;
  }

  dropdown.classList.remove('mpd-dropDown--closing');
  dropdown.classList.remove('mpd-dropDown--close');
  dropdown.classList.add('mpd-dropDown--open');

  triggerDropdownEvent(dropdown, true);
}

export function toggleDropDown(item: Element | null, action?: string): void {
  if (!item) {
    return;
  }

  let innerAction = action;

  if (
    typeof innerAction === 'undefined' ||
    (innerAction !== 'open' && innerAction !== 'close')
  ) {
    if (hasClass(item, 'mpd-dropDown--open')) {
      innerAction = 'close';
    } else if (
      hasClass(item, 'mpd-dropDown--close') ||
      hasClass(item, 'mpd-dropDown--closing')
    ) {
      innerAction = 'open';
    } else if (
      hasClass(item, 'mpd-dropDown--open-from-sm') &&
      window.innerWidth > breakpoints['breakpoint-from-small']
    ) {
      innerAction = 'close';
    } else if (
      hasClass(item, 'mpd-dropDown--open-from-md') &&
      window.innerWidth > breakpoints['breakpoint-from-medium']
    ) {
      innerAction = 'close';
    } else if (
      hasClass(item, 'mpd-dropDown--open-from-lg') &&
      window.innerWidth > breakpoints['breakpoint-from-large']
    ) {
      innerAction = 'close';
    } else if (
      hasClass(item, 'mpd-dropDown--open-from-wide') &&
      window.innerWidth > breakpoints['breakpoint-from-wide']
    ) {
      innerAction = 'close';
    } else {
      innerAction = 'open';
    }
  }

  if (innerAction === 'open') {
    openDropDown(item);
  } else if (innerAction === 'close') {
    closeDropDown(item);
  }
}

function dropDownDocumentClicked(event: Event) {
  const dropDownList = document.querySelectorAll(
    '.mpd-dropDown--open:not(.mpd-dropDown--keepOpen)'
  );

  for (let i = 0; i < dropDownList.length; ++i) {
    const element = dropDownList.item(i);

    if (!element.contains(event.target as Node)) {
      toggleDropDown(element, 'close');
    }
  }
}

function dropdownLinkClicked(this: Element, event: Event) {
  event.preventDefault();

  const dropDown = this.closest('.mpd-dropDown');

  toggleDropDown(dropDown);
}

let dropDownInitialized = false;

function dropDownInit(): void {
  if (dropDownInitialized === false) {
    // close all dropdown when a click on document occurs
    document.addEventListener('click', dropDownDocumentClicked);

    // toggle dropdown state when a link is clicked. delegate allow to add DOM Elements after init
    delegate(document, 'click', '.mpd-dropDown-link', dropdownLinkClicked);

    dropDownInitialized = true;
  }
}

export default dropDownInit;
