import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class OrganizationalUnitClient extends AbstractClient<
  TSMetadata['organizationalUnit']
> {
  getPathBase(): string {
    return '/v1/organizational_units';
  }

  getDefaultGroups(): Array<string> {
    return ['organizational_unit_read', 'organizational_unit_write'];
  }
}

export default OrganizationalUnitClient;
