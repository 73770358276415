import React, { PureComponent } from 'react';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $primaryBlue, $primaryGreen } from '@mapado/makeup/variables';
import { DOMAIN_CONTEXT, useDomainContext } from '@mapado/js-component';
import {
  CustomerType,
  TicketPrice,
  assertRelationIsDefined,
} from 'mapado-ticketing-js-sdk';
import { List, Map, Set } from 'immutable';
import {
  SeatType,
  isAvailableSeatType,
  isAvailableSeatModelType,
  isSeatType,
  SeatEntity,
  SeatEntityType,
  SeatEntityFromType,
} from '../propTypes';
import {
  BOOKING_STATUS_AVAILABLE,
  BOOKING_STATUS_SCANNED,
} from '../utils/booking';
import { useViewContext, View } from '../contexts/ViewContext';
import { getJsEntityId, getSeatGroupColorMap } from '../utils/selectors';
import { getSeatFromSeatEntity } from '../utils/entity';
import { useSeatById } from '../utils/seatSelectable';
import { OnClickSeatFunction } from './Seat/BaseSeat';
import { useSeatingSelector } from '../reducers/typedFunctions';

function getColorForSeatEntity(
  seatEntity: SeatEntity,
  selectedView: View,
  currentDomain: DOMAIN_CONTEXT,
  seatGroupColorMap: Map<string, string | null>
): string {
  if (isSeatType(seatEntity)) {
    if (
      currentDomain === DOMAIN_CONTEXT.DESK &&
      selectedView === View.Contingent &&
      seatEntity.stockContingent &&
      typeof seatEntity.stockContingent === 'object'
    ) {
      return seatEntity.stockContingent.color || $primaryBlue;
    }

    return $primaryBlue;
  }

  if (
    isAvailableSeatType(seatEntity) &&
    currentDomain === DOMAIN_CONTEXT.DESK &&
    seatEntity.bookingStatus === BOOKING_STATUS_SCANNED
  ) {
    // for scanned seats, we want to display a specific green color in EventDateAdmin context
    return $primaryGreen;
  }

  if (
    currentDomain === DOMAIN_CONTEXT.MINISITE ||
    (currentDomain === DOMAIN_CONTEXT.DESK && selectedView !== View.Contingent)
  ) {
    assertRelationIsDefined(seatEntity.seatGroup, 'seatEntity.seatGroup');

    return (
      seatGroupColorMap.get(getJsEntityId(seatEntity.seatGroup)) || $primaryBlue
    );
  }

  if (
    isAvailableSeatType(seatEntity) &&
    currentDomain === DOMAIN_CONTEXT.DESK &&
    selectedView === View.Contingent &&
    seatEntity.stockContingent &&
    typeof seatEntity.stockContingent === 'object'
  ) {
    return seatEntity.stockContingent.color || $primaryBlue;
  }

  if (
    isAvailableSeatModelType(seatEntity) &&
    currentDomain === DOMAIN_CONTEXT.DESK &&
    selectedView === View.Contingent &&
    seatEntity.contingent &&
    typeof seatEntity.contingent === 'object'
  ) {
    return seatEntity.contingent.color || $primaryBlue;
  }

  return $primaryBlue;
}

class SeatElementList<SE extends SeatEntityType> extends PureComponent<
  SeatElementListProps<SE>
> {
  static defaultProps = {
    getTicketPriceListBySeatGroup: null,
  };

  render(): React.ReactNode {
    const {
      isMovingSeat,
      onClickSeat,
      onFocusSeat,
      onUnfocusSeat,
      seatEntityList,
      SeatElement,
      seatById,
      selectableSeatIdSet,
      selectedSeatIdSet,
      selectedView,
      currentDomain,
      seatGroupColorMap,
      getTicketPriceListBySeatGroup,
    } = this.props;

    const isDesk = currentDomain === DOMAIN_CONTEXT.DESK;

    return seatEntityList.map((seatEntity) => {
      const seat = getSeatFromSeatEntity(seatEntity, seatById);
      const seatId = Number(seat['@id'].replace('/v1/seats/', ''));

      return (
        <SeatElement
          key={seatEntity['@id']}
          seatEntity={seatEntity}
          info={seat.info}
          angle={seat.angle}
          type={seat.type}
          hasObstructedView={seat.hasObstructedView}
          seatId={seatId}
          isSelectable={selectableSeatIdSet.has(seat['@id'])}
          isSelected={selectedSeatIdSet.has(seatId)}
          bookingStatus={
            (isAvailableSeatType(seatEntity) && seatEntity.bookingStatus) ||
            (isAvailableSeatModelType(seatEntity) && seatEntity.status) ||
            BOOKING_STATUS_AVAILABLE
          }
          color={getColorForSeatEntity(
            seatEntity,
            selectedView,
            currentDomain,
            seatGroupColorMap
          )}
          isDesk={isDesk}
          isMovingSeat={isMovingSeat}
          onClickSeat={onClickSeat}
          onFocusSeat={onFocusSeat}
          onUnfocusSeat={onUnfocusSeat}
          getTicketPriceListBySeatGroup={getTicketPriceListBySeatGroup}
        />
      );
    });
  }
}

type SeatElementListContainerProps<SE extends SeatEntityType> = {
  isMovingSeat: boolean;
  onClickSeat: OnClickSeatFunction<SE>;
  onFocusSeat: (
    seatId: number,
    participant: CustomerType | null,
    isDesk: boolean
  ) => void;
  onUnfocusSeat: (seatId: number) => void;
  seatEntityList: List<SeatEntityFromType<SE>>;
  selectableSeatIdSet: Set<string>;
  selectedSeatIdSet: Set<number>;
  SeatElement: React.ElementType;
  getTicketPriceListBySeatGroup?: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
};

type SeatElementListProps<SE extends SeatEntityType> =
  SeatElementListContainerProps<SE> & {
    selectedView: View;
    currentDomain: DOMAIN_CONTEXT;
    seatGroupColorMap: Map<string, string | null>;
    seatById: Map<string, SeatType>;
  };

function SeatElementListContainer<SE extends SeatEntityType>(
  props: SeatElementListContainerProps<SE>
): React.ReactElement {
  const seatGroupColorMap = useSeatingSelector(getSeatGroupColorMap);
  const { selectedView } = useViewContext();
  const { currentDomain } = useDomainContext();
  const seatById = useSeatById();

  return (
    <SeatElementList<SE>
      {...props}
      seatById={seatById}
      seatGroupColorMap={seatGroupColorMap}
      selectedView={selectedView}
      currentDomain={currentDomain}
    />
  );
}

export default SeatElementListContainer;
