/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
import {
  createContext,
  useContext,
  ReactElement,
  FunctionComponent,
} from 'react';
import { View } from './ViewContext';

type MultiContextPlanContextProps = {
  elementList: Record<string, ReactElement<unknown, FunctionComponent>>;
  selectedIndex: string;
  hiddenPlanContext: string[];
  onSelect: (name: string) => void;
  selectedView?: View;
  onSelectView?: (view: View) => void;
};

const defaultValue: MultiContextPlanContextProps = {
  elementList: {},
  hiddenPlanContext: [],
  selectedIndex: '',
  onSelect: (name: string): void => {},
};

const MultiContextPlanContext = createContext(defaultValue);

export const useMultiContextPlan = (): MultiContextPlanContextProps =>
  useContext(MultiContextPlanContext);

export default MultiContextPlanContext;
