import { connect } from 'react-redux';
import { Set } from 'immutable';
import SeatingPlanViewer, {
  SeatingPlanViewerProps,
  StateProps,
  DispatchProps,
} from '../../components/Main/SeatingPlanViewer';

import { initSeatingPlanViewer } from '../../actions/AppActions';
import type { RootState } from '../../reducers';
import { isLoadedSeatConfig } from '../../utils/selectors';
import { resetState } from '../../actions/SeatActions';

const getSelectableSeatIdSet = (): Set<string> => Set<string>();

const mapStateToProps = (state: RootState): StateProps => {
  return {
    seatConfig: state.seating.get('seatConfig'),
    isReady: isLoadedSeatConfig(state),
    getSelectableSeatIdSet,
  };
};

const mapDispatchToProps = {
  resetState,
  initSeatingPlanViewer,
};

export default connect<
  StateProps,
  DispatchProps,
  SeatingPlanViewerProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(SeatingPlanViewer);
