import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLongArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 31 30" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M14.042 29.142 1.314 16.414a2 2 0 0 1 0-2.828L14.042.858a2 2 0 0 1 2.828 2.828L7.556 13h23.172v4H7.556l9.314 9.314a2 2 0 1 1-2.828 2.828"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongArrowLeft;
