import { Attribute, ClassMetadata } from 'rest-client-sdk';
import BankAccountClient from '../client/BankAccountClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type BankAccountType = BaseEntity<'BankAccount'> & {
  tag: null | string;
  type: null | string;
  ownerName: null | string;
  addressLine1: null | string;
  addressLine2: null | string;
  city: null | string;
  region: null | string;
  postalCode: null | string;
  addressCountry: null | string;
  iban: null | string;
  wallet: null | string;
  defaultAccount: null | string;
};

class BankAccount extends NetworkEntity<BankAccountType>({
  '@id': null,
  '@type': 'BankAccount',
  tag: null,
  type: null,
  ownerName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  region: null,
  postalCode: null,
  addressCountry: null,
  iban: null,
  wallet: null,
  defaultAccount: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BankAccountType> = {
      '@id': null,
      '@type': 'BankAccount',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/bank_accounts/', '') || '';
  }
}

BankAccount.classMetadata = new ClassMetadata(
  'bankAccount',
  'bank_accounts',
  /** @ts-expect-error -- method signature are incompatible */
  BankAccountClient
);
BankAccount.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('tag'),
  new Attribute('type'),
  new Attribute('ownerName'),
  new Attribute('addressLine1'),
  new Attribute('addressLine2'),
  new Attribute('city'),
  new Attribute('region'),
  new Attribute('postalCode'),
  new Attribute('addressCountry'),
  new Attribute('iban'),
  new Attribute('wallet'),
  new Attribute('defaultAccount'),
]);

export default BankAccount;
