import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 10 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M1 1.5A1.5 1.5 0 0 1 2.5 0h5A1.5 1.5 0 0 1 9 1.5v2.025A1.25 1.25 0 0 0 7.5 4.75V6h-5V4.75A1.25 1.25 0 0 0 1 3.525zM8.984 4H9a1 1 0 0 1 1 1v1H8V5a1 1 0 0 1 .008-.123A1 1 0 0 1 8.984 4m-7.86.008A1 1 0 0 0 0 5v1h1.999V5a1 1 0 0 0-.877-.992M0 6.5h10v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatFilled;
