import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { List, Set } from 'immutable';
import {
  StockNotInContingent,
  EditableStockContingentRow,
  getErrorDescription,
} from '@mapado/js-component';
import {
  assertRelationIsDefined,
  assertRelationIsListOfString,
  assertRelationIsObject,
  StockContingent,
} from 'mapado-ticketing-js-sdk';
import { TOAST_TYPE, useMpdToast } from '@mapado/makeup';
import TicketingSdkInstance from '../../../TicketingSdkInstance';
import {
  resetSelectedSeats,
  selectBatchOfAvailableSeats,
  selectBatchOfSeats,
} from '../../../actions/SeatActions';
import { getEventDateData } from '../../../actions/AppActions';
import { useStockManagement } from '../../../contexts/StockManagementContext';
import { getNotInContingentTotalStock } from '../../../utils/stockCounter';
import {
  useEventDateSelector,
  useSelectedSeatIdSetSelector,
  useStockContingentSelector,
} from '../../../utils/selectors';
import DeleteContingentEntityModal from '../../Modal/DeleteContingentEntityModal';
import { ContingentEntity } from '../../../propTypes';
import { useSeatingDispatch } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

function StockContingentSidebarContent({ onError }: { onError: () => void }) {
  const {
    selectedStockContingent,
    selectStockContingent,
    unselectStockContingent,
    eventDateId,
  } = useStockManagement();
  const { t } = useTranslation();
  const toast = useMpdToast();
  const dispatch = useSeatingDispatch();

  const eventDate = useEventDateSelector();

  assertRelationIsObject(eventDate, 'eventDate');

  const stockContingentList = useStockContingentSelector() || List();
  const selectedSeatIdSet = useSelectedSeatIdSetSelector();

  const [stockContingentToDelete, setStockContingentToDelete] =
    useState<StockContingent | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const totalStockNotInContingent = getNotInContingentTotalStock(eventDate);

  // same count as https://github.com/mapado/desk/blob/491ffacb869a1f7dde7f53ef45f704094b32e948/src/components/cart/StockContingentBar.tsx#L155-L172
  const occupied =
    eventDate.issuedTickets +
    eventDate.bookedTickets -
    stockContingentList.reduce((acc, contingent) => {
      assertRelationIsDefined(
        contingent.bookedTickets,
        'contingent.bookedTickets'
      );

      return acc + contingent.bookedTickets;
    }, 0);

  const modalRoot = document.getElementById('modal-root');

  const reset = () => {
    setIsDeleting(false);
    setModalIsVisible(false);
    setStockContingentToDelete(null);
  };

  const deleteStockContingent = async (stockContingent: ContingentEntity) => {
    setIsDeleting(true);
    unselectStockContingent();

    try {
      await TicketingSdkInstance.getSdk()
        .getRepository('stockContingent')
        .delete(stockContingent as StockContingent);

      dispatch(resetSelectedSeats());
      dispatch(getEventDateData(eventDateId, reset)); // refresh data for not in contingent stock
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      const errorMessage = await getErrorDescription(err);

      toast({
        title: errorMessage,
        type: TOAST_TYPE.ERROR,
      });

      // wait for toast to be close to trigger `onError`
      window.setTimeout(() => {
        onError();
      }, 5000);

      reset();
    }
  };

  const onSelectStockContingent = (idx: number) => {
    const currentStockContingent = stockContingentList?.get(idx);

    if (!currentStockContingent) {
      return;
    }

    if (selectedStockContingent?.['@id'] === currentStockContingent['@id']) {
      unselectStockContingent();
      dispatch(selectBatchOfSeats(Set()));
    } else {
      selectStockContingent(currentStockContingent);

      assertRelationIsDefined(
        currentStockContingent.availableSeatList,
        'selectedStockContingent.availableSeatList'
      );
      assertRelationIsListOfString(
        currentStockContingent.availableSeatList,
        'selectedStockContingent.availableSeatList'
      );

      dispatch(
        selectBatchOfAvailableSeats(currentStockContingent.availableSeatList)
      );
    }
  };

  return (
    <>
      <div className="stock-management-sidebar__content">
        <h3 className="mpd-seating-sidebar-header__title">
          {t('seating.stock_contingent.sidebar.title')}
        </h3>

        {/* Helper message on how to use */}
        {!selectedStockContingent && (
          <p className="mpd-seating-sidebar-header__help">
            {t('seating.stock_contingent.sidebar.help.general')}
          </p>
        )}

        {/* Helper message once an item is selected */}
        {selectedStockContingent &&
          (selectedSeatIdSet.size === 0 ||
            selectedStockContingent.totalStock === null) && (
            <p className="mpd-seating-sidebar-header__help">
              {t('seating.stock_contingent.sidebar.help.select_seats')}
            </p>
          )}

        {totalStockNotInContingent > 0 && (
          <div className="stock-management-sidebar__item">
            <StockNotInContingent
              totalStock={totalStockNotInContingent}
              availableStock={eventDate.notInStockContingentBookableStock}
              bookedStock={occupied}
            />
          </div>
        )}

        {(stockContingentList || List()).map((stockContingent, index) => (
          <EditableStockContingentRow
            key={
              stockContingent.get('@id') ? stockContingent.get('@id') : index
            }
            disabled={
              isDeleting &&
              stockContingentToDelete?.get('@id') === stockContingent.get('@id')
            }
            index={index}
            onDeleteStockContingent={(stock: StockContingent) => {
              setStockContingentToDelete(stock);

              return stock.total
                ? setModalIsVisible(true)
                : deleteStockContingent(stock);
            }}
            stockContingent={stockContingent}
            onSelect={(_, idx) => onSelectStockContingent(idx)}
            isSelected={
              stockContingent['@id'] === selectedStockContingent?.['@id']
            }
            hasEventDate={!!eventDate}
          />
        ))}
      </div>
      {modalRoot &&
        stockContingentToDelete &&
        modalIsVisible &&
        createPortal(
          <DeleteContingentEntityModal
            isDeleting={isDeleting}
            onConfirm={deleteStockContingent}
            onCancel={() => reset()}
            contingentEntity={stockContingentToDelete}
          />,
          modalRoot
        )}
    </>
  );
}

export default StockContingentSidebarContent;
