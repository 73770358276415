import { CartItem, assertRelationIsObject } from 'mapado-ticketing-js-sdk';

// eslint-disable-next-line import/prefer-default-export
export function cartItemIsForEventDate(
  cartItem: CartItem,
  eventDateId: number
): boolean {
  assertRelationIsObject(cartItem.ticketPrice, 'cartItem.ticketPrice');

  const { eventDate } = cartItem.ticketPrice;

  assertRelationIsObject(eventDate, 'cartItem.ticketPrice.eventDate');

  return eventDate.getShortId() === eventDateId.toString();
}
