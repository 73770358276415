import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/deplier-copy" fillRule="nonzero" transform="translate(-3 -3)">
        <path
          id="plus"
          d="M15 27C8.373 27 3 21.627 3 15S8.373 3 15 3s12 5.373 12 12-5.373 12-12 12m-1-13h-3v2h3v3h2v-3h3v-2h-3v-3h-2z"
        />
      </g>
    </g>
  </svg>
);
export default SvgPlus;
