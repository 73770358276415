import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { OptionProps } from './types';
import { useMpdSelectContext } from './context';
import { KEY_CODES, matchKeyEvent } from '../../js-libs/utils';

/**
 * Option component for MpdSelect
 */
function MpdSelectOption({
  className,
  children,
  value,
  disabled,
}: OptionProps): JSX.Element {
  const { selectedValue, onChangeValue, state } = useMpdSelectContext();
  const ref = useRef<HTMLDivElement>(null);
  const isFocused = value === state.focusedValue;
  const isActive = value === selectedValue;

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onChangeValue(value);
  };

  useEffect(() => {
    if (isFocused && state.dropdownAnimationDone) {
      // Focus the dom element when focusedValue variable match with this value component
      ref.current?.focus();
    }
  }, [isFocused, state.dropdownAnimationDone]);

  return (
    <div
      ref={ref}
      className={cn('mpd-select__option', className, {
        'mpd-select__option--selected': isActive,
        'mpd-select__option--disabled': disabled,
      })}
      onClick={handleClick}
      onKeyPress={(event) => {
        // Select the focused value when press ENTER
        if (matchKeyEvent(event.nativeEvent, KEY_CODES.enter)) {
          onChangeValue(state.focusedValue);
        }
      }}
      role="option"
      aria-selected={isActive}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
    >
      {children}
    </div>
  );
}

export default MpdSelectOption;
