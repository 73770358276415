import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeat = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 27 18" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M7 2v9h13V2zm-.5-2A1.5 1.5 0 0 0 5 1.5v4.976a3.2 3.2 0 0 0-1.672-.47C1.457 6.005 0 7.603 0 9.5v.579h1.2c0-.982.741-1.716 1.582-1.716q.098 0 .192.013c.695.09 1.286.686 1.378 1.487q.012.106.013.216v2.916H5V13h17v-.005h.53v-2.916q0-.111.012-.216c.092-.8.683-1.397 1.378-1.487a2 2 0 0 1 .192-.013c.84 0 1.582.734 1.582 1.716h1.2V9.5c0-1.896-1.457-3.495-3.328-3.495-.57 0-1.102.149-1.566.41V1.5A1.5 1.5 0 0 0 20.5 0zm15.794 13.635h2V17a1 1 0 0 1-1 1H3.471a1 1 0 0 1-1-1v-3.365h2V16h17.823z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeat;
