import React, { useEffect, ReactElement, FunctionComponent } from 'react';
import MultiContextPlanContext from '../../contexts/MultiContextPlanContext';

export type MultiContextPlanProps = {
  children: ReactElement<unknown, FunctionComponent>[];
  selectedIndex: string;
  onSelectIndex: (name: string) => void;
  hiddenPlanContext: string[];
};

function MultiContextPlan({
  children,
  hiddenPlanContext,
  selectedIndex,
  onSelectIndex,
}: MultiContextPlanProps): React.ReactElement | null {
  const hasSelectedIndex = !!selectedIndex;

  const elementList: Record<
    string,
    React.ReactElement<unknown, FunctionComponent>
  > = {};

  React.Children.forEach(children, (child) => {
    const childType = child.type;

    if (!childType.displayName) {
      // eslint-disable-next-line no-console
      console.warn(
        'Unable to find displayName for component. You must set it to be displayed in the MultiContextPlan.'
      );
    } else {
      elementList[childType.displayName] = child;
    }
  });

  const elementListKeys = Object.keys(elementList);

  useEffect(() => {
    if (!hasSelectedIndex && elementListKeys.length > 0) {
      onSelectIndex(elementListKeys[0]);
    }
  }, [elementListKeys, hasSelectedIndex, onSelectIndex]);

  if (!selectedIndex || !elementList[selectedIndex]) {
    return null;
  }

  return (
    <MultiContextPlanContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        elementList,
        hiddenPlanContext,
        selectedIndex,
        onSelect: onSelectIndex,
      }}
    >
      {elementList[selectedIndex]}
    </MultiContextPlanContext.Provider>
  );
}

export default MultiContextPlan;
