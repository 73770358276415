import React, {
  Children,
  useCallback,
  useRef,
  ReactNode,
  isValidElement,
  useEffect,
} from 'react';
import handleClassNameChanges from '../hooks/useClassNameChanges';

type Props = {
  children: ReactNode;
};

function MpdLinkTabs({ children }: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectorRef = useRef<HTMLDivElement | null>(null);

  const getSelectorPosition = (selectedItem: HTMLElement): number => {
    const selectedPosition = selectedItem.getBoundingClientRect().left;
    const containerPosition =
      containerRef.current?.getBoundingClientRect().left || 0;

    return selectedPosition - containerPosition;
  };

  const animateSelector = useCallback(() => {
    const container = containerRef.current;
    const selectedItem: HTMLElement | null | undefined = (
      container?.querySelector('.selected') ||
      container?.querySelector('.active')
    )?.closest('.mpd-tabs__tab-label');

    if (selectedItem) {
      const position = getSelectorPosition(selectedItem);
      const selector = selectorRef.current;

      if (!selector) {
        return;
      }

      selector.style.width = `${selectedItem.offsetWidth}px`;
      selector.style.left = `${position < 0 ? 0 : position}px`;
    }
  }, []);

  // Listen container children classNames changes
  if (typeof window !== 'undefined') {
    handleClassNameChanges(containerRef, animateSelector);
  }

  return (
    <div className="mpd-tabs">
      <div ref={containerRef} className="mpd-tabs__tab-container">
        <div ref={selectorRef} className="selector" />
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return child;
          }

          return <Tab animateSelector={animateSelector}>{child}</Tab>;
        })}
      </div>
    </div>
  );
}

type TabProps = {
  children: ReactNode;
  animateSelector: () => void;
};

function Tab({ children, animateSelector }: TabProps) {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined') {
      return;
    }

    const resizeObserver = new ResizeObserver(animateSelector);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, [animateSelector, ref]);

  return (
    <span ref={ref} className="mpd-tabs__tab-label h2-like">
      {children}
    </span>
  );
}

export default MpdLinkTabs;
