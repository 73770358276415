import URI from 'urijs';
import { Iri } from '../entity';
import BookingTrack from '../entity/BookingTrack';
import { assertRelationIsString } from '../errors/errorFactory';
import { TSMetadata } from '../mapping';
import { ShortId } from '../utils';
import AbstractClient, { QueryParam } from './AbstractClient';

class BookingTrackClient extends AbstractClient<TSMetadata['bookingTrack']> {
  getPathBase(pathParameters?: Record<string, unknown>): string {
    if (pathParameters?.iriToClone) {
      return `${pathParameters?.iriToClone}/clone`;
    }

    return '/v1/booking_tracks';
  }

  replaceBookingTrack(id: ShortId, bookingTrack: string): Promise<Response> {
    const url = URI(
      `${this.getPathBase()}/${id}/delete-with-new-booking-track`
    );
    url.addSearch({ fields: '@id' });

    return this.authorizedFetch(url, {
      method: 'POST',
      body: JSON.stringify({
        bookingTrack,
      }),
    });
  }

  reorderFormList(
    id: ShortId,
    customer: Iri<'CustomerFieldContract'>[],
    participant: Iri<'CustomerFieldContract'>[]
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/forms`);

    return this.authorizedFetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        customer,
        participant,
      }),
    });
  }

  clone<Q extends QueryParam>(
    clonedBookingTrack: BookingTrack,
    queryParam: Q,
    pathParameters: Record<string, unknown> = {},
    requestParams: Record<string, unknown> = {}
  ): Promise<BookingTrack> {
    assertRelationIsString(clonedBookingTrack['@id'], 'bookingTrack.@id');

    const iriToClone = clonedBookingTrack['@id'];

    return this.create(
      clonedBookingTrack.set('@id', null),
      queryParam,
      // add iriToClone to pathParameters, this way, we can only call the `create` method, and all should by handled automatically be the SDK
      { ...pathParameters, iriToClone },
      requestParams
    );
  }
}

export default BookingTrackClient;
