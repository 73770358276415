import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Set } from 'immutable';
import { selectBatchOfSeats } from '../actions/SeatActions';
import { TOOL_SELECT_SEAT_FREE_DRAWING } from '../components/const';
import {
  getSelectedSeatIdListHash,
  useSelectedSeatIdSetSelector,
} from '../utils/selectors';
import SvgDrawer from '../utils/SvgDrawer';
import {
  useSeatingDispatch,
  useSeatingSelector,
} from '../reducers/typedFunctions';

function useIsDrawing(): IsDrawingContextType {
  const [isDrawing, setIsDrawing] = useState(false);

  const selectedSeatIdSet = useSelectedSeatIdSetSelector();
  const dispatch = useSeatingDispatch();

  const svgDrawer = useRef(
    new SvgDrawer({
      selectBatchOfSeats: (seatIdList: number[] | Set<number>) =>
        dispatch(selectBatchOfSeats(seatIdList)),
    })
  );

  useEffect(() => {
    if (isDrawing) {
      svgDrawer.current?.setMode(TOOL_SELECT_SEAT_FREE_DRAWING);
      svgDrawer.current?.handleSelectedSeatIdListChange(selectedSeatIdSet);
    } else {
      svgDrawer.current?.setMode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawing]);

  const selectedSeatIdListHash = useSeatingSelector(getSelectedSeatIdListHash);

  useEffect(() => {
    svgDrawer.current?.handleSelectedSeatIdListChange(selectedSeatIdSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatIdListHash]);

  const setSvgDrawerRef = useCallback(
    (ref: HTMLDivElement): void => {
      if (ref) {
        svgDrawer.current?.init();
      }
    },
    [svgDrawer]
  );

  return { isDrawing, setIsDrawing, setSvgDrawerRef };
}

export type IsDrawingContextType = {
  isDrawing: boolean;
  setIsDrawing: (isDrawing: boolean) => void;
  setSvgDrawerRef: (svgDrawer: HTMLDivElement) => void;
};

const DrawingContext = createContext<null | IsDrawingContextType>(null);

type DrawingContainerProps = {
  children: ReactNode;
};

export function DrawingContextProvider({
  children,
}: DrawingContainerProps): JSX.Element {
  const { isDrawing, setIsDrawing, setSvgDrawerRef } = useIsDrawing();

  const contextValue = useMemo(
    () => ({ isDrawing, setIsDrawing, setSvgDrawerRef }),
    [isDrawing, setIsDrawing, setSvgDrawerRef]
  );

  return (
    <DrawingContext.Provider value={contextValue}>
      {children}
    </DrawingContext.Provider>
  );
}

export function useDrawingContext(): IsDrawingContextType {
  const context = React.useContext(DrawingContext);

  if (context === null) {
    throw new Error(
      'useDrawingContext must be used within a DrawingContextProvider'
    );
  }

  return context;
}
