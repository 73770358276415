import { connect } from 'react-redux';
import OrderManagement, {
  StateProps,
  OrderManagementProps,
} from '../../components/Main/OrderManagement';
import type { RootState } from '../../reducers';
import {
  isLoadedAvailableSeatList,
  isLoadedSeatConfig,
} from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => ({
  isReady: isLoadedSeatConfig(state) && isLoadedAvailableSeatList(state),
});

export default connect<StateProps, unknown, OrderManagementProps, RootState>(
  mapStateToProps
)(OrderManagement);
