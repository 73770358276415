import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import { Dispatch, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import type { SeatActionTypes } from './SeatReducer';
import type { RootState } from '.';
import { BookingActionTypes } from '../components/OrderManagement/BookingReducer';

type ActionTypes = SeatActionTypes | BookingActionTypes;

export type AppDispatch = Dispatch<ActionTypes>;

export type SeatingDispatch = Dispatch<ActionTypes>;
type SeatingThunkDispatch = ThunkDispatch<RootState, unknown, ActionTypes>;

type DispatchFunc = () => SeatingDispatch & SeatingThunkDispatch;

export const useSeatingDispatch: DispatchFunc = useDispatch;
export const useSeatingSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useSeatingStore: () => Store<RootState, ActionTypes> = useStore;
