import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class StickerClient extends AbstractClient<TSMetadata['sticker']> {
  getPathBase(): string {
    return '/v1/stickers';
  }
}

export default StickerClient;
