import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPenSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="3 0 26 28" className="mpd-icon" {...props}>
    <path
      id="path-1"
      d="M10 7v1.909H5.167v17.182h18L23.166 16H25v12H3V7zm11.547-5.816s.612-.785 1.4-.17l4.74 3.704s.776.607.164 1.39L14.44 23.272c-.002.005-.009.005-.013.01a.2.2 0 0 1-.048.032q-.024.017-.053.024-.008.003-.016.007l-5.246.913c-.005.001-.01-.002-.016 0a.4.4 0 0 1-.171-.018.6.6 0 0 1-.16-.085q-.016-.009-.03-.016c-.01-.008-.014-.018-.023-.025a.6.6 0 0 1-.121-.134.4.4 0 0 1-.058-.162l-.005-.015-.383-5.31q.001-.009.004-.018.001-.031.01-.059.006-.029.02-.052l.005-.017ZM9.575 19.754l.345 2.808 2.807-.345zm9.85-12.607-8.618 11.032 3.152 2.462L22.578 9.61zm3.08-3.94L20.656 5.57l3.152 2.462 1.847-2.364z"
    />
  </svg>
);
export default SvgPenSquare;
