import thunkMiddleware from 'redux-thunk';
import {
  compose,
  createStore,
  applyMiddleware,
  Store,
  combineReducers,
} from 'redux';
import { bookingReducer } from '@mapado/cart';
import { RootState } from '../../../src/reducers';
import seat from '../../../src/reducers/SeatReducer';

const isDev = process.env.NODE_ENV === 'development';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Function;
  }
}

const rootReducer = combineReducers({
  booking: bookingReducer, // needed in RootState type
  seating: seat,
});

/* eslint-disable no-underscore-dangle */
export default function configureStore(): Store<RootState> {
  const composeEnhancer =
    isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: false })
      : compose;

  const middleWares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleWares);
  const composedEnhancers = composeEnhancer(middlewareEnhancer);

  const store = createStore(rootReducer, undefined, composedEnhancers);

  return store;
}
/* eslint-enable no-underscore-dangle */

export type AppDispatch = Store<RootState>['dispatch'];
