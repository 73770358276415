import { Map } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, Iri, PartialEntity } from '.';

export enum MASS_ACTION_AGGREGATE_NAME {
  TICKET_PRICE_AVAILABILITY = 'ticketPriceAvailability',
}

export enum MASS_ACTION_AGGREGATE_AVAILABILITY {
  ALL = 'all',
  SOME = 'some',
}

type TicketPriceHash = string;

// The value can be a Map if key is Iri<'TicketPriceGroup'> | 'no-group'
// or directly a string from the enum MASS_ACTION_AGGREGATE_AVAILABILITY if the key is Iri<'OfferRule'>.
// It's impossible to type this correctly with Immutable.js so you can use asserts functions to check the type.
export type AggregationBySellingDevice = Map<
  Iri<'TicketPriceGroup'> | 'no-group' | Iri<'OfferRule'>,
  AggregationValue
>;

export type AggregationValue =
  | Map<TicketPriceHash, MASS_ACTION_AGGREGATE_AVAILABILITY>
  | MASS_ACTION_AGGREGATE_AVAILABILITY;

export function assertAggregationValueIsOfferRule(
  d: AggregationValue
): asserts d is MASS_ACTION_AGGREGATE_AVAILABILITY {
  if (
    !Map.isMap(d) &&
    Object.values(MASS_ACTION_AGGREGATE_AVAILABILITY).includes(d)
  ) {
    return;
  }

  throw new Error(
    'The value should be exist in MASS_ACTION_AGGREGATE_AVAILABILITY enum. This should not happen.'
  );
}

export function assertAggregationValueIsTicketPriceGroup(
  d: AggregationValue
): asserts d is Map<TicketPriceHash, MASS_ACTION_AGGREGATE_AVAILABILITY> {
  if (Map.isMap(d)) {
    return;
  }

  throw new Error(
    'The value should be an Immutable.Map. This should not happen.'
  );
}

export type AggregationResult = Map<
  Iri<'SellingDevice'> | 'all',
  AggregationBySellingDevice
>;

export type MassActionAggregateType = BaseEntity<'MassActionAggregate'> & {
  name?: MASS_ACTION_AGGREGATE_NAME.TICKET_PRICE_AVAILABILITY;
  aggregationParams?: Map<string, unknown>;
  aggregationResult?: AggregationResult;
};

class MassActionAggregate extends NetworkEntity<MassActionAggregateType>({
  '@id': null,
  '@type': 'MassActionAggregate',
  name: undefined,
  aggregationParams: undefined,
  aggregationResult: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<MassActionAggregateType> = {
      '@id': null,
      '@type': 'MassActionAggregate',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/mass_action_aggregates/', '') || '';
  }
}

MassActionAggregate.classMetadata = new ClassMetadata(
  'massActionAggregate',
  'mass_action_aggregates',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
MassActionAggregate.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('aggregationParams', 'aggregationParams', 'object'),
  new Attribute('aggregationResult', 'aggregationResult', 'object'),
]);

export default MassActionAggregate;
