import * as React from 'react';
import type { SVGProps } from 'react';
const SvgXCards = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 55 41" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M45.12 0H2.88C2.116.001 1.384.339.844.938.304 1.538.001 2.351 0 3.2v25.6c0 .848.305 1.662.844 2.262.54.6 1.272.937 2.036.938H4v-2.133H2.88c-.53 0-.96-.478-.96-1.067V3.2c0-.59.43-1.066.96-1.067h42.24c.53 0 .96.478.96 1.067V5H48V3.2c0-.849-.305-1.662-.844-2.262-.54-.6-1.272-.937-2.036-.938M29.416 29.128l1.031 1.128-1.128 1.032 1.032 1.128-1.128 1.031-1.032-1.128-1.128 1.032-1.031-1.128 1.128-1.032-1.032-1.128 1.128-1.031 1.032 1.128zm-6.186-.648-2.505-2.74-2.74 2.505-2.505-2.74-2.74 2.505 2.505 2.74-2.74 2.505 2.505 2.74 2.74-2.505 2.505 2.74 2.74-2.505-2.505-2.74zM9.88 9h42.24c.764 0 1.496.339 2.036.938.54.6.843 1.413.844 2.262v25.6c0 .849-.305 1.662-.844 2.262-.54.6-1.272.937-2.036.938H9.88c-.764 0-1.496-.339-2.036-.938A3.4 3.4 0 0 1 7 37.8V12.2c0-.848.305-1.662.844-2.262.54-.6 1.272-.937 2.036-.938m42.24 29.867c.53 0 .96-.478.96-1.067V12.2c0-.589-.43-1.066-.96-1.067H9.88c-.53 0-.96.478-.96 1.067v25.6c0 .589.43 1.066.96 1.067zm-11.615-11.96a4.8 4.8 0 0 1 2.81-.906c1.506-.001 2.92.694 3.8 1.867a4.35 4.35 0 0 1 .644 4.053c-.476 1.372-1.61 2.44-3.048 2.87a4.84 4.84 0 0 1-4.207-.697 4.83 4.83 0 0 1-3.255.887 4.76 4.76 0 0 1-3.028-1.45A4.4 4.4 0 0 1 33 30.5c0-1.121.436-2.202 1.222-3.03a4.76 4.76 0 0 1 3.028-1.45 4.83 4.83 0 0 1 3.255.887m-1.77 4.49a3.815 3.815 0 0 1 0-1.795l.006-.025q.06-.272.156-.536l.022-.063q.018-.056.04-.11.125-.315.298-.608a2.9 2.9 0 0 0-1.93-.437c-.677.084-1.3.402-1.75.895s-.7 1.126-.7 1.782.25 1.29.7 1.782c.45.493 1.073.812 1.75.895a2.9 2.9 0 0 0 1.93-.437 4.5 4.5 0 0 1-.299-.608q-.022-.056-.04-.112l-.02-.06a4 4 0 0 1-.157-.537zm2.595 1.01c.527.506 1.24.79 1.985.793a2.84 2.84 0 0 0 2.361-1.234c.518-.77.594-1.74.201-2.575s-1.2-1.422-2.144-1.558a2.87 2.87 0 0 0-2.525.886 3 3 0 0 0-.3.396 2.7 2.7 0 0 0-.357.903 2.5 2.5 0 0 0-.047.482c.002.716.3 1.401.826 1.907M27.364 16H14.636c-.351 0-.636.672-.636 1.5s.285 1.5.636 1.5h12.728c.351 0 .636-.672.636-1.5s-.285-1.5-.636-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgXCards;
