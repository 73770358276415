import React, { ReactElement, useEffect, useState } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  align?: 'center' | 'right';
  size?: 'xs' | 'l' | 'xl';
};

function MpdLoader({ className, align, size }: Props): ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const loaderClassNames = cn(
    'mpd-loader',
    !isVisible && 'mpd-loader--hidden',
    size && `mpd-loader--size-${size}`
  );
  const hasContainerClassName = !!className;

  useEffect(() => {
    const currentTimeout = window.setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => {
      window.clearTimeout(currentTimeout || 0);
    };
  }, []);

  if (align || hasContainerClassName) {
    const containerClassNames = cn(
      'mpd-loader-container',
      className,
      align && `mpd-loader-container--${align}`
    );

    return (
      <div className={containerClassNames}>
        <div className={loaderClassNames} />
      </div>
    );
  }

  return <div className={loaderClassNames} />;
}

export default MpdLoader;
