import React from 'react';
import { getAvailableSeatListFromCart } from '../../utils/memoized';
import { getIcon } from '../../utils/seatStyle';
import { useCartContext } from '../CartReplacement/CartContext';
import BaseSeat, { SeatProps } from './BaseSeat';

const CartReplacementSeat = React.memo(
  ({
    color,
    isSelectable,
    isSelected,
    bookingStatus,
    seatEntity,
    ...rest
  }: Props) => {
    const cart = useCartContext();

    const inBooking = !!(
      cart &&
      getAvailableSeatListFromCart(cart).find((availableSeat) => {
        return availableSeat['@id'] === seatEntity['@id'];
      })
    );

    return (
      <BaseSeat<'AvailableSeat'>
        {...rest}
        seatEntity={seatEntity}
        isSelectable={isSelectable}
        bookingStatus={bookingStatus}
        icon={getIcon(bookingStatus, inBooking)}
        fill={color}
        isSelected={isSelected || inBooking}
        isZoomed={isSelected}
      />
    );
  }
);

CartReplacementSeat.displayName = 'CartReplacementSeat';

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
};

export default CartReplacementSeat;
