import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import LibPhoneNumber from 'google-libphonenumber';
import { GoogleLibPhoneNumberInstance } from '@mapado/js-component';
import Demo from './Demo';

import '@mapado/makeup/styles/index.css';
import '@mapado/js-component/dist/index.css';
import './style.css';

GoogleLibPhoneNumberInstance.setGoogleLibPhoneNumber(LibPhoneNumber);

render(<Demo />, document.getElementById('demo'));
