import React, { ReactElement } from 'react';
import BaseSeat, { SeatProps } from './BaseSeat';
import { getIcon } from '../../utils/seatStyle';

function StockManagementSeat({
  isSelected,
  isSelectable,
  seatId,
  bookingStatus,
  color,
  ...rest
}: Props): ReactElement {
  return (
    <BaseSeat<'AvailableSeat'>
      {...rest}
      seatId={seatId}
      isSelectable={isSelectable}
      bookingStatus={bookingStatus}
      displayDismissedSeat
      icon={getIcon(bookingStatus, false)}
      fill={color}
      isSelected={isSelected}
    />
  );
}

type Props = SeatProps<'AvailableSeat'> & {
  seatId: number;
  color: string;
};

StockManagementSeat.displayName = 'StockManagementSeat';

export default React.memo(StockManagementSeat);
