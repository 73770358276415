import { useCallback, useEffect, useState } from 'react';
import {
  AvailableSeat,
  Order,
  Ticket,
  assertRelationIsListOfObject,
  assertRelationIsNullOrObject,
} from 'mapado-ticketing-js-sdk';
import {
  filterOrderOnEventDate,
  ORDER_FIELDS_FOR_BOOKING,
} from './OrderManagement/BookingActions';
import TicketingSdkInstance from '../TicketingSdkInstance';
import { autoSelectAvailableSeatList } from '../actions/AppActions';
import { getAvailableSeatListFromOrder } from '../utils/memoized';
import { ReplaceAvailableSeatFunction } from './useCart';
import { useSeatingDispatch } from '../reducers/typedFunctions';

export async function getSingleOrder(
  orderId: number | string,
  eventDateId: number | string
): Promise<Order> {
  const sdk = TicketingSdkInstance.getSdk();

  return sdk
    .getRepository('order')
    .find(orderId, {
      fields: ORDER_FIELDS_FOR_BOOKING,
    })
    .then((order) => {
      const filteredOrder = filterOrderOnEventDate(
        order,
        `/v1/event_dates/${eventDateId}`
      );

      return filteredOrder;
    });
}

type UseOrderReturnType = [Order | null, ReplaceAvailableSeatFunction];

export default function useOrder(
  orderId: number,
  eventDateId: number
): UseOrderReturnType {
  const [order, setOrder] = useState<Order | null>(null);
  const dispatch = useSeatingDispatch();

  useEffect(() => {
    getSingleOrder(orderId, eventDateId).then((fetchedOrder) => {
      const availableSeatList = getAvailableSeatListFromOrder(fetchedOrder);

      // @ts-expect-error AvailableSeatType and AvailableSeat are the same
      dispatch(autoSelectAvailableSeatList(availableSeatList));

      // keep setOrder at last to be sure that all the dispatch are done
      setOrder(fetchedOrder);
    });
  }, [orderId, dispatch, eventDateId]);

  const replaceAvailableSeat = useCallback(
    (oldAvailableSeat: AvailableSeat, newAvailableSeat: AvailableSeat) => {
      if (!order) {
        return;
      }

      const newOrder = order.update('ticketList', (ticketList) => {
        assertRelationIsListOfObject(ticketList, 'ticketList');

        return ticketList.map((ticket: Ticket): Ticket => {
          return ticket.update('availableSeat', (availableSeat) => {
            assertRelationIsNullOrObject(availableSeat, 'ticket.availableSeat');

            if (
              availableSeat &&
              availableSeat['@id'] === oldAvailableSeat['@id']
            ) {
              return newAvailableSeat;
            }

            return availableSeat;
          });
        });
      });

      setOrder(newOrder);
    },
    [order]
  );

  return [order, replaceAvailableSeat];
}
