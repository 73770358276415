export default function findTranslateXY(transformAttribute: string): {
  x: number;
  y: number;
} {
  const str = transformAttribute
    .replace('translate(', '')
    .replace(')', '')
    .replace(' ', '');

  const xyAttrs = str.split(',');
  const currentX = parseInt(xyAttrs[0], 10);
  const currentY = parseInt(xyAttrs[1], 10);

  return {
    x: currentX,
    y: currentY,
  };
}
