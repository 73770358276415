import clone from 'clone';
import { List, Map, MapOf } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import OrderClient from '../client/OrderClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import {
  assertRelationIsListOfObject,
  assertRelationIsObject,
} from '../errors/errorFactory';
import { FormCustomerField, FormData } from '../types/FormDataType';
import { parseDate } from '../utils/date';
import { getFormData } from '../utils/formData';
import Billing from './Billing';
import Booking from './Booking';
import Cart from './Cart';
import Contract from './Contract';
import Coupon from './Coupon';
import Customer from './Customer';
import Invoice, { INVOICE_TYPE } from './Invoice';
import NetworkEntity from './NetworkEntity';
import OrderItem from './OrderItem';
import OrderItemInvoiceGroup from './OrderItemInvoiceGroup';
import OrganizationalUnit from './OrganizationalUnit';
import Payment from './Payment';
import PaymentAttempt from './PaymentAttempt';
import SellingDevice from './SellingDevice';
import Shipping from './Shipping';
import Subscription from './Subscription';
import Tag from './Tag';
import Ticket from './Ticket';
import Transaction from './Transaction';
import Wallet from './Wallet';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const STATUS_PAYED = 'payed';
export const STATUS_REFUNDED_TOTALLY = 'refunded_totally';
export const STATUS_REFUNDED_PARTIALLY = 'refunded_partially';
export const STATUS_DESCRIPTION_CANCELLED = 'ticketing.order.cancelled';
export const STATUS_FAILED = 'failed';
export const STATUS_WAITING_3DS = 'waiting_3ds';

export type OrderType = BaseEntity<'Order'> & {
  comment: null | string;
  status: null | string;
  statusDescription: null | string;
  currency: null | string;
  downloadId: null | string;
  payId: null | string;
  description: null | string;
  publicData: null | Map<string, unknown>;
  customer: null | EntityRelation<Customer>;
  contact: null | EntityRelation<Customer>;
  orderItemList: null | List<EntityRelation<OrderItem>>;
  orderItemInvoiceGroupList: null | List<OrderItemInvoiceGroup>;
  organizationalUnitList: null | List<EntityRelation<OrganizationalUnit>>;
  ticketList: null | List<EntityRelation<Ticket>>;
  soldTicketQuantity: null | number;
  nbTickets: null | number;
  nbCoupons: null | number;
  payment: null | EntityRelation<Payment>;
  contract: null | EntityRelation<Contract>;
  updatedAt: null | Moment;
  createdAt: null | Moment;
  createdCouponList: null | List<EntityRelation<Coupon>>;
  cart: null | EntityRelation<Cart>;
  customerPaidAmount: null | number;
  invoice: null | EntityRelation<Invoice>;
  invoiceList: null | List<EntityRelation<Invoice>>;
  bookingList: null | List<EntityRelation<Booking>>;
  originType: null | string;
  originId: null | string;
  customerRequiredFields: null | List<Map<string, unknown>>;
  sellingDevice: null | EntityRelation<SellingDevice>;
  sellingDeviceForPayment: null | EntityRelation<SellingDevice>;
  transactionBalance: null | number;
  actionnableTransactionBalance: null | number;
  bankTransactionBalance: null | number;
  bankRefundTransactionBalance: null | number;
  balance: null | number;
  externalBalanceForOrder: null | number;
  retailValue: null | number;
  currentRetailValue: null | number;
  autoRefundable: null | boolean;
  nonRevertedPaymentTransactionList: null | List<EntityRelation<Transaction>>;
  externalActionnableTransactionList: null | List<EntityRelation<Transaction>>;
  actionnableTransactionList: null | List<EntityRelation<Transaction>>;
  tagList: null | List<EntityRelation<Tag>>;
  orderOnlyFeeAmount: null | number;
  paymentStatus: null | string;
  refundTransactionName: null | string;
  paymentTransactionName: null | string;
  rescheduledFromOrder: null | EntityRelation<Order>;
  isSubscriptionOrder: null | boolean;
  subscriptionList: null | List<EntityRelation<Subscription>>;
  childrenList: null | List<EntityRelation<Order>>;
  parent: null | Order;
  autoGeneratedPaymentTransactionAmount: null | number;
  isSettled: null | boolean;
  isPaymentLinkOrder: null | boolean;
  paymentLink: null | string;
  shipping: null | EntityRelation<Shipping>;
  billing: null | EntityRelation<Billing>;
  wallet: null | EntityRelation<Wallet>;
  paymentAttemptList: null | List<EntityRelation<PaymentAttempt>>;
  /**
   * formData is a helper that does not exist in the API, but help the front to handle customer and organization form data
   */
  formData: null | MapOf<FormData>;
};

class Order extends NetworkEntity<OrderType>({
  '@id': null,
  '@type': 'Order',
  comment: null,
  status: '',
  statusDescription: null,
  currency: '',
  downloadId: '',
  payId: null,
  description: '',
  publicData: Map<string, unknown>(),
  customer: new Customer(),
  contact: null,
  orderItemList: List<EntityRelation<OrderItem>>(),
  orderItemInvoiceGroupList: null,
  organizationalUnitList: null,
  ticketList: List<EntityRelation<Ticket>>(),
  soldTicketQuantity: 0,
  nbTickets: null,
  nbCoupons: null,
  payment: new Payment(),
  contract: new Contract(),
  updatedAt: null,
  createdAt: null,
  createdCouponList: null,
  cart: new Cart(),
  customerPaidAmount: null,
  invoice: null,
  invoiceList: List<Invoice>(),
  bookingList: List<Booking>(),
  originType: null,
  originId: null,
  customerRequiredFields: null,
  sellingDevice: null,
  transactionBalance: null,
  bankTransactionBalance: null,
  actionnableTransactionBalance: null,
  bankRefundTransactionBalance: null,
  balance: null,
  externalBalanceForOrder: null,
  retailValue: null,
  currentRetailValue: null,
  autoRefundable: null,
  nonRevertedPaymentTransactionList: null,
  actionnableTransactionList: List<Transaction>(),
  externalActionnableTransactionList: List<Transaction>(),
  tagList: List<Tag>(),
  orderOnlyFeeAmount: null,
  paymentStatus: null,
  refundTransactionName: null,
  paymentTransactionName: null,
  rescheduledFromOrder: null,
  isSubscriptionOrder: null,
  subscriptionList: null,
  childrenList: List<Order>(),
  parent: null,
  autoGeneratedPaymentTransactionAmount: null,
  isSettled: null,
  isPaymentLinkOrder: null,
  sellingDeviceForPayment: null,
  paymentLink: null,
  shipping: null,
  billing: null,
  wallet: null,
  formData: null,
  paymentAttemptList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrderType> = { '@id': null, '@type': 'Order' }
  ) {
    const data = clone(val);

    const tz =
      extractTimezone(data, ['contract', 'timezone']) ||
      extractTimezone(data, ['orderItemList', 0, 'ticketing', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);
    data.formData = getFormData(data);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/orders/', '') || '';
  }

  /** @deprecated -- use `createdAt` directly */
  getLocalCreatedAt(): null | Moment {
    if (this.orderItemList && this.orderItemList.size > 0) {
      const tz = this.orderItemList.getIn([0, 'ticketing', 'timezone']);

      /** @ts-expect-error -- conflict between moment and moment-timezone */
      return this.createdAt?.clone().tz(tz);
    }

    // if no orderItems, fallback to utc time
    return this.createdAt;
  }

  /** @deprecated -- use `updatedAt` directly */
  getLocalUpdatedAt(): null | Moment {
    if (this.orderItemList && this.orderItemList.size > 0) {
      const tz = this.orderItemList.getIn([0, 'ticketing', 'timezone']);

      /** @ts-expect-error -- conflict between moment and moment-timezone */
      return this.updatedAt.clone().tz(tz);
    }

    // if no orderItems, fallback to utc time
    return this.updatedAt;
  }

  getSplittedInvoiceCount(): number {
    if (this.invoiceList && this.invoiceList.size > 0) {
      return this.invoiceList.filter((invoice) => {
        if (typeof invoice === 'object') {
          assertRelationIsObject(invoice, 'invoice');

          return invoice.type === INVOICE_TYPE.SPLIT_CANCEL_PAYMENT;
        }

        return false;
      }).size;
    }

    return 0;
  }

  getGdprMessage() {
    assertRelationIsListOfObject(this.organizationalUnitList);
    const gdprOptinMessage = this.organizationalUnitList
      .map((organizationUnit) => organizationUnit.get('gdprOptinMessage'))
      .filter((message) => !!message && message.length > 0)
      .toSet()
      .join(', ');

    return gdprOptinMessage && gdprOptinMessage.length > 0
      ? gdprOptinMessage
      : null;
  }

  getFormCustomerFields(
    disabledFields = false,
    optionalFields?: boolean
  ): FormCustomerField[] {
    if (!this.customerRequiredFields) {
      throw new Error(
        'CustomerRequiredFields must be defined. Please check your request fields.'
      );
    }

    const customerRequiredFields = this.customerRequiredFields.toJS() as FormCustomerField[];
    const formattedFieldList = customerRequiredFields.map(
      (field: FormCustomerField) => {
        const fieldWithFormattedKey = Object.keys(field).reduce((acc, key) => {
          const formattedKey = key.startsWith('form')
            ? key.replace('form', '').toLowerCase()
            : key;

          return { ...acc, [formattedKey]: field[key] };
        }, field);

        const fieldOptions = field.options
          ? {
              ...field.options,
              inputProps: {
                ...field.options.inputProps,
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              options: field.options.options,
              isRequired: optionalFields ? false : field.required,
            }
          : {
              inputProps: {
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              isRequired: optionalFields ? false : field.required,
            };

        return {
          ...fieldWithFormattedKey,
          label: field.slug?.includes('optinEmailInfo')
            ? this.getGdprMessage()
            : field.label,
          name: field.slug,
          options: fieldOptions,
        };
      }
    );

    return formattedFieldList;
  }
}

/** @ts-expect-error -- method signature are incompatible */
Order.classMetadata = new ClassMetadata('order', 'orders', OrderClient);
Order.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('status'),
  new Attribute('paymentStatus'),
  new Attribute('refundTransactionName'),
  new Attribute('paymentTransactionName'),
  new Attribute('statusDescription'),
  new Attribute('currency'),
  new Attribute('downloadId'),
  new Attribute('payId'),
  new Attribute('description'),
  new Attribute('comment'),
  new Attribute('currentRetailValue', 'currentRetailValue', 'integer'),
  new Attribute('retailValue', 'retailValue', 'integer'),
  new Attribute('soldTicketQuantity', 'soldTicketQuantity', 'integer'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('customerPaidAmount', 'customerPaidAmount', 'integer'),
  new Attribute('originType'),
  new Attribute('originId'),
  new Attribute(
    'autoGeneratedPaymentTransactionAmount',
    'autoGeneratedPaymentTransactionAmount',
    'integer'
  ),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('publicData', 'publicData', 'object'),
  new Attribute('balance', 'balance', 'integer'),
  new Attribute(
    'externalBalanceForOrder',
    'externalBalanceForOrder',
    'integer'
  ),
  new Attribute('orderOnlyFeeAmount', 'orderOnlyFeeAmount', 'integer'),
  new Attribute('transactionBalance', 'transactionBalance', 'integer'),
  new Attribute('bankTransactionBalance', 'bankTransactionBalance', 'integer'),
  new Attribute(
    'actionnableTransactionBalance',
    'actionnableTransactionBalance',
    'integer'
  ),
  new Attribute(
    'bankRefundTransactionBalance',
    'bankRefundTransactionBalance',
    'integer'
  ),
  new Attribute('autoRefundable', 'autoRefundable', 'boolean'),
  new Attribute('isSubscriptionOrder', 'isSubscriptionOrder', 'boolean'),

  new Attribute('nbTickets', 'nbTickets', 'integer'),
  new Attribute('nbCoupons', 'nbCoupons', 'integer'),
  new Attribute('isSettled', 'isSettled', 'boolean'),
  new Attribute('isPaymentLinkOrder', 'isPaymentLinkOrder', 'boolean'),
  new Attribute('paymentLink'),
]);

Order.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'customer', 'customer'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'contact'),
  new Relation(Relation.ONE_TO_MANY, 'orderItem', 'orderItemList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'orderItemInvoiceGroup',
    'orderItemInvoiceGroupList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.ONE_TO_MANY, 'booking', 'bookingList'),
  new Relation(Relation.ONE_TO_MANY, 'tag', 'tagList'),
  new Relation(Relation.MANY_TO_ONE, 'payment', 'payment'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'cart', 'cart'),
  new Relation(Relation.ONE_TO_ONE, 'order', 'parent'),
  new Relation(Relation.MANY_TO_ONE, 'invoice', 'invoice'),
  new Relation(Relation.ONE_TO_MANY, 'invoice', 'invoiceList'),
  new Relation(Relation.ONE_TO_MANY, 'subscription', 'subscriptionList'),
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
  new Relation(
    Relation.MANY_TO_ONE,
    'sellingDevice',
    'sellingDeviceForPayment'
  ),
  new Relation(Relation.ONE_TO_MANY, 'order', 'childrenList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'transaction',
    'nonRevertedPaymentTransactionList'
  ),
  new Relation(
    Relation.ONE_TO_MANY,
    'transaction',
    'actionnableTransactionList'
  ),
  new Relation(
    Relation.ONE_TO_MANY,
    'transaction',
    'externalActionnableTransactionList'
  ),
  new Relation(Relation.MANY_TO_ONE, 'order', 'rescheduledFromOrder'),
  new Relation(Relation.MANY_TO_ONE, 'shipping', 'shipping'),
  new Relation(Relation.MANY_TO_ONE, 'billing', 'billing'),
  new Relation(Relation.ONE_TO_MANY, 'coupon', 'createdCouponList'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
  new Relation(
    Relation.ONE_TO_MANY,
    'organizationalUnit',
    'organizationalUnitList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'paymentAttempt', 'paymentAttemptList'),
]);

export default Order;
