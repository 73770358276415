import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="4 3 22 22" className="mpd-icon" {...props}>
    <path
      id="path-1"
      d="M17.568 3C19.187 3 20.5 4.284 20.5 5.889c0 .837-.357 1.586-.928 2.112L26 8v5h-2v12H6V13H4V8l6.928.001A2.86 2.86 0 0 1 10 5.889C10 4.284 11.313 3 12.932 3c.943 0 1.783.436 2.32 1.118A2.93 2.93 0 0 1 17.567 3M8 23h6V13H8zm14-10h-6v10h6zm-8-2v-1H6v1zm-1.068-6c-.524 0-.932.399-.932.889 0 .455.352.831.821.883l.11.006.932-.001v-.888a.89.89 0 0 0-.706-.864l-.115-.019zm4.636 0c-.487 0-.873.344-.926.785l-.006.104v.888h.932a.92.92 0 0 0 .906-.676l.02-.109.006-.103c0-.49-.408-.889-.932-.889M24 11v-1h-8v1z"
    />
  </svg>
);
export default SvgGift;
