import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderTicketingMappingClient extends AbstractClient<
  TSMetadata['providerTicketingMapping']
> {
  getPathBase(): string {
    return '/v1/provider_ticketing_mappings';
  }
}

export default ProviderTicketingMappingClient;
