import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderContingentMappingClient from '../../../client/ProviderContingentMappingClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import Contingent from '../../Contingent';
import NetworkEntity from '../../NetworkEntity';
import { ProviderTicketMappingType } from './ProviderTicketMappingType';

export type ProviderContingentMappingType = BaseEntity<'ProviderContingentMapping'> &
  ProviderTicketMappingType & {
    contingent?: EntityRelation<Contingent> | null;
  };

class ProviderContingentMapping extends NetworkEntity<ProviderContingentMappingType>(
  {
    '@id': null,
    '@type': 'ProviderContingentMapping',
    contract: undefined,
    contingent: undefined,
    label: undefined,
    isMapped: undefined,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderContingentMappingType> = {
      '@id': null,
      '@type': 'ProviderContingentMapping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_contingent_mappings/', '') || ''
    );
  }
}

ProviderContingentMapping.classMetadata = new ClassMetadata(
  'providerContingentMapping',
  'provider_contingent_mappings',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderContingentMappingClient
);
ProviderContingentMapping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('provider'),
  new Attribute('label'),
  new Attribute('isMapped', 'isMapped', 'boolean'),
]);
ProviderContingentMapping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'contingent', 'contingent'),
]);

export default ProviderContingentMapping;
