import React from 'react';
import { getAvailableSeatListFromOrder } from '../../utils/memoized';
import { getIcon } from '../../utils/seatStyle';
import BaseSeat, { SeatProps } from './BaseSeat';
import { useOrderContext } from '../OrderReplacement/OrderContext';

const OrderReplacementSeat = React.memo(
  ({
    color,
    isSelectable,
    isSelected,
    bookingStatus,
    seatEntity,
    ...rest
  }: Props) => {
    const order = useOrderContext();

    const inBooking = !!(
      order &&
      getAvailableSeatListFromOrder(order).find((availableSeat) => {
        return availableSeat['@id'] === seatEntity['@id'];
      })
    );

    return (
      <BaseSeat<'AvailableSeat'>
        {...rest}
        seatEntity={seatEntity}
        isSelectable={isSelectable}
        bookingStatus={bookingStatus}
        icon={getIcon(bookingStatus, inBooking)}
        fill={color}
        isSelected={isSelected || inBooking}
        isZoomed={isSelected}
      />
    );
  }
);

OrderReplacementSeat.displayName = 'OrderReplacementSeat';

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
};

export default OrderReplacementSeat;
