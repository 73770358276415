import React, { ReactNode, ReactElement } from 'react';
import cn from 'classnames';
import { useMultiContextPlan } from '../../contexts/MultiContextPlanContext';
import { TFunction, useTranslation } from '../../i18n';

const getElementName = (t: TFunction, name: string): string => {
  switch (name) {
    case 'CartReplacement':
      return t('seating_plan.toolbar.CartReplacement');
    case 'OrderReplacement':
      return t('seating_plan.toolbar.OrderReplacement');
    case 'OrderViewer':
      return t('seating_plan.toolbar.OrderViewer');
    case 'SeatingPlanViewer':
      return t('seating_plan.toolbar.SeatingPlanViewer');
    case 'OrderManagement':
      return t('seating_plan.toolbar.OrderManagement');
    case 'SeatingPlanPurchase':
      return t('seating_plan.toolbar.SeatingPlanPurchase');
    default:
      // eslint-disable-next-line no-console
      console.error(`Unable to get name for component ${name}.`);

      return name;
  }
};

type BottomToolbarProps = {
  actionButtons?: ReactNode;
};

function BottomToolbar({
  actionButtons = null,
}: BottomToolbarProps): ReactElement {
  const { t } = useTranslation<'main'>();
  const { elementList, hiddenPlanContext, selectedIndex, onSelect } =
    useMultiContextPlan();

  const elementListKeys = Object.keys(elementList).filter(
    (key) => !hiddenPlanContext.includes(key)
  );

  return (
    <div className="mpd-seating__bottom-toolbar">
      {elementListKeys.length > 0 && (
        <div className="mpd-btn-group">
          {elementListKeys.map((elementName) => (
            <button
              key={elementName}
              type="button"
              className={cn('mpd-btn', {
                selected: elementName === selectedIndex,
              })}
              onClick={(): void => onSelect(elementName)}
            >
              {getElementName(t, elementName)}
            </button>
          ))}
        </div>
      )}
      {actionButtons}
    </div>
  );
}

export default BottomToolbar;
