import { ReactNode } from 'react';

export enum TOAST_TYPE {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export type Toast = {
  title: string;
  message?: string;
  type?: TOAST_TYPE;
  // must be a Link (priority for accessibility purposes) or a button
  action?: ReactNode;
};

export enum TOAST_DISAPEAR_TIME {
  DEFAULT = 5000,
  ACTION = 8000,
}
