import '../../styles/components/App.scss';
import '../../styles/components/SeatGroupSidebar.scss';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { List, Set } from 'immutable';
import {
  Cart,
  TicketPrice,
  AvailableSeat,
  assertRelationIsListOfObject,
  assertRelationIsObject,
  Seat,
  assertRelationIsDefined,
  StockContingent,
  CART_TYPE,
  assertRelationIsNullOrString,
} from 'mapado-ticketing-js-sdk';
import {
  useCurrentCart,
  TicketPriceQuantityParameter,
  useLockSeatsForEventDate,
  useSetTicketPriceQuantity,
  SHOPPING_CART,
} from '@mapado/cart';
import { DOMAIN_CONTEXT, useDomainContext } from '@mapado/js-component';
import SeatingLoader from '../SeatingLoader';
import Seating from '../Seating';
import {
  AvailableSeatType,
  NoGroupSeatType,
  SeatConfigType,
  SeatGroupType,
} from '../../propTypes';
import { getSelectableSeatIdSetForSeatingPlanPurchase } from '../../utils/seatSelectable';
import DrawButton from '../Toolbar/DrawButton';
import { RootState } from '../../reducers';
import TicketingSdkInstance from '../../TicketingSdkInstance';
import SeatingPlanPurchaseSidebar from '../Sidebar/SeatingPlanPurchaseSidebar';
import SeatingPlanPurchaseSeat from '../Seat/SeatingPlanPurchaseSeat';
import { CurrentContingentContextProvider } from '../CurrentContingentContext';
import SeatGroupList from '../SeatGroupList';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import { cartItemIsForEventDate } from '../../utils/cart';
import {
  IsDrawingContextType,
  useDrawingContext,
} from '../../contexts/DrawingContext';
import useReloadOnBookingError from '../../utils/useReloadOnBookingError';

type State = {
  eventDateTicketPriceList: List<TicketPrice>;
  isLoading: boolean;
  removedAvailableSeatCartList: List<AvailableSeatType>;
};

/**
 * Get all available seat of a Cart
 */
function getAvailableSeatListFromCart(
  cart: Cart | null,
  eventDateId: number
): List<AvailableSeat> {
  if (!cart) {
    return List();
  }

  assertRelationIsListOfObject(cart.cartItemList, 'cart.cartItemList');

  const cartItemList = cart.cartItemList.filter((cartItem) =>
    cartItemIsForEventDate(cartItem, eventDateId)
  );

  return (
    cartItemList
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((cartItem): AvailableSeat | undefined => {
        assertRelationIsListOfObject(
          cartItem.availableSeatList,
          'cartItem.availableSeatList'
        );

        const firstAvailableSeat = cartItem.availableSeatList.first();

        if (firstAvailableSeat) {
          assertRelationIsObject(
            firstAvailableSeat.seat,
            'cartItem.availableSeatList.seat'
          );

          return firstAvailableSeat;
        }
      })
      .filter(
        (availableSeat): availableSeat is AvailableSeat =>
          typeof availableSeat !== 'undefined'
      )
  );
}

/**
 * Get all selected seat from cart
 */
function getSelectedSeatFromCart(
  cart: Cart | null,
  eventDateId: number
): List<Seat> {
  return getAvailableSeatListFromCart(cart, eventDateId).map(
    (availableSeat) => {
      assertRelationIsObject(
        availableSeat.seat,
        'cartItem.availableSeatList.seat'
      );

      return availableSeat.seat;
    }
  );
}

class SeatingPlanPurchase extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      eventDateTicketPriceList: List(),
      isLoading: true,
      removedAvailableSeatCartList: List(),
    };

    this.init = this.init.bind(this);
    this.onClickSeat = this.onClickSeat.bind(this);
    this.getSelectableSeatIdSet = this.getSelectableSeatIdSet.bind(this);
    this.getTicketPriceListBySeatGroupAndStockContingent =
      this.getTicketPriceListBySeatGroupAndStockContingent.bind(this);
    this.getTicketPriceList = this.getTicketPriceList.bind(this);
    this.handleChangeTicketPrice = this.handleChangeTicketPrice.bind(this);
    this.initCart = this.initCart.bind(this);
  }

  componentDidMount(): void {
    const { eventDateId } = this.props;

    if (!eventDateId) {
      throw new Error('No eventDateId provided');
    }

    this.init();
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      selectedSeatIdSet,
      dispatchSetTicketPriceQuantity,
      selectedAvailableSeatList,
      cart,
      eventDateId,
      dispatchLockSeatsForEventDate,
      currentDomain,
    } = this.props;
    const selectedSeatsFromCart: List<Seat> = getSelectedSeatFromCart(
      cart,
      eventDateId
    );

    if (!prevProps.selectedSeatIdSet.equals(selectedSeatIdSet)) {
      const selectableSeatIdListFromCart = selectedSeatsFromCart.map(
        (seat: Seat) => Number(seat.getShortId())
      );

      if (selectedSeatIdSet.toList().equals(selectableSeatIdListFromCart)) {
        return;
      }

      // remove all items for this event date, and lock all seats
      dispatchLockSeatsForEventDate(SHOPPING_CART, eventDateId);

      const removedAvailableSeatIdList =
        prevProps.selectedAvailableSeatList.filter(
          (prevAvailableSeat) =>
            !selectedAvailableSeatList.some(
              (availableSeat) =>
                availableSeat['@id'] === prevAvailableSeat['@id']
            )
        );

      if (removedAvailableSeatIdList.size > 0) {
        getAvailableSeatListFromCart(cart, eventDateId);

        this.setState((prevState: State) => {
          const newRemovedList: List<AvailableSeatType> =
            prevState.removedAvailableSeatCartList
              .concat(removedAvailableSeatIdList)
              .toSet()
              .toList();

          return { removedAvailableSeatCartList: newRemovedList };
        });
      }

      const addedAvailableSeatIdList = selectedAvailableSeatList.filter(
        (availableSeat) =>
          !prevProps.selectedAvailableSeatList.some(
            (prevAvailableSeat) =>
              availableSeat['@id'] === prevAvailableSeat['@id']
          )
      );

      const removedTicketPriceQuantityList = this.getTicketPriceList(
        removedAvailableSeatIdList,
        -1
      );

      const addedTicketPriceQuantityList = this.getTicketPriceList(
        addedAvailableSeatIdList,
        1
      );

      dispatchSetTicketPriceQuantity(
        SHOPPING_CART,
        removedTicketPriceQuantityList.concat(addedTicketPriceQuantityList)
      );
    }

    if (prevProps.currentDomain !== currentDomain) {
      this.init();
    }
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  // eslint-disable-next-line react/sort-comp
  async init(): Promise<void> {
    const {
      eventDateId,
      initSeatingPlanPurchase,
      currentSellingDeviceId,
      currentDomain,
    } = this.props;

    // wait for init to be resolved to init the current cart as we need the availableSeatList to be set
    await initSeatingPlanPurchase(eventDateId, currentDomain);

    this.initCart();

    // Get all ticketPrices available for this eventDate
    TicketingSdkInstance.getSdk()
      .getRepository('ticketPrice')
      .findBy(
        {
          sellingDevice: currentSellingDeviceId,
          eventDate: eventDateId,
          visible: true,
          willGenerateTicket: true,
          hasBehaviour: false,
          'order[manualSort]': 'ASC',
        },
        [
          '@id',
          'name',
          'facialValue',
          'currency',
          'buyable',
          'enabled',
          'remainingStock',
          'willGenerateTicket',
          'onSale',
          'onlineStockContingent',
          {
            ticketPriceSeatGroupList: ['seatGroup'],
          },
          { bookingTrack: ['@id', 'cartType'] },
        ]
      )
      .then((response) => {
        const ticketPriceList =
          currentDomain === DOMAIN_CONTEXT.MINISITE
            ? response.getMembers().filter((ticketPrice) => ticketPrice.onSale)
            : response.getMembers();

        this.setState({
          eventDateTicketPriceList: ticketPriceList,
          isLoading: false,
        });
      });
  }

  initCart() {
    const {
      initBatchOfSeatsFromSeatList,
      cart,
      eventDateId,
      setAvailableSeatListAvailable,
    } = this.props;

    const selectedSeatsFromCart: List<Seat> = getSelectedSeatFromCart(
      cart,
      eventDateId
    );

    if (cart !== null && selectedSeatsFromCart.size > 0) {
      // @ts-expect-error Seat is compatible with NoGroupSeatType
      initBatchOfSeatsFromSeatList(selectedSeatsFromCart);

      // force availableSeatList of this cart to be available, as you might want to unselect a seat,
      // and it won't be in a cart anymore in that case
      setAvailableSeatListAvailable(
        getAvailableSeatListFromCart(cart, eventDateId)
      );
    }
  }

  getTicketPriceList(
    availableSeatList: List<AvailableSeatType>,
    delta: number
  ): List<TicketPriceQuantityParameter> {
    return (
      availableSeatList
        .map(
          (
            availableSeat: AvailableSeatType
          ): TicketPriceQuantityParameter | null => {
            assertRelationIsObject(
              availableSeat.seatGroup,
              'availableSeat.seatGroup'
            );

            const allTicketPrices =
              this.getTicketPriceListBySeatGroupAndStockContingent(
                availableSeat.seatGroup['@id'],
                null
              );

            const firstTicketPrice = allTicketPrices.find((ticketPrice) => {
              assertRelationIsDefined(
                ticketPrice.remainingStock,
                'ticketPrice.remainingStock'
              );

              return ticketPrice.remainingStock > 0;
            });

            if (delta > 0) {
              if (typeof firstTicketPrice === 'undefined') {
                // no ticket price available
                return null;
              }

              const stockContingent = availableSeat.stockContingent || null;

              if (stockContingent) {
                assertRelationIsObject(stockContingent, 'stockContingent');
              }

              return {
                ticketPrice: firstTicketPrice,
                quantityDelta: delta,
                availableSeat: availableSeat['@id'],
                stockContingent:
                  // @ts-expect-error Seat is compatible with NoGroupSeatType
                  stockContingent ? new StockContingent(stockContingent) : null,
              };
            }

            // @ts-expect-error types seems to accept object too
            return {
              availableSeat: availableSeat['@id'],
              quantityDelta: delta,
            };
          }
        )
        // remove items when no ticket price is available
        .filter((item): item is TicketPriceQuantityParameter => !!item)
    );
  }

  // optimization needed in order to not re-enter the function everytime (memoization ?)
  getTicketPriceListBySeatGroupAndStockContingent(
    seatGroup: string,
    stockContingent: string | null
  ): List<TicketPrice> {
    const { eventDateTicketPriceList } = this.state;
    const { currentDomain } = this.props;

    return eventDateTicketPriceList.filter(
      (ticketPrice: TicketPrice): boolean => {
        assertRelationIsListOfObject(
          ticketPrice.ticketPriceSeatGroupList,
          'ticketPrice.ticketPriceSeatGroupList'
        );
        assertRelationIsObject(
          ticketPrice.bookingTrack,
          'ticketPrice.bookingTrack'
        );

        assertRelationIsNullOrString(
          ticketPrice.onlineStockContingent,
          'ticketPrice.onlineStockContingent'
        );

        // on minisite, cart type option can not impact available seat as they do not have a stock (on desk no restriction, cartType will be "cart")
        // https://github.com/mapado/ticketing/blob/542adfe2e7d4832947d2ba49b63c97d389918092/src/Entity/Cart.php#L95
        // https://github.com/mapado/ticketing/blob/542adfe2e7d4832947d2ba49b63c97d389918092/src/Manager/CartManager.php#L1658-L1660
        if (
          currentDomain === DOMAIN_CONTEXT.MINISITE &&
          ticketPrice.bookingTrack.cartType === CART_TYPE.OPTION
        ) {
          return false;
        }

        // on minisite, we want a hard link between the default stock contingent and the cart item
        if (
          currentDomain === DOMAIN_CONTEXT.MINISITE &&
          stockContingent !== ticketPrice.onlineStockContingent
        ) {
          return false;
        }

        return ticketPrice.ticketPriceSeatGroupList.some(
          (ticketPriceSeatGroup) => ticketPriceSeatGroup.seatGroup === seatGroup
        );
      }
    );
  }

  handleChangeTicketPrice(
    availableSeat: AvailableSeat,
    oldTicketPrice: TicketPrice,
    newTicketPrice: TicketPrice
  ) {
    const {
      dispatchSetTicketPriceQuantity,
      dispatchLockSeatsForEventDate,
      eventDateId,
    } = this.props;

    dispatchLockSeatsForEventDate(SHOPPING_CART, eventDateId);

    dispatchSetTicketPriceQuantity(
      SHOPPING_CART,
      List([
        {
          ticketPrice: oldTicketPrice,
          quantityDelta: -1,
          availableSeat: availableSeat['@id'],
        },
        {
          ticketPrice: newTicketPrice,
          quantityDelta: 1,
          availableSeat: availableSeat['@id'],
        },
      ])
    );
  }

  onClickSeat(
    seatEntity: AvailableSeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    const { removeSeatFromSelectedSeats, addSeatToSelectedSeats } = this.props;
    const seatId = getSeatIdForAvailableSeat(seatEntity);

    if (isSelectable) {
      if (isSelected) {
        removeSeatFromSelectedSeats(seatId);
      } else {
        addSeatToSelectedSeats(seatId);
      }
    }
  }

  getSelectableSeatIdSet(
    state: RootState,
    seatIdSet: Set<string>
  ): Set<string> {
    const { getSelectableSeatIdSet, cart, eventDateId } = this.props;
    const { isLoading, removedAvailableSeatCartList } = this.state;

    if (isLoading) {
      return Set();
    }

    return getSelectableSeatIdSet(
      state,
      seatIdSet,
      getAvailableSeatListFromCart(cart, eventDateId),
      removedAvailableSeatCartList
    );
  }

  render(): ReactElement {
    const {
      cart,
      isReady,
      seatConfig,
      sidebarPrevAction,
      sidebarNextAction,
      currentContingentId,
      eventDateId,
      locale,
      seatGroupList,
      currentDomain,
      isDrawingContext,
    } = this.props;

    if (!isReady) {
      return <SeatingLoader />;
    }

    return (
      <CurrentContingentContextProvider value={currentContingentId}>
        <div
          className="mpd-seating__app mpd-seating__app--plan-purchase"
          id="mpd-seating__app"
        >
          <div
            className="mpd-seating__app__container"
            ref={isDrawingContext.setSvgDrawerRef}
          >
            <div className="mpd-seating__app__event-svg-wrapper">
              {currentDomain === DOMAIN_CONTEXT.MINISITE && (
                <div className="seatgroup-sidebar">
                  <SeatGroupList
                    seatGroupList={seatGroupList}
                    className="seatgroup-sidebar__item"
                  />
                </div>
              )}
              <Seating
                SeatElement={SeatingPlanPurchaseSeat}
                seatEntityType="AvailableSeat"
                // if optimisation of getTicketPriceListBySeatGroupAndStockContingent, we can try to use/adapt getSelectableSeatIdSet instead
                getTicketPriceListBySeatGroup={
                  this.getTicketPriceListBySeatGroupAndStockContingent
                }
                getSelectableSeatIdSet={this.getSelectableSeatIdSet}
                onClickSeat={this.onClickSeat}
                seatConfigBlockList={seatConfig?.seatConfigBlockList || []}
                isMovingSeat={false}
                actionButtons={
                  currentDomain === DOMAIN_CONTEXT.DESK && <DrawButton />
                }
              />
            </div>
            <SeatingPlanPurchaseSidebar
              cart={cart}
              eventDateId={eventDateId}
              locale={locale}
              getTicketPriceListBySeatGroupAndStockContingent={
                this.getTicketPriceListBySeatGroupAndStockContingent
              }
              handleChangeTicketPrice={this.handleChangeTicketPrice}
              sidebarPrevAction={sidebarPrevAction}
              sidebarNextAction={sidebarNextAction}
            />
          </div>
        </div>
      </CurrentContingentContextProvider>
    );
  }
}

function SeatingPlanPurchaseContainer(
  props: SeatingPlanPurchaseContainerProps
) {
  const shouldBeVisible = useReloadOnBookingError();
  const { currentDomain } = useDomainContext();
  const isDrawingContext = useDrawingContext();
  const cart = useCurrentCart();

  const lockSeatsForEventDate = useLockSeatsForEventDate();
  const setTicketPriceQuantity = useSetTicketPriceQuantity();

  if (!shouldBeVisible) {
    return null;
  }

  return (
    <SeatingPlanPurchase
      {...props}
      dispatchLockSeatsForEventDate={lockSeatsForEventDate}
      dispatchSetTicketPriceQuantity={setTicketPriceQuantity}
      cart={cart}
      currentDomain={currentDomain}
      isDrawingContext={isDrawingContext}
    />
  );
}

export type SeatingPlanPurchaseProps = {
  eventDateId: number;
  locale: string;
  /** The current contingent. If you want to display non-contingented seats, you need to explicitly set it to `null`  */
  currentContingentId: string | null;
  // eslint-disable-next-line react/require-default-props
  currentSellingDeviceId?: string;
  // eslint-disable-next-line react/require-default-props
  sidebarPrevAction?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  sidebarNextAction?: ReactNode;
};

export type StateProps = {
  isReady: boolean;
  seatGroupList: List<SeatGroupType>;
  getSelectableSeatIdSet: typeof getSelectableSeatIdSetForSeatingPlanPurchase;
  seatConfig: null | SeatConfigType;
  selectedSeatIdSet: Set<number>;
  selectedAvailableSeatList: List<AvailableSeatType>;
};

export type DispatchProps = {
  resetState: () => void;
  initSeatingPlanPurchase: (
    eventDateId: number,
    currentDomain: DOMAIN_CONTEXT
  ) => void;
  removeSeatFromSelectedSeats: (seatId: number) => void;
  addSeatToSelectedSeats: (seatId: number) => void;
  initBatchOfSeatsFromSeatList: (seatList: List<NoGroupSeatType>) => void;
  setAvailableSeatListAvailable: (
    availableSeatList: List<AvailableSeat>
  ) => void;
};

type SeatingPlanPurchaseContainerProps = SeatingPlanPurchaseProps &
  StateProps &
  DispatchProps;

type Props = SeatingPlanPurchaseContainerProps & {
  currentDomain: DOMAIN_CONTEXT;
  isDrawingContext: IsDrawingContextType;
  cart: Cart | null;
  dispatchSetTicketPriceQuantity: ReturnType<typeof useSetTicketPriceQuantity>;
  dispatchLockSeatsForEventDate: ReturnType<typeof useLockSeatsForEventDate>;
};

export default SeatingPlanPurchaseContainer;
