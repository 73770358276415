/* eslint-disable consistent-return */
import { fromJS, Map } from 'immutable';
import type { PartialEntity } from '../entity';
import AuditDocument, { AuditDocumentType } from '../entity/AuditDocument';
import AvailableSeat, { AvailableSeatType } from '../entity/AvailableSeat';
import AvailableSeatModel, {
  AvailableSeatModelType,
} from '../entity/AvailableSeatModel';
import BankAccount, { BankAccountType } from '../entity/BankAccount';
import Booking, { BookingType } from '../entity/Booking';
import BookingItem, { BookingItemType } from '../entity/BookingItem';
// keep cart item on top of cart because of a weird issues in tests
import type { CartItemType } from '../entity/CartItem';
import CartItem from '../entity/CartItem';
/* eslint-disable import/order */
import type { CartType } from '../entity/Cart';
import Cart from '../entity/Cart';
/** eslint-enable */
import Client, { ClientType } from '../entity/Client';
import Collection, { CollectionType } from '../entity/Collection';
import Contingent, { ContingentType } from '../entity/Contingent';
import Contract, { ContractType } from '../entity/Contract';
import ContractAddendum, {
  ContractAddendumType,
} from '../entity/ContractAddendum';
import Coupon, { CouponType } from '../entity/Coupon';
import Customer, { CustomerType } from '../entity/Customer';
import CustomerField, { CustomerFieldType } from '../entity/CustomerField';
import CustomerFieldContract, {
  CustomerFieldContractType,
} from '../entity/CustomerFieldContract';
import CustomerStatus, { CustomerStatusType } from '../entity/CustomerStatus';
import Deposit, { DepositType } from '../entity/Deposit';
import DeskPaymentMethod, {
  DeskPaymentMethodType,
} from '../entity/DeskPaymentMethod';
import DeskSession, { DeskSessionType } from '../entity/DeskSession';
import DeviceState, { DeviceStateType } from '../entity/DeviceState';
import EventDate, { EventDateType } from '../entity/EventDate';
import EventDatePattern, {
  EventDatePatternType,
} from '../entity/EventDatePattern';
import Invoice, { InvoiceType } from '../entity/Invoice';
import InvoiceLine, { InvoiceLineType } from '../entity/InvoiceLine';
import Job, { JobType } from '../entity/Job';
import Lettering, { LetteringType } from '../entity/Lettering';
import LogicalSeatConfig, {
  LogicalSeatConfigType,
} from '../entity/LogicalSeatConfig';
import MassActionAggregate, {
  MassActionAggregateType,
} from '../entity/MassActionAggregate';
import MasterTicket, { MasterTicketType } from '../entity/MasterTicket';
import Minisite, { MinisiteType } from '../entity/Minisite';
import Module, { ModuleType } from '../entity/Module';
import ProviderTicketImportedFile, {
  ProviderTicketImportedFileType,
} from '../entity/Module/Import/ProviderTicketImportedFile';
import SibilParameter, {
  SibilParameterType,
} from '../entity/Module/Sibil/SibilParameter';
import SibilTicketingSpectacle, {
  SibilTicketingSpectacleType,
} from '../entity/Module/Sibil/SibilTicketingSpectacle';
import ProviderContingentMapping, {
  ProviderContingentMappingType,
} from '../entity/Module/ProviderTicketMapping/ProviderContingentMapping';
import ProviderSeatMapping, {
  ProviderSeatMappingType,
} from '../entity/Module/ProviderTicketMapping/ProviderSeatMapping';
import ProviderSeatConfigMapping, {
  ProviderSeatConfigMappingType,
} from '../entity/Module/ProviderTicketMapping/ProviderSeatConfigMapping';
import ProviderTicketPriceMapping, {
  ProviderTicketPriceMappingType,
} from '../entity/Module/ProviderTicketMapping/ProviderTicketPriceMapping';
import ProviderTicketingMapping, {
  ProviderTicketingMappingType,
} from '../entity/Module/ProviderTicketMapping/ProviderTicketingMapping';
import SibilVenueLieu, {
  SibilVenueLieuType,
} from '../entity/Module/Sibil/SibilVenueLieu';
import Notification, { NotificationType } from '../entity/Notification';
import OfferRule, { OfferRuleType } from '../entity/OfferRule';
import Order, { OrderType } from '../entity/Order';
import OrderItem, { OrderItemType } from '../entity/OrderItem';
import OrderItemInvoiceGroup, {
  OrderItemInvoiceGroupType,
} from '../entity/OrderItemInvoiceGroup';
import OrderItemCashCoupon, {
  OrderItemCashCouponType,
} from '../entity/OrderItemCashCoupon';
import OrganizationalUnit, {
  OrganizationalUnitType,
} from '../entity/OrganizationalUnit';
import PagedCollection from '../entity/PagedCollection';
import Payment, { PaymentType } from '../entity/Payment';
import PaymentFee, { PaymentFeeType } from '../entity/PaymentFee';
import Payout, { PayoutType } from '../entity/Payout';
import ProviderTicketCredentials, {
  ProviderTicketCredentialsType,
} from '../entity/ProviderTicketCredentials';
import Refund, { RefundType } from '../entity/Refund';
import RefundOrderItem, {
  RefundOrderItemType,
} from '../entity/RefundOrderItem';
import Scan, { ScanType } from '../entity/Scan';
import ScanConfig, { ScanConfigType } from '../entity/ScanConfig';
import Season, { SeasonType } from '../entity/Season';
import Seat, { SeatType } from '../entity/Seat';
import SeatConfig, { SeatConfigType } from '../entity/SeatConfig';
import SeatConfigBlock, {
  SeatConfigBlockType,
} from '../entity/SeatConfigBlock';
import SeatGroup, { SeatGroupType } from '../entity/SeatGroup';
import SeatReserved, { SeatReservedType } from '../entity/SeatReserved';
import SellingDevice, { SellingDeviceType } from '../entity/SellingDevice';
import Sticker, { StickerType } from '../entity/Sticker';
import StockContingent, {
  StockContingentType,
} from '../entity/StockContingent';
import Subscription, { SubscriptionType } from '../entity/Subscription';
import SubscriptionMaturity, {
  SubscriptionMaturityType,
} from '../entity/SubscriptionMaturity';
import Tag, { TagType } from '../entity/Tag';
import Tax, { TaxType } from '../entity/Tax';
import Ticket, { TicketType } from '../entity/Ticket';
import TicketPrice, { TicketPriceType } from '../entity/TicketPrice';
import TicketPriceGroup, {
  TicketPriceGroupType,
} from '../entity/TicketPriceGroup';
import TicketPriceSeatGroup, {
  TicketPriceSeatGroupType,
} from '../entity/TicketPriceSeatGroup';
import Ticketing, { TicketingType } from '../entity/Ticketing';
import TicketingCategory, {
  TicketingCategoryType,
} from '../entity/TicketingCategory';
import TicketingOptionStress, {
  TicketingOptionStressType,
} from '../entity/TicketingOptionStress';
import TicketingSellingDevice, {
  TicketingSellingDeviceType,
} from '../entity/TicketingSellingDevice';
import Transaction, { TransactionType } from '../entity/Transaction';
import TransactionGroup, {
  TransactionGroupType,
} from '../entity/TransactionGroup';
import Billing, { BillingType } from '../entity/Billing';
import User, { UserType } from '../entity/User';
import Venue, { VenueType } from '../entity/Venue';
import Version, { VersionType } from '../entity/Version';
import Wallet, { WalletType } from '../entity/Wallet';
import type { AllowedFactoryTypes, Entity } from './types';
import Shipping, { ShippingType } from '../entity/Shipping';
import Fee, { FeeType } from '../entity/Fee';
import BookingTrack, { BookingTrackType } from '../entity/BookingTrack';
import BookingTrackMode, {
  BookingTrackModeType,
} from '../entity/BookingTrackMode';
import OrderFeeItem, { OrderFeeItemType } from '../entity/OrderFeeItem';
import ScanTrack, { ScanTrackType } from '../entity/ScanTrack';
import Checkpoint, { CheckpointType } from '../entity/Checkpoint';
import ScanTrackCheckpoint, {
  ScanTrackCheckpointType,
} from '../entity/ScanTrackCheckpoint';
import TicketCheckpoint, {
  TicketCheckpointType,
} from '../entity/TicketCheckpoint';
import PagedCartCollection from '../entity/PagedCartCollection';
import EntityRegistry from './EntityRegistry';
import Form, { FormType } from '../entity/Form';
import PaymentAttempt, { PaymentAttemptType } from '../entity/PaymentAttempt';

class EntityFactory {
  public static createEntity(val: null, registry?: EntityRegistry): undefined;

  public static createEntity(
    val: PartialEntity<AuditDocumentType>,
    registry?: EntityRegistry
  ): AuditDocument;

  public static createEntity(
    val: PartialEntity<AvailableSeatType>,
    registry?: EntityRegistry
  ): AvailableSeat;

  public static createEntity(
    val: PartialEntity<AvailableSeatModelType>,
    registry?: EntityRegistry
  ): AvailableSeatModel;

  public static createEntity(
    val: PartialEntity<BankAccountType>,
    registry?: EntityRegistry
  ): BankAccount;

  public static createEntity(
    val: PartialEntity<BookingType>,
    registry?: EntityRegistry
  ): Booking;

  public static createEntity(
    val: PartialEntity<BookingItemType>,
    registry?: EntityRegistry
  ): BookingItem;

  public static createEntity(
    val: PartialEntity<BookingTrackType>,
    registry?: EntityRegistry
  ): BookingTrack;

  public static createEntity(
    val: PartialEntity<BookingTrackModeType>,
    registry?: EntityRegistry
  ): BookingTrackMode;

  public static createEntity(
    val: PartialEntity<CartType>,
    registry?: EntityRegistry
  ): Cart;

  public static createEntity(
    val: PartialEntity<CartItemType>,
    registry?: EntityRegistry
  ): CartItem;

  public static createEntity(
    val: PartialEntity<ClientType>,
    registry?: EntityRegistry
  ): Client;

  public static createEntity(
    val: PartialEntity<ContractType>,
    registry?: EntityRegistry
  ): Contract;

  public static createEntity(
    val: PartialEntity<ContractAddendumType>,
    registry?: EntityRegistry
  ): ContractAddendum;

  public static createEntity(
    val: PartialEntity<CouponType>,
    registry?: EntityRegistry
  ): Coupon;

  public static createEntity(
    val: PartialEntity<CustomerType>,
    registry?: EntityRegistry
  ): Customer;

  public static createEntity(
    val: PartialEntity<CustomerFieldType>,
    registry?: EntityRegistry
  ): CustomerField;

  public static createEntity(
    val: PartialEntity<CustomerFieldContractType>,
    registry?: EntityRegistry
  ): CustomerFieldContract;

  public static createEntity(
    val: PartialEntity<CustomerStatusType>,
    registry?: EntityRegistry
  ): CustomerStatus;

  public static createEntity(
    val: PartialEntity<DepositType>,
    registry?: EntityRegistry
  ): Deposit;

  public static createEntity(
    val: PartialEntity<DeskPaymentMethodType>,
    registry?: EntityRegistry
  ): DeskPaymentMethod;

  public static createEntity(
    val: PartialEntity<DeskSessionType>,
    registry?: EntityRegistry
  ): DeskSession;

  public static createEntity(
    val: PartialEntity<DeviceStateType>,
    registry?: EntityRegistry
  ): DeviceState;

  public static createEntity(
    val: PartialEntity<MasterTicketType>,
    registry?: EntityRegistry
  ): MasterTicket;

  public static createEntity(
    val: PartialEntity<EventDateType>,
    registry?: EntityRegistry
  ): EventDate;

  public static createEntity(
    val: PartialEntity<EventDatePatternType>,
    registry?: EntityRegistry
  ): EventDatePattern;

  public static createEntity(
    val: PartialEntity<FeeType>,
    registry?: EntityRegistry
  ): Fee;

  public static createEntity(
    val: PartialEntity<FormType>,
    registry?: EntityRegistry
  ): Form;

  public static createEntity(
    val: PartialEntity<InvoiceType>,
    registry?: EntityRegistry
  ): Invoice;

  public static createEntity(
    val: PartialEntity<InvoiceLineType>,
    registry?: EntityRegistry
  ): InvoiceLine;

  public static createEntity(
    val: PartialEntity<JobType>,
    registry?: EntityRegistry
  ): Job;

  public static createEntity(
    val: PartialEntity<LetteringType>,
    registry?: EntityRegistry
  ): Lettering;

  public static createEntity(
    val: PartialEntity<LogicalSeatConfigType>,
    registry?: EntityRegistry
  ): LogicalSeatConfig;

  public static createEntity(
    val: PartialEntity<MassActionAggregateType>,
    registry?: EntityRegistry
  ): MassActionAggregate;

  public static createEntity(
    val: PartialEntity<ProviderContingentMappingType>,
    registry?: EntityRegistry
  ): ProviderContingentMapping;

  public static createEntity(
    val: PartialEntity<ProviderSeatMappingType>,
    registry?: EntityRegistry
  ): ProviderSeatMapping;

  public static createEntity(
    val: PartialEntity<ProviderSeatConfigMappingType>,
    registry?: EntityRegistry
  ): ProviderSeatConfigMapping;

  public static createEntity(
    val: PartialEntity<ProviderTicketPriceMappingType>,
    registry?: EntityRegistry
  ): ProviderTicketPriceMapping;

  public static createEntity(
    val: PartialEntity<ProviderTicketingMappingType>,
    registry?: EntityRegistry
  ): ProviderTicketingMapping;

  public static createEntity(
    val: PartialEntity<MinisiteType>,
    registry?: EntityRegistry
  ): Minisite;

  public static createEntity(
    val: PartialEntity<ModuleType>,
    registry?: EntityRegistry
  ): Module;

  public static createEntity(
    val: PartialEntity<NotificationType>,
    registry?: EntityRegistry
  ): Notification;

  public static createEntity(
    val: PartialEntity<OfferRuleType>,
    registry?: EntityRegistry
  ): OfferRule;

  public static createEntity(
    val: PartialEntity<OrderType>,
    registry?: EntityRegistry
  ): Order;

  public static createEntity(
    val: PartialEntity<OrderFeeItemType>,
    registry?: EntityRegistry
  ): OrderFeeItem;

  public static createEntity(
    val: PartialEntity<OrderItemType>,
    registry?: EntityRegistry
  ): OrderItem;

  public static createEntity(
    val: PartialEntity<OrderItemInvoiceGroupType>,
    registry?: EntityRegistry
  ): OrderItemInvoiceGroup;

  public static createEntity(
    val: PartialEntity<OrderItemCashCouponType>,
    registry?: EntityRegistry
  ): OrderItemCashCoupon;

  public static createEntity(
    val: PartialEntity<OrganizationalUnitType>,
    registry?: EntityRegistry
  ): OrganizationalUnit;

  public static createEntity(
    val: PartialEntity<PaymentType>,
    registry?: EntityRegistry
  ): Payment;

  public static createEntity(
    val: PartialEntity<PaymentAttemptType>,
    registry?: EntityRegistry
  ): PaymentAttempt;

  public static createEntity(
    val: PartialEntity<PaymentFeeType>,
    registry?: EntityRegistry
  ): PaymentFee;

  public static createEntity(
    val: PartialEntity<PayoutType>,
    registry?: EntityRegistry
  ): Payout;

  public static createEntity(
    val: PartialEntity<ProviderTicketCredentialsType>,
    registry?: EntityRegistry
  ): ProviderTicketCredentials;

  public static createEntity(
    val: PartialEntity<ProviderTicketImportedFileType>,
    registry?: EntityRegistry
  ): ProviderTicketImportedFile;

  public static createEntity(
    val: PartialEntity<RefundType>,
    registry?: EntityRegistry
  ): Refund;

  public static createEntity(
    val: PartialEntity<RefundOrderItemType>,
    registry?: EntityRegistry
  ): RefundOrderItem;

  public static createEntity(
    val: PartialEntity<ScanType>,
    registry?: EntityRegistry
  ): Scan;

  public static createEntity(
    val: PartialEntity<ScanConfigType>,
    registry?: EntityRegistry
  ): ScanConfig;

  public static createEntity(
    val: PartialEntity<SeasonType>,
    registry?: EntityRegistry
  ): Season;

  public static createEntity(
    val: PartialEntity<SeatType>,
    registry?: EntityRegistry
  ): Seat;

  public static createEntity(
    val: PartialEntity<SeatConfigType>,
    registry?: EntityRegistry
  ): SeatConfig;

  public static createEntity(
    val: PartialEntity<SeatGroupType>,
    registry?: EntityRegistry
  ): SeatGroup;

  public static createEntity(
    val: PartialEntity<SeatConfigBlockType>,
    registry?: EntityRegistry
  ): SeatConfigBlock;

  public static createEntity(
    val: PartialEntity<SeatReservedType>,
    registry?: EntityRegistry
  ): SeatReserved;

  public static createEntity(
    val: PartialEntity<SellingDeviceType>,
    registry?: EntityRegistry
  ): SellingDevice;

  public static createEntity(
    val: PartialEntity<ShippingType>,
    registry?: EntityRegistry
  ): Shipping;

  public static createEntity(
    val: PartialEntity<BillingType>,
    registry?: EntityRegistry
  ): Billing;

  public static createEntity(
    val: PartialEntity<SibilParameterType>,
    registry?: EntityRegistry
  ): SibilParameter;

  public static createEntity(
    val: PartialEntity<SibilTicketingSpectacleType>,
    registry?: EntityRegistry
  ): SibilTicketingSpectacle;

  public static createEntity(
    val: PartialEntity<SibilVenueLieuType>,
    registry?: EntityRegistry
  ): SibilVenueLieu;

  public static createEntity(
    val: PartialEntity<ContingentType>,
    registry?: EntityRegistry
  ): Contingent;

  public static createEntity(
    val: PartialEntity<StockContingentType>,
    registry?: EntityRegistry
  ): StockContingent;

  public static createEntity(
    val: PartialEntity<StickerType>,
    registry?: EntityRegistry
  ): Sticker;

  public static createEntity(
    val: PartialEntity<SubscriptionType>,
    registry?: EntityRegistry
  ): Subscription;

  public static createEntity(
    val: PartialEntity<SubscriptionMaturityType>,
    registry?: EntityRegistry
  ): SubscriptionMaturity;

  public static createEntity(
    val: PartialEntity<TagType>,
    registry?: EntityRegistry
  ): Tag;

  public static createEntity(
    val: PartialEntity<TaxType>,
    registry?: EntityRegistry
  ): Tax;

  public static createEntity(
    val: PartialEntity<TicketType>,
    registry?: EntityRegistry
  ): Ticket;

  public static createEntity(
    val: PartialEntity<TicketingType>,
    registry?: EntityRegistry
  ): Ticketing;

  public static createEntity(
    val: PartialEntity<TicketingCategoryType>,
    registry?: EntityRegistry
  ): TicketingCategory;

  public static createEntity(
    val: PartialEntity<TicketingOptionStressType>,
    registry?: EntityRegistry
  ): TicketingOptionStress;

  public static createEntity(
    val: PartialEntity<TicketingSellingDeviceType>,
    registry?: EntityRegistry
  ): TicketingSellingDevice;

  public static createEntity(
    val: PartialEntity<TicketPriceType>,
    registry?: EntityRegistry
  ): TicketPrice;

  public static createEntity(
    val: PartialEntity<TicketPriceGroupType>,
    registry?: EntityRegistry
  ): TicketPriceGroup;

  public static createEntity(
    val: PartialEntity<TicketPriceSeatGroupType>,
    registry?: EntityRegistry
  ): TicketPriceSeatGroup;

  public static createEntity(
    val: PartialEntity<TransactionType>,
    registry?: EntityRegistry
  ): Transaction;

  public static createEntity(
    val: PartialEntity<TransactionGroupType>,
    registry?: EntityRegistry
  ): TransactionGroup;

  public static createEntity(
    val: PartialEntity<UserType>,
    registry?: EntityRegistry
  ): User;

  public static createEntity(
    val: PartialEntity<VenueType>,
    registry?: EntityRegistry
  ): Venue;

  public static createEntity(
    val: PartialEntity<VersionType>,
    registry?: EntityRegistry
  ): Version;

  public static createEntity(
    val: PartialEntity<WalletType>,
    registry?: EntityRegistry
  ): Wallet;

  public static createEntity(
    val: PartialEntity<ScanTrackType>,
    registry?: EntityRegistry
  ): ScanTrack;

  public static createEntity(
    val: PartialEntity<CheckpointType>,
    registry?: EntityRegistry
  ): Checkpoint;

  public static createEntity(
    val: PartialEntity<ScanTrackCheckpointType>,
    registry?: EntityRegistry
  ): ScanTrackCheckpoint;

  public static createEntity(
    val: PartialEntity<TicketCheckpointType>,
    registry?: EntityRegistry
  ): TicketCheckpoint;

  public static createEntity<O extends Entity>(
    val: CollectionType<AllowedFactoryTypes>,
    registry?: EntityRegistry
  ): Collection<O>;

  public static createEntity(
    val: AllowedFactoryTypes,
    registry?: EntityRegistry
  ): unknown;

  // export function createEntity(val: Input<string>): Map<string, unknown>;

  public static createEntity(
    val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>,
    registry?: EntityRegistry
  ): undefined | Entity | Collection<Entity> | Map<string, unknown> {
    if (!val) {
      return;
    }

    switch (val['@type']) {
      /** @ts-expect-error -- PagedCollection does exist in API sometimes, but is a collection */
      case 'hydra:PagedCollection':
        if (val['@id'] === '/v1/carts') {
          // @ts-expect-error val should be converted to an entity
          return new PagedCartCollection(val);
        }
        // @ts-expect-error val should be converted to an entity
        return new PagedCollection(val);

      case 'hydra:Collection':
        return new Collection(val);

      case 'AuditDocument':
        return new AuditDocument(val);

      case 'AvailableSeat':
        return new AvailableSeat(val);

      case 'AvailableSeatModel':
        return new AvailableSeatModel(val);

      case 'BankAccount':
        return new BankAccount(val);

      case 'Booking':
        return new Booking(val);

      case 'BookingItem':
        return new BookingItem(val);

      case 'BookingTrack':
        return new BookingTrack(val);

      case 'BookingTrackMode':
        return new BookingTrackMode(val);

      case 'Cart':
        return new Cart(val, registry);

      case 'CartItem':
        return new CartItem(val, registry);

      case 'Checkpoint':
        return new Checkpoint(val);

      case 'Client':
        return new Client(val);

      case 'Contingent':
        return new Contingent(val);

      case 'Contract':
        return new Contract(val);

      case 'ContractAddendum':
        return new ContractAddendum(val);

      case 'Customer':
        return new Customer(val);

      case 'Coupon':
        return new Coupon(val);

      case 'CustomerField':
        return new CustomerField(val);

      case 'CustomerFieldContract':
        return new CustomerFieldContract(val);

      case 'CustomerStatus':
        return new CustomerStatus(val);

      case 'Deposit':
        return new Deposit(val);

      case 'DeskPaymentMethod':
        return new DeskPaymentMethod(val);

      case 'DeskSession':
        return new DeskSession(val);

      case 'DeviceState':
        return new DeviceState(val);

      case 'EventDate':
        return new EventDate(val);

      case 'EventDatePattern':
        return new EventDatePattern(val);

      case 'Form':
        return new Form(val);

      case 'Fee':
        return new Fee(val);

      case 'Invoice':
        return new Invoice(val);

      case 'InvoiceLine':
        return new InvoiceLine(val);

      case 'Job':
        return new Job(val);

      case 'Lettering':
        return new Lettering(val);

      case 'LogicalSeatConfig':
        return new LogicalSeatConfig(val);

      case 'MassActionAggregate':
        return new MassActionAggregate(val);

      case 'ProviderSeatMapping':
        return new ProviderSeatMapping(val);

      case 'ProviderSeatConfigMapping':
        return new ProviderSeatConfigMapping(val);

      case 'ProviderContingentMapping':
        return new ProviderContingentMapping(val);

      case 'ProviderTicketPriceMapping':
        return new ProviderTicketPriceMapping(val);

      case 'ProviderTicketingMapping':
        return new ProviderTicketingMapping(val);

      case 'MasterTicket':
        return new MasterTicket(val);

      case 'Minisite':
        return new Minisite(val);

      case 'Module':
        return new Module(val);

      case 'Notification':
        return new Notification(val);

      case 'OfferRule':
        return new OfferRule(val);

      case 'Order':
        return new Order(val);

      case 'OrderFeeItem':
        return new OrderFeeItem(val);

      case 'OrderItem':
        return new OrderItem(val);

      case 'OrderItemCashCoupon':
        return new OrderItemCashCoupon(val);

      case 'OrderItemInvoiceGroup':
        return new OrderItemInvoiceGroup(val);

      case 'OrganizationalUnit':
        return new OrganizationalUnit(val);

      case 'Payment':
        return new Payment(val);

      case 'PaymentAttempt':
        return new PaymentAttempt(val);

      case 'PaymentFee':
        return new PaymentFee(val);

      case 'Payout':
        return new Payout(val);

      case 'ProviderTicketCredentials':
        return new ProviderTicketCredentials(val);

      case 'ProviderTicketImportedFile':
        return new ProviderTicketImportedFile(val);

      case 'Refund':
        return new Refund(val);

      case 'RefundOrderItem':
        return new RefundOrderItem(val);

      case 'Scan':
        return new Scan(val);

      case 'ScanTrack':
        return new ScanTrack(val);

      case 'ScanTrackCheckpoint':
        return new ScanTrackCheckpoint(val);

      case 'ScanConfig':
        return new ScanConfig(val);

      case 'Season':
        return new Season(val);

      case 'Seat':
        return new Seat(val);

      case 'SeatConfig':
        return new SeatConfig(val);

      case 'SeatConfigBlock':
        return new SeatConfigBlock(val);

      case 'SeatGroup':
        return new SeatGroup(val);

      case 'SeatReserved':
        return new SeatReserved(val);

      case 'SellingDevice':
        return new SellingDevice(val);

      case 'Shipping':
        return new Shipping(val);

      case 'Billing':
        return new Billing(val);

      case 'SibilParameter':
        return new SibilParameter(val);

      case 'SibilTicketingSpectacle':
        return new SibilTicketingSpectacle(val);

      case 'SibilVenueLieu':
        return new SibilVenueLieu(val);

      case 'StockContingent':
        return new StockContingent(val);

      case 'Sticker':
        return new Sticker(val);

      case 'Subscription':
        return new Subscription(val);

      case 'SubscriptionMaturity':
        return new SubscriptionMaturity(val);

      case 'User':
        return new User(val);

      case 'Tag':
        return new Tag(val);

      case 'Tax':
        return new Tax(val);

      case 'Ticket':
        return new Ticket(val);

      case 'TicketCheckpoint':
        return new TicketCheckpoint(val);

      case 'Ticketing':
        return new Ticketing(val);

      case 'TicketingCategory':
        return new TicketingCategory(val);

      case 'TicketingOptionStress':
        return new TicketingOptionStress(val);

      case 'TicketingSellingDevice':
        return new TicketingSellingDevice(val);

      case 'TicketPrice':
        return new TicketPrice(val, registry);

      case 'TicketPriceGroup':
        return new TicketPriceGroup(val);

      case 'TicketPriceSeatGroup':
        return new TicketPriceSeatGroup(val);

      case 'Transaction':
        return new Transaction(val);

      case 'TransactionGroup':
        return new TransactionGroup(val);

      case 'Venue':
        return new Venue(val);

      case 'Version':
        return new Version(val);

      case 'Wallet':
        return new Wallet(val);

      default:
        return fromJS(val) as Map<string, unknown>;
    }
  }
}

// @ts-expect-error -- don't care
const createEntity: typeof EntityFactory.createEntity = (
  val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>,
  registry?: EntityRegistry
) => {
  if (registry) {
    const foundEntity = registry.get(val);

    if (foundEntity) {
      return foundEntity;
    }
  }

  if (!val) {
    return null;
  }

  // @ts-expect-error don't care
  const createdEntity = EntityFactory.createEntity(val, registry);

  if (registry && typeof createdEntity === 'object') {
    registry.register(createdEntity, val);
  }

  return createdEntity;
};

export default createEntity;
