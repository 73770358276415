import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-594 -1044)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="filtre"
            d="m501.65 225.667 7.017 10.526v8.14h2.666v-8.14l7.018-10.526zM514 237v10h-8v-10l-9.333-14h26.666z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFilter;
