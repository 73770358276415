import React, { PureComponent, ReactElement } from 'react';
import { Set } from 'immutable';
import { Order } from 'mapado-ticketing-js-sdk';
import { MpdToastFunction, useMpdToast } from '@mapado/makeup';
import SidebarTemplate from '../Sidebar/SidebarTemplate';
import OrderSidebar from '../OrderSidebar';
import Seating from '../Seating';
import EventDateCaptionWithFetch from '../Caption/EventDateCaption';
import SeatingLoader from '../SeatingLoader';
import SvgDrawer from '../../utils/SvgDrawer';
import { AvailableSeatType, SeatConfigType } from '../../propTypes';
import '../../styles/components/App.scss';
import '../../styles/contexts/order.css';
import { getSelectableSeatIdSetForReplacement } from '../../utils/seatSelectable';
import { SelectBatchOfSeats } from '../../utils/drawers/FreeHandDrawSeatSelector';
import OrderReplacementSeat from '../Seat/OrderReplacementSeat';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import { OrderContextProvider } from '../OrderReplacement/OrderContext';
import useOrder from '../useOrder';
import { SelectOrMoveSeatAction } from '../../actions/SeatActions';
import { BOOKING_STATUS_IN_ORDER } from '../../utils/booking';
import { ReplaceAvailableSeatFunction } from '../useCart';
import { TFunction, useTranslation } from '../../i18n';

class OrderReplacement extends PureComponent<Props> {
  static defaultProps = {
    onDismissSeating: null,
  };

  // eslint-disable-next-line react/sort-comp
  #svgDrawer: SvgDrawer;

  constructor(props: Props) {
    super(props);

    this.init = this.init.bind(this);
    this.setRef = this.setRef.bind(this);
    this.selectOrMoveSeat = this.selectOrMoveSeat.bind(this);

    this.#svgDrawer = new SvgDrawer({
      // eslint-disable-next-line react/destructuring-assignment
      selectBatchOfSeats: this.props.selectBatchOfSeats,
    });
  }

  componentDidMount(): void {
    const { orderId } = this.props;

    if (!orderId) {
      throw new Error('OrderId is required context');
    }

    this.init();
  }

  componentDidUpdate(prevProps: Props): void {
    const { selectedSeatIdSet } = this.props;

    if (!prevProps.selectedSeatIdSet.equals(selectedSeatIdSet)) {
      this.#svgDrawer.handleSelectedSeatIdListChange(selectedSeatIdSet);
    }
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  setRef(ref: HTMLDivElement): void {
    if (ref) {
      this.#svgDrawer.init();
    }
  }

  init(): void {
    const { eventDateId, orderId, initOrderReplacement } = this.props;

    initOrderReplacement(orderId, eventDateId);
  }

  selectOrMoveSeat(
    seatEntity: AvailableSeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    const { selectOrMoveSeat, order, replaceAvailableSeat, toast, t } =
      this.props;

    if (!order) {
      return;
    }

    const seatId = getSeatIdForAvailableSeat(seatEntity);

    const isCurrentBooking =
      seatEntity.bookingStatus === BOOKING_STATUS_IN_ORDER;

    selectOrMoveSeat(
      order,
      seatId,
      isSelectable,
      isCurrentBooking,
      null,
      replaceAvailableSeat,
      this.init,
      toast,
      t
    );
  }

  render(): ReactElement {
    const {
      selectedSeatIdSet,
      isReady,
      getSelectableSeatIdSet,
      eventDateId,
      seatConfig,
      onDismissSeating,
      order,
    } = this.props;

    if (!isReady) {
      return <SeatingLoader />;
    }

    if (!seatConfig) {
      throw new Error('Unable to find seatConfig. This should not happen.');
    }

    return (
      <div className="mpd-seating__app context-order" id="mpd-seating__app">
        <div className="mpd-seating__app__container" ref={this.setRef}>
          <div className="mpd-seating__app__event-svg-wrapper">
            <EventDateCaptionWithFetch eventDateId={eventDateId} />
            <Seating
              seatEntityType="AvailableSeat"
              SeatElement={OrderReplacementSeat}
              getSelectableSeatIdSet={(state, seatIdSet) => {
                if (order) {
                  return getSelectableSeatIdSet(state, seatIdSet, order);
                }

                return Set();
              }}
              onClickSeat={this.selectOrMoveSeat}
              seatConfigBlockList={
                seatConfig ? seatConfig.seatConfigBlockList : []
              }
              isMovingSeat={selectedSeatIdSet.size > 0}
            />
          </div>
          <SidebarTemplate onClose={onDismissSeating}>
            {order && (
              <OrderSidebar
                seatConfig={seatConfig}
                selectedSeatIdSet={selectedSeatIdSet}
                order={order}
              />
            )}
          </SidebarTemplate>
        </div>
      </div>
    );
  }
}

export type OrderReplacementProps = {
  eventDateId: number;
  orderId: number;
  onDismissSeating?: null | (() => void);
};

type PropsFromContainer = {
  toast: MpdToastFunction;
  t: TFunction;
  order: Order | null;
  replaceAvailableSeat: ReplaceAvailableSeatFunction;
};

export type StateProps = {
  seatConfig: null | SeatConfigType;
  isReady: boolean;
  getSelectableSeatIdSet: typeof getSelectableSeatIdSetForReplacement;
  selectedSeatIdSet: Set<number>;
};

export type DispatchProps = {
  resetState: () => void;
  initOrderReplacement: (orderId: number, eventDateId: number) => void;
  selectBatchOfSeats: SelectBatchOfSeats;
  selectOrMoveSeat: SelectOrMoveSeatAction;
};

type Props = OrderReplacementProps &
  PropsFromContainer &
  StateProps &
  DispatchProps;

export default function OrderReplacementContainer(
  props: Omit<Props, keyof PropsFromContainer>
): ReactElement {
  const { orderId, eventDateId } = props;
  const toast = useMpdToast();
  const { t } = useTranslation();
  const [order, replaceAvailableSeat] = useOrder(orderId, eventDateId);

  return (
    <OrderContextProvider order={order}>
      <OrderReplacement
        {...props}
        order={order}
        replaceAvailableSeat={replaceAvailableSeat}
        toast={toast}
        t={t}
      />
    </OrderContextProvider>
  );
}
