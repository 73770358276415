import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import DeskSessionClient from '../client/DeskSessionClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { extractTimezone } from './utils';
import { BaseEntity, PartialEntity } from '.';

export type DeskSessionType = BaseEntity<'DeskSession'> & {
  startDate: null | Moment;
  endDate: null | Moment;
  sessionNumber: null | number;
  userEmail: null | string;
  contract: null | Contract;
};

class DeskSession extends NetworkEntity<DeskSessionType>({
  '@id': null,
  '@type': 'DeskSession',
  startDate: null,
  endDate: null,
  sessionNumber: null,
  userEmail: null,
  contract: new Contract(),
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<DeskSessionType> = {
      '@id': null,
      '@type': 'DeskSession',
    }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['contract', 'timezone']);

    data.startDate = parseDate(data.startDate, tz);
    data.endDate = parseDate(data.endDate, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/desk_sessions/', '') || '';
  }
}

DeskSession.classMetadata = new ClassMetadata(
  'deskSession',
  'desk_sessions',
  /** @ts-expect-error -- method signature are incompatible */
  DeskSessionClient
);
DeskSession.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('startDate'),
  new Attribute('endDate'),
  new Attribute('sessionNumber', 'sessionNumber', 'number'),
  new Attribute('userEmail'),
]);
DeskSession.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default DeskSession;
