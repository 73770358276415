import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class NotificationClient extends AbstractClient<TSMetadata['notification']> {
  getPathBase(): string {
    return '/v1/notifications';
  }
}

export default NotificationClient;
