import React, { ComponentType } from 'react';
import { LogicalSeatConfigAdminContextProvider } from '../../contexts/LogicalSeatConfigAdminContext';
import { SelectedSeatGroupContextProvider } from '../../contexts/SelectedSeatGroupContext';

export default function withLogicalSeatConfigAdminContextProvider<
  P extends object
>(WrappedComponent: ComponentType<P>) {
  function WithLogicalSeatConfigAdminContextProvider(props: P) {
    return (
      <SelectedSeatGroupContextProvider>
        <LogicalSeatConfigAdminContextProvider>
          <WrappedComponent {...props} />
        </LogicalSeatConfigAdminContextProvider>
      </SelectedSeatGroupContextProvider>
    );
  }

  return WithLogicalSeatConfigAdminContextProvider;
}
