import React, { ReactElement } from 'react';
import { MpdIcon } from '@mapado/makeup';
import cn from 'classnames';
import { useDrawingContext } from '../../contexts/DrawingContext';

type DrawButtonProps = {
  isDrawing: boolean;
  onChange: (isDrawing: boolean) => void;
};

type NoProps = Record<string, never>;
type Props = Partial<DrawButtonProps>;

function DrawButton(prop: NoProps): ReactElement;
/** @deprecated use IsDrawingContext instead */
function DrawButton({ isDrawing, onChange }: DrawButtonProps): ReactElement;
function DrawButton({ isDrawing, onChange }: Props): ReactElement {
  const isDrawingContext = useDrawingContext();

  const onChangeWithDefault =
    typeof onChange !== 'undefined' ? onChange : isDrawingContext.setIsDrawing;
  const isDrawingWithDefault =
    typeof isDrawing !== 'undefined' ? isDrawing : isDrawingContext.isDrawing;

  return (
    <div className="mpd-btn-group">
      <button
        type="button"
        className={cn('mpd-btn mpd-btn--icon-only', {
          selected: !isDrawingWithDefault,
        })}
        onClick={(): void => onChangeWithDefault(false)}
      >
        <MpdIcon icon="selection" width="20" />
      </button>
      <button
        type="button"
        className={cn('mpd-btn mpd-btn--icon-only', {
          selected: isDrawingWithDefault,
        })}
        onClick={(): void => onChangeWithDefault(true)}
      >
        <MpdIcon icon="pen" width="20" />
      </button>
    </div>
  );
}

export default DrawButton;
