import { TicketingSdkContext } from '@mapado/js-component';
import TicketingSdk from 'mapado-ticketing-js-sdk';
import React, { ReactElement, useState } from 'react';
import { SeatingPlanPurchase, TicketingSdkInstance } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';
import ContingentSelector from '../ContingentSelector';
import { getCart } from '../entityFactory';

type Props = {
  eventDateId: number;
  sdk: TicketingSdk;
};

export default function DemoSeatingPlanPurchase({
  eventDateId,
  sdk,
}: Props): ReactElement {
  const [selectedStockContingentId, setSelectedStockContingentId] = useState<
    null | string
  >(null);

  return (
    <>
      <EventDatePagesHeader title="Achat sur plan" eventDateId={eventDateId} />

      <hr />

      <TicketingSdkContext.Provider
        value={TicketingSdkInstance.getDefaultSerializerSdk()}
      >
        <ContingentSelector
          eventDateId={eventDateId}
          sdk={sdk}
          value={selectedStockContingentId}
          onChange={setSelectedStockContingentId}
        />
      </TicketingSdkContext.Provider>

      <hr />

      <SeatingPlanPurchase
        key={eventDateId}
        eventDateId={eventDateId}
        // @ts-expect-error -- don't really care about the type here
        setTicketPriceQuantity={console.log}
        lockSeatsForEventDate={console.log}
        cart={getCart()}
        currentContingentId={selectedStockContingentId}
      />
    </>
  );
}
