import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTicketCancelled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 23 27" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M22.744 1.286 21.212 0l-5.691 6.783H.372v6h1v2h-1v5h4.24L0 25.279l1.532 1.286 5.691-6.783h15.149v-5h-1v-2h1v-6h-4.24zm-8.901 7.497H7.372v7.71zm-4.941 9 7.551-9h3.919v9zm-6.53 0v-9h3v9z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketCancelled;
