import { useCallback, useEffect, useState } from 'react';
import {
  AvailableSeat,
  Cart,
  CartItem,
  assertRelationIsListOfObject,
} from 'mapado-ticketing-js-sdk';
import {
  CART_FIELDS_FOR_BOOKING,
  filterCartOnEventDate,
} from './OrderManagement/BookingActions';
import TicketingSdkInstance from '../TicketingSdkInstance';
import { autoSelectAvailableSeatList } from '../actions/AppActions';
import { getAvailableSeatListFromCart } from '../utils/memoized';
import { useSeatingDispatch } from '../reducers/typedFunctions';

export async function getSingleCart(
  cartId: number | string,
  eventDateId: number | string
): Promise<Cart> {
  const sdk = TicketingSdkInstance.getSdk();

  return sdk
    .getRepository('cart')
    .find(cartId, {
      fields: CART_FIELDS_FOR_BOOKING,
    })
    .then((cart) => {
      const filteredCart = filterCartOnEventDate(
        cart,
        `/v1/event_dates/${eventDateId}`
      );

      return filteredCart;
    });
}

export type ReplaceAvailableSeatFunction = (
  oldAvailableSeat: AvailableSeat,
  newAvailableSeat: AvailableSeat
) => void;

type UseCartReturnType = [Cart | null, ReplaceAvailableSeatFunction];

export default function useCart(
  cartId: number,
  eventDateId: number
): UseCartReturnType {
  const [cart, setCart] = useState<Cart | null>(null);
  const dispatch = useSeatingDispatch();

  useEffect(() => {
    getSingleCart(cartId, eventDateId).then((fetchedCart) => {
      const availableSeatList = getAvailableSeatListFromCart(fetchedCart);

      // @ts-expect-error AvailableSeatType and AvailableSeat are the same
      dispatch(autoSelectAvailableSeatList(availableSeatList));

      // keep `setCart` at last to be sure that the dispatch are done
      setCart(fetchedCart);
    });
  }, [cartId, dispatch, eventDateId]);

  const replaceAvailableSeat = useCallback(
    (oldAvailableSeat: AvailableSeat, newAvailableSeat: AvailableSeat) => {
      if (!cart) {
        return;
      }

      const newCart = cart.update('cartItemList', (cartItemList) => {
        assertRelationIsListOfObject(cartItemList, 'cartItemList');

        return cartItemList.map((cartItem: CartItem): CartItem => {
          return cartItem.update('availableSeatList', (availableSeatList) => {
            if (!availableSeatList) {
              return availableSeatList;
            }

            assertRelationIsListOfObject(
              availableSeatList,
              'availableSeatList'
            );

            const newAvailableSeatList = availableSeatList.map(
              (availableSeat: AvailableSeat): AvailableSeat => {
                if (availableSeat['@id'] === oldAvailableSeat['@id']) {
                  return newAvailableSeat;
                }

                return availableSeat;
              }
            );

            return newAvailableSeatList;
          });
        });
      });

      setCart(newCart);
    },
    [cart]
  );

  return [cart, replaceAvailableSeat];
}
