import React, { ReactElement, ReactNode } from 'react';
import { MpdIcon } from '@mapado/makeup';
import '../../styles/components/Sidebar.scss';
import { useTranslation } from '../../i18n';

type Props = {
  children: ReactNode;
  action?: ReactNode;
  onClose?: null | (() => void);
  className?: string;
};

function SidebarTemplate({
  children,
  action,
  onClose = null,
  className = '',
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={`mpd-seating-sidebar ${className}`}>
      {typeof onClose === 'function' && (
        <>
          <button
            type="button"
            onClick={onClose}
            className="mpd-seating-sidebar__close-seating-btn"
          >
            <MpdIcon icon="cross" height="24" width="24" />
            {t('close')}
          </button>
          <div className="mpd-seating-sidebar__close-seating-spacing" />
        </>
      )}
      <div
        className={`mpd-seating-sidebar__scrollable ${
          typeof onClose === 'function'
            ? 'mpd-seating-sidebar__scrollable--with-close'
            : ''
        }`}
      >
        {children}
      </div>
      {action && <div className="plan-purchase-sidebar__actions">{action}</div>}
    </div>
  );
}

export default SidebarTemplate;
