import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SeatReservedClient from '../client/SeatReservedClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import CartItem from './CartItem';
import EventDate from './EventDate';
import NetworkEntity from './NetworkEntity';
import Seat from './Seat';
import SeatConfig from './SeatConfig';
import Ticket from './Ticket';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SeatReservedType = BaseEntity<'SeatReserved'> & {
  seat: null | EntityRelation<Seat>;
  seatConfig: null | SeatConfig;
  eventDate: null | EntityRelation<EventDate>;
  ticket: null | EntityRelation<Ticket>;
  cartItem: null | EntityRelation<CartItem>;
  ticketPrice: null | EntityRelation<TicketPrice>;
  status: null | string;
  bookingStatus: null | string;
};

class SeatReserved extends NetworkEntity<SeatReservedType>({
  '@id': null,
  '@type': 'SeatReserved',
  seat: new Seat(),
  seatConfig: null,
  eventDate: new EventDate(),
  ticket: new Ticket(),
  cartItem: new CartItem(),
  ticketPrice: new TicketPrice(),
  status: null,
  bookingStatus: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SeatReservedType> = {
      '@id': null,
      '@type': 'SeatReserved',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seat_reserveds/', '') || '';
  }
}

SeatReserved.classMetadata = new ClassMetadata(
  'seatReserved',
  'seat_reserveds',
  /** @ts-expect-error -- method signature are incompatible */
  SeatReservedClient
);
SeatReserved.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('status'),
  new Attribute('bookingStatus'),
]);
SeatReserved.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seat', 'seat'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'ticket', 'ticket'),
  new Relation(Relation.MANY_TO_ONE, 'cartItem', 'cartItem'),
]);

export default SeatReserved;
