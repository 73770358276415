import { Moment } from 'moment-timezone';
import MomentInstance from '../MomentInstance';

export function parseDate(
  str: undefined,
  timezone: null | undefined | string
): undefined;
export function parseDate(
  str: null | '',
  timezone: null | undefined | string
): null;
export function parseDate(
  str: string | Moment,
  timezone: null | undefined | string
): Moment;
export function parseDate(
  str: null | undefined | string | Moment,
  timezone: null | undefined | string
): null | undefined | Moment;
export function parseDate(
  str: null | undefined | string | Moment,
  timezone: null | undefined | string
): null | undefined | Moment {
  if (str === null || typeof str === 'undefined') {
    return str;
  }

  if (!str) {
    return null;
  }

  let d = MomentInstance.moment.parseZone(str);

  if (timezone) {
    d = d.tz(timezone);
  }

  return d;
}
