import React, { forwardRef, ReactElement, Ref } from 'react';

import '../styles/components/SeatConfigBlock.css';

const SeatConfigBlock = forwardRef(function SeatConfigBlock(
  { id = null, info }: SeatConfigBlockTypes,
  ref?: Ref<SVGGElement>
): ReactElement {
  const elementProps: Record<string, string> = {};

  if (id !== null) {
    elementProps.id = id;
  }

  /**
   * The SeatConfigBlock is positionned the same way as the seats
   * @type {Object}
   */
  return (
    <g
      ref={ref}
      {...elementProps}
      className="mpd-seating__config__block"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: info }}
    />
  );
});

export type SeatConfigBlockTypes = {
  id: string | null;
  info: string;
};

export default SeatConfigBlock;
