import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReducedMobilityObstructedView = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 9 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M8.5 7.5V.977A.997.997 0 0 0 7.5 0H1a1 1 0 0 0-1 1v6.5a.996.996 0 0 0 1 1h6.5a1 1 0 0 0 .944-.67A1 1 0 0 0 8.5 7.5M6.581 8 8 6.356V7.5a.5.5 0 0 1-.5.5zM8 5.945 6.226 8H4.51l1.67-1.935.36.526.598-.432-.46-.671L8 3.956zm-1.479-.687L8 3.545V1.556L5.54 4.407h.398zm-1.337-.851L8 1.145V1a.5.5 0 0 0-.5-.5h-.66L4.503 3.208h.616v.745h-.72v.454zm-1.52-.228v.973h.877L3.4 6.475a1.27 1.27 0 0 1-.84-1.016zm.637-1.148h-.636v.737l-1.096 1.27a1.27 1.27 0 0 1 .683-.902l.091-.045v-.69l-.223.088a1.92 1.92 0 0 0-1.161 2.256L.5 7.435V5.445L4.769.5h1.716zM2.059 6.039.573 7.761A.5.5 0 0 0 1 8h1.083l.874-1.012a1.92 1.92 0 0 1-.898-.949m1.16 1.057L2.439 8h1.716l1.868-2.164-.468-.684h-.658L3.699 6.54a1.27 1.27 0 0 0 1.175-.56l.091-.136.538.358-.091.136a1.92 1.92 0 0 1-2.193.758M4.414.5.5 5.034V3.046L2.697.5zM2.342.5.5 2.635V1A.5.5 0 0 1 1 .5zm1.169 1.767c0-.288.228-.53.52-.53s.522.242.522.53-.229.53-.521.53a.525.525 0 0 1-.521-.53"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReducedMobilityObstructedView;
