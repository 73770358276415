import { connect } from 'react-redux';
import { List } from 'immutable';
import SeatingPlanPurchase, {
  SeatingPlanPurchaseProps,
  StateProps,
  DispatchProps,
} from '../../components/Main/SeatingPlanPurchase';
import {
  initBatchOfSeatsFromSeatList,
  resetState,
  addSeatToSelectedSeats,
  removeSeatFromSelectedSeats,
  setAvailableSeatListAvailable,
} from '../../actions/SeatActions';
import { initSeatingPlanPurchase } from '../../actions/AppActions';
import type { RootState } from '../../reducers';
import { getSelectableSeatIdSetForSeatingPlanPurchase } from '../../utils/seatSelectable';
import {
  getSelectedAvailableSeatList,
  isLoadedAvailableSeatList,
} from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => {
  return {
    seatConfig: state.seating.get('seatConfig'),
    seatGroupList: state.seating.get('seatGroupList') || List(),
    isReady: isLoadedAvailableSeatList(state),
    getSelectableSeatIdSet: getSelectableSeatIdSetForSeatingPlanPurchase,
    selectedSeatIdSet: state.seating.get('selectedSeatIdSet'),
    selectedAvailableSeatList: getSelectedAvailableSeatList(state) || List(),
  };
};

const mapDispatchToProps = {
  resetState,
  initSeatingPlanPurchase,
  addSeatToSelectedSeats,
  removeSeatFromSelectedSeats,
  initBatchOfSeatsFromSeatList,
  setAvailableSeatListAvailable,
};

export default connect<
  StateProps,
  DispatchProps,
  SeatingPlanPurchaseProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(SeatingPlanPurchase);
