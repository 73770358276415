import React, { ReactElement } from 'react';
import { OrderManagement } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';

type Props = {
  eventDateId: number;
};

export default function DemoOrderManagement({
  eventDateId,
}: Props): ReactElement {
  return (
    <>
      <EventDatePagesHeader
        title="Gestion des commandes"
        eventDateId={eventDateId}
      />

      <OrderManagement eventDateId={eventDateId} />
    </>
  );
}
