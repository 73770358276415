import { Attribute, ClassMetadata } from 'rest-client-sdk';
import FeatureClient from '../client/FeatureClient';
import NetworkEntity from './NetworkEntity';
import { BaseEntity } from '.';

// "@id" and "@type" not defined for this entity, but too much TypeScript brainfuck to avoid only this class
export type FeatureType = BaseEntity<'Feature'> & {
  'scanner_app-offline_mode': null | string;
  // eslint-disable-next-line camelcase
  mapadobox_socket_enabled: null | string;
};
class Feature extends NetworkEntity<FeatureType>({
  '@id': null,
  '@type': 'Feature',
  'scanner_app-offline_mode': null,
  mapadobox_socket_enabled: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;
}

/** @ts-expect-error -- method signature are incompatible */
Feature.classMetadata = new ClassMetadata('feature', 'features', FeatureClient);
Feature.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('scanner_app-offline_mode'),
  new Attribute('mapadobox_socket_enabled'),
]);

export default Feature;
