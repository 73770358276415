import React, { useEffect, useState, ReactElement } from 'react';
import { FormattedDateForStartAndEndDate } from '@mapado/js-component';
import MomentInstance from '../../MomentInstance';
import { EventDateType } from '../../propTypes';
import TicketingSdkInstance from '../../TicketingSdkInstance';

function EventDateCaptionSkeleton(): ReactElement {
  return (
    <div className="mpd-seating__app__event-caption">
      <div className="mpd-seating__app__event">
        <div className="mpd-seating__app__event-title">&nbsp;</div>

        <div className="mpd-seating__app__event-date">&nbsp;</div>
      </div>
    </div>
  );
}

function EventDateCaption({
  eventDate,
}: EventDateCaptionTypes): ReactElement | null {
  if (eventDate === null || !eventDate.ticketing) {
    return null;
  }

  const moment = MomentInstance.getMoment();
  const { ticketing, startDate, endDate } = eventDate;
  const { timezone, title } = ticketing;

  let localStartDate = null;
  let localEndDate = null;

  if (startDate) {
    localStartDate = moment.utc(startDate).tz(timezone);
  }

  if (endDate) {
    localEndDate = moment.utc(endDate).tz(timezone);
  }

  return (
    <div className="mpd-seating__app__event-caption">
      <div className="mpd-seating__app__event">
        <div className="mpd-seating__app__event-title">{title}</div>

        <div className="mpd-seating__app__event-date">
          <FormattedDateForStartAndEndDate
            startDate={localStartDate}
            endDate={localEndDate}
          />
        </div>
      </div>
    </div>
  );
}

type EventDateCaptionTypes = {
  eventDate: EventDateType;
};

export default function EventDateCaptionWithFetch({
  eventDateId,
}: EventDateCaptionWithFetchTypes): ReactElement {
  const [eventDate, setEventDate] = useState<null | EventDateType>(null);

  useEffect(() => {
    TicketingSdkInstance.getSdk()
      .getRepository('eventDate')
      .find(
        eventDateId,
        [
          '@id',
          'startDate',
          'endDate',
          { ticketing: ['@id', 'timezone', 'title'] },
        ],
        {}
      )
      .then((immutableEventDate) => {
        setEventDate(immutableEventDate.toJS() as EventDateType);
      });
  }, [eventDateId]);

  if (!eventDate) {
    return <EventDateCaptionSkeleton />;
  }

  return <EventDateCaption eventDate={eventDate} />;
}

type EventDateCaptionWithFetchTypes = {
  eventDateId: number;
};
