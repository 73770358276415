import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrossFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 25" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M4.536 21.506c4.714 4.658 12.312 4.613 16.97-.101s4.613-12.312-.101-16.97c-4.714-4.659-12.312-4.613-16.97.1-4.659 4.715-4.613 12.313.1 16.97m7.02-8.527L9.422 10.87l1.406-1.423 2.134 2.11 2.108-2.135 1.423 1.406-2.108 2.134 2.133 2.108-1.405 1.423-2.134-2.108-2.109 2.133-1.423-1.405z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCrossFilled;
