import clone from 'clone';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import ContractClient from '../client/ContractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import ContractAddendum from './ContractAddendum';
import CustomerStatus from './CustomerStatus';
import DeskPaymentMethod from './DeskPaymentMethod';
import DeviceState from './DeviceState';
import Feature from './Feature';
import LogicalSeatConfig from './LogicalSeatConfig';
import Minisite from './Minisite';
import NetworkEntity from './NetworkEntity';
import OfferRule from './OfferRule';
import OrganizationalUnit from './OrganizationalUnit';
import PaymentFee from './PaymentFee';
import ProviderTicketCredentials from './ProviderTicketCredentials';
import SeatConfig from './SeatConfig';
import SeatGroup from './SeatGroup';
import SellingDevice from './SellingDevice';
import Tag from './Tag';
import TicketingCategory from './TicketingCategory';
import Wallet from './Wallet';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const DOCUMENT_CONTRACT = 'contract';
export const DOCUMENT_GDPR = 'gdpr';
export const DOCUMENT_ADDENDUM = 'addendum';
export const DOCUMENT_CONFORMITY = 'conformity';

export type ContractType = BaseEntity<'Contract'> & {
  status: null | string;
  companyName: null | string;
  defaultCurrency: null | string;
  companyDisplayName: null | string;
  publicContactEmail: null | string;
  companyLegalForm: null | string;
  companyVatNumber: null | string;
  companyRecordNumber: null | string;
  companyRecordPlace: null | string;
  companyCapital: null | string;
  companyAddress: null | string;
  companyCountry: null | string;
  companyPostalCode: null | string;
  companyCity: null | string;
  companyType: null | string;
  legalRepresentativeLastName: null | string;
  legalRepresentativeFirstName: null | string;
  legalRepresentativeStatus: null | string;
  timezone: null | string;
  hideFees: boolean;
  notifyOnNewOrderSuccess: boolean;
  isSigned: boolean;
  offerRuleList: null | List<OfferRule>;
  seatConfigList: null | List<SeatConfig>;
  logicalSeatConfigList: null | List<LogicalSeatConfig>;
  seatGroupList: null | List<SeatGroup>;
  sellingDeviceList: null | List<SellingDevice>;
  walletList: null | List<Wallet>;
  minisiteList: null | List<Minisite>;
  providerTicketCredentialsList: null | List<ProviderTicketCredentials>;
  customerRequiredFields: null | Map<
    string,
    Map<'position' | 'slug' | 'required', unknown>
  >;
  gdprOptinMessage: null | string;
  templateParameters: null | Map<string, unknown>;
  customerFieldContractList: null | List<unknown>;
  deviceStateList: null | List<DeviceState>;
  deskPaymentMethodList: null | List<DeskPaymentMethod>;
  tagList: null | List<Tag>;
  additionalInformation: null | Map<string, unknown>;
  currentAccountabilityPeriodStartDate: null | Moment;
  currentAccountabilityPeriodEndDate: null | Moment;
  mailAdditionalInformation: null | string;
  locale: null | string;
  billingType: null | string;
  billingContactEmail: null | string;
  orderNotificationEmailList: null | List<string>;
  isOnboarded: null | boolean;
  onboardingData: null | Map<string, unknown>;
  paymentFee: null | PaymentFee;
  customerStatusList: null | List<CustomerStatus>;
  ticketingCategoryList: null | List<TicketingCategory>;
  contractAddendumList: null | List<ContractAddendum>;
  organizationalUnitList: null | List<EntityRelation<OrganizationalUnit>>;
  saleSessionEndTime: null | string;
  features: null | EntityRelation<Feature>;
};

// those fields are mandatory, if "fields" is given, then the value of "title" will be a non-nullable string
export type ContractNonNullProps =
  | '@id'
  | 'timezone'
  | 'billingType'
  | 'status'
  | 'isOnboarded'
  | 'isSigned'
  | 'hideFees'
  | 'notifyOnNewOrderSuccess'
  | 'locale'
  | 'accountabilityEndDate'
  | 'saleSessionEndTime';

class Contract extends NetworkEntity<ContractType>({
  '@id': null,
  '@type': 'Contract',
  status: 'waiting',
  companyName: '',
  defaultCurrency: null,
  companyDisplayName: null,
  publicContactEmail: '',
  companyLegalForm: null,
  companyVatNumber: '',
  companyRecordNumber: '',
  companyRecordPlace: '',
  companyCapital: '',
  companyAddress: '',
  companyCountry: '',
  companyPostalCode: '',
  companyCity: '',
  companyType: null,
  legalRepresentativeLastName: '',
  legalRepresentativeFirstName: '',
  legalRepresentativeStatus: '',
  timezone: null,
  hideFees: false,
  notifyOnNewOrderSuccess: false,
  isSigned: false,
  offerRuleList: List<OfferRule>(),
  seatConfigList: List<SeatConfig>(),
  logicalSeatConfigList: List<LogicalSeatConfig>(),
  seatGroupList: List<SeatGroup>(),
  sellingDeviceList: List<SellingDevice>(),
  walletList: List<Wallet>(),
  minisiteList: List<Minisite>(),
  providerTicketCredentialsList: List<ProviderTicketCredentials>(),
  customerRequiredFields: Map<
    string,
    Map<'position' | 'slug' | 'required', unknown>
  >(),
  gdprOptinMessage: null,
  templateParameters: Map<string, unknown>(),
  customerFieldContractList: List(),
  deviceStateList: List<DeviceState>(),
  deskPaymentMethodList: List<DeskPaymentMethod>(),
  tagList: List<Tag>(),
  additionalInformation: Map<string, unknown>(),
  currentAccountabilityPeriodStartDate: null,
  currentAccountabilityPeriodEndDate: null,
  mailAdditionalInformation: null,
  locale: null,
  billingType: '',
  billingContactEmail: '',
  orderNotificationEmailList: null,
  isOnboarded: null,
  onboardingData: Map<string, unknown>(),
  paymentFee: null,
  customerStatusList: null,
  ticketingCategoryList: null,
  contractAddendumList: null,
  organizationalUnitList: List<OrganizationalUnit>(),
  saleSessionEndTime: null,
  features: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ContractType> = { '@id': null, '@type': 'Contract' }
  ) {
    const data = clone(val);

    data.currentAccountabilityPeriodStartDate = parseDate(
      data.currentAccountabilityPeriodStartDate,
      data.timezone
    );

    data.currentAccountabilityPeriodEndDate = parseDate(
      data.currentAccountabilityPeriodEndDate,
      data.timezone
    );

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/contracts/', '') || '';
  }

  getLocalCurrentAccountabilityPeriodStartDate(): null | Moment {
    if (!this.currentAccountabilityPeriodStartDate) {
      return null;
    }

    const tz = this.timezone;
    if (!tz) {
      return null;
    }

    return this.currentAccountabilityPeriodStartDate.clone().tz(tz);
  }

  getLocalCurrentAccountabilityPeriodEndDate(): null | Moment {
    if (!this.currentAccountabilityPeriodEndDate) {
      return null;
    }

    const tz = this.timezone;
    if (!tz) {
      return null;
    }

    return this.currentAccountabilityPeriodEndDate.clone().tz(tz);
  }
}

Contract.classMetadata = new ClassMetadata(
  'contract',
  'contracts',
  /** @ts-expect-error -- method signature are incompatible */
  ContractClient
);
Contract.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('status'),
  new Attribute('companyName'),
  new Attribute('defaultCurrency'),
  new Attribute('companyLegalForm'),
  new Attribute('companyDisplayName'),
  new Attribute('companyType'),
  new Attribute('companyRecordPlace'),
  new Attribute('companyRecordNumber'),
  new Attribute('companyCapital'),
  new Attribute('companyAddress'),
  new Attribute('companyCountry'),
  new Attribute('billingType'),
  new Attribute('billingContactEmail'),
  new Attribute('orderNotificationEmailList'),
  new Attribute('mailAdditionalInformation'),
  new Attribute('publicContactEmail'),
  new Attribute('companyPostalCode'),
  new Attribute('companyCity'),
  new Attribute('companyVatNumber'),
  new Attribute('legalRepresentativeLastName'),
  new Attribute('legalRepresentativeFirstName'),
  new Attribute('legalRepresentativeStatus'),
  new Attribute('timezone'),
  new Attribute('hideFees', 'hideFees', 'boolean'),
  new Attribute(
    'notifyOnNewOrderSuccess',
    'notifyOnNewOrderSuccess',
    'boolean'
  ),
  new Attribute('isSigned', 'isSigned', 'boolean'),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('gdprOptinMessage'),
  new Attribute('templateParameters', 'templateParameters', 'object'),
  new Attribute('additionalInformation', 'additionalInformation', 'object'),
  new Attribute('locale'),
  new Attribute('isOnboarded', 'isOnboarded', 'boolean'),
  new Attribute('onboardingData', 'onboardingData', 'object'),
  new Attribute(
    'currentAccountabilityPeriodStartDate',
    'currentAccountabilityPeriodStartDate',
    'datetime'
  ),
  new Attribute(
    'currentAccountabilityPeriodEndDate',
    'currentAccountabilityPeriodEndDate',
    'datetime'
  ),
  new Attribute('saleSessionEndTime'),
]);
Contract.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'offerRule', 'offerRuleList'),
  new Relation(Relation.ONE_TO_MANY, 'seatConfig', 'seatConfigList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'logicalSeatConfig',
    'logicalSeatConfigList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'seatGroup', 'seatGroupList'),
  new Relation(Relation.ONE_TO_MANY, 'sellingDevice', 'sellingDeviceList'),
  new Relation(Relation.ONE_TO_MANY, 'wallet', 'walletList'),
  new Relation(Relation.MANY_TO_ONE, 'paymentFee', 'paymentFee'),
  new Relation(
    Relation.ONE_TO_MANY,
    'customerFieldContract',
    'customerFieldContractList'
  ),
  new Relation(
    Relation.ONE_TO_MANY,
    'deskPaymentMethod',
    'deskPaymentMethodList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'tag', 'tagList'),
  new Relation(Relation.ONE_TO_MANY, 'minisite', 'minisiteList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'providerTicketCredentials',
    'providerTicketCredentialsList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'customerStatus', 'customerStatusList'),
  new Relation(Relation.ONE_TO_MANY, 'deviceState', 'deviceStateList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'contractAddendum',
    'contractAddendumList'
  ),
  new Relation(
    Relation.ONE_TO_MANY,
    'organizationalUnit',
    'organizationalUnitList'
  ),
  new Relation(Relation.ONE_TO_ONE, 'feature', 'features'),
]);

export default Contract;
