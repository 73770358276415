import { Option, Value } from './types';

export enum SELECT_ACTION {
  CLOSE_DROPDOWN = 'CLOSE_DROPDOWN',
  OPEN_DROPDOWN = 'OPEN_DROPDOWN',
  TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN',
  SET_DROPDOWN_POSITION = 'SET_DROPDOWN_POSITION',
  ANIMATION_DONE = 'ANIMATION_DONE',
  CHANGE_SELECTED_VALUE = 'CHANGE_SELECTED_VALUE',
  FOCUS_NEXT_OPTION = 'FOCUS_NEXT_OPTION',
  FOCUS_PREVIOUS_OPTION = 'FOCUS_PREVIOUS_OPTION',
  SELECT_WIDTH = 'SELECT_WIDTH',
}

export type State = {
  openedDropdown: boolean;
  dropdownAnimationDone: boolean;
  focusedValue: Value;
  optionList: Array<Option>;
  isDropdownOnTop: boolean;
  selectWidth: number | undefined;
};

export type Action =
  | {
      type: typeof SELECT_ACTION.CLOSE_DROPDOWN;
    }
  | {
      type: typeof SELECT_ACTION.OPEN_DROPDOWN;
    }
  | {
      type: typeof SELECT_ACTION.TOGGLE_DROPDOWN;
    }
  | {
      type: typeof SELECT_ACTION.SET_DROPDOWN_POSITION;
      isDropdownOnTop: boolean;
    }
  | {
      type: typeof SELECT_ACTION.ANIMATION_DONE;
    }
  | {
      type: typeof SELECT_ACTION.CHANGE_SELECTED_VALUE;
      value: Value;
    }
  | {
      type: typeof SELECT_ACTION.FOCUS_NEXT_OPTION;
    }
  | {
      type: typeof SELECT_ACTION.FOCUS_PREVIOUS_OPTION;
    }
  | {
      type: typeof SELECT_ACTION.SELECT_WIDTH;
      width: number | undefined;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case SELECT_ACTION.CLOSE_DROPDOWN:
      return {
        ...state,
        openedDropdown: false,
        dropdownAnimationDone: false,
      };

    case SELECT_ACTION.OPEN_DROPDOWN:
      return {
        ...state,
        openedDropdown: true,
        dropdownAnimationDone: false,
      };

    case SELECT_ACTION.TOGGLE_DROPDOWN:
      return {
        ...state,
        openedDropdown: !state.openedDropdown,
      };

    case SELECT_ACTION.SET_DROPDOWN_POSITION:
      return {
        ...state,
        isDropdownOnTop: action.isDropdownOnTop,
      };

    case SELECT_ACTION.ANIMATION_DONE:
      return {
        ...state,
        dropdownAnimationDone: true,
      };

    case SELECT_ACTION.CHANGE_SELECTED_VALUE:
      return {
        ...state,
        focusedValue: action.value,
        openedDropdown: false,
        dropdownAnimationDone: false,
      };

    case SELECT_ACTION.FOCUS_NEXT_OPTION: {
      const { optionList, focusedValue } = state;

      const currentIndex = optionList.findIndex(
        (option) => option.value === focusedValue
      );

      if (currentIndex === optionList.length - 1) {
        return state;
      }

      const foundOption = optionList.find((option, index) => {
        if (index <= currentIndex) {
          return false;
        }

        if (option.disabled) {
          return false;
        }

        return true;
      });

      if (foundOption) {
        return {
          ...state,
          focusedValue: foundOption.value,
        };
      }

      return state;
    }

    case SELECT_ACTION.FOCUS_PREVIOUS_OPTION: {
      const { optionList, focusedValue } = state;

      const currentIndex = optionList.findIndex(
        (option) => option.value === focusedValue
      );

      if (currentIndex === 0) {
        return state;
      }

      const foundOption = optionList
        .slice()
        .reverse()
        .find((option) => {
          const index = optionList.findIndex(
            (opt) => opt.value === option.value
          );

          if (index >= currentIndex) {
            return false;
          }

          if (option.disabled) {
            return false;
          }

          return true;
        });

      if (foundOption) {
        return {
          ...state,
          focusedValue: foundOption.value,
        };
      }

      return state;
    }

    case SELECT_ACTION.SELECT_WIDTH:
      return {
        ...state,
        selectWidth: action.width,
      };

    default:
      // @ts-expect-error -- let the runtime fail in this case
      throw new Error(`action type ${action.type} is not authorized`);
  }
}

export default reducer;
