import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAction = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 7 26" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-815 -1044)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="action"
            d="M720.667 229.667a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667m0 9.333a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667m0 9.333a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAction;
