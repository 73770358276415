import React, { useRef } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import MpdIcon from './MpdIcon';

// Make dynamic import when we will do internationalization
import 'flatpickr/dist/l10n/fr.js';
import 'flatpickr/dist/l10n/de.js';
import { useTranslation } from '../i18n';

type Props = DateTimePickerProps & {
  onClear?: () => void;
};

function MpdCalendar({ options, onClear, ...rest }: Props): JSX.Element | null {
  // do use translation hook to get the translation function
  // do not use Suspense and fallback to an empty string. The translation should work on server side and when client download the translations
  // This might display a warning in the console: "Did not expect server HTML to contain the text node "lundi 28 février 2022 à 18:51" in <div>."
  // but this will work fine and be SEO friendly
  const { i18n, ready } = useTranslation(undefined, { useSuspense: false });
  const flatpickrRef = useRef<Flatpickr | null>(null);

  if (!ready) {
    return null;
  }

  // In the minisite context, with NextJS, the import of react-flatpickr is some weird CommonJS exports
  // so we have an object containing `default` instead of a default export
  // @ts-expect-error default is not defined on the type
  const FlatpickrComponent = Flatpickr.default || Flatpickr;

  const formatObj = new Intl.DateTimeFormat(i18n.language).formatToParts(
    new Date()
  );

  let dateFormat = formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'd';
        case 'month':
          return 'm';
        case 'year':
          return 'Y';
        default:
          return obj.value;
      }
    })
    .join('');

  if (options?.enableTime) {
    dateFormat = dateFormat.concat(' H:i');
  }

  let onClose = options?.onClose;

  // allowInput option does not trigger onChange event = we need to trigger onClose event
  if (options?.allowInput && options?.onChange && !onClose) {
    onClose = options.onChange;
  }

  const flatpickrObj = flatpickrRef.current?.flatpickr;

  const { className, value } = rest;
  const inputIsHidden = className?.includes('flatpickr-input--inline');

  let updatedValidValue = value;

  // assure the date format sent to flatpickr is iso
  if (typeof value === 'string' || typeof value === 'number') {
    updatedValidValue = new Date(value);
  }

  const restUpdated = {
    ...rest,
    // if no value prop sent to MpdCalendar, we don't want it for flatpickr
    ...(value ? { value: updatedValidValue } : {}),
  };

  return (
    <div className="flatpickr-container">
      <FlatpickrComponent
        ref={flatpickrRef}
        options={{
          locale: i18n.language,
          dateFormat,
          altFormat: dateFormat,
          onClose,
          ...options,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restUpdated}
      />
      {!inputIsHidden && (
        <>
          <button
            className="mpd-btn mpd-btn--icon-only flatpickr-calendar-btn"
            type="button"
            onClick={() => {
              if (flatpickrRef.current) {
                flatpickrObj?.open();
              }
            }}
          >
            <MpdIcon
              className="flatpickr-calendar-icon"
              icon="agenda"
              width="20"
            />
          </button>
          {flatpickrObj?.input.value && onClear && (
            <button
              className="mpd-btn mpd-btn--icon-only flatpickr-clear-btn"
              type="button"
              onClick={() => {
                if (onClear) {
                  onClear();
                }

                // using .setDate() instead of .clear() because otherwise it sets the input to today's date (bug)
                flatpickrObj?.setDate('');
              }}
            >
              <MpdIcon icon="cross" width="12" />
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default MpdCalendar;
