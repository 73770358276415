import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowTop = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 13" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M9.535.332.124 9.426l2.854 2.951 6.459-6.242 6.244 6.458 2.952-2.853z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowTop;
