import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGeopoint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlSpace="preserve"
    id="Calque_1"
    x={0}
    y={0}
    viewBox="0 0 24 24"
    className="mpd-icon"
    {...props}
  >
    <path d="M12 4c-3.3 0-6 2.7-6 6s2.7 8 6 11c3.3-3 6-7.7 6-11s-2.7-6-6-6m0 9c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3" />
  </svg>
);
export default SvgGeopoint;
