import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class DeskPaymentMethodClient extends AbstractClient<
  TSMetadata['deskPaymentMethod']
> {
  getPathBase(): string {
    return '/v1/desk_payment_methods';
  }
}

export default DeskPaymentMethodClient;
