/* eslint-disable no-console */
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import TicketingSdk, { Contract } from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { MpdToastProvider } from '@mapado/makeup';
import { Provider } from 'react-redux';
import { LogicalSeatConfigAdmin, SeatingPlanViewer } from '../../src/index';
import { PlanEditor } from './demoSpecificImports';
import SeatConfigSelector from './SeatConfigSelector';
import { getLongId } from '../../src/utils/entity';
import { SeatConfigShape } from './DemoTypes';
import DemoSeatingPlanPurchase from './pages/seatingPlanPurchase';
import DemoOrderManagement from './pages/orderManagement';
import DemoOrderReplacement from './pages/order';
import DemoCartReplacement from './pages/reservation';
import DemoOrderViewer from './pages/viewer';
import DemoMultiContext from './pages/multi-context';
import DemoStockManagement from './pages/stockManagement';
import configureStore from './config/configureStore';

const store = configureStore();

interface Props {
  seatConfigList: List<SeatConfigShape>;
  sdk: TicketingSdk;
}

export default function DemoContent({
  seatConfigList,
  sdk,
}: Props): JSX.Element {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getNumberParam = (key: string): number | null => {
    return searchParams.has(key) ? Number(searchParams.get(key)) : null;
  };

  const seatConfigId = Number(getNumberParam('seatConfigId') ?? 103);
  const logicalSeatConfigId = Number(
    getNumberParam('logicalSeatConfigId') || 244
  );
  let selectedSeatConfig = null;

  if (searchParams.has('seatConfigId')) {
    selectedSeatConfig = seatConfigList.find(
      (sc) =>
        sc['@id'] ===
        getLongId(
          '/v1/seat_configs/',
          searchParams.get('seatConfigId') as string
        )
    );
  } else if (searchParams.has('index')) {
    selectedSeatConfig = seatConfigList
      .sortBy((seatConfig) => seatConfig.label)
      .get(Number(searchParams.get('index') as string));
  } else {
    selectedSeatConfig = seatConfigList.first();
  }

  return (
    <Provider store={store}>
      <MpdToastProvider>
        <div className="seating-demo-root__tabContent">
          <Switch>
            <Route exact path="/viewer">
              <DemoOrderViewer
                cartId={getNumberParam('cartId') ?? 1125668}
                eventDateId={getNumberParam('eventDateId') ?? 133018}
              />
            </Route>

            <Route exact path="/stockManagement">
              <DemoStockManagement
                eventDateId={getNumberParam('eventDateId') ?? 262104}
              />
            </Route>

            <Route exact path="/seatingPlanPurchase">
              <DemoSeatingPlanPurchase
                sdk={sdk}
                eventDateId={getNumberParam('eventDateId') ?? 352518}
              />
            </Route>

            <Route exact path="/orderManagement">
              <DemoOrderManagement
                eventDateId={getNumberParam('eventDateId') ?? 133018}
              />
            </Route>

            <Route exact path="/order">
              <DemoOrderReplacement
                orderId={getNumberParam('orderId') ?? 805392}
                eventDateId={getNumberParam('eventDateId') ?? 133018}
              />
            </Route>

            <Route exact path="/reservation">
              <DemoCartReplacement
                cartId={getNumberParam('cartId') ?? 1125668}
                eventDateId={getNumberParam('eventDateId') ?? 133018}
              />
            </Route>

            <Route exact path="/config">
              <h3>Configuration de salle</h3>

              <SeatConfigSelector
                seatConfigList={seatConfigList}
                selectedSeatConfig={selectedSeatConfig}
              />
              <SeatingPlanViewer
                key={`${seatConfigId}`}
                seatConfigId={seatConfigId}
              />
            </Route>

            <Route exact path="/editor">
              <h3>Editor</h3>
              <SeatConfigSelector
                seatConfigList={seatConfigList}
                selectedSeatConfig={selectedSeatConfig}
              />
              <PlanEditor key={`${seatConfigId}`} seatConfigId={seatConfigId} />
            </Route>

            <Route exact path="/logicalSeatConfigAdmin">
              <h3>Administration modèle de salle</h3>

              <LogicalSeatConfigAdmin
                contract={
                  new Contract({
                    '@id': '/v1/contracts/237 ',
                    '@type': 'Contract',
                  })
                }
                logicalSeatConfigId={logicalSeatConfigId}
              />
            </Route>

            <Route exact path="/multi-context">
              <h3>Multi-contexte</h3>

              <DemoMultiContext
                eventDateId={getNumberParam('eventDateId') ?? 133018}
              />
            </Route>
          </Switch>
        </div>
      </MpdToastProvider>
    </Provider>
  );
}
