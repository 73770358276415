import localforage from 'localforage';
import TicketingSdk from 'mapado-ticketing-js-sdk';
import {
  AuthorizationCodeFlowTokenGenerator,
  TokenStorage,
} from 'rest-client-sdk';
import { TicketingSdkInstance } from '../../../src';
import getSdkConfig, { PUBLICID, SECRET, SCOPES } from './sdkConfig';

// eslint-disable-next-line import/prefer-default-export
export function configureTicketingSdk(): TicketingSdk {
  const sdkConfig = getSdkConfig();
  const authCodeFlowConfig = {
    ...sdkConfig.oauth,
    clientId: PUBLICID,
    clientSecret: SECRET,
    redirectUri: `${window.location.protocol}//${window.location.host}/oauth-callback`,
    scope: SCOPES,
  };
  const tokenGenerator = new AuthorizationCodeFlowTokenGenerator(
    authCodeFlowConfig
  );
  const tokenStorage = new TokenStorage(tokenGenerator, localforage);

  TicketingSdkInstance.createTicketingSdk(tokenStorage, sdkConfig.ticketing);

  return TicketingSdkInstance.getSdk();
}
