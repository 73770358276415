import { Map } from 'immutable';
import { Iri } from '../entity';
import Cart from '../entity/Cart';
import Customer from '../entity/Customer';
import Order from '../entity/Order';
import { TSMetadata } from '../mapping';
import { FIELD_PREFIX_SLUG } from '../types/CustomerFieldContractTypes';
import { CUSTOMER_TYPE } from '../types/CustomerTypes';
import { FormData } from '../types/FormDataType';
import {
  getShoppingValues,
  getOrganization,
  getCustomerFormValues,
  mergeCustomerFormValues,
  getContact,
  getNewCustomersIds,
} from '../utils/formData';
import AbstractClient, { QueryParam } from './AbstractClient';

class FormDataClient<
  D extends TSMetadata['cart'] | TSMetadata['order']
> extends AbstractClient<D> {
  async #updateCustomersEntity<E extends Cart | Order>(
    entityId: string,
    customerId: Iri<'Customer'> | null,
    contactId: Iri<'Customer'> | null,
    queryParam: QueryParam,
    requestParams?: Record<string, unknown>
  ): Promise<E> {
    const response = await this.find(entityId, ['customer', 'contact']);

    /** @ts-expect-error -- D["entity"]>' is not assignable to type 'E' */
    return this.update(
      /** @ts-expect-error -- method signature are incompatible */
      response.set('customer', customerId).set('contact', contactId),
      queryParam,
      requestParams
    );
  }

  async updateFormData<E extends Cart | Order>(
    formData: FormData,
    entity: E,
    queryParam: QueryParam,
    requestParams?: Record<string, unknown>
  ): Promise<E> {
    const customerKeys: string[] = Map(new Customer()).keySeq().toArray();
    // Retrieve form values related to entity Cart or Order
    const shoppingValues = getShoppingValues(formData);

    // Retrieve entity organization
    // Then, retrieve all form values related to organization
    // add these values to existing or new organization
    // if no value, organization is null
    const organization = getOrganization(entity);
    const organizationValues = getCustomerFormValues(
      formData,
      FIELD_PREFIX_SLUG.ORGANIZATION,
      customerKeys
    );

    const customerType = organizationValues
      ? CUSTOMER_TYPE.ORGANIZATION
      : CUSTOMER_TYPE.CONTACT;

    const organizationWithValues = mergeCustomerFormValues(
      organization ??
        new Customer({ '@id': null, type: CUSTOMER_TYPE.ORGANIZATION }),
      organizationValues,
      customerType === CUSTOMER_TYPE.ORGANIZATION ? shoppingValues : null
    );

    // Retrieve entity contact
    // Then, retrieve all form values related to contact
    // add these values to existing or new contact
    // if no value, contactWithValues is null
    const contact = getContact(entity);
    const contactValues = getCustomerFormValues(
      formData,
      FIELD_PREFIX_SLUG.CONTACT,
      customerKeys
    );

    const contactWithValues = mergeCustomerFormValues(
      contact ?? new Customer({ '@id': null, type: CUSTOMER_TYPE.CONTACT }),
      contactValues,
      customerType === CUSTOMER_TYPE.CONTACT ? shoppingValues : null
    );

    // Update organization and/or contact if exist
    const updatePromiseList = [
      organization ? organizationWithValues : null,
      contact ? contactWithValues : null,
    ]
      .filter(Boolean)
      .map((customer) =>
        this.sdk
          .getRepository('customer')
          .update(customer, { fields: customerKeys })
      );
    await Promise.all(updatePromiseList);

    // Create organization and/or contact if does not exist
    const createPromiseList = [
      !organization ? organizationWithValues : null,
      !contact ? contactWithValues : null,
    ]
      .filter(Boolean)
      .map((customer) =>
        this.sdk
          .getRepository('customer')
          .create(customer, { fields: customerKeys })
      );

    const newCustomerList = await Promise.all(createPromiseList);

    // If an organization and/or contact has been created, we update Entity with their IRIs
    if (newCustomerList.length) {
      const { customerId, contactId } = getNewCustomersIds(
        entity,
        newCustomerList
      );

      return (this.#updateCustomersEntity<E>(
        entity.getShortId(),
        customerId,
        contactId,
        queryParam,
        requestParams
      ) as unknown) as E;
    }

    const { fields } = queryParam;

    return (this.find(entity.getShortId(), fields) as unknown) as E;
  }

  async updateCustomerContact<E extends Cart | Order>(
    entity: E,
    customer: Customer,
    contact: Customer | null,
    queryParam: QueryParam,
    requestParams?: Record<string, unknown>
  ): Promise<E> {
    const createdCustomer: Customer = await this.sdk
      .getRepository('customer')
      .create(customer, { fields: ['@id'] });

    const createdContact: Customer | null = contact
      ? await this.sdk
          .getRepository('customer')
          .create(contact, { fields: ['@id'] })
      : null;

    return this.#updateCustomersEntity<E>(
      entity.getShortId(),
      createdCustomer.get('@id'),
      createdContact?.get('@id') ?? null,
      queryParam,
      requestParams
    );
  }
}

export default FormDataClient;
