import React, { PureComponent, ReactElement } from 'react';
import { Set } from 'immutable';
import {
  Cart,
  assertRelationIsDefined,
  assertRelationIsObject,
} from 'mapado-ticketing-js-sdk';
import { MpdToastFunction, useMpdToast } from '@mapado/makeup';
import SidebarTemplate from '../Sidebar/SidebarTemplate';
import Seating from '../Seating';
import EventDateCaptionWithFetch from '../Caption/EventDateCaption';
import SeatingLoader from '../SeatingLoader';
import SvgDrawer from '../../utils/SvgDrawer';
import '../../styles/components/App.scss';
import '../../styles/contexts/reservation.css';

import { SeatConfigType, AvailableSeatType } from '../../propTypes';
import {
  getSelectableSeatIdSetForOrderViewer,
  shouldDisplayChangeSeatGroupWarning,
  findCartAvailableSeatBySeatId,
} from '../../utils/seatSelectable';
import { SelectBatchOfSeats } from '../../utils/drawers/FreeHandDrawSeatSelector';
import CartReplacementSeat from '../Seat/CartReplacementSeat';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import CartSidebar from '../CartSidebar';
import useCart, { ReplaceAvailableSeatFunction } from '../useCart';
import { CartContextProvider } from '../CartReplacement/CartContext';
import { SelectOrMoveSeatAction } from '../../actions/SeatActions';
import { isCartEntity } from '../../utils/booking';
import { TFunction, useTranslation } from '../../i18n';
import OtherCategoryReplacementModal from '../Modal/OtherCategoryReplacementModal';

type State = {
  isChangeSeatGroupModalOpened: boolean;
  oldCategory: string | null;
  newCategory: string | null;
  selectedSeat: AvailableSeatType | null;
};

class CartReplacement extends PureComponent<Props, State> {
  static defaultProps = {
    onDismissSeating: null,
  };

  // eslint-disable-next-line react/sort-comp
  #svgDrawer: SvgDrawer;

  constructor(props: Props) {
    super(props);

    this.state = {
      isChangeSeatGroupModalOpened: false,
      newCategory: null,
      oldCategory: null,
      selectedSeat: null,
    };

    this.init = this.init.bind(this);
    this.setRef = this.setRef.bind(this);
    this.selectOrMoveSeat = this.selectOrMoveSeat.bind(this);

    this.#svgDrawer = new SvgDrawer({
      // eslint-disable-next-line react/destructuring-assignment
      selectBatchOfSeats: this.props.selectBatchOfSeats,
    });
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(prevProps: Props): void {
    const { selectedSeatIdSet } = this.props;

    if (!prevProps.selectedSeatIdSet.equals(selectedSeatIdSet)) {
      this.#svgDrawer.handleSelectedSeatIdListChange(selectedSeatIdSet);
    }
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  setRef(ref: HTMLDivElement): void {
    // seems weird
    if (ref) {
      this.#svgDrawer.init();
    }
  }

  init(): void {
    const { cartId, eventDateId, initCartReplacement } = this.props;

    initCartReplacement(cartId, eventDateId);
  }

  selectOrMoveSeat(
    seatEntity: AvailableSeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    const { toast, selectOrMoveSeat, cart, replaceAvailableSeat, t } =
      this.props;

    const { isChangeSeatGroupModalOpened } = this.state;

    const { selectedSeatIdSet } = this.props;

    const movingSeatId = selectedSeatIdSet.first();

    if (!cart || !isSelectable) {
      return;
    }

    const isCurrentBooking = isCartEntity(seatEntity);

    const shouldDisplayChangeSeatGroupModal =
      !isCurrentBooking &&
      shouldDisplayChangeSeatGroupWarning(seatEntity, cart, movingSeatId);

    if (
      !shouldDisplayChangeSeatGroupModal ||
      isChangeSeatGroupModalOpened ||
      !movingSeatId
    ) {
      const seatId = getSeatIdForAvailableSeat(seatEntity);

      selectOrMoveSeat(
        cart,
        seatId,
        isSelectable,
        isCurrentBooking,
        null,
        replaceAvailableSeat,
        this.init,
        toast,
        t
      );

      this.setState({
        isChangeSeatGroupModalOpened: false,
        oldCategory: null,
        newCategory: null,
      });
    } else {
      const availableSeat = findCartAvailableSeatBySeatId(cart, movingSeatId);

      assertRelationIsDefined(availableSeat, 'availableSeat');
      assertRelationIsObject(
        availableSeat.seatGroup,
        'availableSeat.seatGroup'
      );
      assertRelationIsObject(seatEntity.seatGroup, 'seatEntity.seatGroup');

      this.setState({
        oldCategory: availableSeat.seatGroup.label,
        newCategory: seatEntity.seatGroup.label,
      });
      this.setState({
        selectedSeat: seatEntity,
        isChangeSeatGroupModalOpened: true,
      });
    }
  }

  render(): ReactElement {
    const {
      selectedSeatIdSet,
      getSelectableSeatIdSet,
      isReady,
      eventDateId,
      seatConfig,
      onDismissSeating,
      cart,
    } = this.props;

    const {
      isChangeSeatGroupModalOpened,
      oldCategory,
      newCategory,
      selectedSeat,
    } = this.state;

    if (!isReady) {
      return <SeatingLoader />;
    }

    if (!seatConfig) {
      throw new Error('Unable to find seatConfig. This should not happen.');
    }

    return (
      <div
        className="mpd-seating__app context-reservation"
        id="mpd-seating__app"
      >
        <div className="mpd-seating__app__container" ref={this.setRef}>
          <div className="mpd-seating__app__event-svg-wrapper">
            <EventDateCaptionWithFetch eventDateId={eventDateId} />
            <Seating
              seatEntityType="AvailableSeat"
              SeatElement={CartReplacementSeat}
              isMovingSeat={selectedSeatIdSet.size > 0}
              getSelectableSeatIdSet={(state, seatIdSet) => {
                if (cart) {
                  return getSelectableSeatIdSet(state, seatIdSet, cart);
                }

                return Set();
              }}
              onClickSeat={this.selectOrMoveSeat}
              seatConfigBlockList={
                seatConfig ? seatConfig.seatConfigBlockList : []
              }
            />
          </div>
          <SidebarTemplate onClose={onDismissSeating}>
            {cart && (
              <CartSidebar
                seatConfig={seatConfig}
                selectedSeatIdSet={selectedSeatIdSet}
                cart={cart}
              />
            )}
          </SidebarTemplate>

          {isChangeSeatGroupModalOpened && (
            <OtherCategoryReplacementModal
              oldCategory={oldCategory}
              newCategory={newCategory}
              onCancel={() =>
                this.setState({
                  isChangeSeatGroupModalOpened: false,
                  oldCategory: null,
                  newCategory: null,
                })
              }
              onConfirm={() => {
                if (selectedSeat) {
                  this.selectOrMoveSeat(selectedSeat, true, true);
                }
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export type CartReplacementProps = {
  eventDateId: number;
  cartId: number;
  /** @deprecated should be unused */
  onDismissSeating?: null | (() => void);
};

type PropsFromContainer = {
  toast: MpdToastFunction;
  cart: null | Cart;
  replaceAvailableSeat: ReplaceAvailableSeatFunction;
  t: TFunction;
};

export type StateProps = {
  seatConfig: null | SeatConfigType;
  isReady: boolean;
  // should be move in SeatingContainer ?
  getSelectableSeatIdSet: typeof getSelectableSeatIdSetForOrderViewer;
  selectedSeatIdSet: Set<number>;
};

export type DispatchProps = {
  initCartReplacement: (cartId: number, eventDateId: number) => void;
  selectBatchOfSeats: SelectBatchOfSeats;
  selectOrMoveSeat: SelectOrMoveSeatAction;
  resetState: () => void;
};

type Props = CartReplacementProps &
  PropsFromContainer &
  StateProps &
  DispatchProps;

export default function CartReplacementContainer(
  props: Omit<Props, keyof PropsFromContainer>
): ReactElement {
  const { cartId, eventDateId } = props;
  const toast = useMpdToast();
  const [cart, replaceAvailableSeat] = useCart(cartId, eventDateId);
  const { t } = useTranslation();

  return (
    <CartContextProvider cart={cart}>
      <CartReplacement
        {...props}
        cart={cart}
        replaceAvailableSeat={replaceAvailableSeat}
        toast={toast}
        t={t}
      />
    </CartContextProvider>
  );
}
