import React, { useCallback, ReactElement, useEffect, useState } from 'react';
import { MpdLoader } from '@mapado/makeup';
import { Booking } from 'mapado-ticketing-js-sdk';
import { focusBooking, unfocusBooking } from '../../../actions/SeatActions';
import { getBookingCollection, resetBooking } from '../BookingActions';
import { useFocusedBooking } from '../../../utils/selectors';
import BookingLine, { BookingNullableCallback } from './BookingLine';
import BookingTile from './BookingTile';
import '../../../styles/components/Sidebar/BookingCollection.scss';
import { useBookingList } from '../BookingListProvider';
import { useSeatingDispatch } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

type BookingCollectionProps = {
  eventDateId: string;
  contractId: string;
  focusBookingOnSeatPlan: BookingNullableCallback;
  unFocusBookingOnSeatPlan: BookingNullableCallback;
  searchFilter: string;
  canMoveSeats: boolean;
};

function BookingCollection({
  eventDateId,
  contractId,
  focusBookingOnSeatPlan,
  unFocusBookingOnSeatPlan,
  searchFilter,
  canMoveSeats,
}: BookingCollectionProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();
  const focusedBooking = useFocusedBooking();
  const [isFromSearch, setIsFromSearch] = useState(false);
  const hasSearchFilter = searchFilter.length > 0;

  const { state: bookingState, dispatch: bookingDispatch } = useBookingList();

  const bookingCollection = bookingState.get('bookingCollection');
  const currentPage = bookingState.get('currentPage');

  const isLoading =
    bookingState.getIn(['bookingCollection-fetch-status', 'status']) ===
    'IN_PROGRESS';

  const bookingError = bookingState
    .get('bookingCollection-fetch-status')
    .get('error');

  const loadingComplete =
    bookingState.getIn(['bookingCollection-fetch-status', 'status']) ===
    'SUCCEEDED';

  const hasMoreBookings = bookingState.get('hasMoreBookings');

  const bookingListToDisplay = bookingCollection.getMembers();

  const handleOnClick = (booking: Booking) => {
    setIsFromSearch(true);
    focusBookingOnSeatPlan(booking);
    dispatch(focusBooking(booking));
  };

  const getBookings = useCallback(
    (firstPage = true) => {
      getBookingCollection({
        dispatch: bookingDispatch,
        eventDateId,
        contractId,
        currentPage,
        getFirstPage: firstPage,
        search: searchFilter,
        itemsPerPage: 50,
      });
    },
    [bookingDispatch, eventDateId, contractId, currentPage, searchFilter]
  );

  useEffect(() => {
    if (!hasSearchFilter) {
      return;
    }

    const timeout = setTimeout(getBookings, 300);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [hasSearchFilter, getBookings]);

  useEffect(() => {
    setIsFromSearch(false);
    dispatch(unfocusBooking());
    dispatch(resetBooking());
  }, [dispatch, searchFilter]);

  const displayPlaceholderDescription = !searchFilter && !isLoading;

  useEffect(() => {
    if (isLoading) {
      setIsFromSearch(false);
    }
  }, [isLoading]);

  if (focusedBooking) {
    return (
      <BookingTile
        booking={focusedBooking}
        eventDateId={eventDateId}
        isFromSearch={isFromSearch}
        canMoveSeats={canMoveSeats}
      />
    );
  }

  return (
    <div className="booking-collection">
      {displayPlaceholderDescription && (
        <div className="booking-collection__description">
          {t('booking.description')}
        </div>
      )}

      {isLoading && (
        <div className="pt5">
          <MpdLoader />
        </div>
      )}

      {bookingError && (
        <div className="mpd-alert mpd-alert--error">{bookingError}</div>
      )}
      {!!bookingListToDisplay.size && !isLoading && (
        <div className="booking-collection__list">
          <h3 className="booking-collection__list__title">
            {t('order_management.select_order')}
          </h3>
          {bookingListToDisplay.map((booking) => (
            <BookingLine
              key={booking.get('@id')}
              booking={booking}
              onClick={handleOnClick}
              focusBookingOnSeatPlan={focusBookingOnSeatPlan}
              unFocusBookingOnSeatPlan={unFocusBookingOnSeatPlan}
            />
          ))}
          {hasMoreBookings && (
            <div className="txtcenter">
              <button
                type="button"
                className={`mpd-btn mpd-btn--link ${
                  isLoading ? 'mpd-btn--loading' : ''
                }`}
                onClick={(): void => getBookings(false)}
              >
                {t('common.see_more')}
              </button>
            </div>
          )}
        </div>
      )}

      {loadingComplete && bookingListToDisplay.size === 0 && (
        <div className="pt5">{t('no_result')}</div>
      )}
    </div>
  );
}

export default BookingCollection;
