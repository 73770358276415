import React, { FocusEvent, useRef, useState } from 'react';
import Select, { InputAction, SelectInstance, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import cn from 'classnames';
import { generateRandomID } from '../../../js-libs/utils';
import DropdownIndicator from './common/DropdownIndicator';
import ClearIndicator from './common/ClearIndicator';
import LoadingIndicator from './common/LoadingIndicator';
import Option from './common/Option';
import ValueContainer from './common/ValueContainer';
import Input from './common/Input';
import { useTranslation } from '../../../i18n';
import { getSelectTheme, SingleSelectOption } from './common/utils';
import useLoadData, { LoadOptionsType } from './common/useLoadData';
import debounce from '../../../utils/debounce';

export type MpdSingleSelectProps = {
  id?: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  creatable?: boolean;
  name?: string;
  value?: SingleSelectOption | null;
  classnames?: string;
  errorMessage?: string;
  helpMessage?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (option: SingleSelectOption | null) => void;
} & (
  | {
      loadOptions: LoadOptionsType<SingleSelectOption>;
      options?: never;
    }
  | {
      loadOptions?: never;
      options: SingleSelectOption[] | [];
    }
) &
  (
    | {
        searchable: true;
        defaultValue?: never;
      }
    | {
        searchable?: false;
        defaultValue?: SingleSelectOption;
      }
  );

function MpdSingleSelect({
  id,
  placeholder,
  label,
  options,
  loadOptions,
  required = false,
  disabled = false,
  searchable = false,
  creatable = false,
  name,
  value,
  defaultValue,
  classnames,
  errorMessage,
  helpMessage,
  onFocus,
  onBlur,
  onChange,
}: MpdSingleSelectProps): JSX.Element {
  const { t } = useTranslation();
  const selectRef = useRef<SelectInstance<SingleSelectOption, false> | null>(
    null
  );
  const [isLoading, hasMore, loadedOptions, loadData, searchData] = useLoadData<
    SingleSelectOption
  >(loadOptions);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentValue, setCurrentValue] = useState<
    SingleValue<SingleSelectOption> | undefined
  >(value);
  const [inputValue, setInputValue] = useState(value?.label || ''); // we need to control component to manage input selection. cf. https://github.com/JedWatson/react-select/issues/4030

  const isSearchable = creatable || searchable; // creatable is always searchable

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (currentValue) {
      selectRef.current?.inputRef?.select();
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (option: SingleValue<SingleSelectOption>) => {
    setCurrentValue(option);
    setInputValue(option?.label ?? '');

    if (onChange) {
      onChange(option);
    }
  };

  const handleInputChange = (
    newValue: string,
    { action }: { action: InputAction }
  ) => {
    const query = newValue || null;

    if (action === 'input-change') {
      setInputValue(newValue);
      searchData(query);
    }

    if (action === 'menu-close') {
      searchData(null);
    }
  };

  const handleLoadMore = () => {
    if (!hasMore) {
      return;
    }

    loadData(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const getOptionsProps = () => {
    if (typeof loadOptions === 'function') {
      return {
        isLoading,
        loadingMessage: () => t('loading'),
        options: loadedOptions,
        onInputChange: debounce(handleInputChange, 600),
        onMenuScrollToBottom: debounce(handleLoadMore),
      };
    }

    if (isSearchable) {
      return {
        options, // options have been set as prop. No need to load options
        onInputChange: handleInputChange,
        inputValue,
        controlShouldRenderValue: false,
        openMenuOnClick: creatable ? inputValue.length === 0 : true,
      };
    }

    return {
      options, // options have been set as prop. No need to load options
      defaultValue,
    };
  };

  const optionsProps = getOptionsProps();
  const components = {
    DropdownIndicator,
    ClearIndicator,
    LoadingIndicator,
    Option,
    ...(isSearchable ? { Input } : { ValueContainer }),
  };
  const selectId = id ?? generateRandomID();
  const SelectComponent = creatable ? Creatable : Select;

  return (
    <div className={cn('mpd-single-select', classnames)}>
      {label && (
        <label htmlFor={selectId}>
          {label}
          {required && <span className="required" />}
        </label>
      )}
      <SelectComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...optionsProps}
        ref={selectRef}
        id={selectId}
        name={name}
        placeholder={placeholder}
        required={required}
        value={currentValue}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isSearchable}
        isOptionDisabled={(option: SingleSelectOption) =>
          option.disabled ?? false
        }
        formatCreateLabel={(optionName) =>
          t('multi_select.add_option', { optionName })
        }
        noOptionsMessage={() => t('multi_select.no_option')}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        components={components}
        theme={(theme) => getSelectTheme(theme, errorMessage)}
        classNames={{
          indicatorSeparator: () => 'hidden',
          placeholder: () => (placeholder ? 'mpd-color-gray' : 'hidden'),
          control: ({ isFocused }: { isFocused: boolean }) =>
            cn('mpd-single-select-control', {
              'mpd-single-select-control--error': errorMessage,
              'mpd-single-select-control--is-focused': isFocused,
            }),
          option: () => 'mpd-single-select-option',
          valueContainer: () =>
            cn({ 'mpd-single-select-value-container': !isSearchable }),
          input: () => 'mpd-single-select-input',
        }}
      />
      {!!helpMessage && !errorMessage && (
        <span className="help">{helpMessage}</span>
      )}
      {!!errorMessage && (
        <span className="help help--error">{errorMessage}</span>
      )}
    </div>
  );
}

export default MpdSingleSelect;
