import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SeatConfigClient from '../client/SeatConfigClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import EventDate from './EventDate';
import EventDatePattern from './EventDatePattern';
import type { HidableType } from './HidableType';
import LogicalSeatConfig from './LogicalSeatConfig';
import NetworkEntity from './NetworkEntity';
import SeatConfigBlock from './SeatConfigBlock';
import SeatGroup from './SeatGroup';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SeatConfigType = HidableType &
  BaseEntity<'SeatConfig'> & {
    seatGroupList: null | List<EntityRelation<SeatGroup>>;
    seatConfigBlockList: null | List<EntityRelation<SeatConfigBlock>>;
    label: null | string;
    totalStock: null | number;
    contract: null | EntityRelation<Contract>;
    eventDateList: null | List<EntityRelation<EventDate>>;
    eventDatePatternList: null | List<EntityRelation<EventDatePattern>>;
    logicalSeatConfigList: null | List<EntityRelation<LogicalSeatConfig>>;
  };

// those fields can not be `null` in the API, so they won't be `null` if the "fields" is given.
export type SeatConfigNonNullProps =
  | '@id'
  | 'label'
  | 'totalStock'
  | 'contract'
  | 'eventDateList'
  | 'eventDatePatternList'
  | 'logicalSeatConfigList';

class SeatConfig extends NetworkEntity<SeatConfigType>({
  '@id': null,
  '@type': 'SeatConfig',
  seatGroupList: List<SeatGroup>(),
  seatConfigBlockList: List<SeatConfigBlock>(),
  label: null,
  totalStock: null,
  contract: null,
  eventDateList: List<EventDate>(),
  eventDatePatternList: List<EventDatePattern>(),
  logicalSeatConfigList: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SeatConfigType> = { '@id': null, '@type': 'SeatConfig' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seat_configs/', '') || '';
  }
}

SeatConfig.classMetadata = new ClassMetadata(
  'seatConfig',
  'seat_configs',
  /** @ts-expect-error -- method signature are incompatible */
  SeatConfigClient
);
SeatConfig.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
  new Attribute('totalStock', 'totalStock', 'int'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);
SeatConfig.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'seatGroup', 'seatGroupList'),
  new Relation(Relation.ONE_TO_MANY, 'seatConfigBlock', 'seatConfigBlockList'),
  new Relation(Relation.ONE_TO_MANY, 'eventDate', 'eventDateList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'eventDatePattern',
    'eventDatePatternList'
  ),
  new Relation(
    Relation.ONE_TO_MANY,
    'logicalSeatConfig',
    'logicalSeatConfigList'
  ),
]);

export default SeatConfig;
