type Config = {
  oauth: { path: string; scheme: string };
  ticketing: { path: string; scheme: string };
  account: { host: string };
};

export default function getSdkConfig(): Config {
  const isProdEnv = process.env.NODE_ENV === 'production';

  if (isProdEnv) {
    return {
      oauth: {
        path: 'oauth2.mapado.com/oauth/v2/token',
        scheme: 'https',
      },

      ticketing: {
        path: 'ticketing.mapado.net',
        scheme: 'https',
      },
      account: {
        host: 'https://accounts.mapado.com',
      },
    };
  }

  return {
    oauth: {
      path: 'oauth2.preprod.mapado.com/oauth/v2/token',
      scheme: 'https',
    },

    ticketing: {
      path: 'ticketing.preprod.mapado.net',
      scheme: 'https',
    },
    account: {
      host: 'https://accounts.preprod.mapado.com',
    },
  };
}

export const PUBLICID =
  '409_614n6t342zwo4g8cc8wcswck8sw0ocg8g4ggw88g44kk8cowo8';

export const SECRET = '13a314xaw2m8wc4s4sgoscc0s444kc8ws0ggw88wso8g8k4sco';

const SCOPES_READ =
  'ticketing:users:read ticketing:desk_payment_methods:read ticketing:carts:read ticketing:events:read ticketing:orders:read ticketing:seat_configs:read ticketing:logical_seat_configs:read ticketing:seat_groups:read ticketing:available_seats:read ticketing:available_seat_models:read ticketing:seats:read ticketing:tickets:read ticketing:stock_contingents:read ticketing:contingents:read';

const SCOPES_WRITE =
  'ticketing:seat_groups:write ticketing:contingents:write ticketing:seat_configs:write ticketing:available_seats:write ticketing:available_seat_models:write ticketing:seats:write ticketing:stock_contingents:write';

export const SCOPES = `${SCOPES_READ} ${SCOPES_WRITE}`;
