import React, { ReactNode, ReactElement, ElementType } from 'react';
import {
  CustomerType,
  SEAT_TYPE,
  TicketPrice,
  assertRelationIsObject,
  assertRelationIsString,
} from 'mapado-ticketing-js-sdk';
import { List, Set } from 'immutable';
import { DOMAIN_CONTEXT } from '@mapado/js-component';
import initSvgPanZoom, { SvgPanZoom } from '../utils/svgPanZoom';
import SeatElementList from './SeatElementList';
import SeatConfigBlock from './SeatConfigBlock';
import Controls from './Controls';
import SeatTooltip from '../containers/SeatTooltipContainer';
import {
  SeatConfigBlockType,
  SeatEntityType,
  SeatEntityFromType,
  AvailableSeatType,
} from '../propTypes';
import './DraggableSVG.css';
import BottomToolbar from './Toolbar/BottomToolbar';
import TopToolbar from './Toolbar/TopToolbar';
import { OnClickSeatFunction } from './Seat/BaseSeat';
import { BOOKING_STATUS_AVAILABLE } from '../utils/booking';
import { TFunction } from '../i18n';

type State = {
  focusedSeatId: number | null;
  participant: CustomerType | null;
};

class DraggableSVG<SE extends SeatEntityType> extends React.PureComponent<
  Props<SE>,
  State
> {
  static defaultProps = {
    onClickSeat: null,
    actionButtons: null,
    SeatConfigBlockElement: SeatConfigBlock,
    getTicketPriceListBySeatGroup: null,
  };

  // eslint-disable-next-line react/sort-comp
  _svgRef: null | SVGGElement;

  _svgPanZoomReference: null | SvgPanZoom;

  mouseX: number;

  mouseY: number;

  constructor(props: Props<SE>) {
    super(props);
    this.setSvgReference = this.setSvgReference.bind(this);
    this._svgRef = null;
    this._svgPanZoomReference = null;
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.state = {
      focusedSeatId: null,
      participant: null,
    };

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTrackPad = this.onTrackPad.bind(this);
    this.onFocusSeat = this.onFocusSeat.bind(this);
    this.onUnfocusSeat = this.onUnfocusSeat.bind(this);

    this.mouseX = 0;
    this.mouseY = 0;
  }

  componentDidMount(): void {
    this._svgRef?.parentElement?.addEventListener(
      'mouseup',
      this.onMouseMove,
      true
    );

    this._svgRef?.parentElement?.addEventListener(
      'mousemove',
      this.onMouseMove,
      true
    );

    this._svgRef?.parentElement?.addEventListener('wheel', this.onTrackPad);
  }

  componentWillUnmount(): void {
    this._svgRef?.parentElement?.removeEventListener(
      'mouseup',
      this.onMouseMove,
      true
    );

    this._svgRef?.parentElement?.removeEventListener(
      'mousemove',
      this.onMouseMove,
      true
    );

    this._svgRef?.parentElement?.removeEventListener('wheel', this.onTrackPad);

    if (this._svgPanZoomReference !== null) {
      this._svgPanZoomReference.destroy();
    }
  }

  onMouseMove(evt: MouseEvent): void {
    this.mouseX = evt.clientX;
    this.mouseY = evt.clientY;
  }

  /*
   * We use trackpad movement like any maps (google, bings...), we can't go to the left or right, only zoom.
   */
  onTrackPad(event: WheelEvent): void {
    event.preventDefault();

    let scale = Math.exp(-event.deltaY / 100);

    if (scale > 1) {
      scale = Math.min(scale, 1.15);
    } else {
      scale = Math.max(scale, 0.85);
    }

    if (this._svgPanZoomReference) {
      this._svgPanZoomReference.zoomBy(scale, event.offsetX, event.offsetY);
    }
  }

  onFocusSeat(seatId: number, participant: CustomerType | null): void {
    this.setState({
      focusedSeatId: seatId,
      participant,
    });
  }

  onUnfocusSeat(seatId: number): void {
    this.setState((prevState) => {
      const currentFocusedSeatId = prevState.focusedSeatId;

      if (currentFocusedSeatId === seatId) {
        return {
          focusedSeatId: null,
          participant: null,
        };
      }

      return {
        focusedSeatId: currentFocusedSeatId,
        participant: prevState.participant,
      };
    });
  }

  setSvgReference(ref: SVGGElement): void {
    if (ref !== null && this._svgRef !== ref) {
      this._svgRef = ref;

      if (this._svgPanZoomReference !== null) {
        this._svgPanZoomReference.destroy();
      }

      this._svgPanZoomReference = initSvgPanZoom(ref);
    }
  }

  zoomIn(): void {
    if (this._svgPanZoomReference) {
      this._svgPanZoomReference.zoomIn();
    }
  }

  zoomOut(): void {
    if (this._svgPanZoomReference) {
      this._svgPanZoomReference.zoomOut();
    }
  }

  countStandingAvailable(list: List<AvailableSeatType>): number {
    const { getTicketPriceListBySeatGroup } = this.props;
    const availableSeatList = list.filter((seatEntity) => {
      assertRelationIsObject(seatEntity.seatGroup);
      assertRelationIsObject(seatEntity.seat);
      assertRelationIsString(seatEntity.bookingStatus);

      if (!seatEntity.seatGroup['@id'] || !getTicketPriceListBySeatGroup) {
        return false;
      }

      const ticketPriceList = getTicketPriceListBySeatGroup(
        seatEntity.seatGroup['@id'],
        null
      );

      const hasTicketPrice = ticketPriceList && ticketPriceList.size > 0;

      return (
        seatEntity?.bookingStatus === BOOKING_STATUS_AVAILABLE &&
        seatEntity?.seat?.type === SEAT_TYPE.STANDING &&
        hasTicketPrice
      );
    });

    return availableSeatList.size;
  }

  render(): ReactElement {
    const {
      SeatElement,
      SeatConfigBlockElement,
      isMovingSeat,
      movingSeatFetchStatusInProgress,
      selectableSeatIdSet,
      onClickSeat,
      seatConfigBlockList,
      selectedSeatIdSet,
      seatEntityList,
      getTicketPriceListBySeatGroup,
      actionButtons,
      currentDomain,
      t,
    } = this.props;
    const { focusedSeatId, participant } = this.state;

    if (
      typeof onClickSeat === 'undefined' ||
      typeof SeatConfigBlockElement === 'undefined'
    ) {
      throw new Error(
        'This should not happen as they are defined in defaultProps'
      );
    }

    const countStandingSeatAvailable =
      currentDomain === DOMAIN_CONTEXT.MINISITE
        ? this.countStandingAvailable(seatEntityList)
        : null;

    return (
      <>
        <div id="modal-root" />
        <div
          className={`mpd-seating__event-blocker ${
            movingSeatFetchStatusInProgress
              ? 'mpd-seating__event-blocker--enabled'
              : ''
          }`}
        >
          <div className="mpd-seating__svg-container">
            {countStandingSeatAvailable != null &&
              countStandingSeatAvailable > 0 && (
                <div className="mpd-card p2 mpd-seating__alert">
                  {t('seating.info.mixed_placement', {
                    count: countStandingSeatAvailable,
                  })}
                </div>
              )}

            <svg
              preserveAspectRatio="xMaxYMax"
              className="draggableSVG"
              id="seatingSVG"
            >
              <g ref={this.setSvgReference}>
                <g className="seatingFloors" id="seatingFloors">
                  <SeatElementList<SE>
                    SeatElement={SeatElement}
                    isMovingSeat={isMovingSeat}
                    seatEntityList={seatEntityList}
                    selectableSeatIdSet={selectableSeatIdSet}
                    selectedSeatIdSet={selectedSeatIdSet}
                    onClickSeat={onClickSeat}
                    onFocusSeat={this.onFocusSeat}
                    onUnfocusSeat={this.onUnfocusSeat}
                    getTicketPriceListBySeatGroup={
                      getTicketPriceListBySeatGroup
                    }
                  />
                </g>

                <g>
                  {seatConfigBlockList.map((seatConfigBlock) => (
                    <SeatConfigBlockElement
                      key={seatConfigBlock['@id']}
                      id={seatConfigBlock['@id']}
                      info={seatConfigBlock.info}
                    />
                  ))}
                </g>

                {focusedSeatId && (
                  <SeatTooltip
                    seatId={focusedSeatId}
                    participant={participant}
                    mouseX={this.mouseX}
                    mouseY={this.mouseY}
                  />
                )}
              </g>
            </svg>
            <TopToolbar
              controls={
                <Controls zoomIn={this.zoomIn} zoomOut={this.zoomOut} />
              }
            />
            <BottomToolbar actionButtons={actionButtons} />
          </div>
        </div>
      </>
    );
  }
}

type DraggableSVGTypesFromRedux<SE extends SeatEntityType> = {
  movingSeatFetchStatusInProgress: boolean;

  seatEntityList: List<SeatEntityFromType<SE>>;
  selectedSeatIdSet: Set<number>;
  selectableSeatIdSet: Set<string>;
  currentDomain: string;
  t: TFunction;
};

type Props<SE extends SeatEntityType> = DraggableSVGTypesFromRedux<SE> & {
  isMovingSeat: boolean;
  seatConfigBlockList: SeatConfigBlockType[];
  SeatElement: ElementType;
  SeatConfigBlockElement?: typeof SeatConfigBlock | ElementType;
  onClickSeat?: OnClickSeatFunction<SE>;
  getTicketPriceListBySeatGroup?: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
  actionButtons?: null | ReactNode;
  seatEntityList: List<AvailableSeatType>;
};

// wow this is ugly !
export type DraggableSVGTypes<SE extends SeatEntityType> = Omit<
  // props without default props
  Props<SE>,
  | 'onClickSeat'
  | 'actionButtons'
  | 'SeatConfigBlockElement'
  | 'getTicketPriceListBySeatGroup'
> &
  Partial<
    // default props are optionals
    Pick<
      Props<SE>,
      | 'onClickSeat'
      | 'actionButtons'
      | 'SeatConfigBlockElement'
      | 'getTicketPriceListBySeatGroup'
    >
  >;

export default DraggableSVG;
