import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 34" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-803 -971)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="poubelle"
            d="M734.667 156.089v2.578h-2.682V184h-23.938v-25.333h-2.714v-2.578h8.057v-5.422h13.248v5.422h8.029M716 153.35v2.651h8v-2.651zm13.345 28.02v-22.685h-18.669v22.685zm-16.012-20.037H716v17.334h-2.667zm5.334 0h2.666v17.334h-2.666zm5.333 0h2.667v17.334H724z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgTrash;
