import React from 'react';
import '../../../../styles/components/Sidebar/StockContingentSidebar.scss';
import { useTranslation } from '../../../../i18n';

function GaugeSidebarContent() {
  const { t } = useTranslation();

  return (
    <div className="stock-contingent-content">
      <div className="mpd-seating-sidebar-header">
        <h3 className="mpd-seating-sidebar-header__title pt0">
          {t('seating.gauge.sidebar.title')}
        </h3>
      </div>
      <p className="mpd-seating-sidebar-header__help">
        {t('seating.gauge.sidebar.message')}
      </p>
    </div>
  );
}

export default GaugeSidebarContent;
