import React, {
  Children,
  isValidElement,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import type { MpdSelectChildrenType, OptionProps } from './types';
import MpdCard from '../MpdCard';
import { useMpdSelectContext } from './context';
import { SELECT_ACTION } from './reducer';
import { KEY_CODES, matchKeyEvent } from '../../js-libs/utils';

type Props = {
  displayOnTop: boolean;
  children: MpdSelectChildrenType;
};

function MpdSelectDropdown({
  displayOnTop,
  children,
}: Props): JSX.Element | null {
  const { dispatch, state } = useMpdSelectContext();
  const ref = useRef<HTMLDivElement>(null);
  const classnames = cn('mpd-select__dropdown', {
    'mpd-select__dropdown--top': displayOnTop,
    'mpd-select__dropdown--opened-top': state.openedDropdown && displayOnTop,
    'mpd-select__dropdown--opened-bottom':
      state.openedDropdown && !displayOnTop,
  });

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    // Close dropdown when press ESCAPE
    if (matchKeyEvent(event.nativeEvent, KEY_CODES.escape)) {
      dispatch({ type: SELECT_ACTION.CLOSE_DROPDOWN });

      return;
    }

    // Navigate in focus in options when press UP / DOWN
    if (
      matchKeyEvent(event.nativeEvent, KEY_CODES.up) ||
      matchKeyEvent(event.nativeEvent, KEY_CODES.down)
    ) {
      event.preventDefault();

      if (matchKeyEvent(event.nativeEvent, KEY_CODES.up)) {
        dispatch({ type: SELECT_ACTION.FOCUS_PREVIOUS_OPTION });
      } else {
        dispatch({ type: SELECT_ACTION.FOCUS_NEXT_OPTION });
      }
    }
  };

  useEffect(() => {
    // Set width of MpdSelect on Init
    dispatch({
      type: SELECT_ACTION.SELECT_WIDTH,
      width: ref.current?.getBoundingClientRect().width,
    });

    const lostFocus: EventListener = (event) => {
      if (
        event.target instanceof Element &&
        !ref.current?.parentElement?.contains(event.target)
      ) {
        dispatch({ type: SELECT_ACTION.CLOSE_DROPDOWN });
      }
    };

    // Listen click and focus out of the dropdown to close it
    document.addEventListener('focusin', lostFocus);
    document.addEventListener('click', lostFocus);

    return () => {
      document.removeEventListener('focusin', lostFocus);
      document.removeEventListener('click', lostFocus);
    };
  }, [dispatch]);

  useEffect(() => {
    const dropdown = ref.current;
    const animationDone = () => {
      dispatch({ type: SELECT_ACTION.ANIMATION_DONE });
    };

    dropdown?.addEventListener('animationend', animationDone);

    return () => {
      dropdown?.removeEventListener('animationend', animationDone);
    };
  }, [dispatch]);

  return (
    <MpdCard ref={ref} className={classnames} onKeyDown={handleKeyDown}>
      {Children.map(children, (child) => {
        if (!isValidElement<OptionProps>(child)) {
          return null;
        }

        return child;
      })}
    </MpdCard>
  );
}

export default MpdSelectDropdown;
