import React, { ReactElement } from 'react';
import { StockManagement } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';

type Props = {
  eventDateId: number;
};

export default function DemoStockManagement({
  eventDateId,
}: Props): ReactElement {
  return (
    <>
      <EventDatePagesHeader
        title="Gestion des stocks (contingents, catégorie, jauge)"
        eventDateId={eventDateId}
      />

      <StockManagement eventDateId={eventDateId} />
    </>
  );
}
