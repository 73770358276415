import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { createI18nPack } from '@mapado/i18next-pack';

// declare your configuration
const projectName = 'seating';
const namespaceList = ['main'];
const defaultNamespace = 'main';
const languageList = ['fr', 'en', 'de'];
const defaultLanguage = 'fr';

// This function load the local JSON files in case the translation server is down
// We need to keep the full path name and do not use template strings as rollup wont resolve path
function backendLoader(
  language: string,
  namespace: string
): Promise<Record<string, unknown>> {
  if (namespace !== 'main') {
    throw new Error('Namespace must be "main"');
  }

  switch (language) {
    case 'fr':
      return import('../locales/fr/main.json');
    case 'en':
      return import('../locales/en/main.json');
    case 'de':
      return import('../locales/de/main.json');

    default:
      return Promise.reject(new Error(`Do not handle locale ${language}`));
  }
}

const { i18n, useTranslation } = createI18nPack(
  projectName,
  namespaceList,
  defaultNamespace,
  languageList,
  defaultLanguage,
  backendLoader,
  {
    postProcess: ['phraseInContextEditor'],
  }
);

export const phraseInContext = new PhraseInContextEditorPostProcessor({
  phraseEnabled: false, // apparently unable to change at runtime easily. See https://github.com/phrase/i18next-phrase-in-context-editor-post-processor/issues/34#issuecomment-889667045
  projectId: '5fcc1385c87a705a2b5b0db73bead593',
  autoLowercase: false,
});

export function enableInContextTranslation(): void {
  phraseInContext.phraseEnabled = true;
}

export { i18n, useTranslation };

export type TFunction = typeof i18n.t;
