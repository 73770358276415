import React from 'react';
import BaseSeat, { SeatProps } from './BaseSeat';

const SeatingPlanViewerSeat = React.memo(({ color, ...rest }: Props) => {
  return (
    <BaseSeat<'Seat'>
      {...rest}
      icon="seat-filled"
      fill={color}
      displaySeatLabel
      isSelectable
    />
  );
});

type Props = SeatProps<'Seat'> & {
  color: string;
};

SeatingPlanViewerSeat.displayName = 'SeatingPlanViewerSeat';

export default SeatingPlanViewerSeat;
