import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLongArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 30" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M29.142 16.686 16.414 29.414a2 2 0 0 1-2.828 0L.858 16.686a2 2 0 0 1 2.828-2.828L13 23.172V0h4v23.172l9.314-9.314a2 2 0 1 1 2.828 2.828"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongArrowDown;
