import clone from 'clone';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TicketingCategoryClient from '../client/TicketingCategoryClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type TicketingCategoryType = BaseEntity<'TicketingCategory'> & {
  name: null | string;
  contract: null | Contract;
  position: null | number;
};

export type TicketingCategoryNonNullProps = '@id' | 'name' | 'contract';

class TicketingCategory extends NetworkEntity<TicketingCategoryType>({
  '@id': null,
  '@type': 'TicketingCategory',
  name: null,
  position: null,
  contract: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketingCategoryType> = {
      '@id': null,
      '@type': 'TicketingCategory',
    }
  ) {
    const data = clone(val);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticketing_categories/', '') || '';
  }
}

TicketingCategory.classMetadata = new ClassMetadata(
  'ticketingCategory',
  'ticketing_categories',
  /** @ts-expect-error -- method signature are incompatible */
  TicketingCategoryClient
);
TicketingCategory.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('position', 'position', 'number'),
]);
TicketingCategory.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default TicketingCategory;
