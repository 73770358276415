import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatFoldableObstructedView = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 6 6" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M4.75 1.538V3.1l-.333.4H3.115zM2.698 4H5.25V1a1 1 0 0 0-.224-.631A1 1 0 0 0 4.25 0h-2.5a1 1 0 0 0-1 1v3h1.948m-.281-.5L4.693.768A.5.5 0 0 0 4.25.5h-.635L1.25 3.338V3.5zM1.75.5h1.167l-1.667 2V1q0-.034.005-.067l.34-.408A.5.5 0 0 1 1.75.5m3.5 4H0V5a1 1 0 0 0 1 1h4.032A1 1 0 0 0 6 5v-.5h-.75"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatFoldableObstructedView;
