import moment from 'moment';

type MomentType = typeof moment; // & { utc: typeof utc };

class MomentInstance {
  private static moment: MomentType;

  static setMoment(momentInstance: MomentType): void {
    MomentInstance.moment = momentInstance;
  }

  static getMoment(): MomentType {
    return MomentInstance.moment;
  }
}

export default MomentInstance;
