import clone from 'clone';
import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Client from './Client';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import SellingDevice from './SellingDevice';
import Transaction from './Transaction';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type DepositType = BaseEntity<'Deposit'> & {
  accountType: null | string;
  currency: null | string;
  client: null | Client;
  transactionList: null | List<Transaction>;
  sellingDevice: null | EntityRelation<SellingDevice>;
  order: null | EntityRelation<Order>;
  provider: null | string;
  providerMetadata: null | Map<string, unknown>;
};

class Deposit extends NetworkEntity<DepositType>({
  '@id': null,
  '@type': 'Deposit',
  accountType: null,
  currency: null,
  client: null,
  transactionList: null,
  sellingDevice: null,
  order: null,
  provider: null,
  providerMetadata: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<DepositType> = { '@id': null, '@type': 'Deposit' }
  ) {
    const data = clone(val);
    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/deposits/', '') || '';
  }
}

Deposit.classMetadata = new ClassMetadata(
  'deposit',
  'deposits',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Deposit.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('currency'),
  new Attribute('accountType'),
  new Attribute('provider'),
  new Attribute('providerMetadata', 'providerMetadata', 'object'),
]);
Deposit.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'client', 'client'),
  new Relation(Relation.ONE_TO_MANY, 'transaction', 'transactionList'),
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
]);

export default Deposit;
