import { List } from 'immutable';
import {
  RelationShouldBeDefinedError,
  RelationShouldBeNullOrObjectError,
  RelationShouldBeNullOrStringError,
  RelationShouldBeObjectError,
  RelationShouldBeStringError,
} from '.';

export function assertRelationIsDefined<T>(
  object: undefined | null | T,
  name?: string
): asserts object is T {
  if (object === null) {
    throw new RelationShouldBeDefinedError(name);
  }

  if (typeof object === 'undefined') {
    throw new RelationShouldBeDefinedError(name);
  }
}

export function assertRelationIsString(
  possibleString: unknown,
  name?: string
): asserts possibleString is string {
  if (typeof possibleString !== 'string') {
    throw new RelationShouldBeStringError(name);
  }
}

export function assertRelationIsNullOrString(
  possibleNullOrString: unknown,
  name?: string
): asserts possibleNullOrString is null | string {
  if (
    possibleNullOrString !== null &&
    typeof possibleNullOrString !== 'string'
  ) {
    throw new RelationShouldBeNullOrStringError(name);
  }
}

export function assertRelationIsListOfString(
  possibleListOfString: undefined | null | List<unknown>,
  name?: string
): asserts possibleListOfString is List<string> {
  if (
    possibleListOfString === null ||
    typeof possibleListOfString === 'undefined'
  ) {
    throw new RelationShouldBeDefinedError(name);
  }

  possibleListOfString.forEach((possibleString) => {
    assertRelationIsString(possibleString, name);
  });
}

export function assertRelationIsNullOrListOfString(
  possibleListOfString: null | List<unknown>,
  name?: string
): asserts possibleListOfString is List<string> /* | null -- activate null when https://github.com/microsoft/TypeScript/issues/49588 is fixed */ {
  if (possibleListOfString === null) {
    return;
  }

  possibleListOfString.forEach((possibleString) => {
    assertRelationIsString(possibleString, name);
  });
}

export function assertRelationIsObject<T extends object>(
  possibleObject: undefined | null | string | T,
  name?: string
): asserts possibleObject is T {
  if (typeof possibleObject !== 'object' || possibleObject === null) {
    throw new RelationShouldBeObjectError(name);
  }
}

export function assertRelationIsNullOrObject<T>(
  possibleObject: null | string | T,
  name?: string
): asserts possibleObject is null | T {
  if (typeof possibleObject !== 'object' && possibleObject !== null) {
    throw new RelationShouldBeNullOrObjectError(name);
  }
}

export function assertRelationIsListOfObject<T extends object>(
  possibleListOfObject: undefined | null | List<null | string | T>,
  name?: string
): asserts possibleListOfObject is List<T> {
  if (
    possibleListOfObject === null ||
    typeof possibleListOfObject === 'undefined'
  ) {
    throw new RelationShouldBeDefinedError(name);
  }

  possibleListOfObject.forEach((possibleString) => {
    assertRelationIsObject(possibleString, name);
  });
}

export function assertRelationIsNullOrListOfObject<T extends object>(
  possibleListOfObject: null | List<null | string | T>,
  name?: string
): asserts possibleListOfObject is List<T> /* | null -- activate null when https://github.com/microsoft/TypeScript/issues/49588 is fixed */ {
  if (possibleListOfObject === null) {
    return;
  }

  possibleListOfObject.forEach((possibleString) => {
    assertRelationIsObject(possibleString, name);
  });
}
