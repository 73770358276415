import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import SeatConfig from './SeatConfig';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SeatConfigBlockType = BaseEntity<'SeatConfigBlock'> & {
  seatConfig: null | EntityRelation<SeatConfig>;
  info: null | string;
  x: null | number;
  y: null | number;
  z: null | number;
  width: null | number;
  height: null | number;
  fontSize: null | number;
};
class SeatConfigBlock extends NetworkEntity<SeatConfigBlockType>({
  '@id': null,
  '@type': 'SeatConfigBlock',
  seatConfig: new SeatConfig(),
  info: null,
  x: 0,
  y: 0,
  z: 0,
  width: 0,
  height: 0,
  fontSize: 0,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SeatConfigBlockType> = {
      '@id': null,
      '@type': 'SeatConfigBlock',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seat_blocks/', '') || '';
  }
}

SeatConfigBlock.classMetadata = new ClassMetadata(
  'seatConfigBlock',
  'seat_config_blocks',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
SeatConfigBlock.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('info'),
  new Attribute('x:', 'x', 'integer'),
  new Attribute('y:', 'y', 'integer'),
  new Attribute('z:', 'z', 'integer'),
  new Attribute('width', 'width', 'integer'),
  new Attribute('height', 'height', 'integer'),
  new Attribute('fontSize', 'fontSize', 'integer'),
]);
SeatConfigBlock.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
]);

export default SeatConfigBlock;
