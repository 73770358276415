import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import VenueClient from '../client/VenueClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import type Contract from './Contract';
import type { HidableType } from './HidableType';
import type SibilVenueLieu from './Module/Sibil/SibilVenueLieu';
import NetworkEntity from './NetworkEntity';
import type { BaseEntity, EntityRelation, PartialEntity } from '.';

export type VenueType = HidableType &
  BaseEntity<'Venue'> & {
    name: null | string;
    seatingName: null | string;
    address: null | string;
    city: null | string;
    zipCode: null | string;
    countryCode: null | string;
    timezone: null | string;
    contract: null | EntityRelation<Contract>;
    sibilVenueLieu: null | EntityRelation<SibilVenueLieu>;
  };

export type VenueNonNullProps = '@id' | 'name' | 'timezone' | 'contract';

class Venue extends NetworkEntity<VenueType>({
  '@id': null,
  '@type': 'Venue',
  name: null,
  seatingName: null,
  address: null,
  city: null,
  zipCode: null,
  countryCode: null,
  timezone: null,
  contract: null,
  sibilVenueLieu: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<VenueType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/venues/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Venue.classMetadata = new ClassMetadata('venue', 'venues', VenueClient);
Venue.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('name'),
  new Attribute('seatingName'),
  new Attribute('address'),
  new Attribute('city'),
  new Attribute('zipCode'),
  new Attribute('countryCode'),
  new Attribute('timezone'),
]);
Venue.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'sibilVenueLieu', 'sibilVenueLieu'),
]);

export default Venue;
