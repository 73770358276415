import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 24" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M17.457 9.618v2.277c0 1.177.406 2.277 1.096 3.219.285.392.447.824.447 1.334v.197C19 17.94 17.904 19 16.564 19H2.436C1.096 19 0 17.94 0 16.645v-.197c0-.51.162-.942.447-1.334.69-.982 1.096-2.08 1.096-3.22V9.619c0-3.73 2.436-6.595 6.13-7.341v-.903C7.673.628 8.282 0 9.094 0h.812c.771 0 1.42.628 1.42 1.374v.903c3.695.746 6.131 3.572 6.131 7.34M12 21c0 1.64-1.133 3-2.5 3S7 22.688 7 21z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBell;
