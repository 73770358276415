import {
  SeatConfig,
  EventDate,
  assertRelationIsObject,
  Fields,
} from 'mapado-ticketing-js-sdk';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $primaryBlue } from '@mapado/makeup/variables';
import { Dispatch } from 'redux';
import TicketingSdkInstance from '../TicketingSdkInstance';
import { SET_SEATCONFIG_DATA } from '../reducers/types';
import type { SeatActionTypes, SeatThunkAction } from '../reducers/SeatReducer';
import { resetState } from './SeatActions';
import {
  LOGICAL_SEAT_CONFIG_FIELDS,
  SEAT_CONFIG_FIELDS,
  SEAT_FIELDS,
} from './fields';
import {
  LogicalSeatConfigType,
  SeatConfigType,
  SeatGroupType,
} from '../propTypes';

export function setSeatConfigData(
  seatConfig: SeatConfig | SeatConfigType,
  extractSeatGroupsAndSeats = false
): SeatActionTypes {
  return {
    type: SET_SEATCONFIG_DATA,
    seatConfig:
      // @ts-expect-error -- custom serializer typing error
      typeof seatConfig.toJS === 'function' ? seatConfig.toJS() : seatConfig,
    extractSeatGroupsAndSeats,
  };
}

export function getSeatConfigData(
  rawSeatConfigId: string,
  resetLoadStatus: boolean,
  loadSeatGroupsAndSeats = false
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    const seatConfigId = rawSeatConfigId.replace('/v1/seat_configs/', '');

    if (resetLoadStatus) {
      dispatch(resetState());
    }

    let fields = SEAT_CONFIG_FIELDS;

    if (loadSeatGroupsAndSeats) {
      fields = [...fields, { seatList: SEAT_FIELDS }];
    }

    return TicketingSdkInstance.getSdk()
      .getRepository('seatConfig')
      .find(seatConfigId, fields, {})
      .then((seatConfig) => {
        let updatedSeatConfig = seatConfig;
        // create fake seatGroup and add seatList to it to make
        // seatConfig compatibe with Seat based plans
        const fakeSeatGroup: SeatGroupType = {
          '@type': 'SeatGroup',
          '@id': '/v1/seat_groups/0',
          seatColor: $primaryBlue,
          label: 'fakeSeatGroup',
          // @ts-expect-error -- custom serializer typing error
          seatList: updatedSeatConfig.get('seatList'),
        };

        updatedSeatConfig = updatedSeatConfig
          // @ts-expect-error -- custom serializer typing error
          .set('seatGroupList', [fakeSeatGroup])
          // @ts-expect-error -- custom serializer typing error
          .set('seatList', null);

        return dispatch(
          setSeatConfigData(updatedSeatConfig, loadSeatGroupsAndSeats)
        );
      });
  };
}

export function getSeatConfigDataForEventDateId(
  eventDateId: string | number
): Promise<null | SeatConfigType | LogicalSeatConfigType> {
  const fields: Fields = [
    {
      seatConfig: SEAT_CONFIG_FIELDS,
    },
    {
      logicalSeatConfig: LOGICAL_SEAT_CONFIG_FIELDS,
    },
  ];

  return TicketingSdkInstance.getSdk()
    .getRepository('eventDate')
    .find(eventDateId, fields)
    .then((eventDate: EventDate) => {
      // @ts-expect-error -- custom serializer typing error
      const seatConfig: SeatConfigType = eventDate.get('seatConfig');
      // @ts-expect-error -- custom serializer typing error
      const logicalSeatConfig: LogicalSeatConfigType =
        eventDate.get('logicalSeatConfig');

      if (logicalSeatConfig) {
        assertRelationIsObject(
          logicalSeatConfig,
          'eventDate.logicalSeatConfig'
        );

        return logicalSeatConfig;
      }

      if (seatConfig) {
        assertRelationIsObject(seatConfig, 'eventDate.seatConfig');

        return seatConfig;
      }

      return null;
    });
}
