import { BookingItem } from 'mapado-ticketing-js-sdk';
import React from 'react';
import { getEntityId } from '@mapado/js-component';
import { getIcon } from '../../utils/seatStyle';
import { useFocusedBooking } from '../../utils/selectors';
import BaseSeat, { SeatProps } from './BaseSeat';
import { getAvailableSeatListFromBooking } from '../../utils/seatSelectable';

const OrderManagementSeat = React.memo(
  ({
    seatEntity,
    seatId,
    color,
    isSelectable,
    isSelected,
    bookingStatus,
    ...rest
  }: Props) => {
    const booking = useFocusedBooking();

    const isBookingProxy = !booking?.bookingItemList.size;

    const bookingSeats = !isBookingProxy // TODO@lpot [once booking will be fully migrated] delete this condition
      ? booking?.bookingItemList.map(
          (item: BookingItem): string => `${item.seatId}`
        )
      : getAvailableSeatListFromBooking(booking).map(getEntityId);

    const entityId = !isBookingProxy // TODO@lpot [once booking will be fully migrated] delete this condition
      ? `${seatId}`
      : seatEntity['@id'];

    return (
      <BaseSeat<'AvailableSeat'>
        {...rest}
        seatEntity={seatEntity}
        seatId={seatId}
        isSelectable={isSelectable}
        bookingStatus={bookingStatus}
        displayDismissedSeat
        icon={getIcon(bookingStatus, false)}
        fill={color}
        isSelected={booking ? bookingSeats.contains(entityId) : isSelected}
        isZoomed={isSelected}
      />
    );
  }
);

OrderManagementSeat.displayName = 'OrderManagementSeat';

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
};

export default OrderManagementSeat;
