import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLongArrowTop = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 30" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M.858 13.314 13.586.586a2 2 0 0 1 2.828 0l12.728 12.728a2 2 0 0 1-2.828 2.828L17 6.828V30h-4V6.828l-9.314 9.314a2 2 0 1 1-2.828-2.828"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongArrowTop;
