import { useCallback, useEffect } from 'react';
import { List } from 'immutable';
import { AvailableSeat, WithValue } from 'mapado-ticketing-js-sdk';
import {
  usePrevious,
  getEntityId,
  getErrorDescription,
} from '@mapado/js-component';
import { TOAST_TYPE, useMpdToast } from '@mapado/makeup';
import TicketingSdkInstance from '../../TicketingSdkInstance';
import { getEventDateData } from '../../actions/AppActions';
import {
  resetSelectedSeats,
  updateAvailableSeatListSeatGroup,
} from '../../actions/SeatActions';
import {
  useStockManagement,
  STOCK_CONTINGENT_FIELDS,
  STOCK_MANAGEMENT_TABS,
} from '../../contexts/StockManagementContext';
import { getLongId } from '../../utils/entity';
import {
  useSelectedAvailableSeatList,
  useSelectedSeatIdSetSelector,
} from '../../utils/selectors';
import { useDrawingContext } from '../../contexts/DrawingContext';
import { useSelectedSeatGroupContext } from '../../contexts/SelectedSeatGroupContext';
import { useSeatingDispatch } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';

export function useUpdateStockContingentOnChange(onError: () => void): void {
  const { selectedStockContingent, selectedTab, eventDateId } =
    useStockManagement();
  const { setIsDrawing } = useDrawingContext();
  const selectedAvailableSeatList = useSelectedAvailableSeatList();
  const toast = useMpdToast();
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();

  const selectedSeatIdSet = useSelectedSeatIdSetSelector();

  const prevSelectedStockContingentId = usePrevious(
    selectedStockContingent?.get('@id')
  );
  const prevSelectedSeatIdSet = usePrevious(selectedSeatIdSet);
  const updateStockContingent = useCallback(async () => {
    if (!selectedStockContingent) {
      return;
    }

    try {
      const availableSeatList = List(
        selectedAvailableSeatList.map((availableSeat) => availableSeat['@id'])
      );

      await TicketingSdkInstance.getSdk()
        .getRepository('stockContingent')
        .update(
          selectedStockContingent.merge({
            totalStock: availableSeatList.size ?? 0,
            availableSeatList: availableSeatList || List(),
            seatList: selectedStockContingent.seatList || List(),
            eventDate: getLongId('/v1/event_dates/', eventDateId),
            contingent: getEntityId(selectedStockContingent.contingent),
          }),
          { fields: STOCK_CONTINGENT_FIELDS }
        );

      dispatch(getEventDateData(eventDateId));
      setIsDrawing(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      toast({
        title: t('stock_contingent.update_error'),
        type: TOAST_TYPE.ERROR,
      });

      // wait for toast to be close to trigger `onError`
      window.setTimeout(() => {
        onError();
      }, 5000);
    }
  }, [
    dispatch,
    eventDateId,
    onError,
    selectedAvailableSeatList,
    selectedStockContingent,
    setIsDrawing,
    t,
    toast,
  ]);

  useEffect(() => {
    if (selectedTab !== STOCK_MANAGEMENT_TABS.CONTINGENT) {
      return;
    }

    const selectedSeatListHasChanged =
      !prevSelectedSeatIdSet?.equals(selectedSeatIdSet);

    if (
      selectedSeatListHasChanged &&
      prevSelectedStockContingentId &&
      selectedStockContingent &&
      // avoid save when contingent is just selected
      prevSelectedStockContingentId === selectedStockContingent.get('@id')
    ) {
      updateStockContingent();
    }
  }, [
    selectedTab,
    selectedSeatIdSet,
    selectedStockContingent,
    prevSelectedStockContingentId,
    updateStockContingent,
    prevSelectedSeatIdSet,
  ]);
}

export function useUpdateSeatGroupOnChange(): void {
  const { t } = useTranslation();
  const { selectedTab } = useStockManagement();
  const toast = useMpdToast();
  const selectedAvailableSeatList = useSelectedAvailableSeatList();
  const dispatch = useSeatingDispatch();
  const selectedSeatIdSet = useSelectedSeatIdSetSelector();
  const { selectedSeatGroup } = useSelectedSeatGroupContext();
  const prevSelectedSeatIdSet = usePrevious(selectedSeatIdSet);
  const { setIsDrawing } = useDrawingContext();

  useEffect(() => {
    if (
      selectedTab !== STOCK_MANAGEMENT_TABS.SEAT_GROUP ||
      !selectedSeatGroup
    ) {
      return;
    }

    const selectedSeatListHasChanged =
      !prevSelectedSeatIdSet?.equals(selectedSeatIdSet);

    if (selectedSeatListHasChanged && selectedSeatIdSet.size) {
      const availableSeatListToMove = selectedAvailableSeatList
        .map((selectedAvailableSeat) => ({
          '@id': selectedAvailableSeat['@id'],
          seatGroup: selectedSeatGroup['@id'],
        }))
        .toArray();

      TicketingSdkInstance.getSdk()
        .getRepository('availableSeat')
        .putBulk({ 'hydra:member': availableSeatListToMove }, [
          '@id',
          'seatGroup',
        ] as const)
        .then((response: Response) => response.json())
        .then(
          (body: {
            'hydra:member': WithValue<AvailableSeat, '@id' | 'seatGroup'>;
          }) => {
            // @ts-expect-error `WithValue<AvailableSeat, "@id" | "seatGroup">` is compatible with `UpdateAvailableSeatListSeatGroupType`
            dispatch(updateAvailableSeatListSeatGroup(body['hydra:member']));
            dispatch(resetSelectedSeats());
            setIsDrawing(false);
          }
        )
        .catch((err: Error) => {
          // eslint-disable-next-line no-console
          console.error(err);

          getErrorDescription(err).then((message) => {
            toast({
              title: t('stock_management.error.seat_group_update_error'),
              message,
            });
          });
        });
    }
  }, [
    dispatch,
    prevSelectedSeatIdSet,
    selectedAvailableSeatList,
    selectedSeatGroup,
    selectedSeatIdSet,
    selectedTab,
    setIsDrawing,
    t,
    toast,
  ]);
}
