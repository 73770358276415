import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderSeatMappingClient extends AbstractClient<
  TSMetadata['ProviderSeatMapping']
> {
  getPathBase(): string {
    return '/v1/provider_seat_mappings';
  }
}

export default ProviderSeatMappingClient;
