import React, { ReactElement } from 'react';
import '../styles/components/ZoomButton.scss';

type ZoomButtonProps = {
  id: string;
  onZoom: () => void;
  children: ReactElement;
  type: string;
};

function ZoomButton({
  id,
  children,
  onZoom,
  type,
}: ZoomButtonProps): React.ReactElement {
  return (
    <button
      type="button"
      id={id}
      className={`mpd-btn mpd-btn--icon-only zoom-button zoom-button--${type}`}
      onClick={onZoom}
    >
      {children}
    </button>
  );
}

export default ZoomButton;
