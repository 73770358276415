import { connect } from 'react-redux';
import LogicalSeatConfigAdmin, {
  StateProps,
  DispatchProps,
  LogicalSeatConfigAdminProps,
} from '../../components/Main/LogicalSeatConfigAdmin';
import { initLogicalSeatConfigAdmin } from '../../actions/AppActions';
import {
  selectBatchOfSeats,
  selectBatchOfAvailableSeatModels,
  updateContingentSeatList,
  resetState,
} from '../../actions/SeatActions';
import {
  removeAvailableSeatModelListContingent,
  updateAvailableSeatModelListContingent,
  updateAvailableSeatModelListSeatGroup,
  updateAvailableSeatModelListStatus,
} from '../../actions/LogicalSeatConfigAction';
import { isLoadedAvailableSeatModelList } from '../../utils/selectors';
import type { RootState } from '../../reducers';

const mapStateToProps = (state: RootState): StateProps => ({
  logicalSeatConfig: state.seating.get('logicalSeatConfig'),
  contingentList: state.seating.get('contingentList'),
  isReady: isLoadedAvailableSeatModelList(state),
});

const mapDispatchToProps = {
  resetState,
  initLogicalSeatConfigAdmin,
  selectBatchOfSeats,
  updateContingentSeatList,
  selectBatchOfAvailableSeatModels,
  updateAvailableSeatModelListContingent,
  updateAvailableSeatModelListStatus,
  removeAvailableSeatModelListContingent,
  updateAvailableSeatModelListSeatGroup,
};

export default connect<
  StateProps,
  DispatchProps,
  LogicalSeatConfigAdminProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(LogicalSeatConfigAdmin);
