import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class VenueClient extends HidableClient<TSMetadata['venue']> {
  getPathBase(): string {
    return '/v1/venues';
  }
}

export default VenueClient;
