import { Record } from 'immutable';

// eslint-disable-next-line no-shadow
export enum DELIMITER {
  COMMA = 'comma',
  SEMICOLON = 'semicolon',
  PIPE = 'pipe',
  TAB = 'tab',
}

interface ContexType {
  delimiter?: DELIMITER;
  rowsToSkip?: number;
  hasHeader?: boolean;
}

export default class Context extends Record<ContexType>({
  delimiter: undefined,
  hasHeader: undefined,
  rowsToSkip: 0,
}) {}
