import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import Fee from './Fee';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum SHIPPING_TYPE {
  E_TICKET = 'eTicket',
  DESK = 'desk',
  POSTAL_MAIL = 'postalMail',
  REGISTERED_MAIL = 'registeredMail',
}

export type ShippingType = BaseEntity<'Shipping'> & {
  contract: null | EntityRelation<Contract>;
  type: null | SHIPPING_TYPE;
  fee: null | EntityRelation<Fee>;
};

class Shipping extends NetworkEntity<ShippingType>({
  '@id': null,
  '@type': 'Shipping',
  contract: null,
  type: null,
  fee: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ShippingType> = {
      '@id': null,
      '@type': 'Shipping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/shippings/', '') || '';
  }
}

Shipping.classMetadata = new ClassMetadata(
  'shipping',
  'shippings',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Shipping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
]);
Shipping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'fee', 'fee'),
]);

export default Shipping;
