import { List } from 'immutable';
import {
  AvailableSeatStatus,
  AVAILABLE_SEAT_STATUS_AVAILABLE,
  AVAILABLE_SEAT_STATUS_DISMISSED,
  Contingent,
  StockContingent,
  SEAT_TYPE,
  Ticket,
} from 'mapado-ticketing-js-sdk';

export type Id = string | number;

export type SeatType = {
  position: number;
  '@id': string;
  '@type': 'Seat';
  // seatGroup: string | SeatGroupType;
  stockContingent?: null | string | StockContingentType;
  info: {
    label: null | string;
    price: null | number;
    X: string | number;
    Y: string | number;
    'Numéro de place'?: string;
    Rangée?: string;
    rangée?: string;
    rowgroup?: string;
  };
  angle: null | number;
  type: SEAT_TYPE;
  hasObstructedView: boolean | null;
};

export type NoGroupSeatType = Omit<SeatType, 'seatGroup'>;

export type AvailableSeatType = {
  '@id': string;
  '@type': 'AvailableSeat';
  bookingStatus: string;
  bookable: boolean;
  cartItem: null;
  seat: null | string | SeatType;
  status: AvailableSeatStatus;
  ticket: null | string | Ticket;
  visible: boolean;
  seatGroup: null | string | SeatGroupType;
  stockContingent?: null | string | StockContingentType;
};

export type UpdateAvailableSeatListSeatGroupType = {
  '@id': string;
  seatGroup: string;
};

export type SeatGroupType = {
  '@id': string;
  '@type': 'SeatGroup';
  seatColor: string;
  label: string;
  seatList: NoGroupSeatType[] | null;
};

export type StockContingentType = {
  '@id': string;
  '@type': 'StockContingent';
  name: string;
  color: string;
  totalStock?: number;
  dismissedStock?: number;
};

export type SeatConfigBlockType = {
  '@id': string;
  info: string;
};

export type SeatConfigType = {
  '@id': string;
  '@type': 'SeatConfig';
  label: string;
  contract: string;
  seatGroupList: SeatGroupType[] | null;
  seatConfigBlockList: SeatConfigBlockType[];
};

type TicketingType = {
  timezone: string;
  title: string;
  wallet: {
    contract: string;
  };
};

export type EventDateType = {
  '@id'?: string;
  startDate?: string;
  endDate?: string;
  ticketing: TicketingType;
  seatConfig?: string | SeatConfigType;
  logicalSeatConfig?: string | LogicalSeatConfigType;
  nbActiveSeats: number;
  issuedTickets: number;
  bookedTickets: number;
  notInStockContingentBookableStock: number;
  stockContingentList: List<StockContingentType>;
  availableSeatList?: List<AvailableSeatType>;
};

export const SEAT_ENTITY_TYPE = {
  AvailableSeat: 'AvailableSeat',
  Seat: 'Seat',
  AvailableSeatModel: 'AvailableSeatModel',
} as const;

export type SeatEntityType =
  typeof SEAT_ENTITY_TYPE[keyof typeof SEAT_ENTITY_TYPE];

export type SeatEntityFromType<S extends SeatEntityType> =
  S extends 'AvailableSeat'
    ? AvailableSeatType
    : S extends 'AvailableSeatModel'
    ? AvailableSeatModelType
    : S extends 'Seat'
    ? SeatType
    : SeatEntityType;

export type LogicalSeatConfigType = {
  '@id': string;
  '@type': 'LogicalSeatConfig';
  contract: string;
  seatConfig: SeatConfigType;
};

export type AvailableSeatModelType = {
  '@id': string;
  '@type': 'AvailableSeatModel';
  status:
    | null
    | typeof AVAILABLE_SEAT_STATUS_AVAILABLE
    | typeof AVAILABLE_SEAT_STATUS_DISMISSED;
  seat: null | string | SeatType;
  seatGroup: null | string | SeatGroupType;
  contingent?: null | string | Contingent;
};

export type SeatEntity = SeatType | AvailableSeatType | AvailableSeatModelType;

export function isAvailableSeatType(
  seatEntity: SeatEntity
): seatEntity is AvailableSeatType {
  return seatEntity['@type'] === 'AvailableSeat';
}

export function isSeatType(seatEntity: SeatEntity): seatEntity is SeatType {
  return seatEntity['@type'] === 'Seat';
}

export function isAvailableSeatModelType(
  seatEntity: SeatEntity
): seatEntity is AvailableSeatModelType {
  return seatEntity['@type'] === 'AvailableSeatModel';
}

export type ContingentEntity = StockContingent | Contingent;

export function isStockContingent(
  contingentEntity: ContingentEntity
): contingentEntity is StockContingent {
  return contingentEntity['@type'] === 'StockContingent';
}

export type SeatConfigEntity = SeatConfigType | LogicalSeatConfigType;

export function isSeatConfigType(
  seatConfigEntity: SeatConfigEntity
): seatConfigEntity is SeatConfigType {
  return seatConfigEntity['@type'] === 'SeatConfig';
}
