import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ProviderTicketCredentialsType = BaseEntity<'ProviderTicketCredentials'> & {
  contract: null | EntityRelation<Contract>;
  param1: null | string;
  param2: null | string;
  param3: null | string;
  providerName: null | string;
  providerType: null | string;
  active: null | number;
};

class ProviderTicketCredentials extends NetworkEntity<ProviderTicketCredentialsType>(
  {
    '@id': null,
    '@type': 'ProviderTicketCredentials',
    contract: null,
    param1: null,
    param2: null,
    param3: null,
    providerName: null,
    providerType: null,
    active: 1,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderTicketCredentialsType> = {
      '@id': null,
      '@type': 'ProviderTicketCredentials',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_ticket_credentials/', '') || ''
    );
  }
}

ProviderTicketCredentials.classMetadata = new ClassMetadata(
  'providerTicketCredentials',
  'provider_ticket_credentials',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
ProviderTicketCredentials.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('param1'),
  new Attribute('param2'),
  new Attribute('param3'),
  new Attribute('providerName'),
  new Attribute('providerType'),
  new Attribute('active'),
]);
ProviderTicketCredentials.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default ProviderTicketCredentials;
