import { Set } from 'immutable';
import SVG, { Container, Parent } from 'svg.js';
import { TOOL_SELECT_SEAT_FREE_DRAWING } from '../components/const';
import FreeHandDrawSeatSelector, {
  SelectBatchOfSeats,
  FreeHandDrawSeatSelectorInitOptions,
} from './drawers/FreeHandDrawSeatSelector';

type ConstructorArguments = {
  selectBatchOfSeats?: SelectBatchOfSeats;
};

class SvgDrawer {
  #element: null | Container;

  #baseElement: null | Parent;

  #mode: null | string;

  #freeHandDrawSeatSelector?: FreeHandDrawSeatSelector;

  #pt: null | SVGPoint;

  #initialCursor: null | string;

  constructor({ selectBatchOfSeats }: ConstructorArguments) {
    this.#element = null;
    this.#baseElement = null;
    this.#mode = null;

    if (selectBatchOfSeats) {
      this.#freeHandDrawSeatSelector = new FreeHandDrawSeatSelector(
        selectBatchOfSeats
      );
    }

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);

    this.clear();

    this.#pt = null;
    this.#initialCursor = null;
  }

  setMode(mode: null | string, modeOptions = {}): void {
    if (mode === this.#mode) {
      return;
    }

    this.clear();

    switch (mode) {
      case TOOL_SELECT_SEAT_FREE_DRAWING:
        this.initModeFreeHandDrawSelector(modeOptions);
        break;
      default:
        this.#mode = null;
        break;
    }
  }

  clear(): void {
    if (this.#freeHandDrawSeatSelector) {
      this.#freeHandDrawSeatSelector.clear();
    }
  }

  restoreCursor(): void {
    if (this.#element && this.#initialCursor !== null) {
      this.#element.node.style.cursor = this.#initialCursor;
    }
  }

  init(): void {
    /** @ts-expect-error -- do not understand why this is a typescript error 🤷‍♂️ */
    this.#element = SVG.get('seatingSVG');

    // dynamic import is used to avoid loading hammerjs on the server
    import('hammerjs').then(({ default: Hammer }) => {
      if (!this.#element) {
        throw new Error('Unable to find `seatingSVG`. This should not happen.');
      }

      this.#initialCursor = this.#element.node.style.cursor;

      const hammerTime = new Hammer(this.#element.node);

      hammerTime.get('press').set({
        time: 600,
        pointers: 1,
      });

      this.#element.mousemove(this.handleMouseMove);

      this.#element.mousedown(this.handleTouchStart);
      this.#element.mouseup(this.handleTouchEnd);

      const children = this.#element.children();

      if (children) {
        this.#baseElement = children[0] as Parent;
      }

      // Used for cursorPoint function

      /** @ts-expect-error -- svgjs types can not describe the type of the element node */
      this.#pt = this.#element.node.createSVGPoint();
    });
  }

  initModeFreeHandDrawSelector(
    options: FreeHandDrawSeatSelectorInitOptions
  ): void {
    if (!this.#freeHandDrawSeatSelector) {
      throw new Error(
        'Unable to call "initModeFreeHandDrawSelector" if "selectBatchOfSeats" is null.'
      );
    }

    this.#freeHandDrawSeatSelector.init(options);
    this.#mode = TOOL_SELECT_SEAT_FREE_DRAWING;
  }

  handleSelectedSeatIdListChange(selectedSeatIdSet: Set<number>): void {
    if (
      this.#mode === TOOL_SELECT_SEAT_FREE_DRAWING &&
      this.#freeHandDrawSeatSelector
    ) {
      this.#freeHandDrawSeatSelector.onSelectedSeatIdListChange(
        selectedSeatIdSet
      );
    }
  }

  /**
   * The lib does its stuff inside a SVG. Unfortunately,
   * We draw everything inside a <g> element used by svgPanZoom
   * so the scales and positions aren't the same
   * This function converts the mouse position inside the SVG to the mouse position inside the g
   * @see https://stackoverflow.com/questions/10298658/mouse-position-inside-autoscaled-svg
   */
  cursorPoint(x: number, y: number): DOMPoint {
    if (!this.#pt || !this.#baseElement) {
      throw new Error(
        'Unable to call "cusorPoint" if #pt  or #baseElement is null.'
      );
    }

    this.#pt.x = x;
    this.#pt.y = y;

    return this.#pt.matrixTransform(
      /** @ts-expect-error -- svgjs types can not describe the type of the element node */
      this.#baseElement.node.getScreenCTM().inverse()
    );
  }

  handleMouseMove(e: React.MouseEvent<SVGSVGElement>): void {
    if (this.#mode === null) {
      return;
    }

    const loc = this.cursorPoint(e.clientX, e.clientY);

    if (
      this.#mode === TOOL_SELECT_SEAT_FREE_DRAWING &&
      this.#freeHandDrawSeatSelector
    ) {
      this.#freeHandDrawSeatSelector.handleMouseMove(
        this.#element,
        this.#baseElement,
        loc.x,
        loc.y
      );
    }
  }

  handleTouchStart(e: MouseEvent): void {
    const loc = this.cursorPoint(e.clientX, e.clientY);

    if (
      this.#mode === TOOL_SELECT_SEAT_FREE_DRAWING &&
      this.#freeHandDrawSeatSelector
    ) {
      this.#freeHandDrawSeatSelector.handleTouchStart(loc.x, loc.y);
    }
  }

  handleTouchEnd(): void {
    this.restoreCursor();

    if (
      this.#mode === TOOL_SELECT_SEAT_FREE_DRAWING &&
      this.#freeHandDrawSeatSelector
    ) {
      this.#freeHandDrawSeatSelector.handleTouchEnd();
    }
  }
}

export default SvgDrawer;
