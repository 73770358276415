import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SibilTicketingSpectacleClient from '../../../client/Module/SibilTicketingSpectacleClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import type Ticketing from '../../Ticketing';
import { BaseEntity, EntityRelation, PartialEntity } from '../../index';
import type SibilParameter from './SibilParameter';

export type SibilTicketingSpectacleType = BaseEntity<
  'SibilTicketingSpectacle',
  `/v1/module/sibil/ticketing_spectacles/${number}`
> & {
  ticketing: null | EntityRelation<Ticketing>;
  spectacleId: null | number;
  festivalId: null | number;
  sibilParameter: null | SibilParameter;
  coproduction: null | boolean;
  jeunePublic: null | boolean;
};

const defaultValues: SibilTicketingSpectacleType = {
  '@id': null,
  '@type': 'SibilTicketingSpectacle',
  ticketing: null,
  spectacleId: null,
  festivalId: null,
  sibilParameter: null,
  coproduction: null,
  jeunePublic: null,
};

class SibilTicketingSpectacle extends NetworkEntity<SibilTicketingSpectacleType>(
  defaultValues
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<SibilTicketingSpectacleType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string | null {
    return (
      this.get('@id')?.replace('/v1/module/sibil/ticketing_spectacles/', '') ||
      null
    );
  }
}

SibilTicketingSpectacle.classMetadata = new ClassMetadata(
  'sibilTicketingSpectacle',
  'module/sibil/ticketing_spectacles',
  /** @ts-expect-error -- method signature are incompatible */
  SibilTicketingSpectacleClient
);

SibilTicketingSpectacle.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('spectacleId', 'spectacleId', 'integer'),
  new Attribute('festivalId', 'festivalId', 'integer'),
  new Attribute('coproduction', 'coproduction', 'boolean'),
  new Attribute('jeunePublic', 'jeunePublic', 'boolean'),
]);

SibilTicketingSpectacle.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(Relation.MANY_TO_ONE, 'sibilParameter', 'sibilParameter'),
]);

export default SibilTicketingSpectacle;
