import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class SeatClient extends AbstractClient<TSMetadata['seat']> {
  getPathBase(): string {
    return '/v1/seats';
  }

  getDefaultGroups(): Array<string> {
    return ['seat_read', 'seat_write'];
  }
}

export default SeatClient;
