import * as React from 'react';
import type { SVGProps } from 'react';
const SvgScanQr = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 276 433" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="Artboard-Copy-3" transform="translate(-262 -683)">
        <g id="noun_QR-Code_1340286-(1)" transform="translate(262 683)">
          <g id="Group" fillRule="nonzero">
            <g id="Path">
              <path d="M22.08 89.051H0V0h89.051v22.08H22.08zM276 276h-89.051v-22.08h66.971v-66.971H276z" />
            </g>
            <g id="Path">
              <path d="M276 89.051h-22.08V22.08h-66.971V0H276zM89.051 276H0v-89.051h22.08v66.971h66.971z" />
            </g>
          </g>
          <path
            id="Path"
            fillRule="nonzero"
            d="M232 232H126.941v-82.895H44V127h105.059v82.895H232z"
          />
          <path
            id="Path"
            fillRule="nonzero"
            d="M232 188h-22.174v-39.006h-21.652V188H166v-61h66z"
          />
          <path
            id="Shape"
            fillRule="nonzero"
            d="M110 110H44V44h66zM66.173 87.827h21.654V66.173H66.173zM232 110h-66V44h66zm-43.826-22.173h21.652V66.173h-21.655v21.654zM110 232H44v-66h66zm-43.827-22.174h21.654v-21.655H66.173z"
          />
          <path id="Rectangle" fillRule="nonzero" d="M127 44h22v66h-22z" />
          <path id="Combined-Shape" d="M158 327v59h33l-53 47-53-47h34v-59z" />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgScanQr;
