import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExclamation = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-734 -1184)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="exclamation"
            d="M650 363.667c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333-13.333-5.97-13.333-13.333 5.97-13.333 13.333-13.333m-1.333 4V381h2.666v-13.333zm0 16v2.666h2.666v-2.666z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgExclamation;
