import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import StickerClient from '../client/StickerClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type StickerType = BaseEntity<'Sticker'> & {
  contract: null | EntityRelation<Contract>;
  name: null | string;
  pageType: null | string;
  horizontalNb: null | number;
  verticalNb: null | number;
  stickerHeight: null | number;
  stickerWidth: null | number;
  marginLeft: null | number;
  marginTop: null | number;
  horizontalDistance: null | number;
  verticalDistance: null | number;
};

class Sticker extends NetworkEntity<StickerType>({
  '@id': null,
  '@type': 'Sticker',
  contract: new Contract(),
  name: '',
  pageType: '',
  horizontalNb: null,
  verticalNb: null,
  stickerHeight: null,
  stickerWidth: null,
  marginLeft: null,
  marginTop: null,
  horizontalDistance: null,
  verticalDistance: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<StickerType> = {
      '@id': null,
      '@type': 'Sticker',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/stickers/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Sticker.classMetadata = new ClassMetadata('sticker', 'stickers', StickerClient);
Sticker.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('pageType'),
  new Attribute('horizontalNb'),
  new Attribute('verticalNb'),
  new Attribute('stickerHeight'),
  new Attribute('stickerWidth'),
  new Attribute('marginLeft'),
  new Attribute('marginTop'),
  new Attribute('horizontalDistance'),
  new Attribute('verticalDistance'),
]);
Sticker.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Sticker;
