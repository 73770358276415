// @ts-check
import React, { PureComponent, ReactElement } from 'react';
import SeatingLoader from '../SeatingLoader';
import Seating from '../Seating';
import { SeatConfigType } from '../../propTypes';
import { GetSelectableSeatIdSetFunction } from '../../utils/seatSelectable';
import '../../styles/components/App.scss';
import SeatingPlanViewerSeat from '../Seat/SeatingPlanViewerSeat';

const onClickSeat = (): void => {
  /* do nothing */
};

class SeatingPlanViewer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.init = this.init.bind(this);
  }

  componentDidMount(): void {
    this.init();
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  init(): void {
    const { seatConfigId, initSeatingPlanViewer } = this.props;

    if (!seatConfigId) {
      throw new Error('No seatConfigId provided');
    }

    initSeatingPlanViewer(seatConfigId);
  }

  render(): ReactElement {
    const { isReady, getSelectableSeatIdSet, seatConfig } = this.props;

    if (!isReady) {
      return <SeatingLoader />;
    }

    return (
      <div className="mpd-seating__app" id="mpd-seating__app">
        <div className="mpd-seating__app__container">
          <div className="mpd-seating__app__event-svg-wrapper">
            <Seating
              SeatElement={SeatingPlanViewerSeat}
              seatEntityType="Seat"
              getSelectableSeatIdSet={getSelectableSeatIdSet}
              onClickSeat={onClickSeat}
              seatConfigBlockList={
                seatConfig ? seatConfig.seatConfigBlockList : []
              }
              isMovingSeat={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export type SeatingPlanViewerProps = {
  seatConfigId: number;
};

export type StateProps = {
  isReady: boolean;
  getSelectableSeatIdSet: GetSelectableSeatIdSetFunction;
  seatConfig: null | SeatConfigType;
};

export type DispatchProps = {
  resetState: () => void;
  initSeatingPlanViewer: (seatConfigId: number) => void;
};

type Props = SeatingPlanViewerProps & StateProps & DispatchProps;

export default SeatingPlanViewer;
