import { List } from 'immutable';
import URI from 'urijs';
import MasterTicket from '../entity/MasterTicket';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class MasterTicketClient extends AbstractClient<TSMetadata['masterTicket']> {
  getPathBase(): string {
    return '/v1/master_tickets';
  }

  bulkUpdate(
    tickets: List<MasterTicket>,
    fields: FieldsParameter,
    id: string,
    typeId: 'downloadId' | 'payId'
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });
    if (typeId === 'downloadId') {
      uri.addSearch({ downloadId: id });
    } else {
      uri.addSearch({ payId: id });
    }

    const body = JSON.stringify({
      'hydra:member': tickets.toJS(),
    });

    return this.authorizedFetch(uri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });
  }
}

export default MasterTicketClient;
