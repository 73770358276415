import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MpdPopover } from '@mapado/makeup';
import cn from 'classnames';
import { SeatEntityType, SEAT_ENTITY_TYPE } from '../../src/propTypes';

function getEntityTypeFromPathname(pathname: string): SeatEntityType {
  const normalizedPathname = pathname.split('/')[1];

  switch (normalizedPathname) {
    case 'orderManagement':
    case 'seatingPlanPurchase':
    case 'order':
    case 'reservation':
    case 'multi-context':
    case 'viewer':
    case 'stockManagement':
      return SEAT_ENTITY_TYPE.AvailableSeat;

    case 'config':
    case 'editor':
      return SEAT_ENTITY_TYPE.Seat;

    case 'logicalSeatConfigAdmin':
      return SEAT_ENTITY_TYPE.AvailableSeatModel;

    default:
      throw new Error(
        `Unknown entity type for pathname: ${normalizedPathname}`
      );
  }
}

function getSelectorLabel(entityType: SeatEntityType): string {
  switch (entityType) {
    case SEAT_ENTITY_TYPE.AvailableSeat:
      return 'Sièges réservés';
    case SEAT_ENTITY_TYPE.Seat:
      return 'Sièges physiques';
    case SEAT_ENTITY_TYPE.AvailableSeatModel:
      return 'Modèles de sièges';
    default:
      throw new Error(`Unknown entity type :${entityType}`);
  }
}

export default function LinkMenu(): JSX.Element {
  const location = useLocation();
  const [seatEntityType, setSeatEntityType] = useState<SeatEntityType>(
    getEntityTypeFromPathname(location.pathname)
  );
  const getTabUrl = (tab: string) => {
    const searchParams = new URLSearchParams(location.search);

    return { pathname: `/${tab}`, search: searchParams.toString() };
  };

  return (
    <div className="seating-demo__flex-container-centered-space-between">
      <div className="seating-demo__flex-container-centered">
        <MpdPopover
          displayOnLeft
          icon="form-arrow"
          className="m2"
          label={getSelectorLabel(seatEntityType)}
          labelClassName="mr2 mpd-color-gray bold"
        >
          <div className="flex-container-column">
            {Object.values(SEAT_ENTITY_TYPE).map((entityType) => (
              <button
                key={entityType}
                type="button"
                className={cn('mpd-popover__link', {
                  active: seatEntityType === entityType,
                })}
                onClick={() => setSeatEntityType(entityType)}
              >
                {getSelectorLabel(entityType)}
              </button>
            ))}
          </div>
        </MpdPopover>
        <div className="mpd-btn-group">
          {seatEntityType === SEAT_ENTITY_TYPE.Seat && (
            <>
              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('config')}
              >
                <span>Plan de salle</span>
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('editor')}
              >
                <span>Editor</span>
              </NavLink>
            </>
          )}

          {seatEntityType === SEAT_ENTITY_TYPE.AvailableSeatModel && (
            <>
              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('logicalSeatConfigAdmin')}
              >
                Plan de salle logique
              </NavLink>
            </>
          )}

          {seatEntityType === SEAT_ENTITY_TYPE.AvailableSeat && (
            <>
              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('stockManagement')}
              >
                Gestion des stocks
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('seatingPlanPurchase')}
              >
                Achat sur plan
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('orderManagement')}
              >
                Gestion des commandes
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('viewer')}
              >
                Visualiser panier (minisite)
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('order')}
              >
                Reaffectation commande
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('reservation')}
              >
                Réaffectation résa
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="mpd-btn mpd-btn--selection"
                to={getTabUrl('multi-context')}
              >
                Multi-contexte
              </NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
