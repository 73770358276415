import React, { ReactElement } from 'react';
import EventDateSelector from './EventDateSelector';

interface Props {
  eventDateId: number;
  title: string;
}

export default function EventDatePagesHeader({
  eventDateId,
  title,
}: Props): ReactElement {
  return (
    <div className="columns">
      <div className="column is-6">
        <h3>{title}</h3>
      </div>

      <div className="column is-6">
        <EventDateSelector eventDateId={eventDateId} />
      </div>
    </div>
  );
}
