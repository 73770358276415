import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/interogation" fillRule="nonzero" transform="translate(-5 -5)">
        <path
          id="Combined-Shape"
          d="M15 5c5.523 0 10 4.477 10 10s-4.477 10-10 10S5 20.523 5 15 9.477 5 15 5m-.235 13.735q-.495 0-.84.352-.345.354-.345.848 0 .495.345.84t.84.345.848-.345.352-.84-.352-.848a1.16 1.16 0 0 0-.848-.352m.15-8.595q-1.59 0-2.512.945-.924.945-.923 2.235 0 .42.075.675l1.89.075a1.6 1.6 0 0 1-.06-.48q0-.69.39-1.192.39-.504 1.14-.503.72 0 1.11.398.39.397.39.952 0 .705-.645 1.185l-.72.54q-1.185.9-1.185 2.175 0 .225.06.615h1.62v-.09q0-.87.81-1.455l.615-.435q1.485-1.05 1.485-2.655 0-1.215-.945-2.1t-2.595-.885"
        />
      </g>
    </g>
  </svg>
);
export default SvgQuestionMark;
