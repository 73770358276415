import { Map } from 'immutable';
import URI from 'urijs';
import { EntityRelation } from '../entity';
import Cart from '../entity/Cart';
import Order from '../entity/Order';
import PagedCollection from '../entity/PagedCollection';
import { TSMetadata } from '../mapping';
import getFieldsString, { Fields, ShortId } from '../utils';
import { FieldsParameter, USER_TYPE } from './AbstractClient';
import FormDataClient from './FormDataClient';

class OrderClient extends FormDataClient<TSMetadata['order']> {
  getPathBase(): string {
    return '/v1/orders';
  }

  findByOrigin(
    originType: string,
    originId: string,
    fields: FieldsParameter
  ): Promise<PagedCollection<Order>> {
    return this.findBy({ originType, originId }, fields);
  }

  createForCart(
    cart: EntityRelation<Cart>,
    fields: FieldsParameter,
    additionalBodyData: Record<string, unknown> = {}
  ): Promise<Order> {
    const url = new URI(this.getPathBase());
    url.addSearch({ fields: getFieldsString(fields) });

    const body = Object.assign(additionalBodyData, { cart });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
      'item'
    );
  }

  reschedule(
    id: ShortId,
    rescheduleMap: Map<string, unknown>,
    sellingDeviceId: string,
    fields: FieldsParameter
  ): Promise<Order> {
    const url = URI(`${this.getPathBase()}/${id}/reschedule`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify({
          rescheduleMap: rescheduleMap.toJS(),
          sellingDevice: sellingDeviceId,
        }),
      }),
      'item'
    );
  }

  sendInvoiceByMail(
    id: ShortId,
    email: string,
    fields: FieldsParameter
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/send_invoice_by_mail`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  }

  scan(
    id: ShortId,
    fields: FieldsParameter,
    values: Record<string, unknown>
  ): Promise<Order> {
    const url = URI(`${this.getPathBase()}/${id}/scans`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
      }),
      'item'
    );
  }

  sendCouponsByMail(
    id: ShortId,
    email: string,
    locale: string,
    coupons?: string[]
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/send_coupons_by_mail`);

    return this.authorizedFetch(url, {
      headers: {
        'Accept-Language': locale,
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        coupons,
      }),
    });
  }

  sendTicketsByMail(
    id: ShortId,
    email: string,
    locale: string,
    downloadId = '',
    tickets?: string[]
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/send_tickets_by_mail`);

    return this.authorizedFetch(url, {
      headers: {
        'Accept-Language': locale,
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        downloadId,
        tickets,
      }),
    });
  }

  sendTicketsCouponsByMail(
    id: ShortId,
    email: string,
    locale: string,
    downloadId = '',
    tickets?: string[],
    coupons?: string[]
  ): Promise<Response> {
    if (tickets?.length && !coupons?.length) {
      return this.sendTicketsByMail(id, email, locale, downloadId, tickets);
    }

    if (coupons?.length && !tickets?.length) {
      return this.sendCouponsByMail(id, email, locale, coupons);
    }

    const url = URI(`${this.getPathBase()}/${id}/send_tickets_coupons_by_mail`);

    return this.authorizedFetch(url, {
      headers: {
        'Accept-Language': locale,
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        downloadId,
        tickets,
        coupons,
      }),
    });
  }

  getPdfTickets(
    id: ShortId,
    downloadId = '',
    tickets?: string[]
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/tickets.pdf`);

    let params = null;

    if (downloadId.length) {
      params = {
        downloadId,
      };
    }

    if (tickets) {
      params = { ...params, tickets: tickets?.join(',') };
    }

    if (params) {
      url.addSearch(params);
    }

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfTestTickets(
    id: ShortId,
    entityType: string | null = null
  ): Promise<Response> {
    const url = URI(
      `${this.getPathBase()}/test/${
        entityType ? `${entityType}/` : ''
      }${id}/tickets.pdf`
    );
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getHtmlTestTickets(
    id: ShortId,
    entityType: string | null = null
  ): Promise<Response> {
    const url = URI(
      `${this.getPathBase()}/test/${
        entityType ? `${entityType}/` : ''
      }${id}/tickets.html`
    );
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfTicketsWithEventDate(
    orderId: ShortId,
    eventDateId: ShortId,
    downloadId = ''
  ): Promise<Response> {
    const url = URI(
      `${this.getPathBase()}/${orderId}/event_date/${eventDateId}/tickets.pdf?downloadId=${downloadId}`
    );
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfCoupons(
    id: ShortId,
    downloadId = '',
    coupons?: string[]
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/coupons.pdf`);

    let params = null;
    if (downloadId.length) {
      params = {
        downloadId,
      };
    }

    if (coupons) {
      params = { ...params, coupons: coupons?.join(',') };
    }

    if (params) {
      url.addSearch(params);
    }

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfTicketsCoupons(
    id: ShortId,
    downloadId = '',
    tickets?: string[],
    coupons?: string[]
  ): Promise<Response> {
    if (tickets?.length && !coupons?.length) {
      return this.getPdfTickets(id, downloadId, tickets);
    }

    if (coupons?.length && !tickets?.length) {
      return this.getPdfCoupons(id, downloadId, coupons);
    }

    const url = URI(`${this.getPathBase()}/${id}/tickets_coupons.pdf`);

    let params = null;

    if (downloadId.length) {
      params = { downloadId };
    }

    if (tickets?.length && coupons?.length) {
      params = {
        ...params,
        tickets: tickets?.join(','),
        coupons: coupons?.join(','),
      };
    }

    if (params) {
      url.addSearch(params);
    }

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getTestCoupons(
    id: ShortId,
    format: string,
    offerId: number | null = null,
    entityType: string | null = null
  ): Promise<Response> {
    let url = URI(
      `${this.getPathBase()}/test/${
        entityType ? `${entityType}/` : ''
      }${id}/coupons.${format}`
    );
    if (offerId) {
      url = URI(
        `${this.getPathBase()}/test/${
          entityType ? `${entityType}/` : ''
        }${id}/offer-rule/${offerId}/coupons.${format}`
      );
    }

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfInvoices(id: ShortId): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/invoices.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getXlsOrder(id: ShortId, locale: string): Promise<Response> {
    const url = URI(`/v1/entry_list/${id}/tickets.xlsx`);
    return this.authorizedFetch(url, {
      method: 'GET',
      headers: {
        'Accept-Language': locale,
      },
    });
  }

  getFirstRescheduleOrderForOrder(
    order: Order,
    fields: FieldsParameter
  ): Promise<Order> {
    if (!order.rescheduledFromOrder) {
      return Promise.resolve(order);
    }

    let mergedFields: Array<string> = ['@id', 'rescheduledFromOrder'];

    if (Array.isArray(fields)) {
      mergedFields = mergedFields.concat(fields);
    } else if (typeof fields === 'string') {
      mergedFields.push(fields);
    }

    const id =
      typeof order.rescheduledFromOrder === 'string'
        ? order.rescheduledFromOrder.replace('/v1/orders/', '')
        : order.rescheduledFromOrder.getShortId();

    return this.find(Number(id), mergedFields).then((o) =>
      this.getFirstRescheduleOrderForOrder(o, fields)
    );
  }

  generatePaymentProviderData(
    order: Order,
    payId: string,
    fields: Fields,
    user: USER_TYPE | null = null
  ): Promise<Order> {
    const url = URI(
      `${this.getEntityURI(order)}/generate-payment-provider-data`
    );
    url.addSearch({ payId });
    url.addSearch({ fields: getFieldsString(fields) });

    if (user) {
      url.addSearch({ user });
    }

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify({}),
      }),
      'item'
    );
  }

  getPaymentProviderInformations(
    order: Order
  ): Promise<{ hasSuccessTransaction: boolean }> {
    const url = URI(`${this.getEntityURI(order)}/payment-provider-information`);
    url.addSearch({ downloadId: order.downloadId });

    return this.authorizedFetch(url, {
      method: 'GET',
    }).then((response) => response.json());
  }

  getOrderFromPaymentCallback(
    orderId: string,
    contentCallback: string,
    signature: string,
    fields: FieldsParameter,
    params?: Record<string, unknown>
  ): Promise<Order> {
    const url = URI(`${this.getPathBase()}/${orderId}/payment_callback`);
    url.addSearch({ fields: getFieldsString(fields) });

    if (params) {
      url.addSearch(params);
    }

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        headers: {
          'X-callback-signature': signature,
        },
        body: JSON.stringify(contentCallback),
      }),
      'item'
    );
  }
}

export default OrderClient;
