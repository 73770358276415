import clone from 'clone';
import { List, Map, MapOf } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import CartClient from '../client/CartClient';
import EntityRegistry from '../entityFactory/EntityRegistry';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { assertRelationIsListOfObject } from '../errors/errorFactory';
import { CART_TYPE, CART_STATUS } from '../types/CartTypes';
import { FormCustomerField, FormData } from '../types/FormDataType';
import { parseDate } from '../utils/date';
import { getFormData } from '../utils/formData';
import Booking from './Booking';
import BookingTrack from './BookingTrack';
import CartItem from './CartItem';
import CartItemWithOffer from './CartItemWithOffer';
import Contract from './Contract';
import Coupon from './Coupon';
import Customer from './Customer';
import EventDateOptionStatus from './EventDateOptionStatus';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import OrganizationalUnit from './OrganizationalUnit';
import SellingDevice from './SellingDevice';
import Tag from './Tag';
import Ticket from './Ticket';
import Wallet from './Wallet';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type CartType = BaseEntity<'Cart'> & {
  comment: null | string;
  currency: null | string;
  customer: null | EntityRelation<Customer>;
  contact: null | EntityRelation<Customer>;
  cartItemList: null | List<EntityRelation<CartItem>>;
  cartItemWithOfferList: null | List<EntityRelation<CartItemWithOffer>>;
  contractList: null | List<EntityRelation<Contract>>;
  organizationalUnitList: null | List<EntityRelation<OrganizationalUnit>>;
  ticketList: null | List<EntityRelation<Ticket>>;
  nbTickets: null | number;
  bookingList: null | List<EntityRelation<Booking>>;
  createdAt: null | Moment;
  updatedAt: null | Moment;
  deletedAt: null | Moment;
  expiresAt: null | Moment;
  notifiedAt: null | Moment;
  bookTickets: boolean;
  orderList: null | List<EntityRelation<Order>>;
  originType: null | string;
  originId: null | string;
  customerPaidAmount: null | number;
  customerPaidAmountWithoutOffer: null | number;
  retailValue: null | number;
  facialValue: null | number;
  facialFeeValue: null | number;
  totalItemNumber: null | undefined | number;
  totalTicketNumber: null | number;
  reservationName: null | string;
  couponCode: null | string;
  couponCodeList: null | List<string>;
  couponValidityMap: null | Map<
    string,
    Map<'message' | 'isValid', string | boolean>
  >;
  coupon: null | EntityRelation<Coupon>;
  couponList: null | List<EntityRelation<Coupon>>;
  customerRequiredFields: null | List<
    Map<
      'slug' | 'required' | 'position' | string,
      string | boolean | number | unknown
    >
  >;
  walletList: null | List<EntityRelation<Wallet>>;
  tagList: null | List<EntityRelation<Tag>>;
  sellingDevice: null | EntityRelation<SellingDevice>;
  parent: null | EntityRelation<Cart>;
  type: null | CART_TYPE;
  isOption: boolean | null;
  computedBookingTrack: null | EntityRelation<BookingTrack>;
  option: null | EntityRelation<Cart>;
  reservation: null | EntityRelation<Cart>;
  eventDateOptionStatusList: null | List<EventDateOptionStatus>;
  status: null | CART_STATUS;
  appliedCouponList: null | List<EntityRelation<Coupon>>;
  createdCouponList: null | List<EntityRelation<Coupon>>;
  /**
   * formData is a helper that does not exist in the API, but help the front to handle customer and organization form data
   */
  formData: null | MapOf<FormData>;
};

class Cart extends NetworkEntity<CartType>({
  '@id': null,
  '@type': 'Cart',
  comment: null,
  currency: '',
  customer: new Customer(),
  contact: null,
  cartItemList: List<EntityRelation<CartItem>>(),
  cartItemWithOfferList: null,
  contractList: List<EntityRelation<Contract>>(),
  organizationalUnitList: List<EntityRelation<OrganizationalUnit>>(),
  ticketList: List<EntityRelation<Ticket>>(),
  nbTickets: null,
  bookingList: List<EntityRelation<Booking>>(),
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  expiresAt: null,
  notifiedAt: null,
  bookTickets: true,
  orderList: List<EntityRelation<Order>>(),
  originType: '',
  originId: null,
  customerPaidAmount: null,
  customerPaidAmountWithoutOffer: null,
  retailValue: null,
  facialValue: null,
  facialFeeValue: null,
  totalItemNumber: undefined,
  totalTicketNumber: null,
  reservationName: null,
  couponCode: null,
  couponCodeList: null,
  couponValidityMap: Map<
    string,
    Map<'message' | 'isValid', string | boolean>
  >(),
  coupon: new Coupon(),
  couponList: null,
  customerRequiredFields: null,
  walletList: List<EntityRelation<Wallet>>(),
  tagList: List<EntityRelation<Tag>>(),
  sellingDevice: null,
  parent: null,
  type: null,
  isOption: null,
  computedBookingTrack: null,
  option: null,
  reservation: null,
  eventDateOptionStatusList: null,
  status: null,
  appliedCouponList: null,
  createdCouponList: null,
  /**
   * formData is a helper that does not exist in the API, but help the front to handle customer and organization form data
   */
  formData: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CartType> = { '@id': null, '@type': 'Cart' },
    registry?: EntityRegistry
  ) {
    const data = clone(val);

    data.eventDateOptionStatusList = val.eventDateOptionStatusList
      ? List(
          val.eventDateOptionStatusList.map(
            (eventDateOptionStatus) =>
              new EventDateOptionStatus(eventDateOptionStatus)
          )
        )
      : null;

    data.cartItemWithOfferList =
      val.cartItemWithOfferList &&
      val.cartItemWithOfferList.map((ciwo) => {
        if (typeof ciwo === 'string') {
          throw new Error(
            'CartItemWithOffer should not be a string. This should not happen.'
          );
        }

        return new CartItemWithOffer(ciwo);
      });

    // TODO : this should be done in mapEntityRelationShips. Please remove this and test it.
    data.cartItemList = val.cartItemList
      ? List(
          val.cartItemList.map(
            (cartItem): EntityRelation<CartItem> => {
              if (typeof cartItem === 'string') {
                return cartItem;
              }

              return new CartItem(cartItem, registry);
            }
          )
        )
      : List<CartItem>();

    data.createdAt = parseDate(data.createdAt, null);
    data.updatedAt = parseDate(data.updatedAt, null);
    data.notifiedAt = parseDate(data.notifiedAt, null);
    data.formData = getFormData(data);

    super(data);
    return mapEntityRelationShips(this, data, registry);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/carts/', '') || '';
  }

  getLocalCreatedAt(): null | Moment {
    if (this.cartItemList && this.cartItemList.size > 0) {
      const firstCartItem = this.cartItemList.first();

      if (typeof firstCartItem !== 'string') {
        const tz = firstCartItem?.getIn([
          'ticketPrice',
          'eventDate',
          'ticketing',
          'timezone',
        ]);

        /** @ts-expect-error -- issue with moment & moment-timezone */
        return this.createdAt?.clone().tz(tz);
      }
    }

    // if no cartItems, fallback to utc time
    return this.createdAt;
  }

  isReservableOnline(): boolean {
    if (!this.cartItemList || this.cartItemList.size === 0) {
      return false;
    }

    return this.cartItemList.every((cartItem: EntityRelation<CartItem>) => {
      if (typeof cartItem === 'string') {
        throw new Error(
          'Unable to know if a cart is reservable online with cart item as string. Check that your cart entity does contain only CartItem instance.'
        );
      }

      return cartItem.getIn([
        'ticketPrice',
        'eventDate',
        'isReservableOnline',
      ]) as boolean;
    });
  }

  getGdprMessage() {
    assertRelationIsListOfObject(this.organizationalUnitList);
    const gdprOptinMessage = this.organizationalUnitList
      .map((organizationUnit) => organizationUnit.get('gdprOptinMessage'))
      .filter((message) => !!message && message.length > 0)
      .toSet()
      .join(', ');

    return gdprOptinMessage && gdprOptinMessage.length > 0
      ? gdprOptinMessage
      : null;
  }

  getFormCustomerFields(
    disabledFields = false,
    optionalFields?: boolean
  ): FormCustomerField[] {
    if (!this.customerRequiredFields) {
      throw new Error(
        'CustomerRequiredFields must be defined. Please check your request fields.'
      );
    }

    const customerRequiredFields = this.customerRequiredFields.toJS() as FormCustomerField[];
    const formattedFieldList = customerRequiredFields.map(
      (field: FormCustomerField) => {
        const fieldWithFormattedKey = Object.keys(field).reduce((acc, key) => {
          const formattedKey = key.startsWith('form')
            ? key.replace('form', '').toLowerCase()
            : key;

          return { ...acc, [formattedKey]: field[key] };
        }, field);

        const fieldOptions = field.options
          ? {
              ...field.options,
              inputProps: {
                ...field.options.inputProps,
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              options: field.options.options,
              isRequired: optionalFields ? false : field.required,
            }
          : {
              inputProps: {
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              isRequired: optionalFields ? false : field.required,
            };

        return {
          ...fieldWithFormattedKey,
          label: field.slug?.includes('optinEmailInfo')
            ? this.getGdprMessage()
            : field.label,
          name: field.slug,
          options: fieldOptions,
        };
      }
    );

    return formattedFieldList;
  }
}

/** @ts-expect-error -- method signature are incompatible */
Cart.classMetadata = new ClassMetadata('cart', 'carts', CartClient);
Cart.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('comment'),
  new Attribute('currency'),
  new Attribute('cartItemWithOfferList', 'cartItemWithOfferList', 'object'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('deletedAt', 'deletedAt', 'datetime'),
  new Attribute('expiresAt', 'expiresAt', 'datetime'),
  new Attribute('notifiedAt', 'notifiedAt', 'datetime'),
  new Attribute('bookTickets', 'bookTickets', 'boolean'),
  new Attribute('originType'),
  new Attribute('originId'),
  new Attribute('customerPaidAmount', 'customerPaidAmount', 'integer'),
  new Attribute(
    'customerPaidAmountWithoutOffer',
    'customerPaidAmountWithoutOffer',
    'integer'
  ),
  new Attribute('retailValue', 'retailValue', 'integer'),
  new Attribute('totalItemNumber', 'totalItemNumber', 'integer'),
  new Attribute('totalTicketNumber', 'totalTicketNumber', 'integer'),
  new Attribute('reservationName'),
  new Attribute('couponList', 'couponList', 'object'),
  new Attribute('couponCode'),
  new Attribute('couponCodeList', 'couponCodeList', 'object'),
  new Attribute('couponValidityMap', 'couponValidityMap', 'object'),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('parent'),
  new Attribute('nbTickets', 'nbTickets', 'integer'),
  new Attribute('type'),
  new Attribute('isOption', 'isOption', 'boolean'),
  new Attribute(
    'eventDateOptionStatusList',
    'eventDateOptionStatusList',
    'array'
  ),
  new Attribute('status'),
]);
Cart.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'cartItem', 'cartItemList'),
  new Relation(Relation.ONE_TO_MANY, 'tag', 'tagList'),
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.ONE_TO_MANY, 'booking', 'bookingList'),
  new Relation(Relation.ONE_TO_MANY, 'order', 'orderList'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'customer'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'contact'),
  new Relation(Relation.MANY_TO_ONE, 'coupon', 'coupon'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
  new Relation(Relation.ONE_TO_MANY, 'contract', 'contractList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'organizationalUnit',
    'organizationalUnitList'
  ),
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
  new Relation(Relation.MANY_TO_ONE, 'bookingTrack', 'bookingTrack'),
  new Relation(Relation.ONE_TO_ONE, 'cart', 'option'),
  new Relation(Relation.ONE_TO_ONE, 'cart', 'reservation'),
  new Relation(Relation.ONE_TO_MANY, 'coupon', 'appliedCouponList'),
  new Relation(Relation.ONE_TO_MANY, 'coupon', 'createdCouponList'),
]);

export default Cart;
