import { List, Map } from 'immutable';
import { Cart, Customer, SellingDevice } from 'mapado-ticketing-js-sdk';
import { OfferCluster } from '../components/common/offerCluster';
import {
  SHOPPING_CART,
  type CartAtomId,
  type ORIGIN_TYPE_ENV,
} from '../redux/booking/constants';
import type {
  InnerBookingStoreType,
  StoreWithBooking,
} from '../redux/booking/reducer';
import type {
  TicketPriceQuantityInput,
  TicketPriceQuantity,
} from '../redux/booking/utils/ticketPriceQuantities';
import { useCartSelector } from './typedFunctions';

function getCurrentCartState(state: StoreWithBooking): InnerBookingStoreType {
  return state.booking[state.booking.currentCartAtomId];
}

export function getCurrentCartAtomId(state: StoreWithBooking): CartAtomId {
  return state.booking.currentCartAtomId;
}

function getAtomCartState(
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): InnerBookingStoreType | undefined {
  return state.booking[cartAtomId];
}

export const getCurrentCartUpdating = (state: StoreWithBooking): boolean =>
  getCurrentCartState(state).get('currentCartUpdating');

export function getCurrentCart(state: StoreWithBooking): null | Cart {
  const innerState = getCurrentCartState(state);

  return innerState.get('currentCart');
}

export function getAtomCart(
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): null | Cart {
  const innerState = getAtomCartState(state, cartAtomId);

  return innerState?.get('currentCart') || null;
}

/**
 * current shopping cart
 */
export const getShoppingCart = (state: StoreWithBooking): Cart | null =>
  state.booking[SHOPPING_CART].get('currentCart');

export const useShoppingCart = (): Cart | null =>
  useCartSelector(getShoppingCart);

export const useShoppingCartTicketPriceQuantities =
  (): List<TicketPriceQuantity> =>
    useCartSelector((state: StoreWithBooking) =>
      state.booking[SHOPPING_CART].get('currentTicketPriceQuantities')
    );

export const getHasCartIdInStorage = (
  state: StoreWithBooking
): null | boolean => state.booking[SHOPPING_CART].get('hasCartIdInStorage');

export const getOriginTypeEnv = (
  state: StoreWithBooking
): ORIGIN_TYPE_ENV | null => state.booking.originTypeEnv;

export const getConvertToReservation = (state: StoreWithBooking): boolean =>
  getCurrentCartState(state).get('convertToReservation');

export const getAtomConvertToReservation = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): boolean =>
  getAtomCartState(state, cartAtomId)?.get('convertToReservation') || false;

export const getSellingDevice = (
  state: StoreWithBooking
): SellingDevice | null => state.booking.sellingDevice;

export const getAtomReservationCustomer = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): Customer | null =>
  getAtomCartState(state, cartAtomId)?.get('reservationCustomer') ?? null;

export const getAtomReservationContact = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): Customer | null | undefined =>
  getAtomCartState(state, cartAtomId)?.get('reservationContact') ?? null;

export const getCurrentTicketPriceQuantities = (
  state: StoreWithBooking
): List<TicketPriceQuantity> =>
  getCurrentCartState(state).get('currentTicketPriceQuantities');

export const getAtomTicketPriceQuantities = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): List<TicketPriceQuantity> =>
  getAtomCartState(state, cartAtomId)?.get('currentTicketPriceQuantities') ??
  List();

export const getAtomOfferClusterList = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): List<OfferCluster> =>
  getAtomCartState(state, cartAtomId)?.get('offerClusterList') ?? List();

export const getAtomPendingTicketPriceQuantities = (
  state: StoreWithBooking,
  cartAtomId: CartAtomId
): List<TicketPriceQuantityInput> =>
  getAtomCartState(state, cartAtomId)?.get('pendingTicketPriceQuantities') ??
  List();

export const getCurrentBookingError = (
  state: StoreWithBooking
): string | null => getCurrentCartState(state).get('bookingError');

export const getCurrentMapadoError = (
  state: StoreWithBooking
): Map<string, unknown> | null =>
  getCurrentCartState(state).get('mapado:error');
