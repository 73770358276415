import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import HidableClient from '../client/HidableClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import ScanTrackCheckpoint from './ScanTrackCheckpoint';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ScanTrackType = HidableType &
  BaseEntity<'ScanTrack'> & {
    contract: null | EntityRelation<Contract>;
    name: null | string;
    isDefault: null | boolean;
    isDeletable: null | boolean;
    isHidable: null | boolean;
    maxScanNb: null | number;
    scanTrackCheckpointList: null | List<EntityRelation<ScanTrackCheckpoint>>;
  };

class ScanTrack extends NetworkEntity<ScanTrackType>({
  '@id': null,
  '@type': 'ScanTrack',
  contract: new Contract(),
  name: null,
  isDefault: null,
  isDeletable: null,
  isHidable: null,
  maxScanNb: null,
  scanTrackCheckpointList: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ScanTrackType> = { '@id': null, '@type': 'ScanTrack' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/scan_tracks/', '') || '';
  }
}

ScanTrack.classMetadata = new ClassMetadata(
  'scanTrack',
  'scan_tracks',
  /** @ts-expect-error -- method signature are incompatible */
  HidableClient
);
ScanTrack.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('maxScanNb', 'maxScanNb', 'integer'),
  new Attribute('isDefault', 'isDefault', 'boolean'),
  new Attribute('isDeletable', 'isDeletable', 'boolean'),
  new Attribute('isHidable', 'isHidable', 'boolean'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);
ScanTrack.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(
    Relation.ONE_TO_MANY,
    'scanTrackCheckpoint',
    'scanTrackCheckpointList'
  ),
]);

export default ScanTrack;
