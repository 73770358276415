import URI from 'urijs';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class DeviceStateClient extends AbstractClient<TSMetadata['deviceState']> {
  findPhysicalTicketUrl(
    deviceStateId: string,
    ticketId: number,
    paperType: string,
    format = 'png'
  ): URI {
    const uri = new URI(
      `${deviceStateId}/tickets/${ticketId}/${paperType}.${format}`
    );
    return this.makeUri(uri);
  }

  findPhysicalCouponTicketUrl(
    deviceStateId: string,
    orderItemId: number,
    paperType: string,
    format = 'png'
  ): URI {
    const uri = new URI(
      `${deviceStateId}/coupons/${orderItemId}/${paperType}.${format}`
    );
    return this.makeUri(uri);
  }

  findPhysicalTestTicketUrl(
    deviceStateId: string,
    entityId: number,
    paperType: string,
    format: string,
    makeRequest: false,
    entityType: string
  ): URI;

  findPhysicalTestTicketUrl(
    deviceStateId: string,
    entityId: number,
    paperType: string,
    format: string,
    makeRequest: true,
    entityType: string
  ): Promise<Response>;

  findPhysicalTestTicketUrl(
    deviceStateId: string,
    entityId: number,
    paperType: string,
    format = 'png',
    makeRequest = false,
    entityType = 'event_dates'
  ): URI | Promise<Response> {
    const uri = new URI(
      `${deviceStateId}/tickets/test/${entityType}/${entityId}/${paperType}.${format}`
    );

    if (makeRequest) {
      return this.authorizedFetch(`/v1/device_states/${uri}`, {
        method: 'GET',
      });
    }

    return this.makeUri(uri);
  }
}

export default DeviceStateClient;
