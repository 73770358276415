import clone from 'clone';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SubscriptionClient from '../client/SubscriptionClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Coupon from './Coupon';
import Customer from './Customer';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import Payment from './Payment';
import SubscriptionMaturity from './SubscriptionMaturity';
import Wallet from './Wallet';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

// eslint-disable-next-line no-shadow
export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAYMENT_FAILED = 'payment_failed',
  MISSING_PROVIDER_METADATA = 'missing_provider_metadata',
  DISABLED = 'disabled',
  ENDED = 'ended',
}

export type SubscriptionType = BaseEntity<'Subscription'> & {
  createdAt: null | Moment;
  currency: null | string;
  description: null | string;
  name: null | string;
  provider: null | string;
  providerMetadata: null | Record<string, unknown>;
  status: null | string;
  updatedAt: null | Moment;
  coupon: null | EntityRelation<Coupon>;
  customer: null | EntityRelation<Customer>;
  wallet: null | EntityRelation<Wallet>;
  payment: null | EntityRelation<Payment>;
  order: null | EntityRelation<Order>;
  subscriptionMaturityList: null | List<EntityRelation<SubscriptionMaturity>>;
  parameters: null | Map<string, unknown>;
  sepaMandateUniqueRef: null | string;
};

class Subscription extends NetworkEntity<SubscriptionType>({
  '@id': null,
  '@type': 'Subscription',
  createdAt: null,
  currency: null,
  description: null,
  name: null,
  provider: null,
  providerMetadata: null,
  status: null,
  updatedAt: null,
  coupon: null,
  customer: null,
  wallet: null,
  payment: null,
  order: null,
  subscriptionMaturityList: null,
  parameters: null,
  sepaMandateUniqueRef: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SubscriptionType> = {
      '@id': null,
      '@type': 'Subscription',
    }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['wallet', 'contract', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/subscriptions/', '') || '';
  }
}

Subscription.classMetadata = new ClassMetadata(
  'subscription',
  'subscriptions',
  /** @ts-expect-error -- method signature are incompatible */
  SubscriptionClient
);
Subscription.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('currency'),
  new Attribute('description'),
  new Attribute('name'),
  new Attribute('provider'),
  new Attribute('parameters', 'parameters', 'array'),
  new Attribute('providerMetadata', 'providerMetadata', 'array'),
  new Attribute('status'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('sepaMandateUniqueRef'),
]);
Subscription.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'coupon', 'coupon'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'customer'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
  new Relation(Relation.MANY_TO_ONE, 'payment', 'payment'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(
    Relation.ONE_TO_MANY,
    'subscriptionMaturity',
    'subscriptionMaturityList'
  ),
]);

export default Subscription;
