import React, {
  PureComponent,
  RefObject,
  ChangeEvent,
  ReactElement,
} from 'react';

type DefaultProps = {
  required: boolean;
  defaultValue: string;
};

type Props = DefaultProps & {
  id: string;
  label?: string;
  maxLength: number;
  onChange?: (s: string) => void;
  errorMessage?: string;
};

class MpdInputByCharacter extends PureComponent<Props> {
  static defaultProps: DefaultProps = {
    required: false,
    defaultValue: '',
  };

  #input: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.#input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
  }

  get value(): string | undefined {
    return this.#input.current?.value;
  }

  handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const { onChange } = this.props;

    if (!onChange) {
      return;
    }

    onChange(value);
  }

  render(): ReactElement {
    const {
      id,
      label,
      maxLength,
      required,
      errorMessage,
      defaultValue,
    } = this.props;
    const inputWidth = `${maxLength * 2 + maxLength - 0.1}ch`;

    return (
      <div className="form-group form-group--letter">
        {label && (
          <label htmlFor={id} className="mpd-color-gray small inbl mb1">
            {label} {required && <span className="mpd-color-red">*</span>}
          </label>
        )}
        <div className="input-container" style={{ width: inputWidth }}>
          <div>
            <input
              type="text"
              id={id}
              maxLength={maxLength}
              style={{ width: inputWidth }}
              onChange={this.handleChange}
              ref={this.#input}
              defaultValue={defaultValue?.substring(0, maxLength)}
            />
            {errorMessage && (
              <p className="mpd-color-red smaller">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MpdInputByCharacter;
