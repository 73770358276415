import * as React from 'react';
import type { SVGProps } from 'react';
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 16" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-456 -1048)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="liste"
            d="M358 227h2.667v2.667H358zm5.333 0h20v2.667h-20zM358 233.667h2.667v2.666H358zm5.333 0h20v2.666h-20zM358 240.333h2.667V243H358zm5.333 0h20V243h-20z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgList;
