import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TagClient from '../client/TagClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Cart from './Cart';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import Ticketing from './Ticketing';
import type { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TagType = BaseEntity<'Tag'> & {
  ticketingList: List<EntityRelation<Ticketing>>;
  contract: null | EntityRelation<Contract>;
  name: null | string;
  nameTokenized: null | string;
  uniqueId: null | string;
  ticketing: EntityRelation<Ticketing>;
  cart: Cart;
  order: Order;
  orderList: List<EntityRelation<Order>>;
  cartList: List<EntityRelation<Cart>>;
};

class Tag extends NetworkEntity<TagType>({
  '@id': null,
  '@type': 'Tag',
  ticketingList: List<Ticketing>(),
  contract: null,
  name: null,
  nameTokenized: null,
  uniqueId: null,
  ticketing: new Ticketing(),
  cart: new Cart(),
  order: new Order(),
  orderList: List<Order>(),
  cartList: List<Cart>(),
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<TagType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/tags/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Tag.classMetadata = new ClassMetadata('tag', 'tags', TagClient);
Tag.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('nameTokenized'),
  new Attribute('uniqueId'),
]);
Tag.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'cart', 'cart'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(Relation.ONE_TO_MANY, 'cart', 'cartList'),
  new Relation(Relation.ONE_TO_MANY, 'order', 'orderList'),
  new Relation(Relation.ONE_TO_MANY, 'ticketing', 'ticketingList'),
]);

export default Tag;
