import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="2 2 25 25" className="mpd-icon" {...props}>
    <path
      id="path-1"
      d="M23 2v4h4v21H6v-4H2V2zm2 6H8v17h17zm-7.5 3v4.499l4.5.001v2l-4.5-.001V22h-2v-4.501L11 17.5v-2l4.5-.001V11zM21 4H4v17h2V6h15z"
    />
  </svg>
);
export default SvgDuplicate;
