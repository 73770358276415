// breakpoints are in px

export default {
  'breakpoint-to-tiny': 479,
  'breakpoint-from-tiny': 480,

  'breakpoint-to-mini': 619,
  'breakpoint-from-mini': 620,

  'breakpoint-to-small': 767,
  'breakpoint-from-small': 768,

  'breakpoint-to-medium': 879,
  'breakpoint-from-medium': 880,

  'breakpoint-to-large': 989,
  'breakpoint-from-large': 990,

  'breakpoint-to-wide': 1199,
  'breakpoint-from-wide': 1200,
};
