import i18next from 'i18next';
import type { i18n as I18nType } from 'i18next';
import ICU from 'i18next-icu';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

// =================================

let assetPathsPromise: undefined | Promise<Record<string, string>>;

function getAssetPath() {
  if (!assetPathsPromise) {
    assetPathsPromise = fetch(
      'https://translations.mapado.net/asset_paths.json'
    ).then((response): Promise<Record<string, string>> => response.json());
  }

  return assetPathsPromise;
}

export interface BackendLoader {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (language: string, namespace: string): Promise<Record<string, any>>;
}

interface ExtraConfig {
  postProcess?: string | false | readonly string[] | undefined;
  useHttpBackend?: boolean;
}

export function getI18NextInstance(
  projectName: string,
  namespaceList: Array<string>,
  defaultNamespace: string,
  languageList: Array<string>,
  defaultLanguage: string,
  backendLoader: BackendLoader,
  extraConfig: ExtraConfig = {
    postProcess: undefined,
    useHttpBackend: true,
  }
): I18nType {
  const dynamicImportBackend = resourcesToBackend(
    (language, namespace, callback) => {
      return backendLoader(language, namespace)
        .then(({ default: resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }
  );

  const httpBackend = new HttpApi(null, {
    allowMultiLoading: false, // we do not handle loading multiple translations at once
    crossDomain: false, // allow loading from translations.mapado.net
    loadPath(languages, namespaces) {
      const lng = languages[0];
      const ns = namespaces[0];

      if (languageList.indexOf(lng) === -1) {
        return Promise.resolve('https://translations.mapado.net/404');
        // should be handled in the .catch() here : https://github.com/i18next/i18next-http-backend/blob/master/lib/index.js#L61-L64
        // return Promise.reject(new Error(`Unknown language: ${lng}`));
      }

      return getAssetPath()
        .then((assetPaths) => {
          return `https://translations.mapado.net/${
            assetPaths[`${projectName}.${ns}.${lng}.json`]
          }`;
        })
        .catch(() => {
          // should be handled in the .catch() here : https://github.com/i18next/i18next-http-backend/blob/master/lib/index.js#L61-L64
          return 'https://translations.mapado.net/404';
        });
    },
  });

  const i18nInstance = i18next.createInstance();

  let backend: undefined | { backends: any[] };
  if (process.env.NODE_ENV === 'test') {
    backend = undefined;
  } else if (extraConfig.useHttpBackend === false) {
    backend = { backends: [dynamicImportBackend] };
  } else {
    backend = { backends: [httpBackend, dynamicImportBackend] };
  }

  i18nInstance
    .use(ICU)
    .use(initReactI18next) // passes i18next down to react-i18next
    .use(ChainedBackend)
    .init({
      ns: namespaceList, // admin should be loaded explicitly when needed, See https://www.i18next.com/principles/namespaces#sample
      defaultNS: defaultNamespace,

      backend,

      resources:
        process.env.NODE_ENV === 'test'
          ? {
              [defaultLanguage]: {
                [defaultNamespace]: {},
              },
            }
          : undefined,

      lng: defaultLanguage, // if you're using a language detector, do not define the lng option
      fallbackLng: defaultLanguage,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      postProcess: extraConfig.postProcess,
    });

  return i18nInstance;
}
