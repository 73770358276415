import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class ContingentClient extends HidableClient<TSMetadata['contingent']> {
  getPathBase(): string {
    return '/v1/contingents';
  }
}

export default ContingentClient;
