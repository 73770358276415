// generated file, do not edit
import { JSXElementConstructor, SVGProps } from 'react';
import {
  Access,
  Action,
  Agenda,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowTop,
  Bell,
  Building,
  Cart,
  CashDrawer,
  CheckStroke,
  Check,
  ClockNoHands,
  ClockStroke,
  Crm,
  CrossFilled,
  CrossStroke,
  Cross,
  Cut,
  Desk,
  Disallow,
  Download,
  Drag,
  Duplicate,
  Earth,
  Exclamation,
  ExternalProviderFilled,
  ExternalProvider,
  EyeClosed,
  Eye,
  Faq,
  Favorite,
  Filter,
  FolderFilled,
  Folder,
  FormArrow,
  Geopoint,
  Gift,
  Home,
  Hourglass,
  Id,
  Image,
  List,
  Logo,
  Logout,
  LongArrowDown,
  LongArrowLeft,
  LongArrowRight,
  LongArrowTop,
  MagnifyingGlass,
  Mail,
  MinusStroke,
  Minus,
  Option,
  Parameter,
  ParticipantFilled,
  Participant,
  PaymentCard,
  PaymentLater,
  PenSquare,
  Pen,
  Percent,
  Phone,
  Pin,
  PlusStroke,
  Plus,
  Printer,
  QuestionMark,
  ReducedMobilityObstructedView,
  ReducedMobility,
  Refresh,
  RemoveInput,
  Reporting,
  ScanQr,
  SeatFilled,
  SeatFoldableFilled,
  SeatFoldableObstructedView,
  SeatFoldable,
  SeatObstructedView,
  SeatStandingObstructedView,
  SeatStanding,
  Seat,
  Selection,
  SendMail,
  Settings,
  Team,
  TicketCancelled,
  Ticket,
  Trash,
  Unarchive,
  User,
  Valid,
  Web,
  XCards,
} from '../fonts/mapado-icons/svg';

export function getIconTag(
  icon: string
): JSXElementConstructor<SVGProps<SVGSVGElement>> | null {
  /* eslint-disable no-nested-ternary */
  // prettier-ignore
  const iconTag =
    icon === 'access' ? Access :
    icon === 'action' ? Action :
    icon === 'agenda' ? Agenda :
    icon === 'archive' ? Archive :
    icon === 'arrow-down' ? ArrowDown :
    icon === 'arrow-left' ? ArrowLeft :
    icon === 'arrow-right' ? ArrowRight :
    icon === 'arrow-top' ? ArrowTop :
    icon === 'bell' ? Bell :
    icon === 'building' ? Building :
    icon === 'cart' ? Cart :
    icon === 'cash-drawer' ? CashDrawer :
    icon === 'check-stroke' ? CheckStroke :
    icon === 'check' ? Check :
    icon === 'clock-no-hands' ? ClockNoHands :
    icon === 'clock-stroke' ? ClockStroke :
    icon === 'crm' ? Crm :
    icon === 'cross-filled' ? CrossFilled :
    icon === 'cross-stroke' ? CrossStroke :
    icon === 'cross' ? Cross :
    icon === 'cut' ? Cut :
    icon === 'desk' ? Desk :
    icon === 'disallow' ? Disallow :
    icon === 'download' ? Download :
    icon === 'drag' ? Drag :
    icon === 'duplicate' ? Duplicate :
    icon === 'earth' ? Earth :
    icon === 'exclamation' ? Exclamation :
    icon === 'external-provider-filled' ? ExternalProviderFilled :
    icon === 'external-provider' ? ExternalProvider :
    icon === 'eye-closed' ? EyeClosed :
    icon === 'eye' ? Eye :
    icon === 'faq' ? Faq :
    icon === 'favorite' ? Favorite :
    icon === 'filter' ? Filter :
    icon === 'folder-filled' ? FolderFilled :
    icon === 'folder' ? Folder :
    icon === 'form-arrow' ? FormArrow :
    icon === 'geopoint' ? Geopoint :
    icon === 'gift' ? Gift :
    icon === 'home' ? Home :
    icon === 'hourglass' ? Hourglass :
    icon === 'id' ? Id :
    icon === 'image' ? Image :
    icon === 'list' ? List :
    icon === 'logo' ? Logo :
    icon === 'logout' ? Logout :
    icon === 'long-arrow-down' ? LongArrowDown :
    icon === 'long-arrow-left' ? LongArrowLeft :
    icon === 'long-arrow-right' ? LongArrowRight :
    icon === 'long-arrow-top' ? LongArrowTop :
    icon === 'magnifying-glass' ? MagnifyingGlass :
    icon === 'mail' ? Mail :
    icon === 'minus-stroke' ? MinusStroke :
    icon === 'minus' ? Minus :
    icon === 'option' ? Option :
    icon === 'parameter' ? Parameter :
    icon === 'participant-filled' ? ParticipantFilled :
    icon === 'participant' ? Participant :
    icon === 'payment-card' ? PaymentCard :
    icon === 'payment-later' ? PaymentLater :
    icon === 'pen-square' ? PenSquare :
    icon === 'pen' ? Pen :
    icon === 'percent' ? Percent :
    icon === 'phone' ? Phone :
    icon === 'pin' ? Pin :
    icon === 'plus-stroke' ? PlusStroke :
    icon === 'plus' ? Plus :
    icon === 'printer' ? Printer :
    icon === 'question-mark' ? QuestionMark :
    icon === 'reduced-mobility-obstructed-view' ? ReducedMobilityObstructedView :
    icon === 'reduced-mobility' ? ReducedMobility :
    icon === 'refresh' ? Refresh :
    icon === 'remove-input' ? RemoveInput :
    icon === 'reporting' ? Reporting :
    icon === 'scan-qr' ? ScanQr :
    icon === 'seat-filled' ? SeatFilled :
    icon === 'seat-foldable-filled' ? SeatFoldableFilled :
    icon === 'seat-foldable-obstructed-view' ? SeatFoldableObstructedView :
    icon === 'seat-foldable' ? SeatFoldable :
    icon === 'seat-obstructed-view' ? SeatObstructedView :
    icon === 'seat-standing-obstructed-view' ? SeatStandingObstructedView :
    icon === 'seat-standing' ? SeatStanding :
    icon === 'seat' ? Seat :
    icon === 'selection' ? Selection :
    icon === 'send-mail' ? SendMail :
    icon === 'settings' ? Settings :
    icon === 'team' ? Team :
    icon === 'ticket-cancelled' ? TicketCancelled :
    icon === 'ticket' ? Ticket :
    icon === 'trash' ? Trash :
    icon === 'unarchive' ? Unarchive :
    icon === 'user' ? User :
    icon === 'valid' ? Valid :
    icon === 'web' ? Web :
    icon === 'x-cards' ? XCards :
    null;
  /* eslint-enable no-nested-ternary */

  return iconTag;
}
