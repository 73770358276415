import { Entity } from '../entityFactory/types';

// eslint-disable-next-line import/prefer-default-export
export function getEntityId(entity: null | string | Entity): string {
  if (!entity) {
    return '';
  }

  if (typeof entity === 'string') {
    return entity;
  }

  // @ts-expect-error entity should has a "get" method
  return entity.get('@id') || '';
}

function isNonNullObject(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null;
}

export function extractTimezone(
  data: Record<string, unknown>,
  path: Array<string | number>
): string | null {
  let tz = null;
  let nextObject: unknown = data;

  path.forEach((key, index) => {
    if (isNonNullObject(nextObject) && typeof key === 'string') {
      nextObject = nextObject[key];
    } else if (Array.isArray(nextObject) && typeof key === 'number') {
      nextObject = nextObject[key];
    } else {
      nextObject = null;
    }

    if (index === path.length - 1 && typeof nextObject === 'string') {
      tz = nextObject;
    }
  });

  return tz;
}
