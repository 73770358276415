import React, { ReactElement, useState } from 'react';
import {
  MultiContextPlan,
  SeatingPlanPurchase,
  StockManagement,
} from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';
import { getCart } from '../entityFactory';

type Props = {
  eventDateId: number;
};

export default function DemoMultiContext({ eventDateId }: Props): ReactElement {
  const [index, setIndex] = useState('');

  return (
    <>
      <EventDatePagesHeader title="Multi-contextes" eventDateId={eventDateId} />

      <MultiContextPlan
        selectedIndex={index}
        onSelectIndex={setIndex}
        hiddenPlanContext={[]}
      >
        <SeatingPlanPurchase
          eventDateId={eventDateId}
          // @ts-expect-error -- don't really care about the type here
          setTicketPriceQuantity={console.log}
          lockSeatsForEventDate={console.log}
          cart={getCart()}
          currentContingentId={null}
        />
        <StockManagement eventDateId={eventDateId} />
      </MultiContextPlan>
    </>
  );
}
