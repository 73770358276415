import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatStanding = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 9 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M4.5 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m0 1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatStanding;
