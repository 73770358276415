import { List, Map, Record } from 'immutable';

interface SheetType {
  sheetIndex: number;
  sheetName: string;
  columns: Map<string, null | string>;
  values: List<Map<string, string | number | null>>;
  distinctValues: Map<string, null | []>;
  dataToImport: number | null;
}

class Sheet extends Record<SheetType>({
  columns: Map<string, null | string>(),
  values: List<Map<string, string | number | null>>(),
  distinctValues: Map<string, null | []>(),
  sheetName: '',
  sheetIndex: 0,
  dataToImport: null,
}) {}

export default Sheet;
