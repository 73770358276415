import { List } from 'immutable';
import URI from 'urijs';
import PagedCollection from '../entity/PagedCollection';
import Ticketing from '../entity/Ticketing';
import { TSMetadata } from '../mapping';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class TicketingClient extends AbstractClient<TSMetadata['ticketing']> {
  getPathBase(): string {
    return '/v1/ticketings';
  }

  /** @deprecated */
  findByActivity(
    activityUuid: string,
    fields: FieldsParameter
  ): Promise<PagedCollection<Ticketing>> {
    return this.findBy({ activityUuid }, fields);
  }

  /** @deprecated */
  findByActivityUuidList(
    activityUuidList: List<string> | Array<string>,
    fields: FieldsParameter
  ): Promise<PagedCollection<Ticketing>> {
    const uuidList = activityUuidList.join(',');

    return this.findBy({ activityUuid: uuidList }, fields);
  }

  closeSales(
    id: number,
    fields: FieldsParameter,
    queryParam = {}
  ): Promise<Ticketing> {
    return this._massAction('close', id, fields, queryParam);
  }

  suspendSales(
    id: number,
    fields: FieldsParameter,
    queryParam = {}
  ): Promise<Ticketing> {
    return this._massAction('suspend', id, fields, queryParam);
  }

  openSales(
    id: number,
    fields: FieldsParameter,
    queryParam = {}
  ): Promise<Ticketing> {
    return this._massAction('open', id, fields, queryParam);
  }

  private _massAction(
    action: 'close' | 'open' | 'suspend',
    id: number,
    fields: FieldsParameter,
    queryParam: Record<string, unknown> = {}
  ): Promise<Ticketing> {
    if (['close', 'open', 'suspend'].indexOf(action) === -1) {
      throw new Error('action must be one of close|open|suspend');
    }

    const url = new URI(`${this.getPathBase()}/${id}/${action}-sales`);
    url.addSearch(this._mergeFieldsIntoParams(queryParam, fields));

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
      }),
      'item'
    );
  }
}

export default TicketingClient;
