import React, { ReactElement } from 'react';
import { OrderReplacement } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';

type Props = {
  eventDateId: number;
  orderId: number;
};

export default function DemoOrderReplacement({
  eventDateId,
  orderId,
}: Props): ReactElement {
  return (
    <>
      <EventDatePagesHeader
        title="Replacement de commande"
        eventDateId={eventDateId}
      />

      <OrderReplacement
        orderId={orderId}
        eventDateId={eventDateId}
        onDismissSeating={() => console.log('dismiss seating')}
      />
    </>
  );
}
