import { EventDateType } from '../propTypes';

// eslint-disable-next-line import/prefer-default-export
export function getNotInContingentTotalStock(eventDate: EventDateType): number {
  const { nbActiveSeats, stockContingentList } = eventDate;

  const totalStockContingent = stockContingentList.reduce(
    (acc: number, curr) =>
      acc + (curr.totalStock ?? 0) - (curr.dismissedStock ?? 0),
    0
  );

  return nbActiveSeats - totalStockContingent;
}
