import * as React from 'react';
import type { SVGProps } from 'react';
const SvgId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-173 -1044)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="Id"
            d="M78 225.667v18.666h24v-18.666zM75.333 223h29.334v24H75.333zm5.334 5.333h6.666V235h-6.666zm9.333 0h9.333V231H90zm0 5.334h9.333v2.666H90zM90 239h6.667v2.667H90z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgId;
