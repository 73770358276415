import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { Cart } from 'mapado-ticketing-js-sdk';

const CartContext = createContext<Cart | null>(null);

export function useCartContext(): Cart | null {
  return useContext(CartContext);
}

export function CartContextProvider({
  cart,
  children,
}: {
  cart: Cart | null;
  children: ReactNode;
}): ReactElement {
  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
}
