import URI from 'urijs';
import Customer from '../entity/Customer';
import PagedCollection from '../entity/PagedCollection';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class CustomerClient extends AbstractClient<TSMetadata['customer']> {
  getPathBase(): string {
    return '/v1/customers';
  }

  bulkUpdate(
    customerList: Record<string, unknown>[],
    fields: FieldsParameter
  ): Promise<PagedCollection<Customer>> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(uri, {
        method: 'POST',
        body: JSON.stringify({
          'hydra:member': customerList,
        }),
      }),
      'list'
    );
  }
}

export default CustomerClient;
