import { Record } from 'immutable';

export type OptionStressType = {
  optionNb: number;
  score: number;
};

class OptionStress extends Record<OptionStressType>({
  optionNb: 0,
  score: 0,
}) {}

export default OptionStress;
