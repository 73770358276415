import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBuilding = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="building">
        <path
          id="Combined-Shape"
          d="M5 3.993C5 3.445 5.445 3 5.996 3h11.858c.55 0 .996.445.996.993v16.014a.994.994 0 0 1-.996.993H5.996A.995.995 0 0 1 5 20.007zm1.385.392v4.153h2.77V4.385zm4.155 0v4.153h2.77V4.385zm4.155 0v4.153h2.77V4.385zm-8.31 5.538v4.154h2.77V9.923zm4.155 0v4.154h2.77V9.923zm0 6.923V21h2.77v-4.154zm4.155-6.923v4.154h2.77V9.923z"
        />
      </g>
    </g>
  </svg>
);
export default SvgBuilding;
