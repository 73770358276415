import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExternalProvider = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 11 13" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M10.303 8.342c0-1.58-1.441-2.861-3.22-2.861H3.22C1.442 5.481 0 6.761 0 8.341v3.822h10.303zM5.522 6.74h1.561c1.067 0 1.932.724 1.932 1.618v2.551H1.288V8.36c0-.895.865-1.62 1.932-1.62h1.258v1.967L3.623 8a.564.564 0 0 0-.702 0 .46.46 0 0 0-.171.351c0 .138.065.264.17.352l1.729 1.424a.564.564 0 0 0 .702 0L7.08 8.703a.46.46 0 0 0 .171-.352.46.46 0 0 0-.17-.35.564.564 0 0 0-.703 0l-.855.705zm2.083-4.222C7.605 1.128 6.45 0 5.029 0S2.453 1.127 2.453 2.518 3.606 5.035 5.03 5.035c1.422 0 2.576-1.127 2.576-2.517m-3.864 0c0-.696.577-1.26 1.288-1.26s1.288.564 1.288 1.26c0 .695-.577 1.258-1.288 1.258S3.74 3.213 3.74 2.518"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExternalProvider;
