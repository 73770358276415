import clone from 'clone';
import { List } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import EventDateOptionStress from './EventDateOptionStress';
import NetworkEntity from './NetworkEntity';
import OptionStress from './OptionStress';
import { BaseEntity, PartialEntity } from '.';

export type TicketingOptionStressType = BaseEntity<'TicketingOptionStress'> & {
  ticketingId: null | string;
  optionStress: null | OptionStress;
  eventDateOptionStressList: null | List<EventDateOptionStress>;
};
class TicketingOptionStress extends NetworkEntity<TicketingOptionStressType>({
  '@id': null,
  '@type': 'TicketingOptionStress',
  ticketingId: null,
  optionStress: null,
  eventDateOptionStressList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketingOptionStressType> = {
      '@id': null,
      '@type': 'TicketingOptionStress',
    }
  ) {
    const data = clone(val);

    if (val.eventDateOptionStressList) {
      data.eventDateOptionStressList = val.eventDateOptionStressList.map(
        (optionStress) => new EventDateOptionStress(optionStress)
      );
    }

    if (val.optionStress) {
      data.optionStress = new OptionStress(val.optionStress);
    }

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticketing_option_stresses/', '') || '';
  }
}

TicketingOptionStress.classMetadata = new ClassMetadata(
  'ticketingOptionStress',
  'ticketing_option_stresses',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);

TicketingOptionStress.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('ticketingId'),
  new Attribute('optionStress', 'optionStress', 'object'),
  new Attribute(
    'eventDateOptionStressList',
    'eventDateOptionStressList',
    'array'
  ),
]);

export default TicketingOptionStress;
