import React, { useCallback, useEffect, useRef } from 'react';
import { Set } from 'immutable';
import { getEntityId, getShortId } from '@mapado/js-component';
import {
  assertRelationIsDefined,
  assertRelationIsString,
  Booking,
} from 'mapado-ticketing-js-sdk';
import { initOrderManagement } from '../../actions/AppActions';
import { focusBooking, selectBatchOfSeats } from '../../actions/SeatActions';
import { getSelectableSeatIdSetForOrderManagement } from '../../utils/seatSelectable';
import { getLongId, getSeatIdForAvailableSeat } from '../../utils/entity';
import Seating from '../Seating';
import SeatingLoader from '../SeatingLoader';
import EventDateCaptionWithFetch from '../Caption/EventDateCaption';
import OrderManagementSidebar from '../OrderManagement/Sidebar/index';
import OrderManagementSeat from '../Seat/OrderManagementSeat';
import { seatConfigSelector } from '../../utils/selectors';
import { AvailableSeatType } from '../../propTypes';
import TicketingSdkInstance from '../../TicketingSdkInstance';
import {
  CART_FIELDS_FOR_BOOKING,
  filterCartOnEventDate,
  filterOrderOnEventDate,
  ORDER_FIELDS_FOR_BOOKING,
} from '../OrderManagement/BookingActions';
import { SET_BOOKING_STATUS } from '../../reducers/types';
import '../../styles/components/App.scss';
import BookingListProvider from '../OrderManagement/BookingListProvider';
import {
  useSeatingDispatch,
  useSeatingSelector,
} from '../../reducers/typedFunctions';

export type StateProps = {
  isReady: boolean;
};

export type OrderManagementProps = {
  displayEventDateInfo?: boolean;
  eventDateId: number;
  canMoveSeats?: boolean;
};

type Props = StateProps & OrderManagementProps;

function OrderManagement({
  isReady,
  displayEventDateInfo = true,
  eventDateId,
  canMoveSeats = true,
}: Props) {
  const svgRef = useRef(null);

  const dispatch = useSeatingDispatch();
  const selectBookingForAvailableSeat = useSelectBookingForAvailableSeat();

  const seatConfig = useSeatingSelector(seatConfigSelector);

  const init = useCallback(() => {
    dispatch(initOrderManagement(eventDateId));
  }, [dispatch, eventDateId]);

  useEffect(() => {
    if (!eventDateId) {
      throw new Error('No eventDateId provided');
    }

    init();
  }, [eventDateId, init]);

  const onClickSeat = useCallback(
    (
      seatEntity: AvailableSeatType,
      isSelected: boolean,
      isSelectable: boolean
    ): void => {
      if (!isSelectable) {
        return;
      }

      selectBookingForAvailableSeat(
        getLongId('/v1/event_dates/', eventDateId),
        seatEntity
      );
    },
    [eventDateId, selectBookingForAvailableSeat]
  );

  if (!isReady) {
    return <SeatingLoader />;
  }

  if (!seatConfig) {
    throw new Error(
      'SeatConfig should be set in store. Maybe you forgot to check that `app.loadStatus.seatConfigData` is set'
    );
  }

  assertRelationIsDefined(seatConfig.contract, 'seatConfig.contract');

  const contractId = getEntityId(seatConfig.contract);
  const eventDateLongId = getLongId('/v1/event_dates/', eventDateId);

  return (
    <div
      className="mpd-seating__app context-order-management"
      id="mpd-seating__app"
    >
      <div className="mpd-seating__app__container" ref={svgRef}>
        <BookingListProvider>
          <div className="mpd-seating__app__event-svg-wrapper">
            {displayEventDateInfo && (
              <EventDateCaptionWithFetch eventDateId={eventDateId} />
            )}

            <Seating
              SeatElement={OrderManagementSeat}
              seatEntityType="AvailableSeat"
              getSelectableSeatIdSet={getSelectableSeatIdSetForOrderManagement}
              onClickSeat={onClickSeat}
              seatConfigBlockList={seatConfig.seatConfigBlockList || []}
              isMovingSeat={false}
            />
          </div>

          <OrderManagementSidebar
            contractId={contractId}
            eventDateId={eventDateLongId}
            canMoveSeats={canMoveSeats}
          />
        </BookingListProvider>
      </div>
    </div>
  );
}

function useSelectBookingForAvailableSeat() {
  const dispatch = useSeatingDispatch();

  return (eventDateId: string, availableSeat: AvailableSeatType) => {
    if (!availableSeat.ticket && !availableSeat.cartItem) {
      throw new Error(
        'Try to focus a seat with a availableSeat but not booking or ticket'
      );
    }

    const seatId = getSeatIdForAvailableSeat(availableSeat);

    dispatch({ type: SET_BOOKING_STATUS, status: 'IN_PROGRESS' });

    dispatch(selectBatchOfSeats(Set([seatId])));

    const availableSeatMatchingSeatShortId = getShortId(availableSeat['@id']);

    return TicketingSdkInstance.getSdk()
      .getRepository('availableSeat')
      .find(availableSeatMatchingSeatShortId, ['order', 'cart'] as const)
      .then((innerAvailableSeat): Promise<void> => {
        if (innerAvailableSeat.get('order')) {
          const orderId = innerAvailableSeat.get('order');

          assertRelationIsString(orderId, 'availableSeat.order');

          return TicketingSdkInstance.getSdk()
            .getRepository('order')
            .find(getShortId(orderId), ORDER_FIELDS_FOR_BOOKING)
            .then((order) => {
              const orderOnEventDate = filterOrderOnEventDate(
                order,
                eventDateId
              );

              const orderBooking = new Booking({
                '@id': null,
                '@type': 'Booking',
                order: orderOnEventDate,
              });

              dispatch({ type: SET_BOOKING_STATUS, status: 'SUCCEEDED' });

              dispatch(focusBooking(orderBooking));
            });
        }

        if (innerAvailableSeat.get('cart')) {
          const cartId = innerAvailableSeat.get('cart');

          assertRelationIsString(cartId, 'availableSeat.cart');

          return TicketingSdkInstance.getSdk()
            .getRepository('cart')
            .find(cartId, CART_FIELDS_FOR_BOOKING)
            .then((cart) => {
              const cartOnEventDate = filterCartOnEventDate(cart, eventDateId);

              const cartBooking = new Booking({
                '@id': null,
                '@type': 'Booking',
                cart: cartOnEventDate,
              });

              dispatch({ type: SET_BOOKING_STATUS, status: 'SUCCEEDED' });

              dispatch(focusBooking(cartBooking));
            });
        }

        return Promise.resolve();
      });
  };
}

export default React.memo(OrderManagement);
