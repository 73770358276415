import React, {
  isValidElement,
  Children,
  useRef,
  useReducer,
  Reducer,
  ReactElement,
  useEffect,
} from 'react';
import cn from 'classnames';
import type { Props, Option, OptionProps, Value } from './types';
import MpdSelectContextProvider from './context';
import MpdIcon from '../MpdIcon';
import MpdSelectDropdown from './Dropdown';
import reducer, { Action, SELECT_ACTION, State } from './reducer';
import { KEY_CODES, matchKeyEvent } from '../../js-libs/utils';
import MpdSelectOption from './Option';

const MAX_DROPDOWN_HEIGHT = 260;

/** @deprecated Use `MpdSingleSelect` instead */
function MpdSelect({
  value,
  label,
  required = false,
  defaultLabel,
  placeholder,
  inline,
  disabled,
  onChange,
  className,
  flexible,
  children,
}: Props): JSX.Element {
  // Infinite flatten for children because it may be arrays of arrays (of arrays...)
  const flattenedChildren = [children]
    .flat(Infinity)
    .filter(
      (c): c is ReactElement<OptionProps> =>
        Boolean(c) && isValidElement<OptionProps>(c)
    );

  // Define an options list variable to know all options content
  // from children as usable object
  const optionList: Array<Option> = Children.map(
    flattenedChildren,
    (child) => ({
      label: child.props.children,
      value: child.props.value,
      disabled: child.props.disabled || false,
    })
  );

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    optionList,
    openedDropdown: false,
    focusedValue: value || optionList[0]?.value,
    isDropdownOnTop: false,
    dropdownAnimationDone: false,
    selectWidth: undefined,
  });

  const matchedOption = optionList.find((opt) => opt.value === value);

  if (
    value !== undefined &&
    value !== null &&
    value !== '' &&
    optionList.length > 0 &&
    !matchedOption &&
    !disabled
  ) {
    throw new Error(
      'The `value` props do not match with any value in your MpdSelect options. This should not happen.'
    );
  }

  /** @todo: Remove this variable when all components are using `placeholder` instead of `defaultLabel` */
  const defaultPlaceholder = defaultLabel || placeholder;

  const selectedValueRef = useRef<HTMLDivElement>(null);
  const selectedValue = matchedOption?.label || defaultPlaceholder;

  // Define if the dropdown is shown on top or bottom
  useEffect(() => {
    if (disabled || !selectedValueRef.current) {
      return;
    }

    const selectRect = selectedValueRef.current.getBoundingClientRect();
    const modal = document.querySelector('.base-modal__block--inner');

    if (modal) {
      const modalRect = modal.getBoundingClientRect();

      dispatch({
        type: SELECT_ACTION.SET_DROPDOWN_POSITION,
        isDropdownOnTop:
          modalRect.bottom < selectRect.bottom + MAX_DROPDOWN_HEIGHT,
      });

      return;
    }

    const updateWindowResize = () => {
      dispatch({
        type: SELECT_ACTION.SET_DROPDOWN_POSITION,
        isDropdownOnTop:
          window.innerHeight - selectRect.bottom < MAX_DROPDOWN_HEIGHT,
      });
    };

    updateWindowResize();
    window.addEventListener('resize', updateWindowResize);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', updateWindowResize);
    };
  }, [disabled]);

  const handleOpenToggle = () => {
    if (disabled) {
      return;
    }

    dispatch({
      type: SELECT_ACTION.TOGGLE_DROPDOWN,
    });
  };

  const handleChangeValue = (newValue: Value) => {
    // Set selected and focused value with selected value
    dispatch({ type: SELECT_ACTION.CHANGE_SELECTED_VALUE, value: newValue });

    // Trigger the onChange event with the new selected value
    onChange(newValue);

    // Force focus component
    selectedValueRef.current?.focus();
  };

  return (
    <MpdSelectContextProvider
      state={state}
      dispatch={dispatch}
      selectedValue={value}
      onChangeValue={handleChangeValue}
    >
      {label && (
        <span className="mpd-select__label">
          {label}
          {required && <span className="required" />}
        </span>
      )}
      <div
        className={cn(
          'mpd-select',
          {
            'mpd-select--open': state.openedDropdown,
            'mpd-select--inline': inline,
            'mpd-select--disabled': disabled,
            'mpd-select--flexible': flexible,
          },
          className
        )}
      >
        <div
          ref={selectedValueRef}
          className="mpd-select__value"
          role="button"
          tabIndex={0}
          onClick={handleOpenToggle}
          onKeyDown={(event) => {
            if (disabled) {
              return;
            }

            // Open dropdown when press UP / DOWN
            if (
              matchKeyEvent(event.nativeEvent, KEY_CODES.up) ||
              matchKeyEvent(event.nativeEvent, KEY_CODES.down)
            ) {
              event.preventDefault();

              dispatch({ type: SELECT_ACTION.OPEN_DROPDOWN });
            }
          }}
        >
          <div
            className={cn('mpd-select__selected_value', {
              'mpd-color-gray': defaultPlaceholder === selectedValue,
            })}
            style={{ width: flexible ? undefined : state.selectWidth }}
          >
            {selectedValue}
          </div>
          <MpdIcon
            icon={state.openedDropdown ? 'arrow-top' : 'arrow-down'}
            width="24"
            className="mpd-color-blue ml1"
          />
        </div>
        {!disabled && (
          <MpdSelectDropdown displayOnTop={state.isDropdownOnTop}>
            {children || (
              <MpdSelectOption value={value}>
                {value || defaultPlaceholder}
              </MpdSelectOption>
            )}
          </MpdSelectDropdown>
        )}
      </div>
    </MpdSelectContextProvider>
  );
}

export default MpdSelect;
