import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlusStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-522 -973)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="plus"
            d="M438.667 166.667v-4h2.666v4h4v2.666h-4v4h-2.666v-4h-4v-2.666zM440 184c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16m0-2.667c7.364 0 13.333-5.97 13.333-13.333s-5.97-13.333-13.333-13.333-13.333 5.97-13.333 13.333 5.97 13.333 13.333 13.333"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPlusStroke;
