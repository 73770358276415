import React, { PureComponent, ReactElement } from 'react';
import { CustomerType } from 'mapado-ticketing-js-sdk';
import cn from 'classnames';
import { DOMAIN_CONTEXT, useDomainContext } from '@mapado/js-component';
import { seatDimensions } from '../utils/seatDimensions';
import { getSvgPanZoomInstance } from '../utils/svgPanZoom';
import SeatInfoTooltip from './Tooltip/SeatInfoTooltip';
import { SeatType } from '../propTypes';
import '../styles/components/Tooltip.scss';

type State = {
  height: number;
  width: number;
  offsetLeft: number;
  offsetTop: number;
};

class SeatTooltip extends PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  svgElement: null | SVGSVGElement;

  _bodyRef: null | HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this._bodyRef = null;
    this.svgElement = null;

    this.setBodyRef = this.setBodyRef.bind(this);
    this.getOffset = this.getOffset.bind(this);

    this.state = {
      height: 0,
      width: 0,
      offsetLeft: 0,
      offsetTop: 0,
    };
  }

  componentDidMount(): void {
    this.svgElement = document.getElementById(
      'seatingSVG'
    ) as unknown as SVGSVGElement;

    const { left: offsetLeft, top: offsetTop } = this.getOffset();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      offsetLeft,
      offsetTop,
    });

    this.svgElement.children[0].addEventListener('zoom', () => {
      this.setFontSizeFromScale();
    });

    this.setFontSizeFromScale();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    let recomputeOffset = false;
    const nextState: Partial<State> = {}; // type-push-force. We should probably avoid this

    const dimensions = this.getUpdatedDimensions();

    if (dimensions !== null) {
      const { height, width } = dimensions;

      nextState.height = height;
      nextState.width = width;
    }

    const propsNotToCheck = ['mouseX', 'mouseY'];

    Object.keys(this.props).forEach((prop) => {
      if (propsNotToCheck.indexOf(prop) < 0) {
        /** @ts-expect-error -- weird prop comparison */
        // eslint-disable-next-line react/destructuring-assignment
        const nextPropVal = this.props[prop];

        /** @ts-expect-error -- weird prop comparison */
        const currentPropVal = prevProps[prop];

        if (nextPropVal !== currentPropVal) {
          recomputeOffset = true;
        }
      }
    });

    if (
      // eslint-disable-next-line react/destructuring-assignment
      prevState.height !== this.state.height ||
      // eslint-disable-next-line react/destructuring-assignment
      prevState.width !== this.state.width
    ) {
      recomputeOffset = true;
    }

    if (recomputeOffset) {
      const { left, top } = this.getOffset();

      nextState.offsetLeft = left;
      nextState.offsetTop = top;
    }

    if (Object.keys(nextState).length > 0) {
      // @ts-expect-error -- this is a valid state update
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(nextState);
    }
  }

  setBodyRef(ref: HTMLDivElement): void {
    if (ref !== this._bodyRef && ref) {
      this._bodyRef = ref;

      this.setState({
        height: ref.offsetHeight,
        width: ref.offsetWidth,
      });
    }
  }

  /**
   * We must compute the fontSize depending on the scale
   * because a flat value would scale alongside the svg
   * Same for the offsetLeft
   */
  setFontSizeFromScale(): void {
    if (this._bodyRef === null) {
      return;
    }

    const { scale } = getSvgPanZoomInstance().getTransform();

    this._bodyRef.style.fontSize = `${1 / scale}em`;
  }

  getUpdatedDimensions(): null | { height: number; width: number } {
    if (!this._bodyRef) {
      return null;
    }

    const { height, width } = this.state;
    const newHeight = this._bodyRef.offsetHeight;
    const newWidth = this._bodyRef.offsetWidth;

    if (newHeight !== height || newWidth !== width) {
      return {
        height: newHeight,
        width: newWidth,
      };
    }

    return null;
  }

  getOffset(): { left: number; top: number } {
    const { seat, mouseY, mouseX } = this.props;

    let offsetTop = 0;
    let offsetLeft = 0;

    if (this._bodyRef !== null && this.svgElement !== null) {
      const seatShortId = seat['@id'].replace('/v1/seats/', '');
      const seatElt = document.getElementById(seatShortId);

      const elementRealHeight = this._bodyRef.getBoundingClientRect().height;
      const elementRealWidth = this._bodyRef.getBoundingClientRect().width;

      const seatRealHeight = seatElt ? seatElt.clientHeight : 0;
      const seatRealWidth = seatElt ? seatElt.clientWidth : 0;

      // base position is ad the middle of the seat
      // so we have some space
      const tooltipSpacingTop = elementRealHeight + seatRealHeight;
      const tooltipSpacingLeft = elementRealWidth + seatRealWidth;

      const svgRect = this.svgElement.getBoundingClientRect();

      // mouse position is basically the only way i found to get the distance
      // between the seat and the svg container, because the seating map is
      // not aware of its container
      // it's not really precise, as we should check the bounds of the seats,
      // but it seems to mostly work

      const willOverflowTop = mouseY - tooltipSpacingTop < svgRect.top;
      const willOverflowBottom =
        mouseY + tooltipSpacingTop > svgRect.top + svgRect.height;
      const willOverflowLeft = mouseX - tooltipSpacingLeft < svgRect.left;
      const willOverflowRight =
        mouseX + tooltipSpacingLeft > svgRect.left + svgRect.width;

      const isTop = !willOverflowTop && !willOverflowLeft && !willOverflowRight;
      const isRight = willOverflowLeft;
      const isBottom = willOverflowTop && !willOverflowBottom;

      // We try to display the tooltip where there is enough space
      if (isTop) {
        offsetTop = this._bodyRef.offsetHeight + 10;
        offsetLeft = this._bodyRef.offsetWidth / 2;
      } else if (isBottom) {
        offsetTop = this._bodyRef.offsetHeight - seatDimensions.size - 85;
        offsetLeft = this._bodyRef.offsetWidth / 2;
      } else if (isRight) {
        offsetLeft -= seatDimensions.size;
        offsetTop = seatDimensions.size / 2;
      } else {
        offsetLeft += this._bodyRef.offsetWidth + seatDimensions.size;
        offsetTop = seatDimensions.size / 2;
      }

      if (!isTop && !isBottom) {
        if (willOverflowBottom) {
          offsetTop = this._bodyRef.offsetHeight + 20;
        } else if (willOverflowTop) {
          offsetTop = this._bodyRef.offsetHeight - seatDimensions.size;
        }
      }
    }

    return {
      left: offsetLeft,
      top: offsetTop,
    };
  }

  getPosX(): number {
    const { seat } = this.props;

    const x =
      typeof seat.info.X === 'number' ? seat.info.X : parseFloat(seat.info.X);

    return seatDimensions.size * x;
  }

  getPosY(): number {
    const { seat } = this.props;

    const y =
      typeof seat.info.Y === 'number' ? seat.info.Y : parseFloat(seat.info.Y);

    return -seatDimensions.size * y;
  }

  render(): ReactElement {
    const { seat, participant, isDesk } = this.props;
    const { height, width, offsetTop, offsetLeft } = this.state;

    if (seat === null) {
      return <g />;
    }

    const posX = this.getPosX() - offsetLeft;
    const posY = this.getPosY() - offsetTop;

    return (
      <g transform={`translate(${posX} ${posY - 5})`}>
        <foreignObject width={width} height={height}>
          <div
            // useless ? xmlns="http://www.w3.org/1999/xhtml"
            // See https://developer.mozilla.org/en-US/docs/Web/SVG/Element/foreignObject
            // In the context of SVG embedded in an HTML document, the XHTML
            // namespace could be omitted, but it is mandatory in the
            // context of an SVG document
            className={cn('mpd-seating__tooltip', {
              'mpd-seating__tooltip--is-desk': isDesk,
            })}
            ref={this.setBodyRef}
          >
            <SeatInfoTooltip
              seat={seat}
              participant={participant}
              isDesk={isDesk}
            />
          </div>
        </foreignObject>
      </g>
    );
  }
}

export type SeatTooltipProps = {
  mouseX: number;
  mouseY: number;
  // eslint-disable-next-line react/no-unused-prop-types
  seatId: number; // this is used only in the container
  participant: CustomerType | null;
};

export type StateProps = {
  seat: SeatType;
};

type ContainerProps = {
  isDesk: boolean;
};

type Props = SeatTooltipProps & StateProps & ContainerProps;

function SeatTooltipContainer(props: Omit<Props, keyof ContainerProps>) {
  const { currentDomain } = useDomainContext();
  const isDesk = currentDomain === DOMAIN_CONTEXT.DESK;

  return <SeatTooltip {...props} isDesk={isDesk} />;
}

export default SeatTooltipContainer;
