import { Fields } from 'mapado-ticketing-js-sdk';

export const SEAT_FIELDS = [
  'position',
  'info',
  'bookable',
  'visible',
  'angle',
  'type',
  'seatGroup',
  'hasObstructedView',
];

export const CONTINGENT_FIELDS = ['@id', 'name', 'color'];
export const STOCK_CONTINGENT_FIELDS = ['@id', 'name', 'color'];
export const SEAT_GROUP_FIELDS = ['label', 'seatColor'];

const AVAILABLE_SEAT_FIELDS_COMMON = [
  'eventDate',
  'status',
  'bookingStatus',
  'visible',
  'bookable',
  'seatGroup',
  'cartItem',
  'ticket',
];

export const AVAILABLE_SEAT_FIELDS: Fields = [
  ...AVAILABLE_SEAT_FIELDS_COMMON,
  { stockContingent: [...STOCK_CONTINGENT_FIELDS] },
  { seatGroup: [...SEAT_GROUP_FIELDS] },
  { seat: SEAT_FIELDS },
];

export const AVAILABLE_SEAT_FIELDS_LITE: Fields = [
  ...AVAILABLE_SEAT_FIELDS_COMMON,
  'stockContingent',
  'seatGroup',
  'seat',
];

const AVAILABLE_SEAT_MODEL_FIELDS_COMMON = ['status'];

export const AVAILABLE_SEAT_MODEL_FIELDS_LITE: Fields = [
  ...AVAILABLE_SEAT_MODEL_FIELDS_COMMON,
  'contingent',
  'seatGroup',
  'seat',
];

export const SEAT_CONFIG_FIELDS: Fields = [
  '@id',
  'label',
  'contract',
  {
    seatConfigBlockList: ['info', 'x', 'y', 'z', 'width', 'height', 'fontSize'],
  },
];

export const LOGICAL_SEAT_CONFIG_FIELDS: Fields = [
  'name',
  'contract',
  {
    seatConfig: SEAT_CONFIG_FIELDS,
  },
];

export const EVENT_DATE_DATA_DEFAULT_FIELDS: Fields = [
  'startDate',
  'endDate',
  'seatConfig',
  'logicalSeatConfig',
  'notInStockContingentBookableStock',
  'nbActiveSeats',
  'issuedTickets',
  'bookedTickets',
  {
    stockContingentList: ['totalStock', 'bookableStock', 'dismissedStock'],
  },
  {
    ticketing: ['timezone', 'title', { wallet: ['contract'] }],
  },
  {
    ticketPriceList: ['type', 'name'],
  },
];
