import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class SubscriptionMaturityClient extends AbstractClient<
  TSMetadata['subscriptionMaturity']
> {
  getPathBase(): string {
    return '/v1/subscription_maturities';
  }
}

export default SubscriptionMaturityClient;
