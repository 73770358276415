import React, { ReactElement } from 'react';
import { MpdLoader } from '@mapado/makeup';
import { BaseModal } from '@mapado/js-component';
import { ContingentEntity } from '../../propTypes';
import { useTranslation } from '../../i18n';

function DeleteContingentEntityModal({
  isDeleting,
  onCancel,
  onConfirm,
  contingentEntity,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <BaseModal
      zIndexDelta={20}
      actions={
        <div>
          {isDeleting && (
            <div className="txtcenter m4">
              <MpdLoader />
            </div>
          )}
          {!isDeleting && (
            <>
              <button
                type="button"
                className="mpd-btn mpd-btn--secondary"
                onClick={onCancel}
              >
                {t('cancel')}
              </button>

              <button
                type="button"
                className="mpd-btn mpd-btn--primary"
                onClick={(): void => onConfirm(contingentEntity)}
              >
                {t('confirm')}
              </button>
            </>
          )}
        </div>
      }
      onCancel={onCancel}
      title={t('modal.delete_stock_contingent.title')}
    >
      <div
        className="txtcenter"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('modal.delete_stock_contingent.text'),
        }}
      />
    </BaseModal>
  );
}

type Props = {
  isDeleting: boolean;
  onCancel: () => void;
  onConfirm: (contingentEntity: ContingentEntity) => void;
  contingentEntity: ContingentEntity;
};

export default DeleteContingentEntityModal;
