import React, {
  PureComponent,
  RefObject,
  ReactElement,
  ChangeEvent,
  MouseEvent,
} from 'react';
import cn from 'classnames';
import MpdIcon from './MpdIcon';

type State = {
  activeInput: boolean;
  inputQuantity: string | number;
};

type DefaultProps = {
  disabled: boolean;
  selectMode: boolean;
  quantity: number;
};

type Props = DefaultProps & {
  name: string;
  remainingStock?: number;
  remainingStockLabel?: string;
  price: string;
  onChange: (n: number) => void;
};

class MpdTicket extends PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    disabled: false,
    selectMode: false,
    quantity: 0,
  };

  #inputRef: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      activeInput: false,
      inputQuantity: '',
    };

    this.#inputRef = React.createRef();

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickTicket = this.onClickTicket.bind(this);
    this.onClickInput = this.onClickInput.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.addKeyboardListener = this.addKeyboardListener.bind(this);
    this.removeKeyboardListener = this.removeKeyboardListener.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.increaseSelection = this.increaseSelection.bind(this);
    this.decreaseSelection = this.decreaseSelection.bind(this);
  }

  onChangeInput(event: ChangeEvent<HTMLInputElement>): void {
    const { inputQuantity } = this.state;
    const { target } = event;

    if (!target.value.length) {
      this.setState({ inputQuantity: '' });

      return;
    }

    const value = target.validity.valid ? Number(target.value) : inputQuantity;

    this.setState({
      inputQuantity: value,
    });
  }

  onClickInput(event: MouseEvent<HTMLInputElement>): void {
    event.stopPropagation();
    event.currentTarget.select();
  }

  onFocusInput(): void {
    const { quantity } = this.props;

    this.setState({ activeInput: true, inputQuantity: quantity });
  }

  onBlurInput(): void {
    this.setState({ activeInput: false, inputQuantity: '' });

    this.props.onChange(Number(this.state.inputQuantity));
  }

  onClickTicket(): void {
    const {
      selectMode,
      onChange,
      quantity,
      disabled,
      remainingStock,
    } = this.props;

    if (selectMode) {
      onChange(quantity ? 0 : 1);

      return;
    }

    if (disabled) {
      onChange(quantity - 1);

      return;
    }

    if (remainingStock === 0) {
      return;
    }

    onChange(quantity + 1);
  }

  addKeyboardListener(): void {
    const { selectMode } = this.props;

    if (selectMode) {
      return;
    }

    document.addEventListener('keydown', this.handleKeyDown);
  }

  removeKeyboardListener(): void {
    const { selectMode } = this.props;

    if (selectMode) {
      return;
    }

    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent): void {
    const { activeInput } = this.state;
    const key = event.keyCode;

    if (activeInput) {
      // enter keyboard
      if (key === 13) {
        this.#inputRef.current?.blur();
      }

      return;
    }

    if (key < 48 || key > 57) {
      return;
    }

    this.#inputRef.current?.select();
  }

  increaseSelection(event: MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();

    const { onChange, quantity } = this.props;

    onChange(quantity + 1);
  }

  decreaseSelection(event: MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();

    const { onChange, quantity } = this.props;

    onChange(quantity - 1);
  }

  renderQuantity(): ReactElement | null {
    const { selectMode, quantity } = this.props;
    const { inputQuantity, activeInput } = this.state;

    if (quantity === 0 && !activeInput) {
      return null;
    }

    if (selectMode) {
      return (
        <div className="mpd-ticket__aside mpd-ticket__aside--select-mode">
          <MpdIcon
            icon="valid"
            height="24"
            width="24"
            className="mpd-color-white"
          />
        </div>
      );
    }

    return (
      <div className="mpd-ticket__aside">
        <button type="button" onClick={this.increaseSelection}>
          +
        </button>
        <input
          ref={this.#inputRef}
          type="text"
          value={activeInput ? inputQuantity : quantity}
          onChange={this.onChangeInput}
          onClick={this.onClickInput}
          onFocus={this.onFocusInput}
          onBlur={this.onBlurInput}
          pattern="[0-9]*"
        />
        <button type="button" onClick={this.decreaseSelection}>
          -
        </button>
      </div>
    );
  }

  render(): ReactElement {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- do not pass this prop to the <div>
      selectMode,
      name,
      remainingStock,
      remainingStockLabel,
      price,
      disabled,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- do not pass this prop to the <div>
      onChange,
      quantity,
      ...rest
    } = this.props;
    const { activeInput } = this.state;

    const ticketClasses = cn('mpd-ticket', {
      'mpd-ticket--selected': activeInput || quantity > 0,
      'mpd-ticket--disabled': disabled || remainingStock === 0,
    });

    const remainingClasses = cn('remaining', {
      'remaining--empty': remainingStock === 0,
    });

    return (
      <div
        onClick={this.onClickTicket}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onKeyPress={() => {}}
        onMouseEnter={this.addKeyboardListener}
        onMouseLeave={this.removeKeyboardListener}
        className={ticketClasses}
        role="button"
        tabIndex={0}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {this.renderQuantity()}
        <div className="mpd-ticket__content">
          <div>
            <span className="h3-like name">{name}</span>
            {remainingStock !== null && (
              <div className={remainingClasses}>
                {remainingStockLabel ||
                  `${remainingStock} place(s) restante(s)`}
              </div>
            )}
          </div>
          {price && <span className="price">{price}</span>}
        </div>
      </div>
    );
  }
}

export default MpdTicket;
