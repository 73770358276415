import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import CustomerFieldContract from './CustomerFieldContract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum FORM_TYPE {
  CUSTOMER = 'customer',
  PARTICIPANT = 'participant',
}

export type FormType = BaseEntity<'Form'> & {
  customerFieldContractList:
    | List<EntityRelation<CustomerFieldContract>>
    | undefined
    | null;
  type: FORM_TYPE | undefined | null;
};

class Form extends NetworkEntity<FormType>({
  '@id': null,
  '@type': 'Form',
  customerFieldContractList: undefined,
  type: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<FormType> = {
      '@id': null,
      '@type': 'Form',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }
}

Form.classMetadata = new ClassMetadata(
  'form',
  'forms',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Form.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
]);
Form.classMetadata.setRelationList([
  new Relation(
    Relation.ONE_TO_MANY,
    'customerFieldContract',
    'customerFieldContractList'
  ),
]);

export default Form;
