import clone from 'clone';
import type { List, Map } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import UserClient from '../client/UserClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type UserType = BaseEntity<'User'> & {
  firstname: null | string;
  lastname: null | string;
  fullName: null | string;
  email: null | string;
  avatar: null | string;
  address: null | string;
  phoneNumber: null | string;
  phoneNumberAsObject: null | Record<string, unknown>;
  city: null | string;
  impersonated: null | boolean;
  contractUserList: null | List<Map<string, unknown>>;
  isMapadoTeam: null | boolean;
};

class User extends NetworkEntity<UserType>({
  '@id': null,
  '@type': 'User',
  firstname: null,
  lastname: null,
  fullName: null,
  email: null,
  avatar: null,
  address: null,
  phoneNumber: null,
  phoneNumberAsObject: null,
  city: null,
  impersonated: null,
  contractUserList: null,
  isMapadoTeam: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<UserType>) {
    const data = clone(val);
    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/users/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
User.classMetadata = new ClassMetadata('user', 'users', UserClient);
User.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('firstname'),
  new Attribute('lastname'),
  new Attribute('fullName'),
  new Attribute('email'),
  new Attribute('avatar'),
  new Attribute('address'),
  new Attribute('phoneNumber'),
  new Attribute('phoneNumberAsObject', 'phoneNumberAsObject', 'object'),
  new Attribute('city'),
  new Attribute('impersonated', 'impersonated', 'boolean'),
  new Attribute('contractUserList', 'contractUserList', 'array'),
]);

export default User;
