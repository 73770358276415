import clone from 'clone';
import { List, Map } from 'immutable';
import { createEntity } from '../entityFactory';
import type { AllowedFactoryTypes, Entity } from '../entityFactory/types';
import Cart, { CartType } from './Cart';
import Contingent from './Contingent';
import { EVENT_DATE_OPTION_STATUS } from './EventDateOptionStatus';
import PagedCollection, { PagedCollectionInputType } from './PagedCollection';
import Ticketing from './Ticketing';

type PagedCartCollectionInputType = Omit<
  PagedCollectionInputType<CartType>,
  'mapado:filters'
> & {
  'mapado:filters': null | Record<string, Array<AllowedFactoryTypes | string>>;
};

class PagedCartCollection extends PagedCollection<Cart> {
  constructor(val: PagedCartCollectionInputType) {
    const data = clone(val);

    let filters: Map<string, List<Entity | string>> | null = null;

    if (data['mapado:filters']) {
      const entries = Object.entries(data['mapado:filters']);

      filters = Map(
        entries.map(([key, list]) => [
          key,
          List(
            list.map((filter) =>
              typeof filter === 'string'
                ? filter
                : (createEntity(filter) as Entity)
            )
          ),
        ])
      );
    }

    const parameters: PagedCollectionInputType<CartType> = {
      ...data,
      'mapado:filters': filters,
    };

    super(parameters);
  }

  getFilters() {
    return this['mapado:filters'];
  }

  getFilter(type: 'ticketing'): List<Ticketing> | undefined;

  getFilter(type: 'contingent'): List<Contingent> | undefined;

  getFilter(type: 'status'): List<EVENT_DATE_OPTION_STATUS> | undefined;

  getFilter(type: string) {
    return this['mapado:filters']?.get(type);
  }
}

export default PagedCartCollection;
