/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React, { useRef, ReactElement, MouseEvent } from 'react';
import cn from 'classnames';

export enum OFFER_COLOR {
  PINK = 'pink',
  BLUE = 'blue',
  BLUE_DARK = 'blue-dark',
  GREY = 'grey',
  YELLOW = 'yellow',
  BROWN = 'brown',
  CYAN = 'cyan',
  ORANGE = 'orange',
  PURPLE = 'purple',
  PURPLE_DARK = 'purple-dark',
  PURPLE_LIGHT = 'purple-light',
  PURPLE_SECONDARY = 'purple-secondary',
  GREEN = 'green',
  GREEN_DARK = 'green-dark',
}

type CheckboxableProps = {
  checked: boolean;
  onChange: (event: MouseEvent<HTMLInputElement>) => void;
};

type NonCheckboxableProps = {
  checked: undefined;
  onChange: undefined;
};

type Props = (CheckboxableProps | NonCheckboxableProps) & {
  color?: OFFER_COLOR;
  title: string;
  description: string;
  className?: string;
  disabled?: boolean;
};

function MpdOffer({
  color = OFFER_COLOR.PINK,
  title,
  description,
  className,
  checked,
  disabled = false,
  onChange,
  ...rest
}: Props): ReactElement {
  const checkboxMode = checked !== undefined;
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={cn(
        'mpd-offer',
        `mpd-offer--${color}`,
        {
          'mpd-offer--checkbox': checkboxMode,
          'mpd-offer--disabled': disabled,
        },
        className
      )}
      aria-hidden="true"
      onClick={() => {
        if (disabled) {
          return;
        }

        if (!checkboxMode) {
          return;
        }

        const event = new window.MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        checkboxRef.current?.dispatchEvent(event);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {checkboxMode && (
        <div className="checkbox">
          <div className="mpd-checkbox">
            <input
              ref={checkboxRef}
              type="checkbox"
              checked={checked}
              onClick={(event) => {
                if (disabled) {
                  return;
                }

                event.stopPropagation();

                if (onChange) {
                  onChange(event);
                }
              }}
            />
            <label />
          </div>
        </div>
      )}
      <div className="mpd-offer__container">
        <span className="title">{title}</span>
        <span className="description small">{description}</span>
      </div>
    </div>
  );
}

export default MpdOffer;
