/* eslint-disable max-classes-per-file,class-methods-use-this */
import TicketingSdk from 'mapado-ticketing-js-sdk';
import {
  Serializer,
  ClassMetadata,
  Token,
  Config,
  TokenStorageInterface,
} from 'rest-client-sdk';
import { Map, List } from 'immutable';

class SeatingEntitySerializer extends Serializer {
  encodeItem(object: Record<string, unknown> /* , classMetadata */): string {
    return JSON.stringify(object);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  decodeItem(rawData: string /* , classMetadata, response */): Map<any, any> {
    return Map(JSON.parse(rawData));
  }

  decodeList(
    rawListData: string /* , classMetadata, response */
  ): Iterable<Record<string, unknown>> {
    const parsedList = JSON.parse(rawListData);

    if (Array.isArray(parsedList)) {
      return List(parsedList);
    }

    /** @ts-expect-error -- possibly an issue as decodeList should return a List */
    return Map(parsedList);
  }

  denormalizeItem<E>(
    object: Record<string, unknown>,
    classMetadata: ClassMetadata,
    response: Response
  ): E {
    // do stuff with your item input
    if (object && typeof object === 'object') {
      // eslint-disable-next-line no-param-reassign
      object.responseDate =
        response &&
        response.headers &&
        typeof response.headers.get === 'function'
          ? response.headers.get('date')
          : null;
    }

    return object as E;
  }
}
/* eslint-disable @typescript-eslint/ban-types */

/**
 * On some endpoints, we want to bypass the default serialization, which uses
 * ImmutableJS records, because on the big seating plans with lots of seats,
 * the Immutable serialization can takes up to several seconds.
 */
function configureSdk(
  tokenStorage: TokenStorageInterface<Token>,
  sdkConfig: Config
): TicketingSdk {
  const sdk = new TicketingSdk(tokenStorage, sdkConfig);

  const rawSerializer = new SeatingEntitySerializer();

  sdk.getRepository('eventDate').serializer = rawSerializer;
  sdk.getRepository('seatConfig').serializer = rawSerializer;
  sdk.getRepository('logicalSeatConfig').serializer = rawSerializer;
  sdk.getRepository('availableSeat').serializer = rawSerializer;
  sdk.getRepository('availableSeatModel').serializer = rawSerializer;
  sdk.getRepository('seatGroup').serializer = rawSerializer;
  sdk.getRepository('seat').serializer = rawSerializer;

  return sdk;
}

class TicketingSdkInstance {
  private static sdk: TicketingSdk;

  private static defaultSerializerSdk: TicketingSdk;

  static createTicketingSdk(
    tokenStorage: TokenStorageInterface<Token>,
    sdkConfig: Config
  ): void {
    TicketingSdkInstance.sdk = configureSdk(tokenStorage, sdkConfig);
    TicketingSdkInstance.defaultSerializerSdk = new TicketingSdk(tokenStorage, {
      ...sdkConfig,
      unitOfWorkEnabled: true,
    });
  }

  static getSdk(): TicketingSdk {
    if (!TicketingSdkInstance.sdk) {
      throw new Error('sdk instance must be initialised');
    }

    return TicketingSdkInstance.sdk;
  }

  static getDefaultSerializerSdk(): TicketingSdk {
    if (!TicketingSdkInstance.defaultSerializerSdk) {
      throw new Error('defaultSerializerSdk instance must be initialised');
    }

    return TicketingSdkInstance.defaultSerializerSdk;
  }
}

export default TicketingSdkInstance;
