import {
  getCustomerFullname,
  getShortId,
  useDomainContext,
} from '@mapado/js-component';
import { Cart, Customer, Order } from 'mapado-ticketing-js-sdk';
import React, { ReactElement } from 'react';
import { ShouldNotHappenError } from '../../../errors';
import { isCart } from '../../../utils/booking';

type CustomerLineProps = {
  entity: Cart | Order;
  participant?: Customer | null;
};

function CustomerLine({
  entity,
  participant,
}: CustomerLineProps): ReactElement | null {
  if (typeof entity.customer === 'string') {
    throw new ShouldNotHappenError(
      'entity.customer must be either null or an object'
    );
  }

  const { deskDomain } = useDomainContext();
  const customerId = participant ? participant.crmId : entity.customer?.crmId;

  const fullname = getCustomerFullname(participant || entity.customer);
  const hasCustomerInformation = !!fullname;
  const name = isCart(entity) ? entity.reservationName : null;

  let headerContent: string | ReactElement | null = null;

  if (hasCustomerInformation) {
    headerContent = (
      <h3 className="mpd-seating-booking-tile__customer">
        {customerId ? (
          <a
            className="mpd-seating-booking-tile__customer--clickable mpd-color-black"
            href={`${deskDomain}/customers/${getShortId(customerId)}`}
          >
            {fullname}
          </a>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{fullname}</>
        )}
      </h3>
    );
  } else if (name) {
    headerContent = name;
  }

  if (!headerContent) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{headerContent}</>;
}

export default CustomerLine;
