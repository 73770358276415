import { List, Set } from 'immutable';
import {
  assertRelationIsObject,
  AvailableSeatModelStatus,
  AVAILABLE_SEAT_STATUS_AVAILABLE,
  AVAILABLE_SEAT_STATUS_DISMISSED,
  Contingent,
} from 'mapado-ticketing-js-sdk';
import React, { useEffect, useCallback } from 'react';
import Seating from '../Seating';
import SeatingLoader from '../SeatingLoader';
import DrawButton from '../Toolbar/DrawButton';
import { AvailableSeatModelType, LogicalSeatConfigType } from '../../propTypes';
import { required } from '../../utils';
import LogicalSeatConfigAdminSeat from '../Seat/LogicalSeatConfigAdminSeat';
import { getSelectableSeatIdSetForContingentPane } from '../../utils/seatSelectable';
import { SelectBatchOfSeats } from '../../utils/drawers/FreeHandDrawSeatSelector';
import ContingentSidebarContent from '../../containers/Sidebar/ContingentSidebarContentContainer';
import SeatGroupSidebarContent from '../../containers/Sidebar/SeatGroupSidebarContentContainer';
import TabbedSidebar from '../Sidebar/TabbedSidebar';
import { useDrawingContext } from '../../contexts/DrawingContext';
import { useLogicalSeatConfigAdminContext } from '../../contexts/LogicalSeatConfigAdminContext';
import ContingentSidebarActions from '../Sidebar/LogicalSeatConfigAdminSidebar/ContingentPane/ContingentSidebarActions';
import { RootState } from '../../reducers';
import SeatGroupSidebarActions from '../Sidebar/LogicalSeatConfigAdminSidebar/SeatGroupPane/SeatGroupSidebarActions';
import withLogicalSeatConfigAdminContextProvider from '../hoc/withLogicalSeatConfigAdminContextProvider';
import { getSeatIdForAvailableSeatModel } from '../../utils/entity';
import GaugeSidebarContent from '../Sidebar/LogicalSeatConfigAdminSidebar/GaugePane/GaugeSidebarContent';
import GaugeSidebarActions from '../Sidebar/LogicalSeatConfigAdminSidebar/GaugePane/GaugeSidebarActions';
import { STOCK_MANAGEMENT_TABS } from '../../contexts/StockManagementContext';
import {
  addSeatToSelectedSeats,
  removeSeatFromSelectedSeats,
} from '../../actions/SeatActions';
import { useSeatingDispatch } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';

function LogicalSeatConfigAdmin({
  isReady,
  logicalSeatConfig,
  logicalSeatConfigId,
  initLogicalSeatConfigAdmin,
  selectBatchOfSeats,
  updateAvailableSeatModelListStatus,
  contingentList,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();
  const {
    selectedContingent,
    handleChangePane,
    currentPane,
    displayDrawButton,
  } = useLogicalSeatConfigAdminContext();
  const { setIsDrawing, setSvgDrawerRef } = useDrawingContext();

  // startup
  useEffect(() => {
    initLogicalSeatConfigAdmin(logicalSeatConfigId);
  }, [logicalSeatConfigId, initLogicalSeatConfigAdmin]);

  useEffect(() => {
    setIsDrawing(false);
    selectBatchOfSeats(Set());
  }, [currentPane, setIsDrawing, selectBatchOfSeats]);

  const normalizedGetSelectableSeatIdSet = useCallback(
    (state: RootState, seatIdSet: Set<string>) => {
      if (
        currentPane === STOCK_MANAGEMENT_TABS.CONTINGENT &&
        contingentList !== null &&
        selectedContingent !== null
      ) {
        return getSelectableSeatIdSetForContingentPane(
          state,
          seatIdSet,
          selectedContingent
        );
      }

      return seatIdSet;
    },
    [currentPane, contingentList, selectedContingent]
  );

  const disableIsDrawing = useCallback(() => {
    setIsDrawing(false);
  }, [setIsDrawing]);

  const updateAvailableSeats = useCallback(
    async (seat: AvailableSeatModelType) => {
      await updateAvailableSeatModelListStatus(
        seat.status === AVAILABLE_SEAT_STATUS_AVAILABLE
          ? AVAILABLE_SEAT_STATUS_DISMISSED
          : AVAILABLE_SEAT_STATUS_AVAILABLE
      );
      selectBatchOfSeats(Set());
    },
    [selectBatchOfSeats, updateAvailableSeatModelListStatus]
  );

  const onClickSeat = useCallback(
    (
      seatElement: AvailableSeatModelType,
      isSelected: boolean,
      isSelectable: boolean
    ): void => {
      if (!isSelectable) {
        return;
      }

      if (currentPane === STOCK_MANAGEMENT_TABS.GAUGE) {
        // select those seats to mark them visually: notify the user that the click did work
        selectBatchOfSeats(Set([getSeatIdForAvailableSeatModel(seatElement)]));
        updateAvailableSeats(seatElement);
      } else {
        const seatId = getSeatIdForAvailableSeatModel(seatElement);

        if (isSelected) {
          dispatch(removeSeatFromSelectedSeats(seatId));
        } else {
          dispatch(addSeatToSelectedSeats(seatId));
        }
      }
    },
    [currentPane, dispatch, selectBatchOfSeats, updateAvailableSeats]
  );

  if (!isReady || !logicalSeatConfig) {
    return <SeatingLoader />;
  }

  assertRelationIsObject(
    logicalSeatConfig.seatConfig,
    'logicalSeatConfig.seatConfig'
  );

  const { seatConfig } = logicalSeatConfig;

  return (
    <div
      className="mpd-seating__app context-mpd-seating-stock-contingent-seat-selector"
      id="mpd-seating__app"
    >
      <div className="mpd-seating__app__container" ref={setSvgDrawerRef}>
        <div className="mpd-seating__app__event-svg-wrapper">
          <Seating
            seatEntityType="AvailableSeatModel"
            getSelectableSeatIdSet={normalizedGetSelectableSeatIdSet}
            seatConfigBlockList={required(seatConfig).seatConfigBlockList}
            isMovingSeat={false}
            onClickSeat={onClickSeat}
            SeatElement={LogicalSeatConfigAdminSeat}
            actionButtons={displayDrawButton && <DrawButton />}
          />
        </div>

        <TabbedSidebar
          onChangeTab={handleChangePane}
          tabs={[
            {
              name: STOCK_MANAGEMENT_TABS.CONTINGENT,
              label: t('seating.sidebar_tab.contingent.title'),
              element: (
                <ContingentSidebarContent
                  onUnSelectedContingent={disableIsDrawing}
                  onSelectedIdListChange={disableIsDrawing}
                />
              ),
              sidebarAction: (
                <ContingentSidebarActions
                  contractId={logicalSeatConfig.contract}
                />
              ),
            },
            {
              name: STOCK_MANAGEMENT_TABS.SEAT_GROUP,
              label: t('seating.sidebar_tab.seat_group.title'),
              element: (
                <SeatGroupSidebarContent
                  onUnselectSeatGroup={disableIsDrawing}
                  onSelectedIdListChange={disableIsDrawing}
                />
              ),
              sidebarAction: (
                <SeatGroupSidebarActions
                  contractId={logicalSeatConfig.contract}
                />
              ),
            },
            {
              name: STOCK_MANAGEMENT_TABS.GAUGE,
              label: t('seating.sidebar_tab.gauge.title'),
              element: <GaugeSidebarContent />,
              sidebarAction: (
                <GaugeSidebarActions onAction={disableIsDrawing} />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export type LogicalSeatConfigAdminProps = {
  logicalSeatConfigId: number;
};

export type StateProps = {
  logicalSeatConfig: null | LogicalSeatConfigType;
  isReady: boolean;
  contingentList: List<Contingent> | null;
};

export type DispatchProps = {
  initLogicalSeatConfigAdmin: (
    logicalSeatConfigId: null | string | number
  ) => void;
  selectBatchOfSeats: SelectBatchOfSeats;
  updateAvailableSeatModelListStatus: (
    status: AvailableSeatModelStatus
  ) => void;
};

type Props = LogicalSeatConfigAdminProps & StateProps & DispatchProps;

export default withLogicalSeatConfigAdminContextProvider(
  LogicalSeatConfigAdmin
);
