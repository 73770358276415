import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  eventDateId: number;
}

export default function EventDateSelector({ eventDateId }: Props) {
  const [newId, setNewId] = React.useState(eventDateId);
  const location = useLocation();
  const history = useHistory();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        if (newId) {
          const searchParams = new URLSearchParams(location.search);

          searchParams.set('eventDateId', newId.toString());

          history.push({
            search: searchParams.toString(),
          });
        }
      }}
    >
      <div className="colums">
        <div className="column is-4 txtright">Id eventDate</div>
        <div className="column is-6">
          <input
            className="form-control"
            value={newId.toString()}
            onChange={(e) => setNewId(Number(e.target.value))}
          />
        </div>

        <div className="column is-2 txtright">
          <button type="submit" className="mpd-btn mpd-btn--primary">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
