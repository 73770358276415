import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import ScanClient from '../client/ScanClient';
import { createEntity } from '../entityFactory';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Checkpoint from './Checkpoint';
import NetworkEntity from './NetworkEntity';
import Ticket from './Ticket';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ScanType = BaseEntity<'Scan'> & {
  ticket: null | EntityRelation<Ticket> | string;
  scanDate: null | Moment;
  scannedBy: null | string;
  deviceId: null | string;
  deviceInfo: null | string;
  appModel: null | string;
  appVersion: null | string;
  status: null | string;
  eventDate: null;
  checkpoint: null | EntityRelation<Checkpoint>;
};

class Scan extends NetworkEntity<ScanType>({
  '@id': null,
  '@type': 'Scan',
  ticket: new Ticket(),
  scanDate: null,
  scannedBy: null,
  deviceId: null,
  deviceInfo: null,
  appModel: null,
  appVersion: null,
  status: null,
  eventDate: null,
  checkpoint: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<ScanType> = { '@id': null, '@type': 'Scan' }) {
    const data = clone(val);

    const tz = extractTimezone(data, ['ticket', 'ticketingTimezone']);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- ticket is not converted now */
    data.ticket = data.ticket && createEntity(data.ticket);
    data.scanDate = parseDate(data.scanDate, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Scan.classMetadata = new ClassMetadata('scan', 'scans', ScanClient);
Scan.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('scanDate', 'scanDate', 'datetime'),
  new Attribute('scannedBy'),
  new Attribute('deviceId'),
  new Attribute('deviceInfo'),
  new Attribute('appModel'),
  new Attribute('appVersion'),
  new Attribute('status'),
]);
Scan.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'ticket', 'ticket'),
  new Relation(Relation.MANY_TO_ONE, 'checkpoint', 'checkpoint'),
]);

export default Scan;
