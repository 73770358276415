import clone from 'clone';
import { Record } from 'immutable';
import OptionStress from './OptionStress';

export type EventDateOptionStressType = {
  eventDateId: null | string;
  optionStress: null | OptionStress;
};

class EventDateOptionStress extends Record<EventDateOptionStressType>({
  eventDateId: null,
  optionStress: null,
}) {
  constructor(val: EventDateOptionStressType) {
    const data = clone(val);

    if (val.optionStress) {
      data.optionStress = new OptionStress(val.optionStress);
    }

    super(data);
  }
}

export default EventDateOptionStress;
