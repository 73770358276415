import { AbstractClient as BaseClient } from 'rest-client-sdk';
import { TSMetadata } from '../../mapping';

class SibilParameterClient extends BaseClient<TSMetadata['sibilParameter']> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPathBase(pathParameters: Record<string, unknown>): string {
    return '/v1/module/sibil/parameters';
  }
}

export default SibilParameterClient;
