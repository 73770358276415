import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 17" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/mail" fillRule="nonzero" transform="translate(-3 -6)">
        <path
          id="Combined-Shape"
          d="M27 6v17H3V6zM5 9.294V21h20V9.416l-9.925 8.175zM23.575 8h-17l8.5 7z"
        />
      </g>
    </g>
  </svg>
);
export default SvgMail;
