import React, {
  ReactNode,
  createContext,
  useContext,
  ReactElement,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  StockContingent,
  Fields,
  assertRelationIsString,
} from 'mapado-ticketing-js-sdk';
import { getShortId } from '@mapado/js-component';
import { eventDateSelector } from '../utils/selectors';
import { useSelectedSeatGroupContext } from './SelectedSeatGroupContext';

type Context = {
  selectedTab: STOCK_MANAGEMENT_TABS;
  selectedStockContingent: null | StockContingent;
  selectStockContingent: (value: StockContingent) => void;
  unselectStockContingent: () => void;
  selectTab: (value: STOCK_MANAGEMENT_TABS) => void;
  eventDateId: number;
  contractId: string;
};

export enum STOCK_MANAGEMENT_TABS {
  CONTINGENT = 'CONTINGENT',
  SEAT_GROUP = 'SEAT_GROUP',
  GAUGE = 'GAUGE',
}

export const STOCK_CONTINGENT_FIELDS: Fields = [
  'totalStock',
  'bookableStock',
  'bookedTickets',
  'availableSeatList',
  'seatList',
  'draft',
  { contingent: ['@id', 'name', 'color'] },
];

const StockManagementContext = createContext<Context | null>(null);

export const useStockManagement = (): Context => {
  const context = useContext(StockManagementContext);

  if (context === null) {
    throw new Error(
      'useStockManagementContext must be used within a StockManagementProvider'
    );
  }

  return context;
};

export function StockManagementProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const eventDate = useSelector(eventDateSelector);

  const [selectedTab, setSelectedTab] = useState<STOCK_MANAGEMENT_TABS>(
    STOCK_MANAGEMENT_TABS.CONTINGENT
  );
  const [selectedStockContingent, setSelectedStockContingent] =
    useState<null | StockContingent>(null);
  const { unselectSeatGroup } = useSelectedSeatGroupContext();

  const selectStockContingent = (stockContingent: StockContingent) => {
    setSelectedStockContingent(stockContingent);
  };

  const unselectStockContingent = () => {
    setSelectedStockContingent(null);
  };

  const selectTab = useCallback(
    (currentTab: STOCK_MANAGEMENT_TABS) => {
      setSelectedTab(currentTab);
      setSelectedStockContingent(null);
      unselectSeatGroup();
    },
    [unselectSeatGroup]
  );

  if (!eventDate || !eventDate['@id']) {
    throw new Error('EventDate should exist');
  }

  const eventDateId = Number(getShortId(eventDate['@id']));

  assertRelationIsString(
    eventDate.ticketing.wallet.contract,
    'eventDate.ticketing.wallet.contract'
  );

  const contractId: string = eventDate.ticketing.wallet.contract;

  const contextValue = useMemo(
    () => ({
      // TODO this eventDate is only a passed selector from redux
      eventDateId,
      contractId,

      selectedTab,
      selectTab,
      selectedStockContingent,
      selectStockContingent,
      unselectStockContingent,
    }),
    [contractId, eventDateId, selectTab, selectedStockContingent, selectedTab]
  );

  // TODO refactor this to avoid a fat context
  return (
    <StockManagementContext.Provider value={contextValue}>
      {children}
    </StockManagementContext.Provider>
  );
}
