import clone from 'clone';
import { fromJS, is, List, Map } from 'immutable';
import { createEntity } from '../entityFactory';
import type { AllowedFactoryTypes, Entity } from '../entityFactory/types';
import { ObjectEntity } from '../mapping';
import NetworkEntity from './NetworkEntity';

export type PagedCollectionType<E> = {
  '@id': null;
  '@type': 'hydra:PagedCollection';
  'hydra:member': List<E>;
  'hydra:totalItems': number;
  'hydra:itemsPerPage': number;
  'hydra:firstPage': null | string;
  'hydra:lastPage': null | string;
  'hydra:view': Map<string, string>;
  'mapado:filters': null | Map<string, List<Entity | string>>;
};

// eslint-disable-next-line no-shadow
export enum ViewType {
  current = '@id',
  first = 'hydra:first',
  last = 'hydra:last',
  next = 'hydra:next',
  previous = 'hydra:previous',
}

export type PagedCollectionInputType<E> = Partial<
  Pick<
    PagedCollectionType<E>,
    | '@id'
    | '@type'
    | 'hydra:totalItems'
    | 'hydra:itemsPerPage'
    | 'hydra:firstPage'
    | 'hydra:lastPage'
  > & {
    'hydra:member': List<AllowedFactoryTypes> | Array<AllowedFactoryTypes>;
    'hydra:view': Map<ViewType, string> | Record<ViewType, string>;
    'mapado:filters': null | Map<string, List<Entity | string>>;
  }
>;

const defaultValues: PagedCollectionType<unknown> = {
  '@id': null,
  '@type': 'hydra:PagedCollection',
  'hydra:totalItems': 0,
  'hydra:itemsPerPage': 0,
  'hydra:firstPage': null,
  'hydra:lastPage': null,
  'hydra:member': List(),
  'hydra:view': Map<ViewType, string>(),
  'mapado:filters': null,
};

const PagedCollectionFactory = NetworkEntity<PagedCollectionType<unknown>>(
  defaultValues
);

class PagedCollection<E extends ObjectEntity>
  extends PagedCollectionFactory
  implements Iterable<E> {
  private index: number;

  constructor(val: PagedCollectionInputType<E>) {
    const data = clone(val);

    const parameters: Partial<PagedCollectionType<E>> = {
      ...data,
      'hydra:member': data['hydra:member']
        ? List(
            data['hydra:member'].map(
              (member: AllowedFactoryTypes): E => {
                return createEntity(member as AllowedFactoryTypes) as E;
              }
            )
          )
        : List<E>(),
      'hydra:view': data['hydra:view']
        ? (fromJS(data['hydra:view']) as Map<ViewType, string>)
        : Map<ViewType, string>(),
    };

    const out = super(parameters);

    this.index = 0;

    // TODO remove this useless return ? (See https://www.bennadel.com/blog/2522-providing-a-return-value-in-a-javascript-constructor.htm)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error */
    return out;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error -- possible conflict with immutable */
  [Symbol.iterator](): Iterator<E> {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (...args) => {
        if (this.index < this.getMembers().size) {
          return {
            // eslint-disable-next-line no-plusplus
            value: this.getMembers().get(this.index++) as E,
            done: false,
          };
        }
        this.index = 0; // If we would like to iterate over this again without forcing manual update of the index
        return { done: true, value: undefined };
      },
    };
  }

  getPage(type: ViewType): string | null {
    return this['hydra:view'].get(type) || null;
  }

  getFirstPage(): string | null {
    return this.getPage(ViewType.first);
  }

  getCurrentPage(): string | null {
    return this.getPage(ViewType.current);
  }

  getNextPage(): string | null {
    return this.getPage(ViewType.next);
  }

  getLastPage(): string | null {
    return this.getPage(ViewType.last);
  }

  getPreviousPage(): string | null {
    return this.getPage(ViewType.previous);
  }

  getMembers(): List<E> {
    return this['hydra:member'] as List<E>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error */
  merge(newPagedCollection: PagedCollection<E>): PagedCollection<E> {
    const newMembers = this.getMembers()
      // concat the two lists
      .concat(newPagedCollection.getMembers())
      // group by id to reduce duplicates
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- the @id is defined for all our entities */
      .groupBy((m) => m.get('@id'))
      // reduce duplicate using `merge` function or the Record
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- hard to work on that, working for now */
      .map((memberList) => memberList.reduce((prev, curr) => prev.merge(curr)));

    const newMembersSize = newMembers.size as number;
    const newCollection = this.set(
      'hydra:member',
      newMembers.valueSeq().toList()
    ).set('hydra:totalItems', newMembersSize);

    if (
      newMembersSize !== this.get('hydra:totalItems') ||
      !is(newCollection, this)
    ) {
      return newCollection;
    }

    return this;
  }
}

export default PagedCollection;
