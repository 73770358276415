import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatFoldable = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 17" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M7 0h6c2.761 0 5 2.035 5 4.545V11h2v3a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-3h2V4.545C2 2.035 4.239 0 7 0M4 11h12V4.571C16 3.151 14.657 2 13 2H7C5.343 2 4 3.151 4 4.571zm14 2H2v1a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatFoldable;
