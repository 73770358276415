import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import PaymentClient from '../client/PaymentClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import type Order from './Order';
import type Transaction from './Transaction';
import type Wallet from './Wallet';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

/** @deprecated use `PAYMENT_PROVIDER.MANUAL` instead */
export const PROVIDER_MANUAL = 'manual';
/** @deprecated use `PAYMENT_PROVIDER.STRIPE` instead */
export const PROVIDER_STRIPE = 'stripe';
/** @deprecated use `PAYMENT_PROVIDER.MANGOPAY` instead */
export const PROVIDER_MANGOPAY = 'mangopay';
/** @deprecated use `PAYMENT_PROVIDER.PAYLINE` instead */
export const PROVIDER_PAYLINE = 'payline';
/** @deprecated use `PAYMENT_PROVIDER.PAYZONE` instead */
export const PROVIDER_PAYZONE = 'payzone';
/** @deprecated use `PAYMENT_PROVIDER.PAYREXX` instead */
export const PROVIDER_PAYREXX = 'payrexx';
/** @deprecated use `PAYMENT_PROVIDER.NONE` instead */
export const NO_PROVIDER = 'none';

export enum PAYMENT_PROVIDER {
  MANUAL = 'manual',
  STRIPE = 'stripe',
  MANGOPAY = 'mangopay',
  PAYLINE = 'payline',
  PAYZONE = 'payzone',
  PAYREXX = 'payrexx',
  NONE = 'none',
}

export const EXTERNAL_PROVIDER_LIST: PAYMENT_PROVIDER[] = [
  PAYMENT_PROVIDER.MANGOPAY,
  PAYMENT_PROVIDER.PAYLINE,
  PAYMENT_PROVIDER.STRIPE,
  PAYMENT_PROVIDER.PAYZONE,
  PAYMENT_PROVIDER.PAYREXX,
];

export const DISTINCT_PAYMENT_MULTI_WALLET_PROVIDER_LIST: PAYMENT_PROVIDER[] = [
  PAYMENT_PROVIDER.PAYLINE,
  PAYMENT_PROVIDER.STRIPE,
  PAYMENT_PROVIDER.PAYZONE,
  PAYMENT_PROVIDER.PAYREXX,
];

export type PaymentType = BaseEntity<'Payment'> & {
  createdAt: null | string;
  customerPaidAmount: null | number;
  orderStatus: null | string;
  statusDescription: null | string;
  order: null | EntityRelation<Order>;
  paymentTransactionList: null | List<EntityRelation<Transaction>>;
  provider: null | string;
  providerMetadata: null | Map<string, unknown>;
  originType: null | string;
  originId: null | string;
  wallet: null | Wallet;
};

class Payment extends NetworkEntity<PaymentType>({
  '@id': null,
  '@type': 'Payment',
  createdAt: null,
  customerPaidAmount: 0,
  orderStatus: '',
  statusDescription: '',
  order: null,
  paymentTransactionList: List<Transaction>(),
  provider: '',
  providerMetadata: Map<string, unknown>(),
  originType: null,
  originId: null,
  wallet: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<PaymentType> = { '@id': null, '@type': 'Payment' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/payments/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Payment.classMetadata = new ClassMetadata('payment', 'payments', PaymentClient);
Payment.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('@type'),
  new Attribute('customerPaidAmount', 'customerPaidAmount', 'integer'),
  new Attribute('orderStatus'),
  new Attribute('statusDescription'),
  new Attribute('provider'),
  new Attribute('providerMetadata', 'providerMetadata', 'object'),
  new Attribute('originType'),
  new Attribute('originId'),
]);
Payment.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.ONE_TO_MANY, 'transaction', 'paymentTransactionList'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
]);

export default Payment;
