import clone from 'clone';
import { is, List } from 'immutable';
import createEntity from '../entityFactory/EntityFactory';
import { AllowedFactoryTypes } from '../entityFactory/types';
import NetworkEntity from './NetworkEntity';

export type CollectionType<E> = {
  '@id': null | string;
  '@type': 'hydra:Collection';
  'hydra:member': List<E>;
};

const defaultValues: CollectionType<unknown> = {
  '@id': null,
  '@type': 'hydra:Collection',
  'hydra:member': List<unknown>(),
};

const CollectionFactory = NetworkEntity<CollectionType<unknown>>(defaultValues);

class Collection<E> extends CollectionFactory implements Iterable<E> {
  private index: number;

  constructor(val: CollectionType<unknown>) {
    const data = clone(val);

    data['hydra:member'] = List(
      data['hydra:member'].map((member) =>
        createEntity(member as AllowedFactoryTypes)
      )
    );

    const out = super(data);

    this.index = 0;

    // TODO remove this useless return ? (See https://www.bennadel.com/blog/2522-providing-a-return-value-in-a-javascript-constructor.htm)
    /** @ts-expect-error -- to remove ? */
    return out;
  }

  /** @ts-expect-error -- possible conflict with immutable */
  [Symbol.iterator](): Iterator<E> {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (...args) => {
        if (this.index < this.getMembers().size) {
          return {
            // eslint-disable-next-line no-plusplus
            value: this.getMembers().get(this.index++) as E,
            done: false,
          };
        }
        this.index = 0; // If we would like to iterate over this again without forcing manual update of the index

        return { done: true, value: undefined };
      },
    };
  }

  getMembers(): List<E> {
    return this['hydra:member'] as List<E>;
  }

  /** @ts-expect-error -- possible conflict with immutable */
  merge(newCollection: Collection<E>): Collection<E> {
    const newMembers = this.getMembers()
      // concat the two lists
      .concat(newCollection.getMembers())
      // group by id to reduce duplicates
      /** @ts-expect-error -- the @id is defined for all our entities */
      .groupBy((m) => m.get('@id'))
      // reduce duplicate using `merge` function or the Record
      /** @ts-expect-error -- hard to work on that, working for now */
      .map((memberList) => memberList.reduce((prev, curr) => prev.merge(curr)));

    const newMembersSize = newMembers.size;
    const updatedCollection = this.set(
      'hydra:member',
      newMembers.valueSeq().toList()
    );

    if (
      newMembersSize !== this.getMembers().size ||
      !is(updatedCollection, this)
    ) {
      return updatedCollection;
    }

    return this;
  }
}

export default Collection;
