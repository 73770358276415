import { List, Set } from 'immutable';
import {
  Cart,
  CartItem,
  Ticket,
  Order,
  Booking,
  OrderItem,
  SeatGroup,
  PagedCollection,
  AvailableSeat,
  assertRelationIsDefined,
  assertRelationIsObject,
  assertRelationIsListOfObject,
  assertRelationIsString,
} from 'mapado-ticketing-js-sdk';
import { ShouldNotHappenError } from '../errors';
import { isBooking, isCart, isOrder } from './booking';

function doGetAvailableSeatListFromCart(cart: Cart): List<AvailableSeat> {
  const availableSeatList: AvailableSeat[] = [];

  assertRelationIsDefined(cart.cartItemList, 'cart.cartItemList');

  cart.cartItemList.forEach((cartItem) => {
    assertRelationIsObject(cartItem, 'cartItem');
    assertRelationIsDefined(
      cartItem.availableSeatList,
      'cartItem.availableSeatList'
    );

    cartItem.availableSeatList.forEach((availableSeat) => {
      assertRelationIsObject(availableSeat, 'availableSeat');

      availableSeatList.push(availableSeat);
    });
  });

  return List(availableSeatList);
}

function doGetAvailableSeatListFromOrder(order: Order): List<AvailableSeat> {
  assertRelationIsListOfObject(order.ticketList, 'order.ticketList');

  return order.ticketList
    .map((ticket) => ticket.availableSeat)
    .filter(
      (availableSeat): availableSeat is AvailableSeat =>
        availableSeat !== null && typeof availableSeat !== 'string'
    );
}

interface CartToListOfAvailableSeatCallback {
  (cart: Cart): List<AvailableSeat>;
}
interface GetAvailableSeatMemoCache {
  [key: string]: List<AvailableSeat>;
}

const memoizeGetAvailableSeatListFromCart =
  (): CartToListOfAvailableSeatCallback => {
    const cache: GetAvailableSeatMemoCache = {};

    return (cart: Cart): List<AvailableSeat> => {
      const cartHashCode = String(cart.hashCode());

      if (cartHashCode in cache) {
        return cache[cartHashCode];
      }

      const result = doGetAvailableSeatListFromCart(cart);

      cache[cartHashCode] = result;

      return result;
    };
  };

interface OrderToListOfAvailableSeatCallback {
  (order: Order): List<AvailableSeat>;
}

const memoizeGetAvailableSeatListFromOrder =
  (): OrderToListOfAvailableSeatCallback => {
    const cache: GetAvailableSeatMemoCache = {};

    return (order: Order): List<AvailableSeat> => {
      const orderHashCode = order.hashCode();

      if (orderHashCode in cache) {
        return cache[orderHashCode];
      }

      const result = doGetAvailableSeatListFromOrder(order);

      cache[orderHashCode] = result;

      return result;
    };
  };

function getCartOrOrderFromBooking(
  booking: Booking
): Cart | Order | Booking | null {
  if (isBooking(booking)) {
    if (booking.cart) {
      assertRelationIsObject(booking.cart, 'booking.cart');

      return booking.cart;
    }

    if (booking.order) {
      assertRelationIsObject(booking.order, 'booking.order');

      return booking.order;
    }
  }

  return booking;
}

function doGetPossibleSeatGroupListForBookedSeat(
  bookingCollection: PagedCollection<Booking>,
  seatLongIdSet: Set<string>
): List<SeatGroup | string> {
  let possibleSeatGroupListForBookedSeat = List<SeatGroup | string>();

  bookingCollection.getMembers().forEach((booking: Booking) => {
    const cartOrOrder = getCartOrOrderFromBooking(booking);

    assertRelationIsDefined(cartOrOrder, 'cartOrOrder');

    if (!isOrder(cartOrOrder) && !isCart(cartOrOrder)) {
      throw new ShouldNotHappenError('We should get a Cart or an Order here');
    }

    const itemList = isOrder(cartOrOrder)
      ? cartOrOrder.orderItemList
      : cartOrOrder.cartItemList;

    assertRelationIsDefined(itemList, 'itemList');

    itemList
      .toArray()
      .forEach((cartOrOrderItem: string | CartItem | OrderItem) => {
        assertRelationIsObject(cartOrOrderItem, 'cartOrOrderItem');

        let isSeatFound = false;

        if (isCart(cartOrOrder)) {
          const cartItem: CartItem = cartOrOrderItem as CartItem;

          assertRelationIsDefined(
            cartItem.availableSeatList,
            'cartItem.availableSeatList'
          );

          isSeatFound = !!cartItem.availableSeatList.find(
            (availableSeat): boolean => {
              assertRelationIsObject(availableSeat, 'availableSeat');
              assertRelationIsObject(availableSeat.seat, 'availableSeat.seat');

              if (typeof availableSeat.seat === 'string') {
                return seatLongIdSet.has(availableSeat.seat);
              }

              return seatLongIdSet.has(availableSeat.seat.get('@id') || '');
            }
          );
        } else if (isOrder(cartOrOrder)) {
          const orderItem: OrderItem = cartOrOrderItem as OrderItem;

          assertRelationIsDefined(orderItem.ticketList, 'orderItem.ticketList');

          isSeatFound = !!orderItem.ticketList.find((ticket: Ticket) => {
            assertRelationIsObject(
              ticket.availableSeat,
              'ticket.availableSeat'
            );

            assertRelationIsObject(
              ticket.availableSeat.seat,
              'ticket.availableSeat.seat'
            );

            const seatId = ticket.availableSeat.seat['@id'];

            assertRelationIsString(seatId, 'seatId');

            return seatLongIdSet.has(seatId);
          });
        } else {
          // eslint-disable-next-line no-console
          console.error('bookingType must be a Cart or an Order');
        }

        if (isSeatFound) {
          assertRelationIsObject(
            cartOrOrderItem?.ticketPrice,
            'cartOrOrderItem.ticketPrice'
          );
          assertRelationIsDefined(
            cartOrOrderItem?.ticketPrice?.ticketPriceSeatGroupList,
            'cartOrOrderItem.ticketPrice.ticketPriceSeatGroupList'
          );

          const seatGroupList: List<SeatGroup | string> =
            cartOrOrderItem.ticketPrice.ticketPriceSeatGroupList.map(
              (tpSeatGroup): SeatGroup | string => {
                assertRelationIsObject(tpSeatGroup, 'tpSeatGroup');
                assertRelationIsDefined(
                  tpSeatGroup.seatGroup,
                  'tpSeatGroup.seatGroup'
                );

                return tpSeatGroup.seatGroup;
              }
            );

          possibleSeatGroupListForBookedSeat =
            possibleSeatGroupListForBookedSeat.concat(seatGroupList) as List<
              SeatGroup | string
            >; // TODO : weird casting here remove that
        }
      });
  });

  return possibleSeatGroupListForBookedSeat.toSet().toList();
}

interface PossibleSeatGroupListCache {
  [key: string]: List<string | SeatGroup>;
}
interface GetPossibleSeatGroupListForBookedSeatCallback {
  (bookingCollection: PagedCollection<Booking>, seatLongId: Set<string>): List<
    string | SeatGroup
  >;
}

function memoizeGetPossibleSeatGroupListForBookedSeat(): GetPossibleSeatGroupListForBookedSeatCallback {
  const cache: PossibleSeatGroupListCache = {};

  return (
    bookingCollection: PagedCollection<Booking>,
    seatLongId: Set<string>
  ): List<string | SeatGroup> => {
    const hash = `${bookingCollection.hashCode()}_${seatLongId}`;

    if (!(hash in cache)) {
      const result = doGetPossibleSeatGroupListForBookedSeat(
        bookingCollection,
        seatLongId
      );

      cache[hash] = result;
    }

    return cache[hash];
  };
}

export const getAvailableSeatListFromCart =
  memoizeGetAvailableSeatListFromCart();
export const getAvailableSeatListFromOrder =
  memoizeGetAvailableSeatListFromOrder();
export const getPossibleSeatGroupListForBookedSeat =
  memoizeGetPossibleSeatGroupListForBookedSeat();
