import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOption = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 14" className="mpd-icon" {...props}>
    <path d="m2.464 0 .443.46-.848.823.793.825-.459.445-.793-.825-.757.734-.443-.46.757-.735L.4.48.858.035l.758.788zM5.818.856a.73.73 0 0 0-.727.73c0 .403.326.73.727.73h9.455a.73.73 0 0 0 .727-.73.73.73 0 0 0-.727-.73zM5.818 4.507a.73.73 0 0 0-.727.73c0 .404.326.73.727.73h9.455a.73.73 0 0 0 .727-.73.73.73 0 0 0-.727-.73zM5.09 8.888c0-.403.327-.73.728-.73h9.455c.401 0 .727.327.727.73 0 .404-.326.73-.727.73H5.818a.73.73 0 0 1-.727-.73M5.818 11.81a.73.73 0 0 0-.727.73c0 .403.326.73.727.73h9.455a.73.73 0 0 0 .727-.73.73.73 0 0 0-.727-.73z" />
    <path
      fillRule="evenodd"
      d="M1.455 14c.803 0 1.454-.654 1.454-1.46 0-.807-.651-1.46-1.454-1.46C.65 11.08 0 11.732 0 12.54S.651 14 1.455 14m0-.73a.73.73 0 0 0 .727-.73.73.73 0 0 0-.727-.73.73.73 0 0 0-.728.73c0 .403.326.73.728.73M2.91 8.888c0 .807-.652 1.46-1.455 1.46A1.46 1.46 0 0 1 0 8.889c0-.806.651-1.46 1.455-1.46.803 0 1.454.654 1.454 1.46m-.728 0c0 .404-.326.73-.727.73a.73.73 0 0 1-.728-.73.729.729 0 1 1 1.455 0M1.455 6.698c.803 0 1.454-.654 1.454-1.46 0-.807-.651-1.461-1.454-1.461C.65 3.777 0 4.43 0 5.237c0 .807.651 1.46 1.455 1.46m0-.73a.73.73 0 0 0 .727-.73.73.73 0 0 0-.727-.731.73.73 0 0 0-.728.73c0 .404.326.73.728.73"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOption;
