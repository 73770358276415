import { BaseEntity } from '../..';
import NetworkEntity from '../../NetworkEntity';

export const TYPE_TICKETS = 'tickets';

type ImportedFileTypes = typeof TYPE_TICKETS;

export type ImportedFileType = BaseEntity<'ImportedFile'> & {
  fileName: null | string;
  size: null | number;
  mimeType: null | string;
  originalName: null | string;
  importedAt: null | string;
  type: null | ImportedFileTypes;
};

class ImportedFile extends NetworkEntity<ImportedFileType>({
  '@id': null,
  '@type': 'ImportedFile',
  fileName: null,
  size: null,
  mimeType: null,
  originalName: null,
  importedAt: null,
  type: null,
}) {}

export default ImportedFile;
