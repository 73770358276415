import React, {
  ReactElement,
  ReactNode,
  MouseEvent,
  KeyboardEvent,
  useState,
  useRef,
  KeyboardEventHandler,
} from 'react';
import cn from 'classnames';
import MpdIcon from './MpdIcon';
import MpdCard from './MpdCard';
import { MpdIconType } from '../types/MpdIconType';
import { KEY_CODES, matchKeyEvent } from '../js-libs/utils';

const TIMEOUT_DELAY = 500;
const MAX_POPOVER_SIZE = 250;

type Props = {
  buttonCustom?: ReactNode;
  children: ReactNode;
  hover?: boolean;
  displayOnLeft?: boolean;
  className?: string;
  icon?: MpdIconType;
  noIcon?: boolean;
  iconClassName?: string;
  label?: string | ReactElement;
  labelClassName?: string;
  action?: ReactElement;
  keepOpen?: boolean;
  disabled?: boolean;
};

function MpdPopover({
  buttonCustom,
  children,
  hover = false,
  displayOnLeft = false,
  className,
  icon = 'action',
  noIcon = false,
  iconClassName,
  label,
  labelClassName,
  action,
  keepOpen = false,
  disabled = false,
  ...props
}: Props): JSX.Element {
  let timeout: NodeJS.Timeout;
  const [opened, setOpened] = useState(false);
  const [displayAbove, setDisplayAbove] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const classnames = cn(
    'mpd-popover',
    buttonCustom ? 'h100 w100' : 'p2',
    className,
    {
      'mpd-popover--above': displayAbove,
      'mpd-popover--left': displayOnLeft,
      'mpd-popover--disabled': disabled,
    }
  );

  const handleClose = (event?: MouseEvent<HTMLElement>) => {
    event?.stopPropagation();

    if (disabled) {
      return;
    }

    timeout = setTimeout(() => setOpened(false), TIMEOUT_DELAY);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (keepOpen) {
      return;
    }

    setOpened(false);
  };

  const handleOpen = (event: KeyboardEvent | MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (disabled) {
      return;
    }

    const topPosition = containerRef.current?.getBoundingClientRect().top;

    setOpened(true);
    setDisplayAbove(
      topPosition ? window.innerHeight - topPosition < MAX_POPOVER_SIZE : false
    );
  };

  const handleEnter = (event: MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    if (opened) {
      clearTimeout(timeout);
    }

    if (hover) {
      handleOpen(event);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (disabled) {
      return;
    }

    if (
      matchKeyEvent(event.nativeEvent, KEY_CODES.enter) ||
      matchKeyEvent(event.nativeEvent, KEY_CODES.up) ||
      matchKeyEvent(event.nativeEvent, KEY_CODES.down)
    ) {
      setOpened(true);
    }
  };

  return (
    <div
      ref={containerRef}
      className={classnames}
      onClick={handleOpen}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleEnter}
      onMouseLeave={handleClose}
      role="button"
      tabIndex={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {label && !buttonCustom && (
        <span className={cn('mpd-popover__label', labelClassName)}>
          {label}
        </span>
      )}

      {buttonCustom}

      {!noIcon && !buttonCustom && (
        <MpdIcon
          icon={icon}
          width="20"
          className={cn('mpd-popover__action-trigger', iconClassName)}
        />
      )}
      {opened && (
        <div
          className={`mpd-popover__content ${
            buttonCustom && 'mpd-popover__content--large'
          }`}
        >
          <MpdCard onClick={handleClick} className="actived">
            <div className="list">{children}</div>
            {action && <div className="mpd-popover__action">{action}</div>}
          </MpdCard>
        </div>
      )}
    </div>
  );
}

export default MpdPopover;
