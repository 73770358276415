// main function
export default function scrollToY(scrollTargetY = 0): void {
  if (typeof document.documentElement.style.scrollBehavior === 'undefined') {
    // scrollTo options are not supported (IE11 for example)
    // in fact the "smooth" behaviour is not supported but this check is sufficient for us
    window.scrollTo(0, scrollTargetY);
  } else {
    window.scrollTo({
      top: scrollTargetY,
      behavior: 'smooth',
    });
  }
}
