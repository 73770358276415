import React, {
  ReactNode,
  ReactElement,
  ChangeEvent,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import cn from 'classnames';
import MpdIcon from '../MpdIcon';
import { useTranslation } from '../../i18n';

interface DefaultProps {
  children?: ReactNode | null;
  onOpen?: ((b: boolean) => void) | null;
  sortElement?: ReactNode | null;
  className?: string;
}

export interface MpdFiltersWithInputElement extends DefaultProps {
  inputElement: ReactElement;
  placeholder?: never;
  onChange?: never;
}

export interface MpdFiltersWithoutInputElement extends DefaultProps {
  inputElement?: never;
  placeholder?: string;
  onChange: (s: string) => void | Dispatch<SetStateAction<string>>;
}

type Props = MpdFiltersWithInputElement | MpdFiltersWithoutInputElement;

function MpdFilters({
  inputElement,
  placeholder,
  onChange,
  className,
  children = null,
  onOpen = null,
  sortElement = null,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [moreFiltersOpened, setMoreFiltersOpened] = useState(false);
  const hasMoreFilters = !!children;
  const filtersRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (onChange) {
      onChange(value);
    }
  };

  const toggleMoreFilters = () => {
    if (onOpen) {
      onOpen(!moreFiltersOpened);
    }

    setMoreFiltersOpened(!moreFiltersOpened);
  };

  const renderInput = () => {
    if (inputElement) {
      return inputElement;
    }

    if (!inputElement && !onChange) {
      throw new Error(
        `\`onChange\` prop supplied to MpdFilters component should be a function, or you need to pass a custom \`inputElement\`.`
      );
    }

    return (
      <input type="text" placeholder={placeholder} onChange={handleSearch} />
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        !(event.target instanceof Node) ||
        (document.contains(event.target) &&
          !filtersRef.current?.contains(event.target) &&
          !btnRef.current?.contains(event.target))
      ) {
        if (onOpen) {
          onOpen(false);
        }

        setMoreFiltersOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onOpen]);

  return (
    <div
      className={cn(
        'mpd-filters',
        {
          'mpd-filters--with-more-filters': hasMoreFilters || !!sortElement,
        },
        className
      )}
      onClick={(event) => event.stopPropagation()}
      aria-hidden="true"
    >
      <div className="mpd-filters__container">
        <div className="mpd-filters__search">
          <MpdIcon
            icon="magnifying-glass"
            height="18"
            width="18"
            className="mpd-filters__search-icon"
          />
          {renderInput()}
        </div>
        {hasMoreFilters && (
          <button
            type="button"
            className="mpd-btn mpd-btn--link mpd-btn--icon-only mpd-filters__more"
            onClick={toggleMoreFilters}
            title={t('filters.see_more_filters')}
            ref={btnRef}
          >
            <MpdIcon
              icon={moreFiltersOpened ? 'cross' : 'filter'}
              height="16"
              width="16"
            />
          </button>
        )}
        {sortElement}
      </div>
      {hasMoreFilters && (
        <div
          ref={filtersRef}
          className={cn(
            'sub-filters',
            moreFiltersOpened && 'sub-filters--open'
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default MpdFilters;
