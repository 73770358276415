import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import BOOKING_TRACK_MODE_TYPE from '../types/BookingTrackModeType';
import { CART_TYPE } from '../types/CartTypes';
import Billing from './Billing';
import BookingTrack from './BookingTrack';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import Shipping from './Shipping';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type BookingTrackModeType = BaseEntity<'BookingTrackMode'> & {
  contract: null | EntityRelation<Contract>;
  label: null | string;
  description: null | string;
  cartType: null | CART_TYPE;
  type: null | BOOKING_TRACK_MODE_TYPE;
  isDefault: null | boolean;
  position: null | number;
  availableForPaymentProviders: null | List<string>;
  bookingOptions: null | Map<
    'canConvertToReservation' | 'canConvertToOrder',
    boolean
  >;
  shippingList: null | List<EntityRelation<Shipping>>;
  billingList: null | List<EntityRelation<Billing>>;
  bookingTrackList: null | List<EntityRelation<BookingTrack>>;
};

class BookingTrackMode extends NetworkEntity<BookingTrackModeType>({
  '@id': null,
  '@type': 'BookingTrackMode',
  label: null,
  description: null,
  contract: null,
  cartType: null,
  type: null,
  isDefault: null,
  position: null,
  availableForPaymentProviders: null,
  bookingOptions: null,
  shippingList: null,
  billingList: null,
  bookingTrackList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BookingTrackModeType> = {
      '@id': null,
      '@type': 'BookingTrackMode',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/booking_track_modes/', '') || '';
  }
}

BookingTrackMode.classMetadata = new ClassMetadata(
  'bookingTrackMode',
  'booking_track_modes',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
BookingTrackMode.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
  new Attribute('description'),
  new Attribute('cartType'),
  new Attribute('type'),
  new Attribute('position'),
  new Attribute(
    'availableForPaymentProviders',
    'availableForPaymentProviders',
    'array'
  ),
  new Attribute('bookingOptions', 'bookingOptions', 'object'),
  new Attribute('isDefault', 'isDefault', 'boolean'),
]);
BookingTrackMode.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'shipping', 'shippingList'),
  new Relation(Relation.ONE_TO_MANY, 'billing', 'billingList'),
]);

export default BookingTrackMode;
