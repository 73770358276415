import {
  DOCUMENT_CONTRACT,
  DOCUMENT_GDPR,
  DOCUMENT_ADDENDUM,
  DOCUMENT_CONFORMITY,
} from '../entity/Contract';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ContractClient extends AbstractClient<TSMetadata['contract']> {
  getPathBase(): string {
    return '/v1/contracts';
  }

  getContractualDocument(
    documentType: string,
    id: number | string
  ): Promise<Response> {
    switch (documentType) {
      case DOCUMENT_GDPR:
        return this.getPdfContractGdpr(id);
      case DOCUMENT_ADDENDUM:
        return this.getPdfContractAddendum(id, null);
      case DOCUMENT_CONFORMITY:
        return this.getPdfConformity(id);
      case DOCUMENT_CONTRACT:
      default:
        return this.getPdfContract(id);
    }
  }

  getPdfConformity(id: number | string): Promise<Response> {
    const url = `/v1/accountability/conformity-attestation/${id}.pdf`;

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfContract(id: number | string): Promise<Response> {
    const url = `/v1/contracts/${id}/contracts.pdf`;

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfContractAddendum(
    id: number | string,
    idAddendum: number | string | null
  ): Promise<Response> {
    let url = `/v1/contracts/${id}/addendum.pdf`;
    if (idAddendum) {
      url = `/v1/contracts/${id}/addendum-${idAddendum}.pdf`;
    }

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfContractGdpr(id: number | string): Promise<Response> {
    const url = `/v1/contracts/${id}/gdpr.pdf`;

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }
}

export default ContractClient;
