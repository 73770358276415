import URI from 'urijs';
import EventDatePattern, {
  EventDatePatternType,
} from '../entity/EventDatePattern';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class EventDatePatternClient extends AbstractClient<
  TSMetadata['eventDatePattern']
> {
  getPathBase(): string {
    return '/v1/event_date_patterns';
  }

  preview(entity: EventDatePattern): Promise<EventDatePatternType> {
    let url = URI(`${this.getPathBase()}/preview`);
    let method = 'POST';

    if (entity.get('@id')) {
      url = URI(`${entity.get('@id')}/preview`);
      method = 'PUT';
    }

    const oldSerializedModel = this.metadata.getDefaultSerializedModel();
    const newSerializedModel = this.serializer.normalizeItem(
      entity,
      this.metadata
    );

    const diff = this.sdk.unitOfWork.getDirtyData(
      newSerializedModel,
      oldSerializedModel,
      this.metadata
    );

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method,
        body: this.serializer.encodeItem(diff, this.metadata),
      }),
      'item'
    );
  }
}

export default EventDatePatternClient;
