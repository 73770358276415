import { AbstractClient as BaseClient } from 'rest-client-sdk';
import { TSMetadata } from '../../mapping';

class SibilTicketingSpectacleClient extends BaseClient<
  TSMetadata['sibilTicketingSpectacle']
> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPathBase(pathParameters: Record<string, unknown>): string {
    return '/v1/module/sibil/ticketing_spectacles';
  }
}

export default SibilTicketingSpectacleClient;
