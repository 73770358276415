import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import ContractAddendumClient from '../client/ContractAddendumClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import type Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import type { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ContractAddendumType = BaseEntity<'ContractAddendum'> & {
  summary: null | string;
  content: null | string;
  signatureDate: null | string;
  contract: null | EntityRelation<Contract>;
};

class ContractAddendum extends NetworkEntity<ContractAddendumType>({
  '@id': null,
  '@type': 'ContractAddendum',
  summary: null,
  content: null,
  signatureDate: null,
  contract: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<ContractAddendumType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/contract_addendums/', '') || '';
  }
}

ContractAddendum.classMetadata = new ClassMetadata(
  'contractAddendum',
  'contract_addendums',
  /** @ts-expect-error -- method signature are incompatible */
  ContractAddendumClient
);
ContractAddendum.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('summary'),
  new Attribute('content'),
  new Attribute('signatureDate'),
]);
ContractAddendum.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default ContractAddendum;
