import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCut = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 15" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="m4.886 9.183 3.77-2.177-3.748-2.165a2.8 2.8 0 0 1-1.299.757A2.86 2.86 0 0 1 .121 3.585 2.86 2.86 0 0 1 2.134.097 2.857 2.857 0 0 1 5.622 2.11a2.84 2.84 0 0 1-.007 1.504l4.46 2.575 8.84-5.107a.714.714 0 0 1 .971.263.713.713 0 0 1-.263.967l-8.13 4.695 8.152 4.704c.337.196.455.63.263.971a.713.713 0 0 1-.971.26l-8.862-5.117-4.481 2.588a2.852 2.852 0 0 1-1.32 3.233 2.846 2.846 0 0 1-3.876-1.02l-.035-.057a2.85 2.85 0 0 1-.267-2.127 2.8 2.8 0 0 1 1.295-1.707l.07-.043a2.843 2.843 0 0 1 3.425.49M4.25 2.479A1.43 1.43 0 0 0 2.5 1.47a1.43 1.43 0 0 0-1.006 1.75 1.429 1.429 0 0 0 2.613.341c.192-.327.242-.718.143-1.08M3.218 9.798c-.359-.096-.75-.05-1.077.139l-.007.003a1.44 1.44 0 0 0-.665.868c-.096.359-.05.743.128 1.06l.014.025c.192.327.501.565.868.665.366.096.757.046 1.084-.143.331-.188.57-.501.665-.867a1.427 1.427 0 0 0-1.01-1.75"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCut;
