import clone from 'clone';
import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import CustomerStatusClient from '../client/CustomerStatusClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import Coupon from './Coupon';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type CustomerStatusType = BaseEntity<'CustomerStatus'> & {
  label: null | string;
  contract: null | Contract;
  couponList: null | List<Coupon>;
  isSubscription: null | boolean;
};

class CustomerStatus extends NetworkEntity<CustomerStatusType>({
  '@id': null,
  '@type': 'CustomerStatus',
  label: null,
  contract: null,
  couponList: null,
  isSubscription: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CustomerStatusType> = {
      '@id': null,
      '@type': 'CustomerStatus',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/customer_statuses/', '') || '';
  }
}

CustomerStatus.classMetadata = new ClassMetadata(
  'customerStatus',
  'customer_statuses',
  /** @ts-expect-error -- method signature are incompatible */
  CustomerStatusClient
);
CustomerStatus.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
  new Attribute('isSubscription', 'isSubscription', 'boolean'),
]);
CustomerStatus.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'coupon', 'couponList'),
]);

export default CustomerStatus;
