import { List } from 'immutable';
import URI from 'urijs';
import type Payment from '../entity/Payment';
import type Refund from '../entity/Refund';
import type RefundOrderItem from '../entity/RefundOrderItem';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class PaymentClient extends AbstractClient<TSMetadata['payment']> {
  getPathBase(): string {
    return '/v1/payments';
  }

  checkout(
    orderId: string,
    wallet: string,
    metadata: Map<string, unknown>,
    fields: FieldsParameter
  ): Promise<Payment> {
    // eslint-disable-next-line no-console
    console.warn(
      'PaymentClient.checkout is deprecated, please use .create instead'
    );
    const url = new URI(this.getPathBase());
    url.addSearch({
      fields: getFieldsString(fields),
    });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify({
          order: orderId,
          wallet,
          provider: 'manual',
          providerMetadata: metadata,
        }),
      }),
      'item'
    );
  }

  refund(
    paymentId: number | string,
    fields: FieldsParameter,
    body = {}
  ): Promise<Payment> {
    const url = new URI('/v1/refunds');
    url.addSearch({
      fields: getFieldsString(fields),
    });

    const postBody = {
      payment: `${paymentId}`,
      ...body,
    };

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(postBody),
      }),
      'item'
    );
  }

  refundOrderItemList(
    refundOrderItemList: List<RefundOrderItem>,
    fields: FieldsParameter,
    body = {}
  ): Promise<Refund> {
    const url = new URI('/v1/refunds');
    url.addSearch({
      fields: getFieldsString(fields),
    });

    const postBody = {
      refundOrderItemList,
      ...body,
    };

    /** @ts-expect-error -- calling a "response" endpoint in PaymentClient seems weird */
    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(postBody),
      }),
      'item'
    );
  }

  updateProviderMetadata(
    paymentId: string,
    providerMetadata: Map<string, unknown>,
    fields: FieldsParameter
  ): Promise<Payment> {
    const url = new URI(`${paymentId}/provider-metadata`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'PUT',
        body: JSON.stringify(providerMetadata),
      }),
      'item'
    );
  }

  updatePaymentTransactionList(
    payment: Payment,
    fields: FieldsParameter
  ): Promise<Payment> {
    const url = new URI(`${payment.get('@id')}`);
    url.addSearch({
      fields: getFieldsString(fields),
    });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          paymentTransactionList: payment.paymentTransactionList,
        }),
      }),
      'item'
    );
  }
}

export default PaymentClient;
