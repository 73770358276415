import { Attribute, ClassMetadata } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import NetworkEntity from './NetworkEntity';
import { BaseEntity } from '.';

export type TaxType = BaseEntity<'Tax', `/v1/taxes/${number}`> & {
  rate: number;
  countryCode: string;
};

class Tax extends NetworkEntity<TaxType>({
  '@id': null,
  '@type': 'Tax',
  rate: 0,
  countryCode: '',
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  getShortId(): string {
    return this.get('@id')?.replace('/v1/taxes/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Tax.classMetadata = new ClassMetadata('tax', 'taxes', AbstractClient);
Tax.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('rate', 'rate', 'float'),
  new Attribute('countryCode'),
]);

export default Tax;
