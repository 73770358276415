import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum ORDER_FEE_ITEM_TYPE {
  RENTAL = 'rental',
  POSTAL_MAIL = 'postalMail',
  REGISTERED_MAIL = 'registeredMail',
}

export type OrderFeeItemType = BaseEntity<'OrderFeeItem'> & {
  order: null | EntityRelation<Order>;
  type: null | ORDER_FEE_ITEM_TYPE;
  amount: null | number;
  rate: null | number;
  vatRate: null | number;
  currency: null | string;
};

class OrderFeeItem extends NetworkEntity<OrderFeeItemType>({
  '@id': null,
  '@type': 'OrderFeeItem',
  order: null,
  type: null,
  amount: null,
  rate: null,
  vatRate: null,
  currency: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrderFeeItemType> = {
      '@id': null,
      '@type': 'OrderFeeItem',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/order_fee_items/', '') || '';
  }
}

OrderFeeItem.classMetadata = new ClassMetadata(
  'orderFeeItem',
  'order_fee_items',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
OrderFeeItem.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('amount', 'amount', 'integer'),
  new Attribute('rate', 'rate', 'float'),
  new Attribute('vatRate', 'vatRate', 'float'),
  new Attribute('currency'),
]);
OrderFeeItem.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
]);

export default OrderFeeItem;
