import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPen = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 27 32" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-666 -972)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="modifier"
            d="M584.02 150.667H576c-1.333 0-1.333 1.327-1.333 1.327v29.04q.002.011.007.022.005.036.022.071.014.036.038.07c.005.008.005.015.01.021l4.762 5.266q.007.005.018.011a.5.5 0 0 0 .189.122l.004.001a.8.8 0 0 0 .238.041c.015 0 .028.008.044.008s.029-.008.045-.008a.8.8 0 0 0 .238-.042.5.5 0 0 0 .194-.122q.01-.006.017-.012l4.763-5.266c.005-.007.005-.014.011-.02.017-.022.025-.046.035-.07a.2.2 0 0 0 .024-.073c.002-.008.007-.014.007-.021v-29.04c0-1.326-1.313-1.326-1.313-1.326M577.333 160h5.334v18.667h-5.334zM580 184l-2.667-2.667h5.334zm-2.667-30.667h5.334v4h-5.334z"
            transform="rotate(38 580 168.667)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPen;
