import RestClientSdk, {
  ClassMetadata,
  Config,
  Logger,
  Mapping,
  RestClientSdkInterface,
  SerializerInterface,
  Token,
  TokenStorageInterface,
} from 'rest-client-sdk';
import AbstractClient from './client/AbstractClient';
import AuditDocumentClient from './client/AuditDocumentClient';
import AvailableSeatClient from './client/AvailableSeatClient';
import AvailableSeatModelClient from './client/AvailableSeatModelClient';
import BankAccountClient from './client/BankAccountClient';
import BookingClient from './client/BookingClient';
import BookingTrackClient from './client/BookingTrackClient';
import CartClient from './client/CartClient';
import CartItemClient from './client/CartItemClient';
import ContingentClient from './client/ContingentClient';
import ContractAddendumClient from './client/ContractAddendumClient';
import ContractClient from './client/ContractClient';
import CouponClient from './client/CouponClient';
import CustomerClient from './client/CustomerClient';
import CustomerStatusClient from './client/CustomerStatusClient';
import DeskPaymentMethodClient from './client/DeskPaymentMethodClient';
import DeskSessionClient from './client/DeskSessionClient';
import DeviceStateClient from './client/DeviceStateClient';
import EventDateClient from './client/EventDateClient';
import EventDatePatternClient from './client/EventDatePatternClient';
import FeatureClient from './client/FeatureClient';
import HidableClient from './client/HidableClient';
import ProviderTicketImportedFileClient from './client/Import/ProviderTicketImportedFileClient';
import InvoiceClient from './client/InvoiceClient';
import JobClient from './client/JobClient';
import LogicalSeatConfigClient from './client/LogicalSeatConfigClient';
import MasterTicketClient from './client/MasterTicketClient';
import SibilParameterClient from './client/Module/SibilParameterClient';
import SibilProxyClient from './client/Module/SibilProxyClient';
import SibilTicketingSpectacleClient from './client/Module/SibilTicketingSpectacleClient';
import SibilVenueLieuClient from './client/Module/SibilVenueLieuClient';
import NotificationClient from './client/NotificationClient';
import OfferRuleClient from './client/OfferRuleClient';
import OrderClient from './client/OrderClient';
import OrganizationalUnitClient from './client/OrganizationalUnitClient';
import PaymentClient from './client/PaymentClient';
import PayoutClient from './client/PayoutClient';
import ProviderContingentMappingClient from './client/ProviderContingentMappingClient';
import ProviderSeatConfigMappingClient from './client/ProviderSeatConfigMappingClient';
import ProviderSeatMappingClient from './client/ProviderSeatMappingClient';
import ProviderTicketPriceMappingClient from './client/ProviderTicketPriceMappingClient';
import ProviderTicketingMappingClient from './client/ProviderTicketingMappingClient';
import ScanClient from './client/ScanClient';
import SeasonClient from './client/SeasonClient';
import SeatClient from './client/SeatClient';
import SeatConfigClient from './client/SeatConfigClient';
import SeatGroupClient from './client/SeatGroupClient';
import SeatReservedClient from './client/SeatReservedClient';
import StickerClient from './client/StickerClient';
import StockContingentClient from './client/StockContingentClient';
import SubscriptionClient from './client/SubscriptionClient';
import SubscriptionMaturityClient from './client/SubscriptionMaturityClient';
import TagClient from './client/TagClient';
import TicketClient from './client/TicketClient';
import TicketPriceClient from './client/TicketPriceClient';
import TicketPriceGroupClient from './client/TicketPriceGroupClient';
import TicketingCategoryClient from './client/TicketingCategoryClient';
import TicketingClient from './client/TicketingClient';
import TransactionGroupClient from './client/TransactionGroupClient';
import UserClient from './client/UserClient';
import VenueClient from './client/VenueClient';
import VersionClient from './client/VersionClient';
import WalletClient from './client/WalletClient';
import defaultConfig from './config';
import EntitySerializer from './entityFactory';
import mapping, { TSMetadata } from './mapping';

class TicketingSdk implements RestClientSdkInterface<TSMetadata> {
  #decoratedSdk: RestClientSdk<TSMetadata>;

  module: {
    sibil: {
      proxy: SibilProxyClient;
      parameter?: SibilParameterClient;
      ticketingSpectacle?: SibilTicketingSpectacleClient;
      venueLieu?: SibilVenueLieuClient;
    };
  };

  get mapping(): Mapping {
    return this.#decoratedSdk.mapping;
  }

  get tokenStorage(): TokenStorageInterface<Token> {
    return this.#decoratedSdk.tokenStorage;
  }

  get logger(): undefined | Logger {
    return this.#decoratedSdk.logger;
  }

  get unitOfWork() {
    return this.#decoratedSdk.unitOfWork;
  }

  constructor(
    tokenStorage: TokenStorageInterface<Token>,
    config: Config,
    serializer?: SerializerInterface
  ) {
    // keep this assignation here and not in default parameter value as it will break the .d.ts file as a default parameter
    const decoratedSerializer: SerializerInterface =
      serializer || new EntitySerializer();

    this.#decoratedSdk = new RestClientSdk<TSMetadata>(
      tokenStorage,
      { ...defaultConfig.ticketing, ...config },
      mapping,
      decoratedSerializer
    );

    const mappingKeys = this.#decoratedSdk.mapping.getMappingKeys() as (keyof TSMetadata)[];

    const toSkip = [
      'sibilParameter',
      'sibilTicketingSpectacle',
      'sibilVenueLieu',
    ];
    mappingKeys.forEach((key: keyof TSMetadata) => {
      if (toSkip.indexOf(key) !== -1) {
        return;
      }

      /** @ts-expect-error -- create property dynamically to keep the old way : `ticketingSdk['seat'].find()` */
      this[key] = this.getRepository(key);
    });

    this.module = {
      sibil: {
        proxy: new SibilProxyClient(
          this.#decoratedSdk,
          new ClassMetadata('sibilProxy')
        ),
      },
    };

    if (mappingKeys.indexOf('sibilParameter') > -1) {
      this.module.sibil.parameter = this.getRepository('sibilParameter');
    }

    if (mappingKeys.indexOf('sibilTicketingSpectacle') > -1) {
      this.module.sibil.ticketingSpectacle = this.getRepository(
        'sibilTicketingSpectacle'
      );
    }

    if (mappingKeys.indexOf('sibilVenueLieu') > -1) {
      this.module.sibil.venueLieu = this.getRepository('sibilVenueLieu');
    }
  }

  getRepository(key: 'seatReserved'): SeatReservedClient;

  getRepository(key: 'invoice'): InvoiceClient;

  getRepository(key: 'contingent'): ContingentClient;

  getRepository(key: 'stockContingent'): StockContingentClient;

  getRepository(key: 'auditDocument'): AuditDocumentClient;

  getRepository(key: 'availableSeat'): AvailableSeatClient;

  getRepository(key: 'availableSeatModel'): AvailableSeatModelClient;

  getRepository(key: 'job'): JobClient;

  getRepository(key: 'bankAccount'): BankAccountClient;

  getRepository(key: 'masterTicket'): MasterTicketClient;

  getRepository(key: 'subscription'): SubscriptionClient;

  getRepository(key: 'subscriptionMaturity'): SubscriptionMaturityClient;

  getRepository(key: 'booking'): BookingClient;

  getRepository(key: 'tag'): TagClient;

  getRepository(key: 'customer'): CustomerClient;

  getRepository(key: 'cart'): CartClient;

  getRepository(key: 'cartItem'): CartItemClient;

  getRepository(key: 'notification'): NotificationClient;

  getRepository(key: 'ticket'): TicketClient;

  getRepository(key: 'contractAddendum'): ContractAddendumClient;

  getRepository(key: 'offerRule'): OfferRuleClient;

  getRepository(key: 'ticketingCategory'): TicketingCategoryClient;

  getRepository(key: 'contract'): ContractClient;

  getRepository(key: 'order'): OrderClient;

  getRepository(key: 'ticketing'): TicketingClient;

  getRepository(key: 'coupon'): CouponClient;

  getRepository(key: 'payment'): PaymentClient;

  getRepository(key: 'ticketPrice'): TicketPriceClient;

  getRepository(key: 'customerStatus'): CustomerStatusClient;

  getRepository(key: 'payout'): PayoutClient;

  getRepository(key: 'ticketPriceGroup'): TicketPriceGroupClient;

  getRepository(key: 'deskPaymentMethod'): DeskPaymentMethodClient;

  getRepository(key: 'scan'): ScanClient;

  getRepository(key: 'transactionGroup'): TransactionGroupClient;

  getRepository(key: 'deskSession'): DeskSessionClient;

  getRepository(key: 'season'): SeasonClient;

  getRepository(key: 'user'): UserClient;

  getRepository(key: 'deviceState'): DeviceStateClient;

  getRepository(key: 'seat'): SeatClient;

  getRepository(key: 'venue'): VenueClient;

  getRepository(key: 'eventDate'): EventDateClient;

  getRepository(key: 'logicalSeatConfig'): LogicalSeatConfigClient;

  getRepository(key: 'seatConfig'): SeatConfigClient;

  getRepository(key: 'version'): VersionClient;

  getRepository(key: 'eventDatePattern'): EventDatePatternClient;

  getRepository(key: 'seatGroup'): SeatGroupClient;

  getRepository(key: 'wallet'): WalletClient;

  getRepository(key: 'feature'): FeatureClient;

  getRepository(key: 'seatReserved'): SeatReservedClient;

  getRepository(key: 'sibilParameter'): SibilParameterClient;

  getRepository(key: 'sibilTicketingSpectacle'): SibilTicketingSpectacleClient;

  getRepository(key: 'sibilVenueLieu'): SibilVenueLieuClient;

  getRepository(key: 'sibilProxy'): SibilProxyClient;

  getRepository(key: 'organizationalUnit'): OrganizationalUnitClient;

  getRepository(key: 'sticker'): StickerClient;

  getRepository(key: 'scanTrack'): HidableClient<TSMetadata['scanTrack']>;

  getRepository(key: 'checkpoint'): HidableClient<TSMetadata['checkpoint']>;

  getRepository(key: 'bookingTrack'): BookingTrackClient;

  getRepository(
    key: 'providerTicketImportedFile'
  ): ProviderTicketImportedFileClient;

  getRepository(
    key: 'providerContingentMapping'
  ): ProviderContingentMappingClient;

  getRepository(
    key: 'providerTicketingMapping'
  ): ProviderTicketingMappingClient;

  getRepository(
    key: 'providerTicketPriceMapping'
  ): ProviderTicketPriceMappingClient;

  getRepository(key: 'providerSeatMapping'): ProviderSeatMappingClient;

  getRepository(
    key: 'providerSeatConfigMapping'
  ): ProviderSeatConfigMappingClient;

  getRepository<K extends keyof TSMetadata & string>(
    key: K
  ): AbstractClient<TSMetadata[K]>;

  getRepository<K extends keyof TSMetadata & string>(key: K): unknown {
    return this.#decoratedSdk.getRepository(key);
  }
}

export default TicketingSdk;
