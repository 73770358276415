import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import BookingTrackClient from '../client/BookingTrackClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { CART_TYPE } from '../types/CartTypes';
import Billing from './Billing';
import BookingTrackMode from './BookingTrackMode';
import Contract from './Contract';
import Form from './Form';
import NetworkEntity from './NetworkEntity';
import OfferRule from './OfferRule';
import Shipping from './Shipping';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type BookingTrackType = BaseEntity<'BookingTrack'> & {
  contract: null | EntityRelation<Contract>;
  name: null | string;
  cartType: null | CART_TYPE;
  bookingOptions: null | Map<
    'canConvertToReservation' | 'canConvertToOrder',
    boolean
  >;
  shippingList: null | List<EntityRelation<Shipping>>;
  billingList: null | List<EntityRelation<Billing>>;
  isDefault: null | boolean;
  bookingTrackMode: null | EntityRelation<BookingTrackMode>;
  ticketPriceList: null | List<EntityRelation<TicketPrice>>;
  offerRuleList: null | EntityRelation<OfferRule>;
  nbTicketPrices: null | number;
  nbOfferRules: null | number;
  formList: null | List<EntityRelation<Form>> | undefined;
};

class BookingTrack extends NetworkEntity<BookingTrackType>({
  '@id': null,
  '@type': 'BookingTrack',
  name: null,
  contract: null,
  cartType: null,
  bookingOptions: null,
  shippingList: null,
  billingList: null,
  isDefault: null,
  bookingTrackMode: null,
  nbTicketPrices: null,
  nbOfferRules: null,
  ticketPriceList: null,
  offerRuleList: null,
  formList: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BookingTrackType> = {
      '@id': null,
      '@type': 'BookingTrack',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/booking_tracks/', '') || '';
  }
}

BookingTrack.classMetadata = new ClassMetadata(
  'bookingTrack',
  'booking_tracks',
  /** @ts-expect-error -- method signature are incompatible */
  BookingTrackClient
);
BookingTrack.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('cartType'),
  new Attribute('bookingOptions', 'bookingOptions', 'object'),
  new Attribute('isDefault', 'isDefault', 'boolean'),
  new Attribute('nbTicketPrices', 'nbTicketPrices', 'integer'),
  new Attribute('nbOfferRules', 'nbOfferRules', 'integer'),
]);
BookingTrack.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'bookingTrackMode', 'bookingTrackMode'),
  new Relation(Relation.ONE_TO_MANY, 'shipping', 'shippingList'),
  new Relation(Relation.ONE_TO_MANY, 'billing', 'billingList'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(Relation.ONE_TO_MANY, 'offerRule', 'offerRuleList'),
  new Relation(Relation.ONE_TO_MANY, 'form', 'formList'),
]);

export default BookingTrack;
