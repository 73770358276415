import React, { FunctionComponent } from 'react';
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import MpdIcon from '../../../MpdIcon';
import { SingleSelectOption } from './utils';

const DropdownIndicator: FunctionComponent<DropdownIndicatorProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>
>> = (props: DropdownIndicatorProps<SingleSelectOption, false>) => {
  const { menuIsOpen } = props.selectProps;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <MpdIcon
        icon={menuIsOpen ? 'arrow-top' : 'arrow-down'}
        width="24"
        className="mpd-color-blue ml1"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
