import { Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum BILLING_TYPE {
  IMMEDIATE = 'immediate',
  DEFERED = 'defered',
  RECURRING = 'recurring',
}

export enum BILLING_POLICY {
  ANY = 'any',
  ALL = 'all',
}

export type BillingType = BaseEntity<'Billing'> & {
  contract: null | EntityRelation<Contract>;
  type: null | BILLING_TYPE;
  policy: null | BILLING_POLICY;
  recurringPaymentParameters: null | Map<string, unknown>;
};

class Billing extends NetworkEntity<BillingType>({
  '@id': null,
  '@type': 'Billing',
  contract: null,
  type: null,
  policy: null,
  recurringPaymentParameters: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BillingType> = {
      '@id': null,
      '@type': 'Billing',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/billings/', '') || '';
  }
}

Billing.classMetadata = new ClassMetadata(
  'billing',
  'billings',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Billing.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('policy'),
  new Attribute(
    'recurringPaymentParameters',
    'recurringPaymentParameters',
    'object'
  ),
]);
Billing.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Billing;
