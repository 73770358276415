/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
// Options
const defaultOptions = {
  animationType: 'full',
  openBoxWaitingTime: 300,
  animationTime: 400,
  closeBoxWaitingTime: 300,
  classPrefix: 'mpd-slidebox',
  closeElementHtml: null,
  boxClassName: '',
  containerClass: '',
  fixedElementList: ['html'],
  container: 'body',
  onOpen: null,
  onClose: null,
  onLoadBefore: null,
  onLoad: null,
  onChange: null,
  isReactPortal: false,
  pushContent: false, // Will push the content instead of going on top of it
  withOverlay: true,
  manageHistory: true,
};

let options = { ...defaultOptions };

let isDisplayReady = false;
let isContentReady = false;
let contentFetched;
let htmlInitialScroll = 0;
let initialized = false;

// DOM
let openUrlList = [];
let containerElement;
let overlayElement;
let boxElement;
let scrollWrapperElement;
let closeElement = null;
let contentElement;

/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
function htmlToElement(html) {
  const template = document.createElement('template');

  template.innerHTML = html.trim(); // Never return a text node of whitespace as the result

  return template.content.firstChild;
}

/* PUBLIC METHODS
 ********************************************** */
function init(customOptions) {
  if (!isInitialized()) {
    // Options
    options = { ...defaultOptions, ...customOptions };
    openUrlList.push(`${window.location}`);
    initialized = true;

    // DOM
    overlayElement = document.createElement('a');
    // eslint-disable-next-line no-script-url
    overlayElement.setAttribute('href', 'javascript:;');
    overlayElement.setAttribute('title', window.document.title);

    if (options.classPrefix) {
      overlayElement.classList.add(`${options.classPrefix}__overlay`);
    }

    boxElement = document.createElement('div');

    if (options.classPrefix) {
      boxElement.classList.add(`${options.classPrefix}__box`);
    }

    if (options.boxClassName) {
      boxElement.classList.add(`${options.boxClassName}`);
    }

    scrollWrapperElement = document.createElement('div');

    if (options.classPrefix) {
      scrollWrapperElement.classList.add(
        `${options.classPrefix}__scrollWrapper`
      );
    }

    boxElement.appendChild(scrollWrapperElement);

    if (options.closeElementHtml !== null) {
      closeElement = htmlToElement(options.closeElementHtml);
    } else {
      closeElement = document.createElement('button');
      closeElement.innerHTML = '<span>✖</span>';
    }

    if (closeElement) {
      if (options.classPrefix) {
        closeElement.classList.add(`${options.classPrefix}__close`);
      }

      scrollWrapperElement.appendChild(closeElement);
    }

    contentElement = document.createElement('div');

    if (options.classPrefix) {
      contentElement.classList.add(`${options.classPrefix}__content`);
    }

    contentElement.innerHTML = _getLoadingHTML();

    scrollWrapperElement.appendChild(contentElement);

    containerElement = document.createElement('div');

    if (options.classPrefix) {
      containerElement.classList.add(`${options.classPrefix}__container`);
    }

    if (options.containerClass) {
      containerElement.classList.add(`${options.containerClass}`);
    }

    containerElement.classList.add(
      `${options.classPrefix}--${options.animationType}`
    );

    if (options.withOverlay === true) {
      containerElement.append(overlayElement);
    }

    containerElement.append(boxElement);

    const containerElt = document.querySelector(options.container);

    containerElt.appendChild(containerElement);

    // Events
    const closeOptions = { manageHistory: options.manageHistory };

    overlayElement.addEventListener('click', () => {
      close(closeOptions);
    });

    if (closeElement) {
      closeElement.addEventListener('click', () => {
        close(closeOptions);
      });
    }

    // History
    if (options.manageHistory && _hasHistoryAPI()) {
      if (!_getOriginalUrlFromHistory(window.history)) {
        window.history.replaceState(_getStateToPush(), document.title);
      }

      window.addEventListener('popstate', (event) => {
        if (!event.target || !event.target.location) {
          return;
        }

        const url = `${event.target.location}`;
        const requestedOriginalUrl = _getOriginalUrlFromHistory(event);

        if (!requestedOriginalUrl && _isCurrentUrl(url)) {
          // Anchor
          return;
        }

        if (requestedOriginalUrl && requestedOriginalUrl !== getOriginalUrl()) {
          // Wrong navigation context: We simulate classic navigation
          window.location.href = url;

          return;
        }

        if (isOpen() && _isParentUrl(url)) {
          _close();

          return;
        }

        if (!isOpen() && _isValidUrl(url)) {
          _open(url);

          return;
        }

        // Error case: We also simulate classic navigation
        window.location.href = url;
      });
    }
  }
}

function destroy() {
  if (!isInitialized()) {
    return;
  }

  // Remove context class
  const htmlElement = document.getElementsByTagName('html')[0];

  htmlElement.style.paddingRight = null;
  htmlElement.classList.remove(`${options.classPrefix}--context`);

  const containerList = document.getElementsByClassName(
    `${options.classPrefix}__container`
  );

  for (let i = 0; i < containerList.length; i++) {
    const container = containerList[i];

    container.parentNode.removeChild(container);
  }

  openUrlList = [];
  initialized = false;
}

function isInitialized() {
  return initialized;
}

function open(url, openOptions = {}) {
  if (!_isValidUrl(url)) {
    return false;
  }

  if (isInitialized) {
    destroy();
  }

  init(openOptions);

  _open(url);

  if (openOptions.manageHistory !== false && _hasHistoryAPI()) {
    window.history.pushState(_getStateToPush(), null, url);
  }

  return true;
}

function openSlideboxElement(openOptions) {
  if (isInitialized) {
    destroy();
  }

  init(openOptions);
  openUrlList.push(window.location);
  contentElement.innerHTML = '';
  _animateContainerOpen();
}

function isOpen() {
  return openUrlList.length > 1;
}

function getOriginalUrl() {
  if (!isInitialized()) {
    return false;
  }

  return openUrlList[0];
}

function getParentUrl() {
  if (!isInitialized() || !isOpen()) {
    return false;
  }

  return openUrlList[openUrlList.length - 2];
}

function getCurrentUrl() {
  if (!isInitialized()) {
    return false;
  }

  return openUrlList[openUrlList.length - 1];
}

function close() {
  if (!isInitialized() || !isOpen()) {
    return false;
  }

  _close();

  if (options.manageHistory !== false && _hasHistoryAPI()) {
    // No history.back() in case of navigation inside the slidebox (anchors)
    window.history.pushState(_getStateToPush(), null, getCurrentUrl());
  }

  return true;
}

/* PRIVATE METHODS
 ********************************************** */
function _open(url) {
  openUrlList.push(url);
  _animateContainerOpen();

  fetch(url)
    .then((response) => response.text())
    .then((rawContent) => {
      const content = _callHandler('onLoadBefore', [rawContent]);

      _contentReady(content);
    })
    .catch((err) => {
      console.error(err);
      _contentReady(
        '<div class="mpd-block mpd-block-title">' +
          '<div class="mpd-block-content">' +
          '<h1>Oops! An Error Occurred</h1>' +
          '</div>' +
          '</div>'
      );
    });
  _callHandler('onOpen', [url, contentElement]);
}

function _displayReady() {
  isDisplayReady = true;

  if (options.isReactPortal) {
    _displayContent('');
    _callHandler('onOpen', [window.location, contentElement]);
  } else {
    contentElement.innerHTML = _getLoadingHTML();
    _displayContent(contentFetched);
  }
}

function _contentReady(content) {
  isContentReady = true;
  contentFetched = content;
  _displayContent(content);
}

function _displayContent(content) {
  if (isDisplayReady && isContentReady) {
    contentElement.innerHTML = content;
    _callHandler('onLoad', [getCurrentUrl(), contentElement]);
    _callHandler('onChange', [getCurrentUrl(), contentElement]);
    isDisplayReady = false;
    isContentReady = false;
    contentFetched = null;
  }
}

function _close() {
  const previousUrl = getCurrentUrl();

  openUrlList.pop();

  if (isOpen()) {
    const url = openUrlList.pop();

    _open(url);
  } else {
    _callHandler('beforeClose', [previousUrl, document]);

    if (!options.isReactPortal) {
      contentElement.innerHTML = '';
    }

    _callHandler('onClose', [previousUrl, document]);
    setTimeout(_animateBoxClose, options.closeBoxWaitingTime);
    _callHandler('onChange', [getCurrentUrl(), document]);
  }
}

function _hasHistoryAPI() {
  return window.history && window.history.pushState;
}

function _animateContainerOpen(parameters) {
  if (!containerElement.classList.contains(`${options.classPrefix}--active`)) {
    if (options.pushContent) {
      const containerElt = document.querySelector(options.container);

      containerElt.style.marginRight = `${containerElement.offsetWidth}px`;
      containerElt.style.transition = `margin-right ${options.animationTime}ms`;
    }

    containerElement.classList.add(`${options.classPrefix}--active`);
    _toggleBodyScroll();
    setTimeout(_animateBoxOpen, options.openBoxWaitingTime, parameters);
  } else {
    _displayReady(parameters);
  }
}

function _animateBoxOpen(parameters) {
  containerElement.classList.add(`${options.classPrefix}--open`);
  _onTransitionEnd(boxElement, () => {
    _displayReady(parameters);
  });
}

function _animateBoxClose() {
  const containerElt = document.querySelector(options.container);

  if (options.pushContent) {
    containerElt.style.marginRight = '';
  }

  containerElement.classList.remove(`${options.classPrefix}--open`);

  const previousUrl = getCurrentUrl();

  _onTransitionEnd(boxElement, () => {
    containerElement.classList.remove(`${options.classPrefix}--active`);
    _toggleBodyScroll();
    destroy();
    _callHandler('onAfterClose', [previousUrl, document]);

    if (options.pushContent) {
      containerElt.style.transition = '';
    }
  });
}

function _toggleBodyScroll() {
  // Prevent from fixed element to move when removing the body scrollbar
  for (let i = 0; i < options.fixedElementList.length; i++) {
    const fixedElement = document.querySelector(options.fixedElementList[i]);

    if (fixedElement) {
      fixedElement.style.paddingRight = isOpen()
        ? _getDeviceWidth() - fixedElement.offsetWidth
        : '0';
    }
  }

  if (isOpen()) {
    htmlInitialScroll = window.scrollY;
  } else {
    window.scrollTo(0, htmlInitialScroll);
  }

  const htmlElement = document.getElementsByTagName('html')[0];

  if (isOpen()) {
    htmlElement.classList.add(`${options.classPrefix}--context`);
  } else {
    htmlElement.classList.remove(`${options.classPrefix}--context`);
  }
}

function _callHandler(handler, args = []) {
  if (typeof options[handler] === 'function') {
    return options[handler].apply(window, args);
  }

  return null;
}

function _urlParser(url) {
  const parser = document.createElement('a');

  parser.href = url;

  const copy = parser;

  copy.hash = '';
  parser.uri = `${copy}`;

  return parser;
}

function _isValidUrl(rawNewUrl) {
  const newUrl = _urlParser(rawNewUrl);
  const currentUrl = _urlParser(getCurrentUrl());

  return newUrl.host === currentUrl.host && newUrl.uri !== currentUrl.uri;
}

function _isEquivalentUrl(rawNewUrl, referenceUrl) {
  const newUrl = _urlParser(rawNewUrl);
  const currentUrl = _urlParser(referenceUrl);

  return newUrl.host === currentUrl.host && newUrl.uri === currentUrl.uri;
}

function _isCurrentUrl(newUrl) {
  return _isEquivalentUrl(newUrl, getCurrentUrl());
}

function _isParentUrl(newUrl) {
  return _isEquivalentUrl(newUrl, getParentUrl());
}

function _getStateToPush() {
  return {
    slideboxOriginalUrl: getOriginalUrl(),
  };
}

function _getOriginalUrlFromHistory(context) {
  if (!context || !context.state || !context.state.slideboxOriginalUrl) {
    return false;
  }

  return context.state.slideboxOriginalUrl;
}

function _getDeviceWidth() {
  return window.innerWidth > 0 ? window.innerWidth : window.screen.width;
}

function _getLoadingHTML() {
  const loadingClasses = `${options.classPrefix}__loading loading loading--size-xl loading--absolute`;

  return `<div class="${loadingClasses}"><div></div><div></div><div></div></div>`;
}

function _onTransitionEnd(element, callback) {
  setTimeout(callback, options.animationTime);
}

/* EXPORT
 ********************************************** */
const Slidebox = {
  init,
  isInitialized,
  destroy,
  open,
  openSlideboxElement,
  isOpen,
  close,
  getParentUrl,
  getOriginalUrl,
  getCurrentUrl,
};

export default Slidebox;
