import React, { useCallback, useEffect, useState } from 'react';
import { List, Set } from 'immutable';
import { MpdLoader } from '@mapado/makeup';
import {
  assertRelationIsObject,
  assertRelationIsString,
  Contingent,
  StockContingent,
} from 'mapado-ticketing-js-sdk';
import {
  EditableStockContingentRow,
  getEntityId,
  usePrevious,
} from '@mapado/js-component';
import '../../../../styles/components/Sidebar/StockContingentSidebar.scss';
import { createPortal } from 'react-dom';
import DeleteContingentEntityModal from '../../../Modal/DeleteContingentEntityModal';
import { useLogicalSeatConfigAdminContext } from '../../../../contexts/LogicalSeatConfigAdminContext';
import { SelectBatchOfSeats } from '../../../../utils/drawers/FreeHandDrawSeatSelector';
import { ContingentEntity } from '../../../../propTypes';
import { useViewContext, View } from '../../../../contexts/ViewContext';
import { useTranslation } from '../../../../i18n';

function ContingentSidebarContent({
  selectedSeatIdListHash,
  onSelectedIdListChange,
  onUnSelectedContingent,
  selectBatchOfSeats,
  onUpdateContingentSeatList,
  removeAvailableSeatModelListContingent,
  updateStatus,
  contingentList,
  removeContingent,
}: ContingentPaneProps) {
  const { t } = useTranslation();
  const { selectedContingent, setSelectedContingent, handleSelectContingent } =
    useLogicalSeatConfigAdminContext();

  const { onSelectView } = useViewContext();

  const [contingentToDelete, setContingentToDelete] =
    useState<Contingent | null>(null);

  // reset parts of state on selecting contingent
  useEffect(() => {
    selectBatchOfSeats(Set());

    if (!selectedContingent) {
      onUnSelectedContingent();
    }
  }, [onUnSelectedContingent, selectedContingent, selectBatchOfSeats]);

  const selectedContingentId = selectedContingent?.get('@id');
  const prevSelectedContingentId = usePrevious(selectedContingent?.get('@id'));

  // update availableSeatModelList when selectedSeatIdSet changes
  useEffect(() => {
    async function doUpdateContingentSeatList(contingentId: string) {
      await onUpdateContingentSeatList(contingentId);
      onSelectedIdListChange();
      onSelectView(View.Contingent); // in case we are NOT in contingent View
      selectBatchOfSeats(Set());
    }

    if (
      selectedSeatIdListHash &&
      selectedContingentId &&
      prevSelectedContingentId === selectedContingentId
    ) {
      doUpdateContingentSeatList(selectedContingentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatIdListHash]);

  const handleRemoveContingent = useCallback(
    async (contingentEntity: ContingentEntity) => {
      const contingentId = getEntityId(contingentEntity);

      assertRelationIsString(contingentId, 'contingentId');

      const contingentIdx = contingentList.findIndex(
        (c) => getEntityId(c) === contingentId
      );

      if (contingentIdx !== -1) {
        await removeAvailableSeatModelListContingent(contingentId);
        removeContingent(contingentId);
        setContingentToDelete(null);
        setSelectedContingent(null);
        selectBatchOfSeats(Set());
      }
    },
    [
      contingentList,
      removeAvailableSeatModelListContingent,
      removeContingent,
      selectBatchOfSeats,
      setSelectedContingent,
    ]
  );

  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot && contingentToDelete !== null) {
    throw new Error('Unable to find element with id "modal-root"');
  }

  return (
    <>
      <div className="stock-contingent-content">
        <div className="mpd-seating-sidebar-header">
          <h3 className="mpd-seating-sidebar-header__title pt0">
            {t('seating.sidebar.contingent.title')}
          </h3>
        </div>
        {selectedContingent === null && (
          <p className="mpd-seating-sidebar-header__help">
            {t('seating.stock_contingent.sidebar.help.general')}
          </p>
        )}

        {selectedContingent !== null && (
          <p className="mpd-seating-sidebar-header__help">
            {t('seating.stock_contingent.sidebar.help.select_seats')}
          </p>
        )}

        {!contingentList ? (
          <div className="p4 pt0 txtcenter">
            <MpdLoader />
          </div>
        ) : (
          <div>
            <EditableStockContingentRow
              key="no-contingent"
              index={-1}
              stockContingent={
                new StockContingent({
                  '@id': `/v1/stock_contingents/999`,
                  '@type': 'StockContingent',
                  contingent: new Contingent({
                    '@id': `/v1/contingents/999`,
                    '@type': 'Contingent',
                    name: t('seating.contingent.no-contingent'),
                  }),
                })
              }
              isSelected={false}
              hasEventDate={false}
            />
            {contingentList.map((contingent, index) => {
              const contingentId = contingent.get('@id');

              assertRelationIsString(contingentId, 'contingentId');

              return (
                <EditableStockContingentRow
                  key={contingent.get('@id')}
                  index={index}
                  onDeleteStockContingent={(stockContingent) => {
                    assertRelationIsObject(
                      stockContingent.contingent,
                      'stockContingent.contingent'
                    );
                    setContingentToDelete(stockContingent.contingent);
                  }}
                  stockContingent={
                    new StockContingent({
                      '@id': `/v1/stock_contingents/${Number(contingentId)}`,
                      '@type': 'StockContingent',
                      contingent,
                    })
                  }
                  onSelect={(stockContingent) => {
                    assertRelationIsObject(
                      stockContingent.contingent,
                      'stockContingent.contingent'
                    );
                    handleSelectContingent(stockContingent.contingent);
                  }}
                  isSelected={
                    getEntityId(selectedContingent) === getEntityId(contingent)
                  }
                  hasEventDate={false}
                />
              );
            })}
          </div>
        )}
      </div>
      {modalRoot &&
        contingentToDelete !== null &&
        createPortal(
          <DeleteContingentEntityModal
            isDeleting={updateStatus === 'IN_PROGRESS'}
            onConfirm={handleRemoveContingent}
            onCancel={() => setContingentToDelete(null)}
            contingentEntity={contingentToDelete}
          />,
          modalRoot
        )}
    </>
  );
}

export type OwnProps = {
  onSelectedIdListChange: () => void;
  onUnSelectedContingent: () => void;
};

export type StateProps = {
  selectedSeatIdListHash: string | null;
  updateStatus: string | null;
  contingentList: List<Contingent>;
};

export type DispatchProps = {
  selectBatchOfSeats: SelectBatchOfSeats;
  onUpdateContingentSeatList: (contingentId: string) => void;
  removeAvailableSeatModelListContingent: (contingentId: string) => void;
  removeContingent: (contingentId: string) => void;
};

type ContingentPaneProps = OwnProps & StateProps & DispatchProps;

export default ContingentSidebarContent;
