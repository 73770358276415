import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import type { BaseEntity, EntityRelation, PartialEntity } from '../..';
import SibilVenueLieuClient from '../../../client/Module/SibilVenueLieuClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import type Venue from '../../Venue';

export type SibilVenueLieuType = BaseEntity<
  'SibilVenueLieu',
  `/v1/module/sibil/venue_lieux/${number}`
> & {
  venue: null | EntityRelation<Venue>;
  lieuId: null | number;
};

const defaultValues: SibilVenueLieuType = {
  '@id': null,
  '@type': 'SibilVenueLieu',
  venue: null,
  lieuId: null,
};

class SibilVenueLieu extends NetworkEntity<SibilVenueLieuType>(defaultValues) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<SibilVenueLieuType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): null | string {
    return (
      this.get('@id')?.replace('/v1/module/sibil/venue_lieux/', '') || null
    );
  }
}

SibilVenueLieu.classMetadata = new ClassMetadata(
  'sibilVenueLieu',
  'module/sibil/venue_lieux',
  /** @ts-expect-error -- method signature are incompatible */
  SibilVenueLieuClient
);

SibilVenueLieu.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('lieuId', 'lieuId', 'integer'),
]);

SibilVenueLieu.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'venue', 'venue'),
]);

export default SibilVenueLieu;
