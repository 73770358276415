import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 16" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/voir" fillRule="nonzero" transform="translate(-4 -7)">
        <path
          id="Shape"
          d="M18.9 15c0 2.2-1.8 4-4 4-3 0-5-3.3-3.4-6l1.5 1.5c-.4 1.3.6 2.5 1.9 2.5 1.1 0 2-.9 2-2 0-1.3-1.2-2.3-2.5-1.9l-1.5-1.5c2.7-1.6 6 .3 6 3.4m6.9-.4c-.1-.3-3.3-7.6-10.9-7.6S4.1 14.3 4 14.6l1.8.8c0-.1 2.8-6.4 9.1-6.4 6.2 0 9 6.1 9.1 6.4zm-1.7 3.6L22.5 17c-1.4 1.8-3.9 4-7.6 4-3.8 0-6.3-2.3-7.7-4.1l-1.6 1.2c1.7 2.3 4.7 4.9 9.3 4.9 4.5 0 7.5-2.6 9.2-4.8"
        />
      </g>
    </g>
  </svg>
);
export default SvgEye;
