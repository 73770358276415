import React, { ReactElement } from 'react';
import { MpdLoader } from '@mapado/makeup';
import { SeatGroupType } from '../../../propTypes';
import SeatGroupRow from './SeatGroupRow';
import { useSelectedSeatGroupContext } from '../../../contexts/SelectedSeatGroupContext';
import { useSeatGroupSelector } from '../../../utils/selectors';
import { useTranslation } from '../../../i18n';

interface Props {
  getNbSeats: (seatGroup: SeatGroupType) => number;
}

export default function SeatGroupList({ getNbSeats }: Props): ReactElement {
  const { t } = useTranslation();
  const { selectedSeatGroup, handleSelectSeatGroup } =
    useSelectedSeatGroupContext();
  const seatGroupList = useSeatGroupSelector();

  return (
    <div className="stock-contingent-content">
      <div className="mpd-seating-sidebar-header">
        <h3 className="mpd-seating-sidebar-header__title pt0">
          {t('seating.sidebar.seat_group.title')}
        </h3>
      </div>

      {selectedSeatGroup === null && (
        <p className="mpd-seating-sidebar-header__help">
          {t('seating.seat_group.sidebar.help.general')}
        </p>
      )}

      {selectedSeatGroup !== null && (
        <p className="mpd-seating-sidebar-header__help">
          {t('seating.seat_group.sidebar.help.select_seats')}
        </p>
      )}

      {!seatGroupList ? (
        <div className="p4 pt0 txtcenter">
          <MpdLoader />
        </div>
      ) : (
        <div>
          {seatGroupList.map((seatGroup) => (
            <SeatGroupRow
              key={seatGroup['@id']}
              nbSeats={getNbSeats(seatGroup)}
              seatGroup={seatGroup}
              isSelected={
                selectedSeatGroup !== null &&
                selectedSeatGroup['@id'] === seatGroup['@id']
              }
              onSelect={handleSelectSeatGroup}
            />
          ))}
        </div>
      )}
    </div>
  );
}
