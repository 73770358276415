export enum CUSTOMER_TYPE {
  CONTACT = 'contact',
  ORGANIZATION = 'organization',
}

export type GdprInformationType = {
  isOptin: boolean;
  ipAddress: string | null;
  message: string | null;
  timestamp: number | null;
  countryOfResidence: string | null;
};

export type ShoppingDataType = {
  [key: string]: unknown;
};

export type DiffusionListType = {
  [key: string]: boolean;
};

export type CustomerFormValuesType = {
  [key: string]: unknown;
  additionnalInformation?: {
    [key: string]: unknown;
  } | null;
  diffusionList?: {
    [key: string]: unknown;
  } | null;
  shoppingData?: ShoppingDataType | null;
};
