import React, { ReactElement } from 'react';
import { MpdLoader } from '@mapado/makeup';

function SeatingLoader(): ReactElement {
  return (
    <div className="mpd-seating__app context-reservation" id="mpd-seating__app">
      <div className="mpd-seating__app--is-loading">
        <MpdLoader />
      </div>
    </div>
  );
}

export default SeatingLoader;
