import clone from 'clone';
import { List } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import EventDatePatternClient from '../client/EventDatePatternClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { EVENT_DATE_TYPE_LIST } from './EventDate';
import NetworkEntity from './NetworkEntity';
import SeatConfig from './SeatConfig';
import TicketPrice from './TicketPrice';
import Ticketing from './Ticketing';
import { BaseEntity, PartialEntity } from '.';

export type EventDatePatternType = BaseEntity<'EventDatePattern'> & {
  seatConfig: null | SeatConfig;
  ticketing: null | Ticketing;
  schedule: null | List<unknown>;
  ticketPriceList: null | List<TicketPrice>;
  relativeSaleStartDate: null | Moment;
  relativeSaleEndDate: null | Moment;
  totalStock: null | number;
  status: null | string;
  type: null | EVENT_DATE_TYPE_LIST;
};

class EventDatePattern extends NetworkEntity<EventDatePatternType>({
  '@id': null,
  '@type': 'EventDatePattern',

  seatConfig: new SeatConfig(),
  ticketing: new Ticketing(),
  schedule: List(),
  ticketPriceList: List<TicketPrice>(),
  relativeSaleStartDate: null,
  relativeSaleEndDate: null,
  totalStock: null,
  status: null,
  type: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<EventDatePatternType> = {
      '@id': null,
      '@type': 'EventDatePattern',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/event_date_patterns/', '') || '';
  }
}

EventDatePattern.classMetadata = new ClassMetadata(
  'eventDatePattern',
  'event_date_patterns',
  /** @ts-expect-error -- method signature are incompatible */
  EventDatePatternClient
);
EventDatePattern.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('schedule', 'schedule', 'array'),
  new Attribute('relativeSaleStartDate', 'relativeSaleStartDate', 'datetime'),
  new Attribute('relativeSaleEndDate', 'relativeSaleEndDate', 'datetime'),
  new Attribute('totalStock', 'totalStock', 'integer'),
  new Attribute('status'),
  new Attribute('type'),
]);
EventDatePattern.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
]);

export default EventDatePattern;
