import { OFFER_COLOR } from '../components/MpdOffer';

// Solid colors
export enum SOLID_COLOR {
  DARK_VIOLET = '#6C00C1',
  DULL_LAVENDER = '#B290FA',
  BASH_FUL_PINK = '#D00073',
  BRINK_PINK = '#FD5D83',
  ROSE_BUD = '#FFA79B',
  MAROON = '#811902',
  MUDDY_WATERS = '#BC865F',
  FADE_JADE = '#377266',
  KHAKI = '#A3A777',
  SALAD_GREEN = '#A9D136',
  NEON_CARROT = '#FD912E',
  PASTEL_ORANGE = '#FFBC3F',
  TURQUOISE = '#4ED3F0',
}

// Gradiant colors
export const GRADIANT_COLOR = {
  // Extends offer colors
  ...OFFER_COLOR,
} as const;

export const SOLID_COLOR_LIST = Object.values(SOLID_COLOR);
export const GRADIANT_COLOR_LIST = Object.values(GRADIANT_COLOR);
