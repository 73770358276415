const IMAGE_TYPE_IMAGE = 'image';
const IMAGE_TYPE_POSTER = 'poster';

export const ALL_IMAGE_TYPES = [IMAGE_TYPE_POSTER, IMAGE_TYPE_IMAGE];

export interface Media extends Map<string, unknown> {
  get(key: 'path'): string;
  get(key: 'height'): undefined | number;
  get(key: 'width'): undefined | number;
  get(
    key: 'imageType'
  ): undefined | typeof IMAGE_TYPE_IMAGE | typeof IMAGE_TYPE_POSTER;
}
