import { connect } from 'react-redux';
import OrderReplacement, {
  StateProps,
  DispatchProps,
  OrderReplacementProps,
} from '../../components/Main/OrderReplacement';
import { initOrderReplacement } from '../../actions/AppActions';
import { getSelectableSeatIdSetForReplacement } from '../../utils/seatSelectable';
import {
  selectOrMoveSeat,
  selectBatchOfSeats,
  resetState,
} from '../../actions/SeatActions';
import type { RootState } from '../../reducers';
import { isLoadedAvailableSeatList } from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => {
  return {
    seatConfig: state.seating.get('seatConfig'),
    isReady: isLoadedAvailableSeatList(state),
    getSelectableSeatIdSet: getSelectableSeatIdSetForReplacement,
    selectedSeatIdSet: state.seating.get('selectedSeatIdSet'),
  };
};

const mapDispatchToProps = {
  resetState,
  initOrderReplacement,
  selectBatchOfSeats,
  selectOrMoveSeat,
};

export default connect<
  StateProps,
  DispatchProps,
  OrderReplacementProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(OrderReplacement);
