import URI from 'urijs';
import { PartialEntity } from '../entity';
import { SubscriptionType } from '../entity/Subscription';
import { createEntity } from '../entityFactory';
import { TSMetadata } from '../mapping';
import AbstractClient, { FieldsParameter } from './AbstractClient';

export enum SUBSCRIPTION_PAYMENT_TYPE_FILTER {
  SEPA = 'sepa',
  CARD = 'card',
}

class SubscriptionClient extends AbstractClient<TSMetadata['subscription']> {
  getPathBase(): string {
    return '/v1/subscriptions';
  }

  findByCode(
    id: number,
    code: string,
    fields: FieldsParameter,
    queryParams: Record<string, number | string | boolean> = {}
  ): Promise<unknown> {
    const uri = URI(`${this.getPathBase()}/${id}/${code}`);
    const params = this._mergeFieldsIntoParams(queryParams, fields);
    uri.addSearch(params);

    return this.authorizedFetch(uri)
      .then(
        (response) =>
          (response.json() as unknown) as PartialEntity<SubscriptionType>
      )
      .then((sub) => createEntity(sub));
  }

  sendSEPAMandateByMail(
    id: number,
    email: string,
    locale: string
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/sepa_mandate_email`);

    return this.authorizedFetch(url, {
      headers: {
        'Accept-Language': locale,
      },
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  }

  getSEPAMandatePDF(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/sepa_mandate.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  sendPaymentScheduleByMail(
    id: number,
    email: string,
    locale: string
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/payment_schedule_email`);

    return this.authorizedFetch(url, {
      headers: {
        'Accept-Language': locale,
      },
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  }

  getPaymentSchedulePDF(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/payment_schedule.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }
}

export default SubscriptionClient;
