import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 19" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="menu-copy" transform="translate(-33 -629)">
        <g id="Group-26" transform="translate(33 629)">
          <path
            id="Fill-4"
            d="M11.136 14.849c.498-1.838.555-6.177.533-8.79a13 13 0 0 0-.03-.792c.74-1.194 1.997-2.578 2.417-2.765 1.333-.593 1.425 1.155 1.509 3.334.11 2.867-.225 6.622-.18 8.106.029.981.126 2.998 1.112 4.127C17.533 19.254 20 17.6 20 17.6c-.735-1.239-2.205-1.334-1.928-6.687.32-6.181.423-8.684-.593-10.078-.523-.716-2.287-1.493-4.058.097a13.5 13.5 0 0 0-2.102 2.397c-.191-.685-.43-1.164-.638-1.433-.544-.702-1.887-1.44-3.658.15-.776.697-1.382 1.704-1.818 2.616l-.048-.32C4.73 1.725 3.537.535 2.25 1.203 1.484 1.601.668 2.433.112 3.027c-.328.354.155.698.277.763s.258-.033.377-.198c.264-.364.68-.714.955-.79.617-.173 1.096.619 1.179 6.323.061 4.351-.494 7.282-.87 8.272-.376.988-.954 1.656.199 1.456s2.143-1.272 2.409-1.554.387-.628.65-2.784c.188-1.544.258-4.753.126-7.41.698-1.248 1.71-2.934 2.176-3.113.75-.287 1.441 1.158 1.525 3.336.11 2.867-.322 7.717-.233 8.485.09.768 1.584-.117 2.254-.964"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLogo;
