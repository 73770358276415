import React, { createContext, Dispatch, ReactNode, useContext } from 'react';
import { Action, State } from './reducer';
import { Value } from './types';

type MpdSelectContextType = {
  state: State;
  dispatch: Dispatch<Action>;
  selectedValue: Value;
  onChangeValue: (value: Value) => void;
};

const MpdSelectContext = createContext<MpdSelectContextType | null>(null);

type Props = MpdSelectContextType & { children: ReactNode };

function MpdSelectContextProvider({
  state,
  dispatch,
  selectedValue,
  onChangeValue,
  children,
}: Props): JSX.Element {
  return (
    <MpdSelectContext.Provider
      value={{
        state,
        dispatch,
        selectedValue,
        onChangeValue,
      }}
    >
      {children}
    </MpdSelectContext.Provider>
  );
}

export function useMpdSelectContext(): MpdSelectContextType {
  const context = useContext(MpdSelectContext);

  if (!context) {
    throw new Error(
      'No MpdSelect context. Does your component has a MpdSelectContextProvider up in the tree ?'
    );
  }

  return context;
}

export default MpdSelectContextProvider;
