import { List, Map } from 'immutable';
import moment from 'moment';
import {
  assertRelationIsDefined,
  assertRelationIsListOfObject,
  assertRelationIsNullOrListOfObject,
  assertRelationIsNullOrObject,
  assertRelationIsObject,
  Cart,
  CartItemWithOffer,
} from 'mapado-ticketing-js-sdk';
import { Offer } from '../../types';

export const getCartItemWithOfferFacialValue = (
  ciwoList: List<CartItemWithOffer>,
  unit = false
) => {
  return ciwoList.reduce((carry, ciwo) => {
    const facialValue = ciwo.get('facialValue') || 0;
    const quantity = ciwo.get('quantity') || 1;

    return carry + facialValue * (unit ? 1 : quantity);
  }, 0);
};

const getSingleApplicationFacialValue = (
  offerApplication: OfferApplication,
  unit = false
): number =>
  getCartItemWithOfferFacialValue(
    offerApplication.get('cartItemWithOfferList'),
    unit
  );

const getFacialValue = (
  offerApplicationList: List<OfferApplication>,
  unit = false
): number =>
  offerApplicationList.reduce(
    (curr, app) => curr + getSingleApplicationFacialValue(app, unit),
    0
  );

const sortClusterList = (a: OfferCluster, b: OfferCluster): number => {
  const valueA = getFacialValue(a.get('offerApplicationList'), true);
  const valueB = getFacialValue(b.get('offerApplicationList'), true);

  switch (true) {
    case valueA > valueB:
      return -1;
    case valueA < valueB:
      return 1;
    default:
      break;
  }

  const nameA: string = (a.getIn(['offer', 'name']) as string | null) || '';
  const nameB: string = (b.getIn(['offer', 'name']) as string | null) || '';

  return nameA.localeCompare(nameB);
};

function sortCartItemByEventDateStartDate(
  a: CartItemWithOffer,
  b: CartItemWithOffer
) {
  assertRelationIsObject(a.ticketPrice, 'a.ticketPrice');
  assertRelationIsObject(b.ticketPrice, 'b.ticketPrice');

  const {
    ticketPrice: { eventDate: eventDateA },
  } = a;

  const {
    ticketPrice: { eventDate: eventDateB },
  } = b;

  assertRelationIsNullOrObject(eventDateA, 'eventDateA');
  assertRelationIsNullOrObject(eventDateB, 'eventDateB');

  const dateA = eventDateA?.get('startDate');
  const dateB = eventDateB?.get('startDate');

  if (!dateA && dateB) {
    return 1;
  }

  if (dateA && !dateB) {
    return -1;
  }

  if (dateA && dateB) {
    if (dateA < dateB) {
      return -1;
    }

    if (dateA > dateB) {
      return 1;
    }
  }

  return 0;
}

function sortCartItemById(a: CartItemWithOffer, b: CartItemWithOffer) {
  assertRelationIsObject(a.cartItem, 'a.cartItem');
  assertRelationIsObject(b.cartItem, 'b.cartItem');

  const cartItemAId = a.cartItem.get('@id');
  const cartItemBId = b.cartItem.get('@id');

  assertRelationIsDefined(cartItemAId, 'cartItem.@id');
  assertRelationIsDefined(cartItemBId, 'cartItem.@id');

  if (cartItemAId < cartItemBId) {
    return -1;
  }

  if (cartItemAId > cartItemBId) {
    return 1;
  }

  return 0;
}

export function sortCartItemWithOfferListByEventDateStartDateAndId(
  a: CartItemWithOffer,
  b: CartItemWithOffer
): number {
  const sortByDate = sortCartItemByEventDateStartDate(a, b);

  if (sortByDate === 0) {
    return sortCartItemById(a, b);
  }

  return sortByDate;
}

/** @deprecated -- use `sortCartItemWithOfferListByEventDateStartDateAndId` instead */
function sortCartItemWithOfferListForDisplay(
  a: CartItemWithOffer,
  b: CartItemWithOffer
): number {
  const dateA = a.get('date');
  const dateB = b.get('date');

  // dates
  if (!dateA && dateB) {
    return -1;
  }

  if (dateA && !dateB) {
    return 1;
  }

  if (dateA && dateB) {
    if (moment(dateA) < moment(dateB)) {
      return -1;
    }

    if (moment(dateA) > moment(dateB)) {
      return 1;
    }
  }

  // ticketingTitle
  const ticketingTitleA = a.get('ticketingTitle');
  const ticketingTitleB = b.get('ticketingTitle');

  if (!ticketingTitleA && ticketingTitleB) {
    return -1;
  }

  if (ticketingTitleB && !ticketingTitleA) {
    return 1;
  }

  if (
    ticketingTitleA !== null &&
    ticketingTitleB !== null &&
    ticketingTitleA !== ticketingTitleB
  ) {
    return ticketingTitleA.localeCompare(ticketingTitleB);
  }

  // facialValue
  const valueA = a.get('facialValue');
  const valueB = b.get('facialValue');

  if (valueA !== null && valueB !== null) {
    if (valueA < valueB) {
      return -1;
    }

    if (valueA > valueB) {
      return 1;
    }
  }

  // name
  const nameA = a.get('name') || '';
  const nameB = b.get('name') || '';

  if (!nameA && nameB) {
    return -1;
  }

  if (nameA && !nameB) {
    return 1;
  }

  if (!nameA && !nameB) {
    return 0;
  }

  return nameA.localeCompare(nameB);
}

export interface OfferClusterMap<
  T = {
    offerClusterList: List<OfferCluster>;
    noOfferCartItemWithOfferList: List<CartItemWithOffer>;
  },
> extends Map<keyof T, T[keyof T]> {
  get<K extends keyof T>(key: K): T[K];
}

export interface OfferApplication<
  T = {
    cartItemWithOfferList: List<CartItemWithOffer>;
    applicationId: string;
    offer: Offer;
  },
> extends Map<keyof T, T[keyof T]> {
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
}

type TmpOfferClusterKey =
  | 'offerApplicationMap'
  | 'offerApplicationList'
  | 'offer'
  | 'clusterId';
export interface OfferCluster<
  T = {
    offerApplicationMap: Map<string, OfferApplication>;
    offerApplicationList: List<OfferApplication>;
    offer: Offer;
    clusterId: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> extends Map<keyof T, any> {
  // get(key: 'offerApplicationMap', notSetValue: never): string; // Map<unknown, unknown>;

  get<K extends keyof T>(key: K): T[K];
  // get(key: unknown, notSetValue: never): never;
  set<K extends keyof T>(key: K, value: T[K]): this;
}

interface TmpOfferClusterMap extends Map<string, OfferCluster> {
  // get(key: string): TmpOfferCluster;
  // set<K extends keyof T>(key: K, value: T[K]): this;

  getIn(
    keyPath: [string, 'offerApplicationMap']
  ): Map<string, OfferApplication>;
  getIn(keyPath: [string, 'cartItemWithOfferList']): List<CartItemWithOffer>;
  getIn(keyPath: Iterable<unknown>): unknown;
}

export function getOfferApplicationList(
  cart: Cart
): List<OfferApplication> | null {
  assertRelationIsNullOrListOfObject(
    cart.cartItemWithOfferList,
    'cart.cartItemWithOfferList'
  );

  if (!cart.cartItemWithOfferList) {
    return null;
  }

  const ciwoListSortedById = cart.cartItemWithOfferList.sort(sortCartItemById);

  const ciwoListGroupedByApplicationId = ciwoListSortedById
    .filter((ciwo) => ciwo.applicationId) // keep only cartItemWithOffer that have applicationId
    .groupBy((ciwo) => ciwo.get('applicationId'));

  // shape ciwoListGroupedByApplicationId as OfferApplication
  const offerApplicationList = ciwoListGroupedByApplicationId
    .keySeq()
    .map((id: string | null) => {
      const ciwoListSorted = ciwoListGroupedByApplicationId
        .get(id)
        ?.sort(sortCartItemWithOfferListByEventDateStartDateAndId); // cartItem sort by date then cartItem id
      const offer = ciwoListSorted?.first()?.get('offer');

      // @ts-expect-error -- This should be an offer application
      return Map({
        applicationId: id,
        cartItemWithOfferList: ciwoListSorted,
        offer,
      }) as OfferApplication;
    });

  return offerApplicationList.toList();
}

export function getNoOfferCartItemWithOfferList(
  cart: Cart
): List<CartItemWithOffer> | null {
  assertRelationIsNullOrListOfObject(
    cart.cartItemWithOfferList,
    'cart.cartItemWithOfferList'
  );

  if (!cart.cartItemWithOfferList) {
    return null;
  }

  const ciwoWithoutOfferSorted = cart.cartItemWithOfferList
    .filter((ciwo) => !ciwo.applicationId)
    .sort(sortCartItemWithOfferListByEventDateStartDateAndId); // cartItem sort by date then cartItem id

  return ciwoWithoutOfferSorted;
}

/** @deprecated -- we don't want cluster anymore
 * use `getOfferApplicationList` to get equivalent of `offerClusterList.offerApplicationList` but without cluster grouping
 * and `getNoOfferCartItemWithOfferList` get equivalent of `noOfferCartItemWithOfferList`
 * */
export function generateOfferClusterMap(cart: Cart): OfferClusterMap {
  // @ts-expect-error -- This is the right object
  let tmpOfferClusterMap: TmpOfferClusterMap = Map();

  if (cart.cartItemWithOfferList) {
    assertRelationIsListOfObject(
      cart.cartItemWithOfferList,
      'cart.cartItemWithOfferList'
    );

    cart.cartItemWithOfferList.forEach((ciwo: CartItemWithOffer) => {
      const clusterId = ciwo.clusterId || 'no-offer';

      if (!tmpOfferClusterMap.get(clusterId)) {
        const tmp: OfferCluster = Map<TmpOfferClusterKey, unknown>({
          offerApplicationMap: Map<string, OfferApplication>(),
          offerApplicationList: List(),
          offer: ciwo.offer,
          clusterId,
        });

        tmpOfferClusterMap = tmpOfferClusterMap.set(clusterId, tmp);
      }

      let offerApplicationMap: Map<string, OfferApplication> =
        tmpOfferClusterMap.getIn([clusterId, 'offerApplicationMap']);
      const applicationId = ciwo.get('applicationId') || 'no-application';

      if (!offerApplicationMap.get(applicationId)) {
        // @ts-expect-error -- This is an offer application
        const offerApplication: OfferApplication = Map({
          cartItemWithOfferList: List<CartItemWithOffer>(),
          applicationId,
          offer: ciwo.offer,
        });

        offerApplicationMap = offerApplicationMap.set(
          applicationId,
          offerApplication
        );
      }

      const cartItemWithOfferList = (
        offerApplicationMap.getIn([
          applicationId,
          'cartItemWithOfferList',
        ]) as List<CartItemWithOffer>
      ).push(ciwo);

      offerApplicationMap = offerApplicationMap.setIn(
        [applicationId, 'cartItemWithOfferList'],
        cartItemWithOfferList
      );

      tmpOfferClusterMap = tmpOfferClusterMap
        .setIn([clusterId, 'offerApplicationMap'], offerApplicationMap)
        .setIn(
          [clusterId, 'offerApplicationList'],
          offerApplicationMap.toList()
        );
    });
  }

  let noApplicationMapOfferClusterMap = Map<string, OfferCluster>();

  tmpOfferClusterMap.forEach((cluster, key) => {
    let newCluster = cluster.delete('offerApplicationMap');

    newCluster.get('offerApplicationList').forEach((application, idx) => {
      const sortedCartItemWithOfferList = application
        .get('cartItemWithOfferList')
        .sort(sortCartItemWithOfferListForDisplay);

      newCluster = newCluster.setIn(
        ['offerApplicationList', idx, 'cartItemWithOfferList'],
        sortedCartItemWithOfferList
      );
    });
    noApplicationMapOfferClusterMap = noApplicationMapOfferClusterMap.set(
      key,
      newCluster
    );
  });

  const offerClusterList = noApplicationMapOfferClusterMap
    .toList()
    .filter((offerCluster) => offerCluster.get('clusterId') !== 'no-offer')
    .sort(sortClusterList);

  const noOfferCartItemWithOfferList =
    (noApplicationMapOfferClusterMap
      .toList()
      .filter((offerCluster) => offerCluster.get('clusterId') === 'no-offer')
      .getIn([
        0,
        'offerApplicationList',
        0,
        'cartItemWithOfferList',
      ]) as List<CartItemWithOffer>) || List<CartItemWithOffer>();

  const offerClusterMap: OfferClusterMap = Map({
    offerClusterList,
    noOfferCartItemWithOfferList,
  });

  return offerClusterMap;
}

export const testables = { sortCartItemWithOfferListForDisplay };
