import { Attribute, ClassMetadata } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type CustomerFieldType = BaseEntity<'CustomerField'> & {
  slug: null | string;
  isGeneric: null | boolean;
  // all other props
};

class CustomerField extends NetworkEntity<CustomerFieldType>({
  '@id': null,
  '@type': 'CustomerField',
  slug: null,
  isGeneric: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CustomerFieldType> = {
      '@id': null,
      '@type': 'CustomerField',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/customer_fields/', '') || '';
  }
}

CustomerField.classMetadata = new ClassMetadata(
  'customerField',
  'customer_fields',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
CustomerField.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('isGeneric', 'isGeneric', 'boolean'),
]);

export default CustomerField;
