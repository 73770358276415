import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import TicketingSdk, {
  assertRelationIsObject,
  StockContingent,
} from 'mapado-ticketing-js-sdk';
import { MpdSingleSelect } from '@mapado/makeup';

interface Props {
  eventDateId: number;
  sdk: TicketingSdk;
  onChange: (contingentId: null | string) => void;
  value: null | string;
}

type Option = { label: string; value: null | string };

export default function ContingentSelector({
  eventDateId,
  sdk,
  onChange,
  value,
}: Props): JSX.Element {
  const [stockContingentList, setStockContingentList] = useState<
    List<StockContingent>
  >(List());

  useEffect(() => {
    sdk
      .getRepository('stockContingent')
      .findBy(
        {
          eventDate: eventDateId,
        },
        ['@id', { contingent: ['@id', 'name'] }] as const
      )
      .then((response) => {
        setStockContingentList(response.getMembers());
      });
  }, [eventDateId, sdk]);

  const options: Array<Option> = [
    { label: 'Non contingenté', value: null },
    ...stockContingentList
      .map((stockContingent) => {
        assertRelationIsObject(
          stockContingent.contingent,
          'stockContingent.contingent'
        );

        return {
          value: stockContingent['@id'],
          label: stockContingent.contingent.name || '',
        };
      })
      .toArray(),
  ];

  const getOption = (innerValue: string | null): Option | undefined =>
    options.find((o) => o.value === innerValue);

  return (
    <div className="colums">
      <div className="column is-4">Contingent</div>
      <div className="column is-4">
        <MpdSingleSelect
          value={getOption(value)}
          options={options}
          onChange={(selectedOption) => {
            onChange(selectedOption?.value || null);
          }}
        />
      </div>
    </div>
  );
}
