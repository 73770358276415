export enum FIELD_TYPE {
  CRM = 'crm',
  FORM = 'form',
}

export enum RELATED_TO_TYPE {
  ORGANIZATION = 'organization',
  CONTACT = 'contact',
  SHOPPING = 'shopping',
}

export enum FIELD_PREFIX_SLUG {
  ORGANIZATION = 'organization.',
  CONTACT = 'contact.',
  SHOPPING = 'shopping.',
  CONTACT_DIFFUSION_LIST = 'contact.diffusionListOK.',
  ORGANIZATION_DIFFUSION_LIST = 'organization.diffusionListOK.',
}

export const DIFFUSION_LIST_KEY = 'diffusionListOK.';
