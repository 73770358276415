import URI from 'urijs';
import { AvailableSeatModelStatus } from '../entity/AvailableSeatModel';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

export type BulkRequestBody = {
  'hydra:member': Array<BulkRequestMember>;
};

export type BulkRequestMember = {
  '@id': null | string;
  '@type': 'AvailableSeatModel';
  contingent?: null | string;
  seatGroup?: null | string;
  status?: AvailableSeatModelStatus;
};

function filterBulkRequestBody(body: BulkRequestBody) {
  if (!body['hydra:member']) {
    return {};
  }

  return {
    'hydra:member': body['hydra:member'].map(
      (member: BulkRequestMember): BulkRequestMember => {
        const out: BulkRequestMember = {
          '@id': member['@id'],
          '@type': member['@type'],
          contingent: member.contingent,
          seatGroup: member.seatGroup,
          status: member.status,
        };

        return out;
      }
    ),
  };
}

class AvailableSeatModelClient extends AbstractClient<
  TSMetadata['availableSeatModel']
> {
  getPathBase(): string {
    return '/v1/available_seat_models';
  }

  getDefaultGroups(): Array<string> {
    return ['available_seat_model_read', 'available_seat_model_write'];
  }

  bulkRequest(
    method: string,
    body: BulkRequestBody,
    fields: FieldsParameter
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterBulkRequestBody(body)),
    });
  }

  putBulk(
    body: BulkRequestBody,
    fields: FieldsParameter = []
  ): Promise<Response> {
    return this.bulkRequest('PUT', body, fields);
  }
}

export default AvailableSeatModelClient;
