import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExternalProviderFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 11 13" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M10.303 8.342c0-1.58-1.441-2.861-3.22-2.861H3.22C1.442 5.481 0 6.761 0 8.341v3.822h10.303zM7.605 2.518C7.605 1.128 6.45 0 5.029 0S2.453 1.127 2.453 2.518 3.606 5.035 5.03 5.035c1.422 0 2.576-1.127 2.576-2.517M7.25 8.35a.46.46 0 0 1-.17.352L5.35 10.127a.564.564 0 0 1-.702 0L2.92 8.703a.46.46 0 0 1-.171-.352c0-.138.065-.264.17-.35a.564.564 0 0 1 .703 0l.855.705V6.25h1.044v2.456L6.377 8a.564.564 0 0 1 .702 0c.106.087.171.213.171.351"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExternalProviderFilled;
