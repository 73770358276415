import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClockNoHands = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 30" className="mpd-icon" {...props}>
    <g id="icon/horloge" fillRule="evenodd">
      <path
        id="icon-SVG"
        fillRule="nonzero"
        d="M15 3c6.627 0 12 5.373 12 12s-5.373 12-12 12S3 21.627 3 15 8.373 3 15 3m0 2C9.477 5 5 9.477 5 15s4.477 10 10 10 10-4.477 10-10S20.523 5 15 5m0 4v6h6v2h-8V9z"
      />
    </g>
  </svg>
);
export default SvgClockNoHands;
