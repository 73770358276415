import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import OrderItem from './OrderItem';
import Refund from './Refund';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type RefundOrderItemType = BaseEntity<'RefundOrderItem'> & {
  orderItem: null | EntityRelation<OrderItem>;
  refund: null | EntityRelation<Refund>;
};

class RefundOrderItem extends NetworkEntity<RefundOrderItemType>({
  '@id': null,
  '@type': 'RefundOrderItem',
  orderItem: null,
  refund: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<RefundOrderItemType> = {
      '@id': null,
      '@type': 'RefundOrderItem',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/refund_order_items/', '') || '';
  }
}

RefundOrderItem.classMetadata = new ClassMetadata(
  'refundOrderItem',
  'refund_order_items',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
RefundOrderItem.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
]);
RefundOrderItem.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'orderItem', 'orderItem'),
  new Relation(Relation.MANY_TO_ONE, 'refund', 'refund'),
]);

export default RefundOrderItem;
