import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="4 6 22 19" className="mpd-icon" {...props}>
    <path
      id="path-1"
      d="M26 6v5h-2v14H6V11H4V6zm-4 5H8v12h14zm-6 2-.019 5h2.01l-2.995 3.265L12 18h1.963l.018-5zm8-5H6v1h18z"
    />
  </svg>
);
export default SvgArchive;
