import clone from 'clone';
import { List } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Contract from './Contract';
import Coupon from './Coupon';
import Deposit from './Deposit';
import Lettering from './Lettering';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import Payment, { PAYMENT_PROVIDER } from './Payment';
import Refund from './Refund';
import TransactionGroup from './TransactionGroup';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TransactionType = BaseEntity<'Transaction'> & {
  debit: null | number;
  credit: null | number;
  currency: null | string;
  name: null | string;
  nameTranslationKey: null | string;
  operationType: null | string;
  valueDate: null | Moment;
  createdAt: null | Moment;
  label: null | string;
  order: null | EntityRelation<Order>;
  contract: null | EntityRelation<Contract>;
  accountType: null | string;
  transactionGroup: null | EntityRelation<TransactionGroup>;
  lettering: null | EntityRelation<Lettering>;
  deposit: null | EntityRelation<Deposit>;
  payment: null | EntityRelation<Payment>;
  refund: null | EntityRelation<Refund>;
  lettered: null | boolean;
  hasCouponUseApplication: null | boolean;
  otherTransactionGroupTransactionList: null | List<
    EntityRelation<Transaction>
  >;
  externalAmount: null | number;
  externalId: null | string;
  externalProviderBalance: null | number;
  sourceExternalTransaction: null | EntityRelation<Transaction>;
  initialSourceExternalTransaction: null | EntityRelation<Transaction>;
  rescheduledFromExternalTransaction: null | EntityRelation<Transaction>;
  provider: null | PAYMENT_PROVIDER;
  cashCoupon: null | EntityRelation<Coupon>;
  revertedTransaction: null | EntityRelation<Transaction>;
};

class Transaction extends NetworkEntity<TransactionType>({
  '@id': null,
  '@type': 'Transaction',
  debit: null,
  credit: null,
  currency: null,
  name: '',
  nameTranslationKey: '',
  operationType: null,
  valueDate: null,
  createdAt: null,
  label: null,
  order: null,
  contract: null,
  accountType: null,
  transactionGroup: null,
  lettering: null,
  deposit: null,
  payment: null,
  refund: null,
  lettered: null,
  hasCouponUseApplication: null,
  otherTransactionGroupTransactionList: null,
  sourceExternalTransaction: null,
  initialSourceExternalTransaction: null,
  rescheduledFromExternalTransaction: null,
  externalAmount: null,
  externalId: null,
  externalProviderBalance: null,
  provider: null,
  cashCoupon: null,
  revertedTransaction: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TransactionType> = {
      '@id': null,
      '@type': 'Transaction',
    }
  ) {
    const data = clone(val);

    const tz =
      typeof data.contract === 'object' && data.contract !== null
        ? data.contract.timezone
        : null;

    data.valueDate = parseDate(data.valueDate, tz);
    data.createdAt = parseDate(data.createdAt, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/transactions/', '') || '';
  }

  /**
   * @deprecated
   */
  getLocalValueDate(): null | Moment {
    if (!this.valueDate) {
      return null;
    }

    const tz = this.getIn(['contract', 'timezone']);
    if (!tz) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- moment timezone issue */
    return this.valueDate.clone().tz(tz);
  }

  getAmount(): number {
    const credit: number = this.credit || 0;
    const debit: number = this.debit || 0;

    return credit - debit;
  }
}

Transaction.classMetadata = new ClassMetadata(
  'transaction',
  'transactions',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Transaction.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('debit'),
  new Attribute('credit'),
  new Attribute('externalAmount', 'externalAmount', 'integer'),
  new Attribute(
    'externalProviderBalance',
    'externalProviderBalance',
    'integer'
  ),
  new Attribute('currency'),
  new Attribute('externalId'),
  new Attribute('name'),
  new Attribute('provider'),
  new Attribute('nameTranslationKey'),
  new Attribute('label'),
  new Attribute('operationType'),
  new Attribute('accountType'),
  new Attribute('lettered', 'lettered', 'boolean'),
  new Attribute(
    'hasCouponUseApplication',
    'hasCouponUseApplication',
    'boolean'
  ),
  new Attribute('valueDate', 'valueDate', 'datetime'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
]);
Transaction.classMetadata.setRelationList([
  new Relation(
    Relation.MANY_TO_ONE,
    'transaction',
    'sourceExternalTransaction'
  ),
  new Relation(
    Relation.MANY_TO_ONE,
    'transaction',
    'initialSourceExternalTransaction'
  ),
  new Relation(
    Relation.MANY_TO_ONE,
    'transaction',
    'rescheduledFromExternalTransaction'
  ),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'lettering', 'lettering'),
  new Relation(Relation.MANY_TO_ONE, 'deposit', 'deposit'),
  new Relation(Relation.MANY_TO_ONE, 'payment', 'payment'),
  new Relation(Relation.MANY_TO_ONE, 'refund', 'refund'),
  new Relation(Relation.MANY_TO_ONE, 'coupon', 'cashCoupon'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'transactionGroup', 'transactionGroup'),
  new Relation(Relation.ONE_TO_ONE, 'transaction', 'revertedTransaction'),
  new Relation(
    Relation.ONE_TO_MANY,
    'transaction',
    'otherTransactionGroupTransactionList'
  ),
]);

export default Transaction;
