import { Map } from 'immutable';
import { PagedCollection, Booking } from 'mapado-ticketing-js-sdk';
import {
  REQUEST_BOOKING_COLLECTION,
  RECEIVE_BOOKING_COLLECTION,
  RECEIVE_BOOKING,
  INIT_CLEANUP_STATE,
  RESET_BOOKING,
  SET_BOOKING_STATUS,
} from '../../reducers/types';

export interface BookingStateObject {
  bookingCollection: PagedCollection<Booking>;
  'bookingCollection-fetch-status': Map<'status' | 'error', null | string>;
  hasMoreBookings: boolean;
  currentPage: number;
  currentSearch: string;
}

export interface ImmutableMap<T> extends Map<keyof T, unknown> {
  // we do not extends `Map` here to force type checking
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
  setIn(
    keyPath: ['bookingCollection-fetch-status', 'status'], // only allow setIn for status
    value: string
  ): this;
  getIn(
    searchKeyPath: ['bookingCollection-fetch-status', 'status'],
    notSetValue?: unknown
  ): null | string;
  toJS(): Record<keyof T, unknown>;
}

export type BookingState = ImmutableMap<BookingStateObject>;

interface Test {
  foo: string;
}

Map<keyof Test, unknown>({
  foo: 'foo',
});

export const initialBookingState: BookingState = Map<
  keyof BookingStateObject,
  unknown
>({
  bookingCollection: new PagedCollection<Booking>({
    'hydra:member': [],
  }),
  'bookingCollection-fetch-status': Map<'status' | 'error', null | string>({
    status: null,
    error: null,
  }),
  hasMoreBookings: true,
  currentPage: 0,
  currentSearch: '',
}) as BookingState; // force seat state as we create a Map, not a BookingState but they are in fact compatible

type InitCleanupStateAction = {
  type: typeof INIT_CLEANUP_STATE;
};
type RequestBookingCollectionAction = {
  type: typeof REQUEST_BOOKING_COLLECTION;
  page?: number;
  search?: string;
};
type ReceiveBookingCollectionAction = {
  type: typeof RECEIVE_BOOKING_COLLECTION;
  page?: number;
  search?: string;
  bookingCollection: PagedCollection<Booking>;
};

type ReceiveBookingAction = {
  type: typeof RECEIVE_BOOKING;
  bookingCollection: PagedCollection<Booking>;
};
type ResetBookingAction = {
  type: typeof RESET_BOOKING;
};

type BookingStatusAction = {
  type: typeof SET_BOOKING_STATUS;
  status: '' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';
  error?: string;
};

export type BookingActionTypes =
  | InitCleanupStateAction
  | RequestBookingCollectionAction
  | ReceiveBookingCollectionAction
  | ReceiveBookingAction
  | ResetBookingAction
  | BookingStatusAction;

function bookingReducer(
  state: BookingState,
  action: BookingActionTypes
): BookingState {
  switch (action.type) {
    case INIT_CLEANUP_STATE:
      return initialBookingState;

    case REQUEST_BOOKING_COLLECTION: {
      const nextState = state
        .set(
          'bookingCollection-fetch-status',
          Map<'status' | 'error', null | string>({
            status: 'IN_PROGRESS',
            error: null,
          })
        )
        .set('currentPage', action.page || 0)
        .set('currentSearch', action.search || '');

      if (action.page === 1) {
        const newBookingCollection = new PagedCollection<Booking>({
          'hydra:member': [],
        });
        // assertBookingCollectionIsValidForState(newBookingCollection);

        return nextState
          .set('hasMoreBookings', true)
          .set('bookingCollection', newBookingCollection);
      }

      return nextState;
    }

    case RECEIVE_BOOKING_COLLECTION: {
      const { bookingCollection, page, search } = action;

      if ((search || '') !== state.get('currentSearch')) {
        return state;
      }

      const hasMore = !!bookingCollection.getIn(['hydra:view', 'hydra:next']);
      const nextState = state
        .setIn(['bookingCollection-fetch-status', 'status'], 'SUCCEEDED')
        .set('hasMoreBookings', hasMore)
        .set('currentPage', page || 0);

      // assertBookingCollectionIsValidForState(bookingCollection);

      if (page === 1) {
        return nextState.set('bookingCollection', bookingCollection);
      }

      return nextState.set(
        'bookingCollection',
        nextState.get('bookingCollection').merge(bookingCollection)
      );
    }

    case RECEIVE_BOOKING: {
      // assertBookingCollectionIsValidForState(action.bookingCollection);

      return state.set(
        'bookingCollection',
        state.get('bookingCollection').merge(action.bookingCollection)
      );
    }

    case RESET_BOOKING: {
      const nextState = state
        .set(
          'bookingCollection-fetch-status',
          Map<'status' | 'error', null | string>({
            status: null,
            error: null,
          })
        )
        .set(
          'bookingCollection',
          new PagedCollection<Booking>({
            'hydra:member': [],
          })
        )
        .set('currentPage', 0);

      return nextState;
    }

    case SET_BOOKING_STATUS: {
      return state.set(
        'bookingCollection-fetch-status',
        Map<'status' | 'error', null | string>({
          status: action.status,
          error: action.error,
        })
      );
    }

    default:
      return state;
  }
}

export default bookingReducer;
export const testables = { initialState: initialBookingState };
