import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 19" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M13.12 9.535 4.027.123 1.075 2.977l6.242 6.46L.86 15.68l2.853 2.952z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRight;
