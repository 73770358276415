import React, { ReactNode } from 'react';
import cn from 'classnames';

export enum LayoutMode {
  DEFAULT = 'default',
  SPLIT = 'split',
  TABS = 'tabs',
  NAVIGATION = 'menu',
}

type Props = {
  header: ReactNode;
  children: ReactNode;
  className?: string;
} & (
  | {
      mode?: LayoutMode.DEFAULT | LayoutMode.TABS;
      leftContent?: never;
      action?: never;
      isHalf?: never;
      backgroundReversed?: boolean;
      sidebar?: ReactNode;
      hasInnerWidth?: never;
    }
  | {
      mode: LayoutMode.SPLIT | LayoutMode.NAVIGATION;
      leftContent: ReactNode;
      action?: ReactNode;
      isHalf?: boolean;
      backgroundReversed?: boolean;
      sidebar?: never;
      // max-width added to the content column, could be added to all Modes in the future
      hasInnerWidth?: boolean;
    }
);

function MpdLayout({
  mode = LayoutMode.DEFAULT,
  header,
  sidebar,
  leftContent,
  action,
  isHalf,
  hasInnerWidth = false,
  backgroundReversed,
  children,
  className,
}: Props): JSX.Element | null {
  const containerClassnames = cn('mpd-layout', className);

  if ([LayoutMode.SPLIT, LayoutMode.NAVIGATION].includes(mode)) {
    const columnClasses = cn('column', {
      'has-action': !!action,
    });

    return (
      <div
        className={cn(containerClassnames, 'columns is-gapless', {
          'mpd-layout--split': mode === LayoutMode.SPLIT,
          'mpd-layout--reversed': backgroundReversed,
          'mpd-layout--navigation': mode === LayoutMode.NAVIGATION,
        })}
      >
        <div className={columnClasses}>
          <div className="mpd-layout__left-content">
            {header && <div className="mpd-layout__header">{header}</div>}
            {leftContent}
          </div>
          {action && <div className="mpd-layout__action">{action}</div>}
        </div>
        <div
          className={cn('column', {
            'has-inner-width': hasInnerWidth,
            'is-6': isHalf,
            'is-8': !isHalf,
          })}
        >
          {children}
        </div>
      </div>
    );
  }

  const headerClassnames = cn('mpd-layout__header', {
    'mpd-layout__header--tabs': mode === LayoutMode.TABS,
  });

  return (
    <div
      className={cn(containerClassnames, {
        'mpd-layout--sidebar': !!sidebar,
      })}
    >
      <div className={headerClassnames}>{header}</div>
      <div className="mpd-layout__content">
        {children}
        {sidebar && <div className="mpd-layout__sidebar">{sidebar}</div>}
      </div>
    </div>
  );
}

export default MpdLayout;
