import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLongArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 31 30" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="m17.414.858 12.728 12.728a2 2 0 0 1 0 2.828L17.414 29.142a2 2 0 0 1-2.828-2.828L23.9 17H.728v-4H23.9l-9.314-9.314A2 2 0 1 1 17.414.858"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongArrowRight;
