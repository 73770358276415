import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 25" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-526 -1044)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="loupe"
            d="M438.667 225.667a8 8 0 1 0 0 16 8 8 0 0 0 0-16m8.426 14.54 5.392 5.393-1.885 1.885-5.392-5.392a10.62 10.62 0 0 1-6.541 2.24c-5.891 0-10.667-4.775-10.667-10.666S432.776 223 438.667 223c5.89 0 10.666 4.776 10.666 10.667a10.62 10.62 0 0 1-2.24 6.54"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgMagnifyingGlass;
