import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AvailableSeatModelClient from '../client/AvailableSeatModelClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { STATUS_AVAILABLE, STATUS_DISMISSED } from './AvailableSeat';
import Contingent from './Contingent';
import LogicalSeatConfig from './LogicalSeatConfig';
import NetworkEntity from './NetworkEntity';
import Seat from './Seat';
import SeatGroup from './SeatGroup';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type AvailableSeatModelStatus =
  | null
  | typeof STATUS_DISMISSED
  | typeof STATUS_AVAILABLE;

export type AvailableSeatModelType = BaseEntity<'AvailableSeatModel'> & {
  seat: null | EntityRelation<Seat>;
  seatGroup: null | EntityRelation<SeatGroup>;
  logicalSeatConfig: null | EntityRelation<LogicalSeatConfig>;
  contingent: null | EntityRelation<Contingent>;
  position: null | number;
  status: null | typeof STATUS_DISMISSED | typeof STATUS_AVAILABLE;
};

class AvailableSeatModel extends NetworkEntity<AvailableSeatModelType>({
  '@id': null,
  '@type': 'AvailableSeatModel',
  seat: null,
  seatGroup: null,
  logicalSeatConfig: null,
  contingent: null,
  position: null,
  status: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<AvailableSeatModelType> = {
      '@id': null,
      '@type': 'AvailableSeatModel',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/available_seat_models/', '') || '';
  }
}

AvailableSeatModel.classMetadata = new ClassMetadata(
  'availableSeatModel',
  'available_seat_models',
  /** @ts-expect-error -- method signature are incompatible */
  AvailableSeatModelClient
);
AvailableSeatModel.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('position', 'position', 'number'),
  new Attribute('status'),
]);
AvailableSeatModel.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seat', 'seat'),
  new Relation(Relation.MANY_TO_ONE, 'logicalSeatConfig', 'logicalSeatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'seatGroup', 'seatGroup'),
  new Relation(Relation.MANY_TO_ONE, 'contingent', 'contingent'),
]);

export default AvailableSeatModel;
