import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class TicketPriceGroupClient extends HidableClient<
  TSMetadata['ticketPriceGroup']
> {
  getPathBase(): string {
    return '/v1/ticket_price_groups';
  }
}

export default TicketPriceGroupClient;
