import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 13" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="m9.535 12.668 9.412-9.094L16.093.623l-6.46 6.242L3.39.407.438 3.26z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowDown;
