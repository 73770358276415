import { MomentInstance as TicketingSdkMomentInstance } from 'mapado-ticketing-js-sdk';
import { MomentInstance as JsComponentMomentInstance } from '@mapado/js-component';
import moment from 'moment';
import { MomentInstance } from '../../../src';

// eslint-disable-next-line import/prefer-default-export
export function configureMoment(): void {
  moment.locale('fr');

  TicketingSdkMomentInstance.setMoment(moment);
  JsComponentMomentInstance.setMoment(moment);
  MomentInstance.setMoment(moment);
}
