import React from 'react';
import type { Toast } from './constants';
import MpdToast from './MpdToast';

type Props = {
  toastList: Array<Toast>;
};

export default function ToastList({ toastList }: Props): JSX.Element {
  return (
    <div className="mpd-toast-list">
      {toastList.map((toast) => (
        <MpdToast
          title={toast.title}
          message={toast.message}
          type={toast.type}
          action={toast.action}
        />
      ))}
    </div>
  );
}
