import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class LogicalSeatConfigClient extends HidableClient<
  TSMetadata['logicalSeatConfig']
> {
  getPathBase(): string {
    return '/v1/logical_seat_configs';
  }

  getDefaultGroups(): Array<string> {
    return ['logical_seat_config_read', 'logical_seat_config_write'];
  }
}

export default LogicalSeatConfigClient;
