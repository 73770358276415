import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatFoldableFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 6 6" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M1.75 0a1 1 0 0 0-1 1v3h4.5V1a1 1 0 0 0-1-1zM5 6a1 1 0 0 0 1-1v-.5H0V5a1 1 0 0 0 1 1z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatFoldableFilled;
