import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEyeClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 11" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M12 8.965V11h-2V8.948A10.7 10.7 0 0 1 7.494 8.4l-.571 1.995L5 9.846l.655-2.288a12 12 0 0 1-1.996-1.447L1.392 8.308 0 6.872l2.268-2.199C.896 3.037.244 1.533.2 1.4L2 .6c.1.3 2.899 6.397 9.096 6.4h.008C17.4 6.997 20.2.7 20.2.6l1.8.8c-.047.14-.776 1.822-2.321 3.567l1.965 1.905-1.392 1.436-2.005-1.943c-.552.462-1.169.899-1.854 1.28l.63 2.2-1.922.55-.556-1.94c-.78.259-1.628.438-2.545.51"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeClosed;
