// TODO : find why we need to adjust seat position by 10px
export const seatDimensions = {
  size: 30,
  iconSize: 24,
  iconZoomedSize: 30,
  gutter: 8,
} as const;

const getFloat = (x: string | number): number =>
  typeof x === 'string' ? parseFloat(x) : x;

export function apiXToActualX(x: string | number): number {
  return seatDimensions.size * getFloat(x) - 10;
}

export function apiYToActualY(y: string | number): number {
  return -seatDimensions.size * getFloat(y) - 10;
}

export function actualYToApiY(y: string | number): number {
  return getFloat(y) / -seatDimensions.size;
}

export function actualXToApiX(x: string | number): number {
  return getFloat(x) / seatDimensions.size;
}
