import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class SeatConfigClient extends HidableClient<TSMetadata['seatConfig']> {
  getPathBase(): string {
    return '/v1/seat_configs';
  }

  getDefaultGroups(): Array<string> {
    return ['seat_config_read', 'seat_config_write'];
  }
}

export default SeatConfigClient;
