import { connect } from 'react-redux';
import CartReplacement, {
  CartReplacementProps,
  StateProps,
  DispatchProps,
} from '../../components/Main/CartReplacement';
import { initCartReplacement } from '../../actions/AppActions';
import {
  selectOrMoveSeat,
  selectBatchOfSeats,
  resetState,
} from '../../actions/SeatActions';
import { getSelectableSeatIdSetForReplacement } from '../../utils/seatSelectable';
import type { RootState } from '../../reducers';
import { isLoadedAvailableSeatList } from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => {
  return {
    seatConfig: state.seating.get('seatConfig'),
    isReady: isLoadedAvailableSeatList(state),
    getSelectableSeatIdSet: getSelectableSeatIdSetForReplacement,
    selectedSeatIdSet: state.seating.get('selectedSeatIdSet'),
  };
};

const mapDispatchToProps = {
  initCartReplacement,
  selectBatchOfSeats,
  selectOrMoveSeat,
  resetState,
};

export default connect<
  StateProps,
  DispatchProps,
  CartReplacementProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(CartReplacement);
