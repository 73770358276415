/* eslint-disable import/prefer-default-export */
import {
  LogicalSeatConfig,
  AvailableSeatModelBulkRequestMember,
  AvailableSeatModelStatus,
  assertRelationIsDefined,
} from 'mapado-ticketing-js-sdk';
import { Collection, List, Set } from 'immutable';
import { getEntityId, getShortId } from '@mapado/js-component';
import {
  AVAILABLE_SEAT_MODEL_FIELDS_LITE,
  LOGICAL_SEAT_CONFIG_FIELDS,
} from './fields';
import TicketingSdkInstance from '../TicketingSdkInstance';
import {
  REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST,
  SET_AVAILABLE_SEAT_MODEL_LIST,
  SET_LOGICALSEATCONFIG_DATA,
  UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE,
  UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS,
} from '../reducers/types';
import type {
  SeatThunkAction,
  SetLogicalSeatConfigDataAction,
} from '../reducers/SeatReducer';
import getAvailableSeatModelListForLogicalSeatConfig from './getAvailableSeatModelListForLogicalSeatConfig';
import {
  AvailableSeatModelType,
  Id,
  LogicalSeatConfigType,
} from '../propTypes';
import { RootState } from '../reducers';
import {
  availableSeatModelSelector,
  getAvailableSeatModelListByContingentId,
  getAvailableSeatModelListBySeatGroupId,
  getJsEntityId,
  selectedSeatIdSetSelector,
} from '../utils/selectors';

export function setLogicalSeatConfigData(
  lSeatConfig: LogicalSeatConfig | LogicalSeatConfigType
): SetLogicalSeatConfigDataAction {
  return {
    type: SET_LOGICALSEATCONFIG_DATA,
    logicalSeatConfig:
      // @ts-expect-error -- custom serializer typing error
      typeof lSeatConfig.toJS === 'function' ? lSeatConfig.toJS() : lSeatConfig,
  };
}

function updateAvailableSeatModelList(
  availableSeatModelList: List<AvailableSeatModelBulkRequestMember>,
  updatedFieldName: 'contingent' | 'seatGroup' | 'status'
): SeatThunkAction {
  return async (dispatch) => {
    dispatch({
      type: REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST,
    });

    try {
      await TicketingSdkInstance.getSdk()
        .getRepository('availableSeatModel')
        .putBulk(
          { 'hydra:member': availableSeatModelList.toArray() },
          AVAILABLE_SEAT_MODEL_FIELDS_LITE
        );

      return dispatch({
        type: UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS,
        availableSeatModelList,
        updatedFieldName,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE,
        error,
      });
    }
  };
}

export function updateAvailableSeatModelListStatus(
  status: AvailableSeatModelStatus
): SeatThunkAction | SeatThunkAction<Promise<LogicalSeatConfigType>> {
  return async (dispatch, getState: () => RootState) => {
    const availableSeatModelList = availableSeatModelSelector(getState());

    if (!availableSeatModelList) {
      return Promise.resolve();
    }

    const avsmIdListToSwitchStatus =
      filterAvailableSeatModelInSelectedSeatIdSet(
        availableSeatModelList,
        selectedSeatIdSetSelector(getState())
      );

    const availableSeatModelListForPut = avsmIdListToSwitchStatus.map(
      (
        avsm
      ): {
        '@id': string;
        '@type': 'AvailableSeatModel';
        status: AvailableSeatModelStatus;
      } => ({
        '@id': avsm['@id'],
        '@type': avsm['@type'],
        status,
      })
    );

    return dispatch(
      updateAvailableSeatModelList(availableSeatModelListForPut, 'status')
    );
  };
}

export function removeAvailableSeatModelListContingent(
  contingentId: string
): SeatThunkAction | SeatThunkAction<Promise<LogicalSeatConfigType>> {
  return async (dispatch, getState: () => RootState) => {
    const availableSeatModelListByContingentId =
      getAvailableSeatModelListByContingentId(getState());

    if (!availableSeatModelListByContingentId) {
      return Promise.resolve();
    }

    const availableSeatModelListForPut = availableSeatModelListByContingentId
      .get(contingentId)
      ?.toList()
      .map((avsm) => ({
        '@id': avsm['@id'],
        '@type': avsm['@type'],
        contingent: null,
      }));

    if (!availableSeatModelListForPut) {
      return Promise.resolve();
    }

    return dispatch(
      updateAvailableSeatModelList(availableSeatModelListForPut, 'contingent')
    );
  };
}

export function updateAvailableSeatModelListContingent(
  contingentId: string
): SeatThunkAction | SeatThunkAction<Promise<LogicalSeatConfigType>> {
  return async (dispatch, getState: () => RootState) => {
    const availableSeatModelList = getState().seating.get(
      'availableSeatModelList'
    );

    const availableSeatModelListByContingentId =
      getAvailableSeatModelListByContingentId(getState());

    if (!availableSeatModelListByContingentId || !availableSeatModelList) {
      return Promise.resolve();
    }

    const availableSeatListWithSelectedContingent =
      availableSeatModelListByContingentId.get(contingentId) ||
      List<AvailableSeatModelType>();
    const availableSeatListWithoutSelectedContingent =
      availableSeatModelList.filter(
        (avsm) =>
          !availableSeatListWithSelectedContingent.find(
            (cavsm) => getJsEntityId(cavsm) === getJsEntityId(avsm)
          )
      );

    // add contingent to availableSeatModels whose seat IS IN selectedSeatIdSet
    // and whose contingent id IS DIFFERENT FROM contingentId param
    const avsmIdListToAddContingentTo =
      filterAvailableSeatModelInSelectedSeatIdSet(
        availableSeatListWithoutSelectedContingent,
        selectedSeatIdSetSelector(getState())
      );

    // remove contingent from availableSeatModels whose seat IS NOT IN selectedSeatIdSet
    // and whose contingent id IS THE SAME as contingentId param
    const avsmIdListToRemoveContingentFrom: List<AvailableSeatModelType> =
      filterAvailableSeatModelInSelectedSeatIdSet(
        availableSeatListWithSelectedContingent,
        selectedSeatIdSetSelector(getState())
      );

    const availableSeatModelListForPut = avsmIdListToAddContingentTo
      .map((avsm) => ({
        '@id': avsm['@id'],
        '@type': avsm['@type'],
        contingent: contingentId,
      }))
      .concat(
        avsmIdListToRemoveContingentFrom.map((avsm) => ({
          '@id': avsm['@id'],
          '@type': avsm['@type'],
          contingent: null,
        }))
      );

    return dispatch(
      updateAvailableSeatModelList(availableSeatModelListForPut, 'contingent')
    );
  };
}

export function updateAvailableSeatModelListSeatGroup(
  seatGroupId: string
): SeatThunkAction | SeatThunkAction<Promise<LogicalSeatConfigType>> {
  return async (dispatch, getState: () => RootState) => {
    const availableSeatModelList = getState().seating.get(
      'availableSeatModelList'
    );
    const availableSeatModelListBySeatGroupId =
      getAvailableSeatModelListBySeatGroupId(getState());

    if (!availableSeatModelList || !availableSeatModelListBySeatGroupId) {
      return Promise.resolve();
    }

    const availableSeatListWithoutSeatGroup = availableSeatModelList.filter(
      (avsm) =>
        !availableSeatModelListBySeatGroupId
          .get(seatGroupId)
          ?.find((sgavsm) => getJsEntityId(avsm) === getJsEntityId(sgavsm))
    );

    // add seatGroup to availableSeatModels whose seat IS IN selectedSeatIdSet
    // and whose seatGroup id IS DIFFERENT FROM seatGroupId param
    const avsmIdListToAddSeatGroup =
      filterAvailableSeatModelInSelectedSeatIdSet(
        availableSeatListWithoutSeatGroup,
        selectedSeatIdSetSelector(getState())
      );

    const availableSeatModelListForPut = avsmIdListToAddSeatGroup.map(
      (avsm) => ({
        '@id': avsm['@id'],
        '@type': avsm['@type'],
        seatGroup: seatGroupId,
      })
    );

    return dispatch(
      updateAvailableSeatModelList(availableSeatModelListForPut, 'seatGroup')
    );
  };
}

export function getLogicalSeatConfigData(
  logicalSeatConfigId: Id,
  fetchRelatedEntities = false
): SeatThunkAction | SeatThunkAction<Promise<LogicalSeatConfigType>> {
  return async (dispatch) => {
    const logicalSeatConfig = await TicketingSdkInstance.getSdk()
      .getRepository('logicalSeatConfig')
      .find(logicalSeatConfigId, LOGICAL_SEAT_CONFIG_FIELDS);

    if (fetchRelatedEntities) {
      dispatch(
        getAvailableSeatModelListForLogicalSeatConfigAction(logicalSeatConfigId)
      );
    }

    return dispatch(setLogicalSeatConfigData(logicalSeatConfig));
  };
}

function getAvailableSeatModelListForLogicalSeatConfigAction(
  logicalSeatConfigId: Id
): SeatThunkAction {
  return async (dispatch, getState) => {
    const contract = getState().seating.get('contract');
    const contractId = getEntityId(contract);

    assertRelationIsDefined(contractId, 'contractId');

    const availableSeatModelList =
      await getAvailableSeatModelListForLogicalSeatConfig(
        logicalSeatConfigId,
        contractId
      );

    return dispatch({
      type: SET_AVAILABLE_SEAT_MODEL_LIST,
      availableSeatModelList,
    });
  };
}

function filterAvailableSeatModelInSelectedSeatIdSet(
  availableSeatModelList:
    | List<AvailableSeatModelType>
    | Collection<number, AvailableSeatModelType>,
  selectedSeatIdSet: Set<number>
): List<AvailableSeatModelType> {
  return availableSeatModelList
    .filter((avsm): boolean => {
      const seatId = avsm.seat && getEntityId(avsm.seat);

      return (
        typeof seatId === 'string' &&
        selectedSeatIdSet.has(Number(getShortId(seatId)))
      );
    })
    .toList();
}
