import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 15" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-388 -1191)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="fleche-form"
            d="m301.071 370.888 8.087 12.41c.49.752-.107 1.702-1.07 1.702h-16.175c-.964 0-1.562-.95-1.071-1.702l8.087-12.41c.481-.74 1.66-.74 2.142 0"
            transform="rotate(-180 300 377.667)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFormArrow;
