import { Attribute, ClassMetadata } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type PaymentFeeType = BaseEntity<'PaymentFee'> & {
  isPublic: null | boolean;
  comment: null | string;
  formula: null | string;
};
class PaymentFee extends NetworkEntity<PaymentFeeType>({
  '@id': null,
  '@type': 'PaymentFee',
  isPublic: false,
  comment: null,
  formula: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<PaymentFeeType> = { '@id': null, '@type': 'PaymentFee' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/payment_fees/', '') || '';
  }
}

PaymentFee.classMetadata = new ClassMetadata(
  'paymentFee',
  'payment_fees',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
PaymentFee.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('isPublic'),
  new Attribute('comment'),
  new Attribute('formula'),
]);

export default PaymentFee;
