import { Theme } from 'react-select';
import type { MpdIconType } from '../../../../types/MpdIconType';

export type SingleSelectOption = {
  label: string;
  value: string | null;
  disabled?: boolean;
  description?: string;
} & (
  | {
      iconName?: never;
      iconColor?: never;
    }
  | {
      iconName: MpdIconType;
      iconColor: string;
    }
);

export const getSelectTheme = (
  theme: Theme,
  errorMessage: string | null | undefined
): Theme => {
  const secondaryLightGray = '#d2d7dc';
  const secondaryWhite = '#f7f5f7';
  const alertRed = '#e90000';
  const borderColor = errorMessage ? alertRed : secondaryLightGray;

  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      neutral20: borderColor,
      neutral30: borderColor,
      primary: borderColor,
      primary25: secondaryWhite,
      primary50: secondaryLightGray,
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 60,
    },
  };
};
