import { getEntityId } from '@mapado/js-component';
import { List } from 'immutable';
import React, { ReactElement } from 'react';
import { AVAILABLE_SEAT_STATUS_DISMISSED } from 'mapado-ticketing-js-sdk';
import { useAvailableSeatSelector } from '../../../utils/selectors';
import SeatGroupList from '../SeatGroupList';

export default function SeatGroupSidebarContent(): ReactElement {
  const availableSeatList = useAvailableSeatSelector() || List();

  const filteredSeatList = availableSeatList.filter(
    (avs) => avs.status !== AVAILABLE_SEAT_STATUS_DISMISSED
  );

  const availableSeatListBySeatGroup = filteredSeatList.groupBy((avs) =>
    avs.seatGroup ? getEntityId(avs.seatGroup) : 'noSeatGroup'
  );

  return (
    <SeatGroupList
      getNbSeats={(seatGroup) =>
        availableSeatListBySeatGroup.get(seatGroup['@id'])?.count() ?? 0
      }
    />
  );
}
