import React, { useCallback, useEffect, useState } from 'react';
import localforage from 'localforage';
import TicketingSdk from 'mapado-ticketing-js-sdk';
import { useLocation, useHistory } from 'react-router';
import type { History } from 'history';
import { Token } from 'rest-client-sdk';
import redirectToLoginPage from './redirectToLoginPage';

function redirectToUrl(history: History, redirectUrl: string | null): void {
  if (redirectUrl) {
    if (redirectUrl.startsWith(window.location.origin)) {
      // same origin, then use react-router navigation to avoir refreshing all page
      const path = redirectUrl.replace(
        new RegExp(`^${window.location.origin}`),
        ''
      );

      if (path.startsWith('/oauth-callback')) {
        // force redirection to home page if the redirect url is an oauth callback path.
        // If you end up here, then you probably either directly load the callback page, or you had an
        // issue with your token, so let's try to fix this by cleaning the state and start over
        localforage.removeItem('state');
        localforage.removeItem('redirectUrl');

        history.push('/');
      } else {
        history.push(path);
      }
    } else {
      // else redirect the old-fashionned way. We should probably never use this case
      window.location.href = redirectUrl;
    }
  } else {
    // if no redirectUrl is set, or if local storage has been cleaned, then redirect to the homepage.
    history.push('/');
  }
}

type Props = {
  ticketingSdk: TicketingSdk;
};

export default function OauthCallback({
  ticketingSdk,
}: Props): React.ReactElement | null {
  const history = useHistory();
  const location = useLocation();
  const [displayedError, setDisplayedError] = useState<string | null>(null);

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const handleError = useCallback((err: string, redirectToLogin = false) => {
    // eslint-disable-next-line no-console
    console.error(err);

    if (redirectToLogin) {
      redirectToLoginPage(window.location.href);
    } else {
      setDisplayedError(err);
    }
  }, []);

  useEffect(() => {
    async function generateToken() {
      if (!code) {
        handleError('no authorization code', true);
      } else {
        try {
          const token: Token = await ticketingSdk.tokenStorage.generateToken({
            code,
          });

          // @ts-expect-error -- token is not defined as an error but should be
          if (token?.error) {
            // @ts-expect-error -- token is not defined as an error but should be
            handleError(token.error, true);
          } else if (token?.access_token) {
            const state = await localforage.getItem<string>('state');

            await localforage.removeItem('state');

            if (state === searchParams.get('state')) {
              const redirectUrl = await localforage.getItem<string>(
                'redirectUrl'
              );

              await localforage.removeItem('redirectUrl');

              redirectToUrl(history, redirectUrl);
            } else {
              handleError('oauth state does not match the expected state');
            }
          }
        } catch (error: unknown) {
          // error while generating token, let's display the error to the user
          const message: string =
            // eslint-disable-next-line no-nested-ternary
            typeof error === 'string'
              ? error
              : error instanceof Error
              ? error.message
              : 'An exception occured during login';

          handleError(message);
        }
      }
    }

    generateToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- load only once

  if (displayedError) {
    return <div>{displayedError}</div>;
  }

  return null;
}
