import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Toast, TOAST_DISAPEAR_TIME, TOAST_TYPE } from './constants';

type Props = Toast;

enum TOAST_DISPLAY_STATUS {
  OPENING = 'opening',
  OPENED = 'opened',
  CLOSING = 'closing',
  CLOSED = 'closed',
}

export default function MpdToast({
  type = TOAST_TYPE.INFO,
  title,
  message,
  action,
}: Props): JSX.Element | null {
  const ref = useRef<HTMLDivElement>(null);
  const [openingStatus, setOpeningStatus] = useState<TOAST_DISPLAY_STATUS>(
    TOAST_DISPLAY_STATUS.OPENING
  );

  useEffect(() => {
    const toast = ref.current;

    if (openingStatus === TOAST_DISPLAY_STATUS.OPENED) {
      // Toast display time when opened
      const launchTimeout = () =>
        window.setTimeout(
          () => {
            setOpeningStatus(TOAST_DISPLAY_STATUS.CLOSING);
          },
          action ? TOAST_DISAPEAR_TIME.ACTION : TOAST_DISAPEAR_TIME.DEFAULT
        );

      let openingTimeout = launchTimeout();

      toast?.addEventListener('mouseenter', () => {
        window.clearTimeout(openingTimeout);
      });

      toast?.addEventListener('mouseleave', () => {
        openingTimeout = launchTimeout();
      });
    }
  }, [openingStatus]);

  const updateStatus = useCallback(() => {
    if (openingStatus === TOAST_DISPLAY_STATUS.OPENING) {
      setOpeningStatus(TOAST_DISPLAY_STATUS.OPENED);
    }

    if (openingStatus === TOAST_DISPLAY_STATUS.CLOSING) {
      setOpeningStatus(TOAST_DISPLAY_STATUS.CLOSED);
    }
  }, [openingStatus]);

  useEffect(() => {
    const toast = ref.current;

    // Listen animation transition and change status at the end of the animation
    toast?.addEventListener('animationend', updateStatus);

    return () => {
      toast?.removeEventListener('animationend', updateStatus);
    };
  }, [updateStatus]);

  if (openingStatus === TOAST_DISPLAY_STATUS.CLOSED) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={`mb2 mpd-toast mpd-toast--${openingStatus} mpd-alert mpd-alert--${type}`}
    >
      <div className="mpd-toast__info">
        <div className={cn({ bold: message })}>{title}</div>
        {message && <div>{message}</div>}
      </div>
      {action && <div className="mpd-toast__action">{action}</div>}
    </div>
  );
}
