import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderTicketPriceMappingClient extends AbstractClient<
  TSMetadata['providerTicketPriceMapping']
> {
  getPathBase(): string {
    return '/v1/provider_ticket_price_mappings';
  }
}

export default ProviderTicketPriceMappingClient;
