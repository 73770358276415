import React, { createContext, useMemo, useReducer } from 'react';
import bookingReducer, {
  BookingActionTypes,
  BookingState,
  initialBookingState,
} from './BookingReducer';

type Props = {
  children: React.ReactNode;
};

type ContextValue = {
  state: BookingState;
  dispatch: React.Dispatch<BookingActionTypes>;
};

const Context = createContext<ContextValue | undefined>(undefined);

export default function BookingListProvider({ children }: Props): JSX.Element {
  const [state, dispatch] = useReducer(bookingReducer, initialBookingState);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useBookingList(): ContextValue {
  const context = React.useContext(Context);

  if (typeof context === 'undefined') {
    throw new Error('useBookingList must be used within a BookingListProvider');
  }

  return context;
}
