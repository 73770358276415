import { MpdIconType } from '@mapado/makeup';
import {
  BOOKING_STATUS_DISMISSED,
  BOOKING_STATUS_AVAILABLE,
  BOOKING_STATUS_IN_CART,
  BOOKING_STATUS_IN_ORDER,
  BOOKING_STATUS_IN_RESERVATION,
  BOOKING_STATUS_SCANNED,
  BOOKING_STATUS_IN_EXTERNAL_PROVIDER_ORDER,
  BOOKING_STATUS_IN_EXTERNAL_PROVIDER_RESERVATION,
} from './booking';

// eslint-disable-next-line import/prefer-default-export
export function getIcon(
  bookingStatus: string,
  inBooking: boolean
): MpdIconType {
  const inCart = bookingStatus === BOOKING_STATUS_IN_CART;
  const inOrder = bookingStatus === BOOKING_STATUS_IN_ORDER;
  const inReservation = bookingStatus === BOOKING_STATUS_IN_RESERVATION;
  const isDismissed = bookingStatus === BOOKING_STATUS_DISMISSED;
  const isAvailable = bookingStatus === BOOKING_STATUS_AVAILABLE;
  const isScanned = bookingStatus === BOOKING_STATUS_SCANNED;
  const isExternalProviderOrder =
    bookingStatus === BOOKING_STATUS_IN_EXTERNAL_PROVIDER_ORDER;
  const isExternalProviderReservation =
    bookingStatus === BOOKING_STATUS_IN_EXTERNAL_PROVIDER_RESERVATION;

  let icon: MpdIconType = 'seat-filled';

  // seat not purchasable
  if (isDismissed) {
    icon = 'disallow';
  }

  // seat available for purchase (line not really necessary, "seat-filled" is the default)
  if (isAvailable) {
    icon = 'seat-filled';
  }

  if (isExternalProviderOrder) {
    icon = 'external-provider-filled';
  }

  if (isExternalProviderReservation) {
    icon = 'external-provider';
  }

  // seat reserved (order with reservation)
  if (!inBooking && inReservation) {
    icon = 'participant';
  }

  // seat being purchased (order in cart)
  if (!inBooking && inCart) {
    icon = 'cart';
  }

  // purchased seat (order)
  if (!inBooking && inOrder) {
    icon = 'participant-filled';
  }

  // purchased seat (order) and scanned ticket
  if (!inBooking && isScanned) {
    icon = 'valid';
  }

  return icon;
}
