import React, { FunctionComponent } from 'react';
import { LoadingIndicatorProps, GroupBase } from 'react-select';
import { SingleSelectOption } from './utils';
import MpdLoader from '../../../MpdLoader';

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>
>> = () => <MpdLoader size="xs" />;

export default LoadingIndicator;
