import type moment from 'moment';

type MomentType = typeof moment;

class MomentInstance {
  private static momentInstance: MomentType;

  static setMoment(momentInstance: MomentType): void {
    MomentInstance.momentInstance = momentInstance;
  }

  static get moment(): MomentType {
    return MomentInstance.momentInstance;
  }

  /** @deprecated */
  static getMoment(): MomentType {
    return MomentInstance.momentInstance;
  }
}
export default MomentInstance;
