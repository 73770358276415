import clone from 'clone';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import OfferRuleClient from '../client/OfferRuleClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import BookingTrack from './BookingTrack';
import Contract from './Contract';
import Coupon from './Coupon';
import NetworkEntity from './NetworkEntity';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

enum CONNECTOR {
  AND = 'AND',
  OR = 'OR',
}

type MinMaxObject = {
  min?: number;
  max?: number;
};

interface MinMaxMap<T> extends Map<keyof T, unknown> {
  toJS(): Record<keyof T, unknown>;
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
}

type MinMax = MinMaxMap<MinMaxObject>;

type ConstrainsObject = {
  // Same*
  SameDay?: true;
  SameEventDate?: true;
  SameTicketPrice?: true;
  SameTicketing?: true;
  SameVenue?: true;

  // NPer*
  NPerEventDate: MinMax;
  NPerTicketPrice: MinMax;
  NPerTicketing: MinMax;
  NPerVenue: MinMax;
  NbTicket: MinMax;
};

interface ConstrainsMap<T> extends Map<keyof T, unknown> {
  toJS(): Record<keyof T, unknown>;
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
}

type Constrains = ConstrainsMap<ConstrainsObject>;

export type TicketPricePickerActionObject = {
  type: 'ticket_price_picker';
  ticketPriceList: Array<string>;
  name?: string;
  min?: number;
  max?: number;
  constraints?: Constrains;
};

interface TicketPricePickerActionMap<T> extends Map<keyof T, unknown> {
  toJS(): Record<keyof T, unknown>;

  get(key: 'ticketPriceList'): List<string>;
  get<K extends keyof T>(key: K): T[K];

  set<K extends keyof T>(key: K, value: T[K]): this;
}

export type TicketPricePickerAction = TicketPricePickerActionMap<TicketPricePickerActionObject>;

type MessageObject = {
  connector: CONNECTOR;
  actionList: List<TicketPricePickerAction>;
  constraints?: Constrains;
  disallowTicketPriceBatchFetch?: boolean;
};

export interface MessageMap<T> extends Map<keyof T, unknown> {
  toJS(): Record<keyof T, unknown>;
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
}

type Message = MessageMap<MessageObject>;

export type OfferRuleType = BaseEntity<'OfferRule'> & {
  contract: null | Contract;
  couponList: null | List<Coupon>;
  title: null | string;
  description: null | string;
  color: null | string;
  image: null | string;
  message: null | Message;
  conditionsText: null | string;
  enabled: null | boolean;
  archived: null | boolean;
  draft: null | boolean;
  validStartDate: null | Moment;
  validEndDate: null | Moment;
  timezone: null | string;
  hasApplier: null | boolean;
  handcrafted: null | boolean;
  triggerList: null | List<unknown>;
  exchangeData: null | Map<string, unknown>;
  combinable: null | boolean;
  position: null | number;
  applicationData: null; // TODO to remove as the prop is not hydrated since 10.28.0
  applicationDataList: null | List<unknown>;
  ticketPriceList: null | List<TicketPrice>;
  contractList: null | List<Contract>;
  generateMasterTicket: null | boolean;
  isNominative: null | boolean;
  schemaVersion: null | number;
  isListed: null | boolean;
  hasCashCoupon: null | boolean;
  customerRequiredFields: Map<string, unknown>;
  templateParameters: null | Map<string, unknown>;
  bookingTrack: null | EntityRelation<BookingTrack>;
  isGenericCashCoupon: null | boolean;
};

class OfferRule extends NetworkEntity<OfferRuleType>({
  '@id': null,
  '@type': 'OfferRule',
  contract: new Contract(),
  couponList: List<Coupon>(),
  title: '',
  description: null,
  color: null,
  image: '',
  message: null,
  conditionsText: '',
  enabled: false,
  archived: false,
  draft: true,
  validStartDate: null,
  validEndDate: null,
  timezone: '',
  hasApplier: false,
  handcrafted: false,
  triggerList: null,
  exchangeData: null,
  combinable: true,
  position: null,
  applicationData: null,
  applicationDataList: null,
  ticketPriceList: null,
  contractList: null,
  generateMasterTicket: false,
  isNominative: null,
  schemaVersion: null,
  isListed: null,
  hasCashCoupon: null,
  customerRequiredFields: Map<string, unknown>(),
  templateParameters: Map<string, unknown>(),
  bookingTrack: null,
  isGenericCashCoupon: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OfferRuleType> = { '@id': null, '@type': 'OfferRule' }
  ) {
    const data = clone(val);

    data.validStartDate = parseDate(data.validStartDate, data.timezone);
    data.validEndDate = parseDate(data.validEndDate, data.timezone);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/offer_rules/', '') || '';
  }
}

OfferRule.classMetadata = new ClassMetadata(
  'offerRule',
  'offer_rules',
  /** @ts-expect-error -- method signature are incompatible */
  OfferRuleClient
);
OfferRule.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('title'),
  new Attribute('description'),
  new Attribute('color'),
  new Attribute('image', 'image'),
  new Attribute('message', 'message', 'object'),
  new Attribute('conditionsText'),
  new Attribute('enabled', 'enabled', 'boolean'),
  new Attribute('draft', 'draft', 'boolean'),
  new Attribute('archived', 'archived', 'boolean'),
  new Attribute('validStartDate', 'validStartDate', 'datetime'),
  new Attribute('validEndDate', 'validEndDate', 'datetime'),
  new Attribute('timezone'),
  new Attribute('hasApplier', 'hasApplier', 'boolean'),
  new Attribute('handcrafted', 'handcrafted', 'boolean'),
  new Attribute('triggerList', 'triggerList', 'array'),
  new Attribute('exchangeData', 'exchangeData', 'object'),
  new Attribute('combinable', 'combinable', 'boolean'),
  new Attribute('generateMasterTicket', 'generateMasterTicket', 'boolean'),
  new Attribute('position', 'position', 'integer'),
  new Attribute('applicationDataList', 'applicationDataList', 'array'),
  new Attribute('isNominative', 'isNominative', 'boolean'),
  new Attribute('schemaVersion', 'schemaVersion', 'integer'),
  new Attribute('isListed', 'isListed', 'boolean'),
  new Attribute('hasCashCoupon', 'hasCashCoupon', 'boolean'),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('templateParameters', 'templateParameters', 'object'),
  new Attribute('isGenericCashCoupon', 'isGenericCashCoupon', 'boolean'),
]);
OfferRule.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(Relation.ONE_TO_MANY, 'coupon', 'couponList'),
  new Relation(Relation.MANY_TO_MANY, 'contract', 'contractList'),
  new Relation(Relation.MANY_TO_ONE, 'bookingTrack', 'bookingTrack'),
]);

export default OfferRule;
