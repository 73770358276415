import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeatStandingObstructedView = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 9 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M5.102 7.948a3.5 3.5 0 0 1-1.184.004L7.814 3.37A3.5 3.5 0 0 1 8 4.54zm.837-.257a3.5 3.5 0 0 0 1.942-2.283zm1.668-4.804-4.2 4.939a3.5 3.5 0 0 1-.837-.406l4.502-5.294q.316.344.535.76m.687-.808a4 4 0 0 1 .246.438A4.5 4.5 0 1 1 .233 5.935q-.09-.27-.147-.554A4.5 4.5 0 0 1 .02 4.068q.049-.5.2-.962A4.51 4.51 0 0 1 2.438.499q.434-.226.918-.352A4.5 4.5 0 0 1 4.66.003q.291.01.57.056.5.082.955.267.229.092.442.207.403.217.75.507.182.151.347.32.32.33.57.72M6.73 1.802 2.195 7.134a3.5 3.5 0 0 1-.601-.683L5.96 1.318q.419.193.77.484m-1.24-.66L1.346 6.017a3.5 3.5 0 0 1-.31-1.024L4.43 1a3.6 3.6 0 0 1 1.06.141m-1.748-.06A3.5 3.5 0 0 0 1.006 4.3z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatStandingObstructedView;
