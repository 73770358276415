import { HidableType } from '../entity/HidableType';
import { EntityMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

type ObjectEntity = Record<string, unknown>;

class HidableClient<
  D extends EntityMetadata<ObjectEntity, string>
> extends AbstractClient<D> {
  #toggleVisibility(entity: HidableType, action: string): Promise<Response> {
    const url = `${this.getEntityURI(entity)}/${action}`;

    return this.authorizedFetch(url, {
      method: 'PUT',
    });
  }

  hide(entity: HidableType): Promise<Response> {
    return this.#toggleVisibility(entity, 'hide');
  }

  show(entity: HidableType): Promise<Response> {
    return this.#toggleVisibility(entity, 'show');
  }
}

export default HidableClient;
