import { useMemo } from 'react';
import type { List, Map } from 'immutable';
import { Cart, SellingDevice, TicketPrice } from 'mapado-ticketing-js-sdk';
import {
  OfferClusterMap,
  generateOfferClusterMap,
} from '../components/common/offerCluster';
import { CartAtomId } from '../redux/booking/constants';
import { TicketPriceQuantity } from '../redux/booking/utils/ticketPriceQuantities';
import { useCartSelector } from './typedFunctions';
import {
  getHasCartIdInStorage,
  getCurrentCartUpdating,
  getCurrentCart,
  getCurrentBookingError,
  getCurrentMapadoError,
  getAtomCart,
  getCurrentCartAtomId,
  getCurrentTicketPriceQuantities,
  getSellingDevice,
} from '.';

export function useCurrentCart(): Cart | null {
  return useCartSelector(getCurrentCart);
}

export function useAtomCart(cartAtomId: CartAtomId): Cart | null {
  return useCartSelector((state) => getAtomCart(state, cartAtomId));
}

export function useHasCartIdInStorage(): boolean | null {
  return useCartSelector(getHasCartIdInStorage);
}

export function useCurrentCartUpdating(): boolean {
  return useCartSelector(getCurrentCartUpdating);
}

export function useCurrentCartHasOffers(): boolean {
  const cart = useCurrentCart();
  const offerClusterMap = useMemo<OfferClusterMap | null>(
    () => (cart ? generateOfferClusterMap(cart) : null),
    [cart]
  );

  if (!offerClusterMap) {
    return false;
  }

  return offerClusterMap.get('offerClusterList')?.size > 0;
}

export function useCurrentCartBookingError(): string | null {
  return useCartSelector(getCurrentBookingError);
}

export function useCurrentCartMapadoError(): Map<string, unknown> | null {
  return useCartSelector(getCurrentMapadoError);
}

export function useCurrentCartAtomId(): CartAtomId {
  return useCartSelector(getCurrentCartAtomId);
}

export function useTicketPriceQuantityInCart(ticketPrice: TicketPrice): number {
  const counter = useCartSelector((state) => {
    const currentTicketPriceQuantities: List<TicketPriceQuantity> =
      getCurrentTicketPriceQuantities(state);

    return (
      currentTicketPriceQuantities
        .filter((currentTicketPriceQuantity: TicketPriceQuantity): boolean => {
          return (
            // we do not want the ticket prices with an offer
            !currentTicketPriceQuantity.get('groupKey') &&
            // and we want only the current ticket price
            currentTicketPriceQuantity.get('ticketPrice').get('@id') ===
              ticketPrice['@id']
          );
        })
        // sum the quantities of the ticket prices
        .reduce(
          (
            sum: number,
            currentTicketPriceQuantity: TicketPriceQuantity
          ): number => {
            return sum + currentTicketPriceQuantity.get('quantity');
          },
          0
        )
    );
  });

  return counter;
}

export const useSellingDevice = (): SellingDevice | null =>
  useCartSelector(getSellingDevice);

export const useCurrentTicketPriceQuantities = (): List<TicketPriceQuantity> =>
  useCartSelector(getCurrentTicketPriceQuantities);
