export enum CART_TYPE {
  CART = 'cart',
  OPTION = 'option',
  RESERVATION = 'reservation',
}

export enum CART_STATUS {
  DRAFT = 'draft',
  VALIDATED = 'validated',
  ACCOUNTED_FOR_ORDER = 'accounted_for_order',
}
