import { List, Set } from 'immutable';
import { disablePan, enablePan } from '../svgPanZoom';
import findTranslateXY from '../../components/Main/findTranslateXY';

const PATH_CLASSNAME = 'free-hand-draw-seat-selector-path';

// allow both Set<string> AND number[] as method signature
type SeatIdIterable = number[] | Set<number>;
export type SelectBatchOfSeats = (seatIdSet: SeatIdIterable) => void;

type Point = {
  x: number;
  y: number;
};

type State = {
  startX: null | number;
  startY: null | number;
};

export type FreeHandDrawSeatSelectorInitOptions = {
  selectableSeatSelector?: string;
};

class FreeHandDrawSeatSelector {
  #selectedSeatList: HTMLElement[];

  #selectBatchOfSeats: SelectBatchOfSeats;

  #SELECTABLE_SEAT_SELECTOR: string;

  #path: null | SVGPathElement;

  #strPath: string;

  #buffer: Point[];

  #bufferSize: number;

  #seatNodeList?: HTMLCollectionOf<HTMLElement>;

  #state: State;

  constructor(selectBatchOfSeats: SelectBatchOfSeats) {
    this.#selectedSeatList = [];
    this.#selectBatchOfSeats = selectBatchOfSeats;

    this.clear = this.clear.bind(this);
    this.reset = this.reset.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.updateSvgPath = this.updateSvgPath.bind(this);
    this.appendToBuffer = this.appendToBuffer.bind(this);
    this.getAveragePoint = this.getAveragePoint.bind(this);

    this.clear();

    // https://stackoverflow.com/questions/40324313/svg-smooth-freehand-drawing
    this.#path = null;
    this.#strPath = '';
    this.#buffer = [];
    this.#bufferSize = 4;

    // this._requestAnimationFrame = null;
    this.#SELECTABLE_SEAT_SELECTOR = 'mpd-seating__seat--is-selectable';

    this.#state = {
      startX: null,
      startY: null,
    };
  }

  reset(): void {
    this.clear();
    // this._currentSelectionPolygon = null;

    // this._requestAnimationFrame = null;

    this.#selectedSeatList = [];
    this.#path = null;
    this.#strPath = '';
    this.#buffer = [];
  }

  onSelectedSeatIdListChange(selectedSeatIdSet: Set<number>): void {
    if (selectedSeatIdSet.size === 0) {
      this.reset();
    }

    const nodeList = (
      selectedSeatIdSet
        .toList()
        .map((id) => document.getElementById(String(id)))
        .filter((element) => element !== null) as List<HTMLElement>
    ).toArray();

    this.#selectedSeatList = nodeList;
  }

  clear(): void {
    const pathList = document.getElementsByClassName(
      'free-hand-draw-seat-selector-path'
    );

    while (pathList[0]) {
      if (pathList[0].parentNode) {
        pathList[0].parentNode.removeChild(pathList[0]);
      }
    }

    this.#state = {
      startX: null,
      startY: null,
    };
  }

  init(options: FreeHandDrawSeatSelectorInitOptions = {}): void {
    if (typeof options.selectableSeatSelector !== 'undefined') {
      this.#SELECTABLE_SEAT_SELECTOR = options.selectableSeatSelector;
    }

    this.#seatNodeList = document.getElementsByClassName(
      this.#SELECTABLE_SEAT_SELECTOR
    ) as HTMLCollectionOf<HTMLElement>;
  }

  /**
   * Finish the current selection, and sends an event to redux
   */
  finishSelection(): void {
    this.clear();

    // dynamic import is used because raphael does not handle SSR
    import('raphael').then(({ default: Raphael }) => {
      // https://stackoverflow.com/questions/10525729/is-a-point-inside-a-closed-path-svg-javascript
      // Unfortunately, the only native API is chrome-only
      const paper = Raphael(0, 0, 0, 0);
      const p = paper.path(this.#strPath);

      const seatNodeListInsidePath: HTMLElement[] = [];

      if (this.#seatNodeList) {
        for (let i = 0; i < this.#seatNodeList.length; i++) {
          const seatNode = this.#seatNodeList[i];
          const transform = seatNode.getAttribute('transform');

          if (transform === null) {
            throw new Error('seatNode should have a `transform` attribute');
          }

          const { x: nodeX, y: nodeY } = findTranslateXY(transform);

          if (p.isPointInside(nodeX, nodeY)) {
            seatNodeListInsidePath.push(seatNode);
          }
        }
      }

      const seatNodeListToDeselected = this.#selectedSeatList.filter(
        (seatNode) => seatNodeListInsidePath.indexOf(seatNode) >= 0
      );

      this.#selectedSeatList = this.#selectedSeatList
        .concat(seatNodeListInsidePath)
        .filter(
          (seatNode) => seatNodeListToDeselected.indexOf(seatNode) === -1
        );

      const idList: number[] = this.#selectedSeatList
        .map((node): null | number => {
          const id = node.getAttribute('id');

          return id ? parseInt(id, 10) : null;
        })
        .filter((id) => !!id) as number[];

      this.#selectBatchOfSeats(idList);

      this.#path = null;

      paper.remove();
    });
  }

  appendToBuffer(pt: Point): void {
    this.#buffer.push(pt);

    while (this.#buffer.length > this.#bufferSize) {
      this.#buffer.shift();
    }
  }

  getAveragePoint(offset: number): null | Point {
    const len = this.#buffer.length;

    if (len % 2 === 1 || len >= this.#bufferSize) {
      let totalX = 0;
      let totalY = 0;
      let pt;
      let i;
      let count = 0;

      for (i = offset; i < len; i++) {
        count += 1;
        pt = this.#buffer[i];
        totalX += pt.x;
        totalY += pt.y;
      }

      return {
        x: totalX / count,
        y: totalY / count,
      };
    }

    return null;
  }

  updateSvgPath(): void {
    let pt = this.getAveragePoint(0);

    if (pt) {
      // Get the smoothed part of the path that will not change
      this.#strPath += ` L${pt.x} ${pt.y}`;

      // Get the last part of the path (close to the current mouse position)
      // This part will change if the mouse moves again
      let tmpPath = '';

      for (let offset = 2; offset < this.#buffer.length; offset += 2) {
        pt = this.getAveragePoint(offset);

        if (pt) {
          tmpPath += ` L${pt.x} ${pt.y}`;
        }
      }

      // Set the complete current path coordinates
      if (this.#path) {
        this.#path.setAttribute('d', this.#strPath + tmpPath);
      }
    }
  }

  handleTouchStart(x: number, y: number): void {
    const { startX, startY } = this.#state;

    if (startX === null || startY === null) {
      disablePan();
      this.#state.startX = x;
      this.#state.startY = y;

      this.#path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      this.#path.setAttribute('fill', 'none');
      this.#path.setAttribute('stroke', '#000');
      this.#path.setAttribute('stroke-width', '2');
      this.#path.classList.add(PATH_CLASSNAME);
      this.#buffer = [];

      this.appendToBuffer({
        x,
        y,
      });
      this.#strPath = `M${x} ${y}`;
      this.#path.setAttribute('d', this.#strPath);

      const seatingSVG = document.getElementById('seatingSVG');

      if (!seatingSVG) {
        throw new Error(
          'Unable to find element with id `seatingSVG`. This should not happen.'
        );
      }

      seatingSVG.childNodes[0].appendChild(this.#path);
    }
  }

  handleTouchEnd(): void {
    const { startX, startY } = this.#state;

    if (startX !== null && startY !== null && this.#path) {
      // Close the drawing = reset the buffer + add the first point as the last
      this.#buffer = [];
      this.appendToBuffer({ x: startX, y: startY });
      this.updateSvgPath();

      window.requestAnimationFrame(() => {
        this.finishSelection();
      });
      enablePan();
    }
  }

  handleMouseMove(
    _svgElement: unknown,
    _destinationElement: unknown,
    x: number,
    y: number
  ): void {
    const { startX, startY } = this.#state;

    if (startX !== null && startY !== null && this.#path) {
      this.appendToBuffer({ x, y });
      this.updateSvgPath();
    }
  }
}

export default FreeHandDrawSeatSelector;
