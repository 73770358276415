import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SeatGroupType } from '../propTypes';

interface SelectedSeatGroupContextType {
  selectedSeatGroup: SeatGroupType | null;
  handleSelectSeatGroup: (seatGroup: SeatGroupType) => void;
  unselectSeatGroup: () => void;
}

const selectedSeatGroupContext =
  createContext<SelectedSeatGroupContextType | null>(null);

export function useSelectedSeatGroupContext() {
  const context = useContext(selectedSeatGroupContext);

  if (context === null) {
    throw new Error(
      '`useSelectedSeatGroupContext` must be used within a `SelectedSeatGroupContextProvider`'
    );
  }

  return context;
}

export function SelectedSeatGroupContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [selectedSeatGroup, setSelectedSeatGroup] =
    useState<SeatGroupType | null>(null);

  const handleSelectSeatGroup = useCallback(
    (seatGroup: SeatGroupType) => {
      if (selectedSeatGroup && seatGroup['@id'] === selectedSeatGroup['@id']) {
        setSelectedSeatGroup(null);
      } else {
        setSelectedSeatGroup(seatGroup);
      }
    },
    [selectedSeatGroup]
  );

  function unselectSeatGroup(): void {
    setSelectedSeatGroup(null);
  }

  const contextValue = useMemo(
    () => ({ selectedSeatGroup, handleSelectSeatGroup, unselectSeatGroup }),
    [handleSelectSeatGroup, selectedSeatGroup]
  );

  return (
    <selectedSeatGroupContext.Provider value={contextValue}>
      {children}
    </selectedSeatGroupContext.Provider>
  );
}
