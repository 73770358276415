import React, { MouseEventHandler, ReactNode, ReactElement } from 'react';
import cn from 'classnames';
import '../../styles/components/Sidebar/SidebarTab.scss';

type Props = {
  children: ReactNode;
  selected?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

function SidebarTab({
  className = '',
  children,
  selected = false,
  ...props
}: Props): ReactElement {
  const classnames = cn('mpd-seating-sidebar-card', className, {
    selected,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classnames} {...props}>
      {children}
    </div>
  );
}

export default SidebarTab;
