import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';

export function RedirectToLatestPage(): JSX.Element {
  const latestPage = localStorage.getItem('latestPage') ?? '/config';

  return <Redirect to={latestPage} />;
}

export function useRecordLatestPage(): void {
  const history = useHistory();

  useEffect(() => {
    if (!history) {
      return;
    }

    const unlisten = history.listen((location) => {
      const latestPage = `${location.pathname}${location.search}`
        // avoid going to editor, prefer config to avoid human error
        .replace('/editor', '/config');

      localStorage.setItem('latestPage', latestPage);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      unlisten();
    };
  }, [history]);
}
