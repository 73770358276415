import URI from 'urijs';
import PagedCollection from '../entity/PagedCollection';
import Wallet from '../entity/Wallet';
import { TSMetadata } from '../mapping';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class WalletClient extends AbstractClient<TSMetadata['wallet']> {
  getPathBase(): string {
    return '/v1/wallets';
  }

  findByContractList(
    contractList: Array<string>,
    fields: FieldsParameter
  ): Promise<PagedCollection<Wallet>> {
    return this.findBy({ contract: contractList }, fields);
  }

  getWalletPaylinePdf(id: number): Promise<Response> {
    const url = `/v1/wallets/${id}/payline.pdf`;

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  updateWithStripeAuthCode(
    wallet: Wallet,
    authCode: string,
    fields: FieldsParameter
  ): Promise<Wallet> {
    const uri = new URI(
      `${this.getPathBase()}/${wallet.getShortId()}/stripe-auth-code/${authCode}`
    );

    const params = this._mergeFieldsIntoParams({}, fields);
    uri.addSearch(params);

    return this.deserializeResponse(
      this.authorizedFetch(uri, { method: 'PUT' }),
      'item'
    );
  }
}

export default WalletClient;
