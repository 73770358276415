/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';

export type AttributeKey = 'rect' | 'circle' | 'line' | 'text';

type GetValueFunction = (node: Element) => number | string;

export type SetAttributeFunction = (
  node: Element,
  value: string | number | undefined
) => void;

type Attribute = {
  id: string;
  label: string;
  type: string;
  attribute?: string;
  attributeListToUpdate?: Array<string>;
  defaultValue?: string | number;
  getValueFunction?: GetValueFunction;
  setValueFunction?: (node: Element, value: string | number) => void;
  setAttributeFunction?: SetAttributeFunction;
};

type AttributeMap = Record<AttributeKey, Array<Attribute>>;

export type ChangeNodeAttributeFunction = (
  node: Element,
  value: string | number,
  attributeList: Array<string>,
  setAttributeFunction: null | SetAttributeFunction
) => void;

export const getTagnameLabel = (tagname: string): string => {
  switch (tagname.toLowerCase()) {
    case 'line':
      return 'Ligne';
    case 'rect':
      return 'Rectangle';
    case 'circle':
      return 'Cercle';
    case 'text':
      return 'Texte';
    default:
      return '';
  }
};

function getValue(
  node: Element,
  attribute: string | undefined,
  getValueFunction: null | GetValueFunction = null
): string | number | null {
  if (getValueFunction !== null) {
    return getValueFunction(node /* , attribute */);
  }

  if (typeof attribute === 'undefined') {
    throw new Error(
      'attribute should be defined if there is no getValueFunction'
    );
  }

  return node.getAttribute(attribute);
}

export const attributeMap: AttributeMap = {
  rect: [
    {
      label: 'Largeur',
      type: 'number',
      attribute: 'width',
      attributeListToUpdate: ['width'],
      defaultValue: 50,
      id: 'width',
    },
    {
      label: 'Hauteur',
      type: 'number',
      attribute: 'height',
      attributeListToUpdate: ['height'],
      defaultValue: 50,
      id: 'height',
    },
    {
      label: 'X',
      type: 'number',
      attribute: 'x',
      attributeListToUpdate: ['x', 'data-originalX'],
      defaultValue: 0,
      id: 'x',
    },
    {
      label: 'Y',
      type: 'number',
      attribute: 'y',
      attributeListToUpdate: ['y', 'data-originalY'],
      defaultValue: 0,
      id: 'y',
    },
    {
      label: 'Couleur bordure',
      type: 'color',
      attribute: 'stroke',
      attributeListToUpdate: ['stroke'],
      defaultValue: '#808080',
      id: 'color',
    },
    {
      label: 'Epaisseur bordure',
      type: 'number',
      attribute: 'stroke-width',
      attributeListToUpdate: ['stroke-width'],
      defaultValue: 1,
      id: 'stroke-width',
      getValueFunction: (node) => {
        const value = node.getAttribute('stroke-width');

        if (value === null) {
          return 1;
        }

        return value;
      },
    },
    {
      label: 'Couleur de fond',
      type: 'color',
      attribute: 'fill',
      attributeListToUpdate: ['fill'],
      defaultValue: '#cdcdcd',
      id: 'fill',
    },
    {
      label: 'Rotation',
      type: 'number',
      id: 'rotation',
      getValueFunction: (node: Element) => {
        const transform = node.getAttribute('transform');

        if (!transform) {
          return 0;
        }

        const numbers = node
          .getAttribute('transform')
          ?.match(/\d+/g)
          ?.map(Number);

        if (numbers === null || typeof numbers?.[0] === 'undefined') {
          return 0;
        }

        return numbers[0];
      },
      setAttributeFunction: (node, value) => {
        const x = Number(node.getAttribute('x')?.replace('px', ''));
        const y = Number(node.getAttribute('y')?.replace('px', ''));

        const width = Number(node.getAttribute('width')?.replace('px', ''));
        const height = Number(node.getAttribute('height')?.replace('px', ''));

        const centerX = x + width / 2;
        const centerY = y + height / 2;

        node.setAttribute(
          'transform',
          `rotate(${value || '0'} ${centerX} ${centerY})`
        );
      },
    },
    {
      label: 'Arrondi X',
      type: 'number',
      attribute: 'rx',
      attributeListToUpdate: ['rx', 'data-originalRX'],
      defaultValue: '3',
      id: 'rx',
    },
    {
      label: 'Arrondi Y',
      type: 'number',
      attribute: 'ry',
      attributeListToUpdate: ['ry', 'data-originalRY'],
      defaultValue: '3',
      id: 'ry',
    },
  ],

  circle: [
    {
      label: 'Rayon',
      type: 'number',
      attribute: 'r',
      attributeListToUpdate: ['r'],
      defaultValue: 30,
      id: 'r',
    },
    {
      label: 'X',
      type: 'number',
      attribute: 'cx',
      attributeListToUpdate: ['cx', 'data-originalCX'],
      defaultValue: 0,
      id: 'cx',
    },
    {
      label: 'Y',
      type: 'number',
      attribute: 'cy',
      attributeListToUpdate: ['cy', 'data-originalCY'],
      defaultValue: 0,
      id: 'cy',
    },
    {
      label: 'Couleur bordure',
      type: 'string',
      attribute: 'stroke',
      attributeListToUpdate: ['stroke'],
      defaultValue: '#808080',
      id: 'stroke',
    },
    {
      label: 'Epaisseur bordure',
      type: 'number',
      attribute: 'stroke-width',
      id: 'stroke-width',
      attributeListToUpdate: ['stroke-width'],
      defaultValue: 1,
      getValueFunction: (node) => {
        const value = node.getAttribute('stroke-width');

        if (value === null) {
          return 1;
        }

        return value;
      },
    },
    {
      label: 'Couleur de fond',
      type: 'string',
      attribute: 'fill',
      attributeListToUpdate: ['fill'],
      defaultValue: '#cdcdcd',
      id: 'fill',
    },
  ],

  text: [
    {
      label: 'X',
      type: 'number',
      attribute: 'x',
      attributeListToUpdate: ['x', 'data-originalX'],
      defaultValue: 0,
      id: 'x',
    },
    {
      label: 'Y',
      type: 'number',
      attribute: 'y',
      attributeListToUpdate: ['y', 'data-originalY'],
      defaultValue: 0,
      id: 'y',
    },
    {
      label: 'Texte',
      type: 'string',
      id: 'text',
      getValueFunction: (node: Element) => node.innerHTML,
      setAttributeFunction: (node, value) => {
        // @ts-expect-error - number is valid for innerHTML
        // eslint-disable-next-line no-param-reassign
        node.innerHTML = value ?? '';
      },
      defaultValue: '',
    },
    {
      label: 'Taille',
      type: 'number',
      attribute: 'font-size',
      attributeListToUpdate: ['font-size'],
      defaultValue: 14,
      id: 'font-size',
      getValueFunction: (node) => {
        const fs = node.getAttribute('font-size');

        if (!fs) {
          return 14;
        }

        return fs.replace('px', '');
      },
    },
    {
      label: 'Rotation',
      type: 'number',
      id: 'rotation',
      getValueFunction: (node) => {
        const transform = node.getAttribute('transform');

        if (!transform) {
          return 0;
        }

        const numbers = node
          .getAttribute('transform')
          ?.match(/\d+/g)
          ?.map(Number);

        if (numbers === null || typeof numbers?.[0] === 'undefined') {
          return 0;
        }

        return numbers[0];
      },
      setAttributeFunction: (node, value) => {
        const x = Number(node.getAttribute('x'));
        const y = Number(node.getAttribute('y'));

        const centerX = x;
        const centerY = y;

        node.setAttribute(
          'transform',
          `rotate(${value || '0'} ${centerX} ${centerY})`
        );
      },
    },
  ],

  line: [
    {
      label: 'X1',
      type: 'number',
      attribute: 'x1',
      attributeListToUpdate: ['x1'],
      defaultValue: 300,
      id: 'x1',
    },
    {
      label: 'Y1',
      type: 'number',
      attribute: 'y1',
      attributeListToUpdate: ['y1'],
      defaultValue: -100,
      id: 'y1',
    },
    {
      label: 'X2',
      type: 'number',
      attribute: 'x2',
      attributeListToUpdate: ['x2'],
      defaultValue: 0,
      id: 'x2',
    },
    {
      label: 'Y2',
      type: 'number',
      attribute: 'y2',
      attributeListToUpdate: ['y2'],
      defaultValue: -100,
      id: 'y2',
    },
    {
      label: 'Epaisseur',
      type: 'number',
      attribute: 'stroke-width',
      attributeListToUpdate: ['stroke-width'],
      defaultValue: 25,
      id: 'stroke-width',
      getValueFunction: (node) => {
        const value = node.getAttribute('stroke-width');

        if (value === null) {
          return 25;
        }

        return value;
      },
    },
    {
      label: 'Couleur',
      type: 'string',
      attribute: 'stroke',
      attributeListToUpdate: ['stroke'],
      defaultValue: '#808080',
      id: 'stroke',
    },
    {
      label: 'Rotation',
      type: 'number',
      id: 'rotation',
      getValueFunction: (node) => {
        const transform = node.getAttribute('transform');

        if (!transform) {
          return 0;
        }

        const numbers = node
          .getAttribute('transform')
          ?.match(/\d+/g)
          ?.map(Number);

        if (numbers === null || typeof numbers?.[0] === 'undefined') {
          return 0;
        }

        return numbers[0];
      },
      setAttributeFunction: (node, value) => {
        node.setAttribute('transform', `rotate(${value || '0'} 0 0)`);
      },
    },
  ],
};

function getUniqueId(): string {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return Math.random().toString(36).substring(2, 10);
}

interface PropertyFormProps {
  node: Element;
  changeNodeAttribute: ChangeNodeAttributeFunction;
  value: null | string | number;

  id: string;
  label: string;
  type: string;
  attributeListToUpdate?: Array<string>;
  setAttributeFunction?: null | SetAttributeFunction;
}

function PropertyForm({
  changeNodeAttribute,
  node,
  label,
  type,
  value = null,
  attributeListToUpdate = [],
  setAttributeFunction = null,
  id,
}: PropertyFormProps): null | JSX.Element {
  const inputId = `${getUniqueId()}-${id}`;

  if (type === 'number') {
    let innerValue: undefined | number;

    if (value) {
      if (typeof value === 'string') {
        innerValue = parseFloat(value);
      } else {
        innerValue = value;
      }
    }

    return (
      <div className="form-group mpd-seating__editor__property-form">
        <input
          className="form-control mpd-seating__editor__input mpd-seating__editor__input--number"
          type="number"
          value={innerValue ?? ''}
          onChange={(ev) =>
            changeNodeAttribute(
              node,
              parseFloat(ev.target.value),
              attributeListToUpdate,
              setAttributeFunction
            )
          }
          placeholder={label}
          id={inputId}
        />
        <label
          className="mpd-seating__editor__property-label"
          htmlFor={inputId}
        >
          {label}
        </label>
      </div>
    );
  }

  if (type === 'string' || type === 'color') {
    let inputType = 'text';

    if (type === 'color') {
      inputType = 'color';
    }

    return (
      <div className="form-group mpd-seating__editor__property-form">
        <input
          className="form-control mpd-seating__editor__input mpd-seating__editor__input--string"
          type={inputType}
          value={value ?? ''}
          onChange={(ev) =>
            changeNodeAttribute(
              node,
              ev.target.value,
              attributeListToUpdate,
              setAttributeFunction
            )
          }
          placeholder={label}
          id={inputId}
        />
        <label
          className="mpd-seating__editor__property-label"
          htmlFor={inputId}
        >
          {label}
        </label>
      </div>
    );
  }

  return null;
}

type NodeDetailProps = {
  node: HTMLElement;
  changeNodeAttribute: ChangeNodeAttributeFunction;
  deleteNode: (node: HTMLElement) => void;
  duplicateNode: (node: HTMLElement, index: number) => void;
  forceRerender: () => void;
  index: number;
  isLast: boolean;
  onMouseEnter: (node: HTMLElement) => void;
  onMouseLeave: (node: HTMLElement) => void;
};

type State = {
  originalPosition:
    | null
    // line
    | {
        x1: string | null;
        y1: string | null;
        x2: string | null;
        y2: string | null;
      }
    // circle
    | {
        cx: string | null;
        cy: string | null;
      }
    // other
    | {
        x: string | null;
        y: string | null;
      };
};

// do not extend PureComponent here otherwise forced rerenders won't have any effect
class NodeDetail extends Component<NodeDetailProps, State> {
  // eslint-disable-next-line react/sort-comp
  mouseX: null | number;

  mouseY: null | number;

  constructor(props: NodeDetailProps) {
    super(props);
    this.deleteCurrentNode = this.deleteCurrentNode.bind(this);
    this.duplicateCurrentNode = this.duplicateCurrentNode.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onWindowMouseUp = this.onWindowMouseUp.bind(this);
    this.onWindowMouseMove = this.onWindowMouseMove.bind(this);
    this.state = {
      originalPosition: null,
    };

    this.mouseX = null;
    this.mouseY = null;
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onWindowMouseUp);
    window.removeEventListener('mousemove', this.onWindowMouseMove);
  }

  onMouseDown(ev: React.MouseEvent): void {
    this.mouseX = ev.clientX;
    this.mouseY = ev.clientY;

    const { node } = this.props;

    window.addEventListener('mouseup', this.onWindowMouseUp);
    window.addEventListener('mousemove', this.onWindowMouseMove);
    document.body.classList.add('mpd-seating--no-select');

    let newPosition: State['originalPosition'];

    if (node.tagName.toLowerCase() === 'line') {
      newPosition = {
        x1: node.getAttribute('x1'),
        x2: node.getAttribute('x2'),
        y1: node.getAttribute('y1'),
        y2: node.getAttribute('y2'),
      };
    } else if (node.tagName.toLowerCase() === 'circle') {
      newPosition = {
        cx: node.getAttribute('cx'),
        cy: node.getAttribute('cy'),
      };
    } else {
      newPosition = {
        x: node.getAttribute('x'),
        y: node.getAttribute('y'),
      };
    }

    this.setState({
      originalPosition: newPosition,
    });
  }

  onWindowMouseUp(ev: MouseEvent): void {
    document.body.classList.remove('mpd-seating--no-select');
    window.removeEventListener('mouseup', this.onWindowMouseUp);
    window.removeEventListener('mousemove', this.onWindowMouseMove);
    this.setState({
      originalPosition: null,
    });
    this.mouseX = ev.clientX;
    this.mouseY = ev.clientY;
    this.props.forceRerender();
  }

  onWindowMouseMove(ev: MouseEvent): void {
    const { node } = this.props;
    const { originalPosition } = this.state;
    const newMouseX = ev.clientX;
    const newMouseY = ev.clientY;
    const deltaX = newMouseX - (this.mouseX ?? 0);
    const deltaY = newMouseY - (this.mouseY ?? 0);

    window.requestAnimationFrame(() => {
      if (originalPosition === null) {
        return;
      }

      if (node.tagName.toLowerCase() === 'line') {
        // @ts-expect-error - we know that this is a line
        const { x1, y1, x2, y2 } = originalPosition;

        node.setAttribute('x1', `${parseFloat(x1) + deltaX}px`);
        node.setAttribute('y1', `${parseFloat(y1) + deltaY}px`);

        node.setAttribute('x2', `${parseFloat(x2) + deltaX}px`);
        node.setAttribute('y2', `${parseFloat(y2) + deltaY}px`);
      } else if (node.tagName.toLowerCase() === 'circle') {
        // @ts-expect-error - we know that this is a circle
        const { cx, cy } = originalPosition;

        node.setAttribute('cx', `${parseFloat(cx) + deltaX}px`);
        node.setAttribute('cy', `${parseFloat(cy) + deltaY}px`);
      } else {
        // @ts-expect-error - we know that this is not a line or a circle
        const { x, y } = originalPosition;

        node.setAttribute('x', `${parseFloat(x) + deltaX}px`);
        node.setAttribute('y', `${parseFloat(y) + deltaY}px`);
      }
    });
  }

  deleteCurrentNode(event: React.MouseEvent): void {
    event.preventDefault();
    this.props.deleteNode(this.props.node);
  }

  duplicateCurrentNode(event: React.MouseEvent): void {
    event.preventDefault();

    const { duplicateNode, index, node } = this.props;

    duplicateNode(node, index);
  }

  render() {
    const { changeNodeAttribute, isLast, node, onMouseEnter, onMouseLeave } =
      this.props;
    const { originalPosition } = this.state;

    const tagName = node.tagName.toLowerCase();

    if (
      tagName !== 'line' &&
      tagName !== 'circle' &&
      tagName !== 'rect' &&
      tagName !== 'text'
    ) {
      throw new Error('tagName must be line, circle, rect or text');
    }

    const properties = attributeMap[tagName];

    const className = originalPosition
      ? 'mpd-seating__editor__property-list--disabled'
      : '';

    return (
      <div
        onMouseEnter={() => onMouseEnter(node)}
        onMouseLeave={() => onMouseLeave(node)}
        className="mpd-seating__editor__tag"
      >
        <div className="mpd-seating__editor__tagname">
          <label>{getTagnameLabel(node.tagName)}</label>

          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span
            className="mpd-seating__editor__move-anchor"
            onMouseDown={this.onMouseDown}
          >
            #
          </span>
        </div>
        <div className={`mpd-seating__editor__property-list ${className}`}>
          {properties.map((propertyMap) => {
            const innerValue =
              getValue(
                node,
                propertyMap.attribute,
                propertyMap.getValueFunction
              ) ?? '';

            return (
              <PropertyForm
                changeNodeAttribute={changeNodeAttribute}
                node={node}
                key={propertyMap.label}
                value={
                  typeof innerValue === 'string'
                    ? innerValue
                    : innerValue.toString()
                }
                {...propertyMap}
              />
            );
          })}
        </div>

        <button
          type="button"
          className="block w100 txtcenter mpd-btn mpd-btn--secondary mpd-btn--small"
          onClick={this.duplicateCurrentNode}
        >
          Dupliquer
        </button>

        <button
          type="button"
          className="block w100 txtcenter mpd-btn mpd-btn--secondary mpd-btn--small"
          onClick={this.deleteCurrentNode}
        >
          Supprimer
        </button>

        {!isLast && <hr />}
      </div>
    );
  }
}

export default NodeDetail;
