import React from 'react';
import { MpdIcon } from '@mapado/makeup';
import { SeatGroupType } from '../../../../propTypes';

interface Props {
  seatGroup: SeatGroupType;
  onClick: () => void;
  tabIndex: number;
}

export default function SeatGroupSelectorRow({
  seatGroup,
  onClick,
  tabIndex,
}: Props): JSX.Element {
  return (
    <div
      className="contingent-selector__row"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={tabIndex}
      role="button"
    >
      <MpdIcon
        icon="seat-filled"
        color={seatGroup.seatColor ?? undefined}
        width="12"
        className="mr2"
      />
      {seatGroup.label}
    </div>
  );
}
