import {
  ContingentSelector,
  getEntityId,
  TicketingSdkContext,
} from '@mapado/js-component';
import { List } from 'immutable';
import { Contingent } from 'mapado-ticketing-js-sdk';
import React, { useCallback } from 'react';
import { addContingent } from '../../../../actions/SeatActions';
import TicketingSdkInstance from '../../../../TicketingSdkInstance';
import { contingentSelector } from '../../../../utils/selectors';
import {
  useSeatingDispatch,
  useSeatingSelector,
} from '../../../../reducers/typedFunctions';
import { useTranslation } from '../../../../i18n';

function ContingentSidebarActions({
  contractId,
  onUpdateContingents,
}: {
  contractId: string;
  onUpdateContingents?: (updatedContingentList: List<Contingent>) => void;
}) {
  const { t } = useTranslation();

  const contingentList: List<Contingent> | null =
    useSeatingSelector(contingentSelector);

  const dispatch = useSeatingDispatch();

  const handleAddContingent = useCallback(
    (contingent: Contingent) => {
      if (
        contingentList &&
        contingentList.find(
          (lc: Contingent) => getEntityId(lc) === getEntityId(contingent)
        )
      ) {
        return;
      }

      dispatch(addContingent(contingent));
    },
    [contingentList, dispatch]
  );

  return (
    <TicketingSdkContext.Provider
      value={TicketingSdkInstance.getDefaultSerializerSdk()}
    >
      <ContingentSelector
        onSubmitAdminModal={onUpdateContingents}
        onChange={handleAddContingent}
        labelClassName="mpd-btn mpd-btn--primary mpd-color-white"
        className="stock-contingent-button"
        contractId={contractId}
        label={t('stock_contingent.add_new_btn')}
        hiddenContingentIdList={contingentList?.map((c) => c['@id'] ?? '')}
      />
    </TicketingSdkContext.Provider>
  );
}

export default ContingentSidebarActions;
