import React, {
  ReactNode,
  createContext,
  useContext,
  ReactElement,
} from 'react';
import { CartAtomId } from '../redux/booking/constants';

const CartAtomIdContext = createContext<null | CartAtomId>(null);

export function CartAtomIdProvider({
  children,
  cartAtomId,
}: {
  children: ReactNode;
  cartAtomId: CartAtomId;
}): ReactElement {
  return (
    <CartAtomIdContext.Provider value={cartAtomId}>
      {children}
    </CartAtomIdContext.Provider>
  );
}

export const useCartAtomIdContext = (): CartAtomId => {
  const contextValue = useContext(CartAtomIdContext);

  if (contextValue === null) {
    throw new Error(
      'SelectedCardItemContext should be defined. Did you add a context provider up in the tree ?'
    );
  }

  return contextValue;
};
