import clone from 'clone';
import { List, Map } from 'immutable';
import moment, { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import EventDateClient from '../client/EventDateClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import AvailableSeat from './AvailableSeat';
import EventDatePattern from './EventDatePattern';
import LogicalSeatConfig, { FreeOrNumberedSeating } from './LogicalSeatConfig';
import NetworkEntity from './NetworkEntity';
import SeatConfig from './SeatConfig';
import SeatReserved from './SeatReserved';
import StockContingent from './StockContingent';
import TicketPrice from './TicketPrice';
import Ticketing from './Ticketing';
import type { BaseEntity, EntityRelation, PartialEntity } from '.';

export const TYPE_GENERAL_PUBLIC = 'general_public';
export const TYPE_SCHOOL = 'school';
export const TYPE_BUSINESS = 'business';
export const TYPE_OTHER = 'other';

type TYPE_GENERAL_PUBLIC = 'general_public';
type TYPE_SCHOOL = 'school';
type TYPE_BUSINESS = 'business';
type TYPE_OTHER = 'other';

export type EVENT_DATE_TYPE_LIST =
  | TYPE_GENERAL_PUBLIC
  | TYPE_SCHOOL
  | TYPE_BUSINESS
  | TYPE_OTHER;

export enum EVENT_DATE_STATUS {
  CLOSED = 'closed',
  DRAFT = 'draft',
  OPENED = 'opened',
}

// it is not possible to extend enums in Typescript
// we have to create a new enum with the same values
// and the additional ones.
// We could create a type made of the union of the two enums
// but we could not access and use its values as we do with enums
export enum EVENT_DATE_AVAILABILITY_STATUS {
  CLOSED = 'closed',
  DRAFT = 'draft',
  OPENED = 'opened',
  SALE_SCHEDULED = 'saleScheduled',
  MULTIPLE = 'multiple',
}

export type EventDateType = FreeOrNumberedSeating &
  BaseEntity<'EventDate'> & {
    unlimitedStock: null | boolean;
    totalStock: null | number;
    manuallySetStock: null | boolean;
    bookableStock: null | number;
    notInStockContingentBookableStock: null | number;
    contingentBookableStock: null | Map<string, number>;
    remainingStock: null | number;
    startDate: null | Moment;
    endDate: null | Moment;
    startOfEventDay: null | Moment;
    endOfEventDay: null | Moment;
    saleStartDate: null | Moment;
    saleEndDate: null | Moment;
    ticketing: null | EntityRelation<Ticketing>;
    eventDatePattern: null | EntityRelation<EventDatePattern>;
    ticketPriceList: null | List<EntityRelation<TicketPrice>>;
    seatReservedList: null | List<EntityRelation<SeatReserved>>;
    availableSeatList: null | List<EntityRelation<AvailableSeat>>;
    stockContingentList: null | List<EntityRelation<StockContingent>>;
    status: null | EVENT_DATE_STATUS;
    availabilityStatus?: EVENT_DATE_AVAILABILITY_STATUS;
    type: null | EVENT_DATE_TYPE_LIST;
    seatConfig: null | EntityRelation<SeatConfig>;
    logicalSeatConfig: null | EntityRelation<LogicalSeatConfig>;
    seatingName: null | string;
    createdAt: null | Moment;
    updatedAt: null | Moment;
    soldTickets: null | number;
    bookedTickets: null | number;
    importedTickets: null | number;
    reservedTickets: null | number;
    refundedTickets: null | number;
    soldTicketsExStock?: number;
    refundedTicketsExStock?: number;
    issuedTicketsExStock?: number;
    bookedTicketsExStock?: number;
    reservedTicketsExStock?: number;
    bookedItems?: number;
    reservedItems?: number;
    bookedItemsExStock?: number;
    reservedItemsExStock?: number;
    soldItems?: number;
    refundedItems?: number;
    issuedItems?: number;
    soldItemsExStock?: number;
    refundedItemsExStock?: number;
    issuedItemsExStock?: number;
    nbActiveSeats: null | number;
    dismissedStockContingentStock: null | undefined | number;
    turnover: Map<string, unknown>;
    onSale: null | boolean;
    hasTicketPriceOnSale: null | boolean;
    onSaleDemoMode: null | boolean;
    issuedTickets: null | number;
    notOnSaleReasons: List<string>;
    dynamic: null | boolean;
    scannedTicketCount: null | number;
    isReservableOnline: null | boolean;
  };

export type EventDateNonNullProps =
  | '@id'
  | 'ticketing'
  | 'status'
  | 'scannedTicketCount'
  | 'createdAt'
  | 'updatedAt'
  | 'isReservableOnline'
  | 'unlimitedStock';

export type EventDateConstructorType = Omit<
  EventDateType,
  | 'startDate'
  | 'endDate'
  | 'startOfEventDay'
  | 'endOfEventDay'
  | 'saleStartDate'
  | 'saleEndDate'
  | 'createdAt'
  | 'updatedAt'
> & {
  startDate: null | string | Moment;
  endDate: null | string | Moment;
  startOfEventDay: null | string | Moment;
  endOfEventDay: null | string | Moment;
  saleStartDate: null | string | Moment;
  saleEndDate: null | string | Moment;
  createdAt: null | string | Moment;
  updatedAt: null | string | Moment;
};

class EventDate extends NetworkEntity<EventDateType>({
  '@id': null,
  '@type': 'EventDate',
  unlimitedStock: true,
  totalStock: null,
  manuallySetStock: null,
  bookableStock: null,
  notInStockContingentBookableStock: null,
  contingentBookableStock: null,
  remainingStock: null,
  startDate: null,
  endDate: null,
  startOfEventDay: null,
  endOfEventDay: null,
  saleStartDate: null,
  saleEndDate: null,
  ticketing: new Ticketing(),
  eventDatePattern: new EventDatePattern(),
  ticketPriceList: List<TicketPrice>(),
  seatReservedList: List<SeatReserved>(),
  availableSeatList: List<AvailableSeat>(),
  stockContingentList: null,
  status: null,
  type: null,
  logicalSeatConfig: null,
  seatConfig: null,
  seatingName: null,
  createdAt: null,
  updatedAt: null,
  soldTickets: 0,
  bookedTickets: 0,
  importedTickets: 0,
  reservedTickets: 0,
  refundedTickets: 0,
  soldTicketsExStock: undefined,
  refundedTicketsExStock: undefined,
  issuedTicketsExStock: undefined,
  bookedTicketsExStock: undefined,
  reservedTicketsExStock: undefined,
  bookedItems: undefined,
  reservedItems: undefined,
  bookedItemsExStock: undefined,
  reservedItemsExStock: undefined,
  soldItems: undefined,
  refundedItems: undefined,
  issuedItems: undefined,
  soldItemsExStock: undefined,
  refundedItemsExStock: undefined,
  issuedItemsExStock: undefined,
  nbActiveSeats: null,
  dismissedStockContingentStock: undefined,
  turnover: Map<string, unknown>(),
  onSale: false,
  hasTicketPriceOnSale: false,
  onSaleDemoMode: false,
  issuedTickets: 0,
  notOnSaleReasons: List<string>(),
  dynamic: false,
  scannedTicketCount: null,
  isReservableOnline: null,
  isNumberedSeating: null,
  availabilityStatus: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<EventDateConstructorType> = {
      '@id': null,
      '@type': 'EventDate',
    }
  ) {
    const data = clone(val);

    const tz =
      typeof data.ticketing === 'object' && data.ticketing !== null
        ? data.ticketing.timezone
        : null;

    data.startDate = parseDate(data.startDate, tz);
    data.endDate = parseDate(data.endDate, tz);
    data.startOfEventDay = parseDate(data.startOfEventDay, tz);
    data.endOfEventDay = parseDate(data.endOfEventDay, tz);
    data.saleStartDate = parseDate(data.saleStartDate, tz);
    data.saleEndDate = parseDate(data.saleEndDate, tz);
    data.createdAt = parseDate(data.createdAt, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);

    if (
      typeof data.contingentBookableStock === 'object' &&
      data.contingentBookableStock !== null
    ) {
      data.contingentBookableStock = Map(data.contingentBookableStock);
    }

    // @ts-expect-error -- need to fix the moment converted values from the constructor
    super(data);

    // @ts-expect-error -- need to fix the moment converted values from the constructor
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/event_dates/', '') || '';
  }

  getRemainingStock(): null | number {
    if (this.get('unlimitedStock')) {
      return null;
    }

    return this.get('remainingStock');
  }

  getBookableStock(): null | number {
    if (this.get('unlimitedStock')) {
      return null;
    }

    return this.get('bookableStock');
  }

  isPassed(): boolean {
    if (!this.startDate) {
      return false;
    }

    const tz = this.getIn(['ticketing', 'timezone']);

    if (!tz) {
      return this.startDate < moment();
    }

    /** @ts-expect-error -- conflict between moment and moment-timezone */
    return this.startDate.clone().tz(tz) < moment().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalStartDate(): null | Moment {
    if (!this.startDate) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.startDate.clone().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalEndDate(): null | Moment {
    if (!this.endDate) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.endDate.clone().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalStartOfEventDay(): null | Moment {
    if (!this.startOfEventDay) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.startOfEventDay.clone().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalEndOfEventDay(): null | Moment {
    if (!this.endOfEventDay) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.endOfEventDay.clone().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalSaleStartDate(): null | Moment {
    if (!this.saleStartDate) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.saleStartDate.clone().tz(tz);
  }

  /**
   * @deprecated
   */
  getLocalSaleEndDate(): null | Moment {
    if (!this.saleEndDate) {
      return null;
    }

    const tz = this.getIn(['ticketing', 'timezone']);
    if (!tz) {
      return null;
    }

    /** @ts-expect-error -- timezone issue with moment */
    return this.saleEndDate.clone().tz(tz);
  }
}

EventDate.classMetadata = new ClassMetadata(
  'eventDate',
  'event_dates',

  /** @ts-expect-error -- method signature are incompatible */
  EventDateClient
);

EventDate.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('unlimitedStock', 'unlimitedStock', 'boolean'),
  new Attribute('totalStock', 'totalStock', 'integer'),
  new Attribute('manuallySetStock', 'manuallySetStock', 'boolean'),
  new Attribute('bookableStock', 'bookableStock', 'integer'),
  new Attribute(
    'notInStockContingentBookableStock',
    'notInStockContingentBookableStock',
    'integer'
  ),
  new Attribute('contingentBookableStock', 'contingentBookableStock', 'object'),
  new Attribute('remainingStock', 'remainingStock', 'integer'),
  new Attribute('startDate', 'startDate', 'datetime'),
  new Attribute('endDate', 'endDate', 'datetime'),
  new Attribute('saleStartDate', 'saleStartDate', 'datetime'),
  new Attribute('saleEndDate', 'saleEndDate', 'datetime'),
  new Attribute('status'),
  new Attribute('availabilityStatus'),
  new Attribute('type'),
  new Attribute('seatingName'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('soldTickets', 'soldTickets', 'integer'),
  new Attribute('refundedTickets', 'refundedTickets', 'integer'),
  new Attribute('issuedTickets', 'issuedTickets', 'integer'),
  new Attribute('soldTicketsExStock', 'soldTicketsExStock', 'integer'),
  new Attribute('refundedTicketsExStock', 'refundedTicketsExStock', 'integer'),
  new Attribute('issuedTicketsExStock', 'issuedTicketsExStock', 'integer'),
  new Attribute('bookedTickets', 'bookedTickets', 'integer'),
  new Attribute('reservedTickets', 'reservedTickets', 'integer'),
  new Attribute('bookedTicketsExStock', 'bookedTicketsExStock', 'integer'),
  new Attribute('reservedTicketsExStock', 'reservedTicketsExStock', 'integer'),
  new Attribute('bookedItems', 'bookedItems', 'integer'),
  new Attribute('reservedItems', 'reservedItems', 'integer'),
  new Attribute('bookedItemsExStock', 'bookedItemsExStock', 'integer'),
  new Attribute('reservedItemsExStock', 'reservedItemsExStock', 'integer'),
  new Attribute('soldItems', 'soldItems', 'integer'),
  new Attribute('refundedItems', 'refundedItems', 'integer'),
  new Attribute('issuedItems', 'issuedItems', 'integer'),
  new Attribute('soldItemsExStock', 'soldItemsExStock', 'integer'),
  new Attribute('refundedItemsExStock', 'refundedItemsExStock', 'integer'),
  new Attribute('issuedItemsExStock', 'issuedItemsExStock', 'integer'),
  new Attribute('importedTickets', 'importedTickets', 'integer'),
  new Attribute('nbActiveSeats', 'nbActiveSeats', 'integer'),
  new Attribute(
    'dismissedStockContingentStock',
    'dismissedStockContingentStock',
    'integer'
  ),
  new Attribute('onSale', 'onSale', 'boolean'),
  new Attribute('hasTicketPriceOnSale', 'hasTicketPriceOnSale', 'boolean'),
  new Attribute('onSaleDemoMode', 'onSaleDemoMode', 'boolean'),
  new Attribute('issuedTickets', 'issuedTickets', 'integer'),
  new Attribute('notOnSaleReasons', 'notOnSaleReasons', 'array'),
  new Attribute('dynamic', 'dynamic', 'boolean'),
  new Attribute('isReservableOnline', 'isReservableOnline', 'boolean'),
  new Attribute('isNumberedSeating', 'isNumberedSeating', 'boolean'),
  new Attribute('scannedTicketCount', 'scannedTicketCount', 'integer'),
]);
EventDate.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'availableSeat', 'availableSeatList'),
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(Relation.MANY_TO_ONE, 'eventDatePattern', 'eventDatePattern'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(Relation.ONE_TO_MANY, 'seatReserved', 'seatReservedList'),
  new Relation(Relation.MANY_TO_ONE, 'logicalSeatConfig', 'logicalSeatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.ONE_TO_MANY, 'stockContingent', 'stockContingentList'),
]);

export default EventDate;
