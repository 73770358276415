import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCashDrawer = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 26" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-102 -977)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="caisse"
            d="M10.667 172h18.666v-13.333H10.667zM32 172h4v9.333H4V172h4v-16h24zm0 2.667H6.667v4h26.666v-4zM21.333 160H24v2.667h-2.667zm0 4H24v2.667h-2.667zm-2.666 12h2.666v1.333h-2.666zm6.666-16H28v2.667h-2.667zm0 4H28v2.667h-2.667zm-8-4H20v2.667h-2.667zm0 4H20v2.667h-2.667zm4 4H24v2.667h-2.667zm4 0H28v2.667h-2.667zm-8 0H20v2.667h-2.667z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCashDrawer;
