import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 29 25" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-313 -1044)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="home"
            d="m229.267 223-13.934 12.604 1.991 1.801 3.343-3.024v12.8H238v-12.678l3.208 2.902 1.992-1.8-11.944-10.805zm-5.934 8.968 5.934-5.367 6.066 5.488v12.676h-12z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgHome;
