import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { Order } from 'mapado-ticketing-js-sdk';

const OrderContext = createContext<Order | null>(null);

export function useOrderContext(): Order | null {
  return useContext(OrderContext);
}

export function OrderContextProvider({
  order,
  children,
}: {
  order: Order | null;
  children: ReactNode;
}): ReactElement {
  return (
    <OrderContext.Provider value={order}>{children}</OrderContext.Provider>
  );
}
