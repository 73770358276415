import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, PartialEntity } from '../..';
import SibilParameterClient from '../../../client/Module/SibilParameterClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';

export type SibilParameterType = BaseEntity<
  'SibilParameter',
  `/v1/module/sibil/parameters/${number}`
> & {
  name: null | string;
  sibilUsername: null | string;
  sibilPassword: null | string;
  contract: null | string;
};

const defaultValues: SibilParameterType = {
  '@id': null,
  '@type': 'SibilParameter',
  name: null,
  sibilUsername: null,
  sibilPassword: null,
  contract: null,
};

const SibilParameterRecordFactory = NetworkEntity<SibilParameterType>(
  defaultValues
);

class SibilParameter extends SibilParameterRecordFactory {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<SibilParameterType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string | null {
    return this.get('@id')?.replace('/v1/module/sibil/parameters/', '') || null;
  }
}

SibilParameter.classMetadata = new ClassMetadata(
  'sibilParameter',
  'module/sibil/parameters',
  /** @ts-expect-error -- method signature are incompatible */
  SibilParameterClient
);

SibilParameter.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('sibilUsername'),
  new Attribute('sibilPassword'),
]);

SibilParameter.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default SibilParameter;
