import React, { ReactNode, ReactElement } from 'react';
import cn from 'classnames';
import SidebarTemplate from './SidebarTemplate';
import SidebarTab from './SidebarTab';
import { STOCK_MANAGEMENT_TABS } from '../../contexts/StockManagementContext';

type Props = {
  onClose?: () => void;
  onChangeTab?: (tabname: STOCK_MANAGEMENT_TABS) => void;
  className?: string;
  tabs: {
    name: STOCK_MANAGEMENT_TABS;
    label: string;
    element: ReactNode;
    sidebarAction?: ReactElement | null;
  }[];
};

function TabbedSidebar({
  tabs,
  className,
  onClose,
  onChangeTab,
}: Props): ReactElement {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { sidebarAction } = tabs[selectedTab];

  return (
    <SidebarTemplate
      action={sidebarAction}
      className={cn('mpd-seating-tabbed-sidebar', className)}
      onClose={onClose}
    >
      <div className="mpd-seating-sidebar-tabs">
        {tabs.map((tab, index) => (
          <SidebarTab
            key={`sidebar-tab-${tab.name}`}
            onClick={() => {
              setSelectedTab(index);

              if (typeof onChangeTab === 'function') {
                onChangeTab(tab.name);
              }
            }}
            className="p3"
            selected={selectedTab === index}
          >
            {tab.label}
          </SidebarTab>
        ))}
      </div>
      {tabs[selectedTab].element}
    </SidebarTemplate>
  );
}

export default TabbedSidebar;
