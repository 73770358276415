import React, { memo } from 'react';
import { MpdIcon, MpdIconType } from '@mapado/makeup';
import { DOMAIN_CONTEXT, useDomainContext } from '@mapado/js-component';
import { SEAT_TYPE } from 'mapado-ticketing-js-sdk';

type Props = {
  icon: MpdIconType;
  width: number;
  fill: string;
  type: SEAT_TYPE;
  hasObstructedView: boolean;
};

const SeatIcon = memo(function SeatIcon({
  icon,
  width,
  fill,
  type,
  hasObstructedView,
}: Props): JSX.Element | null {
  const { currentDomain } = useDomainContext();
  const isDesk = currentDomain === DOMAIN_CONTEXT.DESK;

  // on desk, use any icon given by the desk, on minisite, do use the seat-filled icon all the times
  const defaultIcon = isDesk ? icon : 'seat-filled';

  const getIconBasedOnType = () => {
    switch (type) {
      case SEAT_TYPE.FOLDABLE:
        return hasObstructedView
          ? 'seat-foldable-obstructed-view'
          : 'seat-foldable-filled';
      case SEAT_TYPE.STANDING:
        if (hasObstructedView) {
          return 'seat-standing-obstructed-view';
        }

        return isDesk ? 'seat-standing' : null;
      case SEAT_TYPE.REDUCED_MOBILITY:
        return hasObstructedView
          ? 'reduced-mobility-obstructed-view'
          : 'reduced-mobility';
      case SEAT_TYPE.NORMAL:
        return hasObstructedView ? 'seat-obstructed-view' : defaultIcon;
      default:
        return defaultIcon;
    }
  };

  const finalIcon = getIconBasedOnType();

  if (!finalIcon) {
    return null;
  }

  return (
    <MpdIcon
      icon={defaultIcon !== 'seat-filled' ? icon : finalIcon}
      width={`${width}`}
      color={fill}
    />
  );
});

export default SeatIcon;
