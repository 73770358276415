import clone from 'clone';
import { Map, Record } from 'immutable';
import EventDate from './EventDate';
import type { EntityRelation } from '.';

export enum EVENT_DATE_OPTION_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export type EventDateOptionStatusType = {
  status: EVENT_DATE_OPTION_STATUS | undefined;
  requestedEventDate: EntityRelation<EventDate> | undefined;
  approvedEventDate: EntityRelation<EventDate> | undefined;
  requestedParticipantNb?: number | undefined;
  requestedParticipantNbPerTicketPriceHash?: Map<string, number>;
  requestedParticipantNbPerContingent?: Map<string, number>;
  approvedParticipantNb?: number | undefined;
};

class EventDateOptionStatus extends Record<EventDateOptionStatusType>({
  status: undefined,
  requestedEventDate: undefined,
  approvedEventDate: undefined,
  requestedParticipantNb: undefined,
  approvedParticipantNb: undefined,
  requestedParticipantNbPerTicketPriceHash: undefined,
  requestedParticipantNbPerContingent: undefined,
}) {
  constructor(val: EventDateOptionStatusType) {
    const data = clone(val);

    if (
      typeof data.requestedParticipantNbPerTicketPriceHash === 'object' &&
      data.requestedParticipantNbPerTicketPriceHash !== null
    ) {
      data.requestedParticipantNbPerTicketPriceHash = Map(
        data.requestedParticipantNbPerTicketPriceHash
      );
    }

    if (
      typeof data.requestedParticipantNbPerContingent === 'object' &&
      data.requestedParticipantNbPerContingent !== null
    ) {
      data.requestedParticipantNbPerContingent = Map(
        data.requestedParticipantNbPerContingent
      );
    }

    if (
      typeof data.requestedEventDate === 'object' &&
      data.requestedEventDate !== null
    ) {
      data.requestedEventDate = new EventDate(data.requestedEventDate);
    }

    if (
      typeof data.approvedEventDate === 'object' &&
      data.approvedEventDate !== null
    ) {
      data.approvedEventDate = new EventDate(data.approvedEventDate);
    }

    super(data);
  }
}

export default EventDateOptionStatus;
