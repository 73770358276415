import { Attribute, ClassMetadata } from 'rest-client-sdk';
import VersionClient from '../client/VersionClient';
import NetworkEntity from './NetworkEntity';
import { BaseEntity } from '.';

export type VersionType = BaseEntity<'Version'> & {
  name: null | string;
  version: null | string;
};

class Version extends NetworkEntity<VersionType>({
  '@id': null,
  '@type': 'Version',
  name: null,
  version: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Version.classMetadata = new ClassMetadata('version', 'versions', VersionClient);
Version.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('name'),
  new Attribute('version'),
]);

export default Version;
