import clone from 'clone';
import { List, Map, MapOf } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import CartItemClient from '../client/CartItemClient';
import EntityRegistry from '../entityFactory/EntityRegistry';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { FormCustomerField, FormData } from '../types/FormDataType';
import { isEmptyArrayOrList } from '../utils';
import { getParticipantFormData } from '../utils/formData';
import AvailableSeat from './AvailableSeat';
import Cart from './Cart';
import Customer from './Customer';
import NetworkEntity from './NetworkEntity';
import Seat from './Seat';
import StockContingent from './StockContingent';
import Ticket from './Ticket';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type CartItemType = BaseEntity<'CartItem'> & {
  amountForPro: null | number;
  data: null | Record<string, unknown> | Map<string, unknown>;
  cart: Cart;
  customerPaidAmount: null | number;
  facialValue: null | number;
  quantity: number;
  valid: null | boolean;
  ticketPrice: null | EntityRelation<TicketPrice>;
  ticketList: null | List<EntityRelation<Ticket>>;
  seatList: List<EntityRelation<Seat>>;
  ticketPriceValid: boolean;
  totalFees: null | number;
  groupKey: null | string;
  stockContingent: null | EntityRelation<StockContingent>;
  seatReservedList: null | List<EntityRelation<AvailableSeat>>;
  availableSeatList: null | List<EntityRelation<AvailableSeat>>;
  participantRequiredFields: null | List<Map<string, unknown>>;
  participant: null | EntityRelation<Customer>;
  /**
   * formData is a helper that does not exist in the API, but help the front to handle participant form data
   */
  participantFormData: null | MapOf<FormData> | undefined;
};

class CartItem extends NetworkEntity<CartItemType>({
  '@id': null,
  '@type': 'CartItem',
  amountForPro: null,
  data: null,
  cart: new Cart(),
  customerPaidAmount: null,
  facialValue: null,
  quantity: 0,
  valid: null,
  ticketPrice: new TicketPrice(),
  ticketList: List<Ticket>(),
  seatList: List<Seat>(),
  ticketPriceValid: false,
  totalFees: null,
  groupKey: null,
  stockContingent: null,
  seatReservedList: List<AvailableSeat>(),
  availableSeatList: null,
  participantRequiredFields: null,
  participant: null,
  participantFormData: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CartItemType> = { '@id': null, '@type': 'CartItem' },
    registry?: EntityRegistry
  ) {
    const data = clone(val);

    if (isEmptyArrayOrList(data.data)) {
      data.data = {};
    }

    data.participantFormData = getParticipantFormData(
      // @ts-expect-error participantRequiredFields and partitipant type issues
      data.participant,
      data.participantRequiredFields
    );

    super(data);

    return mapEntityRelationShips(this, data, registry);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/cart_items/', '') || '';
  }

  getFormParticipantFields(
    disabledFields = false,
    optionalFields?: boolean
  ): FormCustomerField[] {
    if (!this.participantRequiredFields) {
      throw new Error(
        'ParticipantRequiredFields must be defined. Please check your request fields.'
      );
    }

    const participantRequiredFields = this.participantRequiredFields.toJS() as FormCustomerField[];
    const formattedFieldList = participantRequiredFields.map(
      (field: FormCustomerField) => {
        const fieldWithFormattedKey = Object.keys(field).reduce((acc, key) => {
          const formattedKey = key.startsWith('form')
            ? key.replace('form', '').toLowerCase()
            : key;

          return { ...acc, [formattedKey]: field[key] };
        }, field);

        const fieldOptions = field.options
          ? {
              ...field.options,
              inputProps: {
                ...field.options.inputProps,
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              options: field.options.options,
              isRequired: optionalFields ? false : field.required,
            }
          : {
              inputProps: {
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              isRequired: optionalFields ? false : field.required,
            };

        return {
          ...fieldWithFormattedKey,
          name: field.slug,
          options: fieldOptions,
        };
      }
    );

    return formattedFieldList;
  }
}

CartItem.classMetadata = new ClassMetadata(
  'cartItem',
  'cart_items',
  /** @ts-expect-error -- method signature are incompatible */
  CartItemClient
);
CartItem.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('amountForPro', 'amountForPro', 'integer'),
  new Attribute('data', 'data', 'object'),
  new Attribute('customerPaidAmount', 'customerPaidAmount', 'integer'),
  new Attribute('facialValue', 'facialValue', 'integer'),
  new Attribute('quantity', 'quantity', 'integer'),
  new Attribute('valid', 'valid', 'boolean'),
  new Attribute('ticketPriceValid', 'ticketPriceValid', 'boolean'),
  new Attribute('totalFees', 'totalFees', 'integer'),
  new Attribute('groupKey'),
  new Attribute(
    'participantRequiredFields',
    'participantRequiredFields',
    'object'
  ),
]);
CartItem.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'cart', 'cart'),
  new Relation(Relation.ONE_TO_MANY, 'seat', 'seatList'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPrice', 'ticketPrice'),
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.ONE_TO_MANY, 'seatReserved', 'seatReservedList'),
  new Relation(Relation.ONE_TO_MANY, 'availableSeat', 'availableSeatList'),
  new Relation(Relation.MANY_TO_ONE, 'stockContingent', 'stockContingent'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'participant'),
]);

export default CartItem;
