import React, { ReactNode, HTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  active?: boolean;
  selected?: boolean;
  leftBorderColor?: string;
};

const MpdCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      /* eslint-disable */
      className = '',
      children,
      active = false,
      selected = false,
      leftBorderColor,
      /* eslint-enable */
      ...props
    },
    ref
  ): JSX.Element => {
    const classnames = cn('mpd-card', className, {
      active,
      selected,
    });

    const leftBorderSelectedStyle =
      leftBorderColor && selected ? { borderColor: leftBorderColor } : {};

    return (
      <div
        ref={ref}
        className={classnames}
        style={leftBorderSelectedStyle}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {leftBorderColor && (
          <div
            className="left-border"
            style={{ backgroundColor: leftBorderColor }}
          />
        )}
        {children}
      </div>
    );
  }
);

export default MpdCard;
