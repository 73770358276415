const Config = {
  oauth: {
    path: 'oauth2.mapado.com/oauth/v2/token',
    scheme: 'https',
  },

  ticketing: {
    path: 'ticketing.mapado.net',
    scheme: 'https',
  },

  features: {
    mockApi: false,
  },
};

// if (process.env.NODE_ENV === 'development') {
//   Config.ticketing.path = 'ticketing.preprod.mapado.com:81';
//   Config.ticketing.scheme = 'http';
// }

export default Config;
