import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class CustomerStatusClient extends AbstractClient<
  TSMetadata['customerStatus']
> {
  getPathBase(): string {
    return '/v1/customer_statuses';
  }

  getDefaultGroups(): Array<string> {
    return ['customer_status_read', 'customer_status_write'];
  }
}

export default CustomerStatusClient;
