import { List } from 'immutable';
import {
  AVAILABLE_SEAT_FIELDS_LITE,
  SEAT_FIELDS,
  SEAT_GROUP_FIELDS,
  STOCK_CONTINGENT_FIELDS,
} from './fields';
import TicketingSdkInstance from '../TicketingSdkInstance';
import {
  AvailableSeatType,
  Id,
  SeatGroupType,
  StockContingentType,
  SeatType,
} from '../propTypes';

export type GetAvailableSeatListForEventDate = {
  currentCartId?: Id | null; // covid19 context
  currentOrderId?: Id | null; // covid19 context
};

export default async function getAvailableSeatListForEventDate(
  eventDateId: Id,
  options?: GetAvailableSeatListForEventDate
): Promise<List<AvailableSeatType>> {
  const seatGroupPromise = TicketingSdkInstance.getSdk()
    .getRepository('seatGroup')
    .findBy(
      {
        availableSeatListEventDate: eventDateId,
        showHidden: true,
      },
      SEAT_GROUP_FIELDS
    );

  const stockContingentPromise =
    // fetch stockContingent only on desk
    TicketingSdkInstance.getSdk().getRepository('stockContingent').findBy(
      {
        availableSeatListEventDate: eventDateId,
        'order[contingent.name]': 'ASC',
      },
      STOCK_CONTINGENT_FIELDS
    );

  const availableSeatPromise = TicketingSdkInstance.getSdk()
    .getRepository('availableSeat')
    .findBy(
      {
        eventDate: eventDateId,
        currentCart: options?.currentCartId,
        currentOrder: options?.currentOrderId,
      },
      AVAILABLE_SEAT_FIELDS_LITE
    );

  const seatPromise = TicketingSdkInstance.getSdk()
    .getRepository('seat')
    .findBy(
      {
        availableSeatListEventDate: eventDateId,
      },
      SEAT_FIELDS
    );

  const [seatGroupColl, stockContingentColl, seatColl, availableSeatColl] =
    await Promise.all([
      seatGroupPromise,
      stockContingentPromise,
      seatPromise,
      availableSeatPromise,
    ]);

  const updatedAvailableSeatList = availableSeatColl
    .get('hydra:member')
    // @ts-expect-error -- custom serializer typing error
    .map((availableSeat: AvailableSeatType): AvailableSeatType => {
      // @ts-expect-error -- custom serializer typing error
      const seatGroup: null | SeatGroupType =
        seatGroupColl
          .get('hydra:member')
          // @ts-expect-error -- custom serializer typing error
          .find((sg) => sg['@id'] === availableSeat.seatGroup) ?? null;

      // @ts-expect-error -- custom serializer typing error
      const stockContingent: null | StockContingentType =
        stockContingentColl
          .getMembers()
          .find((sc) => sc.get('@id') === availableSeat.stockContingent) ??
        null;

      // @ts-expect-error -- custom serializer typing error
      const seat: null | SeatType =
        seatColl
          .get('hydra:member')
          // @ts-expect-error -- custom serializer typing error
          .find((s) => s['@id'] === availableSeat.seat) ?? null;

      if (typeof seat === 'undefined') {
        throw new Error(
          `Seat ${availableSeat.seat} not found in seatColl for eventDate ${eventDateId}. This should not happen.`
        );
      }

      return {
        ...availableSeat,
        seatGroup,
        stockContingent,
        seat,
      };
    });

  return updatedAvailableSeatList;
}
