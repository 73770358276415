import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReporting = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-383 -977)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="suivie"
            d="M294.667 177.333h4v-14.666h2.666v14.666h4V160H308v17.333h4V156h-24v21.333h4v-12h2.667zM285.333 180v-24h29.334v24z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgReporting;
