import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderSeatConfigMappingClient extends AbstractClient<
  TSMetadata['ProviderSeatConfigMapping']
> {
  getPathBase(): string {
    return '/v1/provider_seat_config_mappings';
  }
}

export default ProviderSeatConfigMappingClient;
