import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import StockContingentClient from '../client/StockContingentClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import AvailableSeat from './AvailableSeat';
import Contingent from './Contingent';
import Contract from './Contract';
import EventDate from './EventDate';
import type { HidableType } from './HidableType';
import LogicalSeatConfig from './LogicalSeatConfig';
import NetworkEntity from './NetworkEntity';
import Seat from './Seat';
import SeatConfig from './SeatConfig';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type StockContingentType = HidableType &
  BaseEntity<'StockContingent'> & {
    totalStock: null | number;
    bookableStock: null | number;
    dismissedStock: null | number;
    bookedTickets: null | number;
    soldTickets: null | number;
    reservedTickets: null | number;
    draft: null | boolean;
    seatConfig: null | EntityRelation<SeatConfig>;
    logicalSeatConfig: null | EntityRelation<LogicalSeatConfig>;
    eventDate: null | EntityRelation<EventDate>;
    childrenList: null | List<EntityRelation<StockContingent>>;
    availableSeatList: null | List<EntityRelation<AvailableSeat>>;
    seatList: null | List<EntityRelation<Seat>>;
    parent: null | EntityRelation<StockContingent>;
    contingent: null | EntityRelation<Contingent>;
    /** @deprecated use `contingent.name` instead */
    name: null | string;
    /** @deprecated use `contingent.color` instead */
    color: null | string;
    /** @deprecated use `contingent.contract` instead */
    contract: null | EntityRelation<Contract>;
  };
class StockContingent extends NetworkEntity<StockContingentType>({
  '@id': null,
  '@type': 'StockContingent',
  totalStock: null,
  bookableStock: null,
  dismissedStock: null,
  bookedTickets: null,
  soldTickets: null,
  reservedTickets: null,
  draft: null,
  contingent: null,
  seatConfig: null,
  logicalSeatConfig: null,
  eventDate: null,
  childrenList: null,
  availableSeatList: null,
  seatList: null,
  parent: null,
  /** @deprecated use `contingent.name` instead */
  name: null,
  /** @deprecated use `contingent.color` instead */
  color: null,
  /** @deprecated use `contingent.contract` instead */
  contract: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<StockContingentType> = {
      '@id': null,
      '@type': 'StockContingent',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/stock_contingents/', '') || '';
  }
}

StockContingent.classMetadata = new ClassMetadata(
  'stockContingent',
  'stock_contingents',
  /** @ts-expect-error -- method signature are incompatible */
  StockContingentClient
);
StockContingent.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('totalStock', 'totalStock', 'int'),
  new Attribute('bookableStock', 'bookableStock', 'int'),
  new Attribute('dismissedStock', 'dismissedStock', 'int'),
  new Attribute('bookedTickets', 'bookedTickets', 'int'),
  new Attribute('reservedTickets', 'reservedTickets', 'int'),
  new Attribute('soldTickets', 'soldTickets', 'int'),
  new Attribute('draft', 'draft', 'boolean'),
  new Attribute('name'),
  new Attribute('color'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);
StockContingent.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'contingent', 'contingent'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'logicalSeatConfig', 'logicalSeatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'stockContingent', 'parent'),
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'eventDatePattern', 'eventDatePattern'),
  new Relation(Relation.ONE_TO_MANY, 'stockContingent', 'childrenList'),
  new Relation(Relation.ONE_TO_MANY, 'seat', 'seatList'),
  new Relation(Relation.ONE_TO_MANY, 'availableSeat', 'availableSeatList'),
]);

export default StockContingent;
