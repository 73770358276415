import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { getEntityId } from '@mapado/js-component';
import { Contingent } from 'mapado-ticketing-js-sdk';
import { useViewContext, View } from './ViewContext';
import { useSelectedSeatGroupContext } from './SelectedSeatGroupContext';
import { STOCK_MANAGEMENT_TABS } from './StockManagementContext';

type ContextProps = {
  selectedContingent: Contingent | null;
  setSelectedContingent: Dispatch<SetStateAction<Contingent | null>>;
  handleChangePane: (pane: string) => void;
  currentPane: string;
  displayDrawButton: boolean;
  handleSelectContingent: (contingent: Contingent) => void;
};

const defaultValue: ContextProps = {
  selectedContingent: null,
  setSelectedContingent: () => null,
  handleChangePane: () => null,
  currentPane: STOCK_MANAGEMENT_TABS.CONTINGENT,
  displayDrawButton: false,
  handleSelectContingent: () => null,
};

const LogicalSeatConfigAdminContext = createContext(defaultValue);

export const useLogicalSeatConfigAdminContext = (): ContextProps =>
  useContext(LogicalSeatConfigAdminContext);

export function LogicalSeatConfigAdminContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentPane, setCurrentPane] = useState<string>(
    STOCK_MANAGEMENT_TABS.CONTINGENT
  );
  const [selectedContingent, setSelectedContingent] =
    useState<Contingent | null>(null);

  const { selectedSeatGroup, unselectSeatGroup } =
    useSelectedSeatGroupContext();

  const { onSelectView } = useViewContext();

  const handleChangePane = useCallback(
    (pane: string) => {
      switch (pane) {
        case STOCK_MANAGEMENT_TABS.CONTINGENT:
          onSelectView(View.Contingent);
          break;
        case STOCK_MANAGEMENT_TABS.SEAT_GROUP:
          onSelectView(View.SeatGroup);
          break;
        default:
          break;
      }

      setSelectedContingent(null);
      unselectSeatGroup();
      setCurrentPane(pane);
    },
    [onSelectView, unselectSeatGroup]
  );

  const handleSelectContingent = useCallback(
    (contingent: Contingent) => {
      if (
        selectedContingent &&
        getEntityId(contingent) === getEntityId(selectedContingent)
      ) {
        setSelectedContingent(null);
      } else {
        setSelectedContingent(contingent);
      }
    },
    [selectedContingent]
  );

  const displayDrawButton =
    (currentPane === STOCK_MANAGEMENT_TABS.CONTINGENT &&
      selectedContingent !== null) ||
    (currentPane === STOCK_MANAGEMENT_TABS.SEAT_GROUP &&
      selectedSeatGroup !== null) ||
    currentPane === STOCK_MANAGEMENT_TABS.GAUGE;

  const contextValue = useMemo(
    () => ({
      selectedContingent,
      setSelectedContingent,
      handleChangePane,
      currentPane,
      displayDrawButton,
      handleSelectContingent,
    }),
    [
      currentPane,
      displayDrawButton,
      handleChangePane,
      handleSelectContingent,
      selectedContingent,
    ]
  );

  return (
    <LogicalSeatConfigAdminContext.Provider value={contextValue}>
      {children}
    </LogicalSeatConfigAdminContext.Provider>
  );
}
