import { List } from 'immutable';
import { ORIGIN_TYPE_ENV_DESK } from '@mapado/js-component';
import {
  assertRelationIsString,
  CartItem,
  CartItemWithOffer,
} from 'mapado-ticketing-js-sdk';
import { ORIGIN_TYPE_ENV } from '../constants';
import type { TicketPriceQuantity } from './ticketPriceQuantities';

/**
 * Used to filter invalid CartItemWithOffer if not on desk.
 * Usage:
 * ```
 * const filtered = cart.cartItemWithOfferList
 *   .filter(filterInvalidCartItemExceptOnDesk(originType));
 * ```
 */
export const filterInvalidCartItemExceptOnDesk =
  (originTypeEnv: null | ORIGIN_TYPE_ENV) =>
  (cartItemWithOffer: CartItemWithOffer): boolean => {
    if (originTypeEnv === ORIGIN_TYPE_ENV_DESK) {
      return true;
    }

    return cartItemWithOffer.valid === true;
  };

/**
 * Convert TicketPriceQuantities to a list of CartItem.
 * This is the list that will be sent to the API, so we need to have a valid list of CartItem in the output.
 */
export function convertToCartItemList(
  currentTicketPriceQuantities: List<TicketPriceQuantity>
): List<CartItem> {
  return (
    currentTicketPriceQuantities
      .map((item): CartItem => {
        const ticketPrice = item.get('ticketPrice');
        const ticketPriceId = ticketPrice['@id'];

        assertRelationIsString(ticketPriceId, 'ticketPrice.@id');

        const availableSeat = item.get('availableSeat');

        return new CartItem({
          '@id': null,
          '@type': 'CartItem',
          ticketPrice,
          quantity: item.get('quantity'),
          groupKey: item.get('groupKey'),
          data: item.get('data'),
          stockContingent: item.getIn(['stockContingent', '@id']),
          facialValue: ticketPrice.facialValue,
          availableSeatList: availableSeat ? List([availableSeat]) : null,
        });
      })

      // explode CartItemWithQuantity to a list of CartItem with a quantity of only one
      // TODO use .flatMap https://immutable-js.com/docs/v4.1.0/List/#flatMap() instead of `.map().reduce()`
      .map((cartItem: CartItem): CartItem[] =>
        Array(cartItem.quantity).fill(cartItem.set('quantity', 1))
      )

      // then merge all arrays
      .reduce(
        (carry: List<CartItem>, cartItemList: Array<CartItem>) =>
          carry.concat(List(cartItemList)),
        List()
      )
  );
}
