import React, { ReactElement, SVGProps } from 'react';
import type { MpdIconType } from '../types/MpdIconType';
import { getIconTag } from './iconFactory';

type IconProps = SVGProps<SVGSVGElement> & {
  width?: string;
  height?: string;
  icon: MpdIconType;
};

function MpdIcon({
  icon,
  width = '16',
  height,
  className,
  ...rest
}: IconProps): ReactElement<SVGProps<SVGSVGElement>> | null {
  const mpdIconClassName = `mpd-icon mpd-icon--${icon}`;
  const allClassName = className
    ? `${mpdIconClassName} ${className}`
    : mpdIconClassName;

  const IconTag = getIconTag(icon);

  if (!IconTag) {
    return null;
  }

  return (
    <IconTag
      width={width}
      height={height || width}
      className={allClassName}
      style={{ minWidth: width }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

export default MpdIcon;
