import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 27 29" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-455 -1184)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="deco"
            d="M364.667 370.85a9.525 9.525 0 1 0 12.003.538l-.003-.003v-4.243a13 13 0 0 1 6.675 11.362c0 7.183-5.822 13.005-13.004 13.005s-13.005-5.822-13.005-13.005a13 13 0 0 1 6.785-11.423l.549-.3zm4-7.183h4V373h-4z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLogout;
