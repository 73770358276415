import { TSMetadata } from '../mapping';
import HidableClient from './HidableClient';

class SeatGroupClient extends HidableClient<TSMetadata['seatGroup']> {
  getPathBase(): string {
    return '/v1/seat_groups';
  }

  getDefaultGroups(): Array<string> {
    return ['seat_group_read', 'seat_group_write'];
  }
}

export default SeatGroupClient;
