import clone from 'clone';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Customer from './Customer';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type BookingItemType = BaseEntity<'BookingItem'> & {
  ticketIri: null | string;
  ticketPriceName: null | string;
  currency: null | string;
  ticketPriceFacialValue: null | number;
  seatId: null | number;
  seatLabel: null | string;
  participant: null | Customer;
  ticketBarcode: null | string;
  ticketCurrentValidScanNb: null | number;
};

class BookingItem extends NetworkEntity<BookingItemType>({
  '@id': null,
  '@type': 'BookingItem',
  ticketIri: null,
  ticketPriceName: null,
  currency: null,
  ticketPriceFacialValue: null,
  seatId: null,
  seatLabel: null,
  participant: null,
  ticketBarcode: null,
  ticketCurrentValidScanNb: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BookingItemType> = {
      '@id': null,
      '@type': 'BookingItem',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }
}

BookingItem.classMetadata = new ClassMetadata(
  'BookingItem',
  'booking_items',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
BookingItem.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('ticketIri'),
  new Attribute('ticketPriceName'),
  new Attribute('currency'),
  new Attribute('ticketPriceFacialValue', 'ticketPriceFacialValue', 'integer'),
  new Attribute('seatId', 'seatId', 'integer'),
  new Attribute('seatLabel'),
  new Attribute('ticketBarcode'),
  new Attribute('ticketCurrentValidScanNb'),
]);

BookingItem.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'customer', 'participant'),
]);

export default BookingItem;
