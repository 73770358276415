import React, { FunctionComponent } from 'react';
import { components, GroupBase, InputProps } from 'react-select';
import { SingleSelectOption } from './utils';

// @ts-expect-error typescipt version is too old. Upgrade it requires to upgrade storybook too
const Input: FunctionComponent<InputProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>
>> = (props: InputProps<SingleSelectOption>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Input {...props} isHidden={false} />
);

export default Input;
