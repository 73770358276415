import { List, Map } from 'immutable';
import URI from 'urijs';
import { PartialEntity } from '../entity';
import PagedCollection from '../entity/PagedCollection';
import TicketPrice, { TicketPriceType } from '../entity/TicketPrice';
import { getEntityId } from '../entity/utils';
import { createEntity } from '../entityFactory';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import { FieldsParameter } from './AbstractClient';
import HidableClient from './HidableClient';

class TicketPriceClient extends HidableClient<TSMetadata['ticketPrice']> {
  getPathBase(): string {
    return '/v1/ticket_prices';
  }

  updateManualSort(
    ticketPrice: TicketPrice,
    fields: FieldsParameter
  ): Promise<TicketPrice> {
    const uri = new URI(`${this.getPathBase()}/${ticketPrice.getShortId()}`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method: 'PUT',
      body: JSON.stringify({
        '@id': ticketPrice.get('@id'),
        manualSort: ticketPrice.manualSort,
      }),
    })
      .then(
        (response) =>
          (response.json() as unknown) as PartialEntity<TicketPriceType>
      )
      .then((tp) => createEntity(tp));
  }

  checkVatRate(ticketPrice: TicketPrice): Promise<Response> {
    let uri;
    let method;

    if (ticketPrice.get('@id')) {
      uri = new URI(
        `${this.getPathBase()}/${ticketPrice.getShortId()}/check-vat-rate`
      );
      method = 'PUT';
    } else {
      uri = new URI(`${this.getPathBase()}/check-vat-rate`);
      method = 'POST';
    }

    let { eventDate } = ticketPrice;
    if (ticketPrice.getIn(['eventDate', '@id']) != null) {
      eventDate = ticketPrice.getIn(['eventDate', '@id']);
    }

    let { eventDatePattern } = ticketPrice;
    if (ticketPrice.getIn(['eventDatePattern', '@id']) != null) {
      eventDatePattern = ticketPrice.getIn(['eventDatePattern', '@id']);
    }

    let { wallet } = ticketPrice;
    if (ticketPrice.getIn(['wallet', '@id']) != null) {
      wallet = ticketPrice.getIn(['wallet', '@id']);
    }

    return this.authorizedFetch(uri, {
      method,
      body: JSON.stringify({
        '@id': ticketPrice.get('@id'),
        tax: ticketPrice.tax,
        eventDate,
        eventDatePattern,
        facialValue: ticketPrice.facialValue,
        type: ticketPrice.type,
        wallet,
      }),
    });
  }

  massCreate(
    ticketPrice: TicketPrice,
    fields: FieldsParameter
  ): Promise<Response> {
    if (!ticketPrice.eventDate) {
      throw new Error(
        'Unable to call `massCreate` on a ticket price without event date'
      );
    }

    const uri = new URI(
      `${getEntityId(
        ticketPrice.eventDate
      )}/ticket_prices/multiple?ticketPriceId=${ticketPrice.get('@id')}`
    );
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method: 'POST',
    });
  }

  massUpdate(
    ticketPrice: TicketPrice,
    hash: string,
    fields: FieldsParameter
  ): Promise<Response> {
    if (!ticketPrice.eventDate) {
      throw new Error(
        'Unable to call `massCreate` on a ticket price without event date'
      );
    }

    const uri = new URI(
      `${getEntityId(
        ticketPrice.eventDate
      )}/ticket_prices/multiple?hash=${hash}&ticketPriceId=${ticketPrice.get(
        '@id'
      )}`
    );
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method: 'PUT',
    });
  }

  massUpdateVatRateList(
    mapList: List<Map<string, string>>
  ): Promise<(undefined | void | Response)[]> {
    const promiseList = mapList.map((map) => {
      const ticketPriceId = map.get('ticketPriceId');
      const taxId = map.get('taxId');
      const hash = map.get('hash');

      if (!hash) {
        throw new Error(
          'Unable to call `massUpdateVatRateList` with an empty hash'
        );
      }

      return this.authorizedFetch(
        new URI(
          `${ticketPriceId}?fields=@id,hash,eventDate{@id},eventDatePattern`
        ),
        {
          method: 'PUT',
          body: JSON.stringify({
            '@id': ticketPriceId,
            tax: taxId,
          }),
        }
      )
        .then((response) => (response.json() as unknown) as TicketPriceType)
        .then((tp) => createEntity(tp))
        .then((ticketPrice: TicketPrice):
          | undefined
          // eslint-disable-next-line consistent-return
          | Promise<void | Response> => {
          const eventDateId = ticketPrice.getIn(['eventDate', '@id']);
          const eventDatePatternId = ticketPrice.get('eventDatePattern');
          if (eventDateId) {
            return this.massUpdate(ticketPrice, hash, ['@id']);
          }
          if (eventDatePatternId) {
            return Promise.resolve();
          }
        });
    });

    return Promise.all(promiseList);
  }

  massDelete(
    ticketPrice: TicketPrice,
    fields: FieldsParameter
  ): Promise<Response> {
    if (!ticketPrice.eventDate) {
      throw new Error(
        'Unable to call `massCreate` on a ticket price without event date'
      );
    }

    const uri = new URI(
      `${getEntityId(ticketPrice.eventDate)}/ticket_prices/multiple?hash=${
        ticketPrice.hash
      }`
    );
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method: 'DELETE',
    });
  }

  findFrequentlyUsed(
    criteria: Record<string, unknown>,
    fields: FieldsParameter
  ): Promise<PagedCollection<TicketPrice>> {
    const uri = new URI(`${this.getPathBase()}/frequently-used`);
    const params = this._mergeFieldsIntoParams(criteria, fields);

    uri.addSearch(params);

    return this.deserializeResponse(this.authorizedFetch(uri), 'list');
  }
}

export default TicketPriceClient;
