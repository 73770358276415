import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ScanConfigType = BaseEntity<'ScanConfig'> & {
  contract: null | EntityRelation<Contract>;
  config: null | Record<string, unknown>;
};

class ScanConfig extends NetworkEntity<ScanConfigType>({
  '@id': null,
  '@type': 'ScanConfig',
  contract: null,
  config: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ScanConfigType> = { '@id': null, '@type': 'ScanConfig' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/scan_configs/', '') || '';
  }
}

ScanConfig.classMetadata = new ClassMetadata(
  'scanConfig',
  'scan_configs',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
ScanConfig.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('config', 'config', 'object'),
]);
ScanConfig.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default ScanConfig;
