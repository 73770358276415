import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Invoice from './Invoice';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export type InvoiceLineType = BaseEntity<'InvoiceLine'> & {
  invoice: null | Invoice;
  label: null | string;
  unitAmount: null | number;
  feeAmount: null | number;
};

class InvoiceLine extends NetworkEntity<InvoiceLineType>({
  '@id': null,
  '@type': 'InvoiceLine',
  invoice: null,
  label: null,
  unitAmount: null,
  feeAmount: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<InvoiceLineType> = {
      '@id': null,
      '@type': 'InvoiceLine',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/invoice_lines/', '') || '';
  }
}

InvoiceLine.classMetadata = new ClassMetadata(
  'invoiceLine',
  'invoice_lines',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
InvoiceLine.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
  new Attribute('unitAmount', 'unitAmount', 'integer'),
  new Attribute('feeAmount', 'feeAmount', 'integer'),
]);
InvoiceLine.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'invoice', 'invoice'),
]);

export default InvoiceLine;
