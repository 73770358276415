import { Fields } from 'mapado-ticketing-js-sdk';

const TICKET_PRICE_FIELDS: Fields = [
  '@id',
  'hideFees',
  'name',
  'type',
  'isPriceDisplayed',
  'description',
  'facialValue',
  'currency',
  'notOnSaleReasons',
  'bookableStock',
  'hash',
  {
    eventDate: [
      '@id',
      'startDate',
      'endDate',
      'bookableStock',
      'notInStockContingentBookableStock',
      'remainingStock',
      'unlimitedStock',
      'notOnSaleReasons',
      'seatConfig',
      'isNumberedSeating',
      {
        ticketing: [
          'title',
          'timezone',
          'slug',
          'currency',
          'imageList',
          'mediaList',
          {
            venue: ['seatingName'],
          },
        ],
      },
    ],
  },
];
const CUSTOMER_ALL_FIELDS: Fields = [
  'type',
  'crmId',
  'firstname',
  'lastname',
  'email',
  'phone',
  'civility',
  'zipcode',
  'birthday',
  'nationality',
  'countryOfResidence',
  'additionnalInformation',
  'client',
  'contract',
  'gdprInformation',
  'city',
  'orderList',
];

export const CREATE_UPDATE_CART_FIELDS: Fields = [
  '@id',
  'comment',
  'createdAt',
  'originType',
  'originId',
  'couponCodeList',
  'couponValidityMap',
  'currency',
  'customerPaidAmount',
  'customerPaidAmountWithoutOffer',
  'facialValue',
  'facialFeeValue',
  'totalTicketNumber',
  'customerRequiredFields',
  'sellingDevice',
  'type',
  'status',
  'reservationName',
  'isOption',
  'option',
  {
    computedBookingTrack: [
      'bookingOptions',
      {
        shippingList: [
          '@id',
          'type',
          { fee: ['@id', 'type', 'calculatedAmount', 'vatRate', 'currency'] },
        ],
      },
      {
        billingList: ['@id', 'type', 'recurringPaymentParameters'],
      },
    ],
  },
  {
    contractList: ['@id'],
  },
  {
    couponList: [
      'code',
      'usage',
      'nbUseMax',
      'offerRuleList',
      'nbUseCurrent',
      'validUntil',
      {
        offerRuleList: ['title'],
      },
      { creatingOrderItemList: [{ ticketPrice: ['name'] }] },
      { customerStatusList: ['label'] },
    ],
  },
  {
    createdCouponList: [
      '@id',
      'code',
      'validUntil',
      'enabled',
      'usage',
      'creatingOrder',
      'creatingCartItemList',
      { customerStatusList: ['label'] },
      { creatingOfferRule: ['@id', 'color'] },
    ],
  },
  {
    appliedCouponList: [
      '@id',
      'code',
      'enabled',
      'usage',
      'applyingCartItemList',
      { creatingOfferRule: ['@id', 'title'] },
    ],
  },
  {
    organizationalUnitList: [
      '@id',
      'name',
      'publicContactEmail',
      'gdprOptinMessage',
    ],
  },
  {
    walletList: ['provider', 'providerContractNumber'],
  },
  {
    cartItemList: [
      'data',
      'quantity',
      {
        stockContingent: ['@id', 'name', 'color'],
      },
      {
        ticketPrice: [
          'name',
          'facialValue',
          'currency',
          {
            eventDate: [
              '@id',
              'startDate',
              'isReservableOnline',
              'isNUmberedSeating',
              {
                ticketing: ['timezone', 'slug'],
              },
            ],
          },
          { bookingTrack: ['@id', 'cartType'] },
        ],
      },
      {
        seatReservedList: [
          {
            seat: [
              {
                info: ['label'],
              },
            ],
          },
        ],
      },
      {
        availableSeatList: [
          'seatGroup',
          {
            seat: [
              {
                info: ['label'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    cartItemWithOfferList: [
      'ticketPrice',
      'quantity',
      'ticketingTitle',
      'ticketingTimezone',
      'date',
      'endDate',
      'name',
      'facialValue',
      'facialValueWithoutOffer',
      'clusterId',
      'applicationId',
      'groupKey',
      'valid',
      'buyable',
      {
        cartItem: [
          '@id',
          'ticketPrice',
          'quantity',
          'data',
          {
            stockContingent: ['@id', 'name', 'color'],
          },
        ],
      },
      {
        availableSeatList: [
          'manuallyAssigned',
          {
            seat: [
              {
                info: ['label'],
              },
            ],
          },
        ],
      },
      {
        offer: ['id', 'name', 'message', 'exchangeData'],
      },
      {
        ticketPrice: TICKET_PRICE_FIELDS,
      },
    ],
  },
  {
    customer: CUSTOMER_ALL_FIELDS,
  },
  {
    contact: CUSTOMER_ALL_FIELDS,
  },
  { tagList: ['@id', 'name'] },
];

const CART_ORDER_STATUS_EXTRA_FIELDS: Record<string, Array<string>> = {
  orderList: ['status'],
};

/** @deprecated to move in desk */
const CART_WITH_ALL_FIELDS: Fields = [
  ...CREATE_UPDATE_CART_FIELDS,
  CART_ORDER_STATUS_EXTRA_FIELDS,
];

export { CART_WITH_ALL_FIELDS };
