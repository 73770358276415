import { connect } from 'react-redux';
import OrderViewer, {
  StateProps,
  DispatchProps,
  OrderViewerProps,
} from '../../components/Main/OrderViewer';
import { initOrderViewer } from '../../actions/AppActions';
import { getSelectableSeatIdSetForOrderViewer } from '../../utils/seatSelectable';
import { resetState, selectOrMoveSeat } from '../../actions/SeatActions';
import type { RootState } from '../../reducers';
import { isLoadedAvailableSeatList } from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => {
  return {
    seatConfig: state.seating.get('seatConfig'),
    isReady: isLoadedAvailableSeatList(state),
    getSelectableSeatIdSet: getSelectableSeatIdSetForOrderViewer,
    selectedSeatIdSet: state.seating.get('selectedSeatIdSet'),
  };
};

const mapDispatchToProps = {
  resetState,
  selectOrMoveSeat,
  initOrderViewer,
};

export default connect<StateProps, DispatchProps, OrderViewerProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(OrderViewer);
