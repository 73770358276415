import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import DeskPaymentMethodClient from '../client/DeskPaymentMethodClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type DeskPaymentMethodType = BaseEntity<'DeskPaymentMethod'> & {
  code: null | string;
  contract: null | EntityRelation<Contract>;
  enabled: boolean;
  flexible: boolean;
  label: null | string;
  labelTranslationKey: null | string;
  position: null | number;
};

// those fields are mandatory, if "fields" is given, then their values will be a non-nullable string
export type DeskPaymentMethodNonNullProps =
  | '@id'
  | 'contract'
  | 'enabled'
  | 'flexible'
  | 'label'
  | 'labelTranslationKey';

class DeskPaymentMethod extends NetworkEntity<DeskPaymentMethodType>({
  '@id': null,
  '@type': 'DeskPaymentMethod',
  code: null,
  contract: null,
  enabled: false,
  flexible: false,
  label: null,
  labelTranslationKey: null,
  position: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<DeskPaymentMethodType> = {
      '@id': null,
      '@type': 'DeskPaymentMethod',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/desk_payment_methods/', '') || '';
  }
}

DeskPaymentMethod.classMetadata = new ClassMetadata(
  'deskPaymentMethod',
  'desk_payment_methods',
  /** @ts-expect-error -- method signature are incompatible */
  DeskPaymentMethodClient
);
DeskPaymentMethod.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('code'),
  new Attribute('enabled', 'enabled', 'boolean'),
  new Attribute('flexible', 'flexible', 'boolean'),
  new Attribute('label'),
  new Attribute('labelTranslationKey'),
  new Attribute('position'),
]);
DeskPaymentMethod.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default DeskPaymentMethod;
