import { List } from 'immutable';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  mergeMap,
} from 'rxjs/operators';
import {
  Cart,
  CART_TYPE,
  CART_STATUS,
  Customer,
} from 'mapado-ticketing-js-sdk';
import {
  getAtomReservationCustomer,
  getAtomReservationContact,
  getSellingDevice,
  getAtomCart,
  getAtomConvertToReservation,
  getAtomTicketPriceQuantities,
} from '../../../selector';
import {
  FETCH_CART_STARTED,
  FETCH_CART_UPDATE,
  SET_CUSTOMER_FOR_CART,
  SET_TICKET_PRICE_QUANTITY,
  UPDATE_CUSTOMER,
} from '../action/CartActionTypes';
import {
  createOrUpdateCart,
  updateCustomer as updateCustomerAction,
  updateCustomerError,
  updateCustomerReceived,
} from '../action/cartAction';
import { CartAtomId } from '../constants';
import {
  BookingActionTypes,
  FETCH_CART_STARTED_ACTION,
  FETCH_CART_UPDATE_ACTION,
  SET_CUSTOMER_FOR_CART_ACTION,
  StoreWithBooking,
} from '../reducer';
import {
  InnerBookingActionTypes,
  SET_TICKET_PRICE_QUANTITY_ACTION,
  UPDATE_CUSTOMER_ACTION,
} from '../reducer/innerBookingReducer';
import { convertToCartItemList } from '../utils/cart';
import { TicketPriceQuantity } from '../utils/ticketPriceQuantities';

function mergeInCart(
  cart: Cart,
  sellingDevice: string | null,
  currency: string,
  currentTicketPriceQuantities: List<TicketPriceQuantity>
): Cart {
  const cartItemList = convertToCartItemList(currentTicketPriceQuantities);

  return cart.merge({
    sellingDevice,
    currency,
    cartItemList,
  });
}

function updateCart(
  state$: StateObservable<StoreWithBooking>,
  cartAtomId: CartAtomId
): Observable<BookingActionTypes> {
  const baseState = state$.value;
  const cart =
    getAtomCart(baseState, cartAtomId) ||
    new Cart({
      '@type': 'Cart',
      '@id': null,
    });
  const convertToReservation = getAtomConvertToReservation(
    baseState,
    cartAtomId
  );
  const reservationCustomer = getAtomReservationCustomer(baseState, cartAtomId);
  const reservationContact = getAtomReservationContact(baseState, cartAtomId);

  const currentTicketPriceQuantities = getAtomTicketPriceQuantities(
    baseState,
    cartAtomId
  );

  const sellingDevice = getSellingDevice(baseState)?.get('@id') ?? null;

  const currency =
    cart.currency ||
    currentTicketPriceQuantities.getIn([0, 'ticketPrice', 'currency']);

  if (typeof currency !== 'string') {
    throw new Error(
      'Currency should be available in the injected TicketPrice from `currentTicketPriceQuantities`.'
    );
  }

  let newCart = mergeInCart(
    cart,
    sellingDevice,
    currency,
    currentTicketPriceQuantities
  );

  newCart = newCart.set('customer', reservationCustomer);

  if (typeof reservationContact !== 'undefined') {
    newCart = newCart.set('contact', reservationContact);
  }

  if (convertToReservation) {
    newCart = newCart
      .set('status', CART_STATUS.VALIDATED)
      .set('type', CART_TYPE.RESERVATION);
  }

  return concat(
    of<FETCH_CART_STARTED_ACTION>({ type: FETCH_CART_STARTED, cartAtomId }),
    of<FETCH_CART_UPDATE_ACTION>({ type: FETCH_CART_UPDATE, cartAtomId }),
    from(createOrUpdateCart(newCart, cartAtomId))
  );
}

// epic
const updateCustomerEpic = (action$: Observable<InnerBookingActionTypes>) =>
  action$.pipe(
    ofType<BookingActionTypes, UPDATE_CUSTOMER_ACTION>(UPDATE_CUSTOMER),
    mergeMap((action: UPDATE_CUSTOMER_ACTION) =>
      from(
        // @ts-expect-error window.ticketingSdk should be set
        window.ticketingSdk
          .getRepository('customer')
          .update(action.customer, { fields: ['@id'] })
      ).pipe(
        map((response: Customer) => updateCustomerReceived(response)),
        catchError((error) => of(updateCustomerError(error)))
      )
    )
  );

const customerEpic = (
  action$: Observable<BookingActionTypes>,
  state$: StateObservable<StoreWithBooking>
): Observable<BookingActionTypes> =>
  action$.pipe(
    ofType<BookingActionTypes, SET_CUSTOMER_FOR_CART_ACTION>(
      SET_CUSTOMER_FOR_CART
    ),
    mergeMap((action): Observable<BookingActionTypes> => {
      const { customer, contact, cartAtomId } = action;

      if (customer && customer['@id']) {
        if (contact && contact['@id']) {
          // if the customer has already been set, then update the customer
          return concat<
            Observable<InnerBookingActionTypes>,
            Observable<InnerBookingActionTypes>,
            Observable<BookingActionTypes>
          >(
            of(updateCustomerAction(customer)),
            of(updateCustomerAction(contact)),
            updateCart(state$, cartAtomId)
          );
        }

        // if the customer has already been set, then update the customer
        return concat<
          Observable<InnerBookingActionTypes>,
          Observable<BookingActionTypes>
        >(of(updateCustomerAction(customer)), updateCart(state$, cartAtomId));
      }

      // if the customer is a new customer, update the cart by setting the customer in it
      return updateCart(state$, cartAtomId);
    })
  );

const cartEpic = (
  action$: Observable<BookingActionTypes>,
  state$: StateObservable<StoreWithBooking>
): Observable<BookingActionTypes> =>
  action$.pipe(
    ofType<BookingActionTypes, SET_TICKET_PRICE_QUANTITY_ACTION>(
      SET_TICKET_PRICE_QUANTITY
    ), // catch all these type of actions…
    debounceTime(450), // … and wait 450 ms. switchMap will ignore take only THE LAST ACTION that happend during this 450 ms
    concatMap((action) => {
      // concatMap ensures that previous request has completed before sending next one
      return updateCart(state$, action.cartAtomId);
    })
  );

export default combineEpics(customerEpic, updateCustomerEpic, cartEpic);
