import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class TicketingCategoryClient extends AbstractClient<
  TSMetadata['ticketingCategory']
> {
  getPathBase(): string {
    return '/v1/ticketing_categories';
  }

  getDefaultGroups(): Array<string> {
    return ['ticketing_category_read', 'ticketing_category_write'];
  }
}

export default TicketingCategoryClient;
