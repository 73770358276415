import localforage from 'localforage';
import getSdkConfig, { PUBLICID, SCOPES } from './config/sdkConfig';

export default async function redirectToLoginPage(
  redirectUrl: string
): Promise<void> {
  const state = Math.random().toString(16).substring(2, 9);

  const searchParams = new URLSearchParams({
    response_type: 'code',
    client_id: PUBLICID,
    scope: SCOPES,
    redirect_uri: `${window.location.protocol}//${window.location.host}/oauth-callback`,
    state,
    theme: 'pro',
  });

  await localforage.setItem('redirectUrl', redirectUrl);
  await localforage.setItem('state', state);

  window.location.href = `${
    getSdkConfig().account.host
  }/oauth/v2/auth?${searchParams.toString()}`;
}
