import clone from 'clone';
import { List } from 'immutable';
import type { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SeasonClient from '../client/SeasonClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import Ticketing from './Ticketing';
import { BaseEntity, PartialEntity } from '.';

export enum SEASON_TYPE {
  SEASON = 'season',
  FESTIVAL = 'festival',
}

export type SeasonType = BaseEntity<'Season'> & {
  name: null | string;
  startDate: null | Moment;
  endDate: null | Moment;
  contract: null | Contract;
  ticketingList: null | List<Ticketing>;
  type: null | SEASON_TYPE;
};

export type SeasonNonNullProps =
  | '@id'
  | 'startDate'
  | 'endDate'
  | 'contract'
  | 'type';

class Season extends NetworkEntity<SeasonType>({
  '@id': null,
  '@type': 'Season',
  name: null,
  startDate: null,
  endDate: null,
  contract: null,
  ticketingList: null,
  type: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SeasonType> = { '@id': null, '@type': 'Season' }
  ) {
    const data = clone(val);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seasons/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Season.classMetadata = new ClassMetadata('season', 'seasons', SeasonClient);
Season.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('startDate', 'startDate', 'datetime'),
  new Attribute('endDate', 'endDate', 'datetime'),
  new Attribute('type'),
]);
Season.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'ticketing', 'ticketingList'),
]);

export default Season;
