import URI from 'urijs';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class PayoutClient extends AbstractClient<TSMetadata['payout']> {
  getPathBase(): string {
    return '/v1/payouts';
  }

  getPayoutWire(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/wire.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPayoutInvoice(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/fee_invoice.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPayout(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/payout.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }
}

export default PayoutClient;
