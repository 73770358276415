import React from 'react';
import { MpdSingleSelect, SingleSelectOption } from '@mapado/makeup';
import { getShortId } from '@mapado/js-component';
import { useHistory, useLocation } from 'react-router-dom';
import type { History, Location } from 'history';
import { List } from 'immutable';
import { SeatConfigShape } from './DemoTypes';

function getLabelFromSeatConfig(seatConfig: SeatConfigShape): string {
  const contractShortId = seatConfig.contract['@id'].replace(
    '/v1/contracts/',
    ''
  );
  const shortId = seatConfig['@id'].replace('/v1/seat_configs/', '');

  return `[${contractShortId}] ${seatConfig.contract.companyDisplayName} | [${shortId}] ${seatConfig.label} (${seatConfig.totalStock} sièges)`;
}

interface Props {
  onChange: (newValue: SingleSelectOption | null) => void;
  seatConfigList: List<SeatConfigShape>;
  selectedSeatConfig?: null | SeatConfigShape;
}

function SeatConfigSelector({
  onChange,
  seatConfigList,
  selectedSeatConfig = null,
}: Props): JSX.Element {
  const options: SingleSelectOption[] = seatConfigList
    .map((seatConfig): SingleSelectOption => {
      return {
        label: getLabelFromSeatConfig(seatConfig),
        value: seatConfig['@id'],
      };
    })
    .sortBy((option) => option.label)
    .toArray();

  return (
    <MpdSingleSelect
      placeholder={
        selectedSeatConfig
          ? getLabelFromSeatConfig(selectedSeatConfig)
          : undefined
      }
      options={options}
      onChange={onChange}
      searchable
    />
  );
}

function handleSeatConfigSelectorChange(
  newValue: SingleSelectOption | null,
  location: Location,
  history: History
): void {
  if (!newValue) {
    return;
  }

  const selectedSeatConfigId = newValue.value;

  const searchParams = new URLSearchParams(location.search);

  searchParams.set(
    'seatConfigId',
    selectedSeatConfigId ? getShortId(selectedSeatConfigId) : ''
  );

  history.push({
    search: searchParams.toString(),
  });
}

interface SeatConfigSelectorContainerProps {
  seatConfigList: List<SeatConfigShape>;
  selectedSeatConfig?: null | SeatConfigShape;
}

function SeatConfigSelectorContainer({
  seatConfigList,
  selectedSeatConfig = null,
}: SeatConfigSelectorContainerProps): null | JSX.Element {
  const location = useLocation();
  const history = useHistory();

  if (seatConfigList.size === 0) {
    return null;
  }

  return (
    <div className="mb2">
      <SeatConfigSelector
        onChange={(event) =>
          handleSeatConfigSelectorChange(event, location, history)
        }
        seatConfigList={seatConfigList}
        selectedSeatConfig={selectedSeatConfig}
      />
    </div>
  );
}

export default SeatConfigSelectorContainer;
