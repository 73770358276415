// eslint-disable-next-line import/prefer-default-export
export class ShouldNotHappenError extends Error {
  constructor(message: null | string) {
    super(message || 'This should not happen.');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ShouldNotHappenError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ShouldNotHappenError);
    }

    this.name = 'ShouldNotHappenError';
  }
}
