import clone from 'clone';
import { List } from 'immutable';
import type { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Coupon from './Coupon';
import EventDate from './EventDate';
import NetworkEntity from './NetworkEntity';
import OfferRule from './OfferRule';
import Order from './Order';
import OrderFeeItem from './OrderFeeItem';
import OrderItemCashCoupon from './OrderItemCashCoupon';
import RefundOrderItem from './RefundOrderItem';
import StockContingent from './StockContingent';
import Ticket from './Ticket';
import TicketPrice from './TicketPrice';
import Ticketing from './Ticketing';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum ORDER_ITEM_TYPE {
  DEFAULT = 'default',
  SUBSCRIPTION = 'subscription',
  FEE = 'fee',
}

export type OrderItemType = BaseEntity<'OrderItem'> & {
  applicationId: null | string;
  data: null | Record<string, unknown>;
  customerPaidAmount: null | number;
  retailValue: null | number;
  amountForPro: null | number;
  feeAmount: null | number;
  vatRate: null | number;
  updatedAt: null | Moment;
  createdAt: null | Moment;
  ticketPrice: null | TicketPrice;
  eventDate: null | EventDate;
  ticketing: null | Ticketing;
  ticketList: null | List<Ticket>;
  refundOrderItemList: null | List<RefundOrderItem>;
  createdCoupon: null | Coupon;
  appliedCoupon: null | Coupon;
  order: null | Order;
  totalRefundedAmount: null | number;
  totalRefundedFeeAmount: null | number;
  refundedTotally: null | boolean;
  refunded: null | boolean;
  cancelled: null | boolean;
  rescheduledToOrderItem: null | OrderItem;
  type: null | ORDER_ITEM_TYPE;
  orderFeeItemList: null | List<EntityRelation<OrderFeeItem>>;
  offerRule: null | OfferRule;
  orderItemCashCouponList: null | List<EntityRelation<OrderItemCashCoupon>>;
  stockContingent: null | EntityRelation<StockContingent>;
  facialValue: null | number;
};

class OrderItem extends NetworkEntity<OrderItemType>({
  '@id': null,
  '@type': 'OrderItem',
  applicationId: null,
  data: null,
  customerPaidAmount: null, // TODO: remove when the clients are updated
  retailValue: null,
  amountForPro: null,
  feeAmount: null,
  vatRate: null,
  updatedAt: null,
  createdAt: null,
  ticketPrice: new TicketPrice(),
  eventDate: new EventDate(),
  ticketing: new Ticketing(),
  ticketList: List<Ticket>(),
  refundOrderItemList: List<RefundOrderItem>(),
  createdCoupon: null,
  appliedCoupon: null,
  order: null,
  totalRefundedAmount: 0,
  totalRefundedFeeAmount: 0,
  refundedTotally: null,
  refunded: null,
  cancelled: null,
  rescheduledToOrderItem: null,
  type: null,
  orderFeeItemList: null,
  offerRule: null,
  orderItemCashCouponList: null,
  stockContingent: null,
  facialValue: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrderItemType> = { '@id': null, '@type': 'OrderItem' }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['ticketing', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/order_items/', '') || '';
  }

  /** @deprecated -- use `createdAt` instead */
  getLocalCreatedAt(): null | Moment {
    const tz = this.getIn(['ticketing', 'timezone']);

    if (tz && this.createdAt) {
      /** @ts-expect-error -- conflict between moment and moment-timezone */
      return this.createdAt.clone().tz(tz);
    }

    // if no timezone, fallback to utc time
    return this.createdAt;
  }
}

OrderItem.classMetadata = new ClassMetadata(
  'orderItem',
  'order_items',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
OrderItem.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('applicationId', 'applicationId', 'string'),
  new Attribute('data', 'data', 'object'),
  new Attribute('customerPaidAmount', 'customerPaidAmount', 'integer'),
  new Attribute('retailValue', 'retailValue', 'integer'),
  new Attribute('amountForPro', 'amountForPro', 'integer'),
  new Attribute('feeAmount', 'feeAmount', 'integer'),
  new Attribute('vatRate', 'vatRate', 'integer'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('refundedTotally', 'refundedTotally', 'boolean'),
  new Attribute('refunded', 'refunded', 'boolean'),
  new Attribute('cancelled', 'cancelled', 'boolean'),
  new Attribute('type', 'type', 'string'),
  new Attribute('facialValue', 'facialValue', 'integer'),
]);

OrderItem.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'orderItem', 'rescueduledToOrderItem'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPrice', 'ticketPrice'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.ONE_TO_MANY, 'refundOrderItem', 'refundOrderItemList'),
  new Relation(Relation.MANY_TO_ONE, 'coupon', 'createdCoupon'),
  new Relation(Relation.MANY_TO_ONE, 'coupon', 'appliedCoupon'),
  new Relation(Relation.ONE_TO_MANY, 'orderFeeItem', 'orderFeeItemList'),
  new Relation(Relation.MANY_TO_ONE, 'offerRule', 'offerRule'),
  new Relation(
    Relation.ONE_TO_MANY,
    'orderItemCashCoupon',
    'orderItemCashCouponList'
  ),
  new Relation(Relation.MANY_TO_ONE, 'stockContingent', 'stockContingent'),
]);

export default OrderItem;
