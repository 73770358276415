import { AbstractClient as BaseClient } from 'rest-client-sdk';
import URI from 'urijs';
import { createEntity } from '../../entityFactory';
import { TSMetadata } from '../../mapping';

class SibilProxyClient extends BaseClient<TSMetadata['sibilProxy']> {
  getPathBase(): string {
    return '/v1/module/sibil/proxy';
  }

  doSearch(
    uri: URI,
    sibilParameterId: string
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    /** @ts-expect-error -- ignore this entity as it has been no entity and is weird */
    return this.authorizedFetch(uri, {
      method: 'GET',
      headers: {
        'X-SibilParameter': sibilParameterId,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then(createEntity);
  }

  getSpectacles(
    sibilParameterId: string,
    queryParameters: Record<string, unknown>
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    const uri = new URI(`${this.getPathBase()}/spectacles`);
    uri.addSearch(queryParameters);

    return this.doSearch(uri, sibilParameterId);
  }

  getFestivals(
    sibilParameterId: string,
    queryParameters: Record<string, unknown>
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    const uri = new URI(`${this.getPathBase()}/festivals`);
    uri.addSearch(queryParameters);

    return this.doSearch(uri, sibilParameterId);
  }

  searchLieuxFulltext(
    sibilParameterId: string,
    queryParameters: Record<string, unknown>
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    const uri = new URI(`${this.getPathBase()}/lieux/fulltext`);
    uri.addSearch(queryParameters);

    return this.doSearch(uri, sibilParameterId);
  }

  createSpectacle(
    sibilParameterId: string,
    body: Record<string, unknown>
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    const uri = new URI(`${this.getPathBase()}/spectacles`);

    /** @ts-expect-error -- ignore this entity as it has been no entity and is weird */
    return this.authorizedFetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-SibilParameter': sibilParameterId,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(createEntity);
  }

  createFestival(
    sibilParameterId: string,
    body: Record<string, unknown>
  ): Promise<TSMetadata['sibilProxy']['entity']> {
    const uri = new URI(`${this.getPathBase()}/festivals`);

    /** @ts-expect-error -- ignore this entity as it has been no entity and is weird */
    return this.authorizedFetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-SibilParameter': sibilParameterId,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(createEntity);
  }
}

export default SibilProxyClient;
