import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderSeatConfigMappingClient from '../../../client/ProviderSeatConfigMappingClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import SeatConfig from '../../SeatConfig';
import { ProviderTicketMappingType } from './ProviderTicketMappingType';

export type ProviderSeatConfigMappingType = BaseEntity<'ProviderSeatConfigMapping'> &
  ProviderTicketMappingType & {
    seatConfigName?: string | undefined;
    seatConfig?: EntityRelation<SeatConfig> | null;
  };

class ProviderSeatConfigMapping extends NetworkEntity<ProviderSeatConfigMappingType>(
  {
    '@id': null,
    '@type': 'ProviderSeatConfigMapping',
    contract: undefined,
    provider: undefined,
    seatConfigName: undefined,
    seatConfig: undefined,
    label: undefined,
    isMapped: undefined,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderSeatConfigMappingType> = {
      '@id': null,
      '@type': 'ProviderSeatConfigMapping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_seat_config_mappings/', '') || ''
    );
  }
}

ProviderSeatConfigMapping.classMetadata = new ClassMetadata(
  'providerSeatConfigMapping',
  'provider_seat_config_mappings',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderSeatConfigMappingClient
);
ProviderSeatConfigMapping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('provider'),
  new Attribute('label'),
  new Attribute('seatConfigName'),
  new Attribute('isMapped', 'isMapped', 'boolean'),
]);
ProviderSeatConfigMapping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
]);

export default ProviderSeatConfigMapping;
