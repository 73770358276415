import React from 'react';
import {
  useStockManagement,
  STOCK_MANAGEMENT_TABS,
} from '../../../contexts/StockManagementContext';
import TabbedSidebar from '../TabbedSidebar';
import StockContingentSidebarContent from './StockContingentSidebarContent';
import StockContingentSidebarActions from './StockContingentSidebarActions';
import GaugeSidebarContent from './GaugeSidebarContent';
import GaugeSidebarActions from './GaugeSidebarActions';
import '../../../styles/components/Sidebar/StockManagementSidebar.scss';
import SeatGroupSidebarContent from './SeatGroupSidebarContent';
import SeatGroupSidebarActions from '../LogicalSeatConfigAdminSidebar/SeatGroupPane/SeatGroupSidebarActions';
import { useTranslation } from '../../../i18n';

type Props = {
  onTabChange: (value: STOCK_MANAGEMENT_TABS) => void;
  onError: () => void;
};

function StockManagementSidebar({ onTabChange, onError }: Props) {
  const { t } = useTranslation();
  const { selectTab, contractId } = useStockManagement();

  return (
    <TabbedSidebar
      className="stock-management-sidebar"
      onChangeTab={(tabName: STOCK_MANAGEMENT_TABS) => {
        onTabChange(tabName);
        selectTab(tabName);
      }}
      tabs={[
        {
          name: STOCK_MANAGEMENT_TABS.CONTINGENT,
          label: t('seating.sidebar_tab.contingent.title'),
          element: <StockContingentSidebarContent onError={onError} />,
          sidebarAction: <StockContingentSidebarActions onError={onError} />,
        },
        {
          name: STOCK_MANAGEMENT_TABS.SEAT_GROUP,
          label: t('seating.sidebar_tab.seat_group.title'),
          element: <SeatGroupSidebarContent />,
          sidebarAction: <SeatGroupSidebarActions contractId={contractId} />,
        },
        {
          name: STOCK_MANAGEMENT_TABS.GAUGE,
          label: t('seating.sidebar_tab.gauge.title'),
          element: <GaugeSidebarContent />,
          sidebarAction: <GaugeSidebarActions onError={onError} />,
        },
      ]}
    />
  );
}

export default StockManagementSidebar;
