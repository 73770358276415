import React, { ReactElement } from 'react';
import { OrderViewer } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';

type Props = {
  eventDateId: number;
  cartId: number;
};

export default function DemoOrderViewer({
  eventDateId,
  cartId,
}: Props): ReactElement {
  return (
    <>
      <EventDatePagesHeader title="Visualisation" eventDateId={eventDateId} />

      <OrderViewer cartId={cartId} eventDateId={eventDateId} />
    </>
  );
}
