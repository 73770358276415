import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { isEmptyArrayOrList } from '../utils';
import { parseDate } from '../utils/date';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ClientType = BaseEntity<'Client'> & {
  name: null | string;
  email: null | string;
  phone: null | string;
  corporateName: null | string;
  accountingNumber: null | string;
  origin: null | string;
  issuerName: null | string;
  issuerBankName: null | string;
  ticketingData: null | Record<string, unknown>;
  crmData: null | Record<string, unknown>;
  crmId: null | string;
  creditByCurrencyMap: null | Record<string, number>;
  debitByCurrencyMap: null | Record<string, number>;
  balanceByCurrencyMap: null | Record<string, number>;
  cashCouponCreditByCurrencyMap: null | Record<string, number>;
  cashCouponDebitByCurrencyMap: null | Record<string, number>;
  cashCouponBalanceByCurrencyMap: null | Record<string, number>;
  mostRecentTransactionValueDate: null | Moment;
  active: null | boolean;
  contract: null | EntityRelation<Contract>;
  createdAt: null | Moment;
};

class Client extends NetworkEntity<ClientType>({
  '@id': null,
  '@type': 'Client',
  name: null,
  email: null,
  phone: null,
  corporateName: null,
  accountingNumber: null,
  origin: null,
  issuerName: null,
  issuerBankName: null,
  ticketingData: null,
  crmData: null,
  crmId: null,
  creditByCurrencyMap: null,
  debitByCurrencyMap: null,
  balanceByCurrencyMap: null,
  cashCouponCreditByCurrencyMap: null,
  cashCouponDebitByCurrencyMap: null,
  cashCouponBalanceByCurrencyMap: null,
  mostRecentTransactionValueDate: null,
  active: null,
  contract: new Contract(),
  createdAt: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ClientType> = { '@id': null, '@type': 'Client' }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['contract', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);
    data.mostRecentTransactionValueDate = parseDate(
      data.mostRecentTransactionValueDate,
      tz
    );

    if (isEmptyArrayOrList(data.creditByCurrencyMap)) {
      data.creditByCurrencyMap = {};
    }
    if (isEmptyArrayOrList(data.debitByCurrencyMap)) {
      data.debitByCurrencyMap = {};
    }
    if (isEmptyArrayOrList(data.balanceByCurrencyMap)) {
      data.balanceByCurrencyMap = {};
    }

    if (isEmptyArrayOrList(data.cashCouponCreditByCurrencyMap)) {
      data.cashCouponCreditByCurrencyMap = {};
    }
    if (isEmptyArrayOrList(data.cashCouponDebitByCurrencyMap)) {
      data.cashCouponDebitByCurrencyMap = {};
    }
    if (isEmptyArrayOrList(data.cashCouponBalanceByCurrencyMap)) {
      data.cashCouponBalanceByCurrencyMap = {};
    }

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/clients/', '') || '';
  }
}

/** @ts-expect-error -- method signature are incompatible */
Client.classMetadata = new ClassMetadata('client', 'clients', AbstractClient);
Client.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),

  new Attribute('corporateName'),
  new Attribute('accountingNumber'),
  new Attribute('origin'),
  new Attribute('issuerName'),
  new Attribute('issuerBankName'),
  new Attribute('name'),

  new Attribute('email'),
  new Attribute('phone'),
  new Attribute('ticketingData', 'ticketingData', 'object'),
  new Attribute('crmData', 'crmData', 'object'),
  new Attribute('crmId', 'crmId', 'integer'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('debit', 'debitByCurrencyMap', 'object'),
  new Attribute('credit', 'creditByCurrencyMap', 'object'),
  new Attribute('balance', 'balanceByCurrencyMap', 'object'),
  new Attribute('debit', 'cashCouponCreditByCurrencyMap', 'object'),
  new Attribute('credit', 'cashCouponDebitByCurrencyMap', 'object'),
  new Attribute('balance', 'cashCouponBalanceByCurrencyMap', 'object'),
  new Attribute(
    'mostRecentTransactionValueDate',
    'mostRecentTransactionValueDate',
    'datetime'
  ),
  new Attribute('active', 'active', 'boolean'),
]);
Client.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Client;
