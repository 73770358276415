import URI from 'urijs';
import { createEntity } from '../entityFactory';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class CouponClient extends AbstractClient<TSMetadata['coupon']> {
  getPathBase(): string {
    return '/v1/coupons';
  }

  getDefaultGroups(): Array<string> {
    return ['coupon_read', 'coupon_write'];
  }

  getCodes(criteria: Record<string, unknown>): Promise<unknown> {
    const uri = new URI(`${this.getPathBase()}/codes`);
    const params = this._mergeFieldsIntoParams(criteria, []);

    uri.addSearch(params);

    return this.authorizedFetch(uri).then((response) =>
      response.json().then(createEntity)
    );
  }

  validateCodes(criteria: Record<string, unknown>): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/codes/validate`);
    const params = this._mergeFieldsIntoParams(criteria, []);

    uri.addSearch(params);

    return this.authorizedFetch(uri);
  }
}

export default CouponClient;
