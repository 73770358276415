import { AbstractClient as BaseClient } from 'rest-client-sdk';
import { TSMetadata } from '../../mapping';

class SibilVenueLieuClient extends BaseClient<TSMetadata['sibilVenueLieu']> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPathBase(pathParameters: Record<string, unknown>): string {
    return '/v1/module/sibil/venue_lieux';
  }
}

export default SibilVenueLieuClient;
