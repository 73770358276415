import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import RefundOrderItem from './RefundOrderItem';
import Transaction from './Transaction';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type RefundType = BaseEntity<'Refund'> & {
  refundOrderItemList: null | List<EntityRelation<RefundOrderItem>>;
  refundTransactionList: null | List<EntityRelation<Transaction>>;
};

class Refund extends NetworkEntity<RefundType>({
  '@id': null,
  '@type': 'Refund',
  refundOrderItemList: List<RefundOrderItem>(),
  refundTransactionList: List<Transaction>(),
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<RefundType> = { '@id': null, '@type': 'Refund' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/refunds/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Refund.classMetadata = new ClassMetadata('refund', 'refunds', AbstractClient);
Refund.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
]);
Refund.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'refundOrderItem', 'refundOrderItemList'),
  new Relation(Relation.ONE_TO_MANY, 'transaction', 'refundTransactionList'),
]);

export default Refund;
