import React, { ReactElement } from 'react';
import BaseSeat, { SeatProps } from './BaseSeat';
import { getIcon } from '../../utils/seatStyle';

const LogicalSeatConfigAdminSeat = React.memo(
  function LogicalSeatConfigAdminSeat({
    isSelected,
    seatId,
    bookingStatus,
    color,
    ...rest
  }: Props): ReactElement {
    return (
      <BaseSeat<'AvailableSeatModel'>
        {...rest}
        bookingStatus={bookingStatus}
        icon={getIcon(bookingStatus, false)}
        fill={color}
        isSelected={isSelected}
        seatId={seatId}
        displayDismissedSeat
      />
    );
  }
);

type Props = SeatProps<'AvailableSeatModel'> & {
  seatId: number;
  color: string;
};

LogicalSeatConfigAdminSeat.displayName = 'LogicalSeatConfigAdminSeat';

export default LogicalSeatConfigAdminSeat;
