import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AvailableSeatClient from '../client/AvailableSeatClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import type Cart from './Cart';
import type CartItem from './CartItem';
import EventDate from './EventDate';
import NetworkEntity from './NetworkEntity';
import type Order from './Order';
import Seat from './Seat';
import SeatConfig from './SeatConfig';
import SeatGroup from './SeatGroup';
import StockContingent from './StockContingent';
import Ticket from './Ticket';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const STATUS_DISMISSED = 'dismissed';
export const STATUS_AVAILABLE = 'available';
export const STATUS_BOOKED = 'booked';
export const STATUS_PAID = 'payed';

export type AvailableSeatStatus =
  | null
  | ''
  | typeof STATUS_DISMISSED
  | typeof STATUS_BOOKED
  | typeof STATUS_PAID
  | typeof STATUS_AVAILABLE;

export type AvailableSeatType = BaseEntity<'AvailableSeat'> & {
  seat: null | EntityRelation<Seat>;
  seatConfig: null | EntityRelation<SeatConfig>;
  eventDate: null | EntityRelation<EventDate>;
  stockContingent: null | EntityRelation<StockContingent>;
  ticket: null | EntityRelation<Ticket>;
  cartItem: null | EntityRelation<CartItem>;
  seatGroup: null | EntityRelation<SeatGroup>;
  status: AvailableSeatStatus;
  bookingStatus: null | string;
  bookable: null | boolean;
  visible: null | boolean;
  manuallyAssigned: null | boolean;
  order: null | EntityRelation<Order>;
  cart: null | EntityRelation<Cart>;
};

// those fields are mandatory, if "fields" is given, then their values will be a non-nullable string
export type AvailableSeatNonNullProps =
  | '@id'
  | 'status'
  | 'visible'
  | 'eventDate'
  | 'manuallyAssigned'
  | 'bookingStatus';

class AvailableSeat extends NetworkEntity<AvailableSeatType>({
  '@id': null,
  '@type': 'AvailableSeat',
  seat: null,
  seatConfig: null,
  eventDate: null,
  stockContingent: null,
  ticket: null,
  cartItem: null,
  seatGroup: null,
  status: '',
  bookingStatus: '',
  bookable: null,
  visible: null,
  manuallyAssigned: null,
  order: null,
  cart: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<AvailableSeatType> = {
      '@id': null,
      '@type': 'AvailableSeat',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/available_seats/', '') || '';
  }

  isAvailable(): boolean {
    return this.status === STATUS_AVAILABLE;
  }

  isDismissed(): boolean {
    return this.status === STATUS_DISMISSED;
  }

  isBooked(): boolean {
    return this.status === STATUS_BOOKED;
  }

  isPaid(): boolean {
    return this.status === STATUS_PAID;
  }
}

AvailableSeat.classMetadata = new ClassMetadata(
  'availableSeat',
  'available_seats',
  /** @ts-expect-error -- method signature are incompatible */
  AvailableSeatClient
);
AvailableSeat.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('status'),
  new Attribute('bookingStatus'),
  new Attribute('bookable', 'bookable', 'bool'),
  new Attribute('visible', 'visible', 'bool'),
  new Attribute('manuallyAssigned', 'manuallyAssigned', 'bool'),
]);
AvailableSeat.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seat', 'seat'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'seatGroup', 'seatGroup'),
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'stockContingent', 'stockContingent'),
  new Relation(Relation.MANY_TO_ONE, 'ticket', 'ticket'),
  new Relation(Relation.MANY_TO_ONE, 'cartItem', 'cartItem'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'cart', 'cart'),
]);

export default AvailableSeat;
