import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class SeasonClient extends AbstractClient<TSMetadata['season']> {
  getPathBase(): string {
    return '/v1/seasons';
  }

  getDefaultGroups(): Array<string> {
    return ['season_read', 'season_write'];
  }
}

export default SeasonClient;
