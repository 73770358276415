import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 13" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/check-seul" fillRule="nonzero" transform="translate(-6 -11)">
        <path
          id="icon-SVG"
          d="m22.935 11 1.844 2.007-11.922 10.958L6 17.09l1.93-1.925 5.009 5.021z"
        />
      </g>
    </g>
  </svg>
);
export default SvgCheck;
