import URI from 'urijs';
import { DeskSessionType } from '../entity/DeskSession';
import { createEntity } from '../entityFactory';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class DeskSessionClient extends AbstractClient<TSMetadata['deskSession']> {
  getPathBase(): string {
    return '/v1/desk_sessions';
  }

  getOrCreateCurrent(
    fields: FieldsParameter,
    creationBody = {}
  ): Promise<null | DeskSessionType> {
    return this.getCurrent(fields).then(
      (deskSession: null | DeskSessionType) => {
        if (deskSession === null) {
          return this.start(fields, creationBody);
        }

        return deskSession;
      }
    );
  }

  getCurrent(fields: FieldsParameter): Promise<null | DeskSessionType> {
    const uri = new URI(
      `${this.getPathBase()}?opened=1&user=me&itemsPerPage=1`
    );
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(this.authorizedFetch(uri), 'list').then(
      (collection): null | DeskSessionType => {
        if (collection && collection.get('hydra:member').count() === 1) {
          return collection.get('hydra:member').first() as DeskSessionType;
        }

        return null;
      }
    );
  }

  start(
    fields: FieldsParameter,
    body: Record<string, unknown> = {}
  ): Promise<null | DeskSessionType> {
    const uri = new URI(`${this.getPathBase()}`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(uri, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
      'item'
    );
  }

  close(
    sessionId: number,
    fields: FieldsParameter
  ): Promise<null | DeskSessionType> {
    const uri = new URI(`${this.getPathBase()}/${sessionId}/close`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method: 'POST',
    }).then((response) => {
      if (response.status === 204) {
        return null;
      }

      return response
        .json()
        .then(
          (object: DeskSessionType) => createEntity(object) as DeskSessionType
        );
    });
  }

  getAccountability(id: number | string, format: string): Promise<Response> {
    const uri = `${this.getPathBase()}/${id}/accountability.${format}`;

    switch (format) {
      case 'json':
        return this.authorizedFetch(uri).then((response) => response.json());
      default:
        return this.authorizedFetch(uri);
    }
  }
}

export default DeskSessionClient;
