import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Minisite from './Minisite';
import NetworkEntity from './NetworkEntity';
import Ticketing from './Ticketing';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const PAGE_TYPE_HOME = 'home';
export const PAGE_TYPE_EVENT = 'event';

type PAGE_TYPE_HOME = 'home';
type PAGE_TYPE_EVENT = 'event';

type CanBe =
  | 'dragged'
  | 'deleted'
  | 'created'
  | 'everywhere'
  | 'multiple'
  | 'optionnal';

export type MODULE_PAGE_TYPE_LIST = PAGE_TYPE_HOME | PAGE_TYPE_EVENT;

export type ModuleType = BaseEntity<'Module'> & {
  type: null | string;
  pageType: null | MODULE_PAGE_TYPE_LIST;
  minisite: null | EntityRelation<Minisite>;
  configuration: null | Map<string, unknown>;
  position: null | number;
  canBe: null | Map<CanBe, boolean>;
  ticketingList: null | List<EntityRelation<Ticketing>>;
};

class Module extends NetworkEntity<ModuleType>({
  '@id': null,
  '@type': 'Module',
  type: null,
  pageType: null,
  minisite: new Minisite(),
  configuration: Map<string, unknown>(),
  position: null,
  canBe: null,
  ticketingList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ModuleType> = {
      '@id': null,
      '@type': 'Module',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/modules/', '') || '';
  }
}

Module.classMetadata = new ClassMetadata(
  'module',
  'modules',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Module.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('pageType'),
  new Attribute('configuration', 'configuration', 'object'),
  new Attribute('position', 'position', 'number'),
  new Attribute('canBe', 'canBe', 'object'),
]);

Module.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'minisite', 'minisite'),
  new Relation(Relation.MANY_TO_MANY, 'ticketing', 'ticketingList'),
]);

export default Module;
