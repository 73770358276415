import {
  useTranslation as useTranslationI18next,
  Namespace,
  DefaultNamespace,
  UseTranslationOptions,
} from 'react-i18next';
import type { i18n as I18NType } from 'i18next';
import { getI18NextInstance } from './i18n';

type I18nInstanceParameters = Parameters<typeof getI18NextInstance>;
type I18nPackReturn = {
  i18n: I18NType;
  useTranslation: typeof useTranslationI18next;
};

export default function createI18nPack(
  ...args: I18nInstanceParameters
): I18nPackReturn {
  const i18n = getI18NextInstance(...args);

  function useTranslation<N extends Namespace = DefaultNamespace>(
    ns?: N | Readonly<N>,
    options?: UseTranslationOptions
  ) {
    return useTranslationI18next(ns, { ...options, i18n });
  }

  return {
    i18n,
    useTranslation,
  };
}
