import React from 'react';
import { TOAST_TYPE, useMpdToast } from '@mapado/makeup';
import { dismissSeatList, restoreSeatList } from '../../../actions/SeatActions';
import { useStockManagement } from '../../../contexts/StockManagementContext';
import { useSelectedSeatIdSetSelector } from '../../../utils/selectors';
import { useDrawingContext } from '../../../contexts/DrawingContext';
import { useSeatingDispatch } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

function GaugeSidebarActions({ onError }: { onError: () => void }) {
  const toast = useMpdToast();
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();
  const { eventDateId } = useStockManagement();
  const { isDrawing, setIsDrawing } = useDrawingContext();

  const selectedSeatIdSet = useSelectedSeatIdSetSelector();

  const getSelectedSeatLongIdSet = selectedSeatIdSet.map(
    (shortId) => `/v1/seats/${shortId}`
  );

  const handleError = () => {
    toast({ title: t('stock_gauge.update_error'), type: TOAST_TYPE.ERROR });

    // wait for toast to be close to trigger `onError`
    window.setTimeout(() => {
      onError();
    }, 5000);
  };

  const handleDismissSeatList = () => {
    dispatch(
      dismissSeatList(eventDateId, getSelectedSeatLongIdSet, handleError)
    );
    setIsDrawing(false);
  };

  const handleRestoreSeatList = () => {
    dispatch(
      restoreSeatList(eventDateId, getSelectedSeatLongIdSet, handleError)
    );
    setIsDrawing(false);
  };

  if (!isDrawing || !selectedSeatIdSet.size) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className="mpd-btn mpd-btn--primary"
        onClick={handleDismissSeatList}
      >
        {t('seating.dismiss_seat_cta')}
      </button>
      <button
        type="button"
        className="mpd-btn mpd-btn--secondary"
        onClick={handleRestoreSeatList}
      >
        {t('seating.restore_seat_cta')}
      </button>
    </>
  );
}

export default GaugeSidebarActions;
