import React, { useRef, useEffect, ReactElement } from 'react';
import SeatConfigBlock, {
  SeatConfigBlockTypes,
} from '../../../../src/components/SeatConfigBlock';

function SeatConfigBlockWrapper({
  registerNode,
  unRegisterNode,
  ...rest
}: SeatConfigBlockWrapperProps): ReactElement {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    const { current } = ref;

    if (current) {
      registerNode(current);
    }

    return (): void => {
      if (current) {
        unRegisterNode(current);
      }
    };
  }, [ref, registerNode, unRegisterNode]);

  return (
    <g className="mpd-seating__editor-element">
      <SeatConfigBlock ref={ref} {...rest} />
    </g>
  );
}

type SeatConfigBlockWrapperProps = SeatConfigBlockTypes & {
  registerNode: (element: SVGGElement) => void;
  unRegisterNode: (element: SVGGElement) => void;
};

export default SeatConfigBlockWrapper;
