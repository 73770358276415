import '../styles/components/Legend.scss';
import React from 'react';
import { MpdIcon } from '@mapado/makeup';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $primaryBlue } from '@mapado/makeup/variables';
import { assertRelationIsObject } from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { ContingentEntity, isStockContingent } from '../propTypes';

type Props = {
  contingentEntityList: List<ContingentEntity>;
};

function ContingentEntityList({ contingentEntityList }: Props) {
  return (
    <>
      {contingentEntityList.map((contingentEntity) => {
        let color = $primaryBlue;
        let name = '';

        if (isStockContingent(contingentEntity)) {
          assertRelationIsObject(
            contingentEntity.contingent,
            'stockContingent.contingent'
          );
          color = contingentEntity.contingent.color;
          name = contingentEntity.contingent.name || '';
        } else {
          color = contingentEntity.color;
          name = contingentEntity.name || '';
        }

        return (
          <div key={contingentEntity['@id']} className="legend-item">
            <MpdIcon icon="seat-filled" width="12" color={color} />
            <span className="bold small ml2">{name}</span>
          </div>
        );
      })}
    </>
  );
}

export default ContingentEntityList;
