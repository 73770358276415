import { Map } from 'immutable';
import URI from 'urijs';
import User from '../entity/User';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class UserClient extends AbstractClient<TSMetadata['user']> {
  getCurrent(fields: FieldsParameter): Promise<User> {
    const url = new URI('/v1/me');
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(this.authorizedFetch(url), 'item');
  }

  getUserSettings(fields: FieldsParameter): Promise<User> {
    const url = new URI('/v1/me/settings');
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(this.authorizedFetch(url), 'item');
  }

  updateSettings(
    userSettings: Map<string, unknown>,
    fields: FieldsParameter
  ): Promise<User> {
    const url = new URI(`me/settings`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'PUT',
        body: JSON.stringify(userSettings),
      }),
      'item'
    );
  }
}

export default UserClient;
