import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
  useMemo,
} from 'react';

export enum View {
  Contingent = 'contingent',
  SeatGroup = 'seatGroup',
}

export type ViewListType = ReadonlyArray<View>;

type ViewContextType = {
  selectedView: View;
  onSelectView: (value: View) => void;
  viewList: ViewListType;
};

const ViewContext = createContext<ViewContextType | null>(null);

type Props = {
  children: ReactNode;
  viewList: ViewListType;
  selectedView?: View;
  onSelectView?: (value: View) => void;
};

export function ViewContextProvider({
  children,
  viewList,
  selectedView,
  onSelectView,
}: Props): ReactElement {
  const [localSelectedView, setLocalSelectedView] = useState(
    viewList.includes(View.Contingent) ? View.Contingent : viewList[0]
  );

  const providerValue = useMemo(
    () => ({
      viewList,
      selectedView: selectedView ?? localSelectedView,
      onSelectView: onSelectView ?? setLocalSelectedView,
    }),
    [localSelectedView, onSelectView, selectedView, viewList]
  );

  return (
    <ViewContext.Provider value={providerValue}>
      {children}
    </ViewContext.Provider>
  );
}

export const useViewContext = (): ViewContextType => {
  const context = useContext(ViewContext);

  if (!context) {
    throw new Error('useViewContext must be used within a ViewContextProvider');
  }

  return context;
};
