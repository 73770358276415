import { Record } from 'immutable';

// old function without TypeScript, not working
// const NetworkEntity = (defaultValues) =>
//   class extends Record({ responseDate: null, ...defaultValues }) {};

export type WithResponseDate = {
  responseDate: null | string;
};

function NetworkEntity<TProps extends { [key: string]: unknown }>(
  defaultValues: TProps
): Record.Factory<TProps & WithResponseDate> &
  Readonly<TProps & WithResponseDate> {
  // @ts-expect-error does work at runtime
  return Record<TProps & WithResponseDate>({
    responseDate: null,
    ...defaultValues,
  });
}

export default NetworkEntity;
