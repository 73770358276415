import { Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import DeviceStateClient from '../client/DeviceStateClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const PAPER_TYPE_THERMAL_ROLLER = 'thermal_roller';
export const PAPER_TYPE_STOCK_TICKETS = 'stock_tickets';
export const PAPER_TYPE_LIST = [
  PAPER_TYPE_STOCK_TICKETS,
  PAPER_TYPE_THERMAL_ROLLER,
];

export type DeviceStateType = BaseEntity<
  'DeviceState',
  `/v1/device_states/${string}`
> & {
  contract: null | EntityRelation<Contract>;
  name: null | string;
  paperTypeTicketParametersMap: Map<string, unknown>;
  lastUpdatedTimestamp: null;
  uptime: null | string;
  ramUsage: null | string;
  diskUsage: null | string;
  forwardingMode: boolean;
  isTokenReadable: null | boolean;
  printersState: null | string;
  publicIpAddress: null | string;
  networkInterfaces: null | Array<Record<string, unknown>>;
  pageCutMode: null | string;
  printSpeed: null | string;
  configuration: null | Record<string, unknown>;
  packageVersion: null | string;
  createdAt: null | string;
  updatedAt: null | string;
};

class DeviceState extends NetworkEntity<DeviceStateType>({
  '@id': null,
  '@type': 'DeviceState',
  contract: null,
  name: null,
  paperTypeTicketParametersMap: Map<string, unknown>(),
  lastUpdatedTimestamp: null,
  uptime: null,
  ramUsage: null,
  diskUsage: null,
  forwardingMode: false,
  isTokenReadable: null,
  printersState: null,
  publicIpAddress: null,
  networkInterfaces: null,
  pageCutMode: null,
  printSpeed: null,
  configuration: null,
  packageVersion: null,
  createdAt: null,
  updatedAt: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<DeviceStateType> = {
      '@id': null,
      '@type': 'DeviceState',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/device_states/', '') || '';
  }
}

DeviceState.classMetadata = new ClassMetadata(
  'deviceState',
  'device_states',
  /** @ts-expect-error -- method signature are incompatible */
  DeviceStateClient
);
DeviceState.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute(
    'paperTypeTicketParametersMap',
    'paperTypeTicketParametersMap',
    'object'
  ),
  new Attribute('lastUpdatedTimestamp', 'lastUpdatedTimestamp', 'datetime'),
  new Attribute('uptime'),
  new Attribute('ramUsage'),
  new Attribute('diskUsage'),
  new Attribute('forwardingMode', 'forwardingMode', 'boolean'),
  new Attribute('isTokenReadable', 'isTokenReadable', 'boolean'),
  new Attribute('printersState'),
  new Attribute('publicIpAddress'),
  new Attribute('networkInterfaces', 'networkInterfaces', 'array'),
  new Attribute('pageCutMode'),
  new Attribute('printSpeed'),
  new Attribute('configuration', 'configuration', 'array'),
  new Attribute('packageVersion'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
]);
DeviceState.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default DeviceState;
