import { useMemo } from 'react';
import { Map } from 'immutable';
import {
  useCurrentCartBookingError,
  useCurrentCartMapadoError,
} from '@mapado/cart';

type BookingError = {
  message: string;
  'mapado:error'?: null | Map<string, unknown>;
};

export default function useBookingError(): BookingError | undefined {
  const bookingError = useCurrentCartBookingError();
  const mapadoError = useCurrentCartMapadoError();

  // avoid changing the reference of the object if the error is the same
  const memoizedBookingError = useMemo<BookingError | undefined>(() => {
    if (!bookingError) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return {
      message: bookingError,
      'mapado:error': mapadoError,
    };
  }, [bookingError, mapadoError]);

  return memoizedBookingError;
}
