import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFolderFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 14" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M14.498 4.51h.517c.616 0 1.133.429 1.133.965v1.246h2.117c.394 0 .837.3.714.601l-2.29 6.055c-.123.322-.32.623-.69.623H.71c-.369 0-.812-.3-.689-.623l.05-.107c-.05-.086-.05-.172-.05-.258V2.62c0-.344.32-.623.714-.623h1.674v-.558C2.41.644 3.148 0 4.06 0h8.79c.91 0 1.649.644 1.649 1.439zM4.872 5.517h7.164v.709H4.872zm0-1.846h7.164v.73H4.872zm0-1.825h7.164v.73H4.872zm-1.625.15v4.724H13.66V1.439c0-.387-.37-.709-.812-.709h-8.79c-.443 0-.812.322-.812.709zm-.837.73H.859v8.439l1.428-3.844c.049-.086.073-.15.123-.236zm12.088 3.994h.813V5.475c0-.128-.148-.236-.296-.236h-.517z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolderFilled;
