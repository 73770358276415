import { ClassMetadata, Serializer } from 'rest-client-sdk';
import type Cart from '../entity/Cart';
import { WithResponseDate } from '../entity/NetworkEntity';
import normalizeCart from '../serializer/normalizeCart';
import createEntity from './EntityFactory';
import EntityRegistry from './EntityRegistry';
import { AllowedFactoryTypes, Entity, EntityTypes } from './types';

function addResponseDateToObject<T extends Record<string, unknown>>(
  object: T,
  response: Response
): T & WithResponseDate {
  const responseDate = response?.headers?.get('date') || null;

  const out = object as T & WithResponseDate; // responseDate will be added juste after that
  out.responseDate = responseDate;

  return out;
}

const isEntityCart = (
  entity: Entity,
  classMetadata: ClassMetadata
): entity is Cart => classMetadata && classMetadata.key === 'cart';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default class EntitySerializer extends Serializer {
  normalizeItem(
    entity: Entity,
    classMetadata: ClassMetadata
  ): Record<string, unknown> {
    if (isEntityCart(entity, classMetadata)) {
      return normalizeCart(entity);
    }

    return entity.toJS();
  }

  encodeItem(
    object: Record<string, unknown>,
    classMetadata: ClassMetadata
  ): string {
    return JSON.stringify(object);
  }

  decodeItem(
    rawData: string,
    classMetadata: ClassMetadata,
    response: Response
  ): Record<string, unknown> {
    return rawData
      ? addResponseDateToObject(JSON.parse(rawData), response)
      : null;
  }

  denormalizeItem(
    object: AllowedFactoryTypes,
    classMetadata: ClassMetadata,
    response: Response
  ): EntityTypes {
    // do stuff with your item input

    if (object) {
      const entityRegistry = this.#hasExperimentalEntitySameInstance(response)
        ? new EntityRegistry()
        : undefined;

      return createEntity(object, entityRegistry) as EntityTypes;
    }

    throw new Error('Unable to denormalize item.');
  }

  decodeList(
    rawListData: string,
    classMetadata: ClassMetadata,
    response: Response
  ): Iterable<Record<string, unknown>> {
    return rawListData
      ? addResponseDateToObject(JSON.parse(rawListData), response)
      : null;
  }

  denormalizeList(
    objectList: Iterable<Record<string, unknown>>,
    classMetadata: ClassMetadata,
    response: Response
  ): Iterable<Record<string, unknown>> {
    const entityRegistry = this.#hasExperimentalEntitySameInstance(response)
      ? new EntityRegistry()
      : undefined;

    /** @ts-expect-error -- really really hard / fucked up */
    return createEntity(objectList, entityRegistry);
  }

  #hasExperimentalEntitySameInstance(response: Response): boolean {
    if (!response.url) {
      // handle manually created response (like in mock library)
      return false;
    }

    const experimentalEntitySameInstance = new URL(
      response.url
    ).searchParams.has('experimentalEntitySameInstance');

    return experimentalEntitySameInstance;
  }
}
