import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 19 14" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M15.015 2.91H7.53L6.3.728Q6.253.645 6.21.562C6.058.268 5.918 0 5.586 0H.735a.72.72 0 0 0-.714.728V12.87c0 .1 0 .2.05.301l-.05.125c-.123.377.32.703.69.703H16c.37 0 .566-.326.69-.703l2.29-7.075c.122-.376-.321-.702-.715-.702h-2.117V4.065c0-.628-.518-1.155-1.133-1.155m.295 2.61H3c-.394 0-.591.326-.714.703l-1.428 4.49V.854h4.555c.044.06.079.128.11.19q.032.064.062.111l1.477 2.61h7.953c.148 0 .295.15.295.3zm2.43 1.217H3.464l-2.254 5.895h14.275z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolder;
