import clone from 'clone';
import { List } from 'immutable';
import type { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import CouponClient from '../client/CouponClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import CartItem from './CartItem';
import Customer from './Customer';
import CustomerStatus from './CustomerStatus';
import NetworkEntity from './NetworkEntity';
import OfferRule from './OfferRule';
import Order from './Order';
import OrderItem from './OrderItem';
import Season from './Season';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum COUPON_USAGE {
  CASH = 'cash',
  PER_ORDER = 'per_order',
  PER_ORDER_ITEM = 'per_order_item',
  PER_APPLICATION = 'per_application',
}

export type CouponType = BaseEntity<'Coupon'> & {
  code: null | string;
  data: null | Map<string, unknown>;
  contractKey: string;
  amount: null | number;
  initialAmount: null | number;
  usage: null | COUPON_USAGE;
  nbUseMax: null | number;
  nbUseCurrent: null | number;
  description: null | string;
  offerRuleList: null | List<OfferRule>;
  customerStatusList: null | List<CustomerStatus>;
  validFrom: null | Moment;
  validUntil: null | Moment;
  applyingCartItemList: null | List<EntityRelation<CartItem>>;
  applyingOrderItemList: null | List<OrderItem>;
  applyingOrderList: null | List<EntityRelation<Order>>;
  creatingOrderItemList: null | List<EntityRelation<OrderItem>>;
  creatingCartItemList: null | List<EntityRelation<CartItem>>;
  creatingOrder: null | Order;
  enabled: null | boolean;
  season: null | Season;
  isExpired: null | boolean;
  creatingOfferRule: null | OfferRule;
  participant: null | EntityRelation<Customer>;
};

class Coupon extends NetworkEntity<CouponType>({
  '@id': null,
  '@type': 'Coupon',
  code: null,
  data: null,
  contractKey: '',
  amount: null,
  initialAmount: null,
  usage: null,
  nbUseMax: null,
  nbUseCurrent: 0,
  description: '',
  offerRuleList: List<OfferRule>(),
  customerStatusList: null,
  validFrom: null,
  validUntil: null,
  applyingCartItemList: List<CartItem>(),
  applyingOrderItemList: List<OrderItem>(),
  applyingOrderList: null,
  creatingOrderItemList: List<OrderItem>(),
  creatingCartItemList: List<CartItem>(),
  creatingOrder: null,
  enabled: null,
  season: null,
  isExpired: null,
  creatingOfferRule: null,
  participant: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CouponType> = { '@id': null, '@type': 'Coupon' }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['creatingOfferRule', 'timezone']);
    data.validFrom = parseDate(val.validFrom, tz);
    data.validUntil = parseDate(val.validUntil, tz);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/coupons/', '') || '';
  }
}

/** @ts-expect-error -- method signature are incompatible */
Coupon.classMetadata = new ClassMetadata('coupon', 'coupons', CouponClient);
Coupon.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('data', 'data', 'object'),
  new Attribute('code'),
  new Attribute('contractKey'),
  new Attribute('usage'),
  new Attribute('description'),
  new Attribute('amount', 'amount', 'integer'),
  new Attribute('initialAmount', 'initialAmount', 'integer'),
  new Attribute('nbUseMax', 'nbUseMax', 'integer'),
  new Attribute('nbUseCurrent', 'nbUseCurrent', 'integer'),
  new Attribute('validFrom', 'validFrom', 'datetime'),
  new Attribute('validUntil', 'validUntil', 'datetime'),
  new Attribute('enabled', 'enabled', 'boolean'),
  new Attribute('isExpired', 'isExpired', 'boolean'),
]);
Coupon.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'offerRule', 'offerRuleList'),
  new Relation(Relation.ONE_TO_MANY, 'cartItem', 'applyingCartItemList'),
  new Relation(Relation.ONE_TO_MANY, 'orderItem', 'applyingOrderItemList'),
  new Relation(Relation.ONE_TO_MANY, 'order', 'applyingOrderList'),
  new Relation(Relation.ONE_TO_MANY, 'cartItem', 'creatingCartItemList'),
  new Relation(Relation.ONE_TO_MANY, 'orderItem', 'creatingOrderItemList'),
  new Relation(Relation.ONE_TO_MANY, 'customerStatus', 'customerStatusList'),
  new Relation(Relation.MANY_TO_ONE, 'season', 'seasons'),
  new Relation(Relation.ONE_TO_ONE, 'offerRule', 'creatingOfferRule'),
  new Relation(Relation.ONE_TO_ONE, 'order', 'creatingOrder'),
  new Relation(Relation.ONE_TO_ONE, 'customer', 'participant'),
]);

export default Coupon;
