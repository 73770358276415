import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SeatClient from '../client/SeatClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import SeatGroup from './SeatGroup';
import StockContingent from './StockContingent';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum SEAT_TYPE {
  NORMAL = 'normal',
  FOLDABLE = 'foldable',
  STANDING = 'standing',
  REDUCED_MOBILITY = 'reduced_mobility',
}

export type SeatType = BaseEntity<'Seat'> & {
  seatGroup: null | EntityRelation<SeatGroup>;
  position: null | number;
  info: null | Record<string, unknown>;
  stockContingent: null | EntityRelation<StockContingent>;
  angle: null | number;
  type: null | SEAT_TYPE;
  hasObstructedView: null | boolean;
};

class Seat extends NetworkEntity<SeatType>({
  '@id': null,
  '@type': 'Seat',
  seatGroup: new SeatGroup(),
  position: null,
  info: null,
  stockContingent: null,
  angle: null,
  type: null,
  hasObstructedView: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<SeatType> = { '@id': null, '@type': 'Seat' }) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/seats/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Seat.classMetadata = new ClassMetadata('seat', 'seats', SeatClient);
Seat.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('position', 'position', 'number'),
  new Attribute('angle', 'angle', 'number'),
  new Attribute('type', 'type', 'string'),
  new Attribute('hasObstructedView', 'hasObstructedView', 'boolean'),
  new Attribute('info', 'info', 'object'),
]);
Seat.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seatGroup', 'seatGroup'),
  new Relation(Relation.MANY_TO_ONE, 'stockContingent', 'stockContingent'),
]);

export default Seat;
