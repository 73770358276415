import { BankAccountType } from '../entity/BankAccount';
import Wallet from '../entity/Wallet';
import { TSMetadata } from '../mapping';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class BankAccountClient extends AbstractClient<TSMetadata['bankAccount']> {
  getPathBase(): string {
    return '/v1/bank_accounts';
  }

  findOneByDefaultAccountAndWallet(
    wallet: Wallet,
    fields: FieldsParameter
  ): Promise<Iterable<BankAccountType>> {
    return this.findBy(
      { wallet: wallet.getShortId(), defaultAccount: 1 },
      fields
    );
  }
}

export default BankAccountClient;
