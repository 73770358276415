import * as React from 'react';
import type { SVGProps } from 'react';
const SvgParameter = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 25" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-243 -1045)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="parametre"
            d="M161.56 227a4.002 4.002 0 0 1 7.546 0h5.56v2.667h-5.56a4.002 4.002 0 0 1-7.545 0h-16.228V227zm1.334 16a4.002 4.002 0 0 1 7.545 0h4.228v2.667h-4.228a4.002 4.002 0 0 1-7.545 0h-17.56V243zm-4.455-8h16.228v2.667h-16.228a4.002 4.002 0 0 1-7.545 0h-5.56V235h5.56a4.002 4.002 0 0 1 7.545 0m-3.772 0a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667m12 8a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667m-1.334-16a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgParameter;
