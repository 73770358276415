import Version from '../entity/Version';
import { TSMetadata } from '../mapping';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class VersionClient extends AbstractClient<TSMetadata['version']> {
  getPathBase(): string {
    return '/v1/versions';
  }

  findTicketingApiVersion(fields: FieldsParameter = []): Promise<Version> {
    return this.find('ticketing-api', fields);
  }
}

export default VersionClient;
