import { List } from 'immutable';

type SubFields = Record<string, Fields>;
export type Fields = ReadonlyArray<string | SubFields>;
export type ShortId = number | string;
type FieldsInObject = {
  [key: string]: Fields;
};
// type FieldsParameter = string | Fields | { fields: Fields };

/**
 * Parse an object or and array into a string
 * of comma separated fields, with {} to
 * represent nesting.
 *
 * format ex: [
 *  'firstname',
 *  'lastname',
 *  {
 *    address: [
 *      'streetNumber',
 *      'city',
 *    ],
 *  },
 * ]
 *
 * It will be parsed likewise: "firstname,lastname,address{streetNumber,city}"
 *
 * It is also possible to pass an object with a root field
 *
 * format ex: {
 *  fields: [
 *    'firstname',
 *    'lastname',
 *    ...
 *  ],
 * }
 */
export default function getFieldsString(
  fields: undefined | string | Fields | FieldsInObject
): string {
  if (typeof fields === 'undefined') {
    return '';
  }
  if (typeof fields === 'string') {
    return fields;
  }

  let newFields = fields;
  if (newFields instanceof Object && !(newFields instanceof Array)) {
    const keys = Object.keys(newFields);
    const fieldsArrayKey = keys[0];
    if (keys.length > 1) {
      // eslint-disable-next-line no-console
      console.warn(
        'Using a Fields with more than one key return incertain results and may return unexpected results'
      );
    }

    newFields = newFields[fieldsArrayKey];
  }

  // convert SubFields to string
  const fieldsStr = newFields
    .map((newField) => {
      if (typeof newField === 'string') {
        return newField;
      }

      // eslint-disable-next-line no-use-before-define
      return getSubFieldString(newField);
    })
    .join(',');

  return fieldsStr;
}

function getSubFieldString(subfield: SubFields): string {
  const key = Object.keys(subfield)[0];

  return `${key}{${getFieldsString(subfield[key])}}`;
}

export function isEmptyArrayOrList(
  prop: Array<unknown> | List<unknown> | unknown
): boolean {
  if (Array.isArray(prop)) {
    return prop.length === 0;
  }

  if (List.isList(prop)) {
    return (prop as List<unknown>).size === 0;
  }

  return false;
}

export function extractPageNumber(url: string): number | null {
  const urlSearchParams = new URLSearchParams(url);
  const page = urlSearchParams.get('page');

  return page ? parseInt(page, 10) : null;
}
