import React from 'react';
import { BaseModal } from '@mapado/js-component';
import { useTranslation } from '../../i18n';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  newCategory: string | null;
  oldCategory: string | null;
};

function OtherCategoryReplacementModal({
  onCancel,
  onConfirm,
  newCategory,
  oldCategory,
}: Props) {
  const { t } = useTranslation();

  return (
    <BaseModal
      onCancel={onCancel}
      title={t('modal.other_category.title')}
      actions={
        <>
          <button
            type="button"
            className="mpd-btn mpd-btn--secondary"
            onClick={onCancel}
          >
            {t('modal.other_category.cancel')}
          </button>

          <button
            type="button"
            className="mpd-btn mpd-btn--primary"
            onClick={onConfirm}
          >
            {t('modal.other_category.confirm')}
          </button>
        </>
      }
    >
      <p>
        {t('modal.other_category', {
          oldCategory,
          newCategory,
        })}
      </p>
      <p className="mpd-color-gray">{t('modal.other_category.note')}</p>
    </BaseModal>
  );
}

export default OtherCategoryReplacementModal;
