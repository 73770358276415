import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class AuditDocumentClient extends AbstractClient<TSMetadata['auditDocument']> {
  getPathBase(): string {
    return '/v1/audit_documents';
  }

  getFile(id: number | string): Promise<Response> {
    const uri = `${this.getPathBase()}/${id}/file.pdf`;

    return this.authorizedFetch(uri);
  }
}

export default AuditDocumentClient;
