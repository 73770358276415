import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCart = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 27" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-172 -974)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="panier"
            d="M98.025 157.39h7.821L100.484 180h-21.09L74 157.39h7.984v-4.057h2.683v4.057h10.666v-4.057h2.692zm-14.59 7.943h2.597V172h-2.597zm5.232 0h2.666V172h-2.666zm5.335 0h2.563V172h-2.563zm-16.624-5.276 4.122 17.276h16.876l4.097-17.276z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCart;
