import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClockStroke = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="clock-stroke">
        <path
          id="Shape"
          d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10"
        />
        <path
          id="Shape"
          d="m9.707 15.707 3-3A1 1 0 0 0 13 12V7a1 1 0 0 0-2 0v5l.293-.707-3 3a1 1 0 0 0 1.414 1.414"
        />
        <circle id="Oval-164" cx={18} cy={12} r={1} />
        <circle id="Oval-164" cx={6} cy={12} r={1} />
        <circle id="Oval-164" cx={12} cy={18} r={1} />
      </g>
    </g>
  </svg>
);
export default SvgClockStroke;
