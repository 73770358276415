import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Checkpoint from './Checkpoint';
import NetworkEntity from './NetworkEntity';
import ScanTrack from './ScanTrack';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ScanTrackCheckpointType = BaseEntity<'ScanTrackCheckpoint'> & {
  maxScanNb: null | number;
  scanTrack: null | EntityRelation<ScanTrack>;
  checkpoint: null | EntityRelation<Checkpoint>;
};

class ScanTrackCheckpoint extends NetworkEntity<ScanTrackCheckpointType>({
  '@id': null,
  '@type': 'ScanTrackCheckpoint',
  maxScanNb: null,
  scanTrack: null,
  checkpoint: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ScanTrackCheckpointType> = {
      '@id': null,
      '@type': 'ScanTrackCheckpoint',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/scan_track_checkpoints/', '') || '';
  }
}

ScanTrackCheckpoint.classMetadata = new ClassMetadata(
  'scanTrackCheckpoint',
  'scan_track_checkpoints',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
ScanTrackCheckpoint.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('maxScanNb', 'maxScanNb', 'integer'),
]);
ScanTrackCheckpoint.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'scanTrack', 'scanTrack'),
  new Relation(Relation.MANY_TO_ONE, 'checkpoint', 'checkpoint'),
]);

export default ScanTrackCheckpoint;
