import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRemoveInput = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 14" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/effacer" fillRule="nonzero" transform="translate(-3 -8)">
        <path
          id="Combined-Shape"
          d="M26.354 8v14H9.391L3 15l6.391-7zm-2 2h-14.08l-4.566 5 4.565 5h14.081zm-7.997 1.393 2.046 2.194 2.195-2.046 1.364 1.462-2.195 2.046 2.046 2.194-1.462 1.364-2.046-2.194-2.194 2.046-1.364-1.462 2.194-2.046-2.046-2.194z"
        />
      </g>
    </g>
  </svg>
);
export default SvgRemoveInput;
