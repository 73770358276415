import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import type {
  BookingActionTypes,
  StoreWithBooking,
} from '../redux/booking/reducer';

type CartDispatch = Dispatch<BookingActionTypes>;
type CartThunkDispatch = ThunkDispatch<
  StoreWithBooking,
  unknown,
  BookingActionTypes
>;

type DispatchFunc = () => CartDispatch & CartThunkDispatch;

export const useCartDispatch: DispatchFunc = useDispatch;
export const useCartSelector: TypedUseSelectorHook<StoreWithBooking> =
  useSelector;
