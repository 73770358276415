import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { List } from 'immutable';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Switch, Route, useHistory } from 'react-router';
import type { History } from 'history';
import { MpdLoader, MpdIcon } from '@mapado/makeup';
import TicketingSdk, { User, Fields } from 'mapado-ticketing-js-sdk';
import { DomainContext, DOMAIN_CONTEXT } from '@mapado/js-component';
import LinkMenu from './LinkMenu';
import DemoContent from './DemoContent';
import redirectToLoginPage from './redirectToLoginPage';
import OauthCallback from './OAuthCallback';
import { enableInContextTranslation } from '../../src/i18n';
import { SeatConfigShape } from './DemoTypes';
import { useRecordLatestPage, RedirectToLatestPage } from './LatestPage';
import { configureTicketingSdk } from './config/configureTicketingSdk';
import { configureMoment } from './config/configureMoment';

const USER_FIELDS: Fields = [
  'avatar',
  'email',
  'fullName',
  'impersonated',
  'contractUserList',
] as const;

type DemoProps = {
  onGoToLogin: (history: History, errorMessage?: string) => void;
  sdk: TicketingSdk;
};

function Demo({ onGoToLogin, sdk }: DemoProps) {
  const history = useHistory();
  const [seatConfigList, setsSeatConfigList] = useState<List<SeatConfigShape>>(
    List()
  );
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [mountKey, setMountKey] = useState(Date.now());
  const [domainContext, setDomainContext] = useState(DOMAIN_CONTEXT.DESK);

  const simulateUnmountAndRemount = (): void => {
    setMountKey(Date.now());
  };
  const switchContext = (): void => {
    setDomainContext((prev) =>
      prev === DOMAIN_CONTEXT.DESK
        ? DOMAIN_CONTEXT.MINISITE
        : DOMAIN_CONTEXT.DESK
    );
  };

  useEffect(() => {
    Promise.all([
      sdk.getRepository('user').getCurrent(USER_FIELDS),
      sdk.getRepository('seatConfig').findAll([
        '@id',
        'label',
        'totalStock',
        {
          contract: ['@id', 'companyDisplayName'],
        },
      ] as const),
    ])
      .then(([user, seatConfigCollection]) => {
        // @ts-expect-error -- custom serializer typing error
        let seatConfigArray = seatConfigCollection.get(
          'hydra:member'
        ) as SeatConfigShape[];

        seatConfigArray = seatConfigArray.filter(
          (seatConfig: SeatConfigShape): boolean => seatConfig.contract !== null
        );
        setCurrentUser(user);
        setsSeatConfigList(List(seatConfigArray));
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        onGoToLogin(
          history,
          e?.baseResponse?.status === 403
            ? 'Forbidden error : Are you impersonnated ?'
            : e?.message
        );
      });
  }, [history, onGoToLogin, sdk]);

  if (!currentUser) {
    return (
      <div className="seating-demo__loaderCentered">
        <MpdLoader />
      </div>
    );
  }

  return (
    <div className="seating-demo-root-wrapper">
      <div
        className={cn(
          'seating-demo-header',
          currentUser.impersonated && 'seating-demo-header--impersonnated'
        )}
      >
        <span>{currentUser.email}</span>
        <div>
          <a
            href="#translate"
            className="mr2"
            onClick={(e) => {
              e.preventDefault();
              enableInContextTranslation();
            }}
          >
            Traduire
          </a>
          <Link to="/logout">Déconnexion</Link>
        </div>
      </div>

      <LinkMenu />

      <div className="seating-demo-root">
        <div className="seating-demo-root__content">
          <div>
            <button
              type="button"
              className="mpd-btn mpd-btn--link mpd-btn--no-padding mb1"
              onClick={simulateUnmountAndRemount}
            >
              <MpdIcon icon="magnifying-glass" height="16" width="16" />
              <span>Simulate unmount + remount</span>
            </button>
            <button
              type="button"
              className="mpd-btn mpd-btn--link mpd-btn--no-padding mb1"
              onClick={switchContext}
            >
              <MpdIcon icon="refresh" height="16" width="16" />
              <span>Switch domain context (actual: {domainContext})</span>
            </button>
          </div>

          <DomainContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              currentDomain: domainContext,
              deskDomain: '',
            }}
          >
            <DemoContent
              key={mountKey}
              seatConfigList={seatConfigList}
              sdk={sdk}
            />
          </DomainContext.Provider>
        </div>
      </div>
    </div>
  );
}

function Login({ errorMessage }: { errorMessage?: string }) {
  return (
    <div className="seating-demo__loaderCentered">
      <div className="mpd-block-content">
        <button
          type="button"
          className="mpd-btn mpd-btn--primary"
          onClick={() => {
            redirectToLoginPage(
              new URLSearchParams(window.location.search).get('redirectUrl') ||
                '/'
            );
          }}
        >
          Se connecter avec Mapado
        </button>
      </div>

      {errorMessage && (
        <div className="mpd-alert mpd-alert--error">{errorMessage}</div>
      )}
    </div>
  );
}

function Logout({ ticketingSdk }: { ticketingSdk: TicketingSdk }) {
  const history = useHistory();

  useEffect(() => {
    ticketingSdk.tokenStorage.logout().then(() => {
      history.push('/');
    });
  }, [history, ticketingSdk.tokenStorage]);

  return null;
}

function WithRouterDemo(): React.ReactElement {
  configureMoment();

  const sdk = configureTicketingSdk();

  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  useRecordLatestPage();

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <RedirectToLatestPage />
        </Route>

        <Route exact path="/logout">
          <Logout ticketingSdk={sdk} />
        </Route>

        <Route exact path="/login">
          <Login errorMessage={errorMessage} />
        </Route>

        <Route exact path="/oauth-callback">
          <OauthCallback ticketingSdk={sdk} />
        </Route>

        <Route>
          <Demo
            onGoToLogin={(history: History, innerErrorMessage?: string) => {
              setErrorMessage(innerErrorMessage);

              const redirectUrl = `${window.location.pathname}${window.location.search}`;

              history.push(`/login?redirectUrl=${redirectUrl}`);
            }}
            sdk={sdk}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default WithRouterDemo;
