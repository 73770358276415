import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Checkpoint from './Checkpoint';
import NetworkEntity from './NetworkEntity';
import Ticket from './Ticket';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TicketCheckpointType = BaseEntity<'TicketCheckpoint'> & {
  checkpoint: null | EntityRelation<Checkpoint>;
  currentScanNb: null | number;
  currentValidScanNb: null | number;
  firstScannedDate: null | Moment;
  lastScannedDate: null | Moment;
  maxScanNb: null | number;
  isUnlimited: null | boolean;
  ticket: null | EntityRelation<Ticket>;
  valid: null | boolean;
  validEndDate: null | Moment;
  validStartDate: null | Moment;
};

class TicketCheckpoint extends NetworkEntity<TicketCheckpointType>({
  '@id': null,
  '@type': 'TicketCheckpoint',
  checkpoint: null,
  currentScanNb: null,
  currentValidScanNb: null,
  firstScannedDate: null,
  lastScannedDate: null,
  maxScanNb: null,
  isUnlimited: null,
  ticket: null,
  valid: null,
  validEndDate: null,
  validStartDate: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketCheckpointType> = {
      '@id': null,
      '@type': 'TicketCheckpoint',
    }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['ticket', 'ticketingTimezone']);

    data.lastScannedDate = parseDate(data.lastScannedDate, tz);
    data.firstScannedDate = parseDate(data.firstScannedDate, tz);
    data.validStartDate = parseDate(data.validStartDate, tz);
    data.validEndDate = parseDate(data.validEndDate, tz);

    super(data);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticket_checkpoints/', '') || '';
  }
}

TicketCheckpoint.classMetadata = new ClassMetadata(
  'ticketCheckpoint',
  'ticket_checkpoints',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
TicketCheckpoint.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('maxScanNb', 'maxScanNb', 'integer'),
  new Attribute('currentScanNb', 'currentScanNb', 'integer'),
  new Attribute('currentValidScanNb', 'currentValidScanNb', 'integer'),
  new Attribute('firstScannedDate', 'firstScannedDate', 'datetime'),
  new Attribute('lastScannedDate', 'lastScannedDate', 'datetime'),
  new Attribute('isUnlimited', 'isUnlimited', 'boolean'),
  new Attribute('valid', 'valid', 'boolean'),
  new Attribute('validStartDate', 'validStartDate', 'datetime'),
  new Attribute('validEndDate', 'validEndDate', 'datetime'),
]);
TicketCheckpoint.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'ticket', 'ticket'),
  new Relation(Relation.MANY_TO_ONE, 'checkpoint', 'checkpoint'),
]);

export default TicketCheckpoint;
