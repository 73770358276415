import React, { useState, ReactElement, useRef, useEffect } from 'react';
import cn from 'classnames';
import MpdIcon from './MpdIcon';

type Props = {
  colorList: ReadonlyArray<string>;
  onChange: (color: string) => void;
  selectedColor: string | null;
  isGradiant?: boolean;
};

function MpdColorSelector({
  colorList,
  selectedColor,
  onChange,
  isGradiant = false,
}: Props): ReactElement {
  const colorSelectorRef = useRef<HTMLDivElement>(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        openDropdown &&
        !colorSelectorRef?.current?.contains(event.target as Node)
      ) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown, colorSelectorRef]);

  const selectColor = (color: string) => {
    onChange(color);
    setOpenDropdown(false);
  };

  const selectorClassnames = cn(
    'mpd-color-selector__btn mpd-color-selector__color flex-center',
    {
      [`gradiant-background-color-${selectedColor}`]: isGradiant,
    }
  );

  const selectorStyle = isGradiant
    ? {}
    : { backgroundColor: selectedColor || colorList[0] };

  return (
    <div className="mpd-color-selector" ref={colorSelectorRef}>
      <button
        type="button"
        onClick={() => setOpenDropdown(!openDropdown)}
        className={selectorClassnames}
        aria-label="color selector button"
        style={selectorStyle}
      >
        <MpdIcon icon="form-arrow" width="10" color="white" />
      </button>
      <div
        className={cn('mpd-color-selector__dropdown', {
          active: openDropdown,
        })}
      >
        {colorList.map((color) => {
          const buttonClassnames = cn(
            'mpd-color-selector__btn mpd-color-selector__color--small',
            {
              selected: selectedColor === color,
              [`gradiant-background-color-${color}`]: isGradiant,
            }
          );

          const buttonStyles = isGradiant ? {} : { backgroundColor: color };

          return (
            <button
              key={color}
              type="button"
              onClick={() => selectColor(color)}
              aria-label="color selector button"
              className={buttonClassnames}
              style={buttonStyles}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MpdColorSelector;
