import { List, Record as ImmutableRecord } from 'immutable';
import createEntity from './EntityFactory';
import EntityRegistry from './EntityRegistry';
import type { AllowedFactoryTypes, Entity, EntityTypes } from './types';

function mapListToEntityList(
  list: Array<AllowedFactoryTypes>,
  registry?: EntityRegistry
): List<EntityTypes> {
  return List(
    list.map((value) => createEntity(value, registry) as EntityTypes)
  );
}

export default function mapEntityRelationShips<E extends Entity>(
  entity: E,
  baseJson: AllowedFactoryTypes,
  registry?: EntityRegistry
): E {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error -- really hard for now */
  return entity.withMutations((mutableEntity: E) => {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const prop in baseJson) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error */
      const val = baseJson[prop];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- prop does exist, no need to have a default value */
      if (!val || !entity.get(prop)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (Array.isArray(val)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, mapListToEntityList(val, registry));
      } else if (List.isList(val)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, val);
      } else if (val instanceof ImmutableRecord) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, val);
      } else if (typeof val === 'object' && val !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(
          prop,
          createEntity(val as AllowedFactoryTypes, registry)
        );
      }
    }
  });
}
