import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrm = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 28" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-316 -975)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="crm"
            d="M220.667 157.333v21.334h20v-21.334zM218 154.667h25.333v26.666H218zm6.667 6.666h12V164h-12zm0 5.334h12v2.666h-12zm0 5.333h12v2.667h-12z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCrm;
