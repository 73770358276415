import type { FeatureType } from '../entity/Feature';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class FeatureClient extends AbstractClient<TSMetadata['feature']> {
  getFeatures(): Promise<FeatureType> {
    const url = '/v1/features?fields=';

    return this.deserializeResponse(this.authorizedFetch(url), 'item');
  }

  getContractFeatures(contractId: string): Promise<FeatureType> {
    const url = `/v1/contracts/${contractId}/features?fields=`;

    return this.deserializeResponse(this.authorizedFetch(url), 'item');
  }
}

export default FeatureClient;
