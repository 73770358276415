import URI from 'urijs';
import SeatReserved from '../entity/SeatReserved';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

// relation in body MUST be a string|null, not string|null|Entity
type NoEntityRelationSeatReserved = SeatReserved &
  Record<keyof Pick<SeatReserved, 'eventDate' | 'seat'>, string | null>;

type Body = {
  'hydra:member'?: Array<NoEntityRelationSeatReserved>;
};
type FilteredBody = {
  'hydra:member'?: Array<FilteredSeatReserved>;
};

type FilteredSeatReserved = {
  '@id': null | string;
  '@type': 'SeatReserved';
  eventDate: null | string;
  seat: null | string;
  status: null | string;
};

function filterBody(body: Body): FilteredBody {
  if (!body['hydra:member']) {
    return {};
  }

  return {
    'hydra:member': body['hydra:member'].map(
      (member: FilteredSeatReserved) => ({
        '@id': member['@id'],
        '@type': member['@type'],
        eventDate: member.eventDate,
        seat: member.seat,
        status: member.status,
      })
    ),
  };
}

class SeatReservedClient extends AbstractClient<TSMetadata['seatReserved']> {
  getPathBase(): string {
    return '/v1/seat_reserveds';
  }

  getDefaultGroups(): Array<string> {
    return ['seat_reserved_read', 'seat_reserved_write'];
  }

  bulkRequest(
    method: string,
    body: Record<string, unknown>,
    fields?: FieldsParameter
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterBody(body)),
    });
  }

  putBulk(
    body: Record<string, unknown>,
    fields: FieldsParameter = []
  ): Promise<Response> {
    return this.bulkRequest('PUT', body, fields);
  }

  postBulk(
    body: Record<string, unknown>,
    fields: FieldsParameter = []
  ): Promise<Response> {
    return this.bulkRequest('POST', body, fields);
  }

  deleteBulk(body: Record<string, unknown>): Promise<Response> {
    return this.bulkRequest('DELETE', body);
  }
}

export default SeatReservedClient;
