import React, { FunctionComponent } from 'react';
import { components, GroupBase, ValueContainerProps } from 'react-select';
import { SingleSelectOption } from './utils';
import MpdIcon from '../../../MpdIcon';

// @ts-expect-error typescipt version is too old. Upgrade it requires to upgrade storybook too
const ValueContainer: FunctionComponent<ValueContainerProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>,
  SingleSelectOption
>> = (props: ValueContainerProps<SingleSelectOption>) => {
  const { selectProps } = props;
  const value = selectProps?.value as SingleSelectOption;
  const inputValue = selectProps?.inputValue;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.ValueContainer {...props}>
        <div className="mpd-single-select-label">
          {value?.iconName && !inputValue && (
            <MpdIcon
              icon={value.iconName}
              color={value.iconColor}
              width="12"
              className="ml1 mr2"
            />
          )}
          {props.children}
        </div>
        {value?.description && !inputValue && (
          <span className="mpd-single-select-value-description">
            {value.description}
          </span>
        )}
      </components.ValueContainer>
    </>
  );
};

export default ValueContainer;
