import clone from 'clone';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import {
  FIELD_TYPE,
  RELATED_TO_TYPE,
} from '../types/CustomerFieldContractTypes';
import { isEmptyArrayOrList } from '../utils';
import Contract from './Contract';
import CustomerField from './CustomerField';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type CustomerFieldContractType = BaseEntity<'CustomerFieldContract'> & {
  type: null | string;
  inputType: null | string;
  slug: null | string | undefined;
  searchType: null | string;
  searchOptions: null | Record<string, unknown>;
  options: null | Record<string, unknown>;
  isDeleteable: null | boolean;
  label: null | string;
  description: null | string;
  /** @deprecated  never used but too afraid to delete it */
  noList: false | boolean;
  formType: null | string;
  formIsMultiple: boolean;
  formOptions: null | Record<string, unknown>;
  position: null | number;
  contract: null | EntityRelation<Contract>;
  customerField: null | EntityRelation<CustomerField>;
  dataTarget: null | FIELD_TYPE | undefined;
  relatedTo: null | RELATED_TO_TYPE | undefined;
  customerFieldContractParent: undefined | string;
  required: undefined | boolean;
  readOnly: undefined | boolean;
};

class CustomerFieldContract extends NetworkEntity<CustomerFieldContractType>({
  '@id': null,
  '@type': 'CustomerFieldContract',
  type: null,
  inputType: null,
  slug: null,
  searchType: null,
  searchOptions: null,
  options: null,
  isDeleteable: null,
  label: null,
  description: null,
  noList: false,
  formType: null,
  formIsMultiple: false,
  formOptions: null,
  position: null,
  contract: null,
  customerField: null,
  dataTarget: undefined,
  relatedTo: undefined,
  customerFieldContractParent: undefined,
  required: undefined,
  readOnly: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CustomerFieldContractType> = {
      '@id': null,
      '@type': 'CustomerFieldContract',
    }
  ) {
    const data = clone(val);

    if (isEmptyArrayOrList(data.options)) {
      data.options = {};
    }

    if (isEmptyArrayOrList(data.formOptions)) {
      data.formOptions = {};
    }

    if (isEmptyArrayOrList(data.searchOptions)) {
      data.searchOptions = {};
    }

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/customer_field_contracts/', '') || '';
  }
}

CustomerFieldContract.classMetadata = new ClassMetadata(
  'customerFieldContract',
  'customer_field_contracts',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
CustomerFieldContract.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('inputType'),
  new Attribute('searchType'),
  new Attribute('slug'),
  new Attribute('options', 'options', 'object'),
  new Attribute('isDeleteable', 'isDeleteable', 'boolean'),
  new Attribute('label'),
  new Attribute('description'),
  new Attribute('noList', 'noList', 'boolean'),
  new Attribute('formType'),
  new Attribute('formIsMultiple', 'formIsMultiple', 'boolean'),
  new Attribute('searchOptions', 'searchOptions', 'object'),
  new Attribute('formOptions', 'formOptions', 'object'),
  new Attribute('position'),
  new Attribute('dataTarget'),
  new Attribute('relatedTo'),
  new Attribute('customerFieldContractParent'),
  new Attribute('required', 'required', 'boolean'),
  new Attribute('readOnly', 'readOnly', 'boolean'),
]);

CustomerFieldContract.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'customerField', 'customerField'),
]);

export default CustomerFieldContract;
