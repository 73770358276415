import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { List } from 'immutable';
import { MpdIconType, MpdPopover } from '@mapado/makeup';
import {
  ColoredItemAdminModal,
  useTicketingSdkContext,
} from '@mapado/js-component';
import { Contingent, SeatGroup } from 'mapado-ticketing-js-sdk';
import SeatGroupSelectorRow from './SeatGroupSelectorRow';
import '../../../../styles/components/Sidebar/SeatGroupSelector.scss';
import { useTranslation } from '../../../../i18n';

function useFetchSeatGroupList(contractId: string): {
  seatGroupList: List<SeatGroup> | null;
  fetchSeatGroupList: () => void;
} {
  const ticketingSdk = useTicketingSdkContext();
  const [seatGroupList, setSeatGroupList] = useState<null | List<SeatGroup>>(
    null
  );

  const fetchSeatGroupList = useCallback(() => {
    ticketingSdk
      .getRepository('seatGroup')
      .findBy(
        {
          contract: contractId,
          'order[label]': 'ASC',
        },
        ['@id', 'seatColor', 'label', 'seatConfigId']
      )
      .then((list) => setSeatGroupList(list.getMembers()));
  }, [ticketingSdk, contractId]);

  return { seatGroupList, fetchSeatGroupList };
}

interface Props {
  contractId: string;
  onChange: (seatGroup: SeatGroup) => void;
  onSubmitAdminModal?: (
    updatedSeatGroupList: List<SeatGroup | Contingent>,
    newSeatGroupList: List<SeatGroup | Contingent>,
    deletedSeatGroupList: List<SeatGroup | Contingent>
  ) => void;
  label: string;
  labelClassName?: string;
  className?: string;
  icon?: MpdIconType;
}

export default function SeatGroupSelector({
  contractId,
  onChange,
  onSubmitAdminModal,
  label,
  labelClassName = 'ml2 bold',
  className = 'seatGroup-selector',
  icon,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { seatGroupList, fetchSeatGroupList } =
    useFetchSeatGroupList(contractId);

  useEffect(() => {
    fetchSeatGroupList();
  }, [fetchSeatGroupList]);

  const [isOpenedSeatGroupAdminModal, setIsOpenedSeatGroupAdminModal] =
    useState(false);

  const submitSeatGroupLists = async (
    updatedSeatGroupList: List<SeatGroup | Contingent>,
    newSeatGroupList: List<SeatGroup | Contingent>,
    deletedSeatGroupList: List<SeatGroup | Contingent>
  ) => {
    setIsOpenedSeatGroupAdminModal(false);

    fetchSeatGroupList();

    if (onSubmitAdminModal) {
      onSubmitAdminModal(
        updatedSeatGroupList,
        newSeatGroupList,
        deletedSeatGroupList
      );
    }
  };

  const iconProps = icon ? { icon } : { noIcon: true };

  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot && isOpenedSeatGroupAdminModal !== false) {
    throw new Error('Unable to find element with id "modal-root"');
  }

  return (
    <>
      <MpdPopover
        displayOnLeft
        action={
          <button
            type="button"
            className="mpd-btn mpd-btn--secondary"
            onClick={() => setIsOpenedSeatGroupAdminModal(true)}
          >
            {t('seat_group.selector.title')}
          </button>
        }
        label={label}
        labelClassName={labelClassName}
        className={className}
        disabled={!seatGroupList}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...iconProps}
      >
        <div className="seatGroup-selector__container">
          {seatGroupList && seatGroupList.size === 0 && (
            <span>{t('no_category')}</span>
          )}
          {seatGroupList &&
            seatGroupList.map(
              (seatGroup: SeatGroup, tabIndex: number): JSX.Element => (
                <SeatGroupSelectorRow
                  key={seatGroup['@id']}
                  // @ts-expect-error conflict between SeatGroup and SeatGroupType but they are the same
                  seatGroup={seatGroup}
                  onClick={() => onChange(seatGroup)}
                  tabIndex={tabIndex}
                />
              )
            )}
        </div>
      </MpdPopover>

      {modalRoot &&
        isOpenedSeatGroupAdminModal &&
        seatGroupList &&
        createPortal(
          <ColoredItemAdminModal
            contractId={contractId}
            dataType="SEAT_GROUP"
            coloredItemList={seatGroupList}
            onCancel={() => setIsOpenedSeatGroupAdminModal(false)}
            onChange={submitSeatGroupLists}
          />,
          modalRoot
        )}
    </>
  );
}
