import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import SubscriptionMaturityClient from '../client/SubscriptionMaturityClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import NetworkEntity from './NetworkEntity';
import Subscription from './Subscription';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SubscriptionMaturityType = BaseEntity<'SubscriptionMaturity'> & {
  createdAt: null | Moment;
  updatedAt: null | Moment;
  currency: null | string;
  amount: null | number;
  status: null | null;
  dueDate: null | Moment;
  subscription: null | EntityRelation<Subscription>;
};

class SubscriptionMaturity extends NetworkEntity<SubscriptionMaturityType>({
  '@id': null,
  '@type': 'SubscriptionMaturity',
  createdAt: null,
  updatedAt: null,
  currency: null,
  amount: null,
  status: null,
  dueDate: null,
  subscription: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SubscriptionMaturityType> = {
      '@id': null,
      '@type': 'SubscriptionMaturity',
    }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, [
      'subscription',
      'wallet',
      'contract',
      'timezone',
    ]);

    data.createdAt = parseDate(data.createdAt, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);
    data.dueDate = parseDate(data.dueDate, tz);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/subscription_maturities/', '') || '';
  }
}

SubscriptionMaturity.classMetadata = new ClassMetadata(
  'subscriptionMaturity',
  'subscription_maturities',
  /** @ts-expect-error -- method signature are incompatible */
  SubscriptionMaturityClient
);
SubscriptionMaturity.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('dueDate', 'dueDate', 'datetime'),
  new Attribute('currency'),
  new Attribute('status'),
  new Attribute('amount', 'amount', 'integer'),
]);
SubscriptionMaturity.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'subscription', 'subscription'),
]);

export default SubscriptionMaturity;
