import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderContingentMappingClient extends AbstractClient<
  TSMetadata['providerContingentMapping']
> {
  getPathBase(): string {
    return '/v1/provider_contingent_mappings';
  }
}

export default ProviderContingentMappingClient;
