import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import ContingentClient from '../client/ContingentClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import type { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type ContingentType = HidableType &
  BaseEntity<'Contingent'> & {
    contract: null | EntityRelation<Contract>;
    name: null | string;
    sanitizedName: null | string;
    color: null | string;
  };

class Contingent extends NetworkEntity<ContingentType>({
  '@id': null,
  '@type': 'Contingent',
  contract: null,
  name: null,
  sanitizedName: null,
  color: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ContingentType> = {
      '@id': null,
      '@type': 'Contingent',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/contingents/', '') || '';
  }
}

Contingent.classMetadata = new ClassMetadata(
  'contingent',
  'contingents',
  /** @ts-expect-error -- method signature are incompatible */
  ContingentClient
);
Contingent.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('sanitizedName'),
  new Attribute('color'),
]);
Contingent.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Contingent;
