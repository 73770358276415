import clone from 'clone';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import { mapEntityRelationShips } from '../entityFactory';
import Coupon from './Coupon';
import NetworkEntity from './NetworkEntity';
import OrderItem from './OrderItem';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type OrderItemCashCouponType = BaseEntity<'OrderItemCashCoupon'> & {
  id: number | null;
  orderItem: EntityRelation<OrderItem> | null;
  cashCoupon: EntityRelation<Coupon> | null;
  amount: number | null;
};

class OrderItemCashCoupon extends NetworkEntity<OrderItemCashCouponType>({
  '@id': null,
  '@type': 'OrderItemCashCoupon',
  id: null,
  orderItem: null,
  cashCoupon: null,
  amount: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrderItemCashCouponType> = {
      '@id': null,
      '@type': 'OrderItemCashCoupon',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/order_item_cash_coupons/', '') || '';
  }
}

OrderItemCashCoupon.classMetadata = new ClassMetadata(
  'orderItemCashCoupon',
  'order_item_cash_coupons',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);

OrderItemCashCoupon.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('id', 'id', 'number'),
  new Attribute('amount', 'amount', 'number'),
]);

OrderItemCashCoupon.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_ONE, 'orderItem', 'orderItem'),
  new Relation(Relation.ONE_TO_ONE, 'coupon', 'cashCoupon'),
]);

export default OrderItemCashCoupon;
