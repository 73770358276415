import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 29 26" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-454 -975)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="equipe"
            d="M378 161.333v-4h2.667v4h4V164h-4v4H378v-4h-4v-2.667zm-18.667 16h16v-4.571c0-1.894-1.79-3.429-4-3.429h-8c-2.209 0-4 1.535-4 3.429zm4-10.666h8c3.682 0 6.667 2.713 6.667 6.06V180h-21.333v-7.273c0-3.347 2.984-6.06 6.666-6.06m4-9.334a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334m0-2.666a5.333 5.333 0 1 1 0 10.666 5.333 5.333 0 0 1 0-10.666"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgTeam;
