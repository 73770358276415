import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 16" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/telecharger" fillRule="nonzero" transform="translate(-8 -6)">
        <g id="Group" transform="translate(8 6)">
          <path
            id="Combined-Shape"
            d="M11.977 8H14v8H0V8h2.004v6.005h9.973zm-1.968 0-2.995 3.265L4.018 8h1.963V0h2.018v8z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgDownload;
