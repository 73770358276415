import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ContractAddendumClient extends AbstractClient<
  TSMetadata['contractAddendum']
> {
  getPathBase(): string {
    return '/v1/contract_addendums';
  }
}

export default ContractAddendumClient;
