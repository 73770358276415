import clone from 'clone';
import { List, Map } from 'immutable';
import { ClassMetadata } from 'rest-client-sdk';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import AvailableSeat from './AvailableSeat';
import type CartItem from './CartItem';
import NetworkEntity from './NetworkEntity';
import StockContingent from './StockContingent';
import TicketPrice from './TicketPrice';
import { getEntityId } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

// "@id" and "@type" not defined for this entity, but too much TypeScript brainfuck to avoid only this class
export type CartItemWithOfferType = BaseEntity<'CartItemWithOffer'> & {
  name: null | string;
  quantity: null | number;
  facialValue: null | number;
  facialValueWithoutOffer: null | number;
  offer: null | Map<string, unknown>;
  ticketingTitle: null | string;
  ticketingTimezone: null | string;
  date: null | string; // should probably be date instance and converted in constructor
  endDate: null | string; // should probably be date instance and converted in constructor
  cartItem: null | EntityRelation<CartItem>;
  availableSeatList: null | List<EntityRelation<AvailableSeat>>;
  clusterId: null | string;
  applicationId: null | string;
  groupKey: null | string;
  offerRuleUseApplicationIdQuantityMap: List<string>;
  buyable: null | boolean;
  valid: null | boolean;
  ticketPrice: null | EntityRelation<TicketPrice>;
  customerPaidAmount: null | number;
};

class CartItemWithOffer extends NetworkEntity<CartItemWithOfferType>({
  '@id': null,
  '@type': 'CartItemWithOffer',
  name: null,
  quantity: null,
  facialValue: null,
  facialValueWithoutOffer: null,
  offer: null,
  ticketingTitle: null,
  ticketingTimezone: null,
  date: null,
  endDate: null,
  cartItem: null,
  availableSeatList: null,
  clusterId: null,
  applicationId: null,
  groupKey: null,
  offerRuleUseApplicationIdQuantityMap: List<string>(),
  buyable: null,
  valid: null,
  ticketPrice: null,
  customerPaidAmount: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CartItemWithOfferType> = {
      '@id': null,
      '@type': 'CartItemWithOffer',
    }
  ) {
    const data = clone(val);
    data.offer = val.offer && Map(val.offer);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getKey(): string {
    const offerName = this.getIn(['offer', 'name']) || '';
    const cartItemId = getEntityId(this.get('cartItem'));
    const stockContingent = this.getIn([
      'cartItem',
      'stockContingent',
    ]) as EntityRelation<StockContingent>;
    const stockContingentId = stockContingent
      ? getEntityId(stockContingent)
      : '';
    const ticketPrice = this.get('ticketPrice');
    const clusterId = this.get('clusterId') || '';
    const groupKey = this.get('groupKey') || '';
    const buyable = this.get('buyable');
    let buyableKey = '';
    if (buyable !== null) {
      buyableKey = buyable ? '1' : '0';
    }

    return `${ticketPrice || cartItemId}-${
      this.facialValue
    }-${groupKey}-${offerName}-${clusterId}-${buyableKey}-${stockContingentId}`;
  }
}

export default CartItemWithOffer;
