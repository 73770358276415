import clone from 'clone';
import { List } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TransactionGroupClient from '../client/TransactionGroupClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Contract from './Contract';
import Deposit from './Deposit';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import SellingDevice from './SellingDevice';
import Transaction from './Transaction';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TransactionGroupType = BaseEntity<'TransactionGroup'> & {
  type: null | string;
  contract: null | EntityRelation<Contract>;
  transactionList: null | List<EntityRelation<Transaction>>;
  clientTransactionCredit: null | number;
  clientTransactionDebit: null | number;
  clientTransactionBalance: null | number;
  createdAt: null | Moment;
  order: null | EntityRelation<Order>;
  deposit: null | EntityRelation<Deposit>;
  sellingDevice: null | EntityRelation<SellingDevice>;
};

class TransactionGroup extends NetworkEntity<TransactionGroupType>({
  '@id': null,
  '@type': 'TransactionGroup',
  type: null,
  contract: null,
  transactionList: null,
  clientTransactionCredit: null,
  clientTransactionDebit: null,
  clientTransactionBalance: null,
  createdAt: null,
  order: null,
  deposit: null,
  sellingDevice: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TransactionGroupType> = {
      '@id': null,
      '@type': 'TransactionGroup',
    }
  ) {
    const data = clone(val);

    const tz = extractTimezone(data, ['contract', 'timezone']);

    data.createdAt = parseDate(data.createdAt, tz);

    super(data);
    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/transaction_groups/', '') || '';
  }

  /**
   * @deprecated use createdAt instead
   */
  getLocalCreatedAt(): null | Moment {
    if (!this.createdAt) {
      return null;
    }

    const tz = this.getIn(['contract', 'timezone']);
    if (!tz) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- moment timezone issue */
    return this.createdAt.clone().tz(tz);
  }
}

TransactionGroup.classMetadata = new ClassMetadata(
  'transactionGroup',
  'transaction_groups',
  /** @ts-expect-error -- method signature are incompatible */
  TransactionGroupClient
);
TransactionGroup.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute(
    'clientTransactionCredit',
    'clientTransactionCredit',
    'integer'
  ),
  new Attribute('clientTransactionDebit', 'clientTransactionDebit', 'integer'),
  new Attribute(
    'clientTransactionBalance',
    'clientTransactionBalance',
    'integer'
  ),
]);

TransactionGroup.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'deposit', 'deposit'),
  new Relation(Relation.ONE_TO_MANY, 'transaction', 'transactionList'),
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
]);

export default TransactionGroup;
