import clone from 'clone';
import { List } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import InvoiceClient from '../client/InvoiceClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import InvoiceLine from './InvoiceLine';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import { BaseEntity, PartialEntity } from '.';

export enum INVOICE_TYPE {
  INITIAL_PAYMENT = 'initial_payment',
  REFUND_CORRECTION = 'refund_correction',
  TAX_CHANGE_CANCEL_PAYMENT = 'tax_change_cancel_payment',
  TAX_CHANGE_CANCEL_REFUND = 'tax_change_cancel_refund',
  INITIAL_PAYOUT = 'initial_payout',
  TAX_CHANGE_CANCEL_PAYOUT = 'tax_change_cancel_payout',
  CANCEL_PAYMENT = 'cancel_payment',
  CANCEL_REFUND = 'cancel_refund',
  SPLIT_CANCEL_PAYMENT = 'split_cancel_payment',
  SPLIT_CANCEL_REFUND = 'split_cancel_refund',
}

export type InvoiceType = BaseEntity<'Invoice'> & {
  invoiceLineList: null | List<InvoiceLine>;
  order: null | Order;
  customerName: null | string;
  customerAddress: null | string;
  customerAdditionalInfo: null | string;
  customerVatNumber: null | string;
  identifier: null | string;
  createdAt: null | undefined | Moment;
  cancelled: null | boolean;
  groupLabel: null | string;
  type: null | INVOICE_TYPE;
};
class Invoice extends NetworkEntity<InvoiceType>({
  '@id': null,
  '@type': 'Invoice',
  invoiceLineList: List<InvoiceLine>(),
  order: null,
  customerName: null,
  customerAddress: null,
  customerAdditionalInfo: null,
  customerVatNumber: null,
  identifier: null,
  createdAt: null,
  cancelled: null,
  groupLabel: null,
  type: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<InvoiceType> = { '@id': null, '@type': 'Invoice' }
  ) {
    const data = clone(val);

    data.createdAt = parseDate(data.createdAt, null);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/invoices/', '') || '';
  }

  isCredit(): boolean {
    return (
      this.get('type') === INVOICE_TYPE.REFUND_CORRECTION ||
      this.get('type') === INVOICE_TYPE.TAX_CHANGE_CANCEL_PAYMENT ||
      this.get('type') === INVOICE_TYPE.CANCEL_PAYMENT
    );
  }
}

/** @ts-expect-error -- method signature are incompatible */
Invoice.classMetadata = new ClassMetadata('invoice', 'invoices', InvoiceClient);
Invoice.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('customerName'),
  new Attribute('customerAddress'),
  new Attribute('customerVatNumber'),
  new Attribute('customerAdditionalInfo'),
  new Attribute('identifier'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('groupLabel'),
  new Attribute('type'),
  new Attribute('cancelled', 'cancelled', 'boolean'),
]);
Invoice.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.ONE_TO_MANY, 'invoiceLine', 'invoiceLineList'),
]);

export default Invoice;
