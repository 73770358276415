import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import LogicalSeatConfigClient from '../client/LogicalSeatConfigClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import AvailableSeatModel from './AvailableSeatModel';
import Contingent from './Contingent';
import Contract from './Contract';
import EventDate from './EventDate';
import { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import SeatConfig from './SeatConfig';
import SeatGroup from './SeatGroup';
import StockContingent from './StockContingent';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type FreeOrNumberedSeating = {
  isNumberedSeating: null | boolean;
};

export type LogicalSeatConfigType = FreeOrNumberedSeating &
  HidableType &
  BaseEntity<'LogicalSeatConfig'> & {
    defaultSeatGroup: null | EntityRelation<SeatGroup>;
    name: null | string;
    totalStock: null | number;
    unlimitedStock: null | boolean;
    seatConfig: null | EntityRelation<SeatConfig>;
    contract: null | EntityRelation<Contract>;
    eventDateList: null | List<EntityRelation<EventDate>>;
    availableSeatModelList: null | List<EntityRelation<AvailableSeatModel>>;
    stockContingentList: null | List<EntityRelation<StockContingent>>;
    contingentList: null | List<EntityRelation<Contingent>>;
    seatGroupList: null | List<EntityRelation<SeatGroup>>;
  };

class LogicalSeatConfig extends NetworkEntity<LogicalSeatConfigType>({
  '@id': null,
  '@type': 'LogicalSeatConfig',
  defaultSeatGroup: null,
  name: null,
  totalStock: null,
  unlimitedStock: null,
  seatConfig: null,
  contract: null,
  eventDateList: null,
  availableSeatModelList: null,
  stockContingentList: null,
  contingentList: null,
  seatGroupList: null,
  isNumberedSeating: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<LogicalSeatConfigType> = {
      '@id': null,
      '@type': 'LogicalSeatConfig',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/logical_seat_configs/', '') || '';
  }

  isFreeSeating(): boolean {
    return !this.isNumberedSeating;
  }
}

LogicalSeatConfig.classMetadata = new ClassMetadata(
  'logicalSeatConfig',
  'logical_seat_configs',
  /** @ts-expect-error -- method signature are incompatible */
  LogicalSeatConfigClient
);
LogicalSeatConfig.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('totalStock', 'totalStock'),
  new Attribute('unlimitedStock', 'unlimitedStock', 'boolean'),
  new Attribute('isNumberedSeating', 'isNumberedSeating', 'boolean'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);
LogicalSeatConfig.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seatGroup', 'defaultSeatGroup'),
  new Relation(Relation.MANY_TO_ONE, 'seatConfig', 'seatConfig'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(
    Relation.ONE_TO_MANY,
    'availableSeatModel',
    'availableSeatModelList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'eventDate', 'eventDateList'),
  new Relation(Relation.ONE_TO_MANY, 'stockContingent', 'stockContingentList'),
  new Relation(Relation.ONE_TO_MANY, 'contingent', 'contingentList'),
  new Relation(Relation.ONE_TO_MANY, 'seatGroup', 'seatGroupList'),
]);

export default LogicalSeatConfig;
