// App
export const SET_EVENTDATE_DATA = '@@mapado-seating/SET_EVENTDATE_DATA';

export const SET_AVAILABLE_SEAT_LIST =
  '@@mapado-seating/SET_AVAILABLE_SEAT_LIST';
export const SET_AVAILABLE_SEAT_MODEL_LIST =
  '@@mapado-seating/SET_AVAILABLE_SEAT_MODEL_LIST';

export const INIT_CLEANUP_STATE = '@@mapado-seating/INIT_CLEANUP_STATE';

// Booking
export const REQUEST_BOOKING_COLLECTION =
  '@@mapado-seating/REQUEST_BOOKING_COLLECTION';
export const RECEIVE_BOOKING_COLLECTION =
  '@@mapado-seating/RECEIVE_BOOKING_COLLECTION';
export const RECEIVE_BOOKING = '@@mapado-seating/RECEIVE_BOOKING';
export const SET_BOOKING_STATUS = '@@mapado-seating/SET_BOOKING_STATUS';
// Editor
export const ADD_SEAT_CONFIG_BLOCK = '@@mapado-seating/ADD_SEAT_CONFIG_BLOCK';
export const REMOVE_SEAT_CONFIG_BLOCK =
  '@@mapado-seating/REMOVE_SEAT_CONFIG_BLOCK';
export const UPDATE_SEAT_CONFIG_BLOCK =
  '@@mapado-seating/UPDATE_SEAT_CONFIG_BLOCK';

export const UPDATE_SEAT_POSITION = '@@mapado-seating/UPDATE_SEAT_POSITION';
export const UPDATE_SEAT_LIST = '@@mapado-seating/UPDATE_SEAT_LIST';
export const SELECT_SEAT_LIST = '@@mapado-seating/SELECT_SEAT_LIST';

// Seat
export const SELECT_SEAT = '@@mapado-seating/SELECT_SEAT';
export const SET_AVAILABLE_SEAT_STATUS =
  '@@mapado-seating/SET_AVAILABLE_SEAT_STATUS';
export const SET_AVAILABLE_SEAT_LIST_AVAILABLE =
  '@@mapado-seating/SET_AVAILABLE_SEAT_LIST_AVAILABLE';
export const MOVE_SEAT_LIST_SUCCESS = '@@mapado-seating/MOVE_SEAT_LIST_SUCCESS';
export const MOVE_SEAT_LIST_FAILURE = '@@mapado-seating/MOVE_SEAT_LIST_FAILURE';
export const REQUEST_MOVE_SEAT_LIST = '@@mapado-seating/REQUEST_MOVE_SEAT_LIST';
export const FOCUS_BOOKING = '@@mapado-seating/FOCUS_BOOKING';
export const UNFOCUS_BOOKING = '@@mapado-seating/UNFOCUS_BOOKING';
export const RESET_BOOKING = '@@mapado-seating/RESET_BOOKING';
export const SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST =
  '@@mapado-seating/SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST';
export const SET_STOCK_CONTINGENT_LIST =
  '@@mapado-seating/SET_STOCK_CONTINGENT_LIST';

export const REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST =
  '@@mapado-seating/REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST';
export const UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS =
  '@@mapado-seating/UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS';
export const UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE =
  '@@mapado-seating/UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE';

// SeatConfig
export const SET_SEATCONFIG_DATA = '@@mapado-seating/SET_SEATCONFIG_DATA';

// LogicalSeatCongig
export const SET_LOGICALSEATCONFIG_DATA =
  '@@mapado-seating/SET_LOGICALSEATCONFIG_DATA';
// LogicalSeatCongig

export const ADD_SEAT_GROUP = '@@mapado-seating/ADD_SEAT_GROUP';

export const ADD_CONTINGENT = '@@mapado-seating/ADD_CONTINGENT';

export const REMOVE_CONTINGENT = '@@mapado-seating/REMOVE_CONTINGENT';

export const UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP =
  '@@mapado-seating/UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP';

export const SET_CONTRACT = '@@mapado-seating/SET_CONTRACT';
