import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import SellingDevice from './SellingDevice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TicketingSellingDeviceType = BaseEntity<'TicketingSellingDevice'> & {
  '@id': null | string;
  '@type': 'TicketingSellingDevice';
  minFacialValue: null | number;
  maxFacialValue: null | number;
  hasTicketPriceOnSale: null | boolean;
  hasTicketPriceEnabled: null | boolean;
  sellingDevice: null | EntityRelation<SellingDevice>;
};
class TicketingSellingDevice extends NetworkEntity<TicketingSellingDeviceType>({
  '@id': null,
  '@type': 'TicketingSellingDevice',
  minFacialValue: null,
  maxFacialValue: null,
  hasTicketPriceOnSale: false,
  hasTicketPriceEnabled: null,
  sellingDevice: new SellingDevice(),
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketingSellingDeviceType> = {
      '@id': null,
      '@type': 'TicketingSellingDevice',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticketing_selling_devices/', '') || '';
  }
}

TicketingSellingDevice.classMetadata = new ClassMetadata(
  'ticketingSellingDevite',
  'ticketing_selling_devices',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
TicketingSellingDevice.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('minFacialValue', 'minFacialValue', 'integer'),
  new Attribute('maxFacialValue', 'maxFacialValue', 'integer'),
  new Attribute('hasTicketPriceOnSale', 'hasTicketPriceOnSale', 'boolean'),
  new Attribute('hasTicketPriceEnabled', 'hasTicketPriceEnabled', 'boolean'),
]);
TicketingSellingDevice.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
]);

export default TicketingSellingDevice;
