import { getEntityId, TicketingSdkContext } from '@mapado/js-component';
import { List } from 'immutable';
import { SeatGroup } from 'mapado-ticketing-js-sdk';
import React, { useCallback } from 'react';
import { addSeatGroup } from '../../../../actions/SeatActions';
import { SeatGroupType } from '../../../../propTypes';
import TicketingSdkInstance from '../../../../TicketingSdkInstance';
import {
  getJsEntityId,
  useSeatGroupSelector,
} from '../../../../utils/selectors';
import SeatGroupSelector from './SeatGroupSelector';
import { useSeatingDispatch } from '../../../../reducers/typedFunctions';
import { useTranslation } from '../../../../i18n';

function SeatGroupSidebarActions({
  onUpdateSeatGroups,
  contractId,
}: {
  onUpdateSeatGroups?: () => void;
  contractId: string;
}) {
  const dispatch = useSeatingDispatch();
  const { t } = useTranslation();
  const seatGroupList: List<SeatGroupType> | null = useSeatGroupSelector();

  const handleAddSeatGroup = useCallback(
    (seatGroup: SeatGroup) => {
      if (
        seatGroupList &&
        seatGroupList.find((sg) => getJsEntityId(sg) === getEntityId(seatGroup))
      ) {
        return;
      }

      // @ts-expect-error -- custom serializer typing error
      dispatch(addSeatGroup(seatGroup.toJS()));
    },
    [dispatch, seatGroupList]
  );

  return (
    <TicketingSdkContext.Provider
      value={TicketingSdkInstance.getDefaultSerializerSdk()}
    >
      <SeatGroupSelector
        onSubmitAdminModal={onUpdateSeatGroups}
        onChange={handleAddSeatGroup}
        labelClassName="mpd-btn mpd-btn--primary mpd-color-white"
        className="stock-contingent-button"
        contractId={contractId}
        label={t('seat_group.add_new_btn')}
      />
    </TicketingSdkContext.Provider>
  );
}

export default SeatGroupSidebarActions;
