import { List } from 'immutable';
import type { Moment } from 'moment';
import URI from 'urijs';
import MomentInstance from '../MomentInstance';
import { EntityRelation } from '../entity';
import EventDate from '../entity/EventDate';
import PagedCollection from '../entity/PagedCollection';
import Ticket from '../entity/Ticket';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

// taken (and simplified) from moment definition
type MomentInput = Moment | Date | string | number | null;

class TicketClient extends AbstractClient<TSMetadata['ticket']> {
  getPathBase(): string {
    return '/v1/tickets';
  }

  findValidOrUpdatedSince(
    fields: FieldsParameter,
    params: Record<string, unknown>,
    updatedSince: MomentInput = null
  ): Promise<PagedCollection<Ticket>> {
    const updatedParams = params;
    if (updatedSince) {
      updatedParams.updatedSince = MomentInstance.moment
        .utc(updatedSince)
        .toISOString();
    } else {
      updatedParams.isValid = true;
    }

    return this.findBy(updatedParams, fields);
  }

  findValidForToday(
    fields: FieldsParameter,
    updatedSince: MomentInput = null,
    statusList: null | string | Array<string> = null,
    ticketPriceTypeList: null | string | Array<string> = null,
    itemsPerPage: null | number = null
  ): Promise<PagedCollection<Ticket>> {
    const params: Record<string, unknown> = {
      eventStartDate: 'auto',
    };

    if (statusList) {
      params.status = Array.isArray(statusList)
        ? statusList.join(',')
        : statusList;
    }

    if (ticketPriceTypeList) {
      params.ticketPriceType = Array.isArray(ticketPriceTypeList)
        ? ticketPriceTypeList.join(',')
        : ticketPriceTypeList;
    }

    if (itemsPerPage !== null) {
      params.itemsPerPage = itemsPerPage;
    }

    return this.findValidOrUpdatedSince(fields, params, updatedSince);
  }

  findValidByEventDateList(
    eventDateList:
      | Array<EntityRelation<EventDate>>
      | List<EntityRelation<EventDate>>,
    fields: FieldsParameter,
    updatedSince: MomentInput = null,
    statusList: null | string | Array<string> = null,
    ticketPriceTypeList: null | string | Array<string> = null,
    itemsPerPage: null | number = null
  ): Promise<PagedCollection<Ticket>> {
    const eventDateIdList = eventDateList
      .map((eventDate: EntityRelation<EventDate>) =>
        typeof eventDate === 'string' ? eventDate : eventDate.get('@id')
      )
      .join(',');

    const params: Record<string, unknown> = { eventDate: eventDateIdList };

    if (statusList) {
      params.status = Array.isArray(statusList)
        ? statusList.join(',')
        : statusList;
    }

    if (ticketPriceTypeList) {
      params.ticketPriceType = Array.isArray(ticketPriceTypeList)
        ? ticketPriceTypeList.join(',')
        : ticketPriceTypeList;
    }

    if (itemsPerPage !== null) {
      params.itemsPerPage = itemsPerPage;
    }

    return this.findValidOrUpdatedSince(fields, params, updatedSince);
  }

  findByEventDateList(
    eventDateList:
      | Array<EntityRelation<EventDate>>
      | List<EntityRelation<EventDate>>,
    fields: FieldsParameter,
    statusList: null | string | Array<string> = null,
    ticketPriceTypeList: null | string | Array<string> = null,
    itemsPerPage: null | number = null
  ): Promise<PagedCollection<Ticket>> {
    const eventDateIdList = eventDateList
      .map((eventDate: EntityRelation<EventDate>) =>
        typeof eventDate === 'string' ? eventDate : eventDate.get('@id')
      )
      .join(',');

    const params: Record<string, unknown> = { eventDate: eventDateIdList };

    if (statusList) {
      params.status = Array.isArray(statusList)
        ? statusList.join(',')
        : statusList;
    }

    if (ticketPriceTypeList) {
      params.ticketPriceType = Array.isArray(ticketPriceTypeList)
        ? ticketPriceTypeList.join(',')
        : ticketPriceTypeList;
    }

    if (itemsPerPage !== null) {
      params.itemsPerPage = itemsPerPage;
    }

    return this.findBy(params, fields);
  }

  findUrlForTicketFile(id: number, format: string): URI {
    return this.makeUri(`${this.getPathBase()}/${id}.${format}`);
  }

  updateLastScannedDate(
    ticket: Ticket,
    fields: FieldsParameter
  ): Promise<Response> {
    const id = ticket.get('@id');
    if (!id) {
      throw new Error('Unable to get last scan date for a ticket without id');
    }

    const url = new URI(id);
    url.addSearch({
      fields: getFieldsString(fields),
    });

    const body = JSON.stringify({
      lastScannedDate: ticket.lastScannedDate,
    });

    return this.authorizedFetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  bulkUpdate(
    tickets: List<Ticket>,
    fields: FieldsParameter,
    id: string,
    typeId: 'downloadId' | 'payId'
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    if (typeId === 'downloadId') {
      uri.addSearch({ downloadId: id });
    } else {
      uri.addSearch({ payId: id });
    }

    const body = JSON.stringify({
      'hydra:member': tickets.toJS(),
    });

    return this.authorizedFetch(uri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  unscan(
    ticketId: string,
    fields: FieldsParameter,
    queryParams: Record<string, string> = {}
  ): Promise<Ticket> {
    const body = JSON.stringify({});

    const url = new URI(`${ticketId}/unscan`);
    url.addSearch({
      fields: getFieldsString(fields),
    });

    Object.entries(queryParams).forEach(([key, value]) => {
      url.addSearch({ [key]: value });
    });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      }),
      'item'
    );
  }
}

export default TicketClient;
