import React from 'react';
import { MpdIcon } from '@mapado/makeup';
import ZoomButton from './ZoomButton';

function Controls({ zoomIn, zoomOut }: ControlsProps): React.ReactElement {
  return (
    <div className="mpd-btn-group">
      <ZoomButton onZoom={zoomIn} id="zoomIn-button" type="zoomIn">
        <MpdIcon icon="plus-stroke" width="20" />
      </ZoomButton>
      <ZoomButton onZoom={zoomOut} id="zoomOut-button" type="zoomOut">
        <MpdIcon icon="minus-stroke" width="20" />
      </ZoomButton>
    </div>
  );
}

type ControlsProps = {
  zoomIn: () => void;
  zoomOut: () => void;
};

export default Controls;
