import React, { useEffect } from 'react';
import { List, Set } from 'immutable';
import { usePrevious } from '@mapado/js-component';
import '../../../../styles/components/Sidebar/StockContingentSidebar.scss';
import { AVAILABLE_SEAT_STATUS_DISMISSED } from 'mapado-ticketing-js-sdk';
import { SelectBatchOfSeats } from '../../../../utils/drawers/FreeHandDrawSeatSelector';
import { AvailableSeatModelType } from '../../../../propTypes';
import { getJsEntityId } from '../../../../utils/selectors';
import { useViewContext, View } from '../../../../contexts/ViewContext';
import SeatGroupList from '../../SeatGroupList';
import { useSelectedSeatGroupContext } from '../../../../contexts/SelectedSeatGroupContext';

function SeatGroupSidebarContent({
  onSelectedIdListChange,
  onUnselectSeatGroup,
  selectBatchOfSeats,
  updateAvailableSeatModelListSeatGroup,
  availableSeatModelList,
  selectedSeatIdListHash,
}: SeatGroupPaneProps) {
  const { selectedSeatGroup } = useSelectedSeatGroupContext();

  const { onSelectView } = useViewContext();

  // reset parts of state on selecting seatgroup
  useEffect(() => {
    selectBatchOfSeats(Set());

    if (!selectedSeatGroup) {
      onUnselectSeatGroup();
    }
  }, [onUnselectSeatGroup, selectedSeatGroup, selectBatchOfSeats]);

  const selectedSeatGroupId = selectedSeatGroup?.['@id'];
  const prevSelectedSeatGroupId = usePrevious(selectedSeatGroup?.['@id']);

  // update availableSeatModelList when selectedSeatIdSet changes
  useEffect(() => {
    async function doUpdateSeatGroupSeatList(seatgroupId: string) {
      await updateAvailableSeatModelListSeatGroup(seatgroupId);
      onSelectedIdListChange();
      onSelectView(View.SeatGroup); // in case we are NOT in the seatgroup view
      selectBatchOfSeats(Set());
    }

    if (
      selectedSeatIdListHash &&
      selectedSeatGroupId &&
      prevSelectedSeatGroupId === selectedSeatGroupId
    ) {
      doUpdateSeatGroupSeatList(selectedSeatGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatIdListHash]);

  const filteredSeatList = availableSeatModelList.filter(
    (avs) => avs.status !== AVAILABLE_SEAT_STATUS_DISMISSED
  );

  const availableSeatModelListBySeatGroup = filteredSeatList.groupBy((avsm) =>
    avsm.seatGroup ? getJsEntityId(avsm.seatGroup) : 'noSeatGroup'
  );

  return (
    <SeatGroupList
      getNbSeats={(seatGroup) =>
        availableSeatModelListBySeatGroup.get(seatGroup['@id'])?.count() ?? 0
      }
    />
  );
}

export type OwnProps = {
  onSelectedIdListChange: () => void;
  onUnselectSeatGroup: () => void;
};

export type StateProps = {
  selectedSeatIdListHash: string | null;
  availableSeatModelList: List<AvailableSeatModelType>;
};

export type DispatchProps = {
  selectBatchOfSeats: SelectBatchOfSeats;
  updateAvailableSeatModelListSeatGroup: (seatgroupId: string) => void;
};

type SeatGroupPaneProps = OwnProps & StateProps & DispatchProps;

export default SeatGroupSidebarContent;
