import { MpdIcon } from '@mapado/makeup';
import React from 'react';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $primaryBlue } from '@mapado/makeup/variables';
import cn from 'classnames';
import { SeatGroupType } from '../../../propTypes';
import '../../../styles/components/SeatGroupRow.scss';
import { useTranslation } from '../../../i18n';

type Props = {
  seatGroup: SeatGroupType;
  onSelect?: (seatgroup: SeatGroupType) => void;
  isSelected?: boolean;
  nbSeats: number;
};

function SeatGroupRow({ seatGroup, onSelect, isSelected, nbSeats }: Props) {
  const { t } = useTranslation();

  const isSelectable = typeof onSelect === 'function';

  return (
    <div
      className={cn('seat-group-row', {
        'seat-group-row--selectable': isSelectable,
        'seat-group-row--selected': isSelectable && isSelected,
      })}
      onClick={() => isSelectable && onSelect(seatGroup)}
      aria-hidden="true"
    >
      <div className="seat-group-row__title" key={seatGroup['@id']}>
        <div className="seat-group-row__icon-label">
          <MpdIcon
            icon="seat-filled"
            width="14"
            height="14"
            className="ml1 mr2"
            color={seatGroup.seatColor || $primaryBlue}
          />
          <span className="bold">{seatGroup.label}</span>
        </div>

        {t('seating.nb_seats', {
          count: nbSeats,
        })}
      </div>
    </div>
  );
}

export default SeatGroupRow;
