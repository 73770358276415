import clone from 'clone';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import SeatGroup from './SeatGroup';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TicketPriceSeatGroupType = BaseEntity<'TicketPriceSeatGroup'> & {
  seatGroup: null | EntityRelation<SeatGroup>;
  ticketPrice: null | EntityRelation<TicketPrice>;
};

class TicketPriceSeatGroup extends NetworkEntity<TicketPriceSeatGroupType>({
  '@id': null,
  '@type': 'TicketPriceSeatGroup',
  seatGroup: new SeatGroup(),
  ticketPrice: new TicketPrice(),
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketPriceSeatGroupType> = {
      '@id': null,
      '@type': 'TicketPriceSeatGroup',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticket_price_seat_groups/', '') || '';
  }
}

TicketPriceSeatGroup.classMetadata = new ClassMetadata(
  'ticketPriceSeatGroup',
  'ticket_price_seat_groups',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
TicketPriceSeatGroup.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
]);
TicketPriceSeatGroup.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'seatGroup', 'seatGroup'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPrice', 'ticketPrice'),
]);

export default TicketPriceSeatGroup;
