import { useEffect, useState } from 'react';
import useBookingError from '../components/Main/useBookingError';

/**
 * This hook is a hack to reload the plan if there is a booking error.
 * This is used to reset the redux state, where a simple key SHOULD do the trick theroetically, but doesn't work
 * Please avoid using this hook, it's a hack
 *
 * @deprecated hack hook, please do not use
 *
 * @return {boolean} true if the component should be visible or not according to the reload key
 */
export default function useReloadOnBookingError(): boolean {
  const [reloadKey, setReloadKey] = useState(0);
  const memoizedBookingError = useBookingError();

  useEffect(() => {
    if (memoizedBookingError) {
      // force reload of all component to remove the local selected seats
      setReloadKey((prev) => prev + 1);

      setTimeout(() => {
        // force reload of all component to remove the local selected seats.
        // This is really weird, the `key` prop should do the same thing,
        // but without it, the redux state seems to be fucked up.
        setReloadKey((prev) => prev + 1);
      }, 100);
    }
  }, [memoizedBookingError]);

  const shouldBeVisible = reloadKey % 2 !== 1;

  return shouldBeVisible;
}
