import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFavorite = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-876 -1044)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="favoris"
            d="m785.875 241.501 3.472-2.442a1.33 1.33 0 0 1 1.535 0l3.472 2.442-1.25-4.056a1.33 1.33 0 0 1 .474-1.46l3.396-2.547-4.245-.065a1.33 1.33 0 0 1-1.241-.902l-1.374-4.016-1.373 4.016a1.33 1.33 0 0 1-1.241.902l-4.245.065 3.396 2.547c.45.338.64.922.474 1.46zm-1.655 4.425c-1.04.731-2.416-.268-2.041-1.483l2.121-6.887-5.764-4.325c-1.017-.763-.491-2.38.78-2.4l7.205-.11 2.332-6.819c.411-1.203 2.112-1.203 2.523 0l2.332 6.82 7.205.11c1.271.019 1.797 1.636.78 2.399l-5.765 4.325 2.122 6.887c.374 1.215-1.002 2.214-2.041 1.483l-5.895-4.146z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFavorite;
