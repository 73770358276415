import { connect } from 'react-redux';
import { List } from 'immutable';
import SeatTooltip, {
  StateProps,
  SeatTooltipProps,
} from '../components/SeatTooltip';
import { RootState } from '../reducers';
import { SeatType } from '../propTypes';

type OwnProps = { seatId: number };

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { seatId } = ownProps;
  const seatList = state.seating.get('seatList');
  const seatLongId = `/v1/seats/${seatId}`;

  // TODO use seatByIdSelector here
  const seat: SeatType | undefined = (seatList || List()).find(
    (s) => s['@id'] === seatLongId
  );

  if (typeof seat === 'undefined') {
    throw new Error(`Unable to find seat with id ${seatId}`);
  }

  return {
    seat,
  };
};

export default connect<
  StateProps,
  Record<string, never>,
  SeatTooltipProps,
  RootState
>(mapStateToProps)(SeatTooltip);
