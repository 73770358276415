import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="UI-KIT_graphique" transform="translate(-734 -1042)">
        <g id="icon" transform="translate(98 821)">
          <path
            id="web"
            d="M660.317 237.667h-3.65v-5.334h3.65c.22.855.35 1.744.35 2.667s-.13 1.812-.35 2.667m-3.952 5.877c.176-.599.302-1.221.302-1.877v-1.334h2.561a10.7 10.7 0 0 1-2.863 3.211m-15.593-3.21h2.561v1.333c0 .656.124 1.278.302 1.877a10.7 10.7 0 0 1-2.863-3.21m2.863-13.878c-.178.599-.302 1.221-.302 1.877v1.334h-2.561a10.7 10.7 0 0 1 2.863-3.211m7.698 3.21v-5.087a3.99 3.99 0 0 1 2.667 3.754v1.334zm0 8H654v-5.333h-2.667zm0 7.755v-5.088H654v1.334a3.99 3.99 0 0 1-2.667 3.754m-2.666-20.842v5.088H646v-1.334a3.99 3.99 0 0 1 2.667-3.754M646 237.667h2.667v-5.334H646zM639.333 235c0-.923.13-1.812.35-2.667h3.65v5.334h-3.65c-.22-.855-.35-1.744-.35-2.667m6.667 5.333h2.667v5.088a3.99 3.99 0 0 1-2.667-3.754zm13.228-10.666h-2.561v-1.334c0-.656-.126-1.278-.302-1.877a10.7 10.7 0 0 1 2.863 3.21m-9.228-8c-7.364 0-13.333 5.97-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.97 13.333-13.333-5.97-13.333-13.333-13.333"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgWeb;
