export const CART_BOOKING_ERROR = '@@mapado-booking/CART_BOOKING_ERROR';
export const DISMISS_CART_BOOKING_ERROR =
  '@@mapado-booking/DISMISS_CART_BOOKING_ERROR';
export const CART_RECEIVED = '@@mapado-booking/CART_RECEIVED';
export const FETCH_CART_UPDATE = '@@mapado-booking/FETCH_CART_UPDATE';
export const SET_TICKET_PRICE_QUANTITY =
  '@@mapado-booking/SET_TICKET_PRICE_QUANTITY';
export const SET_CUSTOMER_FOR_CART = '@@mapado-booking/SET_CUSTOMER_FOR_CART';
export const RESET_PENDING_TICKET_PRICE_QUANTITIES =
  '@@mapado-booking/RESET_PENDING_TICKET_PRICE_QUANTITIES';
export const SET_CART_ID = '@@mapado-booking/SET_CART_ID';
export const DISCARD_CART = '@@mapado-booking/DISCARD_CART';
export const UPDATE_COMMENT = '@@mapado-booking/UPDATE_COMMENT';
export const FETCH_CART_STARTED = '@@mapado-booking/FETCH_CART_STARTED';
export const FETCH_CART_ENDED = '@@mapado-booking/FETCH_CART_ENDED';
export const SET_HAS_CART_ID_IN_STORAGE =
  '@@mapado-booking/SET_HAS_CART_ID_IN_STORAGE';
export const FREE_CART_ID = '@@mapado-booking/FREE_CART_ID';
export const SET_CART_AS_SHOPPING_CART =
  '@@mapado-booking/SET_CART_AS_SHOPPING_CART';
export const UPDATE_CUSTOMER = '@@mapado-booking/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_RECEIVED =
  '@@mapado-booking/UPDATE_CUSTOMER_RECEIVED';
export const UPDATE_CUSTOMER_ERROR = '@@mapado-booking/UPDATE_CUSTOMER_ERROR';
