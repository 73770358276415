import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAgenda = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 27" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-104 -1041)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="agenda"
            d="M9.333 231h21.334v2.667H9.333v10.666h21.334v-18.666H28v2.666h-2.667v-2.666H14.667v2.666H12v-2.666H9.333zM28 223h5.333v24H6.667v-24H12v-2.667h2.667V223h10.666v-2.667H28z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAgenda;
