import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFaq = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 29 25" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-244 -978)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="faq"
            d="M171.98 174.61V160h-22.647v14.61h3.973l2.705 2.99 2.706-2.99zm-12.303 2.667-3.767 4.094-3.767-4.094h-5.476v-19.944h27.886v19.944zM152 165.333h4v4h-4zm6.667 0h4v4h-4zm6.666 0h4v4h-4z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFaq;
