import { getShortId } from '@mapado/js-component';
import { Map } from 'immutable';
import {
  assertRelationIsObject,
  assertRelationIsString,
} from 'mapado-ticketing-js-sdk';
import { ShouldNotHappenError } from '../errors';
import {
  AvailableSeatModelType,
  AvailableSeatType,
  isAvailableSeatModelType,
  isAvailableSeatType,
  SeatEntity,
  SeatType,
} from '../propTypes';

// eslint-disable-next-line import/prefer-default-export
export const getLongId = (prefix: string, id: string | number): string => {
  const stringId = String(id);

  return stringId.startsWith(prefix) ? stringId : `${prefix}${id}`;
};

export function getSeatLongIdForAvailableSeat(
  availableSeat: AvailableSeatType
): string {
  assertRelationIsObject(availableSeat.seat, 'availableSeat.seat');
  assertRelationIsString(availableSeat.seat['@id'], 'availableSeat.seat.@id');

  return availableSeat.seat['@id'];
}

export function getSeatIdForAvailableSeat(
  availableSeat: AvailableSeatType
): number {
  return Number(getShortId(getSeatLongIdForAvailableSeat(availableSeat)));
}

export function getSeatIdForAvailableSeatModel(
  availableSeatModel: AvailableSeatModelType
): number {
  assertRelationIsObject(availableSeatModel.seat, 'availableSeat.seat');
  assertRelationIsString(
    availableSeatModel.seat['@id'],
    'availableSeat.seat.@id'
  );

  return Number(getShortId(availableSeatModel.seat['@id']));
}

export function getSeatFromSeatEntity(
  seatEntity: SeatEntity,
  seatById: Map<string, SeatType>
): SeatType {
  let seat: SeatType;

  if (
    (isAvailableSeatType(seatEntity) || isAvailableSeatModelType(seatEntity)) &&
    seatEntity.seat
  ) {
    assertRelationIsObject(seatEntity.seat, 'seatEntity.seat');

    const avsSeatId = seatEntity.seat['@id'];

    assertRelationIsString(avsSeatId, 'avsSeatId');

    const seatFromSeatMap = seatById.get(avsSeatId);

    if (!seatFromSeatMap) {
      throw new ShouldNotHappenError('seat should be found in seatById');
    }

    seat = seatFromSeatMap; // as seatEntity.seat is a string, we need to fetch the seat from the seatById
  } else {
    seat = seatEntity as SeatType;
  }

  if (!seat['@id']) {
    throw new ShouldNotHappenError('seat @id should be defined');
  }

  return seat;
}
