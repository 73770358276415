import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 18" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-733 -979)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="billet"
            d="M642 173.333v-12h-4v12zm2.667 0H662v-12h-17.333zm-9.334-6.666v-8h29.334v8h-1.334v2.666h1.334V176h-29.334v-6.667h1.334v-2.666z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgTicket;
