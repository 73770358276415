import clone from 'clone';
import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TicketPriceGroupClient from '../client/TicketPriceGroupClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import OrganizationalUnit from './OrganizationalUnit';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type TicketPriceGroupType = HidableType &
  BaseEntity<'TicketPriceGroup'> & {
    groupName: null | string;
    contract: null | EntityRelation<Contract>;
    manualSort: null | number;
    ticketPriceList: null | List<EntityRelation<TicketPrice>>;
    organizationalUnit: null | EntityRelation<OrganizationalUnit>;
  };

class TicketPriceGroup extends NetworkEntity<TicketPriceGroupType>({
  '@id': null,
  '@type': 'TicketPriceGroup',
  groupName: null,
  contract: new Contract(),
  manualSort: null,
  ticketPriceList: List<EntityRelation<TicketPrice>>(),
  organizationalUnit: null,
  isHidden: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketPriceGroupType> = {
      '@id': null,
      '@type': 'TicketPriceGroup',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticket_price_groups/', '') || '';
  }
}

TicketPriceGroup.classMetadata = new ClassMetadata(
  'ticketPriceGroup',
  'ticket_price_groups',
  /** @ts-expect-error -- method signature are incompatible */
  TicketPriceGroupClient
);

TicketPriceGroup.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('manualSort'),
  new Attribute('groupName'),
  new Attribute('isHidden', 'isHidden', 'boolean'),
]);

TicketPriceGroup.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(
    Relation.MANY_TO_ONE,
    'organizationalUnit',
    'organizationalUnit'
  ),
]);

export default TicketPriceGroup;
