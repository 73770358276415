import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHourglass = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 27 43" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M4.773 6.609h17.455v2.474c0 4.864-3.927 8.843-8.728 8.843-4.8 0-8.727-3.98-8.727-8.843zm19.852-.912v3.168c0 5.154-3.506 9.682-8.433 10.934V23.2c4.926 1.253 8.433 5.78 8.433 10.934v3.169H27V43H0v-5.696h2.376v-3.169c0-5.154 3.506-9.68 8.432-10.934V19.8c-4.926-1.253-8.432-5.78-8.432-10.934V5.697H0V0h27v5.697zm-1.347 0H3.723v3.168c0 5.184 3.568 8.855 8.432 9.829v5.612c-4.885.978-8.432 4.651-8.432 9.83v3.168h19.555v-3.169c0-5.178-3.546-8.851-8.432-9.83v-5.61c4.863-.976 8.432-4.646 8.432-9.83zM9.5 32H18l4.5 4.5-13.538-.021L5 36.5zm4.383-2.514c.287 0 .52.236.52.527 0 .29-.232.528-.52.528a.525.525 0 0 1-.52-.528c0-.29.233-.527.52-.527m-.765-3.467c.286 0 .52.237.52.528a.524.524 0 0 1-.52.527.525.525 0 0 1-.521-.527c0-.29.233-.528.52-.528m-.375-4.687c.287 0 .52.237.52.528 0 .29-.232.527-.52.527a.525.525 0 0 1-.52-.527c0-.29.233-.528.52-.528m1.515-2.31c.286 0 .52.237.52.527s-.234.528-.52.528a.525.525 0 0 1-.521-.528c0-.29.233-.527.52-.527m.766 8.698c.286 0 .52.237.52.527s-.233.528-.52.528a.525.525 0 0 1-.521-.528c0-.29.233-.527.52-.527m-.766-4.621c.286 0 .52.236.52.528 0 .29-.234.527-.52.527a.525.525 0 0 1-.521-.527c0-.291.233-.528.52-.528"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHourglass;
