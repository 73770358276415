import { connect } from 'react-redux';
import { List } from 'immutable';
import { Contingent } from 'mapado-ticketing-js-sdk';
import ContingentSidebarContent, {
  StateProps,
  DispatchProps,
  OwnProps,
} from '../../components/Sidebar/LogicalSeatConfigAdminSidebar/ContingentPane/ContingentSidebarContent';
import {
  selectBatchOfSeats,
  removeContingent,
} from '../../actions/SeatActions';
import {
  removeAvailableSeatModelListContingent,
  updateAvailableSeatModelListContingent,
} from '../../actions/LogicalSeatConfigAction';

import type { RootState } from '../../reducers';
import { getSelectedSeatIdListHash } from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => ({
  selectedSeatIdListHash: getSelectedSeatIdListHash(state),
  updateStatus:
    state.seating.getIn([
      'updatingAvailableSeatModelList-fetch-status',
      'status',
    ]) || null,
  contingentList: state.seating.get('contingentList') || List<Contingent>(),
});

const mapDispatchToProps = {
  selectBatchOfSeats,
  onUpdateContingentSeatList: updateAvailableSeatModelListContingent,
  removeAvailableSeatModelListContingent,
  removeContingent,
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContingentSidebarContent);
