import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <g id="Symbols" fillRule="evenodd">
      <g id="icon/plier-copy" fillRule="nonzero" transform="translate(-3 -3)">
        <path
          id="moins"
          d="M15 27C8.373 27 3 21.627 3 15S8.373 3 15 3s12 5.373 12 12-5.373 12-12 12m-4-13v2h8v-2z"
        />
      </g>
    </g>
  </svg>
);
export default SvgMinus;
