import React, { ReactElement, MouseEvent, useEffect } from 'react';
import { Booking } from 'mapado-ticketing-js-sdk';
import BookingItemRecap from './BookingItemRecap';
import '../../../styles/components/Sidebar/BookingLine.scss';

function BookingLine({
  booking,
  onClick,
  focusBookingOnSeatPlan,
  unFocusBookingOnSeatPlan,
}: BookingLineProps): ReactElement | null {
  const handleOnClick = (event: MouseEvent): void => {
    event?.stopPropagation();
    event?.preventDefault();
    onClick(booking);
  };

  useEffect(() => {
    return () => unFocusBookingOnSeatPlan(booking);
  }, [booking, unFocusBookingOnSeatPlan]);

  return (
    <div
      className="booking-line booking-line--clickable"
      aria-hidden="true"
      onClick={handleOnClick}
      onMouseEnter={(): void => {
        focusBookingOnSeatPlan(booking);
      }}
      onMouseLeave={(): void => {
        unFocusBookingOnSeatPlan(booking);
      }}
    >
      <BookingItemRecap booking={booking} />
    </div>
  );
}

type BookingCallback = (booking: Booking) => void;
export type BookingNullableCallback = (booking: Booking | null) => void;

type BookingLineProps = {
  booking: Booking;
  onClick: BookingCallback;
  focusBookingOnSeatPlan: BookingNullableCallback;
  unFocusBookingOnSeatPlan: BookingNullableCallback;
};

export default BookingLine;
