import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import Minisite from './Minisite';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type SellingDeviceType = BaseEntity<'SellingDevice'> & {
  contract: null | EntityRelation<Contract>;
  name: null | string;
  type: null | string;
  originType: null | string;
  paymentFeeKey: null | string;
  minisite: null | EntityRelation<Minisite>;
  contractList: null | List<EntityRelation<Contract>>;
  hideFees: null | boolean;
  isExternalProvider: undefined | boolean;
};

class SellingDevice extends NetworkEntity<SellingDeviceType>({
  '@id': null,
  '@type': 'SellingDevice',
  contract: new Contract(),
  name: null,
  type: '',
  originType: null,
  paymentFeeKey: null,
  minisite: null,
  contractList: null,
  hideFees: null,
  isExternalProvider: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<SellingDeviceType> = {
      '@id': null,
      '@type': 'SellingDevice',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/selling_devices/', '') || '';
  }
}

SellingDevice.classMetadata = new ClassMetadata(
  'sellingDevice',
  'selling_devices',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
SellingDevice.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('paymentFeeKey'),
  new Attribute('originType'),
  new Attribute('name'),
  new Attribute('hideFees', 'hideFees', 'boolean'),
  new Attribute('isExternalProvider', 'isExternalProvider', 'boolean'),
]);
SellingDevice.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'minisite', 'minisite'),
  new Relation(Relation.ONE_TO_MANY, 'contract', 'contractList'),
]);

export default SellingDevice;
