import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReducedMobility = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 9 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M0 1a1 1 0 0 1 1-1h6.5a1 1 0 0 1 1 1v6.5a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm4.032.737a.526.526 0 0 0-.521.53c0 .289.228.53.52.53s.522-.241.522-.53a.525.525 0 0 0-.521-.53M4.4 3.031h-.735v2.121h1.89l.985 1.439.598-.432-1.2-1.752H4.4v-.454h.719v-.745h-.72zm-1.057 1.06v-.69l-.223.088a1.917 1.917 0 1 0 2.292 2.85l.09-.137-.537-.358-.09.136a1.27 1.27 0 1 1-1.623-1.844z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReducedMobility;
