import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import NotificationClient from '../client/NotificationClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import type { BaseEntity, EntityRelation, PartialEntity } from '.';

export type NotificationType = BaseEntity<'Notification'> & {
  active: boolean;
  contract: null | EntityRelation<Contract>;
  createdAt: null | Date;
  message: null | string;
  displayStyle: null | string;
};

class Notification extends NetworkEntity<NotificationType>({
  '@id': null,
  '@type': 'Notification',
  active: false,
  contract: null,
  createdAt: null,
  message: null,
  displayStyle: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<NotificationType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/notifications/', '') || '';
  }
}

Notification.classMetadata = new ClassMetadata(
  'notification',
  'notifications',
  /** @ts-expect-error -- method signature are incompatible */
  NotificationClient
);
Notification.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('active'),
  new Attribute('createdAt'),
  new Attribute('message'),
  new Attribute('displayStyle'),
]);
Notification.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Notification;
