import React, { FunctionComponent } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { SingleSelectOption } from './utils';
import MpdIcon from '../../../MpdIcon';

// @ts-expect-error typescipt version is too old. Upgrade it requires to upgrade storybook too
const Option: FunctionComponent<OptionProps<
  SingleSelectOption,
  false,
  GroupBase<SingleSelectOption>
>> = (props: OptionProps<SingleSelectOption>) => {
  const {
    children,
    data: { iconName, iconColor, description },
  } = props;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props}>
        <div className="mpd-single-select-label">
          {iconName && (
            <MpdIcon
              icon={iconName}
              color={iconColor}
              width="12"
              className="mr2"
            />
          )}
          {children}
        </div>
        {description && (
          <span className="mpd-single-select-value-description">
            {description}
          </span>
        )}
      </components.Option>
    </>
  );
};

export default Option;
