import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPrinter = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 29 28" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-384 -1042)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="imprimante"
            d="M291.333 221.667c-.325 0-.622.302-.622.635v6.349h-1.555c-1.369 0-2.49 1.143-2.49 2.54v9.523c0 1.396 1.121 2.286 2.49 2.286h1.555v4.698c0 .333.297.635.622.635H310c.326 0 .622-.302.622-.635V243h1.556c1.368 0 2.489-.89 2.489-2.286v-9.524c0-1.396-1.121-2.54-2.49-2.54h-1.555v-6.348c0-.333-.296-.635-.622-.635zm1.956 2.603h14.755v4.38H293.29zm-2.93 6.73h20.616c.626 0 1.114.687 1.114 1.243v7.14c0 .556-.488.988-1.114.988h-.352v-3.537c0-.26-.266-.495-.557-.495h-18.83c-.292 0-.557.236-.557.495l.008 3.537h-.328c-.627 0-1.115-.432-1.115-.989v-7.139c0-.556.488-1.243 1.115-1.243m18.841 1.333c-.658 0-1.2.56-1.2 1.238 0 .68.542 1.239 1.2 1.239s1.2-.56 1.2-1.239c0-.678-.542-1.238-1.2-1.238M293.35 239h14.71v6.667h-14.71z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPrinter;
