import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import WalletClient from '../client/WalletClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import Payout from './Payout';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type WalletType = BaseEntity<'Wallet'> & {
  contract: null | EntityRelation<Contract>;
  ticketPriceList: null | List<EntityRelation<TicketPrice>>;
  payoutList: null | List<EntityRelation<Payout>>;
  provider: null | string;
  providerContractNumber: null | string;
  providerMetadata: null | Record<string, unknown>;
  feeCollectionType: null | string;
  balanceAmountForPro: null | number;
  currency: null | string;
  missingBankAccounts: null;
  payoutPeriodicity: null | string;
  reserveAmountThreshold: null | number;
  reserveRatioThreshold: null | number;
  paymentPayoutTimeThreshold: null | number;
  ticketingPayoutTimeThreshold: null | number;
  active: null | boolean;
};
class Wallet extends NetworkEntity<WalletType>({
  '@id': null,
  '@type': 'Wallet',
  contract: new Contract(),
  ticketPriceList: List<EntityRelation<TicketPrice>>(),
  payoutList: List<EntityRelation<Payout>>(),
  provider: null,
  providerContractNumber: null,
  providerMetadata: Map(),
  feeCollectionType: 'payout',
  balanceAmountForPro: null,
  currency: null,
  missingBankAccounts: null,
  payoutPeriodicity: 'weekly',
  reserveAmountThreshold: 10000,
  reserveRatioThreshold: 0.1,
  paymentPayoutTimeThreshold: 604800,
  ticketingPayoutTimeThreshold: 259200,
  active: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<WalletType> = { '@id': null, '@type': 'Wallet' }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/wallets/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Wallet.classMetadata = new ClassMetadata('wallet', 'wallets', WalletClient);
Wallet.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('currency'),
  new Attribute('provider'),
  new Attribute('providerContractNumber'),
  new Attribute('providerMetadata', 'providerMetadata', 'object'),
  new Attribute('balanceAmountForPro'),
  new Attribute('currency'),
  new Attribute('missingBankAccounts'),
  new Attribute('payoutPeriodicity'),
  new Attribute('feeCollectionType'),
  new Attribute('reserveAmountThreshold'),
  new Attribute('reserveRatioThreshold'),
  new Attribute('paymentPayoutTimeThreshold'),
  new Attribute('ticketingPayoutTimeThreshold'),
  new Attribute('active', 'active', 'boolean'),
]);
Wallet.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.ONE_TO_MANY, 'ticketPrice', 'ticketPriceList'),
  new Relation(Relation.ONE_TO_MANY, 'payout', 'payoutList'),
]);

export default Wallet;
