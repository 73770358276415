/* eslint-disable jsx-a11y/label-has-for */
import React, { MouseEvent, PureComponent } from 'react';
import { List, Set } from 'immutable';
import { connect } from 'react-redux';
import { updateSeatList } from '../../actions/EditorActions';
import { selectBatchOfSeats } from '../../../../src/actions/SeatActions';
import { SeatType } from '../../../../src/propTypes';
import { RootState } from '../../../../src/reducers';
import { seatByIdSelector } from '../../../../src/utils/seatSelectable';
import {
  seatConfigSelector,
  seatSelector,
  selectedSeatIdSetSelector,
} from '../../../../src/utils/selectors';

type SeatInfoKey = 'Numéro de place' | 'Rangée';

function getRowNumber(seat: SeatType): string {
  const seatInfo = seat.info;

  if (typeof seatInfo['Rangée'] !== 'undefined') {
    return seatInfo['Rangée'];
  }

  if (typeof seatInfo['rangée'] !== 'undefined') {
    return seatInfo['rangée'];
  }

  if (typeof seatInfo.rowgroup !== 'undefined') {
    return seatInfo.rowgroup;
  }

  return '';
}

function getSeatNumber(seat: SeatType): string {
  const seatInfo = seat.info;

  if (typeof seatInfo['Numéro de place'] !== 'undefined') {
    return seatInfo['Numéro de place'];
  }

  return '';
}

function getSeatLabel(seat: SeatType, state: State): string {
  const { newRow, newSeat } = state;

  const row = newRow || getRowNumber(seat);
  const seatNumber = newSeat || getSeatNumber(seat);

  return `${row}${seatNumber}`;
}

type MultiSeatSelectedActionsProps = {
  onError: () => void;
  seatList: List<SeatType> | null;
  selectedSeatList?: List<SeatType>;
  updateSeatList: (seatList: List<SeatType>, onError: () => void) => void;
  selectBatchOfSeats: (seatIds: Set<number>) => void;
};

type State = {
  newRow: string | undefined;
  newSeat: string | undefined;
};

class MultiSeatSelectedActions extends PureComponent<
  MultiSeatSelectedActionsProps,
  State
> {
  constructor(props: MultiSeatSelectedActionsProps) {
    super(props);

    this.state = {
      newRow: undefined,
      newSeat: undefined,
    };

    this.editSeatInfo = this.editSeatInfo.bind(this);
    this.saveSeats = this.saveSeats.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.selectColumn = this.selectColumn.bind(this);
  }

  editSeatInfo(field: 'newRow' | 'newSeat', value: string): void {
    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  }

  saveSeats(): void {
    const { newRow, newSeat } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { selectedSeatList, updateSeatList, onError } = this.props;

    if (!selectedSeatList) {
      return;
    }

    if (
      selectedSeatList.size > 1 &&
      // eslint-disable-next-line no-alert
      !window.confirm(
        `${selectedSeatList.size} sèges seront modifés. Continuer ?`
      )
    ) {
      return;
    }

    if (typeof newRow === 'undefined' && typeof newSeat === 'undefined') {
      // if (typeof seatInformationModificationMap[seat['@id']] === 'undefined') {
      return;
    }

    const updatedData: Partial<Record<SeatInfoKey, string>> = {};

    if (typeof newRow !== 'undefined') {
      updatedData['Rangée'] = newRow;
    }

    if (typeof newSeat !== 'undefined') {
      updatedData['Numéro de place'] = newSeat;
    }

    updateSeatList(
      selectedSeatList.map((s) => {
        const newInfo = {
          ...s.info,
          ...updatedData,
          label: getSeatLabel(s, this.state),
        };

        return {
          ...s,
          info: newInfo,
        };
      }),
      onError
    );

    this.setState({
      newRow: undefined,
      newSeat: undefined,
    });
  }

  selectRow(e: MouseEvent<unknown>): void {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { seatList, selectedSeatList, selectBatchOfSeats } = this.props;

    const firstSeat = selectedSeatList?.first();

    if (!seatList || !firstSeat) {
      return;
    }

    const row = getRowNumber(firstSeat);

    const seatIdSetToSelect = seatList
      .filter((seat) => getRowNumber(seat) === row)
      .map((seat) => Number(seat['@id'].replace('/v1/seats/', '')))
      .toSet();

    selectBatchOfSeats(seatIdSetToSelect);
  }

  selectColumn(e: MouseEvent<unknown>): void {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { seatList, selectedSeatList, selectBatchOfSeats } = this.props;

    const firstSeat = selectedSeatList?.first();

    if (!seatList || !firstSeat) {
      return;
    }

    const column = getSeatNumber(firstSeat);

    const seatIdSetToSelect = seatList
      .filter((seat) => getSeatNumber(seat) === column)
      .map((seat) => Number(seat['@id'].replace('/v1/seats/', '')))
      .toSet();

    selectBatchOfSeats(seatIdSetToSelect);
  }

  render() {
    const { selectedSeatList } = this.props;
    const { newRow, newSeat } = this.state;

    if (!selectedSeatList || selectedSeatList.size === 0) {
      return null;
    }

    const firstSeat = selectedSeatList.first();

    if (!firstSeat) {
      throw new Error('Seat list should not be empty. This should not happen');
    }

    const displaySeatRow = selectedSeatList.groupBy(getRowNumber).size === 1;
    const displaySeatNumber =
      selectedSeatList.groupBy(getSeatNumber).size === 1;
    const onlyOneSeat = selectedSeatList.size === 1;

    return (
      <div className="p3">
        <div>
          <strong>{selectedSeatList.size} sièges sélectionnés</strong>
        </div>

        <div className="mt2">
          <div>
            <strong>Informations de sièges</strong>
          </div>

          <div className="form-group">
            <input
              type="text"
              value={newRow || (displaySeatRow ? getRowNumber(firstSeat) : '')}
              onChange={(evt) => this.editSeatInfo('newRow', evt.target.value)}
              className="form-control"
              id="input-seat-row"
              placeholder="Valeurs multiples"
            />
            <label htmlFor="input-seat-row">Rangée</label>

            {onlyOneSeat && (
              <a href="#allRow" onClick={this.selectRow}>
                Toute la rangée
              </a>
            )}
          </div>

          <div className="form-group">
            <input
              type="text"
              value={
                newSeat || (displaySeatNumber ? getSeatNumber(firstSeat) : '')
              }
              onChange={(evt) => this.editSeatInfo('newSeat', evt.target.value)}
              className="form-control"
              id="input-seat-number"
              placeholder="Valeurs multiples"
            />
            <label htmlFor="input-seat-number">Numéro de place</label>

            {onlyOneSeat && (
              <a href="#allColumn" onClick={this.selectColumn}>
                Toute la colonne
              </a>
            )}
          </div>

          {onlyOneSeat && (
            <div className="form-group">
              <input
                type="text"
                readOnly
                value={getSeatLabel(firstSeat, this.state)}
                className="form-control"
                id="input-seat-label"
              />
              <label htmlFor="input-seat-label">Libellé</label>
            </div>
          )}

          {(typeof newRow !== 'undefined' ||
            typeof newSeat !== 'undefined') && (
            <div className="form-group txtcenter">
              <button
                type="button"
                className="mpd-btn mpd-btn--primary"
                onClick={this.saveSeats}
              >
                Enregistrer les modifications
              </button>
            </div>
          )}
        </div>

        <hr className="mt3" />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const seatConfig = seatConfigSelector(state);
  const selectedSeatIdSet = selectedSeatIdSetSelector(state);

  const seatByIdList = seatByIdSelector(state);

  return {
    selectedSeatList: selectedSeatIdSet
      .map((seatId) => seatByIdList.get(`/v1/seats/${seatId}`))
      .filter((seat): seat is SeatType => typeof seat !== 'undefined')
      .toList(),
    seatList: seatSelector(state),
    seatConfig,
  };
};

const mapDispatchToProps = {
  updateSeatList,
  selectBatchOfSeats,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiSeatSelectedActions);
