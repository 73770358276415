import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDesk = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g id="desk">
        <path id="Oval-59" d="M12.5 11a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
        <path
          id="Combined-Shape"
          d="M19 19v-2c0-2.205-1.794-4-4.007-4h-4.986A4.003 4.003 0 0 0 6 17v2H3.995a1.001 1.001 0 0 0 0 2h17.01a1.001 1.001 0 0 0 0-2z"
        />
      </g>
    </g>
  </svg>
);
export default SvgDesk;
