import React from 'react';
import { List } from 'immutable';
import { Contingent, StockContingent } from 'mapado-ticketing-js-sdk';
import {
  TicketingSdkContext,
  ContingentSelector,
  getEntityId,
} from '@mapado/js-component';
import { TOAST_TYPE, useMpdToast } from '@mapado/makeup';
import TicketingSdkInstance from '../../../TicketingSdkInstance';
import { SET_STOCK_CONTINGENT_LIST } from '../../../reducers/types';
import { resetSelectedSeats } from '../../../actions/SeatActions';
import {
  useStockManagement,
  STOCK_CONTINGENT_FIELDS,
} from '../../../contexts/StockManagementContext';
import { useStockContingentSelector } from '../../../utils/selectors';
import { getLongId } from '../../../utils/entity';
import { useSeatingDispatch } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

function StockContingentSidebarActions({ onError }: { onError: () => void }) {
  const {
    selectStockContingent,
    unselectStockContingent,
    contractId,
    eventDateId,
  } = useStockManagement();

  const { t } = useTranslation();
  const toast = useMpdToast();
  const dispatch = useSeatingDispatch();

  const stockContingentList = useStockContingentSelector() || List();

  const addContingent = async (newContingent: Contingent) => {
    // prevent update of previously selected contingent
    unselectStockContingent();

    try {
      const stockContingentAdded = await TicketingSdkInstance.getSdk()
        .getRepository('stockContingent')
        .create(
          new StockContingent({
            '@id': null,
            '@type': 'StockContingent',
            eventDate: getLongId('/v1/event_dates/', eventDateId),
            contract: contractId,
            totalStock: 0,
            contingent: getEntityId(newContingent),
            draft: false,
          }),
          { fields: STOCK_CONTINGENT_FIELDS }
        );

      dispatch({
        type: SET_STOCK_CONTINGENT_LIST,
        stockContingentList: stockContingentList.push(stockContingentAdded),
      });

      dispatch(resetSelectedSeats());
      selectStockContingent(stockContingentAdded); // Select new contingent added
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      toast({
        title: t('stock_contingent.update_error'),
        type: TOAST_TYPE.ERROR,
      });

      // wait for toast to be close to trigger `onError`
      window.setTimeout(() => {
        onError();
      }, 5000);
    }
  };

  if (!contractId) {
    return null;
  }

  return (
    <TicketingSdkContext.Provider
      value={TicketingSdkInstance.getDefaultSerializerSdk()}
    >
      <ContingentSelector
        onChange={addContingent}
        labelClassName="mpd-btn mpd-btn--primary mpd-color-white"
        className="stock-management-sidebar__actionButton"
        contractId={contractId}
        label={t('stock_contingent.add_new_btn')}
        hiddenContingentIdList={stockContingentList.map(
          (sc) => getEntityId(sc.contingent) ?? ''
        )}
      />
    </TicketingSdkContext.Provider>
  );
}

export default StockContingentSidebarActions;
