import React from 'react';
import { List } from 'immutable';
import { useDomainContext } from '@mapado/js-component';
import DraggableSVG, { DraggableSVGTypes } from './DraggableSVG';
import { SeatEntityFromType, SeatEntityType, SeatType } from '../propTypes';
import {
  getSelectableSeatIdSet,
  GetSelectableSeatIdSetFunction,
} from '../utils/seatSelectable';
import {
  isLoadedAvailableSeatList,
  isLoadedAvailableSeatModelList,
  isLoadedSeatConfig,
  seatSelector,
  useAvailableSeatSelector,
} from '../utils/selectors';
import '../styles/components/Seating.css';
import {
  useSeatingSelector,
  useSeatingStore,
} from '../reducers/typedFunctions';
import { useTranslation } from '../i18n';

function Seating<SE extends SeatEntityType>({
  seatEntityType,
  getSelectableSeatIdSet: getSelectableSeatIdSetInjectedFunction,
  ...rest
}: SeatingProps<SE>): React.ReactElement {
  if (!seatEntityType) {
    // eslint-disable-next-line no-console
    console.warn('The `seatEntityType` props for `Seating` must be set.');
  }

  const { t } = useTranslation();

  const { currentDomain } = useDomainContext();

  const seatingState = useSeatingStore().getState();

  const initialSeatList: List<SeatType> =
    useSeatingSelector(seatSelector) || List();

  let seatEntityList: List<SeatEntityFromType<SE>>;

  const availableSeatList = useAvailableSeatSelector();
  const availableSeatModelList = useSeatingSelector((state) =>
    state.seating.get('availableSeatModelList')
  );

  if (
    seatEntityType === 'AvailableSeat' &&
    isLoadedAvailableSeatList(seatingState) &&
    availableSeatList
  ) {
    // @ts-expect-error seat entity types are valid in this case
    seatEntityList = availableSeatList;
  } else if (
    seatEntityType === 'AvailableSeatModel' &&
    isLoadedAvailableSeatModelList(seatingState) &&
    availableSeatModelList
  ) {
    // @ts-expect-error seat entity types are valid in this case
    seatEntityList = availableSeatModelList;
  } else if (seatEntityType === 'Seat' && isLoadedSeatConfig(seatingState)) {
    // @ts-expect-error seat entity types are valid in this case
    seatEntityList = initialSeatList;
  } else {
    // @ts-expect-error seat entity types are valid in this case
    seatEntityList = List<SeatType>();
  }

  const movingSeatFetchStatusInProgress = useSeatingSelector(
    (state) =>
      state.seating.getIn(['movingSeat-fetch-status', 'status']) ===
      'IN_PROGRESS'
  );
  const selectedSeatIdSet = useSeatingSelector((state) =>
    state.seating.get('selectedSeatIdSet')
  );

  const selectableSeatIdSet = getSelectableSeatIdSet(
    seatingState,
    getSelectableSeatIdSetInjectedFunction
  );

  return (
    <div className="mpd-seating__seating" id="mpd-seating__seating">
      <DraggableSVG<SE>
        {...rest}
        movingSeatFetchStatusInProgress={movingSeatFetchStatusInProgress}
        // @ts-expect-error seat entity types are valid in this case
        seatEntityList={seatEntityList}
        selectedSeatIdSet={selectedSeatIdSet}
        selectableSeatIdSet={selectableSeatIdSet}
        currentDomain={currentDomain}
        t={t}
      />
    </div>
  );
}

type Props<SE extends SeatEntityType> = {
  seatEntityType: SE;
  getSelectableSeatIdSet: GetSelectableSeatIdSetFunction;
};

type SeatingProps<SE extends SeatEntityType> = Omit<
  DraggableSVGTypes<SE>,
  | keyof Props<SE>
  | 'movingSeatFetchStatusInProgress'
  | 'seatGroupList'
  | 'seatEntityList'
  | 'selectedSeatIdSet'
  | 'selectableSeatIdSet'
  | 'currentDomain'
  | 't'
> &
  Props<SE>;

export default Seating;
