import clone from 'clone';
import { fromJS, List, Map, MapOf } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TicketClient from '../client/TicketClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { FormCustomerField, FormData } from '../types/FormDataType';
import { isEmptyArrayOrList } from '../utils';
import { parseDate } from '../utils/date';
import { getParticipantFormData } from '../utils/formData';
import AvailableSeat from './AvailableSeat';
import CartItem from './CartItem';
import Customer from './Customer';
import EventDate from './EventDate';
import MasterTicket from './MasterTicket';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import OrderItem from './OrderItem';
import TicketCheckpoint from './TicketCheckpoint';
import TicketPrice from './TicketPrice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum TICKET_PAYMENT_STATUS {
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
  PAYED = 'payed',
  BOOKED = 'booked',
}

export type TicketType = BaseEntity<'Ticket'> & {
  data: null | Map<string, unknown>;
  facialValue: null | number;
  facialFeeValue: null | number;
  currency: null | string;
  ticketPrice: null | EntityRelation<TicketPrice>;
  order: null | EntityRelation<Order>;
  orderItem: null | EntityRelation<OrderItem>;
  barcode: null | string;
  boughtAt: null | Moment;
  status: null | string;
  tax: null;
  refundedAt: null | Moment;
  firstScannedDate: null | Moment;
  lastScannedDate: null | Moment;
  ticketPriceName: null | string;
  ticketPriceType: null | string;
  valid: null | boolean;
  isUnlimited: null | boolean;
  cartItem: null | EntityRelation<CartItem>;
  seatReserved: null | EntityRelation<AvailableSeat>;
  updatedAt: null | Moment;
  maxScanNb: null | number;
  remainingScanNb: null | number;
  currentScanNb: null | number;
  currentValidScanNb: null | number;
  validStartDate: null | Moment;
  validEndDate: null | Moment;
  ticketingTitle: null | string;
  eventDateStartDate: null | Moment;
  eventDateEndDate: null | Moment;
  ticketingTimezone: null | string;
  /**
   * @deprecated use `participantRequiredFields` instead
   */
  ticketRequiredFields: null | List<Map<string, unknown>>;
  participantRequiredFields: null | List<Map<string, unknown>>;
  /**
   * @deprecated use `participantRequiredFields` instead
   */
  customerRequiredFields: null | Map<string, unknown>;
  eventDate: null | EntityRelation<EventDate>;
  masterTicketList: null | List<EntityRelation<MasterTicket>>;
  availableSeat: null | EntityRelation<AvailableSeat>;
  ticketCheckpointList: null | List<EntityRelation<TicketCheckpoint>>;
  participant: null | EntityRelation<Customer>;
  isCustomized: boolean;
  /**
   * formData is a helper that does not exist in the API, but help the front to handle participant form data
   */
  participantFormData: null | MapOf<FormData> | undefined;
};
class Ticket extends NetworkEntity<TicketType>({
  '@id': null,
  '@type': 'Ticket',
  data: null,
  facialValue: 0,
  facialFeeValue: 0,
  currency: null,
  ticketPrice: new TicketPrice(),
  order: new Order(),
  orderItem: new OrderItem(),
  barcode: null,
  boughtAt: null,
  status: null,
  tax: null,
  refundedAt: null,
  firstScannedDate: null,
  lastScannedDate: null,
  ticketPriceName: '',
  ticketPriceType: '',
  valid: false,
  isUnlimited: null,
  cartItem: new CartItem(),
  seatReserved: null,
  updatedAt: null,
  maxScanNb: null,
  remainingScanNb: null,
  currentScanNb: 0,
  currentValidScanNb: 0,
  validStartDate: null,
  validEndDate: null,
  ticketingTitle: '',
  eventDateStartDate: null,
  eventDateEndDate: null,
  ticketingTimezone: '',
  ticketRequiredFields: null,
  participantRequiredFields: null,
  customerRequiredFields: null,
  eventDate: null,
  masterTicketList: List<MasterTicket>(),
  availableSeat: null,
  ticketCheckpointList: null,
  participant: null,
  isCustomized: false,
  participantFormData: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketType> = { '@id': null, '@type': 'Ticket' }
  ) {
    const data = clone(val);

    if (isEmptyArrayOrList(data.data)) {
      data.data = Map({});
    } else {
      data.data = fromJS(data.data) as Map<string, unknown>;
    }

    const tz = data.ticketingTimezone;

    data.boughtAt = parseDate(data.boughtAt, tz);
    data.lastScannedDate = parseDate(data.lastScannedDate, tz);
    data.firstScannedDate = parseDate(data.firstScannedDate, tz);
    data.updatedAt = parseDate(data.updatedAt, tz);
    data.validStartDate = parseDate(data.validStartDate, tz);
    data.validEndDate = parseDate(data.validEndDate, tz);
    data.eventDateStartDate = parseDate(data.eventDateStartDate, tz);
    data.eventDateEndDate = parseDate(data.eventDateEndDate, tz);

    data.masterTicketList = val.masterTicketList
      ? val.masterTicketList.map((masterTicket): string | MasterTicket => {
          if (typeof masterTicket === 'string') {
            return masterTicket;
          }
          return new MasterTicket(masterTicket);
        })
      : List<MasterTicket>();

    data.participantFormData = getParticipantFormData(
      // @ts-expect-error participantRequiredFields and partitipant type issues
      data.participant,
      data.participantRequiredFields
    );

    super(data);

    return mapEntityRelationShips(this, data);
  }

  /**
   * @deprecated use `eventDateStartDate` instead
   */
  getLocalEventDateStartDate(): null | Moment {
    if (!this.eventDateStartDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.eventDateStartDate.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `eventDateEndDate` instead
   */
  getLocalEventDateEndDate(): null | Moment {
    if (!this.eventDateEndDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.eventDateEndDate.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `firstScannedDate` instead
   */
  getLocalFirstScannedDate(): null | Moment {
    if (!this.firstScannedDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.firstScannedDate.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `lastScannedDate` instead
   */
  getLocalLastScannedDate(): null | Moment {
    if (!this.lastScannedDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.lastScannedDate.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `updatedAt` instead
   */
  getLocalUpdatedAt(): null | Moment {
    if (!this.updatedAt) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.updatedAt.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `validStartDate` instead
   */
  getLocalValidStartDate(): null | Moment {
    if (!this.validStartDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.validStartDate.clone().tz(this.ticketingTimezone);
  }

  /**
   * @deprecated use `validEndDate` instead
   */
  getLocalValidEndDate(): null | Moment {
    if (!this.validEndDate) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error -- issue with moment timezone */
    return this.validEndDate.clone().tz(this.ticketingTimezone);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/tickets/', '') || '';
  }

  /**
   * user does not exists on ticket
   * @deprecated
   */
  getUserId(): null {
    return null;
  }

  isValid(): boolean {
    return !!this.get('valid');
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error -- possibly an conflict with Record */
  merge(newTicket: Ticket): Ticket {
    let mostRecentTicket: null | Ticket = this.updatedAt ? this : null;
    if (
      newTicket.updatedAt &&
      (!this.updatedAt || newTicket.updatedAt > this.updatedAt)
    ) {
      mostRecentTicket = newTicket;
    }

    if (mostRecentTicket) {
      return mostRecentTicket;
    }

    // fallback for ticketing without updatedAt property
    const newStatus = Object.values(TICKET_PAYMENT_STATUS).find(
      (status) => this.status === status || newTicket.status === status
    );

    let newValidity;

    if (this.status === newTicket.status) {
      newValidity = this.valid && newTicket.valid;
    } else {
      newValidity = this.status === newStatus ? this.valid : newTicket.valid;
    }

    return newTicket
      .set('status', newStatus || null)
      .set('valid', newValidity)
      .set('lastScannedDate', this.lastScannedDate || newTicket.lastScannedDate)
      .set(
        'firstScannedDate',
        this.firstScannedDate || newTicket.firstScannedDate
      );
  }

  /** @deprecated  use ticket.participant infos instead */
  getFirstname(): null | string {
    return this.getIn(['data', 'firstname']) as null | string;
  }

  /** @deprecated  use ticket.participant infos instead */
  getLastname(): null | string {
    return this.getIn(['data', 'lastname']) as null | string;
  }

  /** @deprecated  use ticket.participant infos instead */
  getFullname(): string {
    let out = '';

    if (this.getLastname()) {
      out += this.getLastname();
      out += ' ';
    }

    if (this.getFirstname()) {
      out += this.getFirstname();
    }

    return out;
  }

  getFormParticipantFields(
    disabledFields = false,
    optionalFields?: boolean
  ): FormCustomerField[] {
    if (!this.participantRequiredFields) {
      throw new Error(
        'ParticipantRequiredFields must be defined. Please check your request fields.'
      );
    }

    const participantRequiredFields = this.participantRequiredFields.toJS() as FormCustomerField[];
    const formattedFieldList = participantRequiredFields.map(
      (field: FormCustomerField) => {
        const fieldWithFormattedKey = Object.keys(field).reduce((acc, key) => {
          const formattedKey = key.startsWith('form')
            ? key.replace('form', '').toLowerCase()
            : key;

          return { ...acc, [formattedKey]: field[key] };
        }, field);

        const fieldOptions = field.options
          ? {
              ...field.options,
              inputProps: {
                ...field.options.inputProps,
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              options: field.options.options,
              isRequired: optionalFields ? false : field.required,
            }
          : {
              inputProps: {
                className: field.formType !== 'checkbox' ? 'form-control' : '',
                disabled: disabledFields,
                allowCreate: false,
              },
              isRequired: optionalFields ? false : field.required,
            };

        return {
          ...fieldWithFormattedKey,
          name: field.slug,
          options: fieldOptions,
        };
      }
    );

    return formattedFieldList;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
Ticket.classMetadata = new ClassMetadata('ticket', 'tickets', TicketClient);
Ticket.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('data', 'data', 'object'),
  new Attribute('facialValue', 'facialValue', 'integer'),
  new Attribute('currency'),
  new Attribute('barcode'),
  new Attribute('boughtAt', 'boughtAt', 'datetime'),
  new Attribute('status'),
  new Attribute('refundedAt', 'refundedAt', 'datetime'),
  new Attribute('firstScannedDate', 'firstScannedDate', 'datetime'),
  new Attribute('lastScannedDate', 'lastScannedDate', 'datetime'),
  new Attribute('ticketPriceName'),
  new Attribute('ticketPriceType'),
  new Attribute('valid', 'valid', 'boolean'),
  new Attribute('isUnlimited', 'isUnlimited', 'boolean'),
  new Attribute('updatedAt', 'updatedAt', 'datetime'),
  new Attribute('maxScanNb', 'maxScanNb', 'integer'),
  new Attribute('remainingScanNb', 'remainingScanNb', 'integer'),
  new Attribute('currentScanNb', 'currentScanNb', 'integer'),
  new Attribute('currentValidScanNb', 'currentValidScanNb', 'integer'),
  new Attribute('validStartDate', 'validStartDate', 'datetime'),
  new Attribute('validEndDate', 'validEndDate', 'datetime'),
  new Attribute('ticketingTitle'),
  new Attribute('eventDateStartDate', 'eventDateStartDate', 'datetime'),
  new Attribute('eventDateEndDate', 'eventDateEndDate', 'datetime'),
  new Attribute('ticketingTimezone'),
  new Attribute('ticketRequiredFields', 'ticketRequiredFields', 'object'),
  new Attribute(
    'participantRequiredFields',
    'participantRequiredFields',
    'object'
  ),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('isCustomized', 'isCustomized', 'boolean'),
]);

Ticket.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_MANY, 'masterTicket', 'masterTicketList'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPrice', 'ticketPrice'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'orderItem', 'orderItem'),
  new Relation(Relation.MANY_TO_ONE, 'cartItem', 'cartItem'),
  new Relation(Relation.MANY_TO_ONE, 'availableSeat', 'seatReserved'),
  new Relation(Relation.MANY_TO_ONE, 'availableSeat', 'availableSeat'),
  new Relation(
    Relation.ONE_TO_MANY,
    'ticketCheckpoint',
    'ticketCheckpointList'
  ),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'participant'),
]);

export default Ticket;
