/* eslint-disable max-classes-per-file */
class RelationError extends Error {
  constructor(message: null | string) {
    super(message || 'This should not happen.');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationError);
    }

    this.name = 'RelationError';
  }
}

export class RelationShouldBeDefinedError extends RelationError {
  constructor(objectName?: string) {
    super(
      `Object ${
        objectName ?? '<unnamed>'
      } should not be null. This should not happen.`
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationShouldBeDefinedError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationShouldBeDefinedError);
    }

    this.name = 'RelationShouldBeDefinedError';
  }
}

export class RelationShouldBeStringError extends RelationError {
  constructor(relationName?: string) {
    super(
      `Relation ${
        relationName ?? '<unnamed>'
      } should be a string. Please check the \`fields\` that you set for your request.`
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationShouldBeStringError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationShouldBeStringError);
    }

    this.name = 'RelationShouldBeStringError';
  }
}

export class RelationShouldBeNullOrStringError extends RelationError {
  constructor(relationName?: string) {
    super(
      `Relation ${
        relationName ?? '<unnamed>'
      } should be null or a string. Please check the \`fields\` that you set for your request.`
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationShouldBeNullOrStringError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationShouldBeNullOrStringError);
    }

    this.name = 'RelationShouldBeNullOrStringError';
  }
}

export class RelationShouldBeObjectError extends RelationError {
  constructor(relationName?: string) {
    super(
      `Relation ${
        relationName ?? '<unnamed>'
      } should be an object. Please check the \`fields\` that you set for your request.`
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationShouldBeObjectError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationShouldBeObjectError);
    }

    this.name = 'RelationShouldBeObjectError';
  }
}

export class RelationShouldBeNullOrObjectError extends RelationError {
  constructor(relationName?: string) {
    super(
      `Relation ${
        relationName ?? '<unnamed>'
      } should be null or an object. Please check the \`fields\` that you set for your request.`
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelationShouldBeNullOrObjectError.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RelationShouldBeNullOrObjectError);
    }

    this.name = 'RelationShouldBeNullOrObjectError';
  }
}
