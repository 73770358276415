import React, { useEffect, useState, useCallback } from 'react';
import {
  Booking,
  Order,
  Cart,
  BOOKING_ENTITY_NAME,
} from 'mapado-ticketing-js-sdk';
import { getSingleCart } from '../../useCart';
import { getSingleOrder } from '../../useOrder';
import CartTile from './BookingCartTile';
import OrderTile from './BookingOrderTile';
import BookingItemRecap from './BookingItemRecap';
import '../../../styles/components/Sidebar/BookingTile.scss';

function BookingTile({
  booking,
  eventDateId,
  isFromSearch,
  canMoveSeats,
}: BookingTileType) {
  const [data, setData] = useState<Cart | Order | null>(null);

  const entityType: BOOKING_ENTITY_NAME | null =
    booking.entityName ||
    (!!booking.cart && BOOKING_ENTITY_NAME.CART) ||
    (!!booking.order && BOOKING_ENTITY_NAME.ORDER) ||
    null; // TODO@lpot [once booking fully migrated] keep only booking.entityName

  const getCartOrOrderData = useCallback(async () => {
    const getEntityMethod =
      (entityType === BOOKING_ENTITY_NAME.CART && getSingleCart) ||
      (entityType === BOOKING_ENTITY_NAME.ORDER && getSingleOrder);

    if (!entityType || !getEntityMethod) {
      throw new Error(
        'Booking should have an entityType and getEntityMethod must be defined'
      );
    }

    const entityId: string | number =
      booking.entityId || (booking[entityType] as Cart | Order).getShortId(); // TODO@lpot [once booking fully migrated] keep only booking.entityId
    const entityData = await getEntityMethod(entityId, eventDateId);

    setData(entityData);
  }, [booking, entityType, eventDateId]);

  useEffect(() => {
    if (isFromSearch) {
      getCartOrOrderData();
    }
  }, [isFromSearch, getCartOrOrderData]);

  if (isFromSearch && !data) {
    return null;
  }

  return (
    <div className="booking-collection__detail">
      <div className="booking-collection__detail__header">
        <BookingItemRecap booking={booking} />
      </div>
      {entityType === BOOKING_ENTITY_NAME.CART && (
        <CartTile
          canMoveSeats={canMoveSeats}
          cart={(data || booking.cart) as Cart}
          eventDateId={eventDateId}
        />
      )}
      {entityType === BOOKING_ENTITY_NAME.ORDER && (
        <OrderTile
          canMoveSeats={canMoveSeats}
          order={(data || booking.order) as Order}
          eventDateId={eventDateId}
        />
      )}
    </div>
  );
}

type BookingTileType = {
  booking: Booking;
  eventDateId: string;
  isFromSearch: boolean;
  canMoveSeats: boolean;
};

export default BookingTile;
