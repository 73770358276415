import {
  ORIGIN_TYPE_ENV_DESK,
  ORIGIN_TYPE_ENV_MINISITE,
} from '@mapado/js-component';

export type ORIGIN_TYPE_ENV =
  | typeof ORIGIN_TYPE_ENV_DESK
  | typeof ORIGIN_TYPE_ENV_MINISITE;

export const SHOPPING_CART = 'shoppingCart';
export type CartId = `/v1/carts/${number}`;
export type CartAtomId = typeof SHOPPING_CART | CartId;
