import {
  assertRelationIsDefined,
  Cart,
  CART_TYPE,
} from 'mapado-ticketing-js-sdk';
import { formatPrice } from '@mapado/js-component';
import React from 'react';
import { translateOrderOrigin } from '../../utils/booking';
import CustomerCell from '../OrderManagement/Sidebar/BookingCustomerCell';
import { useTranslation } from '../../i18n';

function CartTile({ cart }: CartTileType) {
  const { t } = useTranslation();
  const cartCreatedAt = cart.createdAt?.format('LL, LT');

  assertRelationIsDefined(cart.retailValue, 'cart.retailValue');
  assertRelationIsDefined(cart.currency, 'cart.currency');

  const formattedPrice = formatPrice(cart.retailValue, cart.currency);

  return (
    <section className="mpd-seating-booking-tile__container">
      <CustomerCell entity={cart} />
      <div>
        <div className="txtcenter">
          {cart.type === CART_TYPE.RESERVATION &&
            t('sales.reservation', { number: cart.getShortId() })}
          {cart.type === CART_TYPE.CART &&
            t('sales.carts', { number: cart.getShortId() })}
        </div>

        <div>
          <div className="txtcenter help small">{cartCreatedAt}</div>
          {cart.comment !== null && <div className="blue">{cart.comment}</div>}
        </div>
      </div>

      <div className="mpd-seating-booking-tile__ticket_coupon_details mt2">
        <header className="mpd-table__cell-header txtcenter">
          {formattedPrice}
        </header>
        <article className="mpd-table__cell-description">
          {cart.nbTickets !== 0 &&
            t('order.nb_tickets', {
              count: cart.nbTickets ?? 0,
            })}
        </article>
      </div>

      <div className="mpd-table__column--fixed">
        {t('sales.list_header.origin')} :{' '}
        {translateOrderOrigin(t, cart?.originType?.toLowerCase())}
      </div>
    </section>
  );
}

type CartTileType = {
  cart: Cart;
};

export default CartTile;
