import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ScanClient extends AbstractClient<TSMetadata['scan']> {
  getPathBase(pathParameters?: { ticket?: string }): string {
    if (!(pathParameters && pathParameters.ticket)) {
      return '/v1/scans';
    }

    const { ticket } = pathParameters;

    return `${ticket}/scans`;
  }
}

export default ScanClient;
