import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 19" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M.88 9.535 9.973.123l2.951 2.854-6.242 6.46 6.457 6.244-2.853 2.952z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLeft;
