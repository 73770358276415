import React, { ChangeEvent, useState } from 'react';
import {
  assertRelationIsListOfObject,
  assertRelationIsObject,
  Booking,
  BookingItem,
} from 'mapado-ticketing-js-sdk';
import { MpdIcon } from '@mapado/makeup';
import { List } from 'immutable';
import SidebarTemplate from '../../Sidebar/SidebarTemplate';
import BookingCollection from './BookingCollection';
import { unfocusBooking } from '../../../actions/SeatActions';
import { useSeatingDispatch } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

interface Props {
  eventDateId: string;
  contractId: string;
  canMoveSeats: boolean;
}

function getSeatIdList(booking: Booking | null): List<string> {
  assertRelationIsObject(booking, 'booking');
  assertRelationIsListOfObject(
    booking.bookingItemList,
    'booking.bookingItemList'
  );

  return booking.bookingItemList.map(
    (item: BookingItem): string => `${item.seatId}`
  );
}

function OrderManagementSidebar({
  eventDateId,
  contractId,
  canMoveSeats,
}: Props): JSX.Element {
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useSeatingDispatch();
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
  };

  const doSetFocusOnSeatPlan = (booking: null | Booking, isFocused = true) => {
    window.requestAnimationFrame(() => {
      const FOCUSED_CLASS = 'mpd-seating__seat--is-focused';

      const seatListToRemoveFocusedClassFrom =
        document.getElementsByClassName(FOCUSED_CLASS);

      while (seatListToRemoveFocusedClassFrom.length > 0) {
        seatListToRemoveFocusedClassFrom[0].classList.remove(FOCUSED_CLASS);
      }

      if (isFocused) {
        const seatIdList = getSeatIdList(booking);

        const seatNodeList = seatIdList.map((seatId) =>
          document.getElementById(seatId)
        );

        seatNodeList.forEach((node) => {
          if (node) {
            node.classList.add(FOCUSED_CLASS);
          }
        });
      }
    });
  };

  return (
    <SidebarTemplate>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(unfocusBooking());
        }}
      >
        <div className="form-group p0">
          <input
            className="form-control form-control--with-icon booking-search"
            type="search"
            onChange={handleChange}
            value={searchFilter}
            placeholder={t('order_management.sidebar.search.placeholder')}
          />
          <MpdIcon
            className="form-control-icon"
            icon="magnifying-glass"
            height="18"
            width="18"
          />
        </div>
      </form>
      <BookingCollection
        searchFilter={searchFilter}
        canMoveSeats={canMoveSeats}
        eventDateId={eventDateId}
        contractId={contractId}
        focusBookingOnSeatPlan={(booking) =>
          doSetFocusOnSeatPlan(booking, true)
        }
        unFocusBookingOnSeatPlan={(booking) =>
          doSetFocusOnSeatPlan(booking, false)
        }
      />
    </SidebarTemplate>
  );
}

export default OrderManagementSidebar;
