import { useCallback, useEffect, useRef, useState } from 'react';

type LoadDataType = (page: number, displayLoader?: boolean) => Promise<void>;
type SearchDataType = (query: string | null) => Promise<void>;

type ReturnType<T> = [
  boolean, // isLoading
  boolean, // hasMore
  T[], // options
  LoadDataType,
  SearchDataType
];

export type LoadOptionsType<T> =
  | ((
      page: number,
      query: string | null
    ) => Promise<{ hasMore?: boolean; options: T[] }>)
  | undefined;

function useLoadData<T>(loadOptions: LoadOptionsType<T>): ReturnType<T> {
  const mounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const [searchedOptions, setSearchedOptions] = useState<T[] | null>(null);

  const searchData = useCallback(
    async (query: string | null = null) => {
      if (typeof loadOptions !== 'function') {
        return;
      }

      if (!query) {
        setSearchedOptions(null); // reset search

        return;
      }

      setIsLoading(true);

      const {
        hasMore: hasMoreItem,
        options: newOptionArray,
      } = await loadOptions(1, query);

      setHasMore((prev) => hasMoreItem || prev);
      setSearchedOptions(newOptionArray);
      setIsLoading(false);
    },
    [loadOptions]
  );

  const loadData = useCallback(
    async (page: number, displayLoader = true) => {
      if (typeof loadOptions !== 'function') {
        return;
      }

      setIsLoading(displayLoader);

      const {
        hasMore: hasMoreItem,
        options: newOptionArray,
      } = await loadOptions(page, null);

      setHasMore(hasMoreItem ?? false);
      setOptions((prev) => prev.concat(newOptionArray));
      setIsLoading(false);
    },
    [loadOptions]
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    loadData(1, false);
    // Inspired by https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/useAsync.ts#L126
    // We don't want any hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isLoading, hasMore, searchedOptions ?? options, loadData, searchData];
}

export default useLoadData;
