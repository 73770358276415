import { Map } from 'immutable';
import { CollectionType } from '../entity/Collection';
import { AllowedFactoryTypes, Entity } from './types';

export default class EntityRegistry {
  #registry: Map<string, Entity> = Map({});

  public register(
    entity: Entity,
    val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>
  ): void {
    const id = entity['@id'];

    if (
      /** @ts-expect-error -- PagedCollection does exist in API sometimes, but is a collection */
      entity['@type'] === 'hydra:Collection' ||
      /** @ts-expect-error -- PagedCollection does exist in API sometimes, but is a collection */
      entity['@type'] === 'hydra:PagedCollection'
    ) {
      // ignore collections
      return;
    }

    if (!id) {
      // no id, unable to register
      return;
    }

    const key = this.getKey(val);

    if (!key) {
      // unable to register
      return;
    }

    this.#registry = this.#registry.set(key, entity);
  }

  public get(
    val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>
  ): Entity | null {
    const key = this.getKey(val);

    if (!key) {
      return null;
    }

    return this.#registry.get(key) ?? null;
  }

  private getKey(
    val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>
  ): string | null {
    if (val === null) {
      return null;
    }

    const keys = Object.keys(val);

    return `${val['@id']}|${keys.join(',')}`;
  }
}
