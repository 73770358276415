import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlSpace="preserve"
    id="Calque_1"
    x={0}
    y={0}
    viewBox="-499 501 24 24"
    className="mpd-icon"
    {...props}
  >
    <g id="Page-1_1_">
      <g id="user-stroke">
        <path
          id="Shape"
          d="M-487 513c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4"
        />
        <path
          id="Shape_1_"
          d="M-496 518c0-2.2 1.8-4 4-4h10c2.2 0 4 1.8 4 4v2c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2z"
        />
      </g>
    </g>
  </svg>
);
export default SvgUser;
