import { connect } from 'react-redux';
import { List } from 'immutable';
import SeatGroupSidebarContent, {
  StateProps,
  DispatchProps,
  OwnProps,
} from '../../components/Sidebar/LogicalSeatConfigAdminSidebar/SeatGroupPane/SeatGroupSidebarContent';
import { selectBatchOfSeats } from '../../actions/SeatActions';
import { updateAvailableSeatModelListSeatGroup } from '../../actions/LogicalSeatConfigAction';
import type { RootState } from '../../reducers';
import { AvailableSeatModelType } from '../../propTypes';
import { getSelectedSeatIdListHash } from '../../utils/selectors';

const mapStateToProps = (state: RootState): StateProps => ({
  selectedSeatIdListHash: getSelectedSeatIdListHash(state),
  availableSeatModelList:
    state.seating.get('availableSeatModelList') ||
    List<AvailableSeatModelType>(),
});

const mapDispatchToProps = {
  selectBatchOfSeats,
  updateAvailableSeatModelListSeatGroup,
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(SeatGroupSidebarContent);
