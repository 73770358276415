import React from 'react';
import BaseSeat, { SeatProps } from '../../../../src/components/Seat/BaseSeat';

const PlanEditorSeat = React.memo(({ color, ...rest }: Props) => {
  return (
    <BaseSeat<'Seat'>
      {...rest}
      icon="seat-filled"
      fill={color}
      displaySeatLabel
    />
  );
});

type Props = SeatProps<'Seat'> & {
  color: string;
};

export default PlanEditorSeat;
