import { SOLID_COLOR_LIST } from './colors';

export function callOnElementOrList<E extends Node, P extends Node>(
  elements: E,
  cb: (params: P) => void
): void;
export function callOnElementOrList<
  E extends Element | Element[] | HTMLCollectionOf<Element>,
  P extends Element
>(elements: E, cb: (params: P) => void): void;
export function callOnElementOrList<E extends NodeList, P extends Node>(
  elements: E,
  cb: (params: P) => void
): void;

export function callOnElementOrList<
  E extends Element[] | NodeList | HTMLCollectionOf<Element> | Node,
  P extends Element[] | Node | Element
>(elements: E, cb: (params: P) => void): void {
  if (Array.isArray(elements)) {
    elements.forEach(cb);
  } else if (
    elements instanceof NodeList ||
    elements instanceof HTMLCollection
  ) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements.item(i);

      if (element !== null) {
        // @ts-expect-error -- element should be good, I don't really understand the error
        cb(element);
      }
    }
  } else {
    // @ts-expect-error -- elements should be good, I don't really understand the error
    cb(elements);
  }
}

/**
 * Check the scroll state of a `containerElt`, to see if its `scrollableElt` children is scrollable to the top and/or to the bottom
 * This function applies the class `.is-scrollable-top` if you can scroll
 * @param  DOM Element Object containerElt the parent container element on which to apply the classes
 * @param  DOM Element Object scrollableElt the scrollable DOM element on which to check the scroll state
 */
export function checkScrollable(
  containerElt: Element,
  scrollableElt: HTMLElement
): void {
  const eltScrollHeight = scrollableElt.scrollHeight;
  const { offsetHeight, scrollTop } = scrollableElt;

  const classTopScrollable = 'is-scrollable-top';
  const classBottomScrollable = 'is-scrollable-bottom';

  if (eltScrollHeight > offsetHeight) {
    if (eltScrollHeight - scrollTop === offsetHeight) {
      containerElt.classList.remove(classBottomScrollable);
    } else {
      containerElt.classList.add(classBottomScrollable);
    }

    if (scrollTop === 0) {
      containerElt.classList.remove(classTopScrollable);
    } else {
      containerElt.classList.add(classTopScrollable);
    }
  }
}

/**
 * Returns a color from the list of colors.
 * The same hash will return the same color.
 */
export function generateColor(hash: number): string {
  return SOLID_COLOR_LIST[hash % SOLID_COLOR_LIST.length];
}

/**
 * Transforms an hexadecimal value in rgb css property
 */
export function hexToRGB(hex: string, alpha?: number): string {
  if (!/^#[0-9A-F]{6}$/i.test(hex)) {
    throw new Error(
      "The first parameter must be a valid hexadecimal value (like '#111111')"
    );
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (typeof alpha === 'number') {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Keyboard Event utils
 */
type KeyCode = { keyCode: number; code: string };

export const KEY_CODES: Record<string, KeyCode> = {
  enter: { keyCode: 13, code: 'Enter' },
  escape: { keyCode: 27, code: 'Escape' },
  up: { keyCode: 38, code: 'ArrowUp' },
  down: { keyCode: 40, code: 'ArrowDown' },
};

export function matchKeyEvent(event: KeyboardEvent, keycode: KeyCode): boolean {
  return event.code === keycode.code || event.keyCode === keycode.keyCode;
}

/**
 * Generate a random ID
 */
export function generateRandomID(): string {
  return Math.random().toString(36).substring(2, 12);
}
