import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class TagClient extends AbstractClient<TSMetadata['tag']> {
  getPathBase(): string {
    return '/v1/tags';
  }
}

export default TagClient;
