import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class TransactionGroupClient extends AbstractClient<
  TSMetadata['transactionGroup']
> {
  getPathBase(): string {
    return '/v1/transaction_groups';
  }
}

export default TransactionGroupClient;
