import URI from 'urijs';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class InvoiceClient extends AbstractClient<TSMetadata['invoice']> {
  getPathBase(): string {
    return '/v1/invoices';
  }

  getPdf(id: string): Promise<Response> {
    const url = new URI(`${this.getPathBase()}/${id}/invoice.pdf`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getPdfByDownloadId(id: string, downloadId: string): Promise<Response> {
    const url = new URI(
      `${this.getPathBase()}/${id}/invoice.pdf?downloadId=${downloadId}`
    );

    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  sendByMail(
    id: string,
    email: string,
    fields: FieldsParameter
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/send_invoice_by_mail`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
  }
}

export default InvoiceClient;
