import React, { createContext, ReactNode, useContext } from 'react';

type ContextValue = string | null;

const CurrentContingentContext = createContext<ContextValue | undefined>(
  undefined
);

interface Props {
  children: ReactNode;
  value: ContextValue;
}

export function CurrentContingentContextProvider({
  value,
  children,
}: Props): JSX.Element {
  return (
    <CurrentContingentContext.Provider value={value}>
      {children}
    </CurrentContingentContext.Provider>
  );
}

export function useCurrentContingentContext(): ContextValue {
  const out = useContext(CurrentContingentContext);

  if (typeof out === 'undefined') {
    throw new Error(
      'useCurrentContingentContext must be used within a CurrentContingentContextProvider. Did you pass a currentContingentId to the SeatingPlanPurchaseSeat?'
    );
  }

  return out;
}
