import React, { ReactElement } from 'react';
import { CartReplacement } from '../../../src';
import EventDatePagesHeader from '../components/EventDatePagesHeader';

type Props = {
  eventDateId: number;
  cartId: number;
};

export default function DemoCartReplacement({
  eventDateId,
  cartId,
}: Props): ReactElement {
  return (
    <>
      <EventDatePagesHeader
        title="Replacement de commande"
        eventDateId={eventDateId}
      />

      <CartReplacement
        cartId={cartId}
        eventDateId={eventDateId}
        onDismissSeating={() => console.log('dismiss seating')}
      />
    </>
  );
}
