import clone from 'clone';
import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import { mapEntityRelationShips } from '../entityFactory';
import NetworkEntity from './NetworkEntity';
import OrderItem from './OrderItem';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type OrderItemInvoiceGroupType = BaseEntity<'OrderItemInvoiceGroup'> & {
  label: null | string;
  orderItemList: List<EntityRelation<OrderItem>> | null;
};

class OrderItemInvoiceGroup extends NetworkEntity<OrderItemInvoiceGroupType>({
  '@id': null,
  '@type': 'OrderItemInvoiceGroup',
  orderItemList: null,
  label: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrderItemInvoiceGroupType> = {
      '@id': null,
      '@type': 'OrderItemInvoiceGroup',
    }
  ) {
    const data = clone(val);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/order_item_invoice_groups/', '') || '';
  }
}

OrderItemInvoiceGroup.classMetadata = new ClassMetadata(
  'orderItemInvoiceGroup',
  'order_item_invoice_groups',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);

OrderItemInvoiceGroup.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('label'),
]);

OrderItemInvoiceGroup.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'orderItem', 'orderItemList'),
]);

export default OrderItemInvoiceGroup;
