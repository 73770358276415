import React from 'react';
import {
  getCustomerFullname,
  getFormattedCustomerPhone,
} from '@mapado/js-component';
import { Cart, Order } from 'mapado-ticketing-js-sdk';
import { ShouldNotHappenError } from '../../../errors';

function CustomerCell({ entity }: CustomerCellType) {
  if (typeof entity.customer === 'string') {
    throw new ShouldNotHappenError(
      'entity.customer must be either null or an object'
    );
  }

  const fullname = getCustomerFullname(entity.customer);
  const phone = getFormattedCustomerPhone(entity.customer);
  const hasCustomerInformation =
    entity.customer && (!!fullname || entity.customer.phone);

  const name =
    entity instanceof Cart ? entity.reservationName : entity.description;

  let headerContent: React.ReactElement | undefined;
  let displayName = true;

  if (hasCustomerInformation) {
    headerContent = (
      <span>
        <div className="mpd-seating-booking-tile__customer-fullname larger bold">
          {fullname}
        </div>
      </span>
    );
  } else if (name) {
    // if a cart reservationName or order description is present
    // it will be the header, therefore do not display
    // it again bellow the header
    headerContent = <span>{name}</span>;
    displayName = false;
  }

  return (
    <div>
      {headerContent && (
        <header className="mpd-table__cell-header">{headerContent}</header>
      )}

      <article className="mpd-table__cell-description">
        {displayName && !!name && <div>{name}</div>}
        {!!phone && <div>{phone}</div>}
      </article>
    </div>
  );
}

type CustomerCellType = {
  entity: Cart | Order;
};

export default CustomerCell;
