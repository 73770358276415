import URI from 'urijs';
import StockContingent, {
  StockContingentType,
} from '../entity/StockContingent';
import { createEntity } from '../entityFactory';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import { FieldsParameter } from './AbstractClient';
import HidableClient from './HidableClient';

class StockContingentClient extends HidableClient<
  TSMetadata['stockContingent']
> {
  getPathBase(): string {
    return '/v1/stock_contingents';
  }

  getDefaultGroups(): Array<string> {
    return ['stock_contingent_read', 'stock_contingent_write'];
  }

  clone(
    id: number | string,
    eventDateId: number | string,
    fields: FieldsParameter
  ): Promise<StockContingent> {
    const url = URI(`${this.getPathBase()}/${id}/clone/${eventDateId}`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(url, {
      method: 'POST',
      body: '{}',
    })
      .then((r) => r.json())
      .then((e: StockContingentType) => createEntity(e));
  }
}

export default StockContingentClient;
