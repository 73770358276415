import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import OrganizationalUnitClient from '../client/OrganizationalUnitClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type OrganizationalUnitType = BaseEntity<'OrganizationalUnit'> & {
  name: null | string;
  isDefault: null | boolean;
  contract: null | EntityRelation<Contract>;
  customerRequiredFields: null | Map<
    string,
    Map<'position' | 'slug' | 'required', unknown>
  >;
  hideFees: boolean;
  gdprOptinMessage: null | string;
  templateParameters: null | Map<string, unknown>;
  mailAdditionalInformation: null | string;
  notifyOnNewOrderSuccess: boolean;
  publicContactEmail: null | string;
  orderNotificationEmailList: null | List<string>;
};

class OrganizationalUnit extends NetworkEntity<OrganizationalUnitType>({
  '@id': null,
  '@type': 'OrganizationalUnit',
  name: null,
  isDefault: null,
  contract: null,
  customerRequiredFields: Map<
    string,
    Map<'position' | 'slug' | 'required', unknown>
  >(),
  hideFees: false,
  gdprOptinMessage: null,
  templateParameters: Map<string, unknown>(),
  mailAdditionalInformation: null,
  notifyOnNewOrderSuccess: false,
  publicContactEmail: '',
  orderNotificationEmailList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<OrganizationalUnit> = {
      '@id': null,
      '@type': 'OrganizationalUnit',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/organizational_units/', '') || '';
  }
}

OrganizationalUnit.classMetadata = new ClassMetadata(
  'organizationalUnit',
  'organizational_units',
  /** @ts-expect-error -- method signature are incompatible */
  OrganizationalUnitClient
);

OrganizationalUnit.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('isDefault', 'isDefault', 'boolean'),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('hideFees', 'hideFees', 'boolean'),
  new Attribute('mailAdditionalInformation'),
  new Attribute('gdprOptinMessage'),
  new Attribute('publicContactEmail'),
  new Attribute('templateParameters', 'templateParameters', 'object'),
  new Attribute(
    'notifyOnNewOrderSuccess',
    'notifyOnNewOrderSuccess',
    'boolean'
  ),
  new Attribute('orderNotificationEmailList'),
]);

OrganizationalUnit.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default OrganizationalUnit;
