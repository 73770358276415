import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum STATUS {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  COMPLETE = 'COMPLETE',
}

export enum NAME {
  TICKETING_STATS = 'ticketing_stats',
  MASS_ACTION = 'mass_action',
}

export enum ACTION {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  CLONE = 'clone',
  SCAN = 'scan',
  PAY = 'pay',
  ADD_TO_SELLING_DEVICE = 'add_to_selling_device',
  CREATE_FOR_SELLING_DEVICE = 'create_for_selling_device',
  REMOVE_FROM_SELLING_DEVICE = 'remove_from_selling_device',
}

export enum ENTITY_TYPE {
  EVENT_DATE = 'EventDate',
  TICKETING = 'Ticketing',
  TICKET_PRICE = 'TicketPrice',
  TICKET = 'Ticket',
  ORDER = 'Order',
  CART = 'Cart',
}

export type JobType = BaseEntity<'Job', `/v1/jobs/${string}`> & {
  name: null | string;
  result: null | Record<string, unknown>;
  parameters: null | Record<string, unknown>;
  userData: null | Record<string, unknown>;
  contract: null | EntityRelation<Contract>;
  userId: null | number;
  status: null | string;
  progress: null | number;
  createdAt: null | Moment;
};

class Job extends NetworkEntity<JobType>({
  '@id': null,
  '@type': 'Job',
  name: null,
  result: null,
  parameters: null,
  userData: null,
  contract: null,
  userId: null,
  status: null,
  progress: null,
  createdAt: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<JobType> = { '@id': null, '@type': 'Job' }) {
    super(val);

    const data = clone(val);

    data.createdAt = parseDate(data.createdAt, null);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/jobs/', '') || '';
  }
}

/** @ts-expect-error -- method signature are incompatible */
Job.classMetadata = new ClassMetadata('job', 'jobs', AbstractClient);
Job.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('name'),
  new Attribute('status'),
  new Attribute('progress', 'userId', 'float'),
  new Attribute('userId', 'userId', 'integer'),
  new Attribute('result', 'result', 'array'),
  new Attribute('parameters', 'parameters', 'array'),
  new Attribute('userData', 'userData', 'array'),
]);
Job.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Job;
