import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAccess = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-524 -1184)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="acc\xE9s"
            d="M440 363.667c7.364 0 13.333 5.97 13.333 13.333s-5.97 13.333-13.333 13.333-13.333-5.97-13.333-13.333 5.97-13.333 13.333-13.333m3.358 12H432v2.666h11.82l-5.153 5.309 1.78 1.834 7.997-8.238-7.996-8.238-1.781 1.835z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAccess;
