import * as React from 'react';
import type { SVGProps } from 'react';
const SvgValid = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0 0 5.373 0 12m16.663-3.563 1.4 1.428-6.612 6.482-.346-.352-.01.01-3.542-3.582 1.423-1.407 2.489 2.517z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgValid;
