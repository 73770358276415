import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import MasterTicketClient from '../client/MasterTicketClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Customer from './Customer';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import type Ticket from './Ticket';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export type MasterTicketType = BaseEntity<'MasterTicket'> & {
  barcode: null | string;
  order: EntityRelation<Order>;
  data: null | Record<string, unknown>;
  ticketList: List<Ticket>;
  participant: null | undefined | EntityRelation<Customer>;
};

class MasterTicket extends NetworkEntity<MasterTicketType>({
  '@id': null,
  '@type': 'MasterTicket',
  barcode: null,
  order: new Order(),
  data: null,
  ticketList: List<Ticket>(),
  participant: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(val: PartialEntity<MasterTicketType>) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/master_tickets/', '') || '';
  }
}

MasterTicket.classMetadata = new ClassMetadata(
  'masterTicket',
  'master_tickets',
  /** @ts-expect-error -- method signature are incompatible */
  MasterTicketClient
);
MasterTicket.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('data', 'data', 'object'),
  new Attribute('barcode'),
]);
MasterTicket.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'participant'),
]);

export default MasterTicket;
