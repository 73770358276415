import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 32" className="mpd-icon" {...props}>
    <g id="Page-1" fillRule="evenodd">
      <g
        id="UI-KIT_graphique"
        fillRule="nonzero"
        transform="translate(-874 -973)"
      >
        <g id="icon" transform="translate(98 821)">
          <path
            id="refresh"
            d="M791.022 177.014a9.3 9.3 0 0 0 5.798-2.906 9.3 9.3 0 0 0 2.513-6.372 9.3 9.3 0 0 0-2.907-6.77l1.836-1.933a11.97 11.97 0 0 1 3.738 8.703 11.96 11.96 0 0 1-3.232 8.193 12 12 0 0 1-5.832 3.445l4.193 2.136-.973 1.91-8.578-4.37 4.371-8.577 1.91.973zm-3.205-18.31a9.3 9.3 0 0 0-5.661 2.609 9.3 9.3 0 0 0-2.823 6.687c0 2.399.907 4.652 2.512 6.37l-1.95 1.82a11.96 11.96 0 0 1-3.228-8.19c0-3.28 1.324-6.354 3.628-8.598a11.97 11.97 0 0 1 5.744-3.113l-4.28-2.18.974-1.91 8.578 4.37-4.371 8.577-1.91-.973z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgRefresh;
