import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import type { Toast } from './constants';
import ToastList from './ToastList';

export type MpdToastFunction = {
  (toast: Toast): void;
};

const MpdToastContext = createContext<MpdToastFunction | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export default function MpdToastProvider({ children }: Props): JSX.Element {
  const [toastList, setToastList] = useState<Array<Toast>>([]);

  const addToast = useCallback((toast: Toast) => {
    setToastList((prev) => [...prev, toast]);
  }, []);

  return (
    <MpdToastContext.Provider value={addToast}>
      {children}

      {toastList && <ToastList toastList={toastList} />}
    </MpdToastContext.Provider>
  );
}

export function useMpdToast(): MpdToastFunction {
  const context = useContext(MpdToastContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'You need a `MpdToastProvider` component up in the tree to use `useMpdToast`'
    );
  }

  return context;
}
